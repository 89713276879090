// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CEActionsProgressController, { Props } from "./CEActionsProgressController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
// Customizable Area End

class CEActionsProgress extends CEActionsProgressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-header-box">
              <Typography variant="body1">
                <span
                  onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}
                  data-test-id="pro-navigation"
                >
                  {t("My Dashboards")}
                </span>{" "}
                /{" "}
                <span data-test-id="action-team-navigation" onClick={() => navigationFunc(this.props, "ActionAssignTeam")}>
                  {t("Action assigned to my team")}
                </span>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }}>{t("View Actions Progress")}</Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("View Actions Progress")}
                </Typography>
                <Box className="select-box filter-head-box">
                  <FilterSelect
                    t={t}
                    language={language}
                    label={t("Year")}
                    value={this.state.year}
                    onChange={(event: any) => this.setState({ year: event.target.value })}
                    option={[...this.state.yearList.map((item: any) => ({ label: item, value: item }))]}
                    data-test-id="progress-select-year"
                  />
                </Box>
              </Box>
            </Box>
            <Box className="content-boxes action-progress-table">
              <Box className="top-content dashboard-ticket-table-head">
                <Box className="heading dashboard-ticket-heading">
                  <h2 className="bold-text">{t("View Actions Progress")}</h2>
                </Box>
                <SearchInput
                  onChange={(event: any) => this.setState({ search: event.target.value })}
                  t={t}
                  language={language}
                  label={t("Search by Name")}
                  value={this.state.search}
                  data-test-id="search-query"
                />
              </Box>
              <Divider />
              <TableContainer>
                <Table style={{ minWidth: 900 }} className="unit-table table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Account Manager")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {t("Assigned Actions")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {t("Completed Actions")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Pending Actions")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Delayed Actions")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.actionProgressList.map((user: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{user.full_name}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.Assigned_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.Completed_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.pending_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.delayed_action}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={LanguageAttributeHandler(language, "action-view-menu", "")}
                          >
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem
                                className="view-action-menu"
                                onClick={() => navigationFunc(this.props, "ActionAssignUser", { id: user.user_id })}
                              >
                                {t("View Details")}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsProgress)));
// Customizable Area End
