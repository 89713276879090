import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import * as Yup from "yup";
import moment from "moment";
import toast from "react-hot-toast";
import RichTextEditor from "react-rte";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface LeaseForm {
  tenantName: string;
  landlordName: string;
  buildingName: string;
  unitName: string;
  complexName: string;
  address: string;
  complexId: any;
  buildingId: any;
  unitId: any;
  duration: string;
  startDate: string;
  endDate: string;
  monthlyRent: string;
  currency: string;
}

interface S {
  // Customizable Area Start
  currencyList: any[];
  complexList: any[];
  buildingList: any[];
  unitList: any[];

  templateId: string;
  leaseForm: LeaseForm;

  tenant: any;
  contract: any;

  isPenaltyCountModalOpen: boolean;
  isPenaltyRentModalOpen: boolean;
  isPenaltyAmountModalOpen: boolean;
  isConditionModalOpen: boolean;
  isSaveLeaseModalOpen: boolean;
  isGenerateLeaseModalOpen: boolean;
  isShareModalOpen: boolean;

  isBuildingAvailable: boolean;

  changedTemplate: string;

  shareUrl: string;

  isLatePaymentPenalty: boolean;
  penalty: any;
  penaltyId: string;
  penaltyType: string;
  penaltyAmount: string;

  paymentTerm: any[];
  personalCondition: any[];
  selectedPaymentTermId: any[];
  selectedPaymentTerm: any[];
  selectedPaymentTermText: any[];
  selectedPersonalConditionId: any[];
  selectedPersonalCondition: any[];
  selectedPersonalConditionText: any[];

  editor: any;

  templateName: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LeaseFormController extends BlockComponent<Props, S, SS> {
  GetCurrencyListCallId: any;
  GetBuildingListCallId: any;
  GetUnitListCallId: any;
  IsContractExistCallId: any;
  GetTemplateTextCallId: any;
  GetPenaltyDetailsCallId: any;
  CreatePenaltyCallId: any;
  EditPenaltyCallId: any;
  GetPaymentTermCallId: any;
  GetPersonalConditionCallId: any;
  CreateContractCallId: any;
  CreateTemplateCallId: any;
  EditTemplateCallId: any;
  GetComplexListCallId: any;
  GetUnitDetailsCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      currencyList: [],
      complexList: [],
      buildingList: [],
      unitList: [],

      templateId: "",

      tenant: null,
      contract: null,

      leaseForm: {
        tenantName: "",
        landlordName: "",
        buildingName: "",
        unitName: "",
        complexId: "",
        buildingId: "",
        unitId: "",
        duration: "",
        startDate: "",
        endDate: "",
        monthlyRent: "",
        currency: "",
        complexName: "",
        address: "",
      },

      isPenaltyCountModalOpen: false,
      isPenaltyRentModalOpen: false,
      isPenaltyAmountModalOpen: false,
      isConditionModalOpen: false,
      isSaveLeaseModalOpen: false,
      isGenerateLeaseModalOpen: false,
      isShareModalOpen: false,

      isBuildingAvailable: true,

      changedTemplate: "",

      shareUrl: "",

      isLatePaymentPenalty: false,
      penalty: null,
      penaltyId: "",
      penaltyType: "",
      penaltyAmount: "",

      paymentTerm: [],
      personalCondition: [],
      selectedPaymentTermId: [],
      selectedPaymentTerm: [],
      selectedPaymentTermText: [],
      selectedPersonalConditionId: [],
      selectedPersonalCondition: [],
      selectedPersonalConditionText: [],

      editor: RichTextEditor.createEmptyValue(),

      templateName: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetComplexListCallId:
          this.handleGetComplexListResponse(responseJson);
          break;
        case this.GetUnitDetailsCallId:
          this.handleUnitDetailsResponse(responseJson);
          break;
        case this.GetBuildingListCallId:
          this.handleBuildingListResponse(responseJson);
          break;
        case this.GetUnitListCallId:
          this.handleUnitListResponse(responseJson);
          break;
        case this.GetCurrencyListCallId:
          this.handleCurrencyListResponse(responseJson);
          break;
        case this.IsContractExistCallId:
          this.handleContractExistResponse(responseJson);
          break;
        case this.GetPenaltyDetailsCallId:
          this.handlePenaltyDetailsResponse(responseJson);
          break;
        case this.CreatePenaltyCallId:
          this.handleCreatePenaltyResponse(responseJson);
          break;
        case this.EditPenaltyCallId:
          this.handleEditPenaltyResponse(responseJson);
          break;
        case this.GetTemplateTextCallId:
          this.handleTemplateTextResponse(responseJson);
          break;
        case this.GetPaymentTermCallId:
          this.handlePaymentTermResponse(responseJson);
          break;
        case this.GetPersonalConditionCallId:
          this.handlePersonalConditionResponse(responseJson);
          break;
        case this.CreateContractCallId:
          this.handleCreateContractResponse(responseJson);
          break;
        case this.CreateTemplateCallId:
          this.handleCreateTemplateResponse(responseJson);
          break;
        case this.EditTemplateCallId:
          this.handleEditTemplateResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Response API
  handleGetComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complex) {
      this.setState({ complexList: responseJson.complex });
    }
  };

  handleUnitDetailsResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        leaseForm: {
          ...this.state.leaseForm,
          complexId: this.handleText(responseJson.complex, responseJson.complex.id),
          complexName: this.handleText(responseJson.complex, responseJson.complex.name),
          address: this.handleText(responseJson.complex_address, responseJson.complex_address.address),
          unitName: this.handleText(responseJson.apartment_management, responseJson.apartment_management.apartment_name),
          unitId: this.handleText(responseJson.apartment_management, responseJson.apartment_management.id),
          tenantName: this.handleText(responseJson.tenant_name, responseJson.tenant_name?.full_name),
          landlordName: this.handleText(responseJson.landlord_name, responseJson.landlord_name?.full_name),
          buildingName: this.handleText(responseJson.building_management, responseJson.building_management.name),
          buildingId: this.handleText(responseJson.building_management, responseJson.building_management.id),
          monthlyRent: this.handleText(responseJson.apartment_management, responseJson.apartment_management.monthly_renting_income),
          currency: this.handleText(responseJson.currency, responseJson.currency.currency),
        },
      });
    }
  };
  handleText = (object: any, objectKey: any) => {
    if (object && objectKey) {
      return objectKey;
    } else {
      return "";
    }
  };
  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingList: responseJson.buildings });
    }
  };
  handleUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.apartments) {
      this.setState({ unitList: responseJson.apartments });
    }
  };
  handleCurrencyListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ currencyList: responseJson.data });
    }
  };
  handleContractExistResponse = (responseJson: any) => {
    this.setState({ tenant: responseJson.account, contract: responseJson.contract.data }, () => {
      if (this.state.tenant) {
        window.sessionStorage.setItem("tenant", this.state.tenant.id);
      }
    });
  };
  handlePenaltyDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.penanlty) {
      this.setState({ penalty: responseJson.penanlty });
    }
  };
  handleCreatePenaltyResponse = (responseJson: any) => {
    if (responseJson && responseJson.penanlty) {
      this.setState({ penalty: responseJson.penanlty }, () => {
        this.handlePenaltyCountModal();
        toast.success("Late payment penalty created successfully");
      });
    }
  };
  handleEditPenaltyResponse = (responseJson: any) => {
    if (responseJson && responseJson.penanlty) {
      this.setState({ penalty: responseJson.penanlty, penaltyId: "", penaltyAmount: "", penaltyType: "" }, () => {
        this.handlePenaltyCountModal();
        toast.success("Late payment penalty edited successfully");
      });
    }
  };
  handleTemplateTextResponse = (responseJson: any) => {
    const contract = JSON.parse(window.sessionStorage.getItem("contractForm") as any);

    if (responseJson && responseJson.data) {
      const template = responseJson.data.attributes.template;
      let changedTemplate = template.replace("{{TENANT_NAME}}", contract.tenantName);
      changedTemplate = changedTemplate.replace("{{LANDLORD_NAME}}", contract.landlordName);
      changedTemplate = changedTemplate.replace("{{BUILDING_NAME}}", contract.buildingName);
      changedTemplate = changedTemplate.replace("{{UNIT_NAME}}", contract.unitName);
      changedTemplate = changedTemplate.replace("{{DURATION}}", contract.duration);
      changedTemplate = changedTemplate.replace("{{COMPLEX_NAME}}", contract.complexName);
      changedTemplate = changedTemplate.replace("{{COMPLEX_ADDRESS}}", contract.address);
      changedTemplate = changedTemplate.replace(
        "{{START_DATE}}",
        moment(contract.startDate, "YYYY-MM-DD").format("MMMM DD, YYYY")
      );
      changedTemplate = changedTemplate.replace(
        "{{END_DATE}}",
        moment(contract.endDate, "YYYY-MM-DD").format("MMMM DD, YYYY")
      );
      changedTemplate = changedTemplate.replace("{{AMOUNT}}", contract.currency + " " + contract.monthlyRent);

      window.sessionStorage.setItem("changedTemplate", changedTemplate);
      this.setState({ changedTemplate: changedTemplate });
    }
  };
  handlePaymentTermResponse = (responseJson: any) => {
    if (responseJson && responseJson.terms) {
      this.setState({ paymentTerm: responseJson.terms }, () => {
        if (this.state.selectedPaymentTermId.length > 0) {
          const data = this.state.paymentTerm.filter((term: any) => this.state.selectedPaymentTermId.includes(term.id));
          this.setState({ selectedPaymentTerm: data, selectedPaymentTermText: data });
        }
      });
    }
  };
  handlePersonalConditionResponse = (responseJson: any) => {
    if (responseJson && responseJson.Conditions) {
      this.setState({ personalCondition: responseJson.Conditions }, () => {
        if (this.state.selectedPersonalConditionId.length > 0) {
          const data = this.state.personalCondition.filter((condition: any) =>
            this.state.selectedPersonalConditionId.includes(condition.id)
          );
          this.setState({ selectedPersonalCondition: data, selectedPersonalConditionText: data });
        }
      });
    }
  };
  handleCreateContractResponse = (responseJson: any) => {
    if (responseJson.code === 200) {
      window.sessionStorage.clear();
      toast.success(responseJson.message);
      this.props.navigation.navigate("ContractsList");
    }
  };
  handleCreateTemplateResponse = (responseJson: any) => {
    if (responseJson) {
      window.sessionStorage.clear();
      toast.success("Template created successfully");
      this.props.navigation.navigate("ContractsList");
    }
  };
  handleEditTemplateResponse = (responseJson: any) => {
    if (responseJson) {
      window.sessionStorage.clear();
      toast.success("Template edited successfully");
      this.props.navigation.navigate("ContractsList");
    }
  };

  handleIssueFormComplex = (complex: any, setFieldValue: any) => {
    const complexId = complex.id;
    setFieldValue("complexId", complexId);
    if (complex.is_building) {
      this.setState({ isBuildingAvailable: false }, () => {
        const buildingId = complex.is_building_list[0].id;
        setFieldValue("buildingId", buildingId);
        this.getUnits(complexId, buildingId);
      });
    } else {
      this.setState({ isBuildingAvailable: true }, () => {
        this.getBuilding(complexId);
        setFieldValue("buildingId", "");
      });
    }
  }


  // Get Complex List - API
  getFormComplexList = async () => {
    const user_type = localStorage.getItem("userType");
    this.GetComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_type}`,
    });
  };

  // Get Unit Details - API
  getUnitDetails = async (complexId: any, unitId: any) => {
    this.GetUnitDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_contract/contracts/find_complex?apartment_management_id=${unitId}`,
    });
  };

  // Get Building - API
  getBuilding = async (complexId: any) => {
    this.GetBuildingListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_meeting/find_building`,
    });
  };

  // Get Units - API
  getUnits = async (complexId: any, buildingId: any) => {
    this.GetUnitListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_contract/tenant_resquests/find_unit?building_management_id=${buildingId}`,
    });
  };

  // Get Currency List - API
  getCurrencyList = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetCurrencyListCallId = apiRequest.messageId;

    // const society_id = localStorage.getItem("society_id");
    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_posts/classifieds/currency_list`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Find Unit Contract Exist - API
  handleCheckContractExist = async (complexId: any, unitId: any) => {
    this.IsContractExistCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_contract/contracts/find_tenant?apartment_management_id=${unitId}`,
    });
  };

  // Get Penalty - API
  getPenaltyDetails = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetPenaltyDetailsCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    const tenant_id = window.sessionStorage.getItem("tenant");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/penanlty_late_payments/show_penanlty?tenant_id=${tenant_id}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Penalty - API
  createPenalty = (values: any) => {
    const tenant_id = window.sessionStorage.getItem("tenant");
    const body = {
      penanlty: {
        penanlty_counted: values.penaltyType,
        amount: values.penaltyAmount,
        tenant_id: tenant_id,
      },
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreatePenaltyCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/penanlty_late_payments`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Edit Penalty - API
  editPenalty = (values: any) => {
    const tenant_id = window.sessionStorage.getItem("tenant");
    const body = {
      penanlty: {
        penanlty_counted: values.penaltyType,
        amount: values.penaltyAmount,
        tenant_id: tenant_id,
      },
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.EditPenaltyCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/penanlty_late_payments/${this.state.penaltyId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Template - API
  getTemplateText = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetTemplateTextCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/lease_templates/${this.state.templateId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Payment Term - API
  getPaymentTerm = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetPaymentTermCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/terms`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Personal Condition - API
  getPersonalCondition = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetPersonalConditionCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/conditions`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // CRETE CONTRACT - API
  handleCreateContract = () => {
    const society_id: any = localStorage.getItem("society_id");
    const tenant_id: any = window.sessionStorage.getItem("tenant");
    const latePaymentPenalty: any = window.sessionStorage.getItem("isLatePaymentPenalty");
    const contractTemplate: any = window.sessionStorage.getItem("changedTemplate");
    const contractForm: any = JSON.parse(window.sessionStorage.getItem("contractForm") as any);
    const condition: any = JSON.parse(window.sessionStorage.getItem("condition") as any);

    let data = new FormData();
    data.append("[contract][society_management_id]", society_id);
    data.append("[contract][building_management_id]", contractForm.buildingId);
    data.append("[contract][apartment_management_id]", contractForm.unitId);
    data.append("[contract][tenant_id]", tenant_id);
    data.append("[contract][tenant_name]", contractForm.tenantName);
    data.append("[contract][landlord_name]", contractForm.landlordName);
    data.append("[contract][agreement_duration]", contractForm.duration);
    data.append("[contract][start_date]", contractForm.startDate);
    data.append("[contract][expires_on]", contractForm.endDate);
    data.append("[contract][rent_amount]", contractForm.monthlyRent);
    data.append("[contract][currency]", contractForm.currency);
    if (condition.isEditorCondition) {
      data.append("[contract][custom_term_condition]", condition.editorCondition);
    } else {
      data.append("[contract][term_ids][]", condition.paymentTerm.toString());
      data.append("[contract][condition_ids][]", condition.personalCondition.toString());
    }
    data.append("[contract][penanlty_late_payment]", latePaymentPenalty);
    data.append("[contract][contract_template]", contractTemplate);
    data.append("[contract][custom_contract]", "false");
    data.append("[contract][lease_template_id]", this.state.templateId);

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateContractCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/contracts`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // CRETE TEMPLATE - API
  handleCreateTemplate = () => {
    const society_id: any = localStorage.getItem("society_id");
    const tenant_id: any = window.sessionStorage.getItem("tenant");
    const latePaymentPenalty: any = window.sessionStorage.getItem("isLatePaymentPenalty");
    const contractTemplate: any = window.sessionStorage.getItem("changedTemplate");
    const contractForm: any = JSON.parse(window.sessionStorage.getItem("contractForm") as any);
    const condition: any = JSON.parse(window.sessionStorage.getItem("condition") as any);

    let data = new FormData();
    data.append("[template][lease_template_id]", this.state.templateId);
    data.append("[template][template_name]", this.state.templateName);
    data.append("[template][apartment_management_id]", contractForm.unitId);
    data.append("[template][building_management_id]", contractForm.buildingId);
    data.append("[template][society_management_id", society_id);
    data.append("[template][landlord_name", contractForm.landlordName);
    data.append("[template][start_date]", contractForm.startDate);
    data.append("[template][rent_amount]", contractForm.monthlyRent);
    data.append("[template][currency]", contractForm.currency);
    data.append("[template][expires_on]", contractForm.endDate);
    data.append("[template][tenant_name", contractForm.tenantName);
    data.append("[template][penanlty_late_payment]", latePaymentPenalty);
    data.append("[template][custom_contract]", "false");
    data.append("[template][agreement_duration]", contractForm.duration);
    data.append("[template][custom_lease_template]", contractTemplate);
    if (condition.isEditorCondition) {
      data.append("[template][custom_term_condition]", condition.editorCondition);
    } else {
      data.append("[template][term_ids][]", condition.paymentTerm.toString());
      data.append("[template][condition_ids][]", condition.personalCondition.toString());
    }
    data.append("[template][tenant_id]", tenant_id);

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateTemplateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/saved_lease_templates`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleEditLeaseModal = () => {
    const template_id: any = window.sessionStorage.getItem("templateId");
    const society_id: any = localStorage.getItem("society_id");
    const tenant_id: any = window.sessionStorage.getItem("tenant");
    const latePaymentPenalty: any = window.sessionStorage.getItem("isLatePaymentPenalty");
    const contractTemplate: any = window.sessionStorage.getItem("changedTemplate");
    const contractForm: any = JSON.parse(window.sessionStorage.getItem("contractForm") as any);
    const condition: any = JSON.parse(window.sessionStorage.getItem("condition") as any);

    var data = new FormData();
    data.append("[template][lease_template_id]", this.state.templateId);
    data.append("[template][template_name]", this.state.templateName);
    data.append("[template][apartment_management_id]", contractForm.unitId);
    data.append("[template][building_management_id]", contractForm.buildingId);
    data.append("[template][society_management_id", society_id);
    data.append("[template][landlord_name", contractForm.landlordName);
    data.append("[template][start_date]", contractForm.startDate);
    data.append("[template][rent_amount]", contractForm.monthlyRent);
    data.append("[template][currency]", contractForm.currency);
    data.append("[template][expires_on]", contractForm.endDate);
    data.append("[template][tenant_name", contractForm.tenantName);
    data.append("[template][penanlty_late_payment]", latePaymentPenalty);
    data.append("[template][custom_contract]", "false");
    data.append("[template][agreement_duration]", contractForm.duration);
    data.append("[template][custom_lease_template]", contractTemplate);
    if (condition.isEditorCondition) {
      data.append("[template][custom_term_condition]", condition.editorCondition);
    } else {
      data.append("[template][term_ids][]", condition.paymentTerm.toString());
      data.append("[template][condition_ids][]", condition.personalCondition.toString());
    }
    data.append("[template][tenant_id]", tenant_id);

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.EditTemplateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/saved_lease_templates/${template_id}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  ContractFormValidation: any = Yup.object().shape({
    buildingId: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .nullable(),
    unitId: Yup.string()
      .required("Required")
      .nullable()
      .matches(/\S/, "Required")
      .test(
        "unitId",
        "There is no tenant available for this unit. you need to assign this unit to tenant before creating a contract.",
        (value: any) => {
          if (window.sessionStorage.getItem("isEditFlow") === "false") {
            if (value) {
              return this.state.tenant !== null;
            }
            return true;
          }
          return true;
        }
      )
      .test(
        "unitId",
        "The contract is already created for this unit. you need to terminate the contract before creating a new one.",
        (value: any) => {
          if (window.sessionStorage.getItem("isEditFlow") === "false") {
            if (value) {
              return (
                (this.state.contract === null ||
                  (this.state.contract.attributes.status === "Terminated" ||
                    this.state.contract.attributes.status === "Closed")) &&
                this.state.tenant
              );
            }
            return true;
          }
          return true;
        }
      ),
    duration: Yup.string()
      .required("Required")
      .max(100, "Maximum length should be 100 character")
      .matches(/\S/, "Required"),
    startDate: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .test("Is date greater", "You can not use previous date.", (value: any) => {
        const today = moment().format("YYYY-MM-DD");
        if (!value) return true;
        return moment(today).diff(value) <= 0;
      }),
    endDate: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    currency: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    monthlyRent: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .matches(/^\d+$/, "Enter only number"),
  });

  PenaltyFormValidation: any = Yup.object().shape({
    penaltyType: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    penaltyAmount: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .matches(/^\d+$/, "Enter only number"),
  });

  handleConditionModal = () => {
    this.setState({ isConditionModalOpen: !this.state.isConditionModalOpen });
  };

  handlePenaltyCountModal = () => {
    this.setState({ isPenaltyCountModalOpen: !this.state.isPenaltyCountModalOpen });
  };

  handlePenaltyRentModal = () => {
    this.setState({ isPenaltyRentModalOpen: !this.state.isPenaltyRentModalOpen });
  };

  handlePenaltyAmountModal = () => {
    this.setState({ isPenaltyAmountModalOpen: !this.state.isPenaltyAmountModalOpen });
  };

  goBackFromReviewPage = () => {
    this.props.navigation.navigate("ChangedSelectedTemplate", { templateId: this.state.templateId });
  };

  gotoLeaseFormPage = () => {
    this.props.navigation.navigate("LeaseFormIssueLease", { templateId: this.state.templateId });
  };

  gotoSelectTemplatePage = () => {
    const isEditFlow = window.sessionStorage.getItem("isEditFlow");
    if (isEditFlow === "true") {
      this.props.navigation.navigate("ContractsList");
    } else {
      this.props.navigation.navigate("SelectedTemplateTwo", { templateId: this.state.templateId });
    }
  };

  handleSaveLeaseModal = () => {
    this.setState({ isSaveLeaseModalOpen: !this.state.isSaveLeaseModalOpen });
  };

  handleGenerateLeaseModal = () => {
    this.setState({ isGenerateLeaseModalOpen: !this.state.isGenerateLeaseModalOpen });
  };

  handleShareModal = () => {
    this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
  };
  // Customizable Area End
}
