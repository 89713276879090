import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
// Customizable Area Start


export const configJSON = require("./config");

export interface Props {
    id: string;
    t:(value:string)=> string
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    grantedBy:string
    grantedOn:string
    permissionType:string
    tags  :string
    open:boolean
    reject:boolean
    salesManager:string
    accountManager:string
    propertyManage:string
    role:string
    employeeName:string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class GrantedPermissionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
              grantedOn:'',
              permissionType:'',
              tags:'Granted to Sales Managers',
              open:false,
              reject:false,
              salesManager:'',
              grantedBy:'',
              accountManager:'',
              propertyManage:'',
              role:'',
              employeeName:''
            
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
       
        // Customizable Area End
    }

    // Customizable Area Start
    handleSalesManagerChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ salesManager: value as string })
  };

  handleAccountManagerChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ accountManager: value as string })
  };

  handleGrantedByChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ grantedBy: value as string })
  };

  handleGrantedOnChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ grantedOn: value as string })
  };

  handlePermissionTypeChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ permissionType: value as string })
  };

  handlePropertyManageChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ propertyManage: value as string })
  };

  handleRoleChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ role: value as string })
  };

  handleEmployeeNameChange = (event: ChangeEvent<{ value: unknown | string }>) => {
      const { value } = event.target;
      this.setState({ employeeName: value as string })
  };

  handleTag = (name:string) => {
      this.setState({tags:name})
  }
    
    // Customizable Area End
}