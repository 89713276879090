// Customizable Area Start
import React from "react";
import ComplexMainBlockController, {
    Props,
} from "./ComplexMainBlockController.web";
import '../../../dashboard/src/Company Employee/global.css';
import CompEmpComplexBlock from "./CompEmpComplexBlock.web"
import { withRouter } from 'react-router-dom'
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class ComplexMainBlock extends ComplexMainBlockController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        const { navigation } = this.props;
        const match: any = this.props.match;
        const id = match?.params?.id;

        return (
            <>
                <CEHeaderComponentWeb>
                    <CompEmpComplexBlock navigation={navigation} id={"complexBlock"} location={this.props.location} history={this.props.history} companyId={id} />
                </CEHeaderComponentWeb>
            </>
        );

    }
}

//@ts-ignore
export default withRouter(ComplexMainBlock)
// Customizable Area End


