// Customizable Area Start
import * as React from "react";
import {
  Button,
  Grid,
  Box,
  withStyles,
  Container,
  Link,
  Typography,
  IconButton,
  Drawer,
  Divider,
  Avatar,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  BuildingLogo,
  hamburgerIcon,
  globalIcon,
  chatIcon,
  SidebarEmail,
  SidebarFaq,
  SidebarFee,
  SidebarLogout,
  SidebarNeighbor,
  SidebarProfile,
  SidebarUnit,
  SidebarLogoutDialog,
  DashboardUnit,
  DashboardExpense,
  DashboardTenant,
  DashboardContract,
  DashboardMeeting,
  DashboardPoll,
  DashboardDocument,
  DashboardIncident,
  DashboardAnnouncement,
  DashboardExpenseDollar,
  DashboardInfo,
  DashboardVehicle,
  DashboardVisitor,
  DashboardSuggestion,
  DashboardInvoice,
  DashboardReport,
  DashboardManager,
  voting,
  NotificationGreen,
  ExclamationIcon,
  DashboardBudget,
  DashboardFacility,
  DashboardRent,
} from "./assets";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import DashboardCard from "../../../components/src/DashboardCard";
import DashboardBigCard from "../../../components/src/DashboardCard/DashboardBigCard.web";
import { withTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import DashboardController, { Props } from "./DashboardController.web";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import Slider from "react-slick";
import moment from "moment";
import { ROLE } from "../../../framework/src/Enum";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import NavigateBeforeSharpIcon from '@material-ui/icons/NavigateBeforeSharp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const MenuList = [
  {
    name: "Profile",
    url: "/profile",
    img: SidebarProfile,
  },
  {
    name: "Fees & Payments",
    url: "/FeesAndPayment",
    img: SidebarFee,
  },
  {
    name: "My Units",
    url: "/MyUnitList",
    img: SidebarUnit,
  },
  {
    name: "My Neighbours",
    url: "/NeighboursListing",
    img: SidebarNeighbor,
  },
  {
    name: "Email Alerts",
    url: "/EmailAlerts",
    img: SidebarEmail,
  },
  {
    name: "FAQ",
    url: "/FaqComplexList",
    img: SidebarFaq,
  },
];

const settings = {
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class OwnerDashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getProfile();

    const userType = localStorage.getItem("userType");
    if (userType === ROLE.OWNER || userType === ROLE.PROPERTY_MANAGER) {
      if (userType === ROLE.OWNER) {
        this.getManagerRequestList();
      }
      this.getOwnerDashboardStatistic();
    } else if (userType === ROLE.OWNER_RESIDENT || userType === ROLE.TENANT) {
      this.getResidentDashboardStatistic();
    }

    this.getNotification();
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n?.language;

    return (
      <>
        <Box className={classes.ownerDashboard} dir={languageCondition(language, "rtl", "ltr")} style={{ background: "#F7F9FE", height: "100vh", overflowY: "hidden" }}>
          <Drawer dir={languageCondition(language, "rtl", "ltr")} open={this.state.isMenuOpen} onClose={() => this.toggleDrawer()} anchor={languageCondition(language, "right", "left")}>
            <Box className="dashboard-sidebar">
              <Box className="close-menu">
                <IconButton onClick={() => this.toggleDrawer()}>
                  <CloseIcon />
                </IconButton>{" "}
                <span>{t("Menu")}</span>
              </Box>
              <Divider />
              <div className="user-info">
                <Avatar alt="Remy Sharp" src={this.state.profileData?.attributes?.profile_pic?.url} />
                <h4 className="bold-text">
                  {this.handleValidText(this.state.profileData?.attributes?.full_name?.name)}
                </h4>
                <p>{this.handleValidText(this.state.profileData?.attributes?.email?.email)}</p>
              </div>
              <Divider />
              <List className="menu-list">
                {MenuList.map((menu, index) => (
                  <ListItem key={index}>
                    <Link className="list-box" href={menu.url}>
                      <div className="list-menu">
                        <div className="image">
                          <img src={menu.img} alt="" />
                        </div>
                        <p>{t(menu.name)}</p>
                      </div>
                      {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                    </Link>
                  </ListItem>
                ))}
                <ListItem onClick={() => this.handleLogoutModal()}>
                  <div className="list-box">
                    <div className="list-menu">
                      <div className="image">
                        <img src={SidebarLogout} alt="" />
                      </div>
                      <p>{t("Logout")}</p>
                    </div>
                    {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                  </div>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box display={{ xs: "flex", md: "flex" }} className="menu">
                <div className="left-icon">
                  <IconButton onClick={() => this.toggleDrawer()}>
                    <img src={hamburgerIcon} alt="" />
                  </IconButton>
                  <span className="complex-name">{localStorage.getItem("complexName")}</span>
                </div>
                <div className="right-icon" style={{ display: "flex", gap: "15px" }}>
                  <Box>
                    <Menu
                      className="chairman-lang-menu"
                      arrow={true}
                      align="center"
                      menuButton={<img src={globalIcon} alt="GlobalIcon" />}
                    >
                      <MenuItem className={this.handleMenuClass("en")} onClick={() => this.handleEngLngChange()}>
                        English
                      </MenuItem>
                      <MenuItem className={this.handleMenuClass("ar")} onClick={() => this.handleAreLngChange()}>
                        Arabic
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box>
                    <Link href="/inbox" style={{ margin:"0" }}>
                      <img src={chatIcon} alt="GlobalIcon" />
                    </Link>
                  </Box>
                  <Box>
                    <Link href="/Notifications" style={{ margin:"0" }}>{this.handleNotificationIcon()}</Link>
                  </Box>
                </div>
              </Box>
              <Container className="dashboard">
                <Grid container spacing={1}>
                  <Box className="notification-slider">
                    <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                      {this.state.propertyManagerRequest.map((request: any) => {
                        return (
                          <Box
                            className="slide-box"
                            key={request.id}
                            onClick={() => {
                              this.setState(
                                {
                                  property: {
                                    manager: request.attributes.property_manager.name,
                                    building: request.attributes.building_management.name,
                                    unit: request.attributes.apartment_management.data.attributes.apartment_name,
                                    company: request.attributes.property_manager.company_name,
                                  },
                                },
                                () => {
                                  this.handlePropertyManagerModal();
                                }
                              );
                            }}
                          >
                            <Box className="heading">
                              <img src={NotificationGreen} alt="" />
                              <span>{t("Property manager request received")}</span>
                            </Box>
                            <p>
                              {t("Property manager request received for building")}{" "}
                              {request.attributes.building_management.name} {t("unit")}{" "}
                              {request.attributes.apartment_management.data.attributes.apartment_name}.
                            </p>
                          </Box>
                        );
                      })}
                    </Slider>
                  </Box>
                  <Grid item xs={12} sm={12} className="title">
                    <Typography variant="h6" className="bold-text">
                      {t("My Real Estate Details")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <DashboardCard
                      image={DashboardUnit}
                      heading={t("Number Of Units")}
                      title={t("Total")}
                      value={this.state.realState?.number_of_units?.count}
                      style={{ cursor: "default" }} // Add this line
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/TotalExpense">
                      <DashboardCard
                        image={DashboardExpense}
                        heading={t("Total Expenses")}
                        title={t("Total Expenses")}
                        value={
                          this.state.realState &&
                          this.state.currency +
                            " " +
                            Number(Number(this.state.realState.total_expenses.count).toFixed(2)).toLocaleString()
                        }
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Link href="/RentedVsEmpty">
                      <DashboardBigCard
                        FHeader={t("Rented")}
                        fTitle={t("Rented")}
                        fValue={this.state.realState?.rented_vs_empty?.rented_unit}
                        sHeader={t("Empty Units")}
                        sTitle={t("Empty")}
                        sValue={this.state.realState?.rented_vs_empty?.empty_unit}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Link href="/CollectedVsDue">
                      <DashboardBigCard
                        FHeader={t("Rent Amount Collected")}
                        fTitle={t("Collected")}
                        fValue={
                          this.state.realState &&
                          this.state.currency +
                            " " +
                            Number(
                              Number(this.state.realState.rented_amount_collected_vs_due.rent_collected).toFixed(2)
                            ).toLocaleString()
                        }
                        sHeader={t("Rent Amount Due")}
                        sTitle={t("Due")}
                        sValue={
                          this.state.realState &&
                          this.state.currency +
                            " " +
                            Number(
                              Number(this.state.realState.rented_amount_collected_vs_due.rent_due).toFixed(2)
                            ).toLocaleString()
                        }
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Link href="/SpentVsCollected">
                      <DashboardBigCard
                        FHeader={t("Spent Amount")}
                        fTitle={t("Collected")}
                        fValue={
                          this.state.realState &&
                          this.state.currency +
                            " " +
                            Number(
                              Number(this.state.realState.spent_amount_collected_vs_due.spent_amount).toFixed(2)
                            ).toLocaleString()
                        }
                        sHeader={t("Collected Amount")}
                        sTitle={t("Due")}
                        sValue={
                          this.state.realState &&
                          this.state.currency +
                            " " +
                            Number(
                              Number(this.state.realState.spent_amount_collected_vs_due.collected_amount).toFixed(2)
                            ).toLocaleString()
                        }
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 15 }}>
                  <Grid item xs={12} sm={12} className="title">
                    <Typography variant="h6" className="bold-text">
                      {t("Building Categories")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Tenants">
                      <DashboardCard
                        image={DashboardTenant}
                        heading={t("My tenants")}
                        title={t("Total")}
                        value={this.state.buildingCategory?.my_tenant?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Contracts">
                      <DashboardCard
                        image={DashboardContract}
                        heading={t("Contracts")}
                        title={t("Few will expire after")}
                        value={this.state.buildingCategory?.contracts?.count}
                      />
                    </Link>
                  </Grid>
                  {localStorage.getItem("userType") !== ROLE.PROPERTY_MANAGER && (
                    <Grid item xs={6} sm={6}>
                      <Link href="/MyMeetings">
                        <DashboardCard
                          image={DashboardMeeting}
                          heading={t("Meetings")}
                          title={t("Scheduled")}
                          value={this.state.buildingCategory?.meetings?.count}
                        />
                      </Link>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6}>
                    <Link href="/pollsSurvey">
                      <DashboardCard
                        image={DashboardPoll}
                        heading={t("Poll / Survey")}
                        title={t("Ongoing")}
                        value={this.state.buildingCategory?.poll_surveys?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/BudgetAndExpenseDetails">
                      <DashboardCard
                        image={DashboardBudget}
                        heading={t("Budget")}
                        title={t("For FY")}
                        value={this.state.buildingCategory?.budget?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/BuildingDocuments">
                      <DashboardCard
                        image={DashboardDocument}
                        heading={t("Building Documents")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.buildingCategory?.building_documents?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/IncidentListing">
                      <DashboardCard
                        image={DashboardIncident}
                        heading={t("Incidents")}
                        title={t("Open")}
                        value={this.state.buildingCategory?.incidents?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Announcement">
                      <DashboardCard
                        image={DashboardAnnouncement}
                        heading={t("Announcements")}
                        title={t("Unopened")}
                        value={this.state.buildingCategory?.announcements?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/MyExpenseList">
                      <DashboardCard
                        image={DashboardExpenseDollar}
                        heading={t("Expense")}
                        title={t("Last Updated")}
                        value={moment(this.state.buildingCategory?.expenses?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/ComplexList">
                      <DashboardCard
                        image={DashboardInfo}
                        heading={t("Building Info & Rules")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.buildingCategory?.building_info_and_rules?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/FacilityReservation">
                      <DashboardCard
                        image={DashboardFacility}
                        heading={t("Facility Reservation")}
                        title={t("Facility Listing")}
                        value={this.state.buildingCategory?.facility_reservations?.count}
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 15, marginBottom: "100px" }}>
                  <Grid item xs={12} sm={12} className="title">
                    <Typography variant="h6" className="bold-text">
                      {t("Personal Categories")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/veichleList">
                      <DashboardCard
                        image={DashboardVehicle.default}
                        heading={t("My Vehicles")}
                        title={t("Registered")}
                        value={this.state.personalCategory?.my_vehicle?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Visitors">
                      <DashboardCard
                        image={DashboardVisitor}
                        heading={t("My Visitors")}
                        title={t("Scheduled")}
                        value={this.state.personalCategory?.my_visitor?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/suggestionListing">
                      <DashboardCard
                        image={DashboardSuggestion}
                        heading={t("My Suggestion")}
                        title={t("Total")}
                        value={this.state.personalCategory?.my_suggesition?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/InvoicesAndReceipts">
                      <DashboardCard
                        image={DashboardInvoice}
                        heading={t("My Invoices")}
                        title={t("Last Paid")}
                        value={moment(this.state.personalCategory?.my_invoice?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/PersonalDocument">
                      <DashboardCard
                        image={DashboardDocument}
                        heading={t("Personal Documents")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.personalCategory?.persoanl_documents?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/IssueAReport">
                      <DashboardCard
                        image={DashboardReport}
                        heading={t("Issue a Reports")}
                        title={t("Last Uploaded")}
                        value={this.state.personalCategory?.isssue_report?.count}
                      />
                    </Link>
                  </Grid>
                  {localStorage.getItem("userType") !== ROLE.PROPERTY_MANAGER && (
                    <Grid item xs={6} sm={6}>
                      <Link href="/PropertyManagers">
                        <DashboardCard
                          image={DashboardManager}
                          heading={t("Property Manager")}
                          title={t("Registered")}
                          value={this.state.personalCategory?.property_manager?.count}
                        />
                      </Link>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6}>
                    <Link href="/RentPayments">
                      <DashboardCard
                        image={DashboardRent}
                        heading={t("Rent Payments")}
                        title={t("Registered")}
                        value={this.state.personalCategory?.rent_payments?.count}
                      />
                    </Link>
                  </Grid>
                  {localStorage.getItem("userType") !== ROLE.PROPERTY_MANAGER && (
                    <Grid item xs={6} sm={6}>
                      <Link href="/ChairmanNominations">
                        <DashboardCard
                          image={voting}
                          heading={t("Chairman Nominations")}
                          title={t("Chairman And Vice Chairman Nominations")}
                          value={this.state.personalCategory?.chairman_vice_chairman_nomination?.count}
                        />
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="right-block right-image" display={{ xs: "none", md: "flex" }}>
                <img src={BuildingLogo.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          className="delete-document personal"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.handleLogoutModal()}
          open={this.state.isLogoutModalOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={SidebarLogoutDialog} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                {t("Are you sure you want to logout?")}
              </Typography>
              <Typography variant="body1">{t("You will be returned to the login screen")}</Typography>
              <DialogActions className="dialog-button-group">
                <Button onClick={() => this.logout()}>{t("Logout")}</Button>
                <Button onClick={() => this.handleLogoutModal()}>{t("Cancel")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          className="delete-document personal"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.handlePropertyManagerModal()}
          open={this.state.isPropertyManagerModalOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={ExclamationIcon} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                {t("Property Manager Request Received")}
              </Typography>
              <Typography variant="body1">
                {this.state.property.manager} {t("is claiming to be your Building")} {this.state.property.building} {t("Unit")}{" "}
                {this.state.property.unit} {t("property manager from Company")} {this.state.property.company}. 
                {t("Do you want to give him right to manage your mentioned property?")}
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button onClick={() => this.props.navigation.navigate("PropertyManagerRequest")}>
                  {t("View Profile")}
                </Button>
                <Button onClick={() => this.handlePropertyManagerModal()}>{t("Close")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default withTranslation()(withStyles(DashboardStyleWeb)(OwnerDashboard));
// Customizable Area End
