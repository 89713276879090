import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

interface Filter {
  page: number;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  totalSentInvitationList:any;
  pagination:any;
  filter: Filter;
  search:any;
  complexName:string;
  complexBuildingFilterList:any
  complexDataList:any[];
  cityDataList:any[];
  buildingsDataList:any[];
  countryDataList:any[];
  statusData:any[];
  unitDataList: any[];
  searchQuery:any;

  complexId: string | number;
  complexType: string;

  isViewInvitationOpen: boolean;
  invitationDetail: {
    name: string;
    country: string;
    city: string;
    complex: string;
    unit: string;
    sentOn: string;
    phone: string;
    role: string;
    status: string;
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CETotalSentInviteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetTotalSentInvitationList:any;
  GetFilterSentInvitationList:any;
  GetPageSentInvitationList:any;
  GetCountryListID:any;
  GetCityListID:any;
  GetAllBuildingsDataId:any;
  GetComplexListCallId:any
  GetAllUnitDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      loading: false,
      totalSentInvitationList:[],
      pagination:"",
      search:{
        selectedCountry:"",
        selectedCity:"",
        selectedComplex:"",
        selectedBuilding:"",
        selectedStatus:"",
        selectedIndividualBuilding:"",
        selectedUnit: ""
      },
      complexName:"",
      searchQuery:"",
      complexDataList:[],
      cityDataList:[],
      buildingsDataList:[],
      countryDataList:[],
      unitDataList: [],
      statusData:[{
        label: "Awaiting",
        value: 'Pending'
      },
      {
        label: "Onboard",
        value: 'Accept'
      }],
      filter: {
        page: 1,
      },
      complexBuildingFilterList: [
        {
          label: "Complex",
          value: 'complex'
        },
        {
          label: "Individual Building",
          value: 'indiviualBuilding'
        },
      ],

      complexId: "",
      complexType: "",

      isViewInvitationOpen: false,

      invitationDetail: {
        name: "",
        country: "",
        city: "",
        complex: "",
        unit: "",
        sentOn: "",
        phone: "",
        role: "",
        status: ""
      },
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    const complex_type = this.props.navigation.getParam("complex");
    this.setState({ complexId: complex_id, complexType: complex_type }, () => {
      this.getTotalSentInvitationList();
      if(complex_type === "Complex") {
        this.getBuildingsDataList(complex_id);
      } else {
        this.getUnitDataList(complex_id, "");
      }
    });
  }
  getTotalSentInvitationList = async () => {
    this.setState({ loading: true })
    const { page } = this.state.filter;
    this.GetTotalSentInvitationList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getTotalSentInvitationListEndPoint+`?page=${page}&search_term=${this.state.searchQuery}&status${this.getData(this.state.search.selectedStatus)}&country${this.getData(this.state.search.selectedCountry)}&city${this.getData(this.state.search.selectedCity)}&complex${this.getData(this.state.search.selectedComplex.id || this.state.complexId)}&building_id${this.getData(this.state.search.selectedBuilding)}&apartment_management_id${this.getData(this.state.search.selectedUnit)}`,
    });
  }
  getData = (value: any) => (value !== "" && value!=="all") ? `=${value}` : "";
  onChange = (e: any) => {
    this.setState({ searchQuery: e.target.value, filter: { page: 1 } },()=> this.getTotalSentInvitationList());  
  }
  handleChangeValue=(event:any,keyState:any)=>{
    
    if(keyState==="selectedCountry"){
      this.getCityList(event.target.value)
    } else if (keyState === "selectedBuilding") {
      const value = event.target.value;
      this.getUnitDataList(this.state.complexId, value)
    } else if(keyState==="selectedComplex"){
      const id=event.target.value?.id;
      this.setState({complexName:event.target.value?.name})
      this.getBuildingsDataList(id);
    }else if(keyState==="selectedCity"){
      const id=event.target.value;
      this.getComplexList(id);
    }
    
    this.setState({ search: { ...this.state.search, [keyState]:  event.target.value}, filter: { page: 1 } })
  }
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryListID = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getCityList = async (country: any) => {
    this.GetCityListID = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`
    })
  }
  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataId = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getComplexList = async (city:any ) => {
    this.GetComplexListCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint+`?city=${city}`
    });
  };

  viewUserRequest = async (invitation: any) => {
      this.setState({
        isViewInvitationOpen: true, 
        invitationDetail: {
          name: invitation.attributes.full_name,
          country: invitation.attributes.country,
          city: invitation.attributes.city,
          complex: invitation.attributes.complex.name,
          unit: invitation.attributes?.apartment_management?.apartment_name,
          sentOn: invitation.attributes.created_at,
          phone: invitation.attributes.phone_number,
          role: invitation.attributes.role.name,
          status: this.getStatus(invitation.attributes.status)
        }
      })
  };

  handleViewInvitationModal = () => {
    this.setState({ isViewInvitationOpen: !this.state.isViewInvitationOpen });
  }

  dateFormat=(date:any)=>{
    const createdAt = new Date(date);
    return `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`;
  }

  getStatus = (data: string) => {
    if (data === "Pending") {
      return "Awaiting";
    }
    return data === "Accept" ? "Onboard" : "Rejected";
  }

  handleTotalSentInvitationList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        totalSentInvitationList: responseJson.member_invitations.data ||[],
        pagination: responseJson.meta.pagination,
      })
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingsDataList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
         countryDataList: responseJson.data?.countries.map((item: any) => ({ label: item, value: item }))
      });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
         cityDataList: responseJson.data?.cities.map((item: any) => ({ label: item, value: item })) });
    }
  };
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexDataList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value:{ id:item.id ,name: item.attributes.name} }))}
      )
    }
  }
  handleFilterPage=(value:any)=>{
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        page: Number(value),
      },
    },()=>{
      this.getTotalSentInvitationList()
      
    });
  }

  getUnitDataList = async (id: any, bId: any) => {
    this.GetAllUnitDataCallId= await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${id}&building_management_id=${bId}`,
    });
  };

  handleUnitListResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      this.setState({ unitDataList: responseJson.data.map((item: any) => ({
        label: item.attributes.apartment_name,
        value: item.id,
      }))});
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetTotalSentInvitationList:
          this.handleTotalSentInvitationList(responseJson);
          break;
        case this.GetCountryListID:
          this.handleCountryResponse(responseJson)
          break;
        case this.GetComplexListCallId:
          this.handleComplexList(responseJson)
          break;
        case this.GetCityListID:
          this.handleCityResponse(responseJson)
          break;
        case this.GetAllBuildingsDataId:
          this.handleBuildingList(responseJson)
          break;
        case this.GetAllUnitDataCallId:
          this.handleUnitListResponse(responseJson)
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }
}
