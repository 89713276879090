import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";
import { ContactSupportOutlined } from "@material-ui/icons";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";


// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  error: any;
  userType: string | null;
  allContries: [];
  selectCountry: string;
  allCity: [];
  selectCity: string;
  allBuilding: [];
  selectBuilding: string;
  allUnit: [];
  selectUnit: string;
  selectEmail: string;
  unitRegisterType: string;
  allComplex: [];
  selectComplex: any;
  loading: boolean;
  otp: any;
  selectCode: string;
  selectCode2: string;
  selectCode3: string;
  anchorEl: any;
  showDialog: boolean;
  showDialog1: boolean;
  showDialog2: boolean;
  profiledata: any;
  values: any,
  showDialogDelete: boolean,
  showError: boolean
  dateType: any;
  privacyModal: boolean;
  isPrivacyAPICalled: boolean;
  isProfilePicUpdated: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProfileController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createManagerAccountApiCallId: any;
  createAccountOwnerApiCallId: any;
  createRequestManaulApiCallId: any;
  createRequestApiCallId: any;
  changeUserTypeApiCallId: any;
  updatePhoneApicallId: any;
  getCountryApiCallId: any;
  getComplexApiCallId: any;
  getCityApiCallId: any;
  verifyOtpApiCallId: any;
  getBuildingApiCallId: any;
  getUnitApiCallId: any;
  createVehicleApiCallId: any;
  deleteVehicleAPICallId: any;
  getProfileDataAPiCallId: any;
  updateChairmenProfileAPiId: any;
  chatSettingApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);


    const profileData = JSON.parse(localStorage.getItem('profileData') || '{}')
    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      error: null,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType: '',
      allContries: [],
      selectCountry: '',
      allCity: [],
      selectCity: '',
      allBuilding: [],
      selectBuilding: '',
      allUnit: [],
      selectUnit: '',
      selectCode: `+${profileData?.attributes?.full_phone_number?.country_code}` || '+966',
      selectCode2: `+${profileData?.attributes?.full_phone_number?.country_code}` || '+966',
      selectCode3: '+966',
      selectEmail: '',
      unitRegisterType: '',
      allComplex: [],
      selectComplex: null,
      //@ts-ignore
      //@ts-nocheck
      loading: false,
      otp: '',
      anchorEl: null,
      showDialog: false,
      showDialog2: false,
      profiledata: null,
      values: null,
      showDialogDelete: false,
      showDialog1: false,
      showError: false,
      dateType: "text",
      privacyModal: false,
      isProfilePicUpdated: false,
      isPrivacyAPICalled:true,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  initialValuesFormik = (profileData: any) => {
    let values = {
      bannerUrl: profileData?.attributes?.profile_pic?.url,
      full_name: profileData?.attributes?.full_name?.name,
      banner: '',
      phone: profileData?.attributes?.full_phone_number?.phone_number,
      email: profileData?.attributes?.email?.email,
      male: profileData?.attributes?.gender?.gender === 'Male' ? true : false,
      female: profileData?.attributes?.gender?.gender === 'Female' ? true : false,
      DOB: profileData?.attributes?.date_of_birth?.date_of_birth ? profileData?.attributes?.date_of_birth?.date_of_birth : "",
      gender: profileData?.attributes?.gender?.gender,
      hobbies: profileData?.attributes?.hobbies?.hobbies ? profileData?.attributes?.hobbies?.hobbies : [],
      twitter: profileData?.attributes?.website[0].twitter_link || "",
      fb: profileData?.attributes?.website[2].fb_link || "",
      insta: profileData?.attributes?.website[1].instagram_link || "",
      snap: profileData?.attributes?.website[3].snapchat_link || "",
      bio: profileData?.attributes?.bio?.bio || ""
    }
    return values
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.validationApiCallId:
            this.handleValidationAPIResponse(responseJson);
            break;
          case this.createAccountApiCallId:
            this.handleCreateAccountIResponse(responseJson);
            break;
          case this.getUnitApiCallId:
            this.handleUnitAPIResponse(responseJson, errorReponse);
            break;
          case this.verifyOtpApiCallId:
            this.handleVerifyOTPResponse(responseJson);
            break;
          case this.updatePhoneApicallId:
            this.handleUpdatePhoneResponse(responseJson);
            break;
          case this.chatSettingApiCallId:
            this.handleChatSettingResponse(responseJson, errorReponse);
            break;
          case this.createVehicleApiCallId:
            this.handleUpdatePermissionResponse(responseJson, errorReponse);
            break;
          case this.updateChairmenProfileAPiId:
            this.handleUpdateChairmanProfileResponse(responseJson, errorReponse);
            break;
          case this.createRequestApiCallId:
            this.handleCreateRequestResponse(responseJson, errorReponse);
            break;
          case this.deleteVehicleAPICallId:
            this.handleDeleteVehicleResponse(responseJson, errorReponse);
            break;
          case this.createRequestManaulApiCallId:
            this.handleRequestManualResponse(responseJson, errorReponse);
            break;
          case this.changeUserTypeApiCallId:
            this.handleUserTypeResponse(responseJson, errorReponse);
            break;
          case this.getProfileDataAPiCallId:
            this.handleProfileDataResponse(responseJson, errorReponse);
            break;
          case this.getComplexApiCallId:
            this.handleComplexAPIResponse(responseJson, errorReponse);
            break;
          case this.getCityApiCallId:
            this.handleCityAPIResponse(responseJson, errorReponse);
            break;
          case this.getBuildingApiCallId:
            this.handleBuildingAPIResponse(responseJson, errorReponse);
            break;
          default:
            break;
        }
      }
    }

    this.handleNavigationPayload(message);
    this.handleCountryCodeReceive(message);
    // Customizable Area End
  }

  handleUserTypeResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ loading: false })
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/addressfill')
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleProfileDataResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ profiledata: responseJson.data, selectCode3: `+${responseJson.data.attributes?.full_phone_number?.country_code}` }, () => console.log(this.state.profiledata))
      this.setState({ loading: false })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleComplexAPIResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      //@ts-ignore
      //@ts-nocheck
      let temp = []
      responseJson.data.housing_complexes.map((item: any) =>
        temp.push({ value: item.id, label: item.name })
      )
      // @ts-ignore
      this.setState({ allComplex: temp }, () => console.log(this.state.allComplex))
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleCityAPIResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ allCity: responseJson.data.cities })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleBuildingAPIResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ allBuilding: responseJson.data.buildings })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleDeleteVehicleResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      //@ts-ignore
      //@ts-nocheck
      localStorage.removeItem('selectFamily')
      this.setState({ showDialogDelete: false, showDialog: false, loading: false })

      this.getProfile()

    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleRequestManualResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/RegistrationRequestsignup')
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleCreateRequestResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/RegistrationRequestsignup')
      //@ts-ignore
      //@ts-nocheck
      this.setState({ showDialog: false })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleUpdateChairmanProfileResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.getProfile()
      this.setState({ loading: false, showDialog: false })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleCreateVehicleResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ loading: false })
      //@ts-ignore
      //@ts-nocheck
      if (this.state.privacyModal) {
        this.setState({
          privacyModal: false,
        })
        toast.success("Details updated successfully!")
        this.getProfile()
      } else {
        this.props.history.push({
          pathname: '/profile'
        })
      }
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleChatSettingResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.handleClose('')
      this.getProfile()
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0];
      this.setState({ error });
      this.parseApiCatchErrorResponse(this.state.error);
      this.parseApiCatchErrorResponse(errorReponse);
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
      this.parseApiCatchErrorResponse(this.state.error);
      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.setState({ loading: false })
  }

  handleUpdatePhoneResponse = (responseJson: any) => {
    if (!responseJson.error) {
        this.setState({ showDialogDelete: true, showDialog: false, loading: false })
    }
    else if (responseJson?.error) {
      let error = responseJson.error;
      this.setState({ error }, () => console.log(this.state.error));
      // ApiCatchErrorResponse(error)
      this.setState({ error: responseJson.error, showError: true })
    } else {
      this.setState({ error: responseJson?.errors || "Something went wrong!" });
      this.setState({ error: responseJson.errors, showError: true })
    }
    this.setState({ loading: false })
  }

  handleVerifyOTPResponse = (responseJson: any) => {
    if (responseJson.message === "phone number verified") {
      console.log(responseJson)
      //@ts-ignore
      //@ts-nocheck
      let profileData: any = JSON.parse(localStorage.getItem('profileData'))
      if (profileData) {
        profileData.attributes.full_phone_number.phone_number = responseJson.phone_number
        profileData.attributes.full_phone_number.country_code = responseJson.country_code
        localStorage.setItem('profileData', JSON.stringify(profileData))
      }
      this.setState({ selectCode: responseJson.country_code,showDialogDelete:false, showDialog: false, loading: false,showDialog1:false })
      this.setState({ selectCode3: responseJson.country_code })
      this.getProfile()
      this.successModal("Phone number verified and Updated successfully!")
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0].errors;
      this.setState({ error });
      this.errorModal(error)
    } else {
      this.setState({ error: responseJson.message || "Something went wrong!" });
      // this.parseApiCatchErrorResponse(this.state.error);
      this.setState({ showError: true,otp:"" })
    }
    this.setState({ loading: false })
  }

  handleUnitAPIResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      console.log(responseJson)
      let temp = [responseJson.data.unit_apartments]
      //@ts-ignore
      //@ts-nocheck

      this.setState({ allUnit: responseJson.data.unit_apartments }, () => console.log(this.state.allUnit[0]))
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleCreateAccountIResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      console.log(responseJson)
      localStorage.setItem('res_token', responseJson.meta.token)
      localStorage.setItem('res_user', JSON.stringify(responseJson.data.attributes))
      localStorage.setItem('res_user_id', responseJson.data.id)
      localStorage.setItem('user_email', responseJson.data.attributes.email)
      //@ts-ignore
      //@ts-nocheck
      this.updateType()
      this.setState({ loading: false, error: null })

      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/otp')


    } else if (responseJson?.errors) {
      let error = responseJson.errors[0];
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({ loading: false })
  }

  handleValidationAPIResponse = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  // Customizable Area Start

  handleUpdatePermissionResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      this.setState({ loading: false })
      if (this.state.privacyModal) {
        this.setState({
          privacyModal: false,
          isPrivacyAPICalled: false
        })
        toast.success("Details updated successfully!")
        this.getProfile()
      } else {
        if(this.state.isPrivacyAPICalled){
          this.props.history.push({
            pathname: '/profile'
          })
          this.setState({
            isPrivacyAPICalled: false
          })
        }
      }
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
  async componentDidMount() {
    super.componentDidMount()
    this.getProfile();
  }
  handleCountryCodeReceive = (message: any) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  handleNavigationPayload = (message: any) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  createAccoun(attributes: any): boolean {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    //@ts-ignore
    //@ts-nocheck
    this.setState({ selectEmail: attributes.email, loading: true })

    const attrs = {
      full_name: attributes.full_name,
      last_name: attributes.lastName,
      email: attributes.email,
      password: attributes.password,
      full_phone_number: this.state.selectCode + attributes.phone,
      password_confirmation: attributes.confirm_password
    };

    const data = {
      type: "email_account",
      // @ts-ignore
      // @ts-nocheck
      "user_type": this.props.history.location.state?.data,
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }
  createAccountOwner(attributes: any): boolean {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    this.setState({ selectEmail: attributes.email })

    const attrs = {

      full_name: attributes.full_name,
      last_name: attributes.lastName,
      email: attributes.email,
      password: attributes.password,
      full_phone_number: this.state.selectCode + attributes.phone,
      password_confirmation: attributes.confirm_password
    };

    const data = {
      type: "email_account",
      // @ts-ignore
      // @ts-nocheck
      "user_type": this.props.history.location.state?.data,
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountOwnerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }

  createAccountManager = (attributes: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    this.setState({ selectEmail: attributes.email, loading: true })

    console.log(attributes)
    const attrs = {

      email: attributes.email,
      company_name: attributes.company_name,
      manager_full_name: attributes.managerName,
      owner_full_name: attributes.ownerName,
      owner_phone_number: this.state.selectCode + attributes.owner_phone,
      owner_email: attributes.owner_email,
      password: attributes.password,
      full_phone_number: this.state.selectCode + attributes.phone,
      password_confirmation: attributes.confirm_password
    };

    const data = {
      type: "email_account",
      // @ts-ignore
      // @ts-nocheck
      "user_type": this.props.history.location.state?.data,
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createManagerAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  createRequest = (attributes: any): boolean => {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };

    const attrs = {
      country: this.state.selectCountry,
      city: this.state.selectCity,
      //@ts-ignore
      //@ts-nocheck
      building_management_id: this.state.selectBuilding.id,
      //@ts-ignore
      //@ts-nocheck
      apartment_management_id: this.state.selectUnit.id,
      society_management_id: this.state.selectComplex
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: attrs
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_request_management/requests'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }
  updateTypeOwner = () => {
    console.log(this.state.userType)
    if (this.state.userType) {

      if (this.state.userType === 'Owner') {
        this.props.history.push({
          pathname: '/registerowner',
          state: {
            data: this.state.userType,
          },
        })

      }
      if (this.state.userType === 'Property Manager') {
        //@ts-ignore
        //@ts-nocheck

        this.props.history.push({
          pathname: '/registermanager',
          state: {
            data: this.state.userType,
          },
        })

      }
      if (this.state.userType === 'Tenant') {
        //@ts-ignore
        //@ts-nocheck
        this.props.history.push({
          pathname: '/register',
          state: {
            data: this.state.userType,
          },
        })

      }
      if (this.state.userType === 'Owner Resident') {
        //@ts-ignore
        //@ts-nocheck
        this.props.history.push({
          pathname: '/register',
          state: {
            data: this.state.userType,
          },
        })

      }
    }
  }
  updateType = (): boolean => {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log(this.changeUserTypeApiCallId)
    console.log(requestMessage.messageId)
    //@ts-ignore
    //@ts-nocheck
    this.setState({ loading: true })
    this.changeUserTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // @ts-ignore
      // @ts-nocheck
      `account_block/user_type?user_type=${this.props.history.location.state?.data}&id=${localStorage.getItem('res_user_id')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }

  changeType(value: any) {
    this.setState({ userType: value })

  }

  changeUnitType(value: any) {
    this.setState({ unitRegisterType: value })

  }
  handleChange2 = (e: any) => {

    if (e.target.value) {
      // @ts-ignore
      // @ts-nocheck
      this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

  }
  handleChange = (e: any) => {

    if (e.target.value) {
      // @ts-ignore
      // @ts-nocheck
      this.setState({ ...this.state, [e.target.name]: e.target.value }, () => this.getData(e))
    }

  }
  //@ts-ignore
  //@ts-nocheck

  getData(e) {


    if (e.target.name == 'selectCountry') {
      this.getCity()

    } else if (e.target.name == 'selectCity') {
      this.getComplexbyCity()

    } else if (e.target.name == 'selectComplex') {
      this.getBuilding()

    } else if (e.target.name == 'selectBuilding') {
      this.getUnit()

    }

  }

  getProfile() {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getProfileDataAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/my_profile`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCity() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getCityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_address/city_list?country=${this.state.selectCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getBuilding() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getBuildingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_address/building_list?society_management_id=${this.state.selectComplex}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUnit() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getUnitApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //@ts-ignore
      //@ts-nocheck
      `bx_block_address/apartment_list?id=${this.state.selectBuilding.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  registerUnit = () => {
    if (this.state.unitRegisterType) {
      if (this.state.unitRegisterType == 'Manual') {
        //@ts-ignore
        //@ts-nocheck

        this.props.history.push('/registerunitmanually')
      } else {
        //@ts-ignore
        //@ts-nocheck

        this.props.history.push('/RegisterUnitLink')


      }
    }
  }
  getComplex() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getComplexApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_society_management/society_managements`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getComplexbyCity() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getComplexApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_address/housing_complex_list?city=${this.state.selectCity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleInputChange = (newValue: string) => {
    // localStorage.setItem('selectComplex', JSON.stringify(newValue))
    this.setState({ selectComplex: newValue })



  };
  handleInputChangeCOm = (newValue: any) => {
    console.log(newValue)

    this.setState({ selectComplex: newValue.value }, () => this.getData({ target: { name: 'selectComplex' } }))



  };
  createRequestManual = (attributes: any) => {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('res_token')
    };
    console.log(this.state)
    const attrs = {//@ts-ignore
      //@ts-nocheck
      building_management_id: this.state.selectBuilding.id,
      country: this.state.selectCountry,
      city: this.state.selectCity,
      //@ts-ignore
      //@ts-nocheck
      unit_name: this.state.selectUnit,
      society_management_id: this.state.selectComplex
    };

    const data = {

      attributes: attrs
    };

    const httpBody = {
      data: attrs
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createRequestManaulApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_request_management/requests'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  handleChangeOTP = (otp: any) => this.setState({ otp });

  verifyOtp = (): boolean => {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("userToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    //@ts-ignore
    //@ts-nocheck
    this.setState({ loading: true })
    this.verifyOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/verify_otp?otp=${this.state.otp}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;



  }

  updateProfile = async (values: any) => {
    this.setState({ loading: true,isPrivacyAPICalled:true })
    try {
      const userProfileheader = {
        token: localStorage.getItem("userToken")
      };
        const userProfileformData = new FormData();
      userProfileformData.append("[data][attributes][full_name]", values.full_name)
      userProfileformData.append("[data][attributes][full_phone_number]", `${this.state.selectCode}${values.phone}`)
      userProfileformData.append("[data][attributes][gender]", values.gender == 'Male' ? '0' : '1')
      userProfileformData.append("[data][attributes][date_of_birth]", values.DOB)
      userProfileformData.append("[data][attributes][profile_bio]", values.bio)
      userProfileformData.append("[data][attributes][twitter_link]", values.twitter)
      userProfileformData.append("[data][attributes][fb_link]", values.fb)
      userProfileformData.append("[data][attributes][instagram_link]", values.insta)
      userProfileformData.append("[data][attributes][snapchat_link]", values.snap)
      values.hobbies.map((item: any) => {
        userProfileformData.append('[data][attributes][hobbies][]', item)
      })
      if (this.state.isProfilePicUpdated) {
        let nblob = await fetch(values.bannerUrl).then(rm => rm.blob());
        userProfileformData.append(
          "[data][attributes][image]",
          nblob
        );
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createVehicleApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/profiles_update'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(userProfileheader)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        userProfileformData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      // this.setState({ loading: false })
      console.log(error);
    }

  }

  updateChairmenProfile = async (chairmanValues: any) => {
    this.setState({ loading: true })
    try {
      const chairmanHeader = {
        token: localStorage.getItem("userToken")
      };
      const chairmanFormData = new FormData();
      chairmanFormData.append("[data][attributes][full_name]", chairmanValues.full_name)
      chairmanFormData.append("[data][attributes][full_phone_number]", `${this.state.selectCode}${chairmanValues.phone}`)
      chairmanFormData.append("[data][attributes][gender]", chairmanValues.gender == 'Male' ? '0' : '1')
      chairmanFormData.append("[data][attributes][date_of_birth]", chairmanValues.DOB)
      chairmanFormData.append("[data][attributes][profile_bio]", chairmanValues.bio)
      chairmanFormData.append("[data][attributes][twitter_link]", chairmanValues.twitter)
      chairmanFormData.append("[data][attributes][fb_link]", chairmanValues.fb)
      chairmanFormData.append("[data][attributes][instagram_link]", chairmanValues.insta)
      chairmanFormData.append("[data][attributes][snapchat_link]", chairmanValues.snap)
      chairmanValues.hobbies.map((item: any) => {
        chairmanFormData.append('[data][attributes][hobbies][]', item)
      })
      if (this.state.isProfilePicUpdated) {
        let chairmanBlob = await fetch(chairmanValues.bannerUrl).then(rq => rq.blob());
        chairmanFormData.append(
          "[data][attributes][image]",
          chairmanBlob
        );
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateChairmenProfileAPiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/profiles_update'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(chairmanHeader)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        chairmanFormData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } catch (error) {
      // this.setState({ loading: false })
      console.log(error);
    }

  }

  profileSchema = () => {
    const validations = Yup.object().shape({
      full_name: Yup.string().required(`This field is required`).trim(),
      email: Yup.string().required(`This field is required`).trim(),
      DOB: Yup.date() .max(new Date(), 'Date of Birth cannot be in the future').nullable(),
      hobbies: Yup.string().required(`This field is required`).nullable(),
      fb: Yup.string().matches(/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/, `Invalid facebook URL`).nullable(),
      insta: Yup.string().matches(/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im, `Invalid instagram URL`).nullable(),
      twitter: Yup.string().matches(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/, `Invalid twitter URL`).nullable(),
      snap: Yup.string().matches(/http(?:s)?:\/\/(?:www\.)?snapchat\.com\/([a-zA-Z0-9_]+)/, `Invalid snapchat URL`).nullable(),
    });
    return validations
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, showDialog: true })
  };
  handleClick2 = (event: any) => {
    this.setState({ anchorEl: event.currentTarget, showDialog2: true })
  };
  handleClose = (item: any) => {
    if (item.id) {
      localStorage.setItem('selectFamily', JSON.stringify(item))
      // @ts-ignore
      // @ts-nocheck
      this.props.history.push("/editfamily")

    } else {
      this.setState({ anchorEl: item.currentTarget, showDialog: false })
    }
    // this.setState({ anchorEl:null,showDialog:false })
  };
  handleClose2 = (item: any) => {

    this.setState({ anchorEl: item.currentTarget, showDialog2: false })

    // this.setState({ anchorEl:null,showDialog:false })
  };
  deleteRequest() {
    this.setState({ loading: true })
    // @ts-nocheck
    // @ts-ignore
    let item = JSON.parse(localStorage.getItem('selectFamily'))
    const header = {

      "token": localStorage.getItem('userToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteVehicleAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_family/families/${item.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSelectBanner = (
    e: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    setFieldError: (field: string, message: string) => void,
  ) => {
    let file = e?.target?.files[0];
    console.log("FILE", file)
    if (file && !['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
      return setFieldError('banner', 'Only png and jpeg are supported.')
    }

    setFieldValue("banner", file ? {
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type
    } : '');
    console.log('file', URL.createObjectURL(file))
    setFieldValue("bannerUrl", file ? URL.createObjectURL(file) : "");
    this.setState({
      isProfilePicUpdated: true
    })
    if (file) {
      e.target.value = "";
    }
  };


  updatePublicProfile = (values: any) => {
    if (!values.full_name && !this.state.privacyModal) {
      this.setState({ showDialog: true, values: values })
    } else {
      this.setState({ values: values })
      this.publicViewAPI()
    }
  }

  publicViewAPI = () => {
    this.setState({ loading: true })
    try {
      const header = {

        token: localStorage.getItem("userToken"),
        'content-type': 'application/json'
      };
      const data = {
        "data": {
          "attributes": {
            name_public: this.state.values.full_name,
            email_public: this.state.values.email,
            apartment_no_public: this.state.values.unit,
            phone_no_public: this.state.values.phone,
            gender_public: this.state.values.gender,
            date_of_birth_public: this.state.values.DOB,
            hobbies_public: this.state.values.hobbies,
            twitter_public: this.state.values.twitter,
            facebook_public: this.state.values.full_fbname,
            instagram_public: this.state.values.insta,
            snapchat_public: this.state.values.snap,
            family_details_public: this.state.values.family

          }
        }

      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createVehicleApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/profiles/public_profile'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } catch (error) {
      // this.setState({ loading: false })
      console.log(error);
    }

  }
  addPhoneSchema = () => {
    const validations = Yup.object().shape({
      phone: Yup.number()
        .typeError("Only numbers are allowed.")
        .required("Mobile number is required.")
        .positive("Negative numbers are not allowed.")
        .integer("Number can't contain a decimal.")
    });
    return validations
  }
  checkPhone = (value: any) => {


    let pettrn = /^5\d+$/

    if (value.includes('+')) {

      this.setState({ error: 'Please enter valid mobile number', showError: true })
      return false
    } else if (this.state.selectCode == '+966' || this.state.selectCode == '+971') {

      if (!(pettrn.test(value))) {

        this.setState({ error: 'Please enter valid mobile number', showError: true })
        return false
      }
      else {
        if (value.length == 9) {


          return true
        } else {
          this.setState({ error: 'Please enter valid mobile number', showError: true })
          return false
        }

      }
    } else {
      if (value.length == 10) {


        return true
      } else {
        this.setState({ error: 'Please enter valid mobile number', showError: true })
        return false
      }

    }
  }
  updatePhone = (values: any) => {
    if (this.checkPhone(values.phone)) {
      this.setState({ loading: true, error: null })
      const header = {
        "token": localStorage.getItem('userToken'),

      };
      const formData = new FormData();
      formData.append("new_phone_number", `${values.phone}`)
      formData.append("country_code", `${this.state.selectCode}`)

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePhoneApicallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/profiles/verify_number'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

  }
  handleAddChip = (fn: any, data: any, values: any) => {
    if (data.length > 1) {
      values.push(data)
      fn('hobbies', values)
    }
  }

  handleDeleteChip = (fn: any, data: any, values: any, index: any) => {
    console.log('bye')
    values.splice(index, 1)
    fn('hobbies', values)
    console.log(data, index)
  }
  disablechat = () => {

    this.setState({ loading: true })
    const header = {
      "token": localStorage.getItem('userToken'),

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.chatSettingApiCallId = requestMessage.messageId;
    let value = this.state.profiledata.attributes.disable_chat
    console.log(!value)
    console.log(this.state.profiledata.attributes.disable_chat)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/disable_enable_chat?disable_chat=${!value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  redirectToDashboard = () => {
    let userType = localStorage.getItem('userType')
    if (userType == 'Owner') {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/OwnerDashboard')
    } else {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/residentDashboard')
    }

  }
  handleChangeCCode = (e: any) => {
    console.log(e)
    if (e) {
      // @ts-ignore
      // @ts-nocheck
      this.setState({ selectCode: `+${e}` })
    }
  }


  // Customizable Area End
}
