//Customizable Area Start

import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  Select,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Drawer,
} from "@material-ui/core";
import COOIncidentDetailsController from "./COOIncidentDetailsController.web";
import IncidentChatWeb from "../../../customform/src/IncidentChat.web";

import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import { withRouter, Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import "../../../dashboard/src/Dashboard.web.css";
import { withTranslation, useTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import AlertErrorWeb from "../../../../components/src/AlertError.web";
import {  Tick_Circle_Icon,  } from "../../src/assets";
import MapDialog from "../../../../components/src/MuiDialog/MapDialog.web";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
export const  configJSONBase= require("../../../../framework/src/config");

class COOIncidentDetails extends COOIncidentDetailsController {
  render() {
    const { t }: any = this.props;
    const match: any = this.props.match;
    
    const id = match?.params.id;
   


    const statusArray = ["Unresolved", "Resolved", "Ongoing"];
    return (
      <>
        {this.renderMapmodal()}
        <MainScreen>
          <Box className="incident-Listing-wrapper">
            <Grid
              xs={12}
              md={12}
              sm={12}
              spacing={4}
              className="incidentManagementDetails"
              style={{ paddingTop: 35, minHeight: "90vh" }}
            >
              <Container>
                <Box style={dashBoard.navigation}>
                  <Box>
                    <Box>
                      <Typography variant="body1">
                        <Link to={""} color="inherit">
                          {t("Activities")}
                        </Link>{" "}
                        /{" "}
                        <Link
                          to={"/CompanyEmployee/cooincidentListing"}
                          color="inherit"
                        >
                          {t("Incident Management")}
                        </Link>{" "}
                        /{" "}
                        <Link to={""} color="inherit">
                          <Box component="span" style={{ color: "#2c6fed" }}>
                            {t("Incident Details")}
                          </Box>
                        </Link>
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      className="bold-text"
                      style={dashBoard.subHeading}
                    >
                      {t("Incident Details")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="content-block-wrapper incident-detail-card-block">
                  <Card className="incident-detail-card card">
                    <Box className="card-header">
                      <Typography component="h3" className="bold-text" style={{fontSize:"22px"}}>
                        {this.state.incidentDetails?.attributes?.incident_title}
                      </Typography>
                    
                      <SelectStatus
                        this={this}
                        statusArray={statusArray}
                        t={t}
                        userRole={this.state.userRole}
                        statusDetail={this.state.statusDetail}
                        statusShowDialog={this.state.statusShowDialog}
                      ></SelectStatus>
                    </Box>
                    <CardContent className="card-content">
                      <Box className="row-block">
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Affected Area")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.incident_title
                            }
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography  component="span" >
                            {t("Incident is related to")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.incident_related?.name
                            }
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Incident Number")} :
                          </Typography>
                          <h4 className="bold-text">
                            {this.state.incidentDetails?.id}
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Complex")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes?.complex
                                ?.name
                            }
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={styles.buildingNameMainBox}
                        >
                          <Box style={styles.buildingNameBox}>
                            <Typography className="title-span" component="span">
                              {t("Building")} :
                            </Typography>
                            <h4 className="bold-text">
                              {
                                this.state.incidentDetails?.attributes
                                  ?.building_management?.name
                              }
                            </h4>
                          </Box>
                          <Box style={styles.locationBox}>
                            <LocationOnOutlinedIcon style={styles.locationIcon} />
                            <Typography style={styles.seeBuildingText} onClick={()=>this.handleOpenmapModal()} id={"viewBulidingmap"}>{t("See building on map")}</Typography>
                        </Box>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Unit")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.apartment_management.apartment_name
                            }
                          </h4>
                        </Box>
                       
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Acknowledge by Manager")}:
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.acknoledged_by_manager
                            }
                          </h4>
                          {/* <Aknowledgement attributes={attributes}/> */}
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography  component="span">
                            {t("Latest update from management")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.last_update_from_management
                            }
                          </h4>
                          {/* <LastStatus attributes={attributes} /> */}
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Description")} :
                          </Typography>
                          <h4 className="bold-text">
                            {
                              this.state.incidentDetails?.attributes
                                ?.description
                            }
                          </h4>
                        </Box>
                      </Box>
                      {
                        <>
                          <Box
                            className="card-rows"
                            style={{
                              borderBottom: "1px solid #f4f6fb",
                              paddingBottom: "0.5rem",
                              marginTop: "0",
                            }}
                          >
                            <Typography className="title-span" component="span">
                              {t("Photos")} :
                            </Typography>
                          </Box>
                          <Box
                            className="card-rows"
                            style={{
                              borderBottom: "1px solid #f4f6fb",
                              paddingBottom: "0.5rem",
                              marginTop: "0",
                            }}
                          >
                            {this.state.incidentDetails?.attributes?.attachments?.map(
                              (val: any, index: any) => (
                                <>
                                  <Box style={{ padding: "0px 10px" }}>
                                    <Box className="img-layer">
                                      <img
                                        src={`${configJSONBase.baseURL+val.url}`}
                                        className="card-img"
                                        alt="card-img"
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </>
                              )
                            )}
                          </Box>
                        </>
                      }
                    </CardContent>
                  </Card>
                </Box>

                <Box
                  className="incident-button-row customButton"
                  style={{ padding: "30px 0" }}
                >
                  {(this.state.incidentDetails?.attributes?.incident_status ===
                    "Unresolved" ||
                    this.state.incidentDetails?.attributes?.incident_status ===
                      "Ongoing") && (
                    <>
                      {this.state.incidentDetails?.attributes?.assign_incidents
                        ?.data === null ? (
                        <Button
                          variant="outlined"
                          style={{ borderRadius: "5px",backgroundColor:"white" }}
                          id="openDialog"
                          onClick={this.handleProvider}
                        >
                          {t("Assign incident to provider")}
                        </Button>
                      ) : (
                        <Box className="user-btn-box" style={{backgroundColor:"white"}}>
                          <h6
                            className="user-title bold-text"
                            style={{ color: "#181d25", }}
                          >
                            {
                              this.state.incidentDetails?.attributes
                                ?.assign_incidents?.data?.attributes?.provider
                                ?.full_name
                            }
                          </h6>
                          <Button
                            className="change-btn bold-text"
                            
                            style={{ color: "#3769fc" }}
                            onClick={this.handleProvider}
                            id="changeIncident"
                          >
                            {t("change")}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                  <Button
                    variant="contained"
                    style={{ borderRadius: "8px" }}
                    onClick={() => this.createChatRoom()}
                    id="openchatbox"
                  >
                    {t("start/view ticket conversation")}
                  </Button>
                </Box>
              </Container>
              {/* chat */}
              <Dialog
                className="add-faq-dialog"
                onClose={() => this.setState({ showDialog: false })}
                open={this.state.showDialog}
                fullWidth
                id="closeModal"
              >
                <MuiDialogTitle disableTypography className="dialog-heading">
                  <Typography variant="h6" className="bold-text">
                    Assign Incident to Provider
                  </Typography>
                  <IconButton
                    onClick={this.closeAssignDialog}
                    id="assignProvider"
                  >
                    <CloseIcon />
                  </IconButton>
                </MuiDialogTitle>
                <DialogContent dividers>
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <select
                      className="dialog-select-input"
                      name="providerWork"
                      placeholder="Provider"
                      id="providerName-list"
                      onChange={(e: any) => {
                        this.setState(
                          { assignProvider: e.target.value },
                          () => {
                            this.getProviderName(
                              this.state.incidentDetails?.attributes
                                ?.building_management.society_management_id,
                              this.state.assignProvider
                            );
                          }
                        );
                      }}
                      value={this.state.assignProvider}
                    >
                      <option aria-label="None" value="" disabled selected>
                        Provider
                      </option>
                      {this.state?.providerList?.map((val: any, index: any) => (
                        <option key={index} value={`${val.id}`}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <FormControl fullWidth>
                    <select
                      className="dialog-select-input"
                      name="providerName"
                      // onChange={(e) => {
                      //   const { value } = e.target;
                      //   this.setState({
                      //     assignProviderName: value,
                      //     selectProviderError: "",
                      //   }, this.getProviderInfo);
                      // }}
                      onChange={(e: any) => {
                        this.setState(
                          {  assignProviderName: e.target.value,
                            selectProviderError: "", },
                          () => {
                            this.getProviderInfo();   
                            
                          }
                        );
                      }}
                      // onChange={(e: any) => {
                      //   this.setState({
                      //     assignProviderName: e.target.value,
                      //     selectProviderError: "",
                      //   },()=>this.getProviderInfo());
                      // }}
                      value={this.state.assignProviderName}
                      id="providerName1"
                    >
                      <option aria-label="None" value="" disabled selected>
                        Provider Name
                      </option>
                      {this.state?.assignNameList?.map(
                        (val: any, index: any) => (
                          <option key={index} value={val?.id}>
                            {val?.full_name}
                          </option>
                        )
                      )}
                    </select>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    {
                      this.state.cardValue? (
                      <Box>
                       
                        <Card>
                          <CardContent>
                           
                            <Typography  className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}>
                              {this.state.providerInfo?.attributes?.full_name}
                            </Typography>
                            <div style={{display:"flex"}}>
                            <Typography >
                              Email Address:
                              </Typography>
                              <Typography  className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}> {this.state.providerInfo?.attributes?.email}</Typography>
                              </div>
                           
                              <div style={{display:"flex"}}>
                              <Typography>
                              {" "}
                              Phone number:
                              </Typography>
                              <Typography  className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}>
                              {this.state.providerInfo?.attributes?.full_phone_number}
                              </Typography>
                              </div>
                            
                           <div style={{display:"flex"}}>
                           <Typography>
                              Gender:
                            </Typography>
                            <Typography  className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}>
                            {this.state.providerInfo?.attributes?.gender}
                            </Typography>
                           </div>
                             
                           
                           
                          </CardContent>
                        </Card>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                </DialogContent>
                <DialogActions className="dialog-button-group">
                  <Button
                    className="cancel-button"
                    onClick={this.closeAssignDialog}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="add-button bold-text"
                    id="assignedIncident1"
                    onClick={() => {
                      if (this.state.assignProvider) {
                        if (
                          this.state.incidentDetails.attributes
                            ?.assign_incidents?.data === null
                        ) {
                          this.assignIncidentToProviderList();
                        } else {
                          this.updateProviderDetails(
                            this.state.incidentDetails?.attributes
                              ?.assign_incidents?.data?.id
                          );
                        }
                      } else {
                        // this.setState({selectProviderError:"Please Select Provider"})
                      }
                    }}
                  >
                    {t("Assign Incident")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
              id="updateOpen"
                fullWidth
                onClose={() => this.setState({ statusShowDialog: false, statusDetail: this.state.incidentDetails?.attributes?.incident_status })}
                open={this.state?.statusShowDialog}
                className="cancel-meeting-dialog"
                
              >
                <DialogContent style={{ margin: "15px 0" }}>
                  <Box textAlign="center">
                    <img className="comment-image"  src={Tick_Circle_Icon} alt="comment" />
                    <Typography variant="h6" className="bold-text">
                      {t("Update Status")}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "0px" }}>
                      {t("Are you sure you want to change the status to")} {this.state?.statusDetail}?
                    </Typography>
                    <br />
                    <DialogActions className="dialog-button-group">
                      <Button
                      id="closeBtn"
                        className="cancel-button"
                        style={{ width: "200px", height: "46px" }}
                        onClick={() => { this.setState({ statusShowDialog: false, statusDetail: this.state.incidentDetails.attributes?.incident_status }); }}
                      >
                        {t("Close")}
                      </Button>
                      <Button
                      id="updateStatus"
                        style={{ width: "200px", height: "46px" }}
                        className="add-button"
                        onClick={() => this.updateStatus(this.state?.statusDetail)}
                      >
                        {t("Confirm")}
                      </Button>
                    </DialogActions>
                  </Box>
                </DialogContent>
              </Dialog>
              <Loader loading={this.state.loading} />
            </Grid>
          </Box>
          <Loader loading={this.state.loading} />
          <Drawer
            anchor="right"
            open={this.state.chatDrawer}
            onClose={() => this.setState({ chatDrawer: false })}
            id="errModal"
          >
            {/* @ts-ignore */}
            <IncidentChatWeb onClose={() => this.setState({ chatDrawer: false })} id="chatroom"/>
           
          </Drawer>
          <AlertErrorWeb
              show={this.state.showError}
              handleClose={() =>
                this.setState({ showError: false, error: null })
              }
              message={this.state.error}
            />
        </MainScreen>
      </>
    );
  }
  renderMapmodal=()=>{
    return(
        <MapDialog
        t={this.props.t}
        IsOpen={this.state.openBuildingModal}
        CloseDialog={this.handleCloseMap}
        lat={this.state.latitude}
        long={this.state.longitude}
        id={"viewBuildingModal"}
    />
    )
}
}

const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    // fontWeight: 600,
    marginTop: 15,
    fontSize:"32px"
  },
  detailsHead:{
    textTransform:"none"
  }
};
export const SelectStatus = (props: any) => {
  const { t } = useTranslation();

 
  const checkStatusCl = () => {
    if (
      props?.this.state?.incidentDetails?.attributes?.incident_status ===
      "Unresolved"
    ) {
      return "incident-details-unresolved";
    } else if (
      props?.this.state?.incidentDetails?.attributes?.incident_status ===
      "Pending Confirmation"
    ) {
      return "incident-details-pending";
    } else if (
      props?.this.state?.incidentDetails?.attributes?.incident_status ===
      "Ongoing"
    ) {
      return "incident-details-ongoing";
    } else {
      return "incident-details-resolved";
    }
  };

  const isClosedOrPendingConfirmation =
  props?.this.state.incidentDetails?.attributes?.incident_status === "Closed" ||
  props?.this.state.incidentDetails?.attributes?.incident_status ===
    "Pending Confirmation";
  return (
    <>
    
 
      <Box className={checkStatusCl()}> 
       
        <FormControl variant="outlined">
         
          {props?.this.state.incidentDetails?.attributes?.incident_status ==
            "Closed" 
           ? (
            <Box
              style={{ padding: "10px 60px 10px 14px", borderRadius: "14px" }}
            >
               
              {t("Closed")}
            </Box>
          ) : (
           
            
            <Box
                className="customButton"
                style={{ paddingBottom: "0", borderRadius: "0px" }}
              >
               
                
                <Button
                  style={{ borderRadius: "0px", textTransform: "none" }}
                  variant="contained"
                  className={checkStatusCl()}
                  type="submit"
                  id="selectStatus1"
                >
                  {
                    props?.this.state.incidentDetails?.attributes
                      ?.incident_status
                  }
                </Button>
              </Box>   
          )}
        </FormControl> 
       </Box>
    </>
  );
};
export default withTranslation()(withRouter(COOIncidentDetails));

const styles = {
  buildingNameMainBox:{
    borderBottom: "1px solid #f4f6fb",
    paddingBottom: "0.5rem",
    marginTop: "0",
    justifyContent:'space-between',
    alignItems:'center',
  },
  buildingNameBox:{
    display:'flex',
    alignItems:'center'
  },
  locationBox:{
    display: "flex",
    gap: "4px",
    alignItems: "center",
    marginRight:'25px'
  },
  locationIcon:{
    color: "#fc8434",
    height: "22px",
  },
    seeBuildingText:{ 
      color: "#fc8434",
      fontWeight:900,
      cursor:'pointer'
    }
}

//Customizable Area End
