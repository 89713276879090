// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Select,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  CardContent,
  Card,
  CardActions,
  Modal,
  TextField,
  InputAdornment,
  withStyles,
  FormLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ViewJurisdictionController, { Props } from "./ViewJurisdictionController.web";
import SearchIcon from "@material-ui/icons/Search";
import { Form, Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import "./jurisdiction.css";
import { Menu as ReactMenu } from "@szhsin/react-menu";
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import AlertErrorWeb from "../../../../components/src/AlertError.web";
import { t } from "i18next";
import { TabsComponent } from "../../../../components/src/TabsComponent";
import MapDialog from "../../../../components/src/MuiDialog/MapDialog.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { desigenImg } from "../../../customform/src/assets";
import { TickIcon } from "../assets";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

export class ViewJurisdiction extends ViewJurisdictionController {
  constructor(props: Props) {
    super(props);
  }

  renderMap = () => {
    return (
      <MapDialog
        t={this.props.t}
        IsOpen={this.state.viewLocationstate}
        CloseDialog={this.handleViewlocation}
        lat={this.state.complexLan}
        long={this.state.complexLong}
        data-testId={"viewBuildingModal"}
      />
    );
  };

  handleDetails = () => (
    <Menu
      id="simple-menu1"
      anchorEl={this.state.isDetailsMenu}
      keepMounted
      open={this.state.isDetailsMenu != null ? true : false}
      onClose={this.handleDetailsMenuClose}
      className="menu-field closedetails"
    >
      <MenuItem className="menu-field" onClick={this.handleViewlocation} id="viewBuildingModalmap">
        {t("View location on Map")}
      </MenuItem>
      {this.renderMap()}
      <Link to={`/CompanyEmployee/ComplexDetails/${this.state.switchId}`}>
        <MenuItem className="menu-field">{t("Go to Complex Page")}</MenuItem>
      </Link>
      <MenuItem
        className="menu-field changeJuri"
        onClick={(e) => {
          this.setState({ changejurisdictionModal: true }, () => {
            this.changeJurisdiction();
          });
        }}
        id="change_jur"
      >
        {t("Change Jurisdiction")}
      </MenuItem>
    </Menu>
  );

  tableData = () => (
    <>
      <div style={{ overflowX: "scroll" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="maintablehead">
            <TableRow>
              <TableCell className="tableheader">{t("Complex Name")}</TableCell>
              <TableCell className="tableheader">{t("Account Manager")}</TableCell>
              <TableCell className="tableheader">{t("Total Buildings")}</TableCell>
              <TableCell className="tableheader">{t("Total Units")}</TableCell>
              <TableCell className="tableheader"> {t("Subscription Ends in")}</TableCell>
              <TableCell className="tableheader">{t("Subscription Type")}</TableCell>
              <TableCell className="tableheader">{t("Paying Month")}</TableCell>
              <TableCell className="tableheader  optiontable" />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.complexList?.length > 0 ? (
              this.state.complexList?.map((item: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className="tablecell">{item.attributes.name}</TableCell>
                      <TableCell className="tablecell">{item.attributes.name}</TableCell>
                      <TableCell className="tablecell">{item.attributes.total_buildings}</TableCell>
                      <TableCell className="tablecell">{item.attributes.total_units[0]}</TableCell>
                      <TableCell className="tablecell">{item.attributes.subscription_end_in}</TableCell>
                      <TableCell className="tablecell">{item.attributes.subscription_type}</TableCell>
                      <TableCell className="tablecell">{item.attributes.paying_month}</TableCell>

                      <TableCell className="tablecell">
                        <MoreVertIcon
                          onClick={(e) => {
                            this.setState({ switchId: item.id });
                            this.handleMapvalues(item);
                            this.handleVertMore(e);
                          }}
                          id="moreDetails"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={6} style={webStyle.noData}>
                    {t("There are no complexes added in the jurisdiction")}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );

  viewHeader = (t: any, classes: any) => (
    <Box style={{ padding: "40px", paddingRight: "40px" }}>
      <div className="viewheading-data">
        <Typography className="subhead">
          <Link to={""} color="inherit">
            {t("My Dashboards")}/
          </Link>
          <Link to="/CompanyEmployee/jurisdication" color="inherit">
            {t("Manage Jurisdiction/")}
          </Link>
          <Link to={""} className="minhead" style={{ color: "#2c6fed" }}>
            {t("Manage Jurisdiction")}
          </Link>
        </Typography>
      </div>

      <br />
      <div className="main-head">
        <Typography
          variant="h5"
          className="sub-heading bold-text reports-BudgetReportmain"
          style={{ fontSize: "28px", display: "flex", whiteSpace: "nowrap" }}
        >
          {t("Jurisdiction Details")}
        </Typography>

        <div className="add-complex">
          <div>
            <div>
              <Box className="sorting-header ">
                <Box className="formGroup customSelect">
                  <FormControl variant="outlined">
                    <Select
                      displayEmpty
                      className="select-input budgetReportStatusListSelect changeJurS"
                      value={this.state.changeId}
                      onChange={(e) => {
                        this.setState({ changeId: e.target?.value }, () => {
                          this.changeJurisdictionById(e.target.value);
                        });
                      }}
                      id="filterCountry"
                    >
                      <MenuItem value="" disabled id="selectStatusBudgetReport">
                        {this.state.countryDisplay}({this.state?.detailsData?.jurisdiction_id})
                      </MenuItem>
                      <MenuItem value=" " id="selectStatusBudgetReportAll">
                        {t("All")}
                      </MenuItem>
                      {this.state.jurisdictionList?.length &&
                        this.state.jurisdictionList?.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item.id}>
                              {item.attributes.jurisdiction_name}({item.attributes.jurisdiction_id})
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </div>
          </div>
          <div>
            <Button style={webStyle.addComplexBtn} onClick={this.handleAddComplex} id="addBuilding">
              {t("ADD NEW COMPLEX/BUILDING")}
            </Button>
          </div>
        </div>
      </div>

      <Card className="card-jurisdiction">
        <CardActions className="card_sub">
          <Typography className="mainheading">
            {this.state.detailsData?.jurisdiction_name.length ? (
              <>{`${this.state.detailsData?.jurisdiction_name}(${this.state.detailsData.jurisdiction_id})`}</>
            ) : (
              "NA"
            )}
          </Typography>
          <div>
            <Button
              className="delete-btn-view delete-btn"
              onClick={(e) => {
                this.setState({ confirmModal: true, deleteId: e });
              }}
            >
              {t("DELETE")}
            </Button>
            <Button className="edit-btn edit-btn-jur" onClick={this.handleEdit} style={{ borderRadius: "10px" }}>
              {t("EDIT JURISDICTION")}
            </Button>
          </div>
        </CardActions>

        <Box style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Currency")}</Typography>
              <Typography className="view-subvalue">
                {this.state.detailsData?.currency.length ? this.state.detailsData?.currency.length : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Timezone")}</Typography>
              <Typography className="view-subvalue">
                {this.state.detailsData?.timezone.length ? this.state.detailsData?.timezone : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Unit of Measurement")}</Typography>
              <Typography className="view-subvalue">
                {this.state.detailsData?.unit_of_measurement.length
                  ? this.state.detailsData?.unit_of_measurement
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Management Fee calculation Formula")}</Typography>
              <Typography className="view-subvalue">
                {this.state.detailsData?.formula.length ? this.state.detailsData?.formula : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Mobile number length")}</Typography>
              <Typography className="view-subvalue">
                {this.state.detailsData?.mobile_number_length.length
                  ? this.state.detailsData?.mobile_number_length
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="view-heading">{t("Default Renting Contract")}</Typography>
              <Typography className="view-subvalue">NA</Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box style={{ marginTop: "25px", marginBottom: "25px" }}>
        <TabsComponent
          tabNo={this.state.tabNo}
          handleChange={this.handleTabChange}
          tabs={[
            {
              label: t("Complex"),
            },
            {
              label: t("Individual Buildings"),
              testId: "tabcheck",
            },
          ]}
        />
      </Box>
      <div className={classes.reportList}>
        <Box className="top-bar ChairmanInvoices" id="ChairmanInvoices">
          <Box className="filter" id="ChairmanInvoices" style={{ display: "flex", flexWrap: "wrap" }}>
            <Select
              displayEmpty
              className="select-input selectBuildingForChairmanInvoices countryFilter"
              value={this.state.country}
              onChange={(e) => {
                this.setState({ country: e.target.value }, () => this.getCitiesList(e.target.value));
              }}
              id="filterCountry"
            >
              <MenuItem value="" disabled id="selectStatusBudgetReport">
                {t("Country")}
              </MenuItem>
              <MenuItem value="all" id="selectStatusBudgetReportAll">
                {t("All")}
              </MenuItem>
              {this.state.countryListdata.length &&
                this.state.countryListdata.map((item: any) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              displayEmpty
              className="select-input selectBuildingForChairmanInvoices "
              value={this.state.region}
              onChange={(e) => this.setState({ region: e.target.value })}
              id="regionFilter"
            >
              <MenuItem value="" disabled id="selectStatusBudgetReport">
                {t("Region")}
              </MenuItem>
              <MenuItem value="all" id="selectStatusBudgetReportAll">
                {t("All")}
              </MenuItem>
              {this.state.regionList.region_city_list?.length &&
                this.state.regionList.region_city_list.map((item: any) => {
                  return (
                    <MenuItem key={item} value={item.region}>
                      {item.region}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              displayEmpty
              className="select-input selectBuildingForChairmanInvoices countryFilter"
              value={this.state.city}
              onChange={(e) => this.setState({ city: e.target.value })}
              id="cityFilter"
            >
              <MenuItem value="" disabled id="selectStatusBudgetReport">
                {t("City")}
              </MenuItem>
              <MenuItem value=" " id="selectStatusBudgetReportAll">
                {t("All")}
              </MenuItem>
              {this.state.cityList.region_city_list?.length &&
                this.state.cityList.region_city_list.map((item: any) => {
                  return (
                    <MenuItem key={item} value={item.city}>
                      {item.city}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              displayEmpty
              className="select-input selectBuildingForChairmanInvoices countryFilter"
              value={this.state.status}
              onChange={(e) => this.setState({ status: e.target.value })}
              id="statusFilter"
            >
              <MenuItem value="" disabled id="selectStatusBudgetReport">
                {t("Status")}
              </MenuItem>
              <MenuItem value=" " id="selectStatusBudgetReportAll">
                {t("All")}
              </MenuItem>
              {this.state.statusList.length &&
                this.state.statusList.map((item: any) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              displayEmpty
              className="select-input selectBuildingForChairmanInvoices countryFilter"
              value={this.state.accountManager}
              onChange={(e) => this.setState({ accountManager: e.target.value })}
              id="amFilter"
            >
              <MenuItem value="" disabled id="selectStatusBudgetReport">
                {t("Account Manager")}
              </MenuItem>
              <MenuItem value=" " id="selectStatusBudgetReportAll">
                {t("All")}
              </MenuItem>
              {this.state.amList.length &&
                this.state.amList.map((item: any) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
            <Button
              className="handleSearchFilterForInvoices"
              startIcon={<SearchIcon />}
              onClick={this.handleFilterSubmit}
              id="searchFilter"
            >
              {t("Search")}
            </Button>
          </Box>
        </Box>
      </div>

      {this.state.complexList?.length ? (
        <Box className="view-details">
          <Card>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                  alignItems: "center",
                }}
              >
                <h3 className="bold-text reports-BudgetReportmain" style={{ fontSize: "22px" }}>
                  {t("List of Complex")}
                </h3>

                <TextField
                  placeholder={t("Search by complex name")}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className="text-jur test-search-jur"
                  style={{ border: "1px solid lightgray" }}
                  id="searchValue"
                  onKeyUp={this.handleSearchKeyUp}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
            {this.tableData()}
            <div className="pagination">
              <PaginationModule
                pagination={this.state.pagination}
                handlePagination={this.handlePagination}
                page={this.state.page}
              />
            </div>
          </Card>
        </Box>
      ) : (
        <>
          <Card
            style={{
              height: "300px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CardContent>
              <Typography> {t("There are no complexes added in the jurisdiction")} </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );

  buildingBody = (t: any) => (
    <div>
      <Formik
        validateOnBlur={false}
        initialValues={{
          complex: "",
        }}
        onSubmit={(values: Record<string, string>) => {}}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <Box className="modal-content">
              <Box className="headingData">
                <Typography className="mainheading">{t("Add New Complex/Building")}</Typography>
                <CloseIcon onClick={this.handleBuildingClose} className="closeicon closeB" />
              </Box>
              <Divider />
              <Box>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid item xs={6}>
                    <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                      {t("Country")}
                    </FormLabel>
                    <Select
                      fullWidth
                      id="task-type-select1"
                      variant="outlined"
                      displayEmpty
                      style={{
                        border: "1px solid #ECECEC",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                        marginRight: "10px",
                      }}
                      value={this.state.complex_countryselected}
                      className="select-input inputForAssinged timeSelect countryBuilding task-type-select1"
                      placeholder="Select Country"
                      onChange={(e: any) => {
                        this.setState({ complex_countryselected: e.target.value }, () =>
                          this.getCitiesList(e.target.value)
                        );
                      }}
                    >
                      <MenuItem value="" disabled id="selectStatusBudgetReport ">
                        {t("Country")}
                      </MenuItem>
                      {this.state.countryListdata.length &&
                        this.state.countryListdata.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                      {t("City")}
                    </FormLabel>
                    <Select
                      fullWidth
                      id="task-type-select1"
                      variant="outlined"
                      displayEmpty
                      value={this.state.complex_cityselected}
                      style={{
                        border: "1px solid #ECECEC",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                        marginRight: "10px",
                      }}
                      className="select-input inputForAssinged unitSelect"
                      onChange={(e: any) => {
                        this.setState({ complex_cityselected: e.target.value }, () => {
                          this.handleSelectComplex();
                        });
                      }}
                    >
                      <MenuItem value="" disabled>
                        {t("Select City")}
                      </MenuItem>
                      {this.state.cityList.region_city_list?.length &&
                        this.state.cityList.region_city_list.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item.city}>
                              {item.city}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                    {t("Select Complex")}
                  </FormLabel>
                  <Select
                    fullWidth
                    id="task-type-select1"
                    variant="outlined"
                    displayEmpty
                    style={{
                      border: "1px solid #ECECEC",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                      marginRight: "10px",
                    }}
                    value={this.state.complex_complexSelected}
                    className="select-input inputForAssinged timeSelect complexSelect"
                    onChange={(e: any) => this.setState({ complex_complexSelected: e.target.value })}
                  >
                    <MenuItem value="" disabled>
                      {t("Select Complex")}
                    </MenuItem>
                    {this.state.complexBuildingList?.length &&
                      this.state.complexBuildingList.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item.id}>
                            {item.attributes.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Box>
              <div className="adjustBtn">
                <Button onClick={this.handleBuildingClose} className="btn-cancel">
                  {t("CANCEL")}
                </Button>
                <Button className="btn-add" onClick={this.handleAddBuilding} id="addBuild">
                  {t("ADD")}
                </Button>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );

  changeJurisdictionBody = () => (
    <div>
      <form>
        <Box overflow={"scroll"} className="modal-content">
          <Box className="headingData">
            <Typography className="mainheading">{t("Change Jurisdiction")}</Typography>
            <CloseIcon
              id="closeIcon"
              onClick={() => this.setState({ changejurisdictionModal: false, newDetailsData: null, complex: "" })}
            />
          </Box>
          <Divider />
          <Box className="box-div-modal" style={{ paddingRight: "25px" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                    {t("Select Complex")}
                  </FormLabel>

                  <Select
                    fullWidth
                    id="task-type-select1"
                    variant="outlined"
                    disabled
                    style={{
                      border: "1px solid #ECECEC",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                      marginRight: "10px",
                    }}
                    placeholder="Select Complex"
                    value={this.state.complexById}
                    onChange={(e: any) => this.setState({ complex: e.target.value })}
                    className="select-input inputForAssinged timeSelect  complex_body countryBuilding task-type-select1"
                  >
                    <MenuItem value={this.state.complexById} disabled id="selectStatusBudgetReport ">
                      {this.state.complexById}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                    {t("Current Jurisdiction")}
                  </FormLabel>

                  <TextField
                    label={t("Jurisdiction Name")}
                    fullWidth
                    id="task-type-select1"
                    variant="outlined"
                    style={{
                      border: "1px solid #ECECEC",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                      marginRight: "10px",
                    }}
                    name="jname"
                    value={this.state.detailsData?.jurisdiction_name}
                    className="firstname add-valueJuri"
                    onChange={(e: any) => {
                      this.setState({ newJur: e.target.value.name, newJurId: e.target.value.id }, () => {
                        this.getNewJurisdictionById(e.target.value.id);
                      });
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormLabel component="legend" className="addTeamModal jur-text" style={webStyle.labelsStyle}>
                    {t("New Jurisdiction")}
                  </FormLabel>
                  <Select
                    fullWidth
                    id="task-type-select1"
                    variant="outlined"
                    displayEmpty
                    style={{
                      border: "1px solid #ECECEC",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                      marginRight: "10px",
                    }}
                    placeholder="New jurisdiction"
                    onChange={(e: any) => {
                      this.setState({ newJurId: e.target.value }, () => {
                        this.getNewJurisdictionById(e.target.value.id);
                      });
                    }}
                    value={this.state.newJurId}
                    className="select-input inputForAssinged timeSelect new_jur1 countryBuilding task-type-select1"
                  >
                    <MenuItem value="" disabled id="selectStatusBudgetReport ">
                      {t("Select New Jurisdiction")}
                    </MenuItem>
                    {this.state.jurisdictionList.length > 0 &&
                      this.state.jurisdictionList.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item.id}>
                            {item.attributes.jurisdiction_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Curreny")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.detailsData?.currency.length ? this.state.detailsData?.currency.length : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Currency")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.newDetailsData?.currency.length ? this.state.newDetailsData?.currency.length : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Timezone")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.detailsData?.timezone.length ? this.state.detailsData?.timezone : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Timezone")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.newDetailsData?.timezone.length ? this.state.newDetailsData?.timezone : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Unit of Measurement")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.detailsData?.unit_of_measurement.length
                      ? this.state.detailsData?.unit_of_measurement
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Unit of Measurement")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.newDetailsData?.unit_of_measurement.length
                      ? this.state.newDetailsData?.unit_of_measurement
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Management Fee Calculation Formula")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.detailsData?.formula.length ? this.state.detailsData?.formula : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Management Fee Calculation Formula")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.newDetailsData?.formula.length ? this.state.newDetailsData?.formula : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Mobile No length")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.detailsData?.mobile_number_length.length
                      ? this.state.detailsData.mobile_number_length
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Mobile No length")}</Typography>
                  <Typography className="inputLabel1">
                    {this.state.newDetailsData?.mobile_number_length.length
                      ? this.state.newDetailsData.mobile_number_length
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Deafult Renting Contract")}</Typography>
                  <Typography className="inputLabel1">rentdocument.pdf</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Deafult Renting Contract")}</Typography>
                  <Typography className="inputLabel1">rentdocument_1.pdf</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="adjustBtn">
            <Button
              onClick={() => this.setState({ changejurisdictionModal: false, newDetailsData: null, complex: "" })}
              className="btn-cancel"
            >
              {t("CANCEL")}
            </Button>
            <Button className="btn-add" onClick={() => this.complexChangeJurisdiction()} id="Change_val">
              {t("ADD")}
            </Button>
          </div>
        </Box>
      </form>
    </div>
  );

  static WrrappedComponent: any;
  render() {
    const { t, classes }: any = this.props;

    return (
      <>
        <Dialog maxWidth="md" open={this.state.editModal} scroll="paper" fullWidth>
          <DialogHeader t={t} title="Edit Jurisdiction" onClose={this.handleModalClose} />
          <Formik
            validationSchema={this.validation}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, editModal: false }, () => {
                this.editJurisdiction(values);
                resetForm();
              });
            }}
            initialValues={this.state.editFormData}
            enableReinitialize={true}
          >
            {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid spacing={2} container>
                      <Grid item xs={6}>
                        <NormalTextField
                          id="name"
                          t={t}
                          isLabel={true}
                          label="Jurisdiction Name"
                          {...getFieldProps("name")}
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          id="edit_country"
                          t={t}
                          label="Country"
                          option={this.state.countryListdata.map((item: any) => ({
                            label: item.attributes.name,
                            value: item.attributes.name,
                          }))}
                          {...getFieldProps("country")}
                          onChange={(event: any) => {
                            const value = event.target.value;
                            setFieldValue("country", value);
                            this.getTimezoneListData(value);
                          }}
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Currency"
                          option={this.state.currencyList.map((item: any) => ({
                            label: item.attributes.currency,
                            value: item.id,
                          }))}
                          {...getFieldProps("currency")}
                        />
                        {ErrorHandler(t, touched.currency, errors.currency)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Timezone"
                          option={this.state.timezoneList.map((item: any) => ({ label: item.zone, value: item.zone }))}
                          disabled={!values.country}
                          {...getFieldProps("timezone")}
                        />
                        {ErrorHandler(t, touched.timezone, errors.timezone)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Unit of Measurement"
                          option={[
                            { label: "Sq ft", value: "Sqft" },
                            { label: "Sq m", value: "Sqm" },
                          ]}
                          {...getFieldProps("measurement")}
                        />
                        {ErrorHandler(t, touched.measurement, errors.measurement)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          {...getFieldProps("mobile")}
                          t={t}
                          option={[
                            { label: "9", value: "9" },
                            { label: "10", value: "10" },
                            { label: "11", value: "11" },
                          ]}
                          label="Mobile Number Length"
                        />
                        {ErrorHandler(t, touched.mobile, errors.mobile)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField isLabel={true} t={t} label="Value added tax" {...getFieldProps("tax")} />
                        {ErrorHandler(t, touched.tax, errors.tax)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          isLabel
                          t={t}
                          {...getFieldProps("zone")}
                          label="Zone multipler"
                          type="number"
                        />
                        {ErrorHandler(t, touched.zone, errors.zone)}
                      </Grid>
                      <Grid xs={12} item>
                        <NormalSelect
                          label="Formula"
                          {...getFieldProps("formula")}
                          option={[
                            {
                              value: "Total building budget + contingency / area_of_complex * unit_size",
                              label: "Total building budget + contingency / area_of_complex * unit_size",
                            },
                            { label: "unit_size * zone_multiplier", value: "unit_size * zone_multiplier" },
                          ]}
                          t={t}
                        />
                        {ErrorHandler(t, touched.formula, errors.formula)}
                      </Grid>
                      <Grid xs={12} item>
                        <Box className="add-meeting edit-jurisdiction-contract-box">
                          <Box className="create-audience" style={{ margin: 0 }}>
                            <p className="edit-contract-title">{t("Default Renting Contract")}</p>
                            <span className="edit-contract-add-title">{t("+ Add New Template")}</span>
                          </Box>
                          <Box style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                            {this.state.contractList.map((lease: any, index: number) => {
                              return (
                                <Box
                                  className="contract-box-item"
                                  onClick={() => this.handleSelectedTemplate(lease.id)}
                                  key={index}
                                  style={{ cursor: "pointer", position: "relative" }}
                                >
                                  <Box sx={webStyle.editOrange} className="contract-menu-item">
                                    <ReactMenu menuButton={<MoreVertIcon />}>
                                      <MenuItem className="edit-group-menu">{t("Edit")}</MenuItem>
                                      <MenuItem className="delete-group-menu">{t("Delete")}</MenuItem>
                                    </ReactMenu>
                                  </Box>
                                  <Box
                                    className="contract-content-box"
                                    sx={
                                      Number(this.state.contractSelected) === Number(lease.id)
                                        ? webStyle.rentSelected
                                        : webStyle.rentStyle
                                    }
                                  >
                                    <Box className="leaseTemplate " style={webStyle.rentImageContainer}>
                                      <img src={desigenImg} className="default-contract-img" alt="" />
                                    </Box>
                                    <Box
                                      sx={
                                        Number(this.state.contractSelected) === Number(lease.id)
                                          ? webStyle.rentSelectedTitle
                                          : webStyle.rentTitleStyle
                                      }
                                      className="contract-title"
                                    >
                                      {lease.attributes.title}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="edit-jurisdiction-btn-box dialog__button_group">
                    <Button className="cancel-button edit-jurisdiction-cancel" onClick={this.handleModalClose}>
                      {t("Cancel")}
                    </Button>
                    <Button id="btn-add" type="submit" className="add-button edit-jurisdiction-save">
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        {this.handleDetails()}

        <Modal
          open={this.state.addBuildingOpen}
          onClose={this.handleBuildingClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
          id="building_body"
        >
          {this.buildingBody(t)}
        </Modal>

        <AlertDialog
          IsOpen={this.state.confirmModal}
          Image={TickIcon}
          CloseDialog={this.handleConfirmModalClose}
          Header={t("Delete Jurisdiction?")}
          Content={`<p>${t("Are you sure you want to delete the jurisdiction?")}</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleConfirmModalClose}
          AcceptFun={this.deleteJurisidiction}
          data-test-id="accept-dialog"
        />

        <Modal
          open={this.state.changejurisdictionModal}
          onClose={this.handleBuildingClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
        >
          {this.changeJurisdictionBody()}
        </Modal>

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: null })}
          message={this.state.error}
        />

        <MainScreen>{this.viewHeader(t, classes)}</MainScreen>
      </>
    );
  }
}
const webStyle: any = {
  labelsStyle: {
    color: "#212121",
    margin: "10px 0px 10px 0px",
  },
  noData: {
    alignItem: "center",
    textAlign: "center",
  },
  viewSearch: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    height: "55px",
    width: "150px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#2b6fed",
    },
  },
  addComplexBtn: {
    color: "white",
    backgroundColor: "#fc8434",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  },
  commonBtn: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    borderRadius: "25px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  } as React.CSSProperties,
  deleteJurBtn: {
    color: "red",
    backgroundColor: "white",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  },
  rentSelectedTitle: {
    fontSize: "14px",
    color: "#2B6FED",
    textAlign: "center",
    fontStyle: "normal",
    lineHeight: "normal",
    fontWeight: 700,
    marginBottom: "10px",
  },
  rentImageContainer: {
    borderRadius: "100%",
    marginBottom: "10px",
    padding: "20px",
    border: "5px solid #f7f9fe",
    width: "max-content",
  },
  rentSelected: {
    height: "127",
    justifyContent: "center",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    flexDirection: "column",
    border: "2px solid #2b6fed",
    width: "168",
  },
  rowOptions: {
    position: "absolute",
    width: "fit-content",
    background: "#fff",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999",
    left: "-80px",
    padding: "15px",
  },
  rentTitleStyle: {
    color: "#181d25",
    marginBottom: "10px",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "normal",
    fontWeight: 700,
  },
  rentStyle: {
    flexDirection: "column",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    width: "168",
    borderRadius: "8px",
    border: "1px dashed #e4e4e4",
    alignItems: "center",
    height: "127",
  },
  editOrange: {
    right: "20px",
    top: "10px",
    position: "absolute",
    height: "15px",
    width: "15px",
  },
};

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ViewJurisdiction)));
// Customizable Area End
