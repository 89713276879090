Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.endPointApiCreateVehicle ='bx_block_vehicle/vehicles';
exports.contentTypeApiAddDetail = "application/json";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodDELETE = "DELETE";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";
exports.getTotalSentInvitationListEndPoint= "bx_block_request_management/employee_request_mangements/index_invitaion";
exports.getRequestsManagementListEndPoint = "bx_block_request_management/employee_request_mangements";
exports.getwaitingAcceptenceEndPoint = "bx_block_request_management/employee_request_mangements/awited_acceptence";
exports.getPendingJoinRequestListEndPoint = "bx_block_request_management/employee_request_mangements/pending_join_request";
exports.acceptRejectJoineRequestEndPoint ="bx_block_request_management/employee_request_mangements/accept_reject_joine_request"
exports.getCityListEndPoint ="bx_block_address/city_list"
exports.getComplexListEndPoint ="bx_block_address/housing_complex_list";
exports.getCompanyListEndPoint ="bx_block_custom_user_subs/list_companies_by_city";
exports.deletedApiEndPoint="bx_block_request_management/member_invitations"

exports.createMemberInvitationEndPoint = "bx_block_request_management/member_invitations";
exports.createMemberCountryListEndPoint = "bx_block_address/country_list";
exports.getUserTypeEndPoint = "bx_block_roles_permissions/roles";
exports.getAddTeamMemberEndPoint = "bx_block_my_team/team_members/add_member"

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
// Customizable Area End
