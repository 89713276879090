// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start  
  t:(value:string)=> string;
  navigation :typeof navigation;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchor: null | HTMLElement;
  terminateContractModal: boolean;
  shareUrl: string;
  isShareModalOpen: boolean;
  contractDetails: ContractDetailsSuccessResponse;
  showError:boolean;
  error:string;
  showSuccessMessage:boolean;
  successMessage:string;
  constractId:string;
  termsId: Array<number>;
  conditionId: Array<number>;
  // Customizable Area End
}

// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

export interface ApiFailureResponseError {
  contract: string
};
export interface ContractDetailsSuccessResponse {
  contract: {
    data: {
      id: string;
      type: string;
      attributes: {
        apartment_management_id: number;
        society_management_id: number;
        account_id: number;
        city: string | null;
        country: string | null;
        region: string | null;
        building_management_id: number;
        agreement_duration: string;
        start_date: string;
        landlord_name: string;
        rent_amount: number;
        state: string | null;
        created_at: string;
        expires_on: string;
        duration: string | null;
        currency: string;
        tenant_name: string;
        penanlty_late_payment: boolean;
        status: string;
        contract_template: string;
        custom_contract: boolean;
        updated_at: string;
        lease_template_id: number;
        owner_name: string | null;
        owner: {
          first_name: string | null;
          phone_number: number | null;
          id: number;
          email: string;
          last_name: string | null;
          device_id: string | null;
          country_code: string | null;
          full_phone_number: string;
          activated: boolean;
          user_name: string | null;
          unique_auth_id: string | null;
          user_type: string | null;
          updated_at: string;
          last_visit_at: string | null;
          platform: string | null;
          suspend_until: string | null;
          app_language_id: number | null;
          stripe_id: string | null;
          created_at: string;
          status: string;
          is_blacklisted: boolean;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          role_id: number | null;
          full_name: string;
          date_of_birth: string | null;
          age: number | null;
          is_paid: boolean;
          company_name: string | null;
          manager_full_name: string | null;
          owner_full_name: string | null;
          building_management_id: number | null;
          gender: string | null;
          owner_phone_number: string | null;
          owner_email: string | null;
          disable_chat: boolean;
          language_type: string | null;
          ticket_days_configuration: number;
          public_field: string | null;
        };
        
        lease_template_text: string;
        contract_type: string;
        building_name: string;
        expire_on: number;
        lease_template_pdf: {
          url: string;
        };
        contract_template_pdf: {
          url: string;
        };
        
        society_management: {
          id: number;
          created_at: string;
          name: string;
          complex_area: string;
          updated_at: string;
          maintenance_per_square_feet: number;
          measurement_unit: string;
          description: string;
          zone_multiplier: number;
          contigency: number;
          latitude: string | null;
          late_charge_percentage: string;
          account_manager_id: number | null;
          value_added_tax: string;
          longitude: string | null;
          real_estate_company_id: number | null;
          language: string | null;
          is_building: boolean;
          ticket_days_configuration: number;
          total_floor: number | null;
          total_unit: number | null;
          status: string | null;
          currency_id: number | null;
        };
        unit_name: string;
        conditions: null | Array<TermsAndConditionItem>;
        terms: null | Array<TermsAndConditionItem>;
        custom_term_condition: null;
        custom_term_condition_added: boolean;
        tenant: {
          first_name: string | null;
          last_name: string | null;
          full_phone_number: string;
          country_code: string | null;
          phone_number: number | null;
          id: number;
          activated: boolean;
          device_id: string | null;
          email: string;
          user_name: string | null;
          created_at: string;
          user_type: string | null;
          updated_at: string;
          platform: string | null;
          app_language_id: number | null;
          suspend_until: string | null;
          last_visit_at: string | null;
          stripe_id: string | null;
          status: string;
          is_blacklisted: boolean;
          stripe_subscription_date: string | null;
          unique_auth_id: string | null;
          role_id: number | null;
          full_name: string;
          age: number | null;
          gender: string | null;
          stripe_subscription_id: string | null;
          date_of_birth: string | null;
          owner_full_name: string | null;
          is_paid: boolean;
          public_field: string | null;
          owner_phone_number: string | null;
          manager_full_name: string | null;
          disable_chat: boolean;
          owner_email: string | null;
          language_type: string | null;
          building_management_id: number | null;
          company_name: string | null;
          ticket_days_configuration: number;
        } | null;
        penanlty_late_payments: {
          penanlty_type: string | null;
          amount: string;
          id: number;
          penanlty_counted: string;
          account_id: number;
          tenant_id: number;
        };
        custom_contract_image: string | null;
      };
    };
  };
  code: number;
  message: string;
};

export interface TermsAndConditionItem {
  id: number;
  text: string;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
};

export type TFunction = (value: string) => string;

// Customizable Area End

interface SS {
  id: string;
}

export default class LeaseManagementContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contractDetailsApiCallId: string = "";
  terminateContractApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ContractDetailsId),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      anchor: null,
      terminateContractModal: false,
      showError:false,
      error:"",
      showSuccessMessage:false,
      successMessage:"",
      isShareModalOpen: false,
      shareUrl: "",
      constractId:"",
      termsId: [],
      conditionId: [],
      contractDetails: {    
        "code": 200,
        "message": "Successfully updated",
        "contract": {
          "data": {
            "type": "contract",
            "id": "3",
            "attributes": {
              "account_id": 25,
              "building_management_id": 25,
              "apartment_management_id": 489,
              "landlord_name": "friya",
              "country": null,
              "society_management_id": 31,
              "city": null,
              "agreement_duration": "24",
              "start_date": "2024-01-08",
              "rent_amount": 10000.0,
              "currency": "USD",
              "expires_on": "2026-07-20",
              "lease_template_id": 1,
              "state": null,
              "tenant_name": "freny",
              "region": null,
              "custom_contract": false,
              "status": "Active",
              "penanlty_late_payment": true,
              "duration": null,
              "contract_template": "<p>Tenant name :-&nbsp;<strong>freny</strong></p><p>This agreement made on this&nbsp;<strong>January 08, 2024</strong>&nbsp;between&nbsp;<strong>friya</strong>, residing&nbsp;<strong>Emaar Creek B</strong>,&nbsp;<strong>A-202</strong>&nbsp;on duration of&nbsp;<strong>24 months</strong>,&nbsp;<strong>July 20, 2026</strong>&nbsp;on rent amount&nbsp;<strong>USD 10000</strong>.</p><p><br></p><p>WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to [Lease Property Address Line 1, Address Line 2, City, State, Pin Code] falling in the category, [Independent House / Apartment / Farm House / Residential Property] and comprising of with an extent of [ Square Feet] hereinafter referred to as the 'said premises';</p><p><br></p><p>AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of [Lease Term] commencing from [Lease Start Date] in the manner hereinafter appearing.</p><p><strong>Emaar Creek Harbour Breeze</strong></p><p><strong> Al Khazai Al Khazai, 3173, Al Olaya, Riyadh 12611, Saudi Arabia</strong></p><p><br></p><p>Thanks,</p><p><strong>friya</strong></p>",
              "owner_name": null,
              "created_at": "2024-01-08T07:42:20.477Z",
              "updated_at": "2024-01-08T07:42:20.477Z",
              "owner": {
                "first_name": null,
                "last_name": null,
                "full_phone_number": "+966576109275",
                "id": 25,
                "email": "friya@yopmail.com",
                "phone_number": 576109275,
                "device_id": null,
                "country_code": null,
                "unique_auth_id": null,
                "created_at": "2024-01-08T07:22:35.151Z",
                "updated_at": "2024-01-08T07:23:24.694Z",
                "platform": null,
                "user_name": null,
                "user_type": null,
                "activated": true,
                "last_visit_at": null,
                "is_paid": false,
                "is_blacklisted": false,
                "suspend_until": null,
                "app_language_id": null,
                "stripe_id": null,
                "stripe_subscription_id": null,
                "stripe_subscription_date": null,
                "role_id": null,
                "full_name": "friya",
                "gender": null,
                "date_of_birth": null,
                "status": "regular",
                "company_name": null,
                "owner_email": null,
                "owner_full_name": null,
                "manager_full_name": null,
                "disable_chat": false,
                "age": null,
                "ticket_days_configuration": 0,
                "language_type": null,
                "public_field": null,
                "building_management_id": null,
                "owner_phone_number": null,
              },
              
              "contract_template_pdf": {
                "url": ""
              },
              "lease_template_pdf": {
                "url": ""
              },
              
              "contract_type": "Individual",
              "expire_on": 12,
              "building_name": "Emaar Creek B",
              "lease_template_text": "<p>Tenant name :-&nbsp;<strong>{{TENANT_NAME}}</strong></p><p>This agreement made on this&nbsp;<strong>{{START_DATE}}</strong>&nbsp;between&nbsp;<strong>{{LANDLORD_NAME}}</strong>, residing&nbsp;<strong>{{BUILDING_NAME}}</strong>,&nbsp;<strong>{{UNIT_NAME}}</strong>&nbsp;on duration of&nbsp;<strong>{{DURATION}}</strong>,&nbsp;<strong>{{END_DATE}}</strong>&nbsp;on rent amount&nbsp;<strong>{{AMOUNT}}</strong>.</p><p><br></p><p>WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to [Lease Property Address Line 1, Address Line 2, City, State, Pin Code] falling in the category, [Independent House / Apartment / Farm House / Residential Property] and comprising of with an extent of [ Square Feet] hereinafter referred to as the 'said premises';</p><p><br></p><p>AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of [Lease Term] commencing from [Lease Start Date] in the manner hereinafter appearing.</p><p><strong>{{COMPLEX_NAME}}</strong></p><p><strong>{{COMPLEX_ADDRESS}}</strong></p><p><br></p><p>Thanks,</p><p><strong>{{LANDLORD_NAME}}</strong></p>",
              "society_management": {
                "description": "Emirates Crown is a 63-floor residential tower in Dubai, United Arab Emirates, developed by Bin Shafar Holding and designed by Design & Architecture Bureau. The tower has a structural height of 296 m (971 ft). Construction of the Emirates Crown began in 2005, and was completed in 2008. Upon completion, it stood as the sixth-tallest building in Dubai, and 45th-tallest building in the world. As of 2022, it is the 26th-tallest building in Dubai.",
                "maintenance_per_square_feet": 100.0,
                "id": 31,
                "updated_at": "2024-01-05T07:27:47.290Z",
                "complex_area": "10000000",
                "created_at": "2024-01-05T07:27:47.192Z",
                "ticket_days_configuration": 0,
                "latitude": null,
                "measurement_unit": "Sqft",
                "real_estate_company_id": null,
                "zone_multiplier": 7.0,
                "name": "Emirates Crown",
                "is_building": false,
                "late_charge_percentage": "10.0",
                "value_added_tax": "10.0",
                "longitude": null,
                "contigency": 7.0,
                "total_unit": null,
                "account_manager_id": null,
                "total_floor": null,
                "currency_id": null,
                "language": null,
                "status": null,
              },
              "unit_name": "A-202",
              "conditions": null,
              "terms": null,
              "custom_term_condition_added": false,
              "custom_term_condition": null,
              "tenant": {
                "first_name": null,
                "id": 26,
                "last_name": null,
                "phone_number": null,
                "company_name": null,
                "full_phone_number": "+966-576987100",
                "owner_full_name": null,
                "owner_email": null,
                "manager_full_name": null,
                "owner_phone_number": null,
                "building_management_id": null,
                "disable_chat": false,
                "public_field": null,
                "language_type": null,
                "email": "freny@yopmail.com",
                "country_code": null,
                "activated": true,
                "unique_auth_id": null,
                "device_id": null,
                "suspend_until": null,
                "status": "regular",
                "ticket_days_configuration": 0,
                "stripe_subscription_id": null,
                "stripe_subscription_date": null,
                "role_id": null,
                "full_name": "freny",
                "gender": null,
                "date_of_birth": null,
                "age": null,
                "app_language_id": null,
                "stripe_id": null,
                "is_paid": false,
                "user_type": null,
                "updated_at": "2024-01-08T07:40:49.084Z",
                "platform": null,
                "created_at": "2024-01-08T07:40:49.084Z",
                "user_name": null,
                "is_blacklisted": false,
                "last_visit_at": null,
                
              },
              "penanlty_late_payments": {
                "id": 1,
                "penanlty_counted": "Fixed Amount",
                "account_id": 25,
                "penanlty_type": null,
                "tenant_id": 26,
                "amount": "10",
              },
              "custom_contract_image": null
            }
          }
        },
      }
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    let createContractData = await getStorageData('contractId');
    if (createContractData) {
      this.setState({ constractId: createContractData }, () => {
        this.getContractDetails();
      })
    }
        // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    }else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = await getStorageData('contractId');
      if(createContractData){
        this.setState({constractId:createContractData},()=>{
          this.getContractDetails();
        })
      }
    }
  };

  contractDetailsApiCall = async (data: APIPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      "Content-Type": contentType,
      token: token
    };
    let contractDetailsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    body && type !== "formData"
      ? contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
    return contractDetailsrequestMessage.messageId;
  };

  contractDetailsApiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: ContractDetailsSuccessResponse) => {
    if (apiRequestCallId === this.contractDetailsApiCallId) {
      this.contractDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.terminateContractApiCallId) {
      this.terminateContractSucessCallBack(responseJson);
    }
  };

  contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.contractDetailsApiCallId) {
      this.contractDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.terminateContractApiCallId) {
      this.terminateContractFailureCallBack(responseJson);
    }
  };

  getContractDetails = async () => {
    this.contractDetailsApiCallId = await this.contractDetailsApiCall({
      method: configJSON.contractDetailsMethod,
      endPoint: `${configJSON.contractDetailsEndPoint}/${this.state.constractId}`,
    });
  };

  contractDetailsSucessCallBack = (response: ContractDetailsSuccessResponse) => {    
    this.setState({ contractDetails: response });
    if (response.contract.data.attributes.terms !== null) {
      response.contract.data.attributes.terms.forEach((term) => {
        this.state.termsId.push(term.id)
      });
    }
    if (response.contract.data.attributes.conditions !== null) {
      response.contract.data.attributes.conditions.forEach((condition) => {
        this.state.conditionId.push(condition.id)
      });
    }
    this.setState({ shareUrl: response.contract.data.attributes?.contract_template_pdf?.url, termsId: this.state.termsId, conditionId: this.state.conditionId });
  };

  contractDetailsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  terminateContract = async () => {
    let payload = new FormData();
    payload.append("contract[status]", "Terminated");
    this.terminateContractApiCallId = await this.contractDetailsApiCall({
      method: configJSON.terminateContractMethod,
      endPoint: `${configJSON.terminateContractEndPoint}/${this.state.constractId}`,
      body: payload,
      type: "formData",
    });
  };

  terminateContractSucessCallBack = (response: ContractDetailsSuccessResponse) => {  
    this.setState({ terminateContractModal: false }, () => {
      this.getContractDetails();
    });
  };

  terminateContractFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };
 
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchor: event?.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchor: null,
    });
  };

  handleOpenTerminateContractModal = () => {
    this.setState({ terminateContractModal: true })
  };

  handleCloseTerminateContractModal = () => {
    this.setState({ terminateContractModal: false })
  };

  handleCloseAlertError = () => { this.setState({ showError: false, error: "" }); };

  hanldeCloseSuccessAlert = () => {this.setState({showSuccessMessage:false,successMessage:""})};
  
  handleShareModal = () => {
    this.setState({
      isShareModalOpen: !this.state.isShareModalOpen,
    });
  };

  handleDownloadFile = (fileUrl: string) => {    
        const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'document.pdf';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  handleExistRecordNavigation = (path: string) => {
    let createContractPayload = {
      templateId: this.state.contractDetails.contract.data.id,
      country: this.state.contractDetails.contract.data.attributes.country,
      city: this.state.contractDetails.contract.data.attributes.city,
      complex: "Complex",
      buildingName: this.state.contractDetails.contract.data.attributes.building_name,
      unitName: this.state.contractDetails.contract.data.attributes.unit_name,
      complexName: this.state.contractDetails.contract.data.attributes?.society_management?.name,
      tenant_name: this.state.contractDetails.contract.data.attributes.tenant?.full_name,
      landlord_name: this.state.contractDetails.contract.data.attributes.owner.full_name,
      building_management_id: this.state.contractDetails.contract.data.attributes.building_management_id,
      society_management_id: this.state.contractDetails.contract.data.attributes?.society_management_id,
      tenant_id: this.state.contractDetails.contract.data.attributes.tenant?.id,
      expires_on: this.state.contractDetails.contract.data.attributes.expire_on,
      apartment_management_id: this.state.contractDetails.contract.data.attributes.apartment_management_id,
      agreement_duration: this.state.contractDetails.contract.data.attributes.agreement_duration,
      start_date: this.state.contractDetails.contract.data.attributes.start_date,
      term_ids: this.state.termsId,
      lease_template_id: this.state.contractDetails.contract.data.attributes.lease_template_id,
      rent_amount: this.state.contractDetails.contract.data.attributes.rent_amount,
      currency: this.state.contractDetails.contract.data.attributes.currency,
      condition_ids: this.state.conditionId,
      custom_contract: this.state.contractDetails.contract.data.attributes.custom_contract,
      penanlty_late_payment: this.state.contractDetails.contract.data.attributes.penanlty_late_payment,
      contract_template: this.state.contractDetails.contract.data.attributes.contract_template,
      owner: this.state.contractDetails.contract.data.attributes.owner.full_name,
      accountId: this.state.contractDetails.contract.data.attributes.owner.id,
      status: "Draft",
      contractLink: this.state.contractDetails.contract.data.attributes?.contract_template_pdf?.url
    };
   
    
    const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayload);
      this.send(msgData);
  };

  getContractExpiry = (date: any) => {
    if (!date) {
      return "NA";
    }
    return date === '1' ? date + " month" : date + " months";
  }
  // Customizable Area End
}
