// Customizable Area Start
import React from "react";
import GeneralHeaderController from "./GeneralHeaderController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Box, Hidden } from "@material-ui/core";
import DashboardHeader from "../../../blocks/dashboard/src/Company Employee/DashboardHeader.web";
import CECOOSidebar from "../../../blocks/dashboard/src/Company Employee/CECOOSidebar.web";
import CEAccManSidebar from "../../../blocks/dashboard/src/Company Employee/CEAccManSIdebar.web";
import CESalesManSidebar from "../../../blocks/dashboard/src/Company Employee/CESalesManSidebar.web";

class CEHeaderComponent extends GeneralHeaderController {
  render() {
    const { i18n }: any = this.props;
    const language = i18n?.language;
    const userType = localStorage.getItem("role");
    return (
      <Box style={{ background: "#F4F7FF" }} dir={language === "ar" ? "rtl" : "ltr"}>
        <DashboardHeader navigation={null} id="" />
        <Grid container>
          <Grid item md={3} className="SideBar">
            <Hidden xsDown smDown>
              {userType === "coo" && <CECOOSidebar />}
              {userType === "account_manager" && <CEAccManSidebar />}
              {userType === "sales_manager" && <CESalesManSidebar />}
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={9} style={{ paddingTop: 35, minHeight: "92vh" }}>
            {this.props.children}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// @ts-ignore
export default withTranslation()(withRouter(CEHeaderComponent));

// Customizable Area End
