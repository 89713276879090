import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    TableContainer,
    TableHead,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    Divider,
    Dialog,
    DialogTitle,
    InputBase,
    TextField,
    DialogContent,
    styled,
    Popover,
} from "@material-ui/core";
import { Owner, logo} from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
const StyledPopover = styled(Popover)({
    '& .MuiPopover-paper': {
      padding: '0px',
      backgroundColor: 'white',
      boxShadow: 'none',
      border: '1px solid #E2E8F0',
      '& .actionIcon': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 60,
        paddingTop: 10,
        paddingBottom: 10,
        cursor: 'pointer'
      },
    },
  });
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import LoginReportsController, {
    Props,
} from "./LoginController";

// Customizable Area Start
export class LoginReports extends LoginReportsController {
    constructor(props: Props) {
        super(props);
    }

    renderReportData = () => {
        const BoxView = Box as any
        const { t }: any = this.props;
        if (this.state.viwePage) {
            return (
                <BoxView style={addAnotherUnit.subcontainer}>
                    <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant='h4' className="bold-text">{t("Login Reports")}</Typography>
                            <Typography className="bold-text" data-test-id="downloadButto" onClick={()=> this.handleDownloadReport()} style={addAnotherUnit.downloadReports}>{t("DOWNLOAD REPORT")}</Typography>
                        </BoxView>
                    <BoxView style={addAnotherUnit.main}>
                        <BoxView style={addAnotherUnit.heading}>
                            <BoxView>
                            <img src={logo.default} alt="logo" style={addAnotherUnit.imageBox} height={49} width={126}/>
                            <Typography className="bold-text">{t("TENANT INTERNATIONAL")}</Typography>
                            </BoxView>
                        </BoxView>
                        <div style={addAnotherUnit.detail}>
                            <Typography variant="h5" className="bold-text">{t(this.state.tags)} {t("Report")}</Typography>
                        </div>
                        <Divider />
                        <BoxView style={addAnotherUnit.userData}>
                            <TableContainer component={Paper} style={addAnotherUnit.tblData}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="bold-text">#</TableCell>
                                            <TableCell align="left" className="bold-text">{t("Complex")}</TableCell>
                                            <TableCell align="left" className="bold-text">{t("Building")}</TableCell>
                                            <TableCell align="left" className="bold-text">{t("Name")}</TableCell>
                                            <TableCell align="left" className="bold-text">{t("Email Address")}</TableCell>
                                            <TableCell align="left" className="bold-text">{t("Role")}</TableCell>
                                            {this.state.tags === "Last Login" && <TableCell align="left" className="bold-text">{t("Last Logged In")}</TableCell>}
                                            {this.state.tags === "Days since Last Login" && <TableCell align="left" className="bold-text">{t("Days Since Last Login")}</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tableData?.map((item:any,index:number)=> {
                                            return (
                                                <TableRow>
                                                    <TableCell className="bold-text">{index + 1}</TableCell>

                                                    {<TableCell align="left" style={{ fontWeight: 600 }}>{item.attributes.complex_list.map((item: {name:string}) => item.name).join(', ') || '-'}</TableCell>}
                                                    <TableCell align="left">{item.attributes.building_list.map((item: {name:string}) => item.name).join(', ') || '-'}</TableCell>
                                                    <TableCell align="left">{item.attributes.logged_in_by}</TableCell>
                                                    <TableCell align="left">{item.attributes.email}</TableCell>
                                                    <TableCell align="left">{item.attributes.user_roles.map((item: {name:string}) => item.name).join(', ') || '-'}</TableCell>
                                                    {this.state.tags === "Last Login" && <TableCell align="left">{item.attributes.last_login}</TableCell>}
                                                    {this.state.tags === "Days since Last Login" && <TableCell align="left">{item.attributes.last_login_days}</TableCell>}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <BoxView style={addAnotherUnit.tblpage}>
                                <Typography>{t("TENANT INTERNATIONAL")}</Typography>
                                <Typography>{t("Page 1 of 1")}</Typography>
                            </BoxView>
                        </BoxView>
                    </BoxView>
                </BoxView>
            );
        } else {
            return null;
        }
    }

    handleTable = () => {
        const BoxView = Box as any
        const { t, i18n }: any = this.props;
        const language = i18n.language;
        return (
            <Grid className="table-box-card">
                <Grid item sm={12} md={12} xs={12}>
                    <BoxView className="table-top">
                        <h4 className="bold-text">{t(this.state.tags)}</h4>
                        <BoxView className="filter-head-box" style={{ margin: "0" }}>
                            <BoxView style={addAnotherUnit.searchBox} >
                                {this.state.tags === "Days since Last Login" && <Grid item>
                                    <BoxView>
                                        <Select
                                            disableUnderline
                                            displayEmpty
                                            data-test-id="filterId"
                                            className="filter"
                                            style={addAnotherUnit.filter}
                                            value={this.state.sort}
                                            onChange={(event) => this.handleSortChange(event)}
                                            id="select-Country"
                                        >
                                            <MenuItem
                                                disabled
                                                id="selectStatusBudgetReport"
                                                value=""
                                            >
                                                {t("Sort By")}
                                            </MenuItem>
                                            <MenuItem
                                                id="selectStatusBudgetReport"
                                                onClick={() => this.handleLastLoginApi()}
                                                value="desc"
                                            >
                                                {t("descending")}
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => this.handleLastLoginApi()}
                                                id="selectStatusBudgetReport"
                                                value="asc"
                                            >
                                                {t("ascending")}
                                            </MenuItem>
                                        </Select>
                                    </BoxView>
                                </Grid>}
                                <SearchInput
                                    placeholder={t("Search")}
                                    data-test-id="filterId2"
                                    onChange={(event: React.ChangeEvent<{
                                            value:
                                            unknown;
                                        }>)=>this.handleFilter(event)}
                                    t={t}
                                />
                            </BoxView>
                        </BoxView>
                    </BoxView>
                    <Divider />
                    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align={languageCondition(language, "right", "left")}
                                    >
                                        #
                                    </TableCell>
                                    <TableCell align = {languageCondition(language, "right", "left")} className = "bold-text"
                                    >
                                        {t("Complex")}
                                    </TableCell>

                                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                        {t("Building")}
                                    </TableCell>

                                    <TableCell className = "bold-text" align = {languageCondition( language, "right", "left" )} 
                                    >
                                        {t("Name")}
                                    </TableCell>

                                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                        {t("Email Address")}
                                    </TableCell>

                                    <TableCell className="bold-text" align={languageCondition(language, "right", "left")} 
                                    >
                                        {t("Role")}
                                    </TableCell>

                                    {this.state.tags === "Last Login" &&
                                        <TableCell className="bold-text"
                                            align={languageCondition(language, "right", "left")}
                                        >{t("Last Logged In")}</TableCell>}

                                    {this.state.tags === "Days since Last Login"
                                        && <TableCell
                                            className="bold-text"
                                            align={languageCondition(language, "right", "left")}
                                        >
                                            {t("Days Since Last Login")}</TableCell>}
                                    <TableCell align={languageCondition(language, "right", "left")} />
                                </TableRow>
                            </TableHead>
                            {this.state.tableData ? <TableBody>
                                {this.state.tableData?.map((item: { attributes: { complex_list: { name: string; }[]; building_list: { name: string; }[]; logged_in_by: number | null, email: string; user_roles: { name: string; }[]; last_login: number| null; last_login_days: number| null }; }, index: number) => {
                                    return (
                                        <TableRow >
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            {<TableCell align="left" style={{ fontWeight: 600 }}>{item.attributes.complex_list.map((item: { name: string; }) => item.name).join(', ') || '-'}</TableCell>}
                                            <TableCell align="left">{item.attributes.building_list.map((item: { name: string; }) => item.name).join(', ') || '-'}</TableCell>
                                            <TableCell align="left">{item.attributes.logged_in_by}</TableCell>
                                            <TableCell align="left">{item.attributes.email}</TableCell>
                                            <TableCell align="left">{item.attributes.user_roles.map((item: { name: string; }) => item.name).join(', ') || '-'}</TableCell>
                                            {this.state.tags === "Last Login" && <TableCell align="left">{item.attributes.last_login}</TableCell>}
                                            {this.state.tags === "Days since Last Login" && <TableCell align="left">{item.attributes.last_login_days}</TableCell>}
                                            <TableCell align="left"><MoreVertIcon data-test-id="moreIconId" onClick={(event) => this.handleClick(event)} /></TableCell>
                                            <StyledPopover
                                                open={Boolean(this.state.anchorEl)}
                                                anchorEl={this.state.anchorEl}
                                                data-test-id="closeButtonId"
                                                onClose={this.handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >

                                                <BoxView data-test-id={"btnTableEdit"} onClick={() => this.handleView()} className="actionIcon">
                                                    {("View user profile")}
                                                </BoxView>
                                            </StyledPopover>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>: <Typography style={addAnotherUnit.error}>Data Not Found</Typography> }
                        </Table>
                    </TableContainer>
                    <BoxView className="table-bottom">
                        <p>
                            {t("Showing")} <span className="current-page">{this.state.tableData?.length}</span> {t("of")}{" "}
                            <span className="total-page">{this.state.totalCount}</span>{" "}
                            {t("results")}
                        </p>
                        <BoxView>
                            <Pagination
                                id="PaginationModule"
                                count={this.state.totalPages}
                                onChange={this.handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                data-test-id = "paginationID"
                                style={{ marginRight: "20px" }}
                            />
                        </BoxView>

                    </BoxView>
                </Grid>
            </Grid>
        )

    }

    handleTab = () => {
        const { t }: any = this.props;
        return (
            <Grid container xs={12} spacing={2}>
            <Grid item>
                <Typography data-test-id="lastLoginId" style={this.state.tags === "Last Login" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Last Login")}>{t("Last Login")}</Typography>
            </Grid>
            <Grid item>
                    <Typography data-test-id="naverLoginId" style={this.state.tags === "Never Logged In Users" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Never Logged In Users")}>{t("Never Logged In Users")}</Typography>
            </Grid>
            <Grid item>
                <Typography data-test-id="naverLoginLastId" style={this.state.tags === "Never Logged In Users In Last 30 Months" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Never Logged In Users In Last 30 Months")}>{t(`Never Logged In Users In Last ${this.state.loginMonth ?this.state.loginMonth : "30" } Months`)}</Typography>
            </Grid>

            <Grid item>
                <Typography data-test-id="dayScinceLoginId" style={this.state.tags === "Days since Last Login" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Days since Last Login")}>{t("Days since Last Login")}</Typography>
            </Grid>
        </Grid>  
        )
    }
    
// Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        const { t }: any = this.props;
        const { loginCardDetails  }  = this.state                
        // Customizable Area End
        return (
            // Customizable Area Start
                <CEHeaderComponentWeb>
                    <BoxView style={addAnotherUnit.container}>
                    <BoxView style={{ display: "flex" }}>
                        <Typography variant="body1">
                            {this.state.topMenu.map((tag, index) => {
                                if (index === this.state.topMenu.length - 1) {
                                    return <span style={{ color: "#2B6FED", cursor: "pointer" }}>{t(tag)}</span>
                                } else {
                                    return <span style={{ cursor: "pointer" }} data-test-id="menuBarId" onClick={() => this.handleMenuBar(index)}>{`${t(tag)} / `}</span>
                                }
                            })}
                        </Typography>
                    </BoxView>
                    <br />
                    {!this.state.viwePage && 
                    <BoxView >
                        <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant='h4' className="bold-text">{t("Login Reports")}</Typography>
                            <Typography className="bold-text" data-test-id="downloadButtonId" onClick={()=> this.handleDownload()} style={addAnotherUnit.downloadReports}>{t("DOWNLOAD REPORT")}</Typography>
                        </BoxView>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <BoxView
                                    style={addAnotherUnit.Box}
                                >
                                    <img src={Owner} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Active Users %")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{loginCardDetails?.active_users_percentage}%</Typography>
                                    </BoxView>
                                </BoxView>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <BoxView
                                    style={addAnotherUnit.Box}
                                >
                                    <img src={Owner} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Onboard %")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{loginCardDetails?.onboding_users}%</Typography>
                                        <Grid container xs={12} spacing={2}>

                                        </Grid>
                                    </BoxView>
                                </BoxView>
                            </Grid>
                        </Grid>
                       {this.handleTab()}
                        <Grid container xs={12} spacing={2} style={{ marginTop: "25px" }}>
                            <Grid item>
                                <Select
                                    displayEmpty
                                    style={addAnotherUnit.selectmenu}
                                    className="select-input selectFloorChairmanInvoices"
                                    disableUnderline
                                    value={this.state.individualbuilding}
                                    id="select-city1"
                                    data-test-id="buildingsdropId"
                                    onChange={(event) => this.handleCountryFilterChange(event)}
                                >
                                    <MenuItem disabled value="" id="selectStatusBudgetReport">
                                        {t("Complex/Individual Buildings")}
                                    </MenuItem>
                                    <MenuItem value="Complex" id="selectStatusBudgetReport">
                                        {t("Complex")}
                                    </MenuItem>
                                    <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                        {t("Individual building")}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <BoxView>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        data-test-id="selectCountryId"
                                        style={addAnotherUnit.selectmenu}
                                        className="select-input selectFloorChairmanInvoices"
                                        value={this.state.selectedCountry}
                                        placeholder="Select Country"
                                        id="select-Country"
                                        onChange={(event) => this.handleSelectCountryChange(event)}
                                    >
                                        <MenuItem
                                            value=""
                                            id="selectStatusBudgetReport"
                                            disabled
                                        >
                                            {t("Select Country")}
                                        </MenuItem>
                                        <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                        {this.state.selectCountry?.length > 0 &&
                                            this.state.selectCountry?.map((item: any) => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </BoxView>
                            </Grid>
                            <Grid item>
                                <Select displayEmpty
                                    data-test-id="selectCityId"
                                    value={this.state.selectedCity}
                                    disableUnderline
                                    className="select-input selectUnitChairmanInvoices complexS"
                                    style={addAnotherUnit.selectmenu}
                                    id="select-city1"
                                    onChange={(event) => this.handleSelectCityChange(event)}
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        id="selectStatusBudgetReport"
                                    >
                                        {t("Select City")}
                                    </MenuItem>
                                    <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectCity?.length > 0 &&
                                        this.state.selectCity?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>

                            <Grid item>
                                <Select displayEmpty
                                    value={this.state.selectedComplex}
                                    disableUnderline
                                    id="select-city1"
                                    onChange={(event) => this.handleSelectComplexChange(event)}
                                    style={addAnotherUnit.selectmenu}
                                    data-test-id="selectComplexId"
                                    className="select-input selectUnitChairmanInvoices complexS"
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        id="selectStatusBudgetReport"
                                    >
                                        {t("Select Complex")}
                                    </MenuItem>
                                    <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectComplex?.length > 0 &&
                                        this.state.selectComplex?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>
                            <Grid item>
                                <Select displayEmpty
                                    id="selectBuilding"
                                    disableUnderline
                                    className="select-input invoiceTypeFromChairmanInvoices"
                                    value={this.state.selectedBuilding}
                                    style={addAnotherUnit.selectmenu}
                                    onChange={(event) => this.handleSelectBuildingChange(event)}
                                    data-test-id="slctBuildingId"
                                >
                                    <MenuItem
                                        value=""
                                        id="selectStatusBudgetReport"
                                        disabled
                                    >
                                        {t("Select Building")}
                                    </MenuItem>
                                    <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectBuilding?.length > 0 &&
                                        this.state.selectBuilding?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>

                            <Grid item>
                                <Select displayEmpty
                                    id="selectBuilding"
                                    disableUnderline
                                    className="select-input invoiceTypeFromChairmanInvoices"
                                    value={this.state.selectedRole}
                                    data-test-id="RoleId"
                                    onChange={(event) => this.handleRoleChange(event)}
                                    style={addAnotherUnit.selectmenu}
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        id="selectStatusBudgetReport"
                                    >
                                        {t("Select Role")}
                                    </MenuItem>
                                    <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.role?.length > 0 &&
                                        this.state.role?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item.id}>
                                                    {item.attributes.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={2}>
                            <Grid item>
                                <Select
                                    displayEmpty
                                    id="select-city1"
                                    disableUnderline
                                    style={addAnotherUnit.selectmenu}
                                    data-test-id="SubscriptionId"
                                    value={this.state.selectSubscription}
                                    className="select-input selectFloorChairmanInvoices"
                                    onChange={(event) => this.handleUserrrSubscription(event)}
                                >
                                    <MenuItem disabled value="" id="selectStatusBudgetReport">
                                        {t("Select SubscriptionType")}
                                    </MenuItem>
                                    {this.state.userSubscription?.length > 0 &&
                                        this.state.userSubscription?.map((item: any) => {
                                            return (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.attributes.title}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>
                            <Grid item>
                                <BoxView>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        data-test-id="selectStatusBudgetId"
                                        style={addAnotherUnit.selectmenu}
                                        value={this.state.selectedSalse}
                                        onChange={(event) => this.handleAccountChange(event)}
                                        id="select-Country"
                                    >
                                        <MenuItem
                                            disabled
                                            id="selectStatusBudgetReport"
                                            value=""
                                        >
                                            {t("Sales Manager")}
                                        </MenuItem>
                                        <MenuItem
                                            id="selectStatusBudgetReportAll"
                                            value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                        {this.state.salseManage?.length > 0 &&
                                            this.state.salseManage?.map((item: any) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.first_name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </BoxView>
                            </Grid>
                            <Grid item>
                                <BoxView>
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        data-test-id="selectAccountManagerId"
                                        style={addAnotherUnit.selectmenu}
                                        value={this.state.selectedAccount}
                                        onChange={(event) => this.handleManagerChange(event)}
                                        id="select-Country"
                                    >
                                        <MenuItem
                                            disabled
                                            id="selectStatusBudgetReport"
                                            value=""
                                        >
                                            {t("Account Manager")}
                                        </MenuItem>
                                        {this.state.account?.length > 0 &&
                                            this.state.account?.map((item: any) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.first_name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </BoxView>
                            </Grid>
                            <Grid item>
                                <Button
                                    className="btnMy"
                                    variant="contained"
                                    style={addAnotherUnit.mybtn}
                                    onClick={()=> this.handleSearch()}
                                    data-test-id="saveButton"
                                >
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    {t("Search")}
                                </Button>
                            </Grid>
                        </Grid>
                        {this.handleTable()}
                    </BoxView>
                    }
                    </BoxView>

                    {this.renderReportData()}

                    <Dialog aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth>
                        <DialogTitle id="customized-dialog-title" >
                            <BoxView style={addAnotherUnit.titleBox}>
                                <Typography className="bold-text" variant="h6">Configure time of never logged in users</Typography>
                                <CloseIcon onClick={() => this.setState({ open: false })}/>
                            </BoxView>
                        </DialogTitle>
                        <DialogContent dividers>
                            <InputBase
                                style={addAnotherUnit.inputType}
                                placeholder="Number of month(s)"
                                onChange={(event) => this.handleMonth(event)}
                                data-test-id="monthid"
                            />
                        </DialogContent>
                        <Typography style={addAnotherUnit.errorMsg}>{this.state.loginMonthError}</Typography>
                        <BoxView style={addAnotherUnit.ButtonBox}>

                            <Button variant="outlined" className="bold-text" style={addAnotherUnit.cancleButton} data-test-id="cancelButtonId" onClick={() => this.setState({ open: false })}>
                                CANCEL
                            </Button>

                            <Button variant="contained" className="bold-text" style={addAnotherUnit.confirmButton} data-test-id="confirmButtonId" onClick={() => this.handleConfirm()} >
                                CONFIRM
                            </Button>
                        </BoxView>
                    </Dialog>
                </CEHeaderComponentWeb>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const addAnotherUnit = {
  container: {
    padding: "40px",
  },
  subcontainer: {
    padding: "0px 40px",
  },
  selectmenu: {
    padding: "7px",
    border: "1px solid #F0F0F0",
    borderRadius: "15px",
    background: "#fff",
    width: "100%",
    borderBottom: "none",
    marginTop: "10px",
    gap: "10px",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ButtonBox: {
    padding: 20,
    justifyContent: "flex-end",
    display: "flex",
    gap: 15,
  },
  cancleButton: {
    padding: "5px 40px",
    border: "1px solid #2B6FED",
    color: "#2B6FED",
    fontWeight: 900,
  },
  confirmButton: {
    padding: "5px 40px",
    background: "#2B6FED",
    color: "#fff",
    fontWeight: 900,
  },
  inputType: {
    border: "1px solid darkgray",
    padding: "7px",
    borderRadius: "15px",
    background: "#fff",
    gap: "10px",
    width: "100%",
    marginTop: "10px",
  },
  mybtn: {
    color: "white",
    backgroundColor: "#2D6EED",
    textTransform: "capitalize" as "capitalize",
    padding: "12px 16px",
    marginTop: "15px",
  },
  Box: {
    height: "200px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    flexDirection: "column",
    display: "flex",
    paddingRight: "30px",
    paddingLeft: "30px",
    justifyContent: "space-evenly",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    alignItems: "flex-start",
    marginTop: "25px",
  },
  lable: {
    padding: "5px 15px",
    borderRadius: "20px",
    backgroundColor: "rgba(252, 132, 52, 0.1)",
    color: "#EBA370",
    width: "fit-content",
    fontWeight: 700,
    marginTop: "5px",
  },
  searchButtons: {
    border: "1px solid #f0f0f0",
    borderRadius: "5px",
    background: "white",
    height: "50px",
  },
  main: {
    padding: "15px",
    height: "85%",
    backgroundColor: "#fff",
    marginTop: "20px",
  },
  heading: {
    justifyContent: "space-between",
    display: "flex",
  },
  detail: {
    marginTop: "35px",
    backgroundColor: "rgba(221, 221, 221, 0.38)",
    color: "#191E26",
    textAlign: "center" as "center",
    padding: "10px 50px",
    borderRadius: "8px",
  },
  userData: {
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
  },
  tblData: {
    boxShadow: "none",
    marginTop: "20px",
  },
  tblpage: {
    justifyContent: "space-between",
    display: "flex",
  },
  selectTag: {
    background: "#2B6FED",
    padding: "10px 20px",
    marginTop: "35px",
    color: "#fff",
    borderRadius: "20px",
    cursor: "pointer",
  },
  unSelectTage: {
    background: "#EEF0F8",
    marginTop: "35px",
    color: "#8D8F98",
    padding: "10px 20px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  downloadReports: {
    color: "#fff",
    padding: "10px 20px",
    background: "#FC8434",
    borderRadius: "8px",
    cursor: "pointer",
  },
  errorMsg: {
    color: "red",
    marginLeft: "25px",
  },
  searchBox: {
    display: "flex",
  },
  title: {
    background: "#fff",
    padding: "20",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  filter: {
    fontSize: "14px",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    padding: "8px 12px",
    fontWeight: 500,
    color: "darkgray",
    minWidth: "168px",
    maxWidth: "168px",
    marginRight: "15px",
  },
  imageBox: {
    marginLeft: "20px",
  },
  error: {
    padding: "10px",
  },
};

export default withTranslation()(
    //@ts-ignore
    (withRouter(LoginReports))
);
// Customizable Area End