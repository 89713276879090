import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { emptyFilterValue, handleFilterComplexList } from "../../../components/src/UtilFunction.web";
export const configJSON = require("./config");

interface SelectOption {
  label: string;
  value: string;
}

const labels = [
  { key: "My Vehicles", value: "Vehicle" },
  { key: "My Documents", value: "MyDocument" },
  { key: "Visitors", value: "Visitor" },
  { key: "Incidents", value: "Incident" },
  { key: "Fee & Payments", value: "FeesPayments" },
  { key: "Facility Reservations", value: "FacilityReservation" },
  { key: "My Lease", value: "MyLease" },
  { key: "My Suggestions", value: "MySuggestion" },
  { key: "Classifieds", value: "Classified" },
  { key: "My Neighbors", value: "MyNeighbours" },
  { key: "My Profile", value: "MyProfile" },
  { key: "FAQs", value: "FAQ" },
  { key: "Chat", value: "Chat" },
  { key: "Announcements", value: "Announcement" },
  { key: "Surveys", value: "Survey" },
  { key: "Meetings", value: "Meetings" },
];

interface FilterList {
  company: string;
  isComplex: number;
  complex: string;
  building: string;
  country: string;
  city: string;
}

export interface Props {
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}
// Customizable Area End

export interface S {
    // Customizable Area Start
  open: boolean;
  anchorEl: any;
  userRole: string;

  analytics: { label: string; value: string; id: string }[];
  category: SelectOption[];
  filter: FilterList;

  reactStateList: SelectOption[];
  complexList: any[]; // remove any
  filterComplexList: SelectOption[];
  countriesList: SelectOption[];
  citiesList: SelectOption[];
  buildingList: SelectOption[];

  roleList: string[];
  role: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
  id: string;
    // Customizable Area End
}

export default class DashboardAnalyticsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  GetAnalyticsReactEStateCallId: string = "";
  GetAnalyticsComplexCallId: string = "";
  GetAnalyticsCountryCallId: string = "";
  GetAnalyticsCitiesCallId: string = "";
  GetAnalyticsBuildingCallId: string = "";
  GetAnalyticsDataCallId: string = "";
  GetAnalyticsCategoryCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      open: false,
      anchorEl: "",
      userRole: "",

      filter: {
        company: "",
        isComplex: 1,
        complex: "",
        building: "",
        country: "",
        city: "",
      },

      roleList: ["Resident", "Owner", "Chairman", "Vice Chairman", "Manager", "Property Manager"],
      role: "Resident",

      reactStateList: [],
      complexList: [],
      filterComplexList: [],
      buildingList: [],
      countriesList: [],
      citiesList: [],

      analytics: labels.map((label) => ({ label: label.key, value: "0", id: label.value })),
      category: [],
    };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetAnalyticsReactEStateCallId:
        return this.handleAnalyticsReactEstateResponse(responseJson);
      case this.GetAnalyticsCountryCallId:
        return this.handleAnalyticsCountryResponse(responseJson);
      case this.GetAnalyticsCitiesCallId:
        return this.handleAnalyticsCityListResponse(responseJson);
      case this.GetAnalyticsComplexCallId:
        return this.handleAnalyticsComplexListResponse(responseJson);
      case this.GetAnalyticsBuildingCallId:
        return this.handleAnalyticsBuildingListResponse(responseJson);
      case this.GetAnalyticsDataCallId:
        return this.handleAnalyticsResponse(responseJson);
      case this.GetAnalyticsCategoryCallId:
        return this.handleAnalyticsCategoryResponse(responseJson);
      default:
        break;
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJson);
    }
    ApiCatchErrorResponse(errorResponse);
    // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const user_role = localStorage.getItem("role") || "";
    this.setState({ userRole: user_role }, async () => {
      await this.getAnalyticsReactEstate();
      await this.getAnalyticsCountryList();
      await this.getAnalyticsData();
    });
  }

  getAnalyticsData = async () => {
    const { role } = this.state;
    const { company, complex, building, country, city } = this.state.filter;
    this.GetAnalyticsDataCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_dashboard/analysis_record/analysis_record_percentage?role=${role}&country=${emptyFilterValue(
        country
      )}&city=${emptyFilterValue(city)}&company=${emptyFilterValue(company)}&complex=${emptyFilterValue(
        complex
      )}&building=${emptyFilterValue(building)}`,
      method: "GET",
    });
  };

  handleAnalyticsResponse = (responseJson: { data: { [key: string]: number } }) => {
    if (responseJson && responseJson.data) {
      const dataMap: any = {
        vehicle: { key: "My Vehicles", value: "Vehicle" },
        my_document: { key: "My Documents", value: "MyDocument" },
        visitor: { key: "Visitors", value: "Visitor" },
        incident: { key: "Incidents", value: "Incident" },
        fees_payments: { key: "Fee & Payments", value: "FeesPayments" },
        facility_reservation: { key: "Facility Reservations", value: "FacilityReservation" },
        my_lease: { key: "My Lease", value: "MyLease" },
        my_suggestion: { key: "My Suggestions", value: "MySuggestion" },
        classified: { key: "Classifieds", value: "Classified" },
        my_neighbours: { key: "My Neighbors", value: "MyNeighbours" },
        my_profile: { key: "My Profile", value: "MyProfile" },
        faq: { key: "FAQs", value: "FAQ" },
        chat: { key: "Chat", value: "Chat" },
        announcement: { key: "Announcements", value: "Announcement" },
        survey: { key: "Surveys", value: "Survey" },
        meetings: { key: "Meetings", value: "Meetings" },
      };

      const analytics = Object.keys(responseJson.data).map((key: string) => ({
        label: dataMap[key].key,
        id: dataMap[key].value,
        value: responseJson.data[key].toString(),
      }));

      this.setState({ analytics: analytics });
    }
  };

  getAnalyticsReactEstate = async () => {
    this.GetAnalyticsReactEStateCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
      method: "GET",
    });
  };

  handleAnalyticsReactEstateResponse = (responseJson: {
    compneys: { company_name: string; id: string | number }[];
  }) => {
    if (responseJson && responseJson.compneys) {
      this.setState({
        reactStateList: responseJson.compneys.map((item: { company_name: string; id: string | number }) => ({
          label: item.company_name,
          value: item.id + "",
        })),
      });
    }
  };

  getAnalyticsComplexList = async (city: string, country: string) => {
    this.GetAnalyticsComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${country}`,
    });
  };

  handleAnalyticsComplexListResponse = (responseJson: { complexes: { data: [] } }) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.setState({ filterComplexList: handleFilterComplexList(1, this.state.complexList) });
      });
    }
  };

  getAnalyticsBuildingList = async (complexId: string | number) => {
    this.GetAnalyticsBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleAnalyticsBuildingListResponse = (responseJson: { buildings: { name: string; id: number | string }[] }) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: { name: string; id: number | string }) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  getAnalyticsCountryList = async () => {
    this.GetAnalyticsCountryCallId = await CEApiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleAnalyticsCountryResponse = (responseJson: { data: { countries: string[] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        countriesList: responseJson.data.countries.map((item: string) => ({ label: item, value: item })),
      });
    }
  };

  getAnalyticsCityList = async (country: string | number) => {
    this.GetAnalyticsCitiesCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${emptyFilterValue(country + "")}`,
    });
  };

  handleAnalyticsCityListResponse = (responseJson: { city_list: { city: string }[] }) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        citiesList: responseJson.city_list.map((item: { city: string }) => ({ label: item.city, value: item.city })),
      });
    }
  };

  getSubCategoryAnalysis = async (id: string) => {
    const { role } = this.state;
    this.GetAnalyticsCategoryCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/analysis_record/analysis_sub_record_percentage?role=${role}&feature=${id}`,
    });
  };

  handleAnalyticsCategoryResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      const formattedArray = Object.keys(responseJson.data).map((key: any) => {
        return { label: key.split("_").join(" "), value: responseJson.data[key] };
      });
      this.setState({ category: formattedArray });
    } else {
      this.setState({ category: [] });
    }
  };

  HandlePopover = (event: any, id: string) => {
    this.setState({ open: true, anchorEl: event.currentTarget }, () => {
      this.getSubCategoryAnalysis(id);
    });
  };

  HandleClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleDashboardNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardCompanyEmployee");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleAnalyticsFilterChange = (event: React.ChangeEvent<{ value: unknown | string }>, stateParam: string) => {
    const value = event.target.value as string;
    if (stateParam === "company") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            company: value,
            complex: "",
            building: "",
            country: "",
            city: "",
          },
        },
      );
    } else if (stateParam === "isComplex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            isComplex: Number(value),
          },
        },
        () =>
          this.setState({
            filterComplexList: handleFilterComplexList(Number(value), this.state.complexList),
          })
      );
    } else if (stateParam === "complex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            complex: value,
            building: "",
          },
        },
        () => this.getAnalyticsBuildingList(value)
      );
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building: value,
        },
      });
    } else if (stateParam === "country") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            country: value,
            city: "",
            complex: "",
            building: "",
          },
        },
        () => this.getAnalyticsCityList(value)
      );
    } else if (stateParam === "city") {
      this.setState({
        filter: {
          ...this.state.filter,
          city: value,
          complex: "",
          building: "",
        },
      }, () => this.getAnalyticsComplexList(this.state.filter.city, this.state.filter.country));
    }
  };

  handleChangeRoleTab = (role: string) => {
    this.setState({ role: role }, () => {
      this.getAnalyticsData();
    });
  };
    // Customizable Area End
}
