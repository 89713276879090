//Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { RouteComponentProps } from 'react-router-dom';
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
import { userRoleMapping } from "../../../components/src/AnnouncementsFilter";
import { setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import toast from "react-hot-toast";
export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  accountType: string,
  userRole: string,
  subscriptionDetailsData: any,
  loading: boolean,
  deactivatePlan: boolean,
  confirmDeactivatePlan: boolean,
  freezSubscription: boolean,
  openFreezUnfreezModel:boolean,
  openFreezSubmitmodel:boolean
  extendPlanOpenModel:boolean,
  upgradePlanOpenModel:boolean,
  extendRadio:string,
  extendPRNValue:string
  selectedDate:any,
  startDateType:string,
  upgradeRadio:string,
  upgradePRNValue:string
  upgradeSelectPlaneValue:string,
  propertyDeveloperModel:boolean,
  subscriptionPlans:[],
  extendError:string,
  extendPRNError:string,
  selecePlaneError:string,
  upgradePRNError:string,
  userRoleVal:string,
  configureModal:boolean,
  configureValue:any,
  planDetails:any,
  pathId:string,
  freezeReason:string,
  conformExtendplan:boolean,
  conformupgardeplan:boolean,
  upgradesucessfullState:boolean,
  extendSucessfullState:boolean,
  deactiveReasonModal:boolean,
  deactiveReason:string,
  configureState:boolean
}

interface SS {
  id: any;
}

export default class COOSubscriptionDetailsController extends CommonApiCallForBlockComponent<Props, S, SS> {

  GetSubscriptionDetails: any;
  PutSubscriptionFreez: any;
  PutSubscriptionUnFreez:any;
  PutSubscriptionDeactivate:any;
  GetSubscriptionPlans:any;
  GetSubscriptionPlanDetailsID:any;
  PostSubscriptionExtend:any;
  PutSubscriptionUpgrade:any;
  GetConfigureNotificationId:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      loading: false,
      accountType: '',
      userRole: '',
      planDetails:"",
      subscriptionDetailsData: "",
      deactivatePlan: false,
      confirmDeactivatePlan: false,
      freezSubscription: false,
      openFreezUnfreezModel: false,
      extendPlanOpenModel: false,
      freezeReason:"",
      configureModal:false,
      configureValue:"",
      extendRadio: 'paid',
      extendPRNValue: '',
      selectedDate: '',
      startDateType: 'text',
      upgradePlanOpenModel: false,
      upgradeRadio: 'paid',
      upgradePRNValue: '',
      upgradeSelectPlaneValue:"0",
      propertyDeveloperModel:false,
      subscriptionPlans:[],
      extendError:'',
      extendPRNError:'',
      selecePlaneError:'',
      upgradePRNError:'',
      userRoleVal:'',
      pathId:"",
      conformExtendplan:false,
      conformupgardeplan:false,
      openFreezSubmitmodel:false,
      upgradesucessfullState:false,
      extendSucessfullState:false,
      deactiveReasonModal:false,
      deactiveReason:"",
      configureState:false,

    };/*  */
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    const match: any = this.props.match;
    const { id} = match?.params; 
      this.setState({
        pathId:id
      })
    setStorageData("idees",id)
    await this.getSubscriptionDetails(id)
    const accountRole = localStorage.getItem("role")
    
    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, '')
      this.setState({ accountType: accountRoleName })
    }
    this.handleConfigure()
    this.handleNotification()
    this.ConfigureNotification()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetSubscriptionDetails:
          this.handleSubscriptionDetail(responseJson);
          break;
        case this.GetSubscriptionPlanDetailsID:
          this.handlePlanDetails(responseJson);
          break;
        case this.GetConfigureNotificationId:
          this.handleConfigureNotification();
          break;
        default:
          break;
      }
      this.modelResponsive(message)
      this.upgradeRespose(message)
        this.setState({ loading: false })
    }
  }
  handleConfigureNotification=()=>{
    this.setState({configureModal:false})
  }
  getSubscriptionPlans=async(id: any)=>{
    this.GetSubscriptionPlans = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_user_subs/plans?upgrade=true&current_plan=${id}`,
    })
  }
  handleConfigure=()=>{
    this.setState({configureState:true})
  }
  handleNotification=()=>{
    this.setState({configureState:false})
  }
  ConfigureNotification=()=>{
    this.setState({configureState:true})
  }

  getSubscriptionDetails = async (id:string) => {
    setStorageData("idees",id)
    this.GetSubscriptionDetails = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_user_subs/subscriptions/${id}`
    })
  }

  handleSubscriptionreport=()=>{
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'CESubscriptionReports'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
   getName(MessageEnum.NavigationMessage)
    }

  handleSubscriptionDetail (response: any) {
    if(response.data) {
      this.setState({ subscriptionDetailsData: response.data, userRole: userRoleMapping[response.data.attributes.user_type],freezSubscription:response.data.attributes.status=='freeze',userRoleVal:response.data.attributes.user_type},() => {this.getSubscriptionPlans(response.data.attributes.plan.data.id)})
    }
  }
  handlePlanDetails=(response: any)=>{
    this.setState({planDetails:response.data,propertyDeveloperModel:true })
  }

  formatDate (dateString: string) {
    const p = dateString.split(/\D/g)
    return [p[2], p[1], p[0]].join("-")
  }

  deactivatePlanModel = () => {
    this.setState({ deactivatePlan: !this.state.deactivatePlan,confirmDeactivatePlan: false })
  }

  deactiveReasonModal=()=>{
    this.setState({
      deactiveReasonModal:true,
      deactivatePlan:false
    })
  }

  deactiveReasonModalclose=()=>{
    this.setState({
      deactiveReasonModal:false
    })
  }

  confirmDeactivatePlan = async() => {
    const data = {
      "data": {
        "reason": this.state.deactiveReason
      }
    };
   
    this.PutSubscriptionDeactivate = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/deactivate_subscription/${this.state.subscriptionDetailsData.id}`,
      body: JSON.stringify(data)
    })

    this.setState({ confirmDeactivatePlan: true })
  }
  freezUnfreezPlan=()=>{
    this.setState({freezSubscription:!this.state.freezSubscription})
  }

  freezUnFreezModel=()=>{
    this.setState({openFreezUnfreezModel:!this.state.openFreezUnfreezModel})
  }
  freezUnFreezModelClose=()=>{
    this.setState({openFreezUnfreezModel:false})
  }
  addReason=(event:ChangeEvent<HTMLTextAreaElement>)=>{

    this.setState({
      freezeReason:event.target.value
    })
  }
  addReasondeactive=(event:ChangeEvent<HTMLTextAreaElement>)=>{

    this.setState({
      deactiveReason:event.target.value
    })
  }
  submitFreezereason=()=>{
    this.freezModel()
  }
  freezModel=async()=>{
    const data = {
      "data": {
        "reason": this.state.freezeReason
      }
    };
   
    this.PutSubscriptionFreez = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/freeze_subscription/${this.state.subscriptionDetailsData.id}`,
      body: JSON.stringify(data)
    })
  }

  unfreezModel=async()=>{
    const data = {
      "data": {
        "reason": "Unfreeze reason description"
      }
    };
   
    this.PutSubscriptionUnFreez = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/unfreeze_subscription/${this.state.subscriptionDetailsData.id}`,
      body: JSON.stringify(data)
    })
  }
  freezModelsubmit=()=>{
    this.setState({
      openFreezSubmitmodel:!this.state.openFreezSubmitmodel,
      openFreezUnfreezModel:false
    })
  }
  freezCloseSubmitmodel=()=>{
    this.setState({
      openFreezSubmitmodel:false,
    })
  }


  freezUnfreezPlanModel=()=>{
    this.state.freezSubscription?this.unfreezModel():this.freezModelsubmit()
  }

  freezUnfreezTitle=()=>{
    return this.state.freezSubscription ? configJSON.unfreezeSubscription:configJSON.freezeSubscription
  }
  freezUnfreezDiscription=()=>{
   return this.state.freezSubscription ? configJSON.unfreezDetails:configJSON.freezDetails
  }

  changeExtendDuration=(value:string)=>{
    this.setState({extendRadio:value})
  }

  extendPlanConform = () => {
    const { selectedDate, extendPRNValue, extendRadio } = this.state;
    if (selectedDate === '') {
      this.setState({ extendError: this.state.selectedDate == '' ? 'add correct date' : ''})
    }
    if (extendRadio === 'paid' && extendPRNValue === '') {
      this.setState({ extendError: this.state.selectedDate == '' ? 'add correct date' : '', extendPRNError: this.state.extendPRNValue == '' ? 'add correct number' : '' })
    }
    else {
      this.setState({
        conformExtendplan: !this.state.conformExtendplan,
        extendPlanOpenModel: !this.state.extendPlanOpenModel
      })
    }
  }
  extendPlanConformClose=()=>{
    this.setState({
      conformExtendplan:false,
    })
  }
  handletitleHead=()=>{
    if(this.state.subscriptionDetailsData.attributes?.user_type==="Owner"){
    return this.state.subscriptionDetailsData.attributes?.owner_name
    }
    return this.state.subscriptionDetailsData.attributes?.subscriber_name
  }

 

  extendPlaneSubmit=async()=>{
      const data={"data": {
        "expiry_date":this.state.selectedDate.replace(/-/g, '/'),
        "paid_extension:": this.state.extendRadio==='paid',
        "payment_reference_number": parseInt(this.state.extendPRNValue)
    }}

    this.PostSubscriptionExtend = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/extend_subscription/${this.state.subscriptionDetailsData.id}`,
      body: JSON.stringify(data)
    })
  }

  extendPlanModelOpen=()=>{
    this.setState({extendPlanOpenModel:true,startDateType :'text'})
  }
  extendPlanModelClose=()=>{
    this.setState({extendPlanOpenModel:false,startDateType :'text'})
  }

  changePRNExtendData = (value: string) => {
    const numValue = this.getNumValue(value);  
    this.setState({ extendPRNValue: numValue }, this.validateForm); 
  };
  modelResponsive=(message: Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId == this.PutSubscriptionFreez) {
      if(responseJson.message){
        this.setState({freezSubscription:true,openFreezUnfreezModel:false,openFreezSubmitmodel:false})
        this.getSubscriptionDetails(this.state.pathId)
      }
    }
    if (apiRequestCallId == this.PutSubscriptionUnFreez) {
      if(responseJson.message){
      toast.success(responseJson.message);
      this.setState({freezSubscription:false,openFreezUnfreezModel:false,openFreezSubmitmodel:false})
      this.getSubscriptionDetails(this.state.pathId)
      }
    }
    if (apiRequestCallId == this.PutSubscriptionDeactivate) {
      if(responseJson.message){
      toast.success(responseJson.message);
      this.deactive()
      this.getSubscriptionDetails(this.state.pathId)
      }
    }
    if (apiRequestCallId == this.PostSubscriptionExtend) {
      this.extendResponse(responseJson)
    }
  }
  deactive=()=>{
    this.setState({ confirmDeactivatePlan: true,deactiveReasonModal:false,deactivatePlan:true })
  }
  extendResponse=(responseJson: { message: string, error: string })=>{
    if (responseJson.message) {
      toast.success(responseJson.message);
     this.extendPlanConformClose()
     this.extendPlanopenModel()
     this.setState({selectedDate: '', extendRadio: 'paid', extendPRNValue: '',extendError:'',extendPRNError:'' })
     this.getSubscriptionDetails(this.state.pathId)
    }else if(responseJson.error){
      this.setState({ extendError:responseJson.error.includes('Date')? responseJson.error:'',extendPRNError:''})
    }
  }
  extendPlanopenModel=()=>{
    this.setState({
      extendSucessfullState:true
    })
  }
  extendPlanModel=()=>{
    this.setState({
      extendSucessfullState:false
    })
  }
  upgradeRespose=(message: Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId == this.GetSubscriptionPlans) {
      const activePlan = this.state.subscriptionDetailsData?.attributes?.plan?.data?.id
      this.setState({ subscriptionPlans: responseJson.data?.filter((plan:{id: string}) => plan.id !== activePlan) })
      }

    if (apiRequestCallId == this.PutSubscriptionUpgrade) { 
      if (responseJson.message) {
        toast.success(responseJson.message)
        this.upgradePlanConformClose()
        this.upgradePlanopenModel()
     this.getSubscriptionDetails(this.state.pathId)
        this.setState({upgradeSelectPlaneValue: '0', upgradeRadio: 'paid', upgradePRNValue: '' ,selecePlaneError:'',upgradePRNError:''})
      }
    }
  }
  upgradePlanopenModel=()=>{
    this.setState({
      upgradesucessfullState:true
    })
  }
  upgradePlanModel=()=>{
    this.setState({
      upgradesucessfullState:false
    })
  }


  handleStartDate = () => {
    this.setState({
      startDateType: this.state.startDateType === 'text'||!!this.state.selectedDate ? 'date' : 'text'
    })
  }
 
  setFieldValue = (value: string) => {
    this.setState({ selectedDate: value }, this.validateForm); 
  };

  validateForm = () => {
    const { selectedDate, extendPRNValue, extendRadio } = this.state;
    const dateError = selectedDate === '' ? 'add correct date' : '';
    const prnError = extendRadio === 'paid' && extendPRNValue === '' ? 'add correct number' : '';
    this.setState({ extendError: dateError, extendPRNError: prnError });
  };

 
  upgradePlanModelOpen=()=>{
    this.setState({upgradePlanOpenModel:true})
  }
  upgradePlanModelClose=()=>{
    this.setState({upgradePlanOpenModel:false})
  }
  upgradePlanConform=()=>{
    const { upgradeSelectPlaneValue, upgradePRNValue, upgradeRadio} = this.state;
    if (upgradeSelectPlaneValue === '0') {
      this.setState({ selecePlaneError: this.state.upgradeSelectPlaneValue == '0' ? 'select plan' : ''})
    }
    if (upgradeRadio === 'paid' && upgradePRNValue === '') {
      this.setState({ selecePlaneError: this.state.upgradeSelectPlaneValue == '0' ? 'select plan' : '', upgradePRNError: this.state.upgradePRNValue == '' ? 'add correct number' : '' })
    }
    else{
    this.setState({
      conformupgardeplan:!this.state.conformExtendplan,
      upgradePlanOpenModel:false
    })}
  }
  upgradePlanConformClose=()=>{
    this.setState({
      conformupgardeplan:false,
    })
  }

   isRequestStatus = (status:any) => 
    ["freeze_request", "extend_request", "upgrade_request", "deactive_request","pending"].includes(status);

  changeUpgradeDuration=(value:string)=>{
    this.setState({upgradeRadio:value})
  }

  changePRNData = (value: string) => {
    const numValue = this.getNumValue(value); 
    this.setState({ upgradePRNValue: numValue }, this.validateFormUpgrade);  
  };

  upgradeSelectPlane = (value: string) => {
    this.setState({ upgradeSelectPlaneValue: value || '' }, this.validateFormUpgrade);  
  };

  validateFormUpgrade = () => {
    const { upgradeSelectPlaneValue, upgradePRNValue, upgradeRadio } = this.state;
    let selectPlanError = '';
    let prnError = '';
  
    if (upgradeSelectPlaneValue === '0') {
      selectPlanError = 'select plan';
    }
    if (upgradeRadio === 'paid' && upgradePRNValue === '') {
      prnError = 'add correct number';
    }
  
    this.setState({ selecePlaneError: selectPlanError, upgradePRNError: prnError });
  };

  upgradePlaneSubmitModel = async () => {
      const data = {
        "data": {
          "plan_id": parseInt(this.state.upgradeSelectPlaneValue),
          "paid_extension:": this.state.upgradeRadio === 'paid',
          "payment_reference_number": parseInt(this.state.upgradePRNValue)
        }
      }
      this.PutSubscriptionUpgrade = await apiCall({
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_custom_user_subs/upgrade_subscription_plan/${this.state.subscriptionDetailsData.id}`,
        body: JSON.stringify(data)
      })
  }

 
  deactivateModelTitle=()=>{
    return this.state.confirmDeactivatePlan ? configJSON.planDeactivatedfirstModel :configJSON.planDeactivatePlanSecondModel
  }
  deactivateModelTitleCoo=()=>{
    return this.state.confirmDeactivatePlan ? configJSON.planDeactiveDiscCoo :configJSON.planDeactivatePlanSecondModel
  }

  getNumValue=(value:string)=>{
    return value.split('').reduce((acc, char) => {
      if (/\d/.test(char)) {acc += char;}
      return acc;
    }, '');
  }
  deactivateModelDiscription=()=>{
    return this.state.confirmDeactivatePlan ?configJSON.planDeactivatedfirstModelDisc:configJSON.planDeactivatePlanSecondModelDisc
  }
  deactivateModelDiscriptionCoo=()=>{
    return this.state.confirmDeactivatePlan ?configJSON.planDeactiveModelCoo:configJSON.planDeactivatePlanSecondModelDisc
  }
  deactivateBtnStyle = (disableMainBtnActive: object, mainBtnActive: object) => { return (this.state.freezSubscription || this.state.subscriptionPlans.length ===0) ? disableMainBtnActive : mainBtnActive }
  extendStyle = (extendssubRadiotitlesCheck: object, extendssubRadiotitles: object) => { return this.state.extendRadio === 'free' ? extendssubRadiotitlesCheck : extendssubRadiotitles }
  showStyleBtn = (disableMainBtn:object ,mainBtn:object) => { return this.state.freezSubscription ? disableMainBtn :mainBtn }
  managepropertyDeveloperModel=async()=>{
    if(this.state.propertyDeveloperModel){
      this.setState({propertyDeveloperModel:false})
    }else{
      this.GetSubscriptionPlanDetailsID = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.getPlanDetailsEndPoint+`/${this.state.subscriptionDetailsData?.attributes?.plan?.data?.id}`,
      })
    }
  }

  formateDate = (date:string) => {
    const expiryDate = new Date(date);
    const formattedDay = expiryDate.getDate();
    const formattedMonth = expiryDate.toLocaleString('default', { month: 'long' });
    const formattedYear = expiryDate.getFullYear();
    return `${formattedDay} ${formattedMonth} ${formattedYear}`
  }

  handleGoToStatisticalReport = () => {
    if(this.state.userRoleVal === "Real Estate Company") { 
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CEPropertyDeveloper");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else if (this.state.userRoleVal === "General Assembly") {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CEGAMemberReport");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else if (this.state.userRoleVal === "Owner") {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CEOwnerStatisticalReport");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CEPropertyManager");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
}
// Customizable Area End

