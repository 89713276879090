import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ChangeEvent, MouseEvent } from "react";
import { Message } from "framework/src/Message";
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    classes?: any;
    t: (value: string) => string;
    i18n: {
      language: string;
    }
}

interface S {
    activeSection:string;
  openPopoverIndex: number | null;
  anchorEl: HTMLElement | null;
  dashboardTebleData: Array<DashboardTebleDataItem>;
  userRole: string | null;
  pagination: PaginationData;
  page: string;
  searchInputText: string;
  loader: boolean;
  showErrorMessage: boolean;
  errorMessage: string;
  dashboardData: DashboardSuccessResponse;
}


export interface I18Interface{
  language: string;
}
export interface DashboardTebleDataItem {
  id: string;
  attributes: {
    id: number;
    complex: {
      id: number;
      name: string;
    } | null;
    building: string;
    name: {
      id: number;
      full_name: string;

    };
    chairman: null | string;
    ending_in: number;
    city: string;
    account_manger: null | string;
    ending_on: null | string;
  }
}

export interface PaginationData {
  current_page: null | number;
  next_page: null | number;
  prev_page: null | number;
  total_pages: null | number;
  total_count: null | number;
}

export interface SubscritionDataResponse {
  data: Array<DashboardTebleDataItem>;
  meta: {
    pagination: PaginationData
  }
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  contract: string
}

export interface DashboardSuccessResponse {
  data: {
    Total_company: number | null;
    Total_complex: number | null;
    Total_individual_building: number | null;
    Assigned_sales_manager: number | null;
    Assigned_account_manager: number | null;
    Total_open_tickets: number | null;
    Total_owner_account: number | null;
    Total_resident_account: number | null;
    Total_manager_account: number | null;
    Total_account: number | null;
  }
}
export type TFunction = (value: string) => string;

interface SS {
    id: any;
}

export default class AccManMainScreenController extends CommonApiCallForBlockComponent<Props, S, SS> {
  subscriptionListApiCallId: string = "";
  dashboardDataApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];
        this.state = {
           activeSection: 'home',  
          openPopoverIndex: null,
          anchorEl: null,
          dashboardTebleData: [],
          userRole: "",
          pagination: {
            current_page: 0,
            next_page: 0,
            prev_page: null,
            total_pages: 0,
            total_count: 0,
          },
          page: "",
          searchInputText: "",
          loader: false,
          showErrorMessage: false,
          errorMessage: "",
          dashboardData: {
            data: {
              Total_company: 0,
              Total_complex: 0,
              Total_individual_building: 0,
              Assigned_sales_manager: 0,
              Assigned_account_manager: 0,
              Total_open_tickets: 0,
              Total_owner_account: 0,
              Total_resident_account: 0,
              Total_manager_account: 0,
              Total_account: 0,
            }
          }
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        
    }
    async componentDidMount() {
        await super.componentDidMount();
        this.handleUserRole();
        this.getSubscriptionList("1","");
        this.getDashboardData();
      };

  templateListingApiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")
    let leaseManagmentMainrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
     body && type !== "formData"
      ? leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
: leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(leaseManagmentMainrequestMessage.id, leaseManagmentMainrequestMessage);
    return leaseManagmentMainrequestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.dashboardResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.leaseManagmentMainResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  dashboardResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: SubscritionDataResponse & DashboardSuccessResponse) => {
    if (apiRequestCallId === this.subscriptionListApiCallId) {
      this.subscriptionListingSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.dashboardDataApiCallId) {
      this.dashboardDataSucessCallBack(responseJson);
    }

  };

  leaseManagmentMainResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.subscriptionListApiCallId) {
      this.subscriptionListingFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.dashboardDataApiCallId) {
      this.dashboardDataFailureCallBack(responseJson);
    }
  };

  getSubscriptionList = async (page: string, searchInput: string) => {
    this.setState({ loader: true })
    this.subscriptionListApiCallId = await this.templateListingApiCall({
      method: configJSON.subscriptionListMethod,
      endPoint: `${configJSON.subscriptionListEndpoint}${page}&search=${searchInput}`,
    });
  };

  subscriptionListingSucessCallBack = (response: SubscritionDataResponse) => {
    if (response.meta.pagination !== null) {
      this.setState({ dashboardTebleData: response.data, pagination: response.meta.pagination, loader: false });
    } else {
      this.setState({
        dashboardTebleData: response.data, pagination: {
          current_page: 0,
          next_page: 0,
          prev_page: null,
          total_pages: 0,
          total_count: 0,
        }, loader: false
      });
    }
  };

  subscriptionListingFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showErrorMessage: true, errorMessage: response.errors[0].contract, loader: false });
  };

  getDashboardData = async () => {
    this.setState({ loader: true })
    this.dashboardDataApiCallId = await this.templateListingApiCall({
      method: configJSON.subscriptionListMethod,
      endPoint: configJSON.dashboardDataEndPoint,
    });
  };

  dashboardDataSucessCallBack = (response: DashboardSuccessResponse) => {
    this.setState({ dashboardData: response, loader: false });
  };

  dashboardDataFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showErrorMessage: true, errorMessage: response.errors[0].contract, loader: false });
  };

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: String(value) }, () => {
      this.getSubscriptionList(this.state.page, "");
    });
  };

  handleUserRole = () => {
    const storedRole = localStorage.getItem('role');
    this.setState({ userRole: storedRole });
  };

  handleClick = (event: MouseEvent<HTMLElement>, index: number) => {
    this.setState({ anchorEl: event.currentTarget, openPopoverIndex: index });
  };

  handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchInputText: event.target.value }, () => {
      this.getSubscriptionList("1", this.state.searchInputText);
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openPopoverIndex: null });
  };

  handleNavigation = (pathName: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), pathName);
    this.send(msgData);
  };

  handleCloseError = () => {
    this.setState({ showErrorMessage: true, errorMessage: "" });
  };
     
}
 // Customizable Area End

