import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Link,
  Card,
  CardMedia,
  Button,
  withStyles,
  InputAdornment,
  Select,
  InputLabel,
  Dialog,
  IconButton,
  DialogContent,
  Input,
  DialogActions,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "../../dashboard/src/Dashboard.web.css";
import "../assets/css/style.scss";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Formik, Form } from "formik";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { delete_icon, user_icon, email_icon, building, unit } from "./assets";
import CommunityUserProfileController, { Props } from "./communityManagementController.web";
import { CommunityStyle } from "./CommunityStyle.web";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { NoUserIcon } from "../../LeadManagement/src/assets";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";

const dashBoard = {
  profileImage: {
    borderRadius: "100%",
    width: 70,
    height: 70,
    border: "1px solid #f0f0f0",
  },
};
// Customizable Area End

class AdminAwaitingAcceptance extends CommunityUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAwaitInvitation();
    this.getBuilding();
    this.getUserType();
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container className={classes.communityDashboard}>
            <Box className="navigation-chairman admin-accept-head-box">
              <Box className="admin-accept-heading-box">
                <Typography variant="body1">
                  {t("Community Management")} /{" "}
                  <Link href="/CommunityRequestManagement">{t("Requests Management")}</Link> /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Sent invitations awaiting acceptance")}
                  </Box>
                </Typography>
              </Box>
              <Box className="sub-heading-box admin-accept-heading">
                <Typography variant="h5" className="bold-text">
                  {t("Sent invitations awaiting acceptance")}
                </Typography>
              </Box>
            </Box>

            <Box className="chairman-filter">
              <Box className="action-filter-box admin-accept-filter-box">
                <select
                  onChange={(e: any) => {
                    const selectedBuilding = e.target.value;
                    this.setState({ filter: { ...this.state.filter, building: selectedBuilding } }, () => {
                      if (selectedBuilding !== "") {
                        this.getUnit(selectedBuilding);
                      }
                    });
                  }}
                  name="building"
                  className="select-year admin-await-select-building"
                  value={this.state.filter.building}
                >
                  <option value="">{t("Select Building")}</option>
                  {this.state.allBuilding.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <select
                  className="select-year admin-await-select-unit"
                  value={this.state.filter.unit}
                  onChange={(e: any) => this.setState({ filter: { ...this.state.filter, unit: e.target.value } })}
                >
                  <option value="">{t("Select Unit")}</option>
                  {this.state.allUnit.map((item: any) => (
                    <option value={item.apartment_name}>{item?.apartment_name}</option>
                  ))}
                </select>
                <select
                  className="select-year admin-await-select-type"
                  value={this.state.filter.userType}
                  onChange={(e: any) => this.setState({ filter: { ...this.state.filter, userType: e.target.value } })}
                >
                  <option value="">{t("Select User Type")}</option>
                  {this.state.allUserType.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {languageCondition(language, item.attributes.name_ar, item.attributes.name)}
                    </option>
                  ))}
                </select>
                <Button
                  startIcon={<SearchIcon />}
                  className="admin-await-filter-button"
                  onClick={() => {
                    this.setState(
                      {
                        selctedUnit: this.state.filter.unit,
                        selectedBUilding: this.state.filter.building,
                        selectedUserType: this.state.filter.userType,
                      },
                      () => {
                        this.getAwaitInvitation();
                      }
                    );
                  }}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>

            <Grid container spacing={2} style={{ marginBottom: 30 }}>
              {this.state.allInvitation.map((invitation: any, index: any) => {
                return (
                  <Grid item sm={12} md={6} lg={3} key={invitation.id}>
                    <Card className="user-details await-accept-user">
                      <CardMedia component="img" image={NoUserIcon} alt="image" style={dashBoard.profileImage} />
                      <h4 className="bold-text" style={{ margin: 0 }}>
                        {invitation.attributes?.apartment_management?.apartment_name || "N/A"}
                      </h4>
                      <p style={{ margin: 0 }}>{invitation.attributes.full_name}</p>
                      <p className="invitation-sent-on">
                        {t("Invitation sent on")}: {moment(invitation.attributes.created_at).format("MMM DD, YYYY")}
                      </p>
                      <Box className="roles-box" style={{ margin: 0 }}>
                        <span className="role">{invitation.attributes.role.name}</span>
                      </Box>
                      <Box className={languageCondition(language, "arabic-menu", "user-menu admin-await-menu")}>
                        <Menu menuButton={<MoreVertIcon />}>
                          <MenuItemMenu
                            className="admin-await-resend-menu"
                            onClick={() => {
                              this.setState({ selectInvitation: invitation }, () => {
                                if (invitation.attributes?.building_management?.id) {
                                  this.getUnit2(
                                    invitation.attributes.role.name,
                                    invitation.attributes?.building_management?.id
                                  );
                                }
                                this.handleResendReqModal();
                              });
                            }}
                          >
                            {t("Resend Request")}
                          </MenuItemMenu>
                          <MenuItemMenu
                            className="admin-await-delete-menu"
                            onClick={() => {
                              this.setState({ selectInvitation: invitation }, () => {
                                this.handleDeleteReqModal();
                              });
                            }}
                          >
                            {t("Delete Invitation Request")}
                          </MenuItemMenu>
                        </Menu>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </GeneralHeaderComponentWeb>

        <Dialog
          className="delete-document delete-document-dialog admin-accept-delete-modal"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="sm"
          onClose={() => this.handleDeleteReqModal()}
          open={this.state.setDeleteRequest}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={delete_icon} alt="delete" />
              <Typography variant="h6" className="bold-text">
                {t("Delete Invitation Confirmation")}
              </Typography>
              <Typography variant="body1">
                {t("Are you sure want to delete invitation request sent to")}{" "}
                <b>{this.state?.selectInvitation?.attributes?.full_name}</b> {t("for Unit")}{" "}
                <b>{this.state.selectInvitation?.attributes?.apartment_management?.apartment_name || "N/A"}</b>
              </Typography>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button
                  className="cancel-button"
                  onClick={() => this.handleDeleteReqModal()}
                  style={{ height: "50px" }}
                >
                  {t("Close")}
                </Button>
                <Button
                  style={{ height: "50px", margin: "0" }}
                  className="add-button"
                  onClick={() => {
                    this.deleteRequest();
                    this.handleDeleteReqModal();
                  }}
                >
                  {t("Yes, Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          className="edit-profile edit-unit invite-user-modal"
          open={this.state.setRequestOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <MuiDialogTitle disableTypography className="dialog-heading admin-invitation-heading-box">
            <Typography variant="h6" className="bold-text">
              {t("Resend Request")}
            </Typography>
            <IconButton className="admin-invitation-close-icon" onClick={() => this.handleResendReqModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Formik
            onSubmit={(values, { resetForm }) => {
              if (this.checkUserPhoneValidation(values)) {
                this.updateInvitation(values);
                this.handleResendReqModal();
                resetForm();
              }
            }}
            initialValues={{
              building: this.state.selectInvitation?.attributes?.building_management?.id || "",
              phoneno: this.state.selectInvitation?.attributes?.phone_number?.split(" ")[1],
              email: this.state.selectInvitation?.attributes?.email_address,
              fullname: this.state.selectInvitation?.attributes?.full_name,
              unit: this.state.selectInvitation?.attributes?.apartment_management?.id || "",
              usertype: this.state.selectInvitation?.attributes?.role.id,
              countryCode: this.state.selectInvitation?.attributes?.phone_number?.split(" ")[0],
            }}
            enableReinitialize={true}
            validationSchema={this.InvitationSchema()}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3} className="edit-building">
                      <Grid item md={6}>
                        <InputLabel>{t("Select User Type")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={user_icon} alt="" />
                          <Select
                            onBlur={handleBlur}
                            value={values.usertype}
                            displayEmpty
                            name="usertype"
                            variant="filled"
                            onChange={(e: any) => {
                              setFieldValue("usertype", e.target.value);
                              setFieldValue("unit", "");
                              setFieldValue("building", "");
                            }}
                            readOnly
                            input={<OutlinedInput />}
                            className="select-input admin-invite-user-type"
                          >
                            <MenuItem
                              className={languageCondition(language, "select-arabic-menu", "")}
                              disabled
                              value=""
                            >
                              {t("Select User Type")}
                            </MenuItem>
                            {this.state.allUserType.map((item: any) => (
                              <MenuItem
                                value={item.id}
                                className={languageCondition(language, "select-arabic-menu", "")}
                                key={item.id}
                              >
                                {item.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.usertype, errors.usertype)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Member full name")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            onBlur={handleBlur}
                            className="input-with-icon"
                            fullWidth
                            placeholder={t("Member full name")}
                            value={values.fullname}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={user_icon} alt="icon" />
                              </InputAdornment>
                            }
                            name="fullname"
                          />
                        </Box>
                        {ErrorHandler(t, touched.fullname, errors.fullname)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Email Address")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            placeholder={t("Enter Email Address")}
                            fullWidth
                            className="input-with-icon"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={email_icon} alt="icon" />
                              </InputAdornment>
                            }
                            name="email"
                            value={values.email}
                            readOnly
                          />
                        </Box>
                        {ErrorHandler(t, touched.email, errors.email)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Phone Number")}</InputLabel>
                        <Box className="measurement-modal-box request-phone-number">
                          <PhoneInput
                            value={values.countryCode}
                            onChange={(e: any) => {
                              setFieldValue("countryCode", `+${e}`);
                            }}
                            inputProps={{ name: "selectCode" }}
                            country={"us"}
                            enableSearch={true}
                          />
                          <Input
                            onChange={handleChange}
                            value={values.phoneno}
                            className="input-with-icon"
                            placeholder={t("Phone Number")}
                            onBlur={handleBlur}
                            name="phoneno"
                            fullWidth
                          />
                        </Box>
                        {ErrorHandler(t, touched.phoneno, errors.phoneno)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Select Building")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={building} alt="" />
                          <Select
                            name="building"
                            value={values.building}
                            displayEmpty
                            onBlur={handleBlur}
                            variant="filled"
                            onChange={(e: any) => {
                              setFieldValue("building", e.target.value);
                              this.getUnit2(this.handleUserType(values.usertype), e.target.value);
                            }}
                            disabled={this.handleDisable(this.handleUserType(values.usertype))}
                            input={<OutlinedInput />}
                            className="select-input admin-invite-building"
                          >
                            <MenuItem
                              disabled
                              className={languageCondition(language, "select-arabic-menu", "")}
                              value=""
                            >
                              {t("Select Building")}
                            </MenuItem>
                            {this.state.allBuilding.map((item: any) => (
                              <MenuItem
                              className={languageCondition(language, "select-arabic-menu", "")}
                              key={item.id}
                              value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Select Unit")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={unit} alt="" />
                          <Select
                            name="unit"
                            variant="filled"
                            className="select-input"
                            value={values.unit}
                            disabled={this.handleDisable(this.handleUserType(values.usertype))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            input={<OutlinedInput />}
                            displayEmpty
                          >
                            <MenuItem
                              value=""
                              className={languageCondition(language, "select-arabic-menu", "")}
                              disabled
                            >
                              {t("Select Unit")}
                            </MenuItem>
                            {this.state.allUnit.map((item: any) => (
                              <MenuItem
                              className={languageCondition(language, "select-arabic-menu", "")}
                              key={item.id}
                                value={item.id}
                              >
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.unit, errors.unit)}
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button
                      style={{ height: "50px" }}
                      onClick={() => this.handleResendReqModal()}
                      className="cancel-button"
                    >
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ height: "50px", margin: "0" }}>
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertErrorWeb
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={this.state.error}
          show={this.state.showError}
        />
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withStyles(CommunityStyle)(withRouter(AdminAwaitingAcceptance)));
