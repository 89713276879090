// Customizable Area Start
import React from "react";
import ComplexBuildingDetailsController, { Props } from "./ComplexBuildingDetailsController.web";
import { withRouter } from "react-router-dom";
import BuildingDetailsPage from "./BuildingDetailsPage.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class ComplexBuildingDetails extends ComplexBuildingDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;

    return (
      <div>
        <CEHeaderComponentWeb>
          <BuildingDetailsPage companyId={this.state.companyId} navigation={navigation} id={"complex_listings"} history={history} />
        </CEHeaderComponentWeb>
      </div>
    );
  }
}

export default withRouter(ComplexBuildingDetails);
// Customizable Area End
