// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

import { RouteComponentProps } from "react-router-dom";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { dataURLtoFile, toDataURL } from "../../../../components/src/UtilFunction.web";

export const configJSON = require("../config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface SharedAreaEditForm {
  photos: any[];
  details: string;
  totalArea: string;
  fees: string;
  floorPlan: any;
  floorPlanName: string;
  measurement: string;
}

export interface Reservation {
  id: string;
  type: string;
  attributes: {
    reserved_on: string;
    status: string;
    building: {
      building: string;
    };
    reserved_by: {
      name: string;
    };
    duration: {
      duration: string;
    };
    apartment_management: null | {
      apartment_name: string;
    };
  };
}

interface S {
  loading: boolean;

  sharedAreaId: string;
  details: string;
  complexId: string;

  sharedArea: any;
  imageBoxData: any;
  photoIndex: number;
  reservationData: any;
  selectedBuilding: string;
  setEditOpen: boolean;
  editForm: SharedAreaEditForm;
}

interface SS {
  id: any;
}

export default class SharedAreaDetailsController extends CommonApiCallForBlockComponent<Props, S, SS> {
  sharedApiCall: string = "";
  getUpcomingReservationListCallId: string = "";
  GetUpcomingReservationListCallId3: string = "";
  editSharedAreaSave: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      loading: false,

      sharedAreaId: "",
      details: "",
      complexId: "",

      sharedArea: null,
      imageBoxData: false,
      photoIndex: 0,
      reservationData: [],
      selectedBuilding: "",
      setEditOpen: false,
      editForm: {
        photos: [],
        details: "",
        totalArea: "",
        fees: "",
        floorPlan: null,
        floorPlanName: "",
        measurement: "sqft",
      },
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (responseJson) {
        switch (apiRequestCallId) {
          case this.sharedApiCall:
            return this.handleSharedSucc(responseJson);
          case this.getUpcomingReservationListCallId:
            return this.handleUpcomingReservationSucc(responseJson);
          case this.editSharedAreaSave:
            return this.handleEditSucc();
          default:
            break;
        }
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    const unit_id = this.props.navigation.getParam("id");
    const complex_id = this.props.navigation.getParam("cId");
    const details = this.props.navigation.getParam("details");
    this.setState({ sharedAreaId: unit_id, details: details, complexId: complex_id }, () => {
      this.sharedAreaDetails();
      this.getUpcomingReservation();
    });
  }

  handleSharedSucc = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ sharedArea: responseJson.data?.attributes });
    }
  };

  handleUpcomingReservationSucc = (responseJson: { data: Reservation[] }) => {
    this.setState({ reservationData: responseJson.data });
  };

  slider: any;
  uploadFile: any;
  uploadImages: any;

  nextImage = () => {
    this.slider.slickNext();
  };
  previousImage = () => {
    this.slider.slickPrev();
  };

  handleSharedAreaEditModal = () => {
    this.setState({ setEditOpen: true });
  };

  handleSharedAreaEditModalCloseVal = () => {
    this.setState({ setEditOpen: false });
  };

  openSharedAreaEditModal = async () => {
    const imageUrlPromise: any[] = this.state.sharedArea.photos.map(async (image: any) => {
      return new Promise(async (resolve, reject) => {
        let blobString = await toDataURL(image.url);
        resolve(blobString);
      });
    });
    let photos = await Promise.allSettled(imageUrlPromise);

    this.handleSharedAreaEditState(photos);
  };

  handleSharedAreaEditState = (photos: any) => {
    this.setState(
      {
        loading: false,
        editForm: {
          measurement: this.state.sharedArea.unit_of_mesurment || "",
          photos: photos.map((image: any) => image.value),
          details: this.state.sharedArea.details || "",
          totalArea: this.state.sharedArea.total_area || "",
          fees: this.state.sharedArea.reservation_fee || "",
          floorPlan: this.state.sharedArea.floor_plan ? this.state.sharedArea.floor_plan.url : "",
          floorPlanName: this.state.sharedArea.floor_plan ? this.state.sharedArea.floor_plan.file_name : "",
        },
      },
      () => {
        this.handleSharedAreaEditModal();
      }
    );
  };

  sharedAreaDetails = async () => {
    this.sharedApiCall = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/common_areas/${this.state.sharedAreaId}`,
    });
  };

  getUpcomingReservation = async () => {
    const { sharedAreaId, complexId } = this.state;
    this.getUpcomingReservationListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/upcoming_reservation?common_area_id=${sharedAreaId}&society_management_id=${complexId}`,
    });
  };

  handleSaveSharedAreaDetailsData = (values: SharedAreaEditForm) => {
    let data = new FormData();
    data.append("common_area[details]", values.details);
    data.append("common_area[total_area]", values.totalArea);
    data.append("common_area[reservation_fee]", values.fees);
    data.append("common_area[measurement_unit]", values.measurement);
    values.photos?.forEach((image: any) => {
      data.append("common_area[photos][]", dataURLtoFile(image));
    });
    if (typeof values.floorPlan === "object" && values.floorPlan !== null) {
      data.append("common_area[floor_plan]", values.floorPlan);
    }

    this.handleAPIEditSharedAreaD(data);
  };

  handleAPIEditSharedAreaD = async (data: any) => {
    this.editSharedAreaSave = await apiCall({
      method: "PATCH",
      endPoint: `bx_block_society_management/common_areas/${this.state.sharedAreaId}`,
      body: data,
    });
  };

  handleEditSucc = () => {
    this.setState({ loading: false }, () => {
      this.sharedAreaDetails();
    });
  };
}
// Customizable Area End
