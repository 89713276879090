import React from "react";
// Customizable Area Start
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Box,
  Typography,
  Paper,
  MenuItem,
  Divider,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Breadcrumbs,
  Grid,
  Popover,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
// Customizable Area End

import AccountManagerController, { Props, } from "./AccountManagerController.web";

class AccountManager extends AccountManagerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  renderAccountManager = () => {
    const currentPage = this.state.paginationData?.current_page as number;
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        <AccountAssignedManagerStyle>
          <Breadcrumbs>
            <Typography
              className="mangingDashboard"
              onClick={() => this.props.navigation.navigate("DashboardCompanyEmployee")}
              data-testId={"myDashboardTitleTestId"}
            >
              {t("My Dashboard")}{" "}
            </Typography>
            <Typography
              className="mangingDashboard"
              onClick={() => this.props.navigation.navigate("DashboardCompanyEmployee")}
              data-testId={"generalDashboardTitleTestId"}
            >
              {t("General Dashboard")}
            </Typography>
            <Typography className="assignedAccount">{t("Assigned Account Mangers")}</Typography>
          </Breadcrumbs>
          <Typography className="headingStyle bold-text">{t("Assigned Account Managers")}</Typography>

          <Grid item sm={12} md={12} xs={12}>
            <Grid item className="table-box-card">
              <Box className="table-top">
                <Box className="table-main-heading">
                  <h4 className="bold-text">{t("List of assigned account Manager")}</h4>
                </Box>
                <Box className="filter-head-box" style={{ margin: "0" }}>
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Sort By"
                    option={[
                      { label: "Ascending", value: "asc" },
                      { label: "Descending", value: "desc" },
                    ]}
                    data-testId="sortBySelectTestId"
                    onChange={this.sortByChange}
                  />
                  <SearchInput
                    t={t}
                    language={language}
                    label="Search By name or id"
                    onChange={this.searchChange}
                    data-testId="searchInputTestId"
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer component={Paper} className="table-container">
                <Table style={{ minWidth: 900 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell data-test-id="amt-head-no" align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Account Manager")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("ID")}
                      </TableCell>
                      <TableCell data-test-id="amt-head-email" align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Email Address")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Assigned Complexes")}
                      </TableCell>
                      <TableCell data-test-id="amt-head-building" align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Assigned Buildings")}
                      </TableCell>
                      <TableCell data-test-id="amt-head-owner" align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Assigned Owners")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Assigned Property Developers")}
                      </TableCell>
                      <TableCell data-test-id="amt-head-menu" align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.accountManagerDataSave.map((item: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {(currentPage - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.full_name}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{item.id}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.email}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_complexes}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_buildings}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_owners}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.assigend_property_developers}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                          <IconButton data-testId="account-manager-menu" onClick={this.handleClick}>
                                  <MoreVertIcon onClick={()=>{this.handleViewData(item)}} fontSize="small" />
                                </IconButton>
                                                <Popover
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={this.handleClose}
                                                    data-testId={"popoverTestId"}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            marginLeft: "20px",
                                                        },
                                                    }}
                                                    elevation={1}
                                                >
                                                <MenuItem onClick={this.handleAccountmanagerProfile} data-test-id="view-menu">{t("View Details")}</MenuItem>
                                                </Popover>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="table-bottom">
                <PaginationModule
                  pagination={this.state.paginationData}
                  handlePagination={this.handlePagination}
                  page={this.state.paginationData?.current_page}
                  data-testId="accountManagerPaginationTestId"
                />
              </Box>
            </Grid>
          </Grid>
        </AccountAssignedManagerStyle>
      </>
    );
  };
    // Customizable Area End

    render() {
        // Customizable Area Start
    return (
      <CEHeaderComponentWeb>
        {this.renderAccountManager()}

        <Loader loading={this.state.loader} />

        <AlertError
          show={this.state.showError}
          handleClose={this.handleAlertError}
          message={this.state.errorMessage}
          data-testId={"errorAlertTestId"}
        />
      </CEHeaderComponentWeb>
    );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(AccountManager);
export { AccountManager };

const AccountAssignedManagerStyle = styled(Box)({
  padding: "0 35px",
  "& .mangingDashboard": {
    fontSize: "18px",
    color: "#3D424B",
    cursor: "pointer",
  },
  "& .assignedAccount": {
    color: "#2b6fed",
    fontSize: "18px",
  },
  "& .headingStyle": {
    fontSize: "30px",
    marginTop: "10px",
    marginBottom: "12px",
  },
});

// Customizable Area End
