Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.cardApiEndPoint = "bx_block_my_document/report_dashboard_count/";
exports.btnExampleTitle = "CLICK ME";
exports.countryApiEndpoint = "bx_block_profile/profiles/country_list/"
exports.cityApiEndpoint = "bx_block_my_team/company_employee_nominations/city_list/"
exports.complexApiEndpoin = "bx_block_my_team/company_employee_nominations/complex_list/"
exports.buildingApiEndpoin = "bx_block_profile/profiles/building_list"
exports.loginCardApiEndPoint = "bx_block_my_document/login_report_dashboard";
exports.lastLoginApiEndPoint = "bx_block_my_document/last_login_list/";
exports.neverLoggedApiEndPoint = "bx_block_my_document/never_logged_user/";
exports.loginWithMonthApiEndPoint = "bx_block_my_document/login_with_specific_month/"
exports.downloadReportsEndPoin = "bx_block_my_document/building_documents/download_login_report.pdf/"
exports.roleApiEndPoint = "bx_block_roles_permissions/roles"
exports.salesManagerApiEndPoint = "bx_block_report/property_manager_report/sales_manager_listing"
exports.userSubscriptionTypeApiEndPoint = "bx_block_custom_user_subs/plans"
exports.ownerReportsApiEndPoint="bx_block_report/owner_reports/owner_report_listing/"
exports.ownerReportDetailsApiEndPoint = "bx_block_report/owner_reports/owner_report_details/"
exports.subscriptionReportsApiEndPoint = "bx_block_report/subscription_reports"
exports.getYearListEndPoint="bx_block_expense_report/expense_reports/year_list"
exports.getInvitationReportsEndPoint="bx_block_report/invitation_reports/invitation_report_dashboard"
exports.getBuildingListEndPoint="bx_block_announcement/announcements/building_list"
exports.getComplexListEndPoint="bx_block_address/housing_complex_list";
exports.getCountryListEndPoint="bx_block_address/country_list";
exports.getCityListEndPoint="bx_block_address/city_list";
exports.accountManagerApiEndPoint = "bx_block_report/property_manager_report/account_manager_listing"
exports.PropertyDeveloperApiEndPoint = "bx_block_report/property_manager_report/property_developer_report"
exports.PropertyDeveloperSubApiEndPoint = "bx_block_report/subscription_reports/subscription_statistical_report"
exports.countryListMethod = "GET";
exports.leaseReportGetMethod = "GET";
exports.leaseReportPostMethod = "POST";
exports.countryListEndPoint = "bx_block_address/country_list";
exports.cityListEndPoint = "bx_block_address/city_list?country=";
exports.selectedComplexEndPoint = "bx_block_address/housing_complex_list?";
exports.buildingListEndPoint  = "bx_block_address/building_list?society_management_id=";
exports.accountManagerEndPoint = "bx_block_report/property_manager_report/account_manager_listing";
exports.salesManagerEndPoint = "bx_block_report/property_manager_report/sales_manager_listing";
exports.searchDataEndPoint = "bx_block_report/lease_reports?";
exports.leaseReportContractListEndPoint = "bx_block_report/lease_reports/contract_list";
exports.searchLeaseReportMethod = "GET";
exports.searchLeaseReportEndPoint = "bx_block_report/lease_reports/contract_list?search="; 
exports.downloadReportApiEndPoint = "bx_block_report/invitation_reports/download_invitation_report"
exports.propertyManagerApiEndPoint = "bx_block_report/property_manager_report/property_manager_statical_report"
exports.propertyManagerSubApiEndPoint = "bx_block_report/subscription_reports/subscription_statistical_property_manager_report"
exports.downloadReportApiEndPoint = "bx_block_report/invitation_reports/download_invitation_report";
exports.downloadLeaseReportEndpoint = "bx_block_report/lease_reports/report_generation_link";
exports.gaMemberReportApiEndPoint = "bx_block_report/property_manager_report/ga_usage_statical_report";
exports.gaMemberSubTabelApiIdEndpoint = "bx_block_report/subscription_reports/subscription_statistical_ga_report"
exports.ownerReportApiEndPoint = "bx_block_report/owner_reports/owner_statical_report";
exports.ownerSubTabelApiIdEndpoint = "bx_block_report/subscription_reports/owner_report"


// Customizable Area End