// Customizable Area Start
import React from "react";
import { Box, Button, Card, CardMedia, Divider, Grid } from "@material-ui/core";
import { NoUserIcon } from "../assets";

const PendingReqCard = ({ t ,handleReject,handleAccept, item}: any) => {
  return (
    <Card className="community__user_details">
      <Box className="community_card_top">
        <CardMedia component="img" height="140" image={NoUserIcon} alt="image" />
        <Box className="building-unit-box">
          <h4 className="bold-text">{item.attributes.unit_number}</h4>
        </Box>
        <p className="name_heading">{item.attributes.name}</p>
        <Box className="roles-box">
          <span className="role">{item.attributes.role}</span>
        </Box>
        <p>{item.attributes.email}</p>
        <p>{item.attributes.phone}</p>
      </Box>
      <Divider />
      <Box className="community_card_bottom">
        <Box className="request_details">
          <p>
            <span>{t("Country")}:</span> {item.attributes?.country}
          </p>
          <p>
            <span>{t("City")}:</span> {item.attributes?.city}
          </p>
          <p>
            <span>{t("Building")}:</span> {item.attributes?.building_management.name}
          </p>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              style={{
                width: "100%",
                color: "#2B6FED",
                border: "1px solid #2B6FED",
                fontWeight: 600,
              }}
                onClick={() =>handleReject(item)}
            >
              {t("Decline")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%", backgroundColor: "#2B6FED", fontWeight: 600 }}
                onClick={() =>handleAccept(item)}
            >
              {t("Accept")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default PendingReqCard;
// Customizable Area End
