import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { emptyFilterValue } from "../../../components/src/UtilFunction.web";
import moment from "moment";
import toast from "react-hot-toast";

export interface Props {
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

export interface IPermissionForm {
  permission: string;
  role: string;
  employee: string;
}

export interface IFormPermission {
  id: number;
  permission_name: string;
}

interface SelectOption {
  label: string;
  value: string;
}

interface IPagination {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
  tags: string;
  userRole: string;
  selectedRole: string;

  loading: boolean;
  isRevokePermission: boolean;
  isGrantPermission: boolean;

  filter: {
    salesManager: string;
    accountManager: string;
    grantedBy: string;
    grantedOn: string;
    permissionType: string;
  }

  grantPermissionForm: IPermissionForm;

  permissionList: [];
  pagination: null | IPagination;

  formPermissionList: SelectOption[];
  formPermissionIds:number|null
  employeeList: SelectOption[];
  grantedBy: SelectOption[];
  managerList: SelectOption[];
  managerListData:any

  page: number;
  permissionId: string;
  revokedDatapermission:string,
  revokeSalemanager:string,
  managerPermissionid:number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardsGrantedController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetPermissionCallId: string = "";
    GetFormPermissionCallId: string = "";
    GetEmployeeCallId: string = "";
    CreatePermissionCallId: string = "";
    RevokePermissionCallId: string = "";
    GrantedByCallId: string = "";
    GetManagerCallId: string = "";
    GetAccountManagerCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      tags: "Granted to Sales Managers",
      userRole: "",
      selectedRole: "",

      loading: false,
      isRevokePermission: false,
      isGrantPermission: false,
      
      filter: {
        accountManager: "",
        salesManager: "",
        grantedBy: "",
        grantedOn: "",
        permissionType: "",
      },

      grantPermissionForm: {
        permission: "",
        role: "",
        employee: ""
      },

      permissionList: [],
      pagination: null,

      formPermissionList: [],
      formPermissionIds:null,
      employeeList: [],
      grantedBy: [],
      managerList: [],
      managerListData:[],

      page: 1,
      revokedDatapermission:"",
      revokeSalemanager:"",
      permissionId: "",
      managerPermissionid:0
    };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        switch (apiRequestCallId) {
          case this.GetPermissionCallId:
            return this.handlePermissionSuccess(responseJson);
          case this.GetFormPermissionCallId:
            return this.handleFormPermissionSuccess(responseJson);
          case this.GetEmployeeCallId:
            return this.handleEmployeeSuccess(responseJson);
          case this.CreatePermissionCallId:
            return this.handleCreatePermissionSuccess();
          case this.RevokePermissionCallId:
            return this.handleRevokePermissionSuccess();
          case this.GrantedByCallId:
            return this.handleGrantedBySuccess(responseJson);
          case this.GetManagerCallId:
            return this.handleManagerSuccess(responseJson);
            case this.GetAccountManagerCallId:
              return this.handleAccountManagerSuccess(responseJson);
          default:
            break;
        }

        if (responseJson && responseJson.meta && responseJson.meta.token) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        } else {
          ApiErrorResponse(responseJson);
        }
        ApiCatchErrorResponse(errorResponse);
        // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const user_role = localStorage.getItem("role") || "";
    this.setState({ userRole: user_role, tags: user_role !== "coo" ? "Granted to Me" : "Granted to Sales Managers" }, () => {
      this.getAllPermissionList();
      this.getFormPermissionList();
      this.getGrantedBy();
      this.getManagerList("sales_manager");
      this.getAccountmanagerList("account_manager")
    });
  }

  getAllPermissionList = () => {
    const { grantedBy, grantedOn, accountManager, salesManager, permissionType } = this.state.filter;
    let role = "";
    if (this.state.tags === "Granted to Me") {
      role = "";
    } else if (this.state.tags === "Granted to Sales Managers") {
      role = "sales_manager";
    } else {
      role = "account_manager";
    }
    const manager = accountManager !== "" ? accountManager : salesManager;
    this.setState({ loading: true }, async () => {
      this.GetPermissionCallId = await CEApiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_roles_permissions/user_permissions?granted_on=${grantedOn? moment(grantedOn).format("DD/MM/YYYY") : ""}&granted_by=${emptyFilterValue(
          grantedBy
        )}&permissions_type_id=${emptyFilterValue(permissionType)}&role_name=${role}&page=${
          this.state.page
        }&manager_id=${emptyFilterValue(manager)}`,
      });
    });
  };

  handlePermissionSuccess = (responseJson: { data: [], meta: { pagination: IPagination } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ permissionList: responseJson.data, pagination: responseJson.meta.pagination ,page:responseJson.meta.pagination?.current_page});
      }
    });
  };

  getFormPermissionList = async () => {
    this.GetFormPermissionCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_roles_permissions/user_permissions/permission_list`,
    });
  };

  handleFormPermissionSuccess = (responseJson: { permissions: IFormPermission[] }) => {
    if (responseJson && responseJson.permissions) {
      this.setState({ formPermissionList: responseJson.permissions.map((item: { id: number, permission_name: string })=> ({
        label: item.permission_name,
        value: item.id + ""
      }))});
    }
  };

  handlePermissionSelect = (selectedId: string) => {
    this.setState({
      formPermissionIds: parseInt(selectedId, 10)
    },()=>{
      this.getEmployeeList("sales_manager")
      this.getAccountmanagerList("account_manager")
    });
  };

  getEmployeeList = async (role: string) => {
    this.GetEmployeeCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_roles_permissions/user_permissions/manager_list?role_name=${role}&permission_id=${this.state.formPermissionIds}`,
      method: "GET",
    });
  };

  handleEmployeeSuccess = (responseJson: { managers: any[] }) => {
    if (responseJson && responseJson.managers) {
      this.setState({ employeeList: responseJson.managers.map((item: { id: number, first_name: string, last_name: string })=> ({
        label: item.first_name + " " +  item.last_name,
        value: item.id + "" 
      }))});
    }
  };

  createPermission = async (value: any) => {
    const data = {
      "user_permission": {
        "employee_account_id": value.employee,
        "permission_id": value.permission,
        "role_name": value.role===""?"account_manager":value.role 
      }
    }
    this.CreatePermissionCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_roles_permissions/user_permissions`,
      method: "POST",
      body: JSON.stringify(data)
    });
  };

  handleCreatePermissionSuccess = () => {
    toast.success("Permission granted successfully");
    this.setState({ loading: true }, () => {
      this.getAllPermissionList();
    });
  };

  handleRevokePermission = async () => {
    this.RevokePermissionCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_roles_permissions/user_permissions/${this.state.permissionId}`,
      method: "DELETE",
    });
  };

  handleRevokePermissionSuccess = () => {
    toast.success("Permission revoked successfully");
    this.setState({ loading: true }, () => {
      this.getAllPermissionList();
    });
  };

  getGrantedBy = async () => {
    this.GrantedByCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_roles_permissions/user_permissions/grated_by_list`,
      method: "GET",
    });
  };

  handleGrantedBySuccess = (responseJson: any) => {
    if(responseJson && responseJson.accounts) {
      this.setState({ grantedBy: responseJson.accounts.map((item: { id: number, first_name: string, last_name: string })=> ({
        label: item.first_name + " " +  item.last_name,
        value: item.id + "" 
      }))});
    }
  };

  getManagerList = async (role: string) => {
    this.GetManagerCallId = await CEApiCall({
      endPoint: `bx_block_roles_permissions/user_permissions/manager_list?role_name=${role}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  getAccountmanagerList = async (role: string) => {
    this.GetAccountManagerCallId = await CEApiCall({
      endPoint: `bx_block_roles_permissions/user_permissions/manager_list?role_name=${role}&permission_id=${this.state.formPermissionIds}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  handleManagerSuccess = (responseJson: { managers: any[] }) => {
    if (responseJson && responseJson.managers) {
      this.setState({ managerList: responseJson.managers.map((item: { id: number, first_name: string, last_name: string })=> ({
        label: item.first_name + " " +  item.last_name,
        value: item.id + "" 
      }))});
    }
  };
  handleAccountManagerSuccess=(responseJson:any)=>{
    if (responseJson && responseJson.managers) {
      this.setState({ managerListData: responseJson.managers.map((item: { id: number, first_name: string, last_name: string })=> ({
        label: item.first_name + " " +  item.last_name,
        value: item.id + "" 
      }))});
    }
  }


  handleGrantedFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value as string;
    if (stateParam === "salesManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          salesManager: value,
          grantedOn: "",
          permissionType: "",
          grantedBy: "",
        },
      });
    } else if (stateParam === "accountManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          accountManager: value,
          grantedOn: "",
          grantedBy: "",
          permissionType: "",
        },
      });
    } else if (stateParam === "grantedBy") {
      this.setState({
        filter: {
          ...this.state.filter,
          grantedBy: value,
          permissionType: "",
          grantedOn: "",
        },
      });
    } else if (stateParam === "grantedOn") {
      this.setState({
        filter: {
          ...this.state.filter,
          permissionType: "",
          grantedOn: value,
        },
      });
    } else if (stateParam === "permissionType") {
      this.setState({
        filter: {
          ...this.state.filter,
          permissionType: value,
        },
      });
    } 
  };

  handleTag = (name: string, role: string) => {
    this.setState({
      tags: name, page: 1, selectedRole: role, filter: {
        accountManager: "",
        salesManager: "",
        grantedBy: "",
        grantedOn: "",
        permissionType: "",
      },
    }, () => {
      this.getAllPermissionList();

      if(role !== "") {
        this.getManagerList(role);
      }
    });
  };

  handleRevokedata=(permission:any)=>{
    this.setState({
      revokedDatapermission:permission && permission.attributes && permission.attributes.permission_type && permission.attributes.permission_type.permission_name      ,
      revokeSalemanager:permission.attributes?.manager?.full_name
    })
  }

  handleRevokeDialog = () => {
    this.setState({ isRevokePermission: !this.state.isRevokePermission });
  }

  handleGrantPermission = () => {
    this.setState({ isGrantPermission: !this.state.isGrantPermission });
  }

  handleApprovalroute=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CEDashboardApproval'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handlePagination = (e: any, value: any) => {
    this.setState({ page: value }, () => {
      this.getAllPermissionList();
    });
  };
    // Customizable Area End
}
