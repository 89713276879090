// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  docName: string;
  complexId: string;
  details: string;

  isShareModalOpen: boolean;
  shareUrl: string;

  documentList: any[];
  resolutionList: any[];
}

interface SS {
  id: any;
}

export default class ComplexDocumentController extends BlockComponent<Props, S, SS> {
  ChairmanDocumentsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      docName: "",
      complexId: "",
      details: "",

      isShareModalOpen: false,
      shareUrl: "",

      documentList: [],
      resolutionList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.ChairmanDocumentsCallId) {
        this.getChairmanDocumentsResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    const complex_id = this.props.navigation.getParam("id");
    const document_name = this.props.navigation.getParam("name");
    const details = this.props.navigation.getParam("details");
    this.setState({ docName: document_name, complexId: complex_id, details: details }, () => {
      this.getDocuments();
    });
  }

  getDocuments = async () => {
    const { docName, complexId } = this.state;
    this.ChairmanDocumentsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_document/all_docs_society?document_type=${docName.toLowerCase()}&complex=${complexId}`,
    });
  };

  getChairmanDocumentsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, documentList: responseJson.data });
    }
  };

  handleShareModal = () => {
    this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
  };

  handleOpenShareModal = (url: string) => {
    this.setState({ shareUrl: url }, () => {
      this.handleShareModal();
    });
  };

  documentClass = (documentPage: any) => {
    return documentPage === "resolution" ? "resolutions" : "document-card document-box";
  };

  handleDynamicRoute = () => {
    if (this.state.details === "ComplexDetails") {
      navigationFunc(this.props, "CEComplexDetails", { id: this.state.complexId });
    } else {
      navigationFunc(this.props, "ComplexBuildingDetails", { id: this.state.complexId });
    }
  };

  handleDocumentView = (id: string | number) => {
    const { details, complexId, docName } = this.state;
    navigationFunc(this.props, "CEComplexDocumentView", {
      details: details,
      id: complexId,
      name: docName,
      dId: id,
    });
  };
}

// Customizable Area End
