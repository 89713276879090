// Customizable Area Start
import React from "react";
import { Box, Dialog } from "@material-ui/core";
import { languageCondition } from "../ConditionManager.web";
import DialogHeader from "./DialogHeader.web";
import GoogleMapReact from "google-map-react";
import { MapLocationIcon } from "../assets";

const LocationPin: React.FC<{ mapLocation: any; lat: string | number; lng: string | number }> = ({
  mapLocation,
  lat,
  lng,
}) => <img src={mapLocation} alt="Location Pin" />;

const MapDialog = ({ IsOpen, CloseDialog, lat, long, t, language }: any) => {
  return (
    <Dialog
      className="edit-profile chairman-map-modal"
      scroll="paper"
      dir={languageCondition(language, "rtl", "ltr")}
      maxWidth="sm"
      open={IsOpen}
      fullWidth
    >
      <DialogHeader t={t} title={t("Location")} onClose={CloseDialog} />
      {lat && long ? (
        <Box className="google-map-box">
          <GoogleMapReact
            defaultCenter={{
              lng: long,
              lat: lat,
            }}
            defaultZoom={15}
          >
            <LocationPin mapLocation={MapLocationIcon} lat={lat} lng={long} />
          </GoogleMapReact>
        </Box>
      ) : (
        <Box className="no-google-map-box">{t("No Location Available")}</Box>
      )}
    </Dialog>
  );
};

export default MapDialog;
// Customizable Area End
