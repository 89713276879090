// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  location: any;
  match: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

export type StyleObject = {
  [key: string]: {
    [key: string]: string;
  };
};

interface S {
  loading: boolean;
  unitId: string;

  unitData: UnitData;

  imageBox: boolean;
  photoIndex: number;
}

interface UnitData {
  unitName: string;
  complexName: string;
  photos: any[];
  lat: number;
  long: number;
  country: string;
  region: string;
  city: string;
  currency: string;
  floor: string;
  size: string;
  measurement: string;
  configuration: string;
  purchasePrice: string;
  purchaseDate: string;
  currentValuation: string;
  rentHistory: any[];
  familyList: any[];
  buildingName: string;
  rentStatus: string;
  tenantName: string;
  rentStartDate: string;
  rentEndDate: string;
  rentAmount: string;
}

interface SS {
  id: any;
}

export default class CEUnitDetailsController extends BlockComponent<Props, S, SS> {
  GetUnitDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      unitId: "",

      imageBox: false,
      photoIndex: 0,

      unitData: {
        unitName: "",
        complexName: "",
        photos: [],
        lat: 0,
        long: 0,
        country: "",
        region: "",
        city: "",
        currency: "",
        floor: "",
        size: "",
        measurement: "",
        configuration: "",
        purchasePrice: "",
        purchaseDate: "",
        currentValuation: "",
        rentHistory: [],
        familyList: [],
        buildingName: "",
        rentStatus: "",
        tenantName: "",
        rentStartDate: "",
        rentEndDate: "",
        rentAmount: "",
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.GetUnitDetailsCallId) {
        this.handleGetUnitDetailsResponse(responseJsonBlock);
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    const unit_id = this.props.navigation.getParam("id");
    this.setState({ unitId: unit_id }, () => {
      this.getUnitDetail();
    });
  }

  slider: any;
  nextImage = () => {
    this.slider.slickNext();
  };
  previousImage = () => {
    this.slider.slickPrev();
  };

  getUnitDetail = async () => {
    this.GetUnitDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/${this.state.unitId}`,
    });
  };

  handleGetUnitDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        unitData: {
          unitName: responseJson.data.attributes.apartment_name,
          complexName: responseJson.data.attributes.society_management.name,
          photos: responseJson.data.attributes.photos,
          lat: responseJson.data.attributes.lat,
          long: responseJson.data.attributes.long,
          country: responseJson.data.attributes.country,
          region: responseJson.data.attributes.region,
          city: responseJson.data.attributes.city,
          currency: responseJson.data.attributes.currency && responseJson.data.attributes.currency.currency,
          floor: responseJson.data.attributes.floor_number,
          size: responseJson.data.attributes.size,
          measurement: responseJson.data.attributes.society_management.measurement_unit,
          configuration: responseJson.data.attributes.configuration,
          purchasePrice: responseJson.data.attributes.purchase_price,
          purchaseDate: responseJson.data.attributes.purchase_date,
          currentValuation: responseJson.data.attributes.current_valuation,
          rentHistory: responseJson.data.attributes.rent_history.data,
          buildingName: responseJson.data.attributes.building_management.name,
          rentStatus: responseJson.data.attributes.status,
          tenantName: responseJson.data.attributes.rent_status && responseJson.data.attributes.rent_status.tenant_name,
          rentStartDate:
            responseJson.data.attributes.rent_status && responseJson.data.attributes.rent_status.start_date,
          rentEndDate: responseJson.data.attributes.rent_status && responseJson.data.attributes.rent_status.end_date,
          rentAmount: responseJson.data.attributes.rent_status && responseJson.data.attributes.rent_status.rent_amount,
          familyList: responseJson.data.attributes.family_members.data
            ? responseJson.data.attributes.family_members.data
            : [],
        },
      });
    }
  };

  handleUnitDetailText = (text: any) => (text ? text : "-");
}
// Customizable Area End
