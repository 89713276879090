// Customizable Area Start
import React from "react";
import { Box, InputLabel, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const NormalDateField = ({ t, language, label, icon, isLabel, value, ...props }: any) => {
  return (
    <Box className="select_icon__form_box input_icon__form_box">
      {isLabel && <InputLabel>{t(label)}</InputLabel>}
      <Box className="select__select_box">
        {icon && <img src={icon} alt="icon" />}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className={icon ? "input_box icon_date" : "input_box icon_out_date"}
            fullWidth
            variant="inline"
            placeholder={t(label)}
            format="DD/MM/YYYY"
            value={value}
            renderInput={(params: any) => <TextField {...params} />}
            {...props}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

export default NormalDateField;
// Customizable Area End
