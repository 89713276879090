// Customizable Area Start
export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const Building1 = require("../assets/building1.png");
export const ListCopy = require("../assets/List Copy.png");
export const Car = require("../assets/Car Copy.png");
export const user = require("../assets/user.svg");
export const palette = require("../assets/palette.png");
export const upload = require("../assets/Upload.png");
export const NoVehicles = require("../assets/NoVehicles.png");
export const edit = require("../assets/edit.png");
export const deleteIcon = require("../assets/delete.png");
export const deleteI = require("../assets/deleteIcon.png");
export const info = require("../assets/info.png");
export const userBlue = require("../assets/IconlyLightProfile.png");
export const CarBlue = require("../assets/CarBlue.png");
export const List = require("../assets/List.png");
export const paletteBlue = require("../assets/paletteBlue.png");
export const Rc = require("../assets/Registration-Certificate.png");
export const CarFront = require("../assets/carFront.png");
export const Tick = require("../assets/tick.png");
export const Building = require("../assets/building.png");
export const Hash = require("../assets/Hash.png");
export const Hyperlink = require("../assets/Hyperlink.png");
export const User3 = require("../assets/User 3.png");
export const Search = require("../assets/search.png");
export const NoChat = require("../assets/noChat.png");
export const Send = require("../assets/send.png");
export const DoubleTick = require("../assets/doubleTick.png");
export const Sendbutton = require("../assets/Submit_with_Round.png");
export const buildingImg = require("../assets/buildingImg.png");
export const CarLogo = require("../assets/carLogo.png");
export const EditIcon = require("../assets/edit-icon.png");
export const visitorIcon = require("../assets/visitor.png");
export const unitIcon = require("../assets/unit.png");
export const teamIcon = require("../assets/team.png");
export const complexIcon = require("../assets/complex.png");
export const faqIcon = require("../assets/faq.png");
export const chatIcon = require("../assets/chat.png");
export const reportIcon = require("../assets/report.png");
export const CrossIcon = require("../assets/x-mark.png");
export const CheckIcon = require("../assets/check.png");
export const DeleteIcon = require("../assets/delete-icon.png");
export const userIcon = require("../assets/user.png");
export const emailIcon = require("../assets/email-icon.png");
export const phoneIcon = require("../assets/phone-icon.png");
export const buildingIcon = require("../assets/building-icon.png");
export const unitsIcon = require("../assets/unit-icon.png");
export const ceRealEstateIcon = require("../assets/real-estate.png");
export const ceBuildingIcon = require("../assets/ce-building.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const cross = require("../assets/cross.png");
export const Document = require("../assets/document.png");
export const city = require("../assets/city.png");
export const configuration = require("../assets/configuration.png");
export const country = require("../assets/country.png");
export const floor = require("../assets/floor.png");
export const purchase_date = require("../assets/purchase-date.png");
export const purchase_price = require("../assets/purchase-price.png");
export const region = require("../assets/region.png");
export const size = require("../assets/size.png");
export const valuation = require("../assets/valuation.png");
export const call_org = require("../assets/call-org.png");
export const email_org = require("../assets/email-org.png");
export const chat = require("../assets/chat.png");
export const bentalyLogo = require("../assets/bentalylogo.png");
export const currency_icon = require("../assets/currency-icon.png");
export const flag = require("../assets/flag.png");
export const profile_icon = require("../assets/profile-icon.png");
export const pencil = require("../assets/pencil.png");
export const location = require("../assets/location.png");
export const building = require("../assets/building.png");
export const complexbw = require("../assets/complexbw.png");
export const purchase_datebw = require("../assets/purchase-datebw.png");
export const purchase_pricebw = require("../assets/purchase-pricebw.png");
export const sizebw = require("../assets/sizebw.png");
export const unitbw = require("../assets/unitbw.png");
export const valutionbw = require("../assets/valuationbw.png");
export const configurationbw = require("../assets/configurationbw.png");
export const cancle = require("../assets/cancle.png");
export const true_mark = require("../assets/true-mark.png");
export const uploadbw = require("../assets/uploadbw.png");
export const del_image = require("../assets/del-image.png");
export const floorIcon = require("../assets/floor-icon.png");
export const earthIcon = require("../assets/earth-icon.png");
export const downloadIcon = require("../assets/download.png");
export const nextIcon = require("../assets/right-arrow.png");
export const previousIcon = require("../assets/left-arrow.png");
export const mapLocation = require("../assets/map-location.png");
export const BuildingLogo = require("../assets/building-main.png");
export const ManagementChat = require("../assets/management-chat.png");
export const ManagementPhone = require("../assets/management-call.png");
export const ManagementEmail = require("../assets/management-email.png");
export const UserIcon = require("../assets/user.png");
export const NoUserIcon = require("../assets/no-user.png");
export const userProfile = require("../assets/user.png");
export const profile = require("../assets/view_Oval.png");
export const blanckImg = require("../assets/blanck.png");
export const desigenImg = require("../assets/desigen.png");
export const editOrangeIcon = require("../assets/edit-orange.png");
// Customizable Area End