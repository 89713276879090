// Customizable Area Start
import React from "react";
import RealEstateCompaniesTableController, { Props } from "./RealEstateCompaniesTableController.web";
import {
  Box,
  Typography,
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Divider,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CSSProperties } from "@material-ui/styles";
import { withRouter } from "react-router";
import Loader from "../../../../components/src/Loader.web";
import { Link as NavLink } from "react-router-dom";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { statusMapping, RealEstateFilter } from "../../../../components/src/RealEstateFilter/index";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";

class RealEstateCompaniesTable extends RealEstateCompaniesTableController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    const { anchorEl } = this.state;
    const openPopover = Boolean(anchorEl);

    return (
      <>
        <Box style={webStyle.companyBox}>
          <Typography style={{ fontSize: "18px" }}> {t("Real Estate Companies")}</Typography>
          <Box style={webStyle.titleBox}>
            <Typography style={webStyle.bigTitle} className="bold-text">
              {t("Real Estate Companies")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={webStyle.registerBtn}
              onClick={() => this.props.history.push("/CompanyEmployee/realestate-register-company")}
            >
              <span className="bold-text">{t("REGISTER NEW COMPANY")}</span>
            </Button>
          </Box>
          <Box style={webStyle.selectBoxes} className="estate_table_select_boxes">
            <Box style={webStyle.selectBoxLeft} className="estate_table_select_boxes_left">
              <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                <RealEstateFilter
                  language={language}
                  t={t}
                  classComponent={this}
                  filterList={this.handleFilterList()}
                />
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.handleFilterSubmit}
                    startIcon={<SearchIcon style={{ marginLeft: "10px" }} />}
                  >
                    {t("Search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.tableContainer} className="estate_table_select_boxes_container">
            <Box style={webStyle.tableHeader} className="estate_table_select_boxes_header">
              <h2 style={webStyle.tableHeading} className="bold-text">
                {t("List of Real Estate Companies")}
              </h2>
              <SearchInput
                onChange={this.handleSearchKeyUp}
                t={t}
                language={language}
                label={t("Search by Company ID or Name")}
                width="320px"
                data-testid="searchquery"
              />
            </Box>

            <TableContainer dir={languageCondition(language, "rtl", "ltr")}>
              <Table className="table-box" style={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Company ID")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Company Name")}
                    </TableCell>
                    {this.state.accountType === "coo" ? (
                      <>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Sales Manager")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Account Manager")}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Managed By")}
                      </TableCell>
                    )}
                    <TableCell className="bold-text company-table" align={languageCondition(language, "right", "left")}>
                      {t("Country")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Region")}
                    </TableCell>
                    <TableCell className="bold-text company-table" align={languageCondition(language, "right", "left")}>
                      {t("City")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Status")}
                    </TableCell>
                    <TableCell className="bold-text" align={languageCondition(language, "right", "left")}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.companies.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>{"No record found"}</TableCell>
                    </TableRow>
                  )}
                  {this.state.companies.map((company: any, i: number) => {
                    return (
                      <TableRow key={company.id}>
                        <TableCell align={languageCondition(language, "right", "left")}>{company.id}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {company.attributes.company_name}
                        </TableCell>
                        {this.state.accountType === "coo" ? (
                          <>
                            <TableCell
                              align={languageCondition(language, "right", "left")}
                              style={{ textTransform: "capitalize" }}
                            >
                              {company.attributes.sales_manager || "-"}
                            </TableCell>
                            <TableCell
                              align={languageCondition(language, "right", "left")}
                              style={{ textTransform: "capitalize" }}
                            >
                              {company.attributes.account_manager || "-"}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            style={{ textTransform: "capitalize" }}
                          >
                            {company.attributes.managed_by}
                          </TableCell>
                        )}
                        <TableCell align={languageCondition(language, "right", "left")} className="text_capital">
                          {company.attributes.country}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="text_capital">
                          {company.attributes.region}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="text_capital">
                          {company.attributes.city}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {company?.attributes?.status ? (
                            <span
                              style={{
                                ...webStyle.companyTag,
                                ...webStyle[company?.attributes?.status],
                              }}
                              className="bold-text"
                            >
                              {t(statusMapping[company?.attributes?.status])}
                            </span>
                          ) : (
                            "--"
                          )}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} style={webStyle.moreOptions}>
                          <MoreVertIcon
                            style={webStyle.moreIcon}
                            data-testid="clickpopover"
                            onClick={(e) => this.handlePopover(e, company.id)}
                          />
                          <Popover
                            itemID="handleCloseID"
                            id="custom-menu"
                            open={openPopover}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClosePopover}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            className="real_estate_popover"
                          >
                            <ul style={webStyle.rowList}>
                              <li style={webStyle.option1}>
                                <NavLink
                                  to={`/CompanyEmployee/realestate-company-details/${this.state.activeId}`}
                                  style={webStyle.linkOption}
                                >
                                  {t("View Details")}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to={`/CompanyEmployee/realestate-edit-company/${this.state.activeId}`}
                                  style={webStyle.linkOption}
                                >
                                  {t("Edit Details")}
                                </NavLink>
                              </li>
                            </ul>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <Box style={webStyle.tableFooter}>
              <PaginationModule page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handlePagination}/>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle: any = {
  searchIcon: {
    fontSize: "14px",
  },
  selectFormControl: {
    width: "15%",
  },
  selectBigFormControl: {
    width: "20%",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  companyBox: { padding: "0 30px 30px" },
  smallTitle: { fontSize: "18px" },
  bigTitle: { fontSize: "32px", fontWeight: 700 },
  registerBtn: {
    background: "#FC8434",
    borderColor: "#FC8434",
    boxShadow: "unset",
    borderRadius: "8px",
    fontWeight: 700,
    height: "40px",
  },
  currentRecords: {
    color: "#FC8434",
  },
  selectBigForm: {
    minWidth: "20% ",
    color: "#181d25",
    border: "1px solid #f0f0f0",
  },
  noRecord: {
    padding: "25px 16px",
    display: "flex",
    textAlign: "center" as CSSProperties["textAlign"],
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
    cursor: "pointer",
  },
  selectForm: {
    minWidth: "15%",
    color: "#181d25",
    border: "1px solid #f0f0f0",
  },
  linkOption: {
    fontSize: "14px",
  },
  companyTag: {
    background: "rgb(30,198,91,0.12)",
    color: "#1EC65B",
    textAlign: "center" as CSSProperties["textAlign"],
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
  approved: {
    background: "rgb(30,198,91,0.2)",
    color: "rgb(30 198 91)",
  },
  rejected: {
    background: "rgb(242,23,23, 0.2)",
    color: "#F21717",
  },
  pending_approval: {
    background: "rgb(252, 132, 52, 0.2)",
    color: "rgb(252, 132, 52)",
  },
  reject_deletion: {
    color: "red",
    background: "rgb(242,23,23, 0.2)",
  },
  deactive: {
    color: "red",
    background: "rgb(242,23,23, 0.2)",
  },
  pending_deletion: {
    color: "rgb(252, 132, 52)",
    background: "rgb(252, 132, 52, 0.2)",
  },
  moreOptions: {
    position: "relative" as CSSProperties["position"],
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "160px",
    left: "-130px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  option1: {
    marginBottom: "15px" as CSSProperties["marginBottom"],
  },
  rowList: {
    listStyle: "none",
  },
  selectBoxLeft: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  selectBoxRight: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectBoxes: {
    marginTop: "30px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchBtn: {
    marginTop: "5px",
    marginLeft: "15px",
    height: "35px",
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontSize: "14px",
    boxShadow: "unset",
    minHeight: "35px",
  },
  searchText: {
    marginLeft: "10px",
  },
  tableContainer: {
    background: "#fff",
  },
  tableHeader: {
    padding: "30px 16px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableFooter: {
    padding: "30px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeading: {
    fontSize: "22px",
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  searchIconButton: {
    padding: "0px",
  },
  innerInput: {
    padding: "0px",
    color: "#212121",
  },
};

export default withTranslation()(withRouter(RealEstateCompaniesTable));
// Customizable Area End
