import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  Select,
  InputAdornment,
  Button,
  TableContainer,
  TableHead,
  Table,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Divider,
  styled,
  IconButton,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import { t } from "i18next";
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from "@material-ui/lab/Pagination";
import { Menu } from "@szhsin/react-menu";
import { SuccessIcon } from "./assets";
type TFunction = (value: string) => string;
// Customizable Area End

import GrantedPermissionsController, {
    Props,
} from "./GrantedPermissionsController.web";

// Customizable Area Start
export default class GrantedPermissions extends GrantedPermissionsController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <CEHeaderComponentWeb>
                <DashboardApprovalCss>
                    <BoxView className="containerBox">
                        <BoxView className="navigation">
                            <Typography variant="body1" className="">
                                <Link href="/CompanyEmployee/dashboard">
                                    {t("My Dashboards")}
                                </Link>{" "}
                                /{" "}
                                <BoxView component="span">
                                    {t("Approval Dashbaord")}
                                </BoxView>{" "}
                                /{" "}<BoxView component="span" className="tabColor">
                                    {t(this.state.tags)}
                                </BoxView>
                            </Typography>

                        </BoxView>
                        <br />
                        <BoxView className="header-title">
                            <Typography variant='h4' className="bold-text">{t("Granted Permissions")}</Typography>
                            <Typography className="bold-text downloadButton" data-test-id="open-Granted-Dialog"  onClick={()=> this.setState({open:true})} >{t("GRANT PERMISSION")}</Typography>
                        </BoxView>
                        <BoxView >
                            <Grid container xs={12} spacing={2}>
                                <Grid item>
                                    <Typography style={this.state.tags === "Granted to Sales Managers" ? tagesCss.selectTag : tagesCss.unSelectTage}  data-test-id="subscriptionId" onClick={() => this.handleTag("Granted to Sales Managers")} className="bold-text">{t("Granted to Sales Managers")}</Typography>
                                </Grid>
                                <Grid item>
                                    <BoxView>
                                        <Typography  onClick={() => this.handleTag("Granted to Account Managers")} style={this.state.tags === "Granted to Account Managers" ? tagesCss.selectTag : tagesCss.unSelectTage} data-test-id="IndividualId" className="bold-text">{t("Granted to Account Managers")}</Typography>
                                    </BoxView>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} spacing={2}>
                               {this.state.tags === "Granted to Sales Managers" &&  <Grid item>
                                    <BoxView>
                                        <Select
                                            disableUnderline
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            displayEmpty
                                            data-test-id="selectSalesManagerId"
                                            className="selectmenu"
                                            value={this.state.salesManager}
                                            id="select-Country"
                                            onChange={(event) => this.handleSalesManagerChange(event)}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled
                                                id="selectStatusBudgetReport"
                                            >
                                                {t("Sales Manager")}
                                            </MenuItem>
                                            <MenuItem
                                                value="All"
                                                id="selectStatusBudgetReportAll"
                                            >
                                                {t("All")}
                                            </MenuItem>
                                        </Select>
                                    </BoxView>
                                </Grid>}

                                {this.state.tags === "Granted to Account Managers" &&  <Grid item>
                                    <BoxView>
                                        <Select
                                            disableUnderline
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            displayEmpty
                                            data-test-id="selectCountryId"
                                            className="selectmenu"
                                            value={this.state.accountManager}
                                            id="select-Country"
                                            onChange={(event) => this.handleAccountManagerChange(event)}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled
                                                id="selectStatusBudgetReport"
                                            >
                                                {t("Account Manager")}
                                            </MenuItem>
                                            <MenuItem
                                                value="All"
                                                id="selectStatusBudgetReportAll"
                                            >
                                                {t("All")}
                                            </MenuItem>
                                        </Select>
                                    </BoxView>
                                </Grid>}
                                <Grid item>
                                    <Select displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="selectCityId"
                                        value={this.state.grantedBy}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handleGrantedByChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Granted By")}
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Select 
                                        displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="selectUserTypeId"
                                        value={this.state.grantedOn}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handleGrantedOnChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Granted On")}
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Select 
                                        displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="selectRequestorId"
                                        value={this.state.permissionType}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handlePermissionTypeChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Permission Type")}
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className="mybtn"
                                        data-test-id="saveButton"
                                    >
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        {t("Search")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid className="table-box-card">
                                <Grid item sm={12} md={12} xs={12}>
                                    <h4 className="bold-text table-top">{t(this.state.tags)}</h4>
                                    <Divider />
                                    <TableContainer component={Paper} className="table-container">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell
                                                     className="bold-text"
                                                    >
                                                        {t("Permission Type")}
                                                    </TableCell>
                                                    {this.state.tags === "Granted to Sales Managers" && <TableCell
                                                        className="bold-text"
                                                    >
                                                        {t("Sales Manager")}
                                                    </TableCell>}
                                                    {this.state.tags === "Granted to Account Mancaers" && <TableCell
                                                        className="bold-text"
                                                    >
                                                        {t("Account Manager")}
                                                    </TableCell>}
                                                    <TableCell
                                                        className="bold-text"
                                                    >
                                                        {t("Granted By")}
                                                    </TableCell>
                                                    <TableCell
                                                    
                                                        className="bold-text"
                                                    >
                                                        {t("Granted On")}
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>1</TableCell>
                                                    <TableCell>Nomination Start</TableCell>
                                                    <TableCell className="ellipse-one">
                                                    Ainara Vergara
                                                    </TableCell>
                                                    <TableCell>
                                                    Ainara Vergara
                                                    </TableCell>
                                                    <TableCell>
                                                    01-06-2022
                                                    </TableCell>
                                                    <TableCell className="ce-pm-report-menu">
                                                        <Menu menuButton={<IconButton><MoreVertIcon /></IconButton>}>
                                                            <MenuItem
                                                                className="ce-pm-view-report"
                                                                data-test-id="viewProfileId"
                                                                onClick={()=> this.setState({reject:true,})}
                                                            >
                                                                {t("Revoke")}
                                                            </MenuItem>
                                                        </Menu>

                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <BoxView className="table-bottom">
                                        <p>
                                            {t("Showing")} <span className="current-page">0</span> {t("of")}{" "}
                                            <span className="total-page">0</span>{" "}
                                            {t("results")}
                                        </p>
                                        <BoxView>
                                            <Pagination
                                                id="PaginationModule"
                                                count={10}
                                                variant="outlined"
                                                shape="rounded"
                                                data-test-id="paginationID"
                                                style={{ marginRight: "20px" }}
                                            />
                                        </BoxView>

                                    </BoxView>
                                </Grid>
                            </Grid>
                        </BoxView>
                    </BoxView>

                    <DialogActionsCss aria-labelledby="customized-dialog-title" open={this.state.open} maxWidth="lg">
                        <DialogTitle id="customized-dialog-title" >
                            <BoxView className="titleBox">
                                <Typography className="bold-text" variant="h6">{t("Grant Permission")}</Typography>
                                <CloseIcon data-test-id="findPesoneId" className="closeIcon" onClick={()=>this.setState({open:false})} />
                            </BoxView>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <BoxView>
                                        <Typography className="bold-text">{t("Permission")}</Typography>
                                        <Select displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="propertyManagerId"
                                        value={this.state.propertyManage}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handlePropertyManageChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Select Permission")}
                                        </MenuItem>
                                    </Select>
                                    </BoxView>
                                </Grid>
                                <Grid item xs={6}>
                                    <BoxView>
                                        <Typography className="bold-text">{t("Role")}</Typography>
                                        <Select displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="selectRolerId"
                                        value={this.state.role}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handleRoleChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Select Role")}
                                        </MenuItem>
                                    </Select>
                                    </BoxView>
                                </Grid>
                                <Grid item xs={6}>
                                    <BoxView>
                                        <Typography className="bold-text">{t("Employee Name")}</Typography>
                                        <Select displayEmpty
                                        IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                        data-test-id="selectEmployeeId"
                                        value={this.state.employeeName}
                                        disableUnderline
                                        className="selectmenu"
                                        id="select-city1"
                                        onChange={(event) => this.handleEmployeeNameChange(event)}
                                    >
                                        <MenuItem
                                            disabled
                                            value=""
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Select Name")}
                                        </MenuItem>
                                    </Select>
                                    </BoxView>
                                </Grid>
                                
                            </Grid>
                        </DialogContent>
                        <DialogActions className="buttons">
                            <Button color="primary" className="cancleButton" data-test-id="findPersonCancle" onClick={()=>this.setState({open:false})}>
                                {t("CANCEL")}
                            </Button>
                            <Button color="primary" onClick={()=>this.setState({open:false})} className="selectButton" data-test-id="selectParsonId">
                                {t("SAVE")}
                            </Button>
                        </DialogActions>
                    </DialogActionsCss>

                    <DialogActionsCss
                        fullWidth
                        open={this.state.reject }
                        className="cancel-meeting-dialog submit-meeting-minute-dialog"
                    >
                        <DialogContent className="dialog-content">
                            <BoxView textAlign="center">
                                <img className="comment-image" src={SuccessIcon} alt="check" />
                                <Typography variant="h6" className="bold-text">
                                    {t("Revoke Permission?")}
                                </Typography>
                                <Typography variant="body1" className="body-text" >
                                    {t("Are you sure want to revoke view lease agreement permission from Alex Walker?")}
                                </Typography>
                                <DialogActions className="dialog-button-group">
                                    <Button
                                        className="cancel-button"
                                        data-test-id="cancelButtonId"
                                        onClick={()=>this.setState({reject:false})}
                                    >
                                        {t("Close")}
                                    </Button>
                                    <Button className="add-button" data-test-id="confirmButtonId" onClick={()=>this.setState({reject:false})}>
                                        {t("Confirm")}
                                    </Button>
                                </DialogActions>
                            </BoxView>
                        </DialogContent>
                    </DialogActionsCss>
                </DashboardApprovalCss>
            </CEHeaderComponentWeb>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DashboardApprovalCss = styled(Box)({
  '& .containerBox': {
      padding: "0px 40px"
  },
  '& .selectmenu': {
      border: '1px solid #F0F0F0',
      padding: '7px',
      background: '#fff',
      borderRadius: '15px',
      borderBottom: 'none',
      width: '100%',
      gap: '10px',
      marginTop: '10px',
  },
  '& .mybtn': {
      backgroundColor: '#2D6EED',
      color: 'white',
      padding: '8px 16px',
      marginTop: '11px',
      textTransform: 'capitalize',
  },
  '& .Box': {
      borderRadius: '10px',
      backgroundColor: '#fff',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      paddingLeft: '30px',
      alignItems: 'flex-start',
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      marginTop: '25px',
  },
  '& .selectTag': {
      padding: '10px 20px',
      background: '#2B6FED',
      color: '#fff',
      marginTop: '35px',
      cursor: 'pointer',
      borderRadius: '20px',
  },
  '& .unSelectTage': {
      marginTop: '35px',
      background: '#EEF0F8',
      color: '#8D8F98',
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
  },
  "& .header-title": {
      display: "flex",
      justifyContent: "space-between",
  },
  '& .table-container': {
      marginTop: '20px',
      boxShadow: 'none',
  },
  '& .tabColor': {
      color: "rgb(43, 111, 237)"
  },
  '& .table-top': {
      margin: "0px 20px 0px auto",
  },
  "& .downloadButton": {
      padding: "10px 20px",
      background: "#FC8434",
      color: "#fff",
      borderRadius: "8px",
      cursor: "pointer"
  }
  
});
export const DialogActionsCss = styled(Dialog)({
  '& .cancel-button' : {
      border: "1px solid #2b6fed",
      backgroundColor: "white",
      color: "#2b6fed",
      borderRadius: "8px",
      fontWeight: 600,
      padding:"10px 30px",
      width: "200px",
  },
  '& .add-button': {
      backgroundColor: "#2b6fed",
      color: "white",
      borderRadius: "8px",
      fontWeight: 600,
      margin: "0",
      width: "200px",
      padding:"10px 30px",
  },
  '& .dialog-button-group': {
      gap:"10px"
  },
  '& .body-text': {
      marginBottom: "8px" 
  },
  '& .dialog-content': {
      margin: "15px 0"
  },
  '& .lableText': {
      color:"#fc8434",
      fontSize:"14px"
  },
  '& .selectButton': {
      color: "white",
      border:"1px solid #2B6FED", 
      padding:"15px 60px", 
      fontWeight:900,
      alignItems:"end",
      backgroundColor:"#2B6FEC",
      borderRadius:"8px"
  },
  '& .cancleButton': {
      color: "#2B6FED",
      border:"1px solid #2B6FED", 
      padding:"15px 50px", 
      fontWeight:900,
      alignItems:"end",
      borderRadius:"8px"

  },
  '& .titleBox': {
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      minWidth:"852px"
  },
  '& .buttons': {
      flexDirection: "unset", 
      gap: "15px",
      margin: "20px 35px 10px 0px "
  }, 
  '& .selectmenu': {
      border: '1px solid #F0F0F0',
      padding: '15px',
      background: '#C8C8C838',
      borderRadius: '15px',
      borderBottom: 'none',
      width: '100%',
      gap: '10px',
      marginTop: '10px',
  },
  "& .closeIcon": {
      cursor:"pointer"
  }

})
export const tagesCss = {
  selectTag: {
      background: "#2B6FED",
      padding: "10px 20px",
      marginTop: "20px",
      color: "#fff",
      borderRadius: "20px",
      cursor: "pointer"
  },
  unSelectTage: {
      background: "#EEF0F8",
      marginTop: "20px",
      color: "#8D8F98",
      padding: "10px 20px",
      borderRadius: "20px",
      cursor: "pointer"
  },
}

// Customizable Area End
