// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Card,
  Chip,
  Link,
  Typography,
  withStyles,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Divider,
  Table,
  Dialog,
  InputLabel,
  DialogContent,
  TextareaAutosize,
  DialogActions,
  IconButton,
  Input,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import Slider from "react-slick";
import { nextIcon, previousIcon, downloadIcon, Document, Close, uploadbw } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import SharedAreaDetailsController, { Props, Reservation } from "./SharedAreaDetailsController.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { BuildingApartmentStyle } from "../../../LeadManagement/src/BuildingApartmentStyle.web";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CloseIcon as Close_Icon } from "../assets";

const settings = {
  infinite: false, slidesToShow: 5, swipeToSlide: true,
};

class SharedAreaDetails extends SharedAreaDetailsController {
  constructor(props: Props) {
    super(props);
  }

  DialogBox = (t: (val: string) => string, language: string) => {
    return (
      <Dialog
        className="edit-profile edit-share-area-modal"
        open={this.state.setEditOpen}
        scroll="paper"
        dir={languageCondition(language, "rtl", "ltr")}
        fullWidth
        maxWidth="md"
      >
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Edit Details")}
          </Typography>
          <IconButton onClick={() => this.setState({ setEditOpen: false })} id="closeBtn">
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.editForm}
          onSubmit={(values, { resetForm }) => {
            this.handleSharedAreaEditModalCloseVal();
            this.handleSaveSharedAreaDetailsData(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit} id="testVal">
                <DialogContent dividers>
                  <Grid container spacing={2} className="edit-building">
                    <Grid item xs={12}>
                      <InputLabel>{t("Upload Photos")}</InputLabel>
                      <Grid container spacing={4}>
                        <Grid item md={3} className="testShare">
                          <Box
                            className="upload-photo shared-area-upload-images upl"
                            onClick={() => this.uploadImages.click()}
                          >
                            <img src={uploadbw} alt="images" />
                          </Box>
                          <input
                            id="testup"
                            type="file"
                            multiple
                            onBlur={handleBlur}
                            accept="image/*"
                            ref={(ref: any) => (this.uploadImages = ref)}
                            onChange={(e: any) => {
                              const newPhotos = [...values.photos];
                              for (let file of e.target.files) {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  newPhotos.push(reader.result);
                                  setFieldValue("photos", newPhotos);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                            name="photos"
                            style={{ display: "none" }}
                            className="read uploadfileimages"
                          />
                        </Grid>
                        {values.photos.map((image: any, index: number) => {
                          return (
                            <Grid item md={3} key={index} className="shared-area-images-grid read">
                              <Box className="building-image shared-area-image-box read">
                                <img
                                  src={Close_Icon}
                                  className="delete-image shared-area-delete-cross-image read"
                                  onClick={() => {
                                    const remainImage = values.photos.filter((img: any, idx: number) => idx !== index);
                                    setFieldValue("photos", remainImage);
                                  }}
                                />
                                <img src={image} alt="shared-area-images" />
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {ErrorHandler(t, touched.photos, errors.photos)}
                    </Grid>
                    <Grid item xs={12} id="testid">
                      <InputLabel className="read">{t("Details")}</InputLabel>
                      <TextareaAutosize
                        className="about-us read"
                        placeholder={t("Details")}
                        value={values.details}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="details"
                      />
                      {ErrorHandler(t, touched.details, errors.details)}
                    </Grid>
                    <Grid item xs={12} id="testid">
                      <InputLabel className="read">{t("Total Area")}</InputLabel>
                      <Input
                        className="input-with-icon read"
                        fullWidth
                        placeholder={t("Total Area")}
                        value={values.totalArea}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="totalArea"
                      />
                      {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                    </Grid>
                    <Grid item xs={6} id="testid">
                      <InputLabel className="read">{t("Reservation Fees (Per hour)")}</InputLabel>
                      <Box className="measurement-modal-box read">
                        <Input
                          className="input-with-icon read"
                          fullWidth
                          placeholder={t("Reservation Fees (Per hour)")}
                          value={values.fees}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fees"
                          startAdornment={
                            <InputAdornment position="start">
                              {t(this.state.sharedArea?.currency?.currency)}
                            </InputAdornment>
                          }
                        />
                      </Box>
                      {ErrorHandler(t, touched.fees, errors.fees)}
                    </Grid>
                    <Grid item xs={6} id="testid">
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Unit of Measurement"
                        value={values.measurement}
                        name="measurement"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        option={[
                          { label: "Sq. Ft.", value: "Sqft" },
                          { label: "Sq. M.", value: "Sq m" },
                        ]}
                      />
                      {ErrorHandler(t, touched.fees, errors.fees)}
                    </Grid>
                    <Grid item xs={12} id="testid">
                      <InputLabel className="read">{t("Floor Plan")}</InputLabel>
                      <Box className="floor-plan-box read">
                        <input
                          id="floorPdf"
                          type="file"
                          ref={(ref: any) => (this.uploadFile = ref)}
                          style={{ display: "none" }}
                          accept=".pdf"
                          className="floor-plan-pdf loadPdf"
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            setFieldValue("floorPlan", file);
                            setFieldValue("floorPlanName", file.name);
                          }}
                          onBlur={handleBlur}
                          name="floorPlan"
                        />
                        {!values.floorPlan ? (
                          <span className="placeholder">{t("Floor Plan")}</span>
                        ) : (
                          <Chip
                            className="read"
                            label={values.floorPlanName}
                            onDelete={() => {
                              setFieldValue("floorPlan", null);
                              setFieldValue("floorPlanName", "");
                            }}
                            deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                          />
                        )}
                        <img onClick={() => this.uploadFile.click()} src={uploadbw} className="loadImg-pic" />
                      </Box>
                      {ErrorHandler(t, touched.floorPlan, errors.floorPlan)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog-button-group read" style={{ gap: "10px" }}>
                  <Button className="cancel-button read" onClick={() => this.setState({ setEditOpen: false })}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button read" style={{ margin: "0" }}>
                    {t("Save")}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  render() {
    const { t, classes, i18n } = this.props;
    const language = i18n.language;

    return (
      <CEHeaderComponentWeb>
        {this.DialogBox(t, language)}

        <Container className={classes.building}>
          <Box style={dashBoard.navigationPage}>
            <Box className="shared-area-heading-title">
              <Typography variant="body1">
                <Link href={`/CompanyEmployee/complexlisting`}>{t("Complex")}</Link> /{" "}
                <Link href="/CompanyEmployee/complexlisting">{t("Buildings")}</Link> /{" "}
                <Link href={`/CompanyEmployee/${this.state.details}/${this.state.complexId}`}>
                  {t("Complex Details")}
                </Link>{" "}
                / <Link href={`/CompanyEmployee/${this.state.details}/${this.state.complexId}`}>{t("Buildings")}</Link>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {this.state.sharedArea?.name}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid container style={dashBoard.gaMemberMainData}>
              <Grid item xs={6}>
                <Typography variant="h5" style={dashBoard.subHeadingData} className="bold-text">
                  {this.state.sharedArea?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  data-test-id="editOpen"
                  id="editTest"
                  className="edit-button"
                  variant="contained"
                  color="primary"
                  onClick={() => this.openSharedAreaEditModal()}
                >
                  {t("Edit Details")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="building-info">
            <Card>
              <Box className="building-info-bottom shared-area-image">
                {this.state.sharedArea?.photos?.length === 0 ? (
                  <span>{t("No Photos Available")}</span>
                ) : (
                  <>
                    <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                      {this.state.sharedArea?.photos?.map((image: any, index: number) => {
                        return (
                          <div
                            className="slider-image-box shared-area-image-popup testData"
                            onClick={() => this.setState({ imageBoxData: true, photoIndex: index })}
                            key={index}
                          >
                            <img src={image.url} alt="shared-area-images" className="tcell" />
                          </div>
                        );
                      })}
                    </Slider>
                    <Box className="slick-bottom shared-area-slick-button tcell">
                      <Box className="button prev shared-area-prev-button tcell" onClick={this.previousImage}>
                        <img src={previousIcon} alt="" className="tcell" />
                      </Box>
                      <Box className="button next shared-area-next-button tcell" onClick={this.nextImage}>
                        <img src={nextIcon} alt="" className="tcell" />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Card>
          </Box>

          <Box className="about-building">
            <Card>
              <h4 className="bold-text">{t("Details")}</h4>
              <p>{this.state.sharedArea?.details || "-"}</p>
              <Box className="bottom-detail" style={{ marginTop: "18px" }}>
                <Box className="left-detail">
                  <p>
                    {t("Total Area")}:{" "}
                    <span className="bold-text">
                      {Number(this.state.sharedArea?.total_area || "0").toLocaleString()}
                    </span>
                  </p>
                  <p>
                    {t("Reservation fees")}:{" "}
                    <span className="bold-text">
                      {Number(this.state.sharedArea?.reservation_fee || "0").toLocaleString()} {t("per hour")}
                    </span>
                  </p>
                </Box>
                {this.state.sharedArea?.floor_plan && (
                  <Box className="right-detail">
                    <Box className="name">
                      <img src={Document} alt="" />
                      <h6 className="bold-text">{t("Floor Plan")}</h6>
                    </Box>
                    <Link href={this.state.sharedArea?.floor_plan.url} target="_blank">
                      <img src={downloadIcon} alt="" />
                    </Link>
                  </Box>
                )}
              </Box>
            </Card>
          </Box>

          <Box className="content-boxes shared-table">
            <Card>
              <Box className="top-content">
                <Box className="heading testRes">
                  <h2 className="bold-text">{t("Upcoming Reservation")}</h2>
                </Box>
              </Box>
              <Divider />
              <TableContainer className="unit-table testRes">
                <Table className="unit-table testRes">
                  <TableHead className="unitHeadData">
                    <TableRow className="unitHeadData">
                      <TableCell className="tcell">#</TableCell>
                      <TableCell className="tcell">{t("Reserved By")}</TableCell>
                      <TableCell className="tcell">{t("Unit No")}</TableCell>
                      <TableCell className="tcell">{t("Reserved On")}</TableCell>
                      <TableCell className="tcell">{t("Duration")}</TableCell>
                      <TableCell className="tcell"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.reservationData.length === 0 && (
                      <TableRow className="tcell">
                        <TableCell colSpan={5}>{t("No reservation available")}</TableCell>
                      </TableRow>
                    )}
                    {this.state.reservationData.map((item: Reservation) => {
                      return (
                        <TableRow key={item.id} className="sa-table-body-row">
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.attributes.reserved_by.name}</TableCell>
                          <TableCell>{item.attributes.apartment_management?.apartment_name}</TableCell>
                          <TableCell>{item.attributes.reserved_on}</TableCell>
                          <TableCell>{item.attributes.duration.duration}</TableCell>
                          <TableCell align="right">
                            <Menu menuButton={<MoreVertIcon />}>
                              <MenuItem>{t("View Reservation")}</MenuItem>
                              <MenuItem>{t("Cancel Reservation")}</MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
        </Container>
      </CEHeaderComponentWeb>
    );
  }
}

const dashBoard = {
  navigationPage: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingData: {
    fontWeight: 600,
    marginTop: 0,
  },
  gaMemberMainData: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    justifyContent: "space-between",
  },
};

export default withTranslation()(withStyles(BuildingApartmentStyle)(withRouter(SharedAreaDetails)));
// Customizable Area End
