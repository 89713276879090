import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { getStorageData } from "framework/src/Utilities";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
navigation: typeof navigation;
  id: string;
  t: (value: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewSavedTemplateModal: boolean;
  savedTemplateModalData: string;
  loader: boolean;
  showError: boolean;
  errorMessage: string;
  searchText: string;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  savedTemplatesList: Array<SavedTemplatesItem> | null;
  selectedTemplate: number | null;
  savedTemplateId: string;
  complexType: string;
  showSuccess: boolean;
  showSuccessMessage: string;
  ownerName: string;
  isTenantNameEnabled: boolean;
  successMessage: string;
  constractId: string;
  error:string;
  termsId: Array<number>;
  conditionId: Array<number>;
  createContractPayload: CreateContractPayload;
  successError: HasSuccessErrorType;
  reviewDraftDetails: ContractDetailsSuccessResponse;
  // Customizable Area End
}

// Customizable Area Start
interface CreateContractPayload {
  complex: string;
  templateId: string;
  building_management_id: string;
  country: string;
  city: string;
  unitName: string;
  buildingName: string;
  tenant_name: TenantNameInterface;
  complexName: string;
  society_management_id: string;
  landlord_name: string;
  tenant_id: string;
  expires_on: string;
  apartment_management_id: string;
  agreement_duration: string;
  start_date: string;
  term_ids: Array<{id:number,text:string}>;
  lease_template_id: string;
  condition_ids: Array<{id:number,text:string}>;
  currency: string;
  rent_amount: string;
  custom_contract: boolean;
  penanlty_late_payment: boolean;
  contract_template: string;
  owner: string | null;
  accountId: Account_id;
  contractLink: string;
}
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
};
type TenantNameInterface = null | number | string
type Account_id = null | number | string

export interface ContractDetailsSuccessResponse {
  contract: {
    data: {
      id: string;
      type: string;
      attributes: {
        apartment_management_id: number | string;
        account_id: number | string;
        building_management_id: number | string;
        society_management_id: number | string;
        agreement_duration: string;
        region: string | null;
        country: string | null;
        landlord_name: string;
        start_date: string;
        city: string | null;
        currency: string;
        state: string | null;
        expires_on: string;
        rent_amount: number | string;
        duration: string | null;
        tenant_name: string;
        penanlty_late_payment: boolean;
        lease_template_id: number | string;
        owner: {
          first_name: string | null;
          phone_number: PhoneNumber
          id: number | string;
          email: string;
          last_name: string | null;
          device_id: string | null;
          country_code: string | null;
          created_at: string;
          activated: boolean;
          full_phone_number: string;
          unique_auth_id: string | null;
          updated_at: string;
          user_name: string | null;
          last_visit_at: string | null;
          user_type: string | null;
          suspend_until: string | null;
          app_language_id: AppLanguageId;
          platform: string | null;
          stripe_id: string | null;
          role_id: RoleId;
          status: string;
          is_blacklisted: boolean;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          full_name: string;
          date_of_birth: string | null;
          is_paid: boolean;
          gender: string | null;
          age: Age;
          owner_phone_number: string | null;
          company_name: string | null;
          manager_full_name: string | null;
          owner_full_name: string | null;
          owner_email: string | null;
          public_field: string | null;
          building_management_id: BuildingManagementId;
          disable_chat: boolean;
          language_type: string | null;
          ticket_days_configuration: number | string;
        };
        contract_template: string;
        custom_contract: boolean;
        created_at: string;
        updated_at: string;
        status: string;
        owner_name: string | null;
        
        
        lease_template_text: string;
        building_name: string;
        contract_type: string;
        expire_on: number | string;
        lease_template_pdf: {
          url: string;
        };
        contract_template_pdf: {
          url: string;
        };
        
        society_management: {
          name: string;
          created_at: string;
          updated_at: string;
          description: string;
          complex_area: string;
          ticket_days_configuration: number | string;
          maintenance_per_square_feet: number | string;
          measurement_unit: string;
          id: number | string;
          zone_multiplier: number | string;
          contigency: number | string;
          latitude: string | null;
          late_charge_percentage: string;
          real_estate_company_id: RealEstateCompanyId;
          value_added_tax: string;
          longitude: string | null;
          account_manager_id: AccountManagerId;
          is_building: boolean;
          total_unit: TotalUnit;
          total_floor: TotalFloor;
          language: string | null;
          status: string | null;
          currency_id: CurrencyId;
        };
        tenant: {
          id: number | string;
          first_name: string | null;
          last_name: string | null;
          full_phone_number: string;
          country_code: string | null;
          phone_number: PhoneNumber
          email: string;
          activated: boolean;
          device_id: string | null;
          unique_auth_id: string | null;
          user_name: string | null;
          created_at: string;
          user_type: string | null;
          updated_at: string;
          platform: string | null;
          app_language_id: AppLanguageId;
          suspend_until: string | null;
          last_visit_at: string | null;
          stripe_id: string | null;
          status: string;
          is_blacklisted: boolean;
          stripe_subscription_date: string | null;
          stripe_subscription_id: string | null;
          role_id: RoleId;
          full_name: string;
          age: Age;
          gender: string | null;
          company_name: string | null;
          date_of_birth: string | null;
          owner_full_name: string | null;
          is_paid: boolean;
          owner_phone_number: string | null;
          manager_full_name: string | null;
          disable_chat: boolean;
          owner_email: string | null;
          language_type: string | null;
          building_management_id: BuildingManagementId;
          public_field: string | null;
          ticket_days_configuration: number | string;
        };
        penalty_late_payments: {
          penanlty_type: string | null;
          id: number | string;
          penanlty_counted: string;
          tenant_id: number | string;
          account_id: number | string;
          amount: string;
        };
        terms: Array<TermsAndConditionItem> ;
        custom_term_condition: null;
        conditions: Array<TermsAndConditionItem> ;
        unit_name: string;
        custom_contract_image: string | null;
        custom_term_condition_added: boolean;
      };
    };
  };
  message: string;
  code: number;
};
export interface TermsAndConditionItem {
  id: number;
  text: string;
}
type PhoneNumber =  number | string | null;
type AppLanguageId =  number | string | null;
type RoleId =  number | string | null;
type Age =  number | string | null;
type BuildingManagementId =  number | string | null;
type RealEstateCompanyId =  number | string | null;
type AccountManagerId =  number | string | null;
type IsBuilding =  boolean;
type TotalUnit =  number | string | null;
type TotalFloor =  number | string | null;
type CurrencyId =  number | string | null;

export type TFunction = (value: string) => string;

export type DataType = string | number | null;

export interface SavedTemplatesItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    lease_template_id: number;
    template_name: string | null;
    custom_lease_template: DataType;
    account_id: number;
    apartment_management_id: DataType;
    building_management_id: DataType;
    society_management_id: number;
    landlord_name: string | null;
    country: string | null;
    city: string | null;
    agreement_duration: DataType;
    start_date: DataType;
    rent_amount: DataType;
    currency: DataType;
    expires_on: DataType;
    tenant_name: DataType;
    duration: DataType;
    contract_type: DataType;
    custom_term_condition: DataType;
    penanlty_late_payment: boolean | null;
    custom_contract: boolean;
    tenant_id: DataType;
    term_ids: Array<string> | null;
    condition_ids: Array<string> | null;
    lease_template_text: string;
    lease_template_pdf: {
      url: string;
    };
    custom_lease_template_pdf: {
      url: string;
    } | null;
    terms: Array<string> | null;
    conditions: Array<string> | null;
    penanlty_late_payments: boolean | null;
    society_management: SocietyManagement | null;
    building_management: BuildingManagement | null;
    apartment_management: ApartMentManagement | null;
    template_in_used_count: number | null;
  }
}

export interface ApartMentManagement {
  id: number;
  apartment_name: string;
}

export interface BuildingManagement {
  id: number;
  name: string;
}

export interface SocietyManagement {
  id: number;
  name: string;
}

export interface SavedTemplateListResponse {
  owner_name: string;
  templates: {
    data: Array<SavedTemplatesItem>;
  }
}

export interface DeleteSavedTemplateResponse {
  message: string;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
  contract: string
};

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
// Customizable Area End

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedTemplateDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedTemplateListApiCallId: string = "";
  deleteSavedTemplateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ContractDetailsId),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      viewSavedTemplateModal: false,
      savedTemplateModalData: "",
      loader: false,
      showError: false,
      errorMessage: "",
      searchText: "",
      anchorEl: null,
      selectedId: 0,
      savedTemplatesList: [],
      selectedTemplate: null,
      savedTemplateId: "",
      complexType:"",
      showSuccess: false,
      isTenantNameEnabled: false,
      ownerName:"",
      successError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      error:"",
      constractId: "",
      showSuccessMessage: "",
      successMessage: "",
      termsId: [],
      conditionId: [],
      reviewDraftDetails: {
        "code": 200,
        "message": "",
        "contract": {
          "data": {
            "type": "contract",
            "id": "",
            "attributes": {
              "account_id": 0,
              "building_management_id": 0,
              "apartment_management_id": 0,
              "landlord_name": "",
              "country": null,
              "region": null,
              "society_management_id": 0,
              "agreement_duration": "",
              "start_date": "",
              "state": null,
              "rent_amount": 0,
              "currency": "",
              "expires_on": "",
              "status": "",
              "tenant_name": "",
              "city": null,
              "duration": null,
              "created_at": "",
              "penanlty_late_payment": true,
              "custom_contract": false,
              "lease_template_id": 0,
              "owner_name": null,
              "updated_at": "",
              "contract_template": "",
              "owner": {
                "first_name": null,
                "last_name": null,
                "id": 0,
                "full_phone_number": "",
                "email": "",
                "phone_number": 0,
                "device_id": null,
                "country_code": null,
                "unique_auth_id": null,
                "created_at": "",
                "updated_at": "",
                "platform": null,
                "activated": true,
                "user_type": null,
                "app_language_id": null,
                "last_visit_at": null,
                "is_blacklisted": false,
                "user_name": null,
                "suspend_until": null,
                "stripe_id": null,
                "stripe_subscription_id": null,
                "stripe_subscription_date": null,
                "status": "",
                "full_name": "",
                "gender": null,
                "date_of_birth": null,
                "age": null,
                "company_name": null,
                "owner_full_name": null,
                "role_id": null,
                "is_paid": false,
                "manager_full_name": null,
                "owner_phone_number": null,
                "disable_chat": false,
                "owner_email": null,
                "public_field": null,
                "language_type": null,
                "ticket_days_configuration": 0,
                "building_management_id": null
              },
              "contract_template_pdf": {
                "url": ""
              },
              "lease_template_pdf": {
                "url": ""
              },
              "contract_type": "",
              "expire_on": 0,
              "lease_template_text": "",
              "building_name": "",
              "society_management": {
                "description": "",
                "maintenance_per_square_feet": 0,
                "id": 0,
                "created_at": "",
                "ticket_days_configuration": 0,
                "complex_area": "",
                "name": "",
                "real_estate_company_id": null,
                "updated_at": "",
                "zone_multiplier": 0,
                "contigency": 0,
                "latitude": null,
                "late_charge_percentage": "",
                "measurement_unit": "",
                "value_added_tax": "",
                "longitude": null,
                "total_unit": null,
                "is_building": false,
                "account_manager_id": null,
                "total_floor": null,
                "currency_id": null,
                "language": null,
                "status": null
              },
              "unit_name": "",
              "custom_term_condition_added": false,
              "conditions": [],
              "custom_term_condition": null,
              "terms": [],
              "tenant": {
                "first_name": null,
                "id": 0,
                "full_phone_number": "",
                "last_name": null,
                "company_name": null,
                "manager_full_name": null,
                "owner_full_name": null,
                "owner_email": null,
                "public_field": null,
                "phone_number": null,
                "owner_phone_number": null,
                "disable_chat": false,
                "ticket_days_configuration": 0,
                "language_type": null,
                "email": "",
                "country_code": null,
                "building_management_id": null,
                "unique_auth_id": null,
                "device_id": null,
                "suspend_until": null,
                "status": "",
                "activated": true,
                "stripe_subscription_id": null,
                "stripe_id": null,
                "stripe_subscription_date": null,
                "full_name": "",
                "gender": null,
                "date_of_birth": null,
                "role_id": null,
                "is_paid": false,
                "age": null,
                "platform": null,
                "user_type": null,
                "app_language_id": null,
                "updated_at": "",
                "created_at": "",
                "last_visit_at": null,
                "user_name": null,
                "is_blacklisted": false
              },
              "custom_contract_image": null,
              "penalty_late_payments": {
                "id": 0,
                "penanlty_counted": "",
                "account_id": 0,
                "penanlty_type": null,
                "tenant_id": 0,
                "amount": ""
              }
              }
            }
          }
        },
      createContractPayload: {
        country: "",
        templateId: "",
        complex: "",
        city: "",
        unitName: "",
        buildingName: "",
        tenant_name: "",
        complexName: "",
        building_management_id: "",
        landlord_name: "",
        tenant_id: "",
        society_management_id: "",
        apartment_management_id: "",
        expires_on: "",
        start_date: "",
        agreement_duration: "",
        lease_template_id: "",
        term_ids: [],
        currency: "",
        rent_amount: "",
        custom_contract: false,
        condition_ids: [],
        contract_template: "",
        penanlty_late_payment: false,
        accountId: "",
        owner: "",
        contractLink: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.savedTemplateDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.savedTemplateDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let savedTemplateId = await getStorageData('savedTemplateId');
      let complexType = await getStorageData('complexType');

      this.setState({ savedTemplateId: savedTemplateId, complexType: complexType }, () => {
        this.getSavedTemplateList();
      })
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSavedTemplateList();
  }

  contractDetailsApiCall = async (data: APIPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      "Content-Type": contentType,
      token: token
    };
    let contractDetailsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    body && type !== "formData"
      ? contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
    return contractDetailsrequestMessage.messageId;
  };

  savedTemplateDetailsApiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: SavedTemplateListResponse & DeleteSavedTemplateResponse) => {
    if (apiRequestCallId === this.getSavedTemplateListApiCallId) {
      this.savedTemplateListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteSavedTemplateApiCallId) {
      this.deleteSavedTemplateItemSucessCallBack(responseJson);
    }
  };

  savedTemplateDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.getSavedTemplateListApiCallId) {
      this.savedTemplateListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteSavedTemplateApiCallId) {
      this.deleteSavedTemplateItemFailureCallBack(responseJson);
    }
  };

  getSavedTemplateList = async () => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.savedTemplateItemListEndpoint}${this.state.savedTemplateId}&search=${this.state.searchText}`;
    this.getSavedTemplateListApiCallId = await this.contractDetailsApiCall({
      method: configJSON.contractDetailsMethod,
      endPoint: endPoint
    });
  };

  savedTemplateListSucessCallBack = (response: SavedTemplateListResponse) => {
    if (response.templates.data === null || response.templates.data.length === 0) {
      this.setState({ showError: true, errorMessage: "No Templates Found", loader: false });
    } else {
      this.setState({ savedTemplatesList: response.templates.data, ownerName: response.owner_name, loader: false });
    }
  };

  savedTemplateListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
  };

  handleDeleteTemplate = async (templateId: string) => {
    this.setState({ loader: true, anchorEl: null, selectedId: null });
    this.deleteSavedTemplateApiCallId = await this.contractDetailsApiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.deleteSavedTemplateItem}${templateId}`,
    });
  };

  deleteSavedTemplateItemSucessCallBack = (response: DeleteSavedTemplateResponse) => {
    this.setState({ showSuccess: true, showSuccessMessage: response.message, loader: false }, () => {
      this.getSavedTemplateList();
    });
  };

  deleteSavedTemplateItemFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, selectedId: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: selectedId });
  };

  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      this.getSavedTemplateList();
    });
  };

  handleSavedTemplateModal = () => {
    this.setState({ viewSavedTemplateModal: !this.state.viewSavedTemplateModal });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };

  downloadTemplate = (downloadUrl: string) => {
    const downloadBtn = document.createElement('a');
    downloadBtn.href = downloadUrl;
    downloadBtn.download = 'document.pdf';
    downloadBtn.target = '_blank';
    document.body.appendChild(downloadBtn);
    downloadBtn.click();
    document.body.removeChild(downloadBtn);
  };

  viewTemplate = (templateId: string) => {
    if (this.state.savedTemplatesList !== null) {
      let viewTemplateModalData = this.state.savedTemplatesList.find((item) => {
        return item.id === templateId
      });
      if (viewTemplateModalData) {
        let template = viewTemplateModalData.attributes.lease_template_text
        template = template.replace("{{TENANT_NAME}}", viewTemplateModalData.attributes.tenant_name + "");
        template = template.replace("{{START_DATE}}", viewTemplateModalData.attributes.start_date + "");
        template = template.replace("{{BUILDING_NAME}}", viewTemplateModalData.attributes.building_management?.name + "");
        template = template.replace("{{UNIT_NAME}}", viewTemplateModalData.attributes.apartment_management?.apartment_name + "");
        template = template.replace("{{DURATION}}", viewTemplateModalData.attributes.agreement_duration + " months");
        template = template.replace("{{END_DATE}}", viewTemplateModalData.attributes.expires_on + "");
        template = template.replace("{{AMOUNT}}", viewTemplateModalData.attributes.rent_amount + "");
        template = template.replace("{{COMPLEX_NAME}}", viewTemplateModalData.attributes.society_management?.name + "");
        template = template.replace("{{COMPLEX_ADDRESS}}", viewTemplateModalData.attributes.city + ", " + viewTemplateModalData.attributes.country);
        template = template.replace(/{{LANDLORD_NAME}}/g, viewTemplateModalData.attributes.landlord_name + "");
        this.setState({ savedTemplateModalData: template }, () => {
          this.setState({ viewSavedTemplateModal: !this.state.viewSavedTemplateModal }, () => {
            this.handleClose();
          });
        });
      }
    }
  };

  handleCloseAlertError = () => {
    this.setState({ showError: false });
  };

  handleCloseAlertSuccess = () => {
    this.setState({ showSuccess: false});
  };

  handleNavigation = (savedTemplatesList: any) => {
    if (!savedTemplatesList || !savedTemplatesList[0]?.attributes) {
      return;
    }
  
    const attributes = savedTemplatesList[0]?.attributes;
  
    let createContractPayload = {
      templateId: attributes.lease_template_id,
      country: attributes.country,
      city: attributes.city,
      complex: this.state.complexType,
      buildingName: attributes.building_management?.name ,
      unitName: attributes.apartment_management?.apartment_name,
      complexName: attributes.society_management?.name ,
      tenant_name: "",
      landlord_name: attributes.landlord_name,
      building_management_id: attributes.building_management?.id ,
      society_management_id: attributes.society_management?.id,
      tenant_id: "",
      expires_on: "",
      apartment_management_id: attributes.apartment_management?.id ,
      agreement_duration: "",
      start_date: "",
      term_ids: this.state.termsId,
      lease_template_id: attributes.lease_template_id,
      rent_amount: "",
      currency: "",
      condition_ids: this.state.conditionId ,
      custom_contract: attributes.custom_contract,
      penanlty_late_payment: attributes.penanlty_late_payment,
      contract_template: attributes.lease_template_text,
      owner: attributes.landlord_name,
      ownerNumber: "",
      ownerEmail: "",
      accountId: attributes.account_id,
      status: "active",
      isTenantNameEnabled: !!attributes.tenant_name
    };
  
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractForm");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayload);
    this.send(msgData);
  };
  handleNullValue = (value: null | { url: string }) => {
    return value === null ? "-" : value.url;
  }

  handleTopNavigation = () => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "LeaseManagment");
    this.send(msgData);
  };
  // Customizable Area End
}