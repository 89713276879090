// Customizable Area Start
import React from "react";
import { Avatar, Box, Card, Container, Grid, Typography, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import CEInviteUserProfileController, { Props } from "./CEInviteUserProfileController.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { ProfileStyleWeb } from "../../Settings5/src/ProfileStyle.web";
import { CallIcon, EmailIcon, FacebookIcon, InstagramIcon, SnapchatIcon, TwitterIcon } from "./assets";
import { handleLink, navigationLink } from "../../../components/src/UtilFunction.web";

class CEInviteUserProfile extends CEInviteUserProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes } = this.props;

    return (
      <>
        <Loader loading={this.state.loading} />

        <CEHeaderComponentWeb>
          <Container className={classes.ChairmanProfile}>
            <Box className="navigation" style={{ flexDirection: "column", alignItems: "flex-start", gap: "15px" }}>
              <Typography variant="body1" className="">
                <span className="main-report-link" onClick={() => navigationLink("ReportsStatistics", this)}>
                  {t("Documents & Reports")} / {t("Reports")} /
                </span>{" "}
                <span className="report-link" onClick={() => navigationLink("InvitationReports", this)}>
                  {t("Invitations Reports")}
                </span>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {this.state.inviteUserDetail && this.state.inviteUserDetail.attributes.full_name.name}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {this.state.inviteUserDetail && this.state.inviteUserDetail.attributes.full_name.name}
                </Typography>
              </Box>
            </Box>
            <Box className="my-profile-box">
              {this.state.inviteUserDetail && (
                <Card className="profile-details-box">
                  <Grid container>
                    <Grid item xs={3} className="left-side" style={{ padding: 20 }}>
                      <Avatar
                        className="profile"
                        alt={this.state.inviteUserDetail.attributes.full_name.name}
                        src={this.state.inviteUserDetail.attributes.profile_pic?.url}
                      />
                      <Typography variant="h6" className="sub-heading bold-text">
                        {this.state.inviteUserDetail.attributes.full_name.name}
                      </Typography>
                      <Typography variant="body2" className="sub-heading bold-text">
                        {this.state.inviteUserDetail.attributes.units}
                      </Typography>
                      <Box className="icons">
                        <img
                          onClick={() =>
                            handleLink(`tel:${this.state.inviteUserDetail?.attributes?.full_phone_number?.full_phone_number}`)
                          }
                          className="call-link"
                          src={CallIcon}
                          alt="phone"
                        />
                        <img
                          src={EmailIcon}
                          className="email-link"
                          alt="email"
                          onClick={() => handleLink(`mailto:${this.state.inviteUserDetail?.attributes?.email}`)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="border" />
                    <Grid item xs={8} className="right-side" style={{ padding: 20 }}>
                      <Grid container className="about">
                        <Grid item xs={12}>
                          <span>{t("About")}</span>
                          <p>{this.state.inviteUserDetail.attributes.bio}</p>
                        </Grid>
                      </Grid>
                      <Grid container className="info">
                        <Grid item xs={3}>
                          <span>{t("Gender")}</span>
                          <p>{this.state.inviteUserDetail.attributes.gender}</p>
                        </Grid>
                        <Grid item xs={3}>
                          <span>{t("DOB")}</span>
                          <p> {this.state.inviteUserDetail.attributes.date_of_birth}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <span>{t("Hobbies")}</span>
                          <Box className="hobbies" style={{ margin: "0px", padding: "2px" }}>
                            {(this.state.inviteUserDetail.attributes?.hobbies || []).map((hobby: string) => {
                              return (
                                <span key={hobby} style={{ textTransform: "capitalize" }}>
                                  {hobby}
                                </span>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container className="social">
                        <Grid item xs={12}>
                          <span>{t("Social Media")}</span>
                          <Box className="icons">
                            {this.state.link.twitter && (
                              <img
                                onClick={() => handleLink(this.state.link.twitter)}
                                className="twitter-link"
                                src={TwitterIcon}
                                alt="phone"
                              />
                            )}
                            {this.state.link.instagram && (
                              <img
                                src={InstagramIcon}
                                className="instagram-link"
                                onClick={() => handleLink(this.state.link.instagram)}
                                alt="phone"
                              />
                            )}
                            {this.state.link.facebook && (
                              <img
                                onClick={() => handleLink(this.state.link.facebook)}
                                src={FacebookIcon}
                                alt="phone"
                                className="facebook-link"
                              />
                            )}
                            {this.state.link.snapchat && (
                              <img
                                className="snap-link"
                                src={SnapchatIcon}
                                alt="phone"
                                onClick={() => handleLink(this.state.link.snapchat)}
                              />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(ProfileStyleWeb)(withRouter(CEInviteUserProfile)));
// Customizable Area End
