import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
const navigation = require('react-navigation');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string;
    navigation: typeof navigation;
    t: (value: string) => string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean;
    tabValue: number;
    showError: boolean;
    errorMessage: string;
    openPopOver: HTMLElement | null;
    company: string;
    companyId: string;
    companyList: Array<CompanyListItem>;
    country: string;
    countryList: Array<string>;
    city: string;
    cityList: Array<string>;
    complex: string;
    complexId: string;
    complexList: Array<ComplexListItemResponse>;
    building: string;
    buildingId: string;
    buildingList: Array<BuildingListItem>;
    complexData: Array<ComplexDataItem>;
    searchText: string;
    individualBuildingList: Array<IndividualBuildingListItem>;
    userRole: string | null;
    openPopoverIndex: number | null;
    anchorEl: null,
    userDetails: UserDetailsResponse;
    userId: string;
    isShareModalOpen: boolean;
    shareUrl: null | string;
    // Customizable Area End
}
// Customizable Area Start
export type TFunction = (value: string) => string;

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface CountryResponse {
    data: {
        countries: Array<string>;
    }
}

export interface CityListResponse {
    data: {
        cities: Array<string>;
    }
}

export interface CompanyResponse {
    data: Array<CompanyListItem>;
}

export interface CompanyListItem {
    id: string;
    attributes: {
        name: string;
    }
}

export interface ApiFailureErrorResponse {
    errors: Array<ApiFailureErrorResponse>;
};

export interface ApiFailureErrorResponse {
    contract: string;
};

export interface ComplexListSuccessResponse {
    data: Array<ComplexListItemResponse>;
}

export interface ComplexListItemResponse {
    id: string;
    attributes: {
        name: string;
    }
}

export interface BuildingListResponse {
    data: {
        buildings: Array<BuildingListItem>;
    }
}

export interface BuildingListItem {
    id: number;
    name: string;
}


export interface ComplexTabDataResponse {
    data: Array<ComplexDataItem>
}

export interface ComplexDataItem {
    id: string;
    attributes: {
        id: number;
        company: string | null;
        complex_id: number | null;
        building_id: number | null;
        building_name: string | null;
        unit_number: string | null;
        status: string | null;
    }
};

export interface IndividualBuildingTabDataResponse {
    data: Array<IndividualBuildingListItem>;
}

export interface IndividualBuildingListItem {
    id: string;
    attributes: {
        id: number;
        company: string | null;
        complex_id: number | null;
        building_id: number | null;
        building_name: string | null;
        unit_number: string | null;
        status: string | null;
    }
};

export interface ComplexListSuccessResponse {
    data: Array<ComplexListItemResponse>;
}

export interface ComplexListItemResponse {
    id: string;
    type: string;
    attributes: {
        name: string;
    }
}

export interface UserDetailsResponse {
    data: {
        id: string;
        attributes: {
            id: number;
            full_name: {
                name: string | null;
            },
            bio: {
                bio: null,
            },
            apartment_number: {
                apartment_number: string | null;
            },
            full_phone_number: {
                country_code: string | null;
                phone_number: string | null;
                full_phone_number: string | null;
            },
            email: {
                email: string | null;
            },
            gender: {
                gender: string | null;
            },
            date_of_birth: {
                date_of_birth: string | null;
            },
            hobbies: {
                hobbies: Array<string> | null;
            },
            website: [
                {
                    twitter_link: string | null;
                },
                {
                    instagram_link: string | null;
                },
                {
                    fb_link: string | null;
                },
                {
                    snapchat_link: string | null;
                }
            ],
            profile_pic: string | null;
        }
    }
}

// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    companyListApiCallId: string = "";
    complexListApiCallId: string = "";
    buildingListApiCallId: string = "";
    complexTabDataApiCallId: string = "";
    individualBuildingTabDataApiCallId: string = "";
    userDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loader: false,
            tabValue: 0,
            showError: false,
            errorMessage: "",
            openPopOver: null,
            company: "",
            companyId: "",
            companyList: [],
            country: "",
            countryList: [],
            city: "",
            cityList: [],
            complex: "",
            complexId: "",
            complexList: [],
            building: "",
            buildingId: "",
            buildingList: [],
            complexData: [],
            searchText: "",
            individualBuildingList: [],
            userRole: "",
            openPopoverIndex: null,
            anchorEl: null,
            userDetails: {
                data: {
                    id: "",
                    attributes: {
                        id: 5,
                        full_name: {
                            name: "",
                        },
                        bio: {
                            bio: null,
                        },
                        apartment_number: {
                            apartment_number: "",
                        },
                        full_phone_number: {
                            country_code: "",
                            phone_number: "",
                            full_phone_number: "",
                        },
                        email: {
                            email: "",
                        },
                        gender: {
                            gender: null,
                        },
                        date_of_birth: {
                            date_of_birth: null,
                        },
                        hobbies: {
                            hobbies: null,
                        },
                        website: [
                            {
                                twitter_link: null,
                            },
                            {
                                instagram_link: null,
                            },
                            {
                                fb_link: null,
                            },
                            {
                                snapchat_link: null,
                            }
                        ],
                        profile_pic: null,
                    }
                }
            },
            userId: "",
            isShareModalOpen: false,
            shareUrl: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson.errors) || responseJson.contracts) {
                this.dashboardProfileApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.dashboardProfileApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserId();
        this.getUserType();
        this.getCountryList();
        this.getCompanyList();
        this.getComplexTabData();
        this.getUserDetails();
    };

    getUserId = async () => {
        let userId = await getStorageData('userId');
        if (userId) {
            this.setState({ userId: userId });
        }
    };

    dashboardProfileApiCall = async (apiPayloadType: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = apiPayloadType;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let dashboardProfilerequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        dashboardProfilerequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        dashboardProfilerequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        dashboardProfilerequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        dashboardProfilerequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        dashboardProfilerequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(dashboardProfilerequestMessage.id, dashboardProfilerequestMessage);
        return dashboardProfilerequestMessage.messageId;
    };

    dashboardProfileApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CountryResponse & CityListResponse & CompanyResponse & ComplexListSuccessResponse & BuildingListResponse & ComplexTabDataResponse & IndividualBuildingTabDataResponse & UserDetailsResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexTabDataApiCallId) {
            this.complexTabDataSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingTabDataApiCallId) {
            this.individualBuildingTabDataSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.userDetailsApiCallId) {
            this.userDetailsSuccessCallBack(responseJson);
        }
    };

    dashboardProfileApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureErrorResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexTabDataApiCallId) {
            this.complexTabDataFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingTabDataApiCallId) {
            this.individualBuildingTabDataFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.userDetailsApiCallId) {
            this.userDetailsFailureCallBack(responseJson);
        }
    };

    getComplexTabData = async () => {
        this.complexTabDataApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.dashboardProfileComplexEndpoint}${this.state.userId}`,
        });
    };

    complexTabDataSucessCallBack = (response: ComplexTabDataResponse) => {        
        this.setState({ complexData: response.data });
    };

    complexTabDataFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getIndividualBuildingTabData = async () => {
        this.individualBuildingTabDataApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.dashboardProfileIndividualBuildingEndpoint}${this.state.userId}`,
        });
    };

    individualBuildingTabDataSucessCallBack = (response: IndividualBuildingTabDataResponse) => {
        this.setState({ individualBuildingList: response.data });
    };

    individualBuildingTabDataFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCountryList = async () => {
        this.countryListApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint,
        });
    };

    countryListSucessCallBack = async (response: CountryResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureCallBack = async (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityListApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndPoint}${this.state.country}`
        });
    };

    cityListSucessCallBack = (response: CityListResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCompanyList = async () => {
        this.companyListApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.companyListEndpoint,
        });
    };

    companyListSucessCallBack = (response: CompanyResponse) => {
        this.setState({ companyList: response.data });
    };

    companyListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    }

    getComplexListing = async () => {
        this.complexListApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.complexListEndpoint}city=${this.state.city}&is_building=true`,
        });
    };

    complexListSucessCallBack = (response: ComplexListSuccessResponse) => {
        this.setState({ complexList: response.data });
    };

    complexListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getBuildingListing = async () => {
        this.buildingListApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.buidlingListEndPoint}${this.state.complexId}`,
        });
    };

    buildingListSuccessCallBack = (response: BuildingListResponse) => {
        this.setState({ buildingList: response.data.buildings })
    };

    buildingListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getUserDetails = async () => {
        this.userDetailsApiCallId = await this.dashboardProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.userProfileEndpoint}${this.state.userId}`,
        });
    };

    userDetailsSuccessCallBack = (response: UserDetailsResponse) => {
        this.setState({ userDetails: response });
    };

    userDetailsFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole });
    };

    handleAlertError = () => {
        this.setState({ showError: false });
    };

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue }, () => {
            if (this.state.tabValue === 0) {
                this.getComplexTabData();
            }
            if (this.state.tabValue === 1) {
                                this.getIndividualBuildingTabData();
            }
        });
    };

    handleCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let company = this.state.companyList.find((company) => { return company.attributes.name === event.target.value });
        if (company) {
            this.setState({ company: event.target.value as string, companyId: company.id });
        }
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string }, () => {
            this.getCityList()
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string }, () => {
            this.getComplexListing();
        });
    };

    handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let complex = this.state.complexList.find((complex) => { return complex.attributes.name === event.target.value });
        if (complex) {
            this.setState({ complex: event.target.value as string, complexId: complex.id }, () => {
                this.getBuildingListing()
            });
        }
    };

    handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let building = this.state.buildingList.find((building) => { return building.name === event.target.value });
        if (building) {
            this.setState({ building: event.target.value as string, buildingId: String(building.id) });
        }
    };

    handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value });
    };

    handleClosePopover = () => {
        this.setState({ openPopOver: null, openPopoverIndex: null });
    };

    handleOpenPopover = (event: React.MouseEvent<HTMLElement>, index: number) => {
        this.setState({ openPopOver: event.currentTarget, openPopoverIndex: index });
    };

    handleNullValues = (value: null | string) => { 
        if(value === null){
            return "";
        }else{
            return value;
        }
    };

    handleOpenShareModal = (shareUrl: string | null) => {
        this.setState({ isShareModalOpen: true, shareUrl: shareUrl })
    };
    handleCloseShareModal = () => {
        this.setState({ isShareModalOpen: false })
    };
    // Customizable Area End
}
