// import React from "react";
import React from 'react';
//components
import { Box, Button, Card, CardContent, Dialog, DialogTitle, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

//resources
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedController, { Props } from "./ClassifiedController.web";
//Customizable Area Start
//resorces
import { Building1, DeleteIcon, Filter_Icon, NoClassifiedIcon, Setting_Icon, Close_Icon } from "../src/assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class ClassifiedListing extends ClassifiedController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    this.getClassifiedListing(this.state.status)
  }
  render() {
    // @ts-ignore
    const { t,i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper" dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container" style={{ backgroundColor: "#F7F9FE" }}>
            <Grid item xs={12} md={7} className="auth-cols" style={{ height: "92vh" }}>
              <Box className="content-header">
                <Box className="left-block blocks">
                  <ArrowBackIcon onClick={this.redirectToDashboard} style={{ fontSize: "20px" }} />
                  <HeadingComponent />
                </Box>
                {
                  this.state.myOrAllClassified ?
                    <Box className="incident-right-block blocks">
                      <Button className="handleClick_1_filter_icon" aria-controls="fade-menu" aria-haspopup="true" onClick={(e: any) => this.handleClick_1(e)}>
                        <img src={Filter_Icon} className="filter-icon icons" alt="" />
                      </Button>
                      <Menu
                        id="fade-menu"
                        anchorEl={this.state.anchorEl_1}
                        keepMounted
                        open={Boolean(this.state.anchorEl_1)}
                        className="classifiedListingMenu onCloseCheckMyClassified"
                        data-test-id="classifiedListingMenu"
                        onClose={() => this.handleClose_1("", "")}
                        style={{ top: "28px" }}
                      >
                        <MenuItem className="MyClassifiedseller" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_1(e, "seller")}>{t("Sell")}</MenuItem>
                        <MenuItem className="MyClassifiedbuyer" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_1(e, "buyer")}>{t("Buy")}</MenuItem>
                        <MenuItem className="MyClassifiedgeneric" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_1(e, "generic")}>{t("Generic")}</MenuItem>
                        <MenuItem className="MyClassifiedall" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_1(e, "All")}>{t("All")}</MenuItem>
                      </Menu>
                    </Box>
                    :
                    <Box className="incident-right-block blocks">
                      <Button className="handleClick_2_filter" aria-controls="fade-menu" aria-haspopup="true" onClick={(e: any) => this.handleClick_2(e)}>
                        <img src={Filter_Icon} className="filter-icon icons" alt="" />
                      </Button>
                      <Menu
                        id="fade-menu"
                        anchorEl={this.state.anchorEl_2}
                        keepMounted
                        open={Boolean(this.state.anchorEl_2)}
                        data-test-id="classifiedListingMenu1"
                        className="classifiedListingMenu classifiedListingMenuPublic"
                        onClose={() => this.handleClose_1("", "")}
                        style={{ top: "28px" }}
                      >
                        <MenuItem className="publicClassifiedseller1" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_2(e, "seller")}>{t("Sell")}</MenuItem>
                        <MenuItem className="publicClassifiedbuyer1" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_2(e, "buyer")}>{t("Buy")}</MenuItem>
                        <MenuItem className="publicClassifiedgeneric1" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_2(e, "generic")}>{t("Generic")}</MenuItem>
                        <MenuItem className="publicClassifiedall1" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={(e) => this.handleClose_2(e, "All")}>{t("All")}</MenuItem>
                      </Menu>
                    </Box>
                }
              </Box>
              <Box className="common_content_block content-block">
                <Box className="content-block-wrapper " style={{ backgroundColor: "#f8f9fe", display: "flex", justifyContent: "center", paddingLeft: "10px", paddingRight: "0px",overflowY: "auto",maxHeight: "70vh" }}>
                  <Box className="incident-content-wrapper" style={{ width: "95%" }}>
                    <div className="classified-header">
                      <Box className={this.state.myOrAllClassified ? "customButton" : "customButton btn-gray"} style={{ paddingBottom: "0px" }}>
                        <Button className="getClassifiedListingData" variant="contained" onClick={() => this.getClassifiedListing(this.state.status)}>{t("All Classifieds")}</Button>
                      </Box>
                      <Box className={this.state.myOrAllClassified ? "customButton btn-gray" : "customButton"} style={{ paddingBottom: "0px" }}>
                        <Button className="getClassifiedListingData1" variant="contained" onClick={() => this.getMyClassifiedList()}>{t("My Classifieds")}</Button>
                      </Box>
                    </div>
                    {
                      this.state.classifiedListing.length === 0 ?
                          <Box className='no-classification-wrapper' style={{ minHeight: "70vh", marginRight: "10px" }}>
                            <Box className='no-classification'>
                              <img src={NoClassifiedIcon} className="lock-logo" alt="Lock_Icon" />
                              <h1 className="bold-text" style={{ fontSize: "26px" }}>{t("No classifieds were")} <br></br>{t("found")}</h1>
                              <p style={{ fontSize: "14px" }}>{t("Looks like you have’t added any classifieds! You can create a new request by tapping the below button.")}</p>
                            </Box>
                          </Box>
                        :
                        this.state.classifiedListing?.map((val: any, index: any) => (
                          <>
                            {
                              !this.state.myOrAllClassified &&
                              <Box className="classifiedCardRow">
                                <Button className="menu-btn Setting_IconMore" aria-controls="simple-menu" style={languageCondition(language,{left:"0px",width:"180%"},{right:"0px"})} onClick={(e: any) => { this.handleClick(e, val.attributes.id, val.attributes.classified_type) }}>
                                  <img src={Setting_Icon} className="grid-icon icons" alt="" />
                                </Button>
                                <Menu
                                  data-test-id="classifiedListingMenus"
                                  id="simple-menu"
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  className="classifiedListingMenu classifiedListingEditMenu"
                                  onClose={() => this.handleClose("", "")}
                                  style={{ top: "42px" }}
                                >
                                  <MenuItem className="editData" onClick={(e) => this.handleClose(e, "edit")}>{t("Edit")}</MenuItem>
                                  <MenuItem className="deleteData" onClick={(e) => this.handleClose(e, "delete")}>{t("Delete")}</MenuItem>
                                </Menu>
                              </Box>
                            }
                            <Card className="classified-card card" key={val.attributes?.id} >
                              <CardContent
                                data-test-id="getClassifiedDetails"
                                className="costom-card-content"
                                onClick={(e: any) => {
                                  this.getClassifiedDetails(e, val?.id)
                                }}
                              >
                                <Box className="classified-card-header">
                                  <Typography component="h4" style={{ fontSize: "16px" }} className="bold-text">
                                    {val.attributes?.title}
                                  </Typography>
                                </Box>
                                <Typography className="sub-title h5-title" component="h5">
                                  {val.attributes?.description}
                                </Typography>

                                {(() => {
                                  if (val.attributes?.classified_type === "seller") {
                                    return <Typography component="span">{t("Available to sell:")}</Typography>
                                  } else if (val.attributes?.classified_type === "buyer") {
                                    return <Typography component="span">{t("Available to buy:")}</Typography>
                                  } else {
                                    return <Typography component="span">{t("Available:")}</Typography>
                                  }
                                })()}

                                <Typography className="sub-title h5-title" component="h5">
                                  {val.attributes.duration_from} {t("to")} {val.attributes.duration_to}
                                </Typography>
                                <hr />
                                <Box className="card-footer classified-footer">
                                  <ClassifedType val={val} language={language} t={t}/>
                                  <StatusButton val={val} language={language} t={t}/>
                                </Box>
                              </CardContent>

                            </Card>
                            {/* </Box> */}
                          </>
                        ))
                    }
                  </Box>
                </Box>

                {
                  !this.state.myOrAllClassified &&
                    <Box className="footer-block desktop-ui">
                      <Box className="customButton add-incident CreateClassifedFormButton" style={{position:"absolute",bottom:"0px"}}>
                        <Button data-test-id="footerblock" variant="contained" className="CreateClassifedBottomButton" style={{width:"300px"}} onClick={() => {
                          this.setState({ loading: true });//@ts-ignore
                          this.props.history.push("/ClassifiedType")
                        }} >{this.state.classifiedListing.length === 0 ? t('Add Classified Request')
                          :
                          t('ADD Classified')}</Button>
                      </Box>
                    </Box>
                }
              </Box>
            </Grid>
            {/* desktop footer block */}
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
            <Dialog
              data-test-id="deleteShowDialog"
              open={this.state.deleteShowDialog}
              onClose={() => this.setState({ deleteShowDialog: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="diloag-wrapper"
              PaperProps={{
                style: {
                  borderRadius: '15px',
                  width: "500px",
                  margin: "0"
                },
              }}
            >
              <Box className="diloag-body classified-dialouge-body desktop-ui ">
                <Box className="diloag-header classified-header">
                  <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                    {""}
                  </DialogTitle>
                  <Button className="deleteShowDailog" onClick={() => this.setState({ deleteShowDialog: false })} >
                    <img src={Close_Icon} className="close-icon" />
                  </Button>
                </Box>
                <Box className="diloag-content classified-content diloag-management-content">
                  <img src={DeleteIcon} className="lock-logo" alt="Lock_Icon" />
                  <h3 className="bold-text">{t("Delete classified")} <br /> {t("request?")}</h3>
                  <p className="lead" style={{ width: "88%" }}>{t("Are you sure want to delete published classified")} {t(this.state.classifiedType)} {t("request? Once deleted no one will be able to view your request.")}</p>
                  <Box className="diloag-btn customButton deleteModalBtns" style={{ paddingBottom: "0px", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                    <Button className="deleteClassifieddata" variant="contained" style={{ height: "56px", width: "220px" }} onClick={() => this.deleteClassified()}>{t("Yes")}</Button>
                    <Button className="deleteShowDialog1" variant="outlined" style={{ width: "220px" }} onClick={() => { this.setState({ deleteShowDialog: false }) }}>{t("No, don’t delete")}</Button>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

const ClassifedType = (props: any) => {
  const { val,language,t } = props
  return (
    <>
      {
        val?.attributes?.classified_type === "buyer" &&
        <div className="left-block">
          {/* <img src={Dollar_Icon} className="dollar-icon" alt="Dollar Icon" /> */}
          <Typography component="h4" className="classifiedBuyerTypography" style={{ fontSize: "14px", color: "#FC8434" }}>
            {val?.attributes?.price_from} {languageCondition(language,val?.attributes?.currency?.currency_ar,val?.attributes?.currency?.currency)} - {val?.attributes?.price_to} {languageCondition(language,val?.attributes?.currency?.currency_ar,val?.attributes?.currency?.currency)}
          </Typography>
        </div>
      }
      {
        val?.attributes?.classified_type === "generic" &&
        <div className="left-block">
          {/* <img src={Dollar_Icon} className="dollar-icon" alt="Dollar Icon" /> */}
          <Typography component="h4" className="classifiedGenericTypography" style={{ fontSize: "14px", color: "#FC8434" }} >
            {val?.attributes?.payment_detail} {languageCondition(language,val?.attributes?.currency?.currency_ar,val?.attributes?.currency?.currency)}
          </Typography>
        </div>
      }
      {
        val?.attributes?.classified_type === "seller" &&
        <div className="left-block">
          {/* <img src={Dollar_Icon} className="dollar-icon" alt="Dollar Icon" /> */}
          <Typography component="h4" className="classifiedSellerTypography" style={{ fontSize: "14px", color: "#FC8434" }}>
            {val?.attributes?.price} {languageCondition(language,val?.attributes?.currency?.currency_ar,val?.attributes?.currency?.currency)}
          </Typography>
        </div>
      }
      <ClassifiedCheck val={val} language={language} t={t} />
    </>
  )
}
const StatusButton = (props: any) => {
  const val = props?.val;
  const t = props.t;
  return (
    <>
      <Box className="customButton">
        <Button variant="contained" className={val?.attributes?.classified_status === 'Pending Approval' ? "contain warning" : val?.attributes?.classified_status === 'Published' ? 'contain success' : 'contain danger'} type="submit">
          {t(val?.attributes?.classified_status)}</Button>
      </Box>
    </>
  )
}

const ClassifiedCheck = (props: any) => {
  const { val,t } = props
  let type = ""
  if (val.attributes?.classified_type === "buyer") {
    type = "Buy"
  } else {
    if (val.attributes?.classified_type === "generic") {
      type = "Generic"
    } else {
      type = "Sell"
    }
  }
  return (
    <>
      {
        type === "Buy" &&
        <Box className="customButton">
          <Button variant="contained" className="contain success" type="submit" >{t(type)}</Button>
        </Box>
      }
      {
        type === "Generic" &&
        <Box className="customButton">
          <Button variant="contained" className="contain blue" type="submit" >{t(type)}</Button>
        </Box>
      }
      {
        type === "Sell" &&
        <Box className="customButton">
          <Button variant="contained" className="contain danger" type="submit" >{t(type)}</Button>
        </Box>
      }
    </>
  )
}

const HeadingComponent = () => {
  return (
    <>
      <h4 className='bold-text' style={{ display: "flex", fontSize: "18px" }}>Classifieds</h4>
    </>
  )
}

export default  withTranslation()(withRouter(ClassifiedListing))
//Customizable Area End