// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import CETotalAcceptRejectInviteController, { Props } from "./CETotalAcceptRejectInviteController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { formatDate, navigationFunc } from "../../../../components/src/helperFunctions.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";

class CETotalAcceptRejectInvite extends CETotalAcceptRejectInviteController {
  constructor(props: Props) {
    super(props);
  }

  invitationDetail = (t: (val: string) => string, label: string, value: string, xs: any) => {
    return (
      <Grid item xs={xs}>
        <Typography variant="body2" className="detail-label">
          {t(label)}
        </Typography>
        <Typography variant="body2" className="detail-value" style={{ color: "grey" }}>
          {value}
        </Typography>
      </Grid>
    );
  };

  render() {
    const { t, classes, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation total-accept-reject-head-box">
              <Typography variant="body1" className="total-invite-heading">
                <span
                  data-test-id="com-mana-navigation"
                  onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}
                >
                  {t("Community Management")}
                </span>{" "}
                /{" "}
                <span
                  data-test-id="req-mana-navigation"
                  onClick={() => navigationFunc(this.props, "CERequestManagement")}
                >
                  {t("Requests Management")}
                </span>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }} className="total-invite-head">
                  {t(this.handleHeader())}
                </Box>
              </Typography>
              <Box className="sub-heading-box total-invite-sub-head">
                <Typography variant="h5" className="bold-text total-invite-text">
                  {t(this.handleHeader())}
                </Typography>
              </Box>
            </Box>
            <Box className="filter-head-box total-invite-filter-box">
              {this.state.complexType === "Complex" && (
                <FilterSelect
                  t={t}
                  language={language}
                  value={this.state.filter.building}
                  label={t("Select Building")}
                  option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                  onChange={(event: any) => this.handleChangeValue(event, "Building")}
                />
              )}
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.filter.unit}
                option={[{ label: "All", value: "all" }, ...this.state.unitList]}
                onChange={(event: any) => this.handleChangeValue(event, "Unit")}
              />
              <Button className="action-search-btn" startIcon={<SearchIcon />} onClick={this.getTableList}>
                {t("Search")}
              </Button>
            </Box>

            <Grid className="table-box-card">
              <Grid item sm={12} md={12} xs={12}>
                <Box className="table-top">
                  <h4 className="bold-text">{t("Complex")}</h4>
                  <SearchInput
                    onChange={this.handleDebounceSearch}
                    t={t}
                    language={language}
                    label={t("Search by Name")}
                    data-test-id="search-query"
                  />
                </Box>
                <Divider />
                <Table className="table-box">
                  <TableHead className="total-table-head">
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text total-name">
                        {t("Name")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Unit Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text total-sent">
                        {t("Invitation sent on")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Phone Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="total-menu" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.invitationList.map((item: any, i: number) => (
                      <TableRow>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {(this.state.page - 1) * 10 + (i + 1)}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{item.name}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                          {item.unit}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {formatDate(item.sentOn, "DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{item.phone}</TableCell>
                        <TableCell
                          align={languageCondition(language, "right", "left")}
                          className={LanguageAttributeHandler(language, "meeting-menu", "")}
                        >
                          <Menu
                            menuButton={
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            }
                          >
                            <MenuItem onClick={() => this.viewDetail(item)}>{t("View Details")}</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider />
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{this.state.invitationList.length}</span> {t("of")}{" "}
                    <span className="total-page">{this.state.pagination?.total_count}</span> {t("results")}
                  </p>
                  {this.state.pagination && (
                    <Pagination
                      siblingCount={2}
                      variant="outlined"
                      shape="rounded"
                      count={this.state.pagination.total_pages}
                      page={this.state.pagination.current_page}
                      onChange={this.handleInvitePagination}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />

        <Dialog
          open={this.state.isViewInvitationOpen}
          scroll="paper"
          dir={languageCondition(language, "rtl", "ltr")}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title="View Details" onClose={this.handleViewInvitationModal} />
          <Box style={webStyle.subContent} className="select-meeting">
            <Grid container spacing={2}>
              {this.invitationDetail(t, "Name", this.state.invitation.name, 4)}
              {this.invitationDetail(t, "Country", this.state.invitation.country, 4)}
              {this.invitationDetail(t, "City", this.state.invitation.city, 4)}
              {this.invitationDetail(t, "Complex", this.state.invitation.complex, 4)}
              {this.invitationDetail(t, "Unit Number", this.state.invitation.unit, 4)}
              {this.invitationDetail(t, "Sent On", formatDate(this.state.invitation.sentOn, "DD/MM/YYYY"), 4)}
              {this.invitationDetail(t, "Phone Number", this.state.invitation.phone, 4)}
              {this.invitationDetail(t, "Role", this.state.invitation.role, 4)}
              {this.invitationDetail(t, "Status", this.state.invitation.status, 4)}
            </Grid>
          </Box>
        </Dialog>
      </>
    );
  }
}

const webStyle: any = {
  subContent: { padding: "20px 30px" },
};

export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CETotalAcceptRejectInvite)));
// Customizable Area End
