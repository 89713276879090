import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
interface UserFilters {
    city: string;
    complex_id: string;
    [key: string]: string | number | boolean | null;
  }
export interface Props {
    t:(value:string)=> string
}
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");

export interface Props {
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    selectedCity:string;
    selectedCountry:string;
    individualbuilding:string;
    selectDate:string;
    open:boolean;
    subTable:boolean;
    openTabel: { open: boolean; index: number; }[];
    downloadPage:boolean;
    downloadTable:boolean;
    subscriptionId: number | null;
    ownerStatistical:[];
    token:string;
    countPage:number | undefined;
    totalPages:number;
    totalCount: number;
    selectComplex: { id: string; name: string }[];
    selectCountry: string[];
    selectCity: string[];
    subData: string;
    ownerStatisticalReportSub: [];
    toDate: string;
    date:string
    subscriberId: string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

export default class OwnerStatisticalReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    ownerStatisticalReportApiId: string = ""
    getComplexApiId: string = ""
    getCountryApiId: string = ""
    getCityApiId: string = ""
    ownerStatisticalReportSubApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            selectedCity:"",
            selectedCountry:"",
            individualbuilding:"",
            selectDate:"",
            open:false,
            openTabel: [],
            subTable:false,
            downloadPage:false,
            downloadTable:false,
            subscriptionId:null,
            ownerStatistical:[],
            token:'',
            countPage:1,
            totalPages:1,
            totalCount:0,
            selectComplex: [],
            selectCountry: [],
            selectCity: [],
            subData:'',
            ownerStatisticalReportSub: [],
            toDate:'',
            date:'',
            subscriberId: ''
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.ownerStatisticalReportApiId) {
                this.setState({ ownerStatistical: responseJson.data,totalPages:responseJson?.meta?.pagination?.total_pages,totalCount:responseJson?.meta?.pagination?.total_count})
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.ownerStatisticalReportSubApiId) {
                this.setState({ownerStatisticalReportSub:responseJson.data})
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let subscription = await getStorageData("idees");
        this.setState({ subscriptionId: subscription })
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.ownerStatisticalReportApi(1)
        this.ComplexApi()
        this.handleCountryApi()
        this.handleCityApi()
    }

    tableAppend = (index: number) => {
        let tableData: { open: boolean; index: number; }[] = this.state.openTabel;
        let item = tableData.find(item => item.index === index);
        if (item) {
            item.open = !item.open;
        } else {
            tableData.push({ open: true, index: index });
        }
        this.setState({ openTabel: tableData });
    }

    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCity: value as string })
    };

    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleCityApi(value as string)
        this.setState({ selectedCountry: value as string })
    };

    handleComplexIndividualChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleSubscriotionReport = (index:string) => {
        this.setState({ subTable: true,subscriberId:index})
        this.ownerSubscriptionReportApi(index)
    }

    handleDownload = () => {
        if (this.state.subTable) {
            this.setState({ downloadPage: true })
        } else if (!this.state.subTable) {
            this.setState({ downloadTable: true })
        }
    }

    getStatusClass = (status: string) => {
        switch (status) {
            case 'active':
                return 'status-active';
            case 'suspended':
                return 'status-suspend';
            case 'deactive':
                return 'status-deactive';
            default:
                return '';
        }
    };

    handleSerach = () => {
        this.ownerStatisticalReportApi(1)
    }

    ownerStatisticalReportApi = (page:number) => {
        const { selectedCountry,  selectedCity, individualbuilding, subscriptionId} = this.state
        let userFilters: UserFilters = {
            country: selectedCountry,
            city: selectedCity,
            complex_id: individualbuilding, 
            subscriber_id: subscriptionId,
            page: page,
            per_page: "10"
        }

        let initialEndPoint = `${configJSON.ownerReportApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter] && userFilters[filter] !== 'All') {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ownerStatisticalReportApiId = requestMessages.messageId;
        
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handlePageChange = (_event:React.ChangeEvent<unknown>,page:number) => {
        this.ownerStatisticalReportApi(page)
    }

    ComplexApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getComplexApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.complexApiEndpoin
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCityApi = (value?:string) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const params = `?country=${value}` 
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint + `${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleSerachData = () => {
        this.ownerSubscriptionReportApi(this.state.subscriberId)
    }

    ownerSubscriptionReportApi = (index: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ownerStatisticalReportSubApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ownerSubTabelApiIdEndpoint}?subscriber_id=${index}&start_date=${this.formatDate(this.state.date)}`
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    goToManageSubscriptions = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionListing");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    handleDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/owner_reports/download_owner_statical_report?listing=false&subscriber_id=${this.state.subscriptionId}`, `OwnerUsageStatisticalReport.pdf`
        );
    }

    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    };

    handleSubTableDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/subscription_reports/download_owner_report?subscriber_id=${this.state.subscriberId}`, `OwnerSubscriptionReport.pdf`
        );
    }

    formatDate = (date:string) => {
        const latestDate = new Date(date);
        const day = String(latestDate.getDate()).padStart(2, '0');
        const month = String(latestDate.getMonth() + 1).padStart(2, '0');
        const year = String(latestDate.getFullYear()).slice(-2);
        
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    handleToDate = (event:ChangeEvent<{ value: unknown | string}>) => {        
        this.setState({
            ...this.state,
            date: event.target.value as string,
        });
      }
    // Customizable Area End
}
