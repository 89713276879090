import React from "react";
// Customizable Area Start
import { Box, Breadcrumbs, Button, Chip, Divider, FormControl, IconButton, MenuItem, Paper, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BuildingListItem, RegisterOwnerResponseItem, TFunction } from './RegisteredResidentsController.web';
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { ComplexListItem } from './RegisteredResidentsController.web';
import AlertError from "../../../components/src/AlertError.web";
// Customizable Area End

import RegisteredResidentsController, { Props, } from "./RegisteredResidentsController.web";

class RegisteredResidents extends RegisteredResidentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderRegisteredResidents = () => {
        const { popoverAncher } = this.state;
        const open = Boolean(popoverAncher);
        const { t }: { t: TFunction } = this.props;
        const currentPage=this.state.paginationData?.current_page as number

        return <Box className='registeredOwnerTopSectionContainer'>
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography className="residentsTopheading" data-testId={"myDashboardTestId"} onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>
                        {t("My Dashboard")}
                    </Typography>
                    <Typography className="residentsTopheading" data-testId={"generalDashboardTestId"} onClick={()=>this.handleNavigation("DashboardCompanyEmployee")}>
                        {t("General Dashboard")}
                    </Typography>
                    <Typography className="textActive">{t("Registered Residents")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Typography variant='h4' className='registerHeading'>{t("Registered Residents")}</Typography>
            </Box>
            <Box className='searchFilterContainer'>
                <FormControl variant="filled">
                    <Select
                        value={this.state.country}
                        onChange={this.handleCountryChange}
                        displayEmpty
                        data-testId="selectCountryId"
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Select Country'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: string, countryIndex: number) => {
                            return <MenuItem value={country} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        data-testId="selectCityId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Select City'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select City")}</MenuItem>
                        {this.state.cityList.map((city: string, cityIndex: number) => {
                            return <MenuItem value={city} key={cityIndex}>{city}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId="selectComplexId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        <MenuItem value="Complex">{t("Complex")}</MenuItem>
                        <MenuItem value="Individual Building">{t("Individual Building")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.selectComplex}
                        onChange={this.handleSelectComplex}
                        data-testId="selectComplexTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined' placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                            return <MenuItem value={complex.attributes.name} key={complexIndex}>{complex.attributes.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId="buildingTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Owner Status'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Select Building")}</MenuItem>
                        {this.state.buildingList.map((building: BuildingListItem, buildingIndex: number) => {
                            return <MenuItem value={building.name} key={buildingIndex}>{building.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <Select
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        data-testId="statusTestId"
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">{t("Residents Status")}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant='contained' data-testId="cooSearchBtnTestId" className={"searchBtn"} startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredResidents("")}>{t("Search")}</Button>
            </Box>
            <Box component={Paper} className='registeredOwnerTableContainer'>
                <Box className='registeredOwnerTableTopContainer'>
                    <Typography variant='h5' className='tableHeading'>{t("List of registered residents")}</Typography>
                    <Box className='searchContainer'>
                        <FormControl variant="filled">
                            <Select
                                value={this.state.sortBy}
                                onChange={this.handleSortByChange}
                                data-testId="sortByTestId"
                                displayEmpty
                                className={"payingClientsSelect"}
                                variant='outlined'
                                placeholder='Sort By'
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                            >
                                <MenuItem value="">{t("Sort By")}</MenuItem>
                                <MenuItem value="asc">{t("Ascending")}</MenuItem>
                                <MenuItem value="desc">{t("Descending")}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            variant='outlined'
                            className='searchTextField'
                            value={this.state.searchName}
                            onChange={this.handleSearchName}
                            data-testId="searchTestId"
                            InputProps={{ startAdornment: <SearchIcon className='searchIcon' /> }}
                            placeholder='Search by Owner Name or ID' />
                    </Box>
                </Box>
                <Divider />
                <Box >
                    <TableContainer className='tableContainer'>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='tableColumnHeading' align="center">#</TableCell>
                                    <TableCell className='tableColumnHeading' align="center">{t("Resident Name")}</TableCell>
                                    <TableCell className='tableColumnHeading' align="center">{t("Resident ID")}</TableCell>
                                    <TableCell className='tableColumnHeading' align="center">{t("Complex Name")}</TableCell>
                                    <TableCell className='tableColumnHeading' align="center">{t("Building Name")}</TableCell>
                                    <TableCell className='tableColumnHeadingOwnerStatus' align="left">{t("Resident Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.residentsData.map((item: RegisterOwnerResponseItem, index: number) => {
                                    return <TableRow key={index}>
                                        <TableCell align="center">{(currentPage - 1) * 10 + index+ 1}</TableCell>
                                        <TableCell align="center" data-testId={`residentsName${index}`}>{item.attributes.resident_name === null ? "-" : item.attributes.resident_name}</TableCell>
                                        <TableCell align="center">{item.attributes.resident_id}</TableCell>
                                        <TableCell align="center">{item.attributes.complex_name.name}</TableCell>
                                        <TableCell align="center">{item.attributes.building_name.name}</TableCell>
                                        <TableCell align="left" >
                                            <Box className='ownerStatusContainer'>
                                                <Box className='ownerStatusChipContainer'>
                                                    {item.attributes.status === "Accept" && <Chip className='ownerStatusBlue' label={item.attributes.status} />}
                                                    {item.attributes.status === "Accepted" && <Chip className='ownerStatusGreen' label={item.attributes.status} />}
                                                    {item.attributes.status === "Pending" && <Chip className='ownerStatusOrange' label={item.attributes.status} />}                                                                                                                                                
                                                </Box>
                                                <Box>
                                                    <IconButton data-testId="menuTestId" onClick={(event) => this.handleTableMenu(event)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Popover
                                                        open={open}
                                                        anchorEl={this.state.popoverAncher}
                                                        onClose={this.handleClosePopover}
                                                        data-testId="closeMenuTestId"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                marginLeft: "20px",
                                                            },
                                                        }}
                                                        elevation={1}
                                                    >
                                                        <MenuItem data-testId="detailsTestId" onClick={()=>this.handleViewDetails(item.id)}>{t("View Details")}</MenuItem>
                                                    </Popover>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <Box className='tablePaginationContainer'>
                            <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"PaginationTestId"} />
                        </Box>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                <RegisteredResidentsContainer>
                    {this.renderRegisteredResidents()}
                </RegisteredResidentsContainer>
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(RegisteredResidents);
export { RegisteredResidents };
const RegisteredResidentsContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#F4F7FF",
    height: "100vh",
    overflow: "scroll",
    "& .residentsTopheading": {
        cursor: "pointer",
    },
    "& .registeredOwnerTopSectionContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    "& .registerHeading": {
        fontWeight: "900"
    },
    "& .textActive": {
        color: "#3f7cef"
    },
    "& .registeredOwnerTableContainer": {
        borderRadius: "10px"
    },
    "& .registeredOwnerTableTopContainer": {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        alignItems: "center"
    },
    "& .searchContainer": {
        display: "flex",
        gap: "20px"
    },
    "& .payingClientsSelect": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .tableHeading": {
        fontWeight: "900"
    },
    "& .searchTextField": {
        width: "270px",
        height: "50px",
        borderRadius: "10px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "50px",
            border: "1px solid #EAEAEA",
        },
        "& .MuiOutlinedInput-inputAdornedStart": {
            paddingLeft: "40px"
        }
    },
    "& .searchIcon": {
        color: "#777a7f"
    },
    "& .tableContainer": {
        overflow: "scroll"
    },
    "& .tableColumnHeading": {
        fontWeight: "700"
    },
    "& .tableColumnHeadingOwnerStatus": {
        width: "250px",
        fontWeight: "700"
    },
    "& .ownerStatusBlue": {
        backgroundColor: "#e6edfc",
        color: "#3f7dee",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusOrange": {
        backgroundColor: "#fef0e6",
        color: "#fc924b",
        fontWeight: "700",
        width: "200px"
    },
    "& .ownerStatusGreen": {
        backgroundColor: "#e4f8eb",
        color: "#40ce74",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusChipContainer": {
        display: "flex",
        flexDirection: "column"
    },
    "& .ownerStatusContainer": {
        display: "flex",
        justifyContent: "space-between",
        width: "300px"
    },
    "& .MuiIconButton-root": {
        padding: "10px 0px",
        width: "40px"
    },
    "& .tablePaginationContainer": {
        padding: "20px",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .colorOrange": {
        color: "#fc8e45"
    },
    "& .colorBold": {
        fontWeight: "900"
    },
    "& .paginationContainer": {
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#fc8e45",
            border: "none",
            color: "#fff"
        },
        "& .MuiPaginationItem-rounded": {
            color: "#ebeced"
        }
    },
    "& .searchFilterContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .searchBtn": {
        backgroundColor: "#2a6fed",
        height: "45px",
        color: "#fff",
        borderRadius: "10px"
    },
    "& .searchbtnIcon": {
        color: "#ffff"
    }
});
// Customizable Area End