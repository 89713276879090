// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import { Box, Container, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import { NotificationStyle } from "./NotificationStyle.web";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CEAdminNotificationController, { Props } from "./CEAdminNotificationController.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import moment from "moment";

class CEAdminNotification extends CEAdminNotificationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.chairmanNotification}>
            <Box className="navigation admin-notification-navigation">
              <Box className="admin-notification-heading-box">
                <Typography variant="body1">
                  {t("Home")} /{" "}
                  <Box component="span" style={{ color: "blue" }}>{t("Notifications")}</Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text admin-notification-heading">
                  {t("Notifications")}
                </Typography>
              </Box>
            </Box>
            <Box className="content-boxes admin-notification-content">
              <Box className="tab-content admin-notification-tabs-box">
                <Box className="tab-content-box admin-notification-tab-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="notification-box admin-notification-empty">{t("No message found")}</Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={`notification-box unRead`}>
                        <Box className="header admin-notification-tag-header">
                          <Box className="tag-box">
                            <span className="building admin-notification-tag">Building</span>
                            <span className="category admin-notification-tag">Module</span>
                          </Box>
                          <Box className="admin-notification-menu">
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem className="admin-read-menu">{t("Mark as read")}</MenuItem>
                              <MenuItem className="admin-unread-menu">{t("Mark as unread")}</MenuItem>
                              <MenuItem className="admin-delete-menu">{t("Delete")}</MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                        <h4 className="bold-text">{languageCondition(language, "Title ar", "Title")}</h4>
                        <p>{languageCondition(language, "Description Ar", "Description")}</p>
                        <p className="time admin-notification-time">{moment("").fromNow()}</p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(NotificationStyle)(CEAdminNotification));
// Customizable Area End
