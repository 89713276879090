import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { CEApiCall, apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
import { t } from "i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as Yup from "yup";
import { handleFilterComplexList } from "../../../components/src/UtilFunction.web";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    history: any;
    location: any;
    match: any;
}

interface FormValue {
    complex:string;
    type:string;
    country:string;
    individualBuilding:string;
    city:string;
    building:string;
    unit:string;
    fullName:string;
    emailID:string;
    countryCode:string;
    phoneNumber:string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    loading: boolean;
    selectedYear: any;
    invitationData: any;
    pagination: any;
    countryList: any[];
    cityList: any[];
    complexList: any[];
    filterComplexList: any[];
    buildingList: any[];
    invitaionReport: any[];
    yearList: any[];
    cityFilter: any;
    countryFilter: any;
    buildingFilter: any;
    complexFilter: any;
    page: any;
    filter: any;
    complexBuildingList: any;
    searchQuery: string

    formValue: FormValue;
    invitationId: string;
    disableField: boolean,
    isResendInviteDialogOpen: boolean;

    userRoleList: any[];
    unitList: any[];

    error: string;
    showError: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InvitationReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInvitationReportId: string = ""
    getCityFilterList: string = ""
    getCountryFilterList: string = ""
    getComplexFilterList: string = ""
    getBuildingFilterList: string = ""
    getInvitationReportList: string = ""
    getYearListCallId: string = ""
    getdownloadInvitationReportList: string= ""
    GetInviteDetailCallId: string = "";
    GetAllUserTypeAPICallId: string = "";
    GetAllUnitListCallId: string = "";
    ResendInvitationCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            loading: false,
            selectedYear: new Date().getFullYear(),
            invitationData: {},
            filter: {
                selectedType: "complex",
                selectedCountry: "",
                selectedCity: "",
                selectedComplex: "",
                selectedBuilding: "",
                selectedYear: ""
            },
            searchQuery: "",
            countryList: [],
            cityList: [],
            complexList: [],
            filterComplexList: [],
            buildingList: [],
            invitaionReport: [],
            yearList: [],
            pagination: "",
            cityFilter: "",
            countryFilter: "",
            buildingFilter: "",
            complexFilter: "",
            page: "1",
            complexBuildingList: [
                {
                    label: t("Complex"),
                    value: 'complex'
                },
                {
                    label: t("Individual Building"),
                    value: 'indiviualBuilding'
                },
            ],

            isResendInviteDialogOpen: false,
            invitationId: "",
            disableField: false,
            formValue: {
                complex: "",
                type:"",
                country:"",
                individualBuilding:"",
                building:"",
                unit:"",
                fullName:"",
                city:"",
                emailID:"",
                phoneNumber:"",
                countryCode:"+1",
            },

            userRoleList: [],
            unitList: [],

            error: "",
            showError: false,
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            switch (apiRequestCallId) {
                case this.getYearListCallId:
                    this.handleYearListResponse(responseJson);
                    break;
                case this.getCountryFilterList:
                    this.handleCountryListResponse(responseJson);
                    break;
                case this.getCityFilterList:
                    this.handleCityListResponse(responseJson);
                    break;
                case this.getComplexFilterList:
                    this.handleComplexList(responseJson);
                    break;
                case this.getBuildingFilterList:
                    this.handleBuildingList(responseJson);
                    break;
                case this.getInvitationReportList:
                    this.handleInvitationReportResponse(responseJson);
                    break;
                case this.getdownloadInvitationReportList:
                    this.handleDownloadInvitationReport(responseJson.data);
                    break;
                case this.GetInviteDetailCallId:
                    this.handleInvitationDetailResponse(responseJson);
                    break;
                case this.GetAllUserTypeAPICallId:
                    this.handleUserListResponse(responseJson);
                    break;
                case this.GetAllUnitListCallId:
                    this.handleUnitListResponse(responseJson);
                    break;
                case this.ResendInvitationCallId:
                    this.handleResendInvitationResponse();
                    break;
                default:
                    break;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getInvitationReport()
        this.getInvitationReportData()
        this.getCountryData()
        this.getYearList();
        this.getAllUserType();
    }

    getAllUserType = async () => {
        this.GetAllUserTypeAPICallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: "bx_block_roles_permissions/roles",
            method: configJSON.validationApiMethodType
        });
    };

    handleUserListResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ userRoleList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.id })) })
        }
    }

    getUnitDataList = async (id:any) => {
        this.GetAllUnitListCallId = await apiCall({
          method: configJSON.validationApiMethodType,
          contentType: configJSON.validationApiContentType,
          endPoint: `bx_block_custom_form/incident_managements/unit_list?building_management_id=${id}`,
        });
    };

    handleUnitListResponse = (responseJson: any) => {
        if (responseJson && responseJson.data.apartments) {
            this.setState({
                unitList: responseJson.data.apartments.map((item: any) => ({ label: item.apartment_name, value: item.id }))
            });
        }
    }

    handleFormSubmit = async (value: any) => {
        let data = new FormData();
        data.append("member_invitation[full_name]", value.fullName);
        data.append("member_invitation[email_address]", value.emailID);
        data.append("member_invitation[phone_number]", value.countryCode + "-" + value.phoneNumber);
        data.append("member_invitation[role_id]", value.type);
        data.append("member_invitation[building_management_id]", value.building);
        data.append("member_invitation[apartment_management_id]", value.unit);
        data.append("member_invitation[society_management_id]", value.complex);

        this.ResendInvitationCallId = await apiCall({
            endPoint: "bx_block_request_management/member_invitations" + `?request_id=${this.state.invitationId}`,
            body: data,
            method: "POST"
        });
    }

    handleResendInvitationResponse = () => {
        this.setState({ loading: false, showError: true, error: "Invitation Resend Successfully" });
    }

    downloadInvitationReport = async () => {
        this.getdownloadInvitationReportList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getInvitationReportsEndPoint + `?listing=true&search_term=${this.state.searchQuery}&city=${this.state.filter.selectedCity}&country=${this.state.filter.selectedCountry}&search_year=${this.state.filter.selectedYear}&complex_id=${this.state.filter.selectedComplex}&building=${this.state.filter.selectedBuilding}`
        })
    }
    handleDownloadInvitationReport = async (invitaionReportList:any) => {
        const tableColumn: string[] = ["#","Complex Name", "Building", "Name", "Role", "Sent on"];
        const tableRows: string[][] = [];
        
        invitaionReportList && invitaionReportList.forEach((item:any,index: number) => {
            const itemData: string[] = [(index + 1).toString(),item.attributes.complex ? item.attributes.complex : "-",item.attributes.bulidng ? item.attributes.bulidng : "-",item.attributes.name ? item.attributes.name : "-",item.attributes.role ? item.attributes.role : "-",item.attributes.created_at ? new Date(item.attributes.created_at).toLocaleString() : "-"];
            tableRows.push(itemData);
        });
        
        const doc = new (jsPDF as any)(); // Create new instance of jsPDF
        doc.text("Invitation Report", 14, 15);
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
        });

        doc.save(`Invitation_report.pdf`);
    };

    manageChangeYear = (e: any) => {
        this.setState({
            selectedYear: e.target.value
        }, () => this.getInvitationReport())
    }

    convertDate = (dateString: string | undefined) => {
        if (!dateString) return "";
        const p = dateString.split(/\D/g);
        return [p[2], p[1], p[0]].join("-");
    }
    getCountryData = async () => {
        this.getCountryFilterList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getCountryListEndPoint
        })
    }
    getCityData = async (country: string) => {
        this.getCityFilterList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getCityListEndPoint + `?country=${country}`
        })
    }
    getComplexData = async (city?: string) => {
        this.getComplexFilterList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getComplexListEndPoint + `?city=${city}`
        })
    }
    getBuildingData = async (society_management_id: any) => {
        this.getBuildingFilterList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getBuildingListEndPoint + `?society_management_id=${society_management_id}`
        })
    }

    getInvitationReportData = async () => {
        this.getInvitationReportList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getInvitationReportsEndPoint + `?listing=true&page=${this.state.page}&per_page=10`
        })
    }
    getData = (value: any) => (value !== "" && value!=="all") ? `=${value}` : "";
    getInvitationReportFilterData = async () => {
        this.getInvitationReportList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getInvitationReportsEndPoint + `?listing=true&search_term=${this.state.searchQuery}&city${this.getData(this.state.filter.selectedCity)}&country${this.getData(this.state.filter.selectedCountry)}&search_year${this.getData(this.state.filter.selectedYear)}&complex_id${this.getData(this.state.filter.selectedComplex)}&building${this.getData(this.state.filter.selectedBuilding)}&page=${this.state.page}&per_page=10`,
        })
    }
    getInvitationReportSearchData = async () => {
        this.getInvitationReportList = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getInvitationReportsEndPoint + `?listing=true&search_term=${this.state.searchQuery}&page=${this.state.page}&per_page=10`,
        })
    }
    onChange = (e: any) => {
        this.setState({ searchQuery: e.target.value, page: 1 }, () => this.getInvitationReportSearchData());
    }
    getYearList = async () => {
        this.getYearListCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getYearListEndPoint
        });
    };
    getInvitationReport = async () => {
        const societyID = localStorage.getItem("society_id")
        this.getInvitationReportId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `society_managements/${societyID}/bx_block_report/invitation_reports/request_count?search_year=${this.state.selectedYear}`,
        });
    }
    handleInvitationPagination = (e: any, value: any) => {
        this.setState({
            page: value
        }, () => {
            this.getInvitationReportFilterData()
        })
    }
    handleChangeValue = (event: any, keyState: any) => {
        if (keyState === "selectedCountry") {
            this.getCityData(event.target.value)
        } else if (keyState === "selectedCity") {
            this.getComplexData(event.target.value)
        } else if (keyState === "selectedComplex") {
            const id = event.target.value;
            this.getBuildingData(id);
        } else if(keyState === "selectedType") {
            this.setState({ filterComplexList: handleFilterComplexList(event.target.value === "complex" ? 1 : 2, this.state.complexList) } )
        }

        this.setState({ filter: { ...this.state.filter, [keyState]: event.target.value } })
    }

    handleBuildingList = (responseJson: any) => {
        if (responseJson && responseJson.buildings) {
            this.setState({
                buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))
            }
            )
        }
    }
    handleCountryListResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                countryList: responseJson.data?.countries.map((item: any) => ({ label: item, value: item }))
            });
        }
    };
    handleCityListResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                cityList: responseJson.data?.cities.map((item: any) => ({ label: item, value: item }))
            });
        }
    };
    handleComplexList = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ complexList: responseJson.data }, () => {
                this.setState({ filterComplexList: handleFilterComplexList(this.state.filter.selectedType === "complex" ? 1 : 2, this.state.complexList) } )
            })
        }
    }
    handleYearListResponse = (responseJson: any) => {
        if (responseJson && responseJson.year) {
            this.setState({
                yearList: responseJson.year.map((item: any) => ({ label: item, value: item }))
            });
        }
    };
    handleInvitationReportResponse = (responseJson: any) => {
        this.setState({
            invitaionReport: responseJson.data,
            pagination: responseJson?.meta?.pagination,
            invitationData: responseJson.meta
        })
    }

    handleInvitationClose = () => {
        this.setState({ isResendInviteDialogOpen: !this.state.isResendInviteDialogOpen });
    }

    handleOpenInvitation = (id: number | string) => {
        this.setState({ loading: true }, async () => {
            this.GetInviteDetailCallId = await CEApiCall({
                endPoint: `bx_block_request_management/member_invitations/${id}`,
                method: "GET",
                contentType: "application/json",
            });
        });
    }

    handleInvitationDetailResponse = (responseJson: any) => {
        this.setState({ loading: false }, () => {
            if (responseJson && responseJson.data) {
                const invitation = responseJson.data;
                this.setState({
                    isResendInviteDialogOpen: true, invitationId: invitation.id, formValue: {
                        complex: invitation.attributes.complex.id + "",
                        type: invitation.attributes.role.id,
                        country: invitation.attributes.country,
                        individualBuilding: invitation.attributes.complex.is_building ? "indiviualBuilding" : "complex",
                        building: invitation.attributes?.building_management?.id,
                        unit: invitation.attributes?.apartment_management?.id,
                        fullName: invitation.attributes.full_name,
                        city: invitation.attributes.city,
                        emailID: invitation.attributes.email_address,
                        phoneNumber: invitation.attributes.phone_number.split("-")[1],
                        countryCode: invitation.attributes.phone_number.split("-")[0],
                    },
                }, () => this.handleCallBack(invitation));
            }
        });
    }

    handleCallBack = (invitation: any) => {
        this.setState({ filterComplexList: handleFilterComplexList(invitation.attributes.complex.is_building ? 2 : 1, this.state.complexList) }, () => {
            this.handleHideBuildingUnitField(invitation.attributes.role.id.toString());
            this.getCityData(invitation.attributes.country);
            this.getComplexData(invitation.attributes.city);
            this.getBuildingData(invitation.attributes.complex.id);
            this.getUnitDataList(invitation.attributes?.building_management?.id)
        })
    }

    handleHideBuildingUnitField= async (type:any) => {
        const selectedUser = this.state.userRoleList.filter((element: any) => element.value===type)
        this.setState({ disableField: selectedUser[0].label !== "Owner Resident" && selectedUser[0].label !== "Owner" && selectedUser[0].label !== "Tenant" && selectedUser[0].label !== "Property Manager"  });
    }

    resendInviteValidation: any = Yup.object().shape({
        phoneNumber: Yup.string().required("Required").matches(/\S/, "Required"),
        type: Yup.string().required("Required").matches(/\S/, "Required"),
        unit: Yup.mixed().test("unit", "Required", (value: any) => {
            if (!this.state.disableField && !value) {
                return false;
            }
            return true;
        }),
        individualBuilding: Yup.string().required("Required").matches(/\S/, "Required"),
        emailID: Yup.string().email("Invalid email format").required("Required"),
        fullName: Yup.string().required("Required").max(100, "Maximum length of Name should be 100 character").matches(/\S/, "Required"),
        complex: Yup.string().required("Required").matches(/\S/, "Required"),
        city: Yup.string().required("Required").matches(/\S/, "Required"),
        country: Yup.string().required("Required").matches(/\S/, "Required"),
        building: Yup.mixed().test("unit", "Required", (value: any) => {
            if (!this.state.disableField && !value) {
                return false;
            }
            return true;
        }),
    });
    // Customizable Area End
}
