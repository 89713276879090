// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";

interface OwnProps {
  pagination: any;
  handlePagination: any;
  page: any;
}


const PaginationModule = (props: OwnProps) => {
  const { t } = useTranslation();

  let count = 0;
  if (props.pagination < 10) {
    count = props.pagination?.total_count;
  } else {
    if (10 * props.pagination?.current_page - 10 + 10 > props.pagination?.total_count) {
      count = props.pagination?.total_count;
    } else {
      count = 10 * props.pagination?.current_page - 10 + 10;
    }
  }

  return (
    <>
      <Box style={{ display: "flex", marginLeft: "15px" }}>
        <Typography style={{ marginRight: "5px", paddingRight: "0px" }}>{t("Showing")} </Typography>
        <Typography
          className={"bold-text"}
          style={{ marginRight: "5px", fontWeight: "bold", color: "#FC8434", paddingRight: "0px" }}
        >
          {count ? count : 0}{" "}
        </Typography>
        <Typography style={{ paddingRight: "0px" }}> {t("of")}</Typography>
        <Typography
          className={"bold-text"}
          style={{ marginRight: "5px", marginLeft: "5px", fontWeight: "bold", paddingRight: "0px" }}
        >
          {props.pagination?.total_count || 0}{" "}
        </Typography>
        <Typography style={{ paddingRight: "0px" }}> {t("Results")} </Typography>
      </Box>
      {props.pagination?.current_page && (
        <Pagination
          id="PaginationModule"
          count={props.pagination?.total_pages}
          onChange={props.handlePagination}
          variant="outlined"
          shape="rounded"
        />
      )}
    </>
  );
};

export default PaginationModule;

// Customizable Area End
