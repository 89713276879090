// Customizable Area Start
import React from "react";
import { Container, Typography, Button, withStyles, Card, Dialog, DialogContent, DialogActions, Box, Grid } from "@material-ui/core";
import CEActionsTeamController, { Props } from "./CEActionsTeamController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { CEBuildingIcon, CECalendarIcon, CERealEstateIcon, CEUserIcon } from "../assets";
import moment from "moment";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import NormalDateField from "../../../../components/src/CommonComponents/NormalDateField.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
// Customizable Area End

class CEActionsTeam extends CEActionsTeamController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-header-box">
              <Typography variant="body1">
                <span data-test-id="team-navigation" onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>{t("My Dashboards")}</span> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Actions assigned to my team")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("Actions assigned to my team")}
                </Typography>
                <Box className="action-heading-btn-box">
                  {localStorage.getItem("role") !== "account_manager" && (
                    <Button className="action-progress-btn" onClick={() => navigationFunc(this.props, "ActionAssignProgress")}>
                      {t("View action progress")}
                    </Button>
                  )}
                  <Button className="action-assign-btn" onClick={() => this.handleActionAssignModal()}>
                    {t("Assign action")}
                  </Button>
                </Box>
              </Box>
              <Box className="filter-head-box action-team-filter-box">
                <FilterSelect
                  t={t}
                  data-test-id="action-select-real-estate"
                  label="Real Estate Company"
                  language={language}
                  value={this.state.reactEstate}
                  option={[{ label: "All", value: "all" }, ...this.state.teamReactStateList.map((item: any) => ({ label: item.company_name, value: item.id }))]}
                  onChange={(e: any) => this.setState({ reactEstate: e.target.value })}
                />
                <FilterSelect
                  t={t}
                  onChange={(e: any) => this.setState({ complex: e.target.value })}
                  language={language}
                  option={[{ label: "All", value: "all" }, ...this.state.teamFilterComplexList.map((item: any) => ({ label: item.name, value: item.id }))]}
                  value={this.state.complex}
                  label="Select Complex"
                  data-test-id="action-select-complex"
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Building"
                  value={this.state.building}
                  data-test-id="action-select-building"
                  onChange={(e: any) => this.setState({ building: e.target.value })}
                  option={[{ label: "All", value: "all" }, ...this.state.teamBuildingList.map((item: any) => ({ label: item.name, value: item.id }))]}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Assign By"
                  data-test-id="action-select-assign-by"
                  value={this.state.assign}
                  onChange={(e: any) => this.setState({ assign: e.target.value })}
                  option={[{ label: "All", value: "all" }, ...this.state.teamAssignList.map((item: any) => ({ label: item.full_name, value: item.id }))]}
                />
                <Button
                  className="action-search-btn action-team-search-filter"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterValue: {
                        building: this.state.building,
                        reactEstate: this.state.reactEstate,
                        complex: this.state.complex,
                        assign: this.state.assign,
                      },
                    });
                  }}
                  startIcon={<SearchIcon />}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="action-content-main-box">
              <Grid container spacing={2}>
                {this.state.actionAssignTeamTaskList.map((task: any) => {
                  return (
                    <Grid item sm={12} className="action-team-content-box" key={task.id}>
                      <Card className="action-card">
                        <h4 className="bold-text">{task.attributes.title}</h4>
                        <p className="description">{task.attributes.description}</p>
                        <Box className="action-info-box action-team-info-content">
                          <Box className="action-content-box">
                            <Box className="action-column-box">
                              <Box className="action-info action-team-action-box">
                                <img src={CERealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{this.handleTeamCompanyName(task.attributes)}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CEUserIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned to")}</span>
                                  <p className="bold-text">{task.attributes.assign_to}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box">
                              <Box className="action-info action-team-action-box">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Complex")}</span>
                                  <p className="bold-text">{task.attributes.society_management?.name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned on")}</span>
                                  <p className="bold-text">{moment(task.attributes.assign_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box action-team-action-box">
                              <Box className="action-info">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Building")}</span>
                                  <p className="bold-text">{task.attributes.building_management ? task.attributes.building_management.name : "-"}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Due on")}</span>
                                  <p className="bold-text">{moment(task.attributes.due_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}</p>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.isActionAssignOpen}
          className="edit-profile edit-unit action-assign-create-modal"
          maxWidth="md"
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title={t("Action Assign")} onClose={this.handleActionAssignModal} />
          <Formik
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true }, () => {
                this.handleSubmitAssignForm(values);
                this.handleActionAssignModal();
                resetForm();
              });
            }}
            initialValues={this.state.actionForm}
            enableReinitialize={true}
            validationSchema={this.actionAssignValidation}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3} className="edit-building">
                      <Grid item md={12}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Title"
                          value={values.title}
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel
                        />
                        {ErrorHandler(t, touched.title, errors.title)}
                      </Grid>
                      <Grid item md={12}>
                        <NormalTextArea
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="description"
                          t={t}
                          language={language}
                          label={t("Description")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.description, errors.description)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.realStateCompany}
                          name="realStateCompany"
                          label="Real Estate Company"
                          onBlur={handleBlur}
                          onChange={async (event: any) => {
                            const realEstate = event.target.value;
                            await this.getActionTeamComplex(realEstate);
                            setFieldValue("realStateCompany", realEstate);
                          }}
                          option={this.state.teamReactStateList.map((item: any) => ({ label: item.company_name, value: item.id }))}
                          data-test-id="assign-select-real-estate"
                        />
                        {ErrorHandler(t, touched.realStateCompany, errors.realStateCompany)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.isComplex}
                          name="isComplex"
                          label="Complex/Individual Building"
                          onBlur={handleBlur}
                          onChange={async (event: any) => {
                            const isComplex = event.target.value;
                            setFieldValue("isComplex", isComplex);

                            if (isComplex === "complex") {
                              const filterComplexList = this.state.complexList.filter((complex) => !complex.is_building);
                              this.setState({ teamComplexList: filterComplexList });
                            } else {
                              const filterComplexList = this.state.complexList.filter((complex) => complex.is_building);
                              this.setState({ teamComplexList: filterComplexList });
                            }
                          }}
                          option={[{ label: "Complex", value: "complex" }, { label: "Individual Building", value: "individual" }]}
                          data-test-id="assign-select-is-building"
                        />
                        {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.complex}
                          name="complex"
                          label={values.isComplex === "complex" ? t("Complex") : t("Individual Building")}
                          onBlur={handleBlur}
                          onChange={async (event: any) => {
                            const complex = event.target.value;
                            await this.getActionTeamBuilding(complex);
                            await this.getActionTeamAssignTo(complex);
                            setFieldValue("complex", complex);
                          }}
                          option={this.state.teamComplexList.map((item: any) => ({ label: item.name, value: item.id }))}
                          data-test-id="assign-select-complex"
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      {values.isComplex === "complex" && (
                        <Grid item md={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            value={values.building}
                            name="building"
                            label="Building"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            option={this.state.teamBuildingList.map((item: any) => ({ label: item.name, value: item.id }))}
                          />
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Grid>
                      )}
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.assignTo}
                          name="assignTo"
                          label="Assigned To"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          option={this.state.teamAssignToList.map((item: any) => ({ label: item.full_name, value: item.id }))}
                        />
                        {ErrorHandler(t, touched.assignTo, errors.assignTo)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalDateField
                          language={language}
                          t={t}
                          label={t("Due On")}
                          value={values.dueOn}
                          name="dueOn"
                          onChange={(val: string) => setFieldValue("dueOn", val)}
                          onBlur={handleBlur}
                          isLabel={true}
                          icon={CECalendarIcon}
                          data-test-id="assign-select-date"
                        />
                        {ErrorHandler(t, touched.dueOn, errors.dueOn)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button style={{ height: "50px" }} onClick={this.handleActionAssignModal} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ height: "50px", margin: "0" }}>
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsTeam)));
// Customizable Area End
