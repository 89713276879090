// Customizable Area Start
import React from "react";
import RealEstateCompanyEmployeeController, { Props } from "./RealEstateCompanyEmployeeController";
import RealEstateCompaniesTable from "./RealEstateCompaniesTable.web";
import { withRouter } from "react-router-dom";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class RealEstateCompanies extends RealEstateCompanyEmployeeController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <RealEstateCompaniesTable navigation={navigation} id={"real_estate_companies_table"} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(RealEstateCompanies);
// Customizable Area End
