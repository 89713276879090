// Customizable Area Start
import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { TextField,} from "@material-ui/core";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87) !important',  
          fontFamily: "Gotham_Medium !important",
          fontSize: ".875rem"
        },
      },
    },
  },
})

const FilterDateField = ({ t, language, label, option, value, ...props }: any) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className="input__filter_input select-year date__filter_input"
          variant="inline"
          placeholder={t(label)}
          format="DD/MM/YYYY"
          fullWidth
          value={value}
          sx={{ backgroundColor: "red" }}
          renderInput={(params: any) => <TextField inputProps={{
            sx: {
              backgroundColor: "red",
              "&:placeholder": {
                color: "red"
              }
            }
          }} />}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default FilterDateField;
// Customizable Area End
