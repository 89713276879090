//Customizable Area Start
import React from "react";
import {

    Box,
    Grid,

} from "@material-ui/core";
import MainScreenController, {
    Props,
} from "./MainScreenController.web"
import './global.css';
import DashboardHeader from "./DashboardHeader.web";
import CEAccManSidebar from "./CEAccManSIdebar.web";
import CECOOSidebar from "./CECOOSidebar.web";
import CESalesManSidebar from "./CESalesManSidebar.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";



 class MainScreen extends MainScreenController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        const { i18n }: any = this.props;
        const language = i18n?.language;

        if (localStorage.loginSuccessToken) {
            return (

                <>
                    <Box maxWidth='xl' className="dashboard_container" dir={language === "ar" ? "rtl" : "ltr"}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DashboardHeader navigation={undefined} id={""} />
                            </Grid>
                        </Grid>

                        <Box className="dashboard_middle">
                            <Grid container className="dashboard_grid">
                                <Grid item xs={3} >
                                    {this.state.userRole === "coo" && <CECOOSidebar />}

                                    {this.state.userRole === "account_manager" && <CEAccManSidebar />}

                                    {this.state.userRole === "sales_manager" && <CESalesManSidebar />}
                                </Grid>
                                <Grid item xs={9} style={{ backgroundColor: "#F4F7FF" }}>
                                    {this.props.children}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};

// @ts-ignore
export default withTranslation()(withRouter(MainScreen));

//Customizable Area End


