// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import * as yup from "yup";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import React from "react";

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  location: any;
  match: any;
  t: any;
}

interface S {
  enableField: boolean;
  isEditMode: boolean;
  genericModalOpen: boolean;
  isMenu: null | HTMLElement;
  measurement: string;
  jurisdictionList: any;
  jurSearch: string;
  currency: string;
  name: string;
  jname: string;
  jid: number;
  timezone: string;
  page: any;
  pagination: any;
  mobile: number;
  zone: number;
  tax: number;
  formula: string;
  countryList: any;
  currencyList: any;
  timezoneList: any;
  searchCountry: any;
  openDetailsView: number;
  complexSelect: any;
  citySelected: any;
  countrySelected: any;
  regionSelected: any;
  searchId: any;
  searchQuery: any;
  loading: boolean;

  contractList: any[];
  contractSelected: any
}
interface FilledDataTypes {
  jname: string;
  jid: number;
  currency: string;
  mobile: number;
  timezone: string;
  formula: string;
  tax: string;
  zone: string;
  measurement: string;
}

interface SS {
  id: any;
}

export default class JurisdictionFeedController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getJurisidictionList: any;
  addJurisdictionCallId: any;
  getCountryApiCallId: any;
  getCurrencyApiCallId: any;
  getTimezoneApiCallId: any;
  getSearchBycountry: any;
  GetContractListCallId: string = "";

  formRef: React.RefObject<FormikProps<FilledDataTypes>>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();
    this.state = {
      enableField: false,
      isEditMode: false,
      genericModalOpen: false,
      isMenu: null,
      measurement: "",
      jurisdictionList: [],
      jurSearch: "",
      currency: "",
      name: "",
      jname: "",
      jid: 0,
      timezone: "",
      mobile: 0,
      page: 1,
      formula: "",
      zone: 0,
      tax: 0,
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      countryList: [],
      currencyList: [],
      timezoneList: [],
      searchCountry: "",
      openDetailsView: 0,
      complexSelect: "",
      regionSelected: "",
      citySelected: "",
      countrySelected: "",
      searchId: "",
      searchQuery: "",
      loading: false,

      contractList: [],
      contractSelected: ""
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    this.getJurisdiction();
    this.getCountryList();
    this.getCurrencyList();
    this.getContractList();
  }

  handleJurisdictionPagination = (e: any, value: any) => {
    this.setState({
      page: value,
    });
  };

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getJurisdiction();
    }
  }
  getJurisdiction = async () => {
    this.getJurisidictionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions?page=${this.state.page}&per_page=10`,
    });
  };
  getCountryList = async () => {
    this.getCountryApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/addresses`,
    });
  };
  getCurrencyList = async () => {
    this.getCurrencyApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_posts/classifieds/currency_list`,
    });
  };
  getTimezoneList = async (country: any) => {
    this.getTimezoneApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/list_of_timezone?country=${country}`,
    });
  };
  handleSearchKeyUp = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery }, () => {
      this.filterByCountry();
    });
  };
  handleFilterSubmit = () => {
    this.filterByCountry();
  };
  filterByCountry = async () => {
    this.setState({ loading: true });
    this.getSearchBycountry = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/search_by_country?country=${this.state.searchCountry
        }&search_by_id=${this.state.searchQuery}`,
    });
  };

  addJurisdiction = async (values: any) => {
    const formData = new FormData();
    formData.append("jurisdiction[jurisdiction_name]", values.name);
    formData.append("jurisdiction[country]", values.country);
    formData.append("jurisdiction[currency_id]", values.currency);
    formData.append("jurisdiction[timezone]", values.timezone);
    formData.append("jurisdiction[unit_of_measurement]", values.measurement);
    formData.append("jurisdiction[mobile_number_length]", values.mobile);
    formData.append("jurisdiction[formula]", values.formula);
    formData.append("jurisdiction[zone_multiplier]", values.zone);
    formData.append("jurisdiction[value_added_tax]", values.tax);

    this.addJurisdictionCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_society_management/company_employee/jurisdictions`,
      body: formData,
    });
  };

  handleJurisdictionListSucc = (resposeJson: any) => {
    if (resposeJson?.jurisdictions?.data?.length) {
      this.setState({
        jurisdictionList: resposeJson.jurisdictions.data,
        page: resposeJson.meta.pagination.current_page,
        pagination: resposeJson.meta.pagination
      });
    } else {
      this.setState({ loading: false })
    }
  };

  handleAddJurisdictionSucc = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.getJurisdiction();
      }
    })
  };

  handleCountrySucc = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countryList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name })) });
    }
  };
  handleSearchData = (response: any) => {
    if (response && response.jurisdictions) {
      this.setState({ jurisdictionList: response.jurisdictions.data, page: response.meta?.pagination?.current_page, pagination: response.meta?.pagination, loading: false })
    }
  };
  handleCurrencyListSucc = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ currencyList: responseJson.data.map((item: any) => ({ label: item.attributes.currency, value: item.id })) });
    }
  };
  handleTimeZoneSucc = (responseJson: any) => {
    if (responseJson && responseJson.timezones) {
      this.setState({ timezoneList: responseJson.timezones.map((item: any) => ({ label: item.zone, value: item.zone })) });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson?.errors) {
        switch (apiRequestCallId) {
          case this.getJurisidictionList:
            return this.handleJurisdictionListSucc(responseJson);
          case this.addJurisdictionCallId:
            return this.handleAddJurisdictionSucc(responseJson);
          case this.getCountryApiCallId:
            return this.handleCountrySucc(responseJson)
          case this.getCurrencyApiCallId:
            return this.handleCurrencyListSucc(responseJson)
          case this.getTimezoneApiCallId:
            return this.handleTimeZoneSucc(responseJson)
          case this.getSearchBycountry:
            return this.handleSearchData(responseJson)
          case this.GetContractListCallId:
            return this.handleLeaseListResponse(responseJson)
        }
      }
    }
  }

  handleClose = () => {
    this.setState({ genericModalOpen: false });
  };

  handleMenuClose = () => {
    this.setState({ isMenu: null });
  };

  handleAddJurisdiction = () => {
    this.setState({ genericModalOpen: true });
  };

  handleView = () => {
    this.props.history.push("/CompanyEmployee/ViewJurisdiction");
  };

  handleModalClose = () => {
    this.setState({ genericModalOpen: false });
  };

  handleDetailsOptionsData = (id: number) => {
    this.setState({ openDetailsView: id });
  };

  validation = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .max(100),
    currency: yup.string().required("Currency is required"),
    timezone: yup.string().required("Timezone is required"),
    mobile: yup.string().required("Mobile length is required"),
    tax: yup
      .string()
      .required(" Value added tax is required")
      .max(100),
    zone: yup
      .number()
      .required("Zone multipler is required")
      .min(0, "Zone multipler is required")
      .max(100),
    measurement: yup.string().required(" Unit of Measurement is required"),
    formula: yup.string().required("Measurement formula is required"),
    country: yup.string().required("Country is required"),
  })


  getContractList = async () => {
    this.GetContractListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contract/lease_templates`,
    });
  };

  handleLeaseListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ contractList: responseJson.data }, () => {
        if (responseJson.data.length > 0) {
          this.setState({ contractSelected: responseJson.data[0].id });
        }
      });
    }
  };

  handleSelectedTemplate = (template: any) => {
    this.setState({ contractSelected: template }, () => {
    });
  }
}
// Customizable Area End
