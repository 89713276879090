// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  complexBuildingId: any;
  buildingId: any;
  handleBack: any;
  handleNext: any;
  jurisdictionSelected: any;
  currency: any;
  step: number;
  measurement: any
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface IFacilityValue {
  name: string;
  detail: string;
  area: string;
  fees: string;
  images: any[];
  floorDocument: any;
}

interface S {
  loading: boolean;

  isFacilityModalOpen: boolean;

  facilityList: any[];

  facilityValues: IFacilityValue;
  editId: string;
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockStep6Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  AddSharedAreaApiCallId: string = "";
  DeleteSharedAreaApiCallId: string = "";
  GetSharedAreaListCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isFacilityModalOpen: false,

      facilityList: [],

      facilityValues: {
        name: "",
        detail: "",
        area: "",
        fees: "",
        images: [],
        floorDocument: null,
      },

      editId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.AddSharedAreaApiCallId:
          return this.handleSharedAreaResponse();
        case this.GetSharedAreaListCallId:
          return this.handleSharedAreaListResponse(responseJson);
        case this.DeleteSharedAreaApiCallId:
          return this.handleDeleteFacilityAreaResponse();
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getSharedAreaList();
    });
  }

  getSharedAreaList = async () => {
    this.GetSharedAreaListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/common_area_list?society_management_id=${this.props.complexBuildingId}`,
    });
  };

  handleSharedAreaListResponse = (response: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (response && response.data) {
        this.setState({ facilityList: response.data });
      }
    });
  };

  handleDeleteArea = async (id: string) => {
    this.DeleteSharedAreaApiCallId = await apiCall({
      method: "DELETE",
      endPoint: `bx_block_society_management/common_areas/${id}`,
    });
  };

  handleDeleteFacilityAreaResponse = () => {
    this.setState({ loading: false }, () => {
      this.getSharedAreaList();
    });
  };

  handleSubmitForm = (values: IFacilityValue, resetForm: () => void) => {
    this.setState({ isFacilityModalOpen: false, loading: true }, () => {
      if (this.state.editId) {
        this.editSharedArea(values);
      } else {
        this.addSharedArea(values);
      }
      resetForm();
    });
  };

  addSharedArea = async (values: IFacilityValue) => {
    const formData = new FormData();
    formData.append(`common_area[name]`, values.name);
    formData.append("common_area[society_management_id]", this.props.complexBuildingId + "");
    formData.append(`common_area[details]`, values.detail);
    formData.append(`common_area[total_area]`, values.area);
    formData.append(`common_area[reservation_fee]`, values.fees);
    formData.append(`common_area[floor_plan]`, values.floorDocument || "");

    [...values.images].forEach((photo: any) => {
      formData.append(`common_area[photos][]`, photo);
    });

    this.AddSharedAreaApiCallId = await apiCall({
      body: formData,
      method: "POST",
      endPoint: `bx_block_society_management/common_areas`,
    });
  };

  handleEditSharedArea = async (facilityItem: any) => {
    let photos: any[] = [];
    if (facilityItem.attributes.photos.length > 0) {
      photos = await handleImages(facilityItem.attributes.photos);
    }

    let floor: any = null;
    if (facilityItem.attributes.floor_plan) {
      const floors = await handleImages([facilityItem.attributes.floor_plan]);
      floor = floors[0];
    }

    this.setState({
      editId: facilityItem.id,
      isFacilityModalOpen: true,
      facilityValues: {
        name: facilityItem.attributes.name,
        detail: facilityItem.attributes.details,
        area: facilityItem.attributes.total_area,
        fees: facilityItem.attributes.reservation_fee,
        images: photos,
        floorDocument: floor,
      },
    });
  };

  editSharedArea = async (values: IFacilityValue) => {
    const data = new FormData();
    data.append(`common_area[reservation_fee]`, values.fees);
    data.append(`common_area[total_area]`, values.area);
    data.append(`common_area[floor_plan]`, values.floorDocument || "");
    data.append(`common_area[details]`, values.detail);

    [...values.images].forEach((photo: any) => {
      data.append(`common_area[photos][]`, photo);
    });

    this.AddSharedAreaApiCallId = await apiCall({
      body: data,
      method: "PATCH",
      endPoint: `bx_block_society_management/common_areas/${this.state.editId}`,
    });
  };

  handleSharedAreaResponse = () => {
    this.setState({ loading: false }, () => {
      this.getSharedAreaList();
    });
  };

  handleSharedFacilityDialog = () => {
    this.setState({ isFacilityModalOpen: !this.state.isFacilityModalOpen, editId: "" });
  };

  validationSchema = Yup.object().shape({
    detail: Yup.string().required("Details are required"),
    name: Yup.string()
      .min(3, "Facility name must be at least 3 characters")
      .max(50, "Facility name must be at most 50 characters")
      .required("Facility Name is required"),
    area: Yup.string().required("Total Area is required"),
    fees: Yup.string().required("Reservation fees is required"),
    floorDocument: Yup.mixed().required("Please upload a Floor Document File"),
    images: Yup.array().min(1, "At least one image is required").max(6, "Maximum of 6 images allowed").nullable(),
  });
}

// Customizable Area End
