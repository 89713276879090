// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import * as Yup from "yup";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  step: number;
  handleNext: any;
  handleBack: any;
  jurisdiction: string | number;
  complexID: string | number;
  handleChangeCurrency: any;
  measurement: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  navigation: any;
  country: any;
}

interface S {
  loading: boolean;
  isAddBuildingModalOpen: boolean;
  isDetailModalOpen: boolean;

  buildingList: any[];
  countriesList: any[];

  buildingFormValue: IBuildingFormValue;

  tempBuildingId: string;
  isEditOpen: boolean;
}

interface IBuildingFormValue {
  logo: any;
  images: any[];
  about: string;
  name: string;
  country: string;
  area: string;
  floor: string;
  location: string;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep5Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetBuildingListCallId: string = "";
  AddEditBuildingAPICallId: string = "";
  DeleteBuildingAPICallId: string = "";
  getJurisdictionDetailCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isAddBuildingModalOpen: false,
      isDetailModalOpen: false,

      buildingList: [],
      countriesList: [],

      buildingFormValue: {
        logo: null,
        images: [],
        about: "",
        name: "",
        country: this.props.country,
        area: "",
        floor: "",
        location: "",
      },

      tempBuildingId: "",
      isEditOpen: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetBuildingListCallId:
          return this.handleBuildingListResponse(responseJsonBlock);
        case this.DeleteBuildingAPICallId:
          return this.handleDeleteBuildingResponse();
        case this.AddEditBuildingAPICallId:
          return this.handleAddEditBuildingResponse(responseJsonBlock);
        case this.getJurisdictionDetailCallId:
          return this.handleJurisdictionDetailResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getBuildingList();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevProps.jurisdiction !== this.props.jurisdiction) {
      if (this.props.jurisdiction) {
        this.getJurisdiction(this.props.jurisdiction);
      }
    }

    if (prevProps.country !== this.props.country) {
      if (this.props.country) {
        this.setState({
          buildingFormValue: {
            ...this.state.buildingFormValue,
            country: this.props.country,
          },
        });
      }
    }
  }

  getJurisdiction = async (id: number | string) => {
    this.getJurisdictionDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleJurisdictionDetailResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data;
      const currency = jurisdiction.attributes.currency.currency;
      this.props.handleChangeCurrency(currency);
    }
  };

  getBuildingList = async () => {
    this.GetBuildingListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/building_managements?society_management_id=${this.props.complexID}`,
    });
  };

  handleBuildingListResponse = (responseJson: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ buildingList: responseJson.data });
      }
    });
  };

  deleteBuilding = (id: number | string) => {
    this.setState({ loading: true }, async () => {
      this.DeleteBuildingAPICallId = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_settings/building_managements/${id}`,
      });
    });
  };

  handleDeleteBuildingResponse = () => {
    this.setState({ loading: false }, () => {
      this.getBuildingList();
    });
  };

  handleSubmitBuilding = async (values: IBuildingFormValue) => {
    const formData = new FormData();
    formData.append("society_management_id", this.props.complexID + "");
    formData.append("building_management[logo]", values.logo);
    [...values.images].length &&
      [...values.images].forEach((image: any) => {
        formData.append(`building_management[photos][]`, image);
      });
    formData.append("building_management[description]", values.about);
    formData.append("building_management[name]", values.name);
    formData.append("building_management[country]", values.country);
    formData.append("building_management[building_area]", values.area);
    formData.append("building_management[total_floor]", values.floor);
    formData.append("building_management[googlemaplocation]", values.location);

    if (this.state.isEditOpen) {
      this.AddEditBuildingAPICallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_society_management/company_employee/complexes/update_complex_building?building_management_id=${this.state.tempBuildingId}`,
        body: formData,
      });
    } else {
      this.AddEditBuildingAPICallId = await apiCall({
        body: formData,
        method: "POST",
        endPoint: `bx_block_society_management/company_employee/complexes/create_complex_building`,
      });
    }
  };

  handleAddEditBuildingResponse = (responseJson: { data: any }) => {
    this.setState({ loading: false, isEditOpen: false }, () => {
      if (responseJson && responseJson.data) {
        this.getBuildingList();
        this.handleDetailsModalOpen(responseJson.data.id);
      }
    });
  };

  handleBuildingModal = () => {
    this.setState({ isAddBuildingModalOpen: !this.state.isAddBuildingModalOpen });
  };

  handleDetailsModalOpen = (id: number | string) => {
    this.setState({ isDetailModalOpen: true, tempBuildingId: id + "" });
  };

  handleDetailsModalClose = () => {
    this.setState({ isDetailModalOpen: false, tempBuildingId: "" });
  };

  handleBuildingNow = () => {
    sessionStorage.setItem("isCreateComplex", "true");
    sessionStorage.setItem("createComplexId", this.props.complexID + "");
    sessionStorage.setItem("isCreateComplexStep", "4");
    this.props.navigation.navigate("EditComplexBuildingMainBlock", {
      id: this.props.complexID,
      bId: this.state.tempBuildingId,
    });
  };

  handleEditBuildingOpen = async (building: any) => {
    let photos: any[] = [];
    if (building.attributes.photos.length > 0) {
      photos = await handleImages(building.attributes.photos);
    }
    let logo: any = null;
    if (building.attributes.logo) {
      const logos = await handleImages([building.attributes.logo]);
      logo = logos[0];
    }

    this.setState({
      loading: false,
      isEditOpen: true,
      tempBuildingId: building.id,
      buildingFormValue: {
        logo: logo,
        images: photos,
        about: building.attributes.description,
        name: building.attributes.name,
        country: building.attributes.country,
        area: building.attributes.building_area,
        floor: building.attributes.total_floors,
        location: building.attributes.googlemaplocation,
      },
      isAddBuildingModalOpen: true,
    });
  };

  validationSchema = Yup.object().shape({
    logo: Yup.mixed().required("Please upload a logo"),
    images: Yup.array().min(1, "At least one image is required").required("Please upload images"),
    about: Yup.string().required("About Us is required").nullable(),
    name: Yup.string()
      .min(3, "Building name must be at least 3 characters")
      .max(50, "Building name must be at most 50 characters")
      .required("Building Name is required"),
    area: Yup.string().required("Building Area is required"),
    floor: Yup.string().required("Total Floors is required"),
    location: Yup.string().required("Google Map Location is required").nullable(),
    country: Yup.string().required("Country is required"),
  });
}

// Customizable Area End
