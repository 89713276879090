// Customizable Area Start
import React from "react";
import { Container, Typography, Button, withStyles, Card } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CEActionsUserController, { Props } from "./CEActionsUserController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import { CEBuildingIcon, CECalendarIcon, CERealEstateIcon, CEUserIcon } from "../assets";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
// Customizable Area End

class CEActionsUser extends CEActionsUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-user-header-box">
              <Typography variant="body1">
                <span
                  data-test-id="user-navigation"
                  onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}
                >
                  {t("My Dashboards")}
                </span>{" "}
                /{" "}
                <span data-test-id="team-navigation" onClick={() => navigationFunc(this.props, "ActionAssignTeam")}>
                  {t("Action assigned to my team")}
                </span>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("View Actions Progress")}
                </Box>
              </Typography>
              <Box className="sub-heading-box action-user-name-head">
                <Typography variant="h5" className="bold-text">
                  {this.state.userName}' {t("Tasks")}
                </Typography>
              </Box>
              <Box className="filter-head-box action-user-filter-box">
                <FilterSelect
                  t={t}
                  data-test-id="user-select-complex"
                  label="Complex"
                  language={language}
                  value={this.state.complex}
                  option={[
                    { label: "All", value: "all" },
                    ...this.state.meComplexList.map((item: any) => ({ label: item.name, value: item.id })),
                  ]}
                  onChange={(event: any) => this.setState({ complex: event.target.value })}
                />
                <FilterSelect
                  t={t}
                  data-test-id="user-select-building"
                  label="Building"
                  language={language}
                  value={this.state.building}
                  option={[
                    { label: "All", value: "all" },
                    ...this.state.meBuildingList.map((item: any) => ({ label: item.name, value: item.id })),
                  ]}
                  onChange={(event: any) => this.setState({ building: event.target.value })}
                />
                <FilterSelect
                  t={t}
                  data-test-id="user-select-status"
                  label="Status"
                  language={language}
                  value={this.state.status}
                  option={[
                    { label: "All", value: "all" },
                    { label: "Pending", value: "pending" },
                    { label: "Completed", value: "completed" },
                    { label: "Delayed", value: "delayed" },
                  ]}
                  onChange={(event: any) => this.setState({ status: event.target.value })}
                />
                <Button
                  className="action-search-btn action-user-search"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterValue: {
                        complex: this.state.complex,
                        status: this.state.status,
                        building: this.state.building,
                      },
                    });
                  }}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="action-content-main-box">
              <Grid container spacing={2} className="action-user-content-box">
                {this.state.userActionProgressList.map((task: any, index: number) => {
                  return (
                    <Grid item sm={12} key={index}>
                      <Card className="action-card">
                        <h4 className="bold-text">{task.task_name}</h4>
                        <p className="description">{task.task_description}</p>
                        <Box className="action-info-box">
                          <Box className="action-content-box action-user-action-box">
                            <Box className="action-column-box">
                              <Box className="action-info action-user-info-box">
                                <img src={CERealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{this.handleUserCompanyName(task)}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CEUserIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned to")}</span>
                                  <p className="bold-text">{task.assigned_to}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box action-user-info-box">
                              <Box className="action-info">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Complex")}</span>
                                  <p className="bold-text">{task.complex_name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned on")}</span>
                                  <p className="bold-text">{task.assigned_on}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box ">
                              <Box className="action-info action-user-info-box">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Building")}</span>
                                  <p className="bold-text">{task.building_name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Due on")}</span>
                                  <p className="bold-text">{task.due_on}</p>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsUser)));
// Customizable Area End
