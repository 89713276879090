// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography, Card, Avatar, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { CheckIcon, profile } from "../assets";
import CompEmpBuildingBlockStep7Controller, { Props } from "./CompEmpBuildingBlockStep7Controller.web";
import "../../assets/commonForm.css";
import AlertError from "../../../../components/src/AlertError.web";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../../components/src/Loader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { Form, Formik } from "formik";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

export default class CompEmpBuildingBlockStep7 extends CompEmpBuildingBlockStep7Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box>
          <Box className="topBarFormik">
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography className="Coresteps bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
                {t("Core Members")}
              </Typography>
              <Typography className="Coresteps" style={{ fontSize: "16px" }}>
                {t("Step")} {this.props.step + 1} {t("of")} 7
              </Typography>
            </Box>

            <Box className="complexBuildingBlock" style={{ marginTop: "30px" }}>
              <Box className="complexBuildingBlock">
                <Grid container spacing={2} alignItems="stretch" className="complexBuildingBlock">
                  {this.state.coreMembersList.map((value: any, index: number) => (
                    <Grid
                      item
                      sm={6}
                      md={4}
                      lg={3}
                      key={index}
                      style={{ display: "flex" }}
                      className="complexBuildingBlock"
                    >
                      <Box
                        style={{ width: "100%", display: "flex", flexDirection: "column" }}
                        className="complexBuildingBlock"
                      >
                        <Card
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            ...webStyle.coreCardBox,
                          }}
                        >
                          <Box style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                            <CloseIcon
                              data-test-id="delete-member"
                              onClick={() =>
                                this.setState({ loading: true }, () => this.handleDeleteCoreMember(value.id))
                              }
                            />
                          </Box>
                          <Box
                            className="complexBuildingBlock"
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              flexGrow: 1,
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={value.attributes.user_profile.data.attributes.profile_pic || profile} />
                            <Box className="bold-text" style={webStyle.stepCoreP}>
                              {value.attributes.role}
                            </Box>
                            <Box style={webStyle.stepCoreP} className="complexBuildingBlock">
                              {value.attributes.name}
                            </Box>
                          </Box>
                          <Box style={webStyle.coreCardTag} className="complexBuildingBlock">
                            {value.attributes.role_list.map((role: string, index: number) => {
                              return (
                                <span key={index} className="bold-text complexBuildingBlock" style={webStyle.roleTag}>
                                  {role}
                                </span>
                              );
                            })}
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box className="complexBuildingBlock">
              <Box style={webStyle.addCoreMemberBtnBox}>
                <Box style={{ marginTop: "20px", border: "1px solid #2B6FED", borderRadius: "8px" }}>
                  <Box
                    style={webStyle.addCoreMemberBtn}
                    onClick={this.handleAddMemberDialog}
                    className="bold-text"
                    data-test-id="add-role-dialog"
                  >
                    {t("+ ADD NEW MEMBER")}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
          <Grid style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">PREV</span>
            </Button>
          </Grid>
          <Grid style={{ marginTop: "30px" }}>
            <Button style={webStyle.saveButton} onClick={this.handleCreateBuildingDialog}>
              <span className="bold-text">Save</span>
            </Button>
          </Grid>
        </Box>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
          data-test-id="core-alert-error"
        />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isCoreMembersModalOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title={t("Add New Member")} onClose={this.handleCoreMemberDialog} />
          <Formik
            initialValues={this.state.userValues}
            validationSchema={this.validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isCoreMembersModalOpen: false }, () => {
                this.addNewCoreMember(values);
                resetForm();
              });
            }}
            data-test-id="core-member-form"
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <Form translate="yes" onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalSelect
                        value={values.account}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="account"
                        t={t}
                        language={language}
                        label={t("User")}
                        option={this.state.userList}
                        disabled={this.state.userList.length === 0}
                      />
                      {ErrorHandler(t, touched.account, errors.account)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        name="role"
                        t={t}
                        onChange={handleChange}
                        value={values.role}
                        onBlur={handleBlur}
                        language={language}
                        option={this.state.roleList}
                        label={t("Role")}
                      />
                      {ErrorHandler(t, touched.role, errors.role)}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        className="complexBuildingBlock new-member-dialog"
                        onClick={this.handleNewCoreMemberDialog}
                        style={webStyle.newCoreMember}
                      >
                        {t("+ CREATE NEW MEMBER")}
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={this.handleCoreMemberDialog}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("SAVE")}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isNewCoreMembersModalOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title={t("Create New Member")} onClose={this.handleNewCoreMemberDialog} />
          <Formik
            initialValues={this.state.memberValues}
            validationSchema={this.validationCreateSchemaBlock}
            enableReinitialize={true}
            data-test-id="member-invite-form"
            onSubmit={(values, { resetForm }) => {
              this.setState({ isNewCoreMembersModalOpen: false, loading: true }, () => {
                this.createCoreMember(values);
              });
            }}
          >
            {({ values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NormalSelect
                          onBlur={handleBlur}
                          t={t}
                          onChange={handleChange}
                          value={values.type}
                          label={t("User Type")}
                          option={this.state.allRoleList}
                          name="type"
                          language={language}
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="name"
                          t={t}
                          language={language}
                          label={t("Full Name")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          t={t}
                          language={language}
                          label={t("Email Address")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.email, errors.email)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="form__phone_number">
                          <PhoneInput
                            value={values.countryCode}
                            country="us"
                            enableSearch={true}
                            onChange={(value: any) => {
                              setFieldValue("countryCode", `+${value}`);
                            }}
                            data-test-id="core-invite-country-code"
                          />
                          <NormalTextField
                            t={t}
                            language={language}
                            label="Phone Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isLabel
                            value={values.phone}
                            name="phone"
                          />
                        </Box>
                        {ErrorHandler(t, touched.phone, errors.phone)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          onBlur={handleBlur}
                          language={language}
                          onChange={handleChange}
                          isLabel
                          value={values.country}
                          label="Country"
                          name="country"
                          disabled
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          onBlur={handleBlur}
                          language={language}
                          onChange={handleChange}
                          isLabel
                          value={values.city}
                          label="City"
                          name="city"
                          disabled
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          onBlur={handleBlur}
                          t={t}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("isComplex", Number(event.target.value));
                            setFieldValue("complex", "");
                            this.handleIsComplex(event.target.value);
                          }}
                          value={values.isComplex}
                          label={t("Complex/Individual Building")}
                          option={[
                            { label: "Complex", value: 1 },
                            { label: "Individual Building", value: 2 },
                          ]}
                          name="isComplex"
                          language={language}
                          data-test-id="core-invite-is-complex"
                        />
                        {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          label={values.isComplex === 1 ? t("Complex") : t("Individual Building")}
                          value={values.complex}
                          name="complex"
                          onBlur={handleBlur}
                          t={t}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("complex", event.target.value);
                            setFieldValue("building", "");
                            if (values.isComplex === 1) {
                              this.getCoreMemberBuildingList(event.target.value);
                            } else {
                              this.getCoreMemberUnitList(event.target.value, "");
                            }
                          }}
                          language={language}
                          option={this.state.filterComplexList}
                          data-test-id="core-invite-complex"
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      {values.isComplex !== 2 && (
                        <Grid item xs={6}>
                          <NormalSelect
                            label={t("Building")}
                            value={values.building}
                            name="building"
                            onBlur={handleBlur}
                            t={t}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue("building", event.target.value);
                              setFieldValue("unit", "");
                              this.getCoreMemberUnitList(values.complex, event.target.value);
                            }}
                            language={language}
                            option={this.state.buildingList}
                            data-test-id="core-invite-building"
                          />
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <NormalSelect
                          label={t("Unit")}
                          value={values.unit}
                          name="unit"
                          onBlur={handleBlur}
                          t={t}
                          onChange={handleChange}
                          language={language}
                          option={this.state.unitList}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleNewCoreMemberDialog}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertDialog
          IsOpen={this.state.isCreateBuildingOpen}
          Image={CheckIcon}
          CloseDialog={this.handleCreateBuildingDialog}
          Header={this.props.isEditBuilding ? t("Building Edited") : t("New Building Created")}
          Content={`<p>${t(this.handleBuildingMessage())}</p>`}
          DeclineText={t("Okay")}
          DeclineFun={this.handleSubmitCreateBuildingDialog}
          language={language}
        />
      </>
    );
  }
}

const webStyle: any = {
  prevButton: {
    width: "177px",
    fontWeight: "bold",
    padding: "0px 20.5px",
    borderRadius: "8px",
    height: "50px",
    border: "1px solid #2B6FED",
    color: "#2B6FED",
    display: "flex",
    justifyContent: "center",
    textTransform: "uppercase",
    textAlign: "center",
  },
  addCoreMemberBtn: {
    color: "#2B6FED",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "10px 25px",
    cursor: "pointer",
  },
  addCoreMemberBtnBox: { display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" },
  saveButton: {
    justifyContent: "center",
    height: "50px",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    width: "177px",
    color: "#FFFFFF",
    fontWeight: "bold",
    backgroundColor: "#2B6FED",
    padding: "0px 20.5px",
    textTransform: "uppercase",
  },
  roleTag: {
    color: "rgb(43, 111, 237)",
    width: "max-content",
    background: "rgba(43, 111, 237, 0.08)",
    borderRadius: "14px",
    padding: "6px 22px",
    alignItems: "center",
    display: "flex",
    fontSize: "14px",
    lineHeight: "14px",
    justifyContent: "center",
  },
  step6: {
    margin: "11px 0px",
    color: "black",
    fontSize: "16px",
    fontWeight: 400,
  },
  coreCardBox: {
    padding: "20px 10px",
    border: "1px solid #e9e9e9",
    boxShadow: "none",
    borderRadius: "8px",
  },
  coreCardTag: { justifyContent: "center", display: "flex", gap: "10px", flexFlow: "wrap" },
  stepCoreP: { margin: "11px 0px", color: "black", fontSize: "16px" },
  newCoreMember: { color: "#FC8434", padding: "15px 0", fontWeight: "400", fontSize: "15px", cursor: "pointer" },
};
// Customizable Area End
