import React from "react";
// Customizable Area Start
import { Box, styled, Typography, Tab, Button, Grid, Divider, Popover } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { ExclamationIcon } from "./assets";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import DashboardAnalyticsController, {
    Props,
} from "./DashboardAnalyticsController.web";

// Customizable Area Start
export class DashboardAnalytics extends DashboardAnalyticsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n } = this.props;
    const language = i18n.language;
        // Customizable Area End
            return (
              // Customizable Area Start
              <CEHeaderComponentWeb>
                <DashboardAnalyticsCss>
                  <Box className="containerBox">
                    <Box className="navigation">
                      <Typography variant="body1" className="breadcrumb-link">
                        <Box data-test-id="dashboard-link" onClick={() => this.handleDashboardNavigation()}>
                          {t("My Dashboards")}
                        </Box>{" "}
                        /{" "}
                        <Box component="span" style={{ color: "#2b6fed" }}>
                          {t("Analysis Dashboard")}
                        </Box>
                      </Typography>
                    </Box>
                    <br />
                    <Box className="header-title">
                      <Typography variant="h4" className="bold-text">
                        {t("Dashboard")}
                      </Typography>
                    </Box>
                    <Box className="request-tab-box">
                      {this.state.roleList.map((role: string) => {
                        return (
                          <Tab
                            onClick={() => this.handleChangeRoleTab(role)}
                            label={t(role)}
                            data-test-id="role-tab"
                            className={role === this.state.role ? "active" : ""}
                          />
                        );
                      })}
                    </Box>
                    <Box className="filter-head-box">
                      {this.state.userRole === "coo" && (
                        <FilterSelect
                          option={[{ label: "All", value: "all" }, ...this.state.reactStateList]}
                          language={language}
                          t={t}
                          label="Select Company"
                          onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                            this.handleAnalyticsFilterChange(event, "company")
                          }
                          value={this.state.filter.company}
                          data-test-id="analytic-company-filter"
                        />
                      )}
                      <FilterSelect
                        t={t}
                        language={language}
                        option={[
                          { label: "All", value: 0 },
                          { label: "Complex", value: 1 },
                          { label: "Individual Building", value: 2 },
                        ]}
                        style={{ fontWeight: "bold", color: "black" }}
                        value={this.state.filter.isComplex}
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleAnalyticsFilterChange(event, "isComplex")
                        }
                        data-test-id="analytic-isComplex-filter"
                      />
                      <FilterSelect
                        t={t}
                        language={language}
                        label={t("Select Country")}
                        option={[{ label: "All", value: "all" }, ...this.state.countriesList]}
                        value={this.state.filter.country}
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleAnalyticsFilterChange(event, "country")
                        }
                        data-test-id="analytic-country-filter"
                      />
                      <FilterSelect
                        label={t("Select City")}
                        t={t}
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleAnalyticsFilterChange(event, "city")
                        }
                        language={language}
                        value={this.state.filter.city}
                        option={[{ label: "All", value: "all" }, ...this.state.citiesList]}
                        data-test-id="analytic-city-filter"
                      />
                      <FilterSelect
                        language={language}
                        t={t}
                        option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                        value={this.state.filter.complex}
                        label={this.state.filter.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleAnalyticsFilterChange(event, "complex")
                        }
                        data-test-id="analytic-complex-filter"
                      />
                      {this.state.filter.isComplex !== 2 && (
                        <FilterSelect
                          t={t}
                          language={language}
                          option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                          onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                            this.handleAnalyticsFilterChange(event, "building")
                          }
                          label="Select Building"
                          value={this.state.filter.building}
                          data-test-id="analytic-building-filter"
                        />
                      )}
                      <Button onClick={this.getAnalyticsData} startIcon={<SearchIcon />} className="await-search-filter-btn">
                        {t("Search")}
                      </Button>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item sm={12}>
                        <Box className="action-card">
                          <Box className="action-info-box">
                            <Box className="action-content-box">
                              <Box className="content-box">
                                <Box className="action-info">
                                  <h3 className="bold-text subscription_table_heading2">{t("Most used features")}</h3>
                                </Box>
                              </Box>
                              <Divider />
                              <Box className="action-info-body">
                                <Box className="info-body-box">
                                  <span>{t("Features")}</span>
                                </Box>
                                <Box className="info-body-box">
                                  {this.state.analytics.map((item: { label: string; value: string; id: string }) => {
                                    return (
                                      <Box className="Feature-div">
                                        <Typography className="vehicle-name">
                                          {t(item.label)}{" "}
                                          <img
                                            onMouseEnter={(e) => this.HandlePopover(e, item.id)}
                                            src={ExclamationIcon} className="info-icon" data-test-id="info-icon-pop-over"
                                          />
                                        </Typography>
                                        <Box className="progress-container">
                                          <Box className="progress-bar">
                                            <Box className="progress" style={{ width: `${item.value}%` }}></Box>
                                            {Number(item.value) > 0 && <span className="progress-label">{item.value}%</span>}
                                          </Box>
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </DashboardAnalyticsCss>

                <PopoverCss
                  open={this.state.open}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "center", horizontal: "left" }}
                  PaperProps={{ style: { width: "500px", overflow: "auto" } }}
                  data-test-id="analysis-action-card"
                >
                  <Box className="action-card">
                    <Box className="action-info-box">
                      <Box className="action-content-box">
                        <Box className="content-box">
                          <Box className="action-info">
                            <h4 className="bold-text subscription_table_heading2">{t("Most used features")}</h4>
                            <CloseIcon
                              data-test-id="close-icon" className="close-icon" onClick={this.HandleClose}
                            />
                          </Box>
                        </Box>
                        <Divider />
                        <Box className="action-info-body">
                          <Box className="info-body-box">
                            <span>{t("Features")}</span>
                          </Box>
                          <Box className="info-body-box">
                            {this.state.category.length === 0 && (
                              <Typography>{t("No feature category available")}</Typography>
                            )}
                            {this.state.category.map((category: { label: string; value: string }) => {
                              return (
                                <Box className="Feature-div" key={category.label}>
                                  <Typography className="vehicle-name">
                                    {t(category.label)} <img src={ExclamationIcon} className="info-icon" />
                                  </Typography>
                                  <Box className="progress-container">
                                    <Box className="progress-bar">
                                      <Box className="progress" style={{ width: `${category.value}%` }}></Box>
                                      {Number(category.value) > 0 && <span className="progress-label">{category.value}%</span>}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </PopoverCss>
              </CEHeaderComponentWeb>
              // Customizable Area End
            );
        }
}

// Customizable Area Start
export const DashboardAnalyticsCss = styled(Box)({
  "& .containerBox": {
    padding: "0px 40px",
  },
  "& .breadcrumb-link": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  "& .subcontainer": {
    padding: "0px 40px",
  },
  "& .header-title": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .request-tab-box": {
    marginTop: "15px",
    marginBottom: "25px",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    "& .MuiTab-root": {
      borderRadius: "25px",
      minWidth: "auto",
      minHeight: "auto",
      background: "#EDF0F9",
      textTransform: "capitalize",
      fontSize: "16px",
      marginRight: "10px",
      padding: "6px 20px",
      color: "gray",
    },
    "& .MuiTab-root.active": {
      color: "white",
      background: "#2C6DF3",
      fontWeight: "600",
      opacity: 1,
    },
    "& Button": {
      color: "white",
      textTransform: "capitalize",
      fontSize: "16px",
      background: "#FE8335",
      minHeight: "auto",
      padding: "6px 20px",
      minWidth: "auto",
      borderRadius: "25px",
      boxShadow: "none",
    },
  },
  "& .action-card": {
    background: "#fff",
    borderRadius: 8,
    boxShadow: "4px 0px 14px #e9e9e9",
    "& .action-info-box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      "& .action-info": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        gap: "15px",
        "& span": {
          fontSize: "14px",
          color: "#FC8434",
        },
        "& p": {
          fontWeight: "600",
        },
        "& .close-icon": {
          cursor: "pointer",
        },
      },
      "& .action-content-box": {
        width: "100%",
        "& .content-box": {
          display: "flex",
          alignItems: "center",
          gap: "50px",
          padding: "20px",
          justifyContent: "space-between",
        },
      },
    },
    "& hr": {
      width: "100%",
    },
    "& .action-info-body": {
      width: "100%",
      "& .info-body-box": {
        padding: "10px 20px",
      },
    },
  },
  "& .vehicle-name": {
    display: "flex",
    fontSize: 14,
    width: "200px",
    flexDirection: "row",
    alignItems: "center",
  },
  "& .Feature-div": {
    flexDirection: "row",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  "& .progress-container": {
    flex: 1,
  },
  "& .progress-bar": {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    height: "35px",
    display: "flex",
    gap: "8px",
    alignItems: "center"
  },
  "& .progress": {
    borderRadius: "4px",
    backgroundColor: "#2C6DF3",
    height: "100%",
  },
  "& .info-icon": {
    marginLeft: "5px",
    height: "20px",
    cursor: "pointer",
  },
});

export const PopoverCss = styled(Popover)({
  "& .info-icon": {
    height: "20px",
    marginLeft: "5px",
  },
  "& .vehicle-name": {
    alignItems: "center",
    width: "125px",
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
  },
  "& .Feature-div": {
    flexDirection: "row",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  "& .progress-container": {
    flex: 1,
  },
  "& .progress-bar": {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    height: "35px",
    position: "relative",
    display: "flex",
    gap: "8px",
    alignItems: "center"
  },
  "& .progress": {
    borderRadius: "4px",
    height: "100%",
    backgroundColor: "#ff4d01",
  },
  "& .action-card": {
    borderRadius: 8,
    background: "#fff",
    boxShadow: "4px 0px 14px #e9e9e9",
    "& .action-info-box": {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      "& .action-info": {
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        gap: "15px",
        "& span": {
          color: "#FC8434",
          fontSize: "14px",
        },
        "& p": {
          fontWeight: "600",
        },
        "& .close-icon": {
          cursor: "pointer",
        },
      },
      "& .action-content-box": {
        width: "100%",
        "& .content-box": {
          alignItems: "center",
          gap: "50px",
          justifyContent: "space-between",
          display: "flex",
          padding: "20px",
        },
      },
    },
    "& hr": {
      width: "100%",
    },
    "& .action-info-body": {
      width: "100%",
      "& .info-body-box": {
        padding: "10px 20px",
      },
      "& .table-row-heading": {
        alignItems: "center",
        justifyContent: "space-between",
        color: "#c7c7c7",
        fontSize: "15px",
        display: "flex",
        borderBottom: "1px solid #f9f9f9",
        padding: "10px 0",
      },
      "& .table-row-body": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #f9f9f9",
        padding: "10px 0",
        fontSize: "16px",
        "& span:last-child": {
          fontWeight: "600",
        },
      },
      "& .table-row-body:last-child": {
        borderBottom: "0",
      },
    },
  },
});

export default withTranslation()(DashboardAnalytics);
// Customizable Area End