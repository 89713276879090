import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  withStyles,
  Divider,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  MenuItem,
  Dialog,
  DialogContent,
  Grid,
  InputLabel,
  DialogActions,
  styled,
  TextField,
} from "@material-ui/core";
import { Menu } from "@szhsin/react-menu";
import { withTranslation } from "react-i18next";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { withRouter } from "react-router";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import { Field, Formik } from "formik";
import NormalSelect from "../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler, checkPhoneValidation } from "../../../components/src/UtilFunction.web";
import NormalTextField from "../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import Loader from "../../../components/src/Loader.web";
import AlertError from "../../../components/src/AlertError.web";
// Customizable Area End

import InvitationReportController, {
    Props,
} from "./InvitationReportController.web";

// Customizable Area Start
class InvitationReport extends InvitationReportController {
  constructor(props: Props) {
      super(props);
  }

  tableBody = (t: any) =>
    this.state.invitaionReport.map((row: any, i: number) => {
      return (
        <TableRow key={i}>
          <TableCell>{((this.state.page - 1) * 10) + (i + 1)}</TableCell>
          <TableCell>{row.attributes.complex}</TableCell>
          <TableCell>{row.attributes?.bulidng || "_"}</TableCell>
          <TableCell>{row.attributes?.name || "_"}</TableCell>
          <TableCell>{row.attributes?.role || "-"}</TableCell>
          <TableCell>{this.convertDate(row.attributes.created_at)}</TableCell>
          <TableCell className="view-page-menu buildid" id="detailsId">
            <Menu
              menuButton={
                <IconButton data-test-id="ir-report-menu">
                  <MoreVertIcon />
                </IconButton>
              }
              style={webStyle.btnMenu}
            >
              <MenuItem className="buildid">
                <Link to={`/CompanyEmployee/InvitationReport/UserProfile/${row.id}`}>{t("View Profile")}</Link>
              </MenuItem>
              <MenuItem onClick={() => this.handleOpenInvitation(row.id)} className="resend-menu-id">
                {t("Resend invitation")}
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      );
    });
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t, classes, i18n }: any = this.props;
        const language = i18n.language;
        // Customizable Area End
        return (
            // Customizable Area Start
        <>
        <Loader loading={this.state.loading} />

        <CEHeaderComponentWeb>
          <Container className={classes.reportDetails}>
            <Box className="navigation">
              <Typography variant="body1">
                {t("Documents & Reports")} /
                <Box
                  style={webStyle.reportLable}
                  component="span"
                  className="reports-invitationReport breadcrumbInvitation"
                  data-test-id="breadcrumbInvitation"
                  onClick={() => this.props.history.push("/CompanyEmployee/ReportsStatistics")}
                >
                  {t("Reports")}
                </Box>{" "}
                /{" "}
                <Box className="reports-invitationReport" component="span" style={webStyle.subHeaderColor}>
                  {t("Invitations Reports")}
                </Box>
              </Typography>
              <Box style={webStyle.subheadingbox}>
                <Typography
                  className="sub-heading bold-text reports-invitationReport"
                  variant="h5"
                  style={webStyle.subHeaderStyle}
                >
                  {t("Invitations Reports")}
                </Typography>
                <Typography
                  className="sub-heading bold-text reports-invitationReport invitations-download"
                  style={webStyle.subHeaderButtonStyle}
                  onClick={this.downloadInvitationReport}
                >
                  {t("DOWNLOAD REPORT")}
                </Typography>
              </Box>
              <Box className="filter-head-box">
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Complex / Individual Building"
                  value={this.state.filter.selectedType}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedType")}
                  option={[...this.state.complexBuildingList]}
                  data-test-id="filter-type"
                  style={{ fontWeight: "bold", color: "black" }}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Country"
                  value={this.state.filter.selectedCountry}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedCountry")}
                  option={[{ label: "All", value: "all" }, ...this.state.countryList]}
                  data-test-id="filter-country"
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select City"
                  value={this.state.filter.selectedCity}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedCity")}
                  option={[{ label: "All", value: "all" }, ...this.state.cityList]}
                  data-test-id="filter-city"
                />
                <FilterSelect
                  t={t}
                  label={this.state.filter.selectedType === "complex" ? "Select Complex" : "Select Individual Building"}
                  language={language}
                  value={this.state.filter.selectedComplex}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedComplex")}
                  option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                  data-test-id="filter-complex"
                />
                {this.state.filter.selectedType !== "indiviualBuilding" && (
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Select Building"
                    value={this.state.filter.selectedBuilding}
                    onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                    option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                    data-test-id="filter-building"
                  />
                )}
                <FilterSelect
                  t={t}
                  language={language}
                  label="Year"
                  option={[{ label: "All", value: "all" }, ...this.state.yearList]}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedYear")}
                  value={this.state.filter.selectedYear}
                  data-test-id="filter-year"
                />
                <Button
                  className="action-search-btn"
                  data-test-id="filter-search"
                  startIcon={<SearchIcon />}
                  onClick={this.getInvitationReportFilterData}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="budget-box audit-box">
              <Card>
                <Box className="heading">
                  <h4 className="bold-text reports-invitationReport">{t("Invitations Reports")}</h4>
                </Box>
                <Divider />
                <Box className="budget-content-box">
                  <Box className="head audit-line">
                    <p className={"bold-text"}>{t("Title")}</p>
                    <span className={"bold-text"}>{t("Count")}</span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Total sent invitations")}</p>
                    <span>{this.state.invitationData?.total_sent_invitation || 0} </span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Accepted invitations by users")}</p>
                    <span>{this.state.invitationData?.accepted_invitation_by_users || 0}</span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Rejected invitation by users")}</p>
                    <span>{this.state.invitationData?.rejected_invitation_by_users || 0}</span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Total received requests")}</p>
                    <span>{this.state.invitationData?.total_receive_invitation || 0}</span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Pending join requests")}</p>
                    <span>{this.state.invitationData?.pending_join_requests || 0}</span>
                  </Box>
                  <hr />
                  <Box className="audit-line">
                    <p>{t("Sent invitations awaiting acceptance")}</p>
                    <span>{this.state.invitationData?.sent_invitation_awaiting_acceptence || 0}</span>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Box style={webStyle.tableContainerStyle} className="subscription_table_select_boxes_container">
              <Box style={webStyle.tableHeaderStyle} className="subscription_table_select_boxes_header">
                <h3 className="bold-text subscription_table_heading2">{t("Oldest Pending Invitations")}</h3>
                <SearchInput
                  onChange={this.onChange}
                  t={t}
                  language={language}
                  label="Search by complex"
                  data-test-id="search-invitations-input"
                />
              </Box>
              <Table className="table-box">
                <TableHead>
                  <TableRow>
                    <TableCell className="bold-text">{t("#")}</TableCell>
                    <TableCell className="bold-text">{t("Complex")}</TableCell>
                    <TableCell className="bold-text">{t("Building")}</TableCell>
                    <TableCell className="bold-text">{t("Name")}</TableCell>
                    <TableCell className="bold-text">{t("Role")}</TableCell>
                    <TableCell className="bold-text">{t("Sent On")}</TableCell>
                    <TableCell className="bold-text"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.tableBody(t)}</TableBody>
              </Table>
              <Divider />
              <Box style={webStyle.tableFooterStyle} className="paginate_details">
                <PaginationModule
                  pagination={this.state.pagination}
                  handlePagination={this.handleInvitationPagination}
                  page={this.state.page}
                />
              </Box>
            </Box>
          </Container>
        </CEHeaderComponentWeb>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
          data-test-id="alert-dialog"
        />

        <DialogActionsCss
          scroll="paper"
          dir={languageCondition(language, "rtl", "ltr")}
          fullWidth
          open={this.state.isResendInviteDialogOpen}
          maxWidth="md"
        >
          <DialogHeader t={t} title="Resend Invitation" onClose={this.handleInvitationClose} />
          <Formik
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isResendInviteDialogOpen: false }, () => {
                this.handleFormSubmit(values);
                resetForm();
              });
            }}
            data-test-id="resend-invitation-form"
            initialValues={this.state.formValue}
            enableReinitialize={true}
            validationSchema={this.resendInviteValidation}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          name="type"
                          label="User Type"
                          option={this.state.userRoleList}
                          data-test-id="user-type"
                          value={values.type}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          value={values.fullName}
                          isLabel={true}
                          label="Member full name"
                          name="fullName"
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.fullName, errors.fullName)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          value={values.emailID}
                          isLabel={true}
                          name="emailID"
                          label="Email Address"
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.emailID, errors.emailID)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="form__phone_numbers" display="flex" alignItems="center">
                          <PhoneInput
                            data-test-id="country-code"
                            inputProps={{ name: "selectCode" }}
                            onChange={(value: any) => {
                              setFieldValue("countryCode", `+${value}`);
                            }}
                            enableSearch={true}
                            value={values.countryCode}
                            country="us"
                            disabled
                          />
                          <Box className="input_icon__form_box">
                            <InputLabel>{t("Phone Number")}</InputLabel>
                            <Field
                              as={TextField}
                              type="text"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              placeholder={t("Phone Number")}
                              className="input_box"
                              onChange={handleChange}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              disabled
                            />
                          </Box>
                        </Box>
                        {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          label="Country"
                          value={values.country}
                          language={language}
                          name="country"
                          data-test-id="select-country"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          option={[...this.state.countryList]}
                          disabled
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          label="City"
                          key="city"
                          data-test-id="select-city"
                          value={values.city}
                          language={language}
                          option={this.state.cityList}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          name="individualBuilding"
                          data-test-id="individual-building"
                          label="Complex/Individual Buildings"
                          value={values.individualBuilding}
                          option={this.state.complexBuildingList}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.individualBuilding, errors.individualBuilding)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          label="Complex"
                          data-test-id="select-complex"
                          name="complex"
                          language={language}
                          value={values.complex}
                          option={this.state.complexList.map((item: any) => ({label: item.attributes.name, value: item.id}))}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      {!this.state.disableField && (
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label="Building"
                            name="building"
                            value={values.building}
                            option={this.state.buildingList}
                            onChange={handleChange}
                            disabled
                          />
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Grid>
                      )}
                      {!this.state.disableField && (
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            value={values.unit}
                            label="Unit"
                            name="unit"
                            option={this.state.unitList}
                            onChange={handleChange}
                            disabled
                          />
                          {ErrorHandler(t, touched.unit, errors.unit)}
                        </Grid>
                      )}
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleInvitationClose}>
                      {t("Cancel")}
                    </Button>
                    <Button className="add-button" type="submit" data-testid="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogActionsCss>
          </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle: any = {
  tableContainerStyle: {
    background: "#fff",
  },
  tableHeaderStyle: {
    padding: "20px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeaderStyle: {
    fontSize: 28,
  },
  subHeaderButtonStyle: {
    color: "#fff",
    padding: "10px 20px",
    background: "#FC8434",
    borderRadius: "8px",
    cursor: "pointer",
  },
  subHeaderColor: {
    color: "blue",
  },
  subheadingbox: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "10px",
    display: "flex",
    "& h5": {
      fontWeight: "600",
      fontSize: "28px",
    },
  },
  reportLable: { cursor: "pointer" },
  tableFooterStyle: {
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchInputStyle: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  innerInput: {
    padding: "0px",
    color: "#212121",
  },
};

const DialogActionsCss = styled(Dialog)({
  "& .form__phone_numbers": {
    position: "relative",
    "& .react-tel-input": {
      width: "fit-content",
      left: 0,
      zIndex: 9,
      top: "33px",
      borderRight: "1px solid #f0f0f0",
      position: "absolute",
      "& .country-list": {
        position: "fixed",
      },
      "& .selected-flag": {
        background: "transparent",
      },
    },
    "& .input_icon__form_box .input_box": {
      width: "68%",
      paddingLeft: "120px",
    },
    "& .input_box": {
      fontSize: "16px",
      background: "rgb(243, 243, 243) none repeat scroll 0% 0%",
      borderRadius: "8px",
      outline: "none",
      padding: "10px 12px",
      border: "none",
    },
    "& .input_box::placeholder": {
      borderBottom: "none",
      textDecoration: "none !important",
    },
  },
});

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(InvitationReport)));
// Customizable Area End