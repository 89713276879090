// Customizable Area Start
import React from "react";
import { Box, Checkbox } from "@material-ui/core";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";

type StyleObject = {
  [key: string]: {
    [key: string]: string | number;
  };
};

const PublishCard = ({ t, label, value, ...props }: any) => {
  return (
    <Box style={webStyle.publishBox}>
      <Box style={{ width: "82%" }}>
        <label className="checkboxLabel bold-text">{t(label)}</label>
        <p style={webStyle.valueP} title={value || "N/a"}>
          {value || "N/a"}
        </p>
      </Box>
      <Checkbox
        className="radio-toolbar"
        icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
        checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />}
        {...props}
      />
    </Box>
  );
};

const webStyle: StyleObject = {
  publishBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    borderRadius: "8px",
    padding: "15px",
    border: "1px solid #e4e4e4",
  },
  valueP: {
    marginTop: "8px",
    width: "-webkit-fill-available",
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
  },
};

export default PublishCard;
// Customizable Area End
