import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isRejectDialogOpen: boolean;
  isAcceptDialogOpen:boolean;
  pendingJoinRequestList:any;
  filter:any;
  complexBuildingFilterList:any[];
  countryList:any[];
  complexList:any[];
  companyList:any[];
  unitList:any[];
  buildingsList:any[];
  cityList:any[];
  userList:any[];
  pendingJoinRequest:any;

  complexId: string | number;
  complexType: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEPendingJoinRequestController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPendingJoinRequestList:any;
  getInvitationResponse:any;
  GetCountryList:any;
  GetComplexListId:any;
  GetCityList:any;
  GetAllUnitDataCallId:any;
  GetCompanyListId:any
  GetAllBuildingsDataCallId:any;
  showAllUserAPICallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      loading: false,
      isRejectDialogOpen: false,
      isAcceptDialogOpen:false,
      pendingJoinRequestList:[],
      filter: {
        selectedType:"",
        selectedCountry:"",
        selectedCity:"",
        selectedComplex:"",
        selectedBuilding:"",
        selectedCompany:"",
        selectedIndividualBuilding:"",
        selectedUnit:"",
      },
      countryList:[],
      complexList:[],
      unitList:[],
      companyList:[],
      buildingsList:[],
      cityList:[],
      userList:[],
      pendingJoinRequest:{
        id:"",
        name:"",
        unti:""
      },
      complexBuildingFilterList: [
        {
          label: "Complex",
          value: 'complex'
        },
        {
          label: "Indiviual Building",
          value: 'indiviualBuilding'
        },
      ],

      complexId: "",
      complexType: "",
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.getPendingJoinRequestList:
          this.handlegetPendingJoinRequestList(responseJson);
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
          break;
        case this.getInvitationResponse:
          this.handlegetPendingJoinStatus(responseJson);
          break;
        case this.GetCountryList:
          this.handleCountryResponse(responseJson);
          break;
        case this.GetCityList:
          this.handleCityResponse(responseJson)
          break;
        case this.GetComplexListId:
          this.handleComplexList(responseJson);
          break;
        case this.GetAllUnitDataCallId:
            this.handleUnitList(responseJson)
            break;
        case this.GetAllBuildingsDataCallId:
            this.handleBuildingList(responseJson)
            break;
        case this.GetCompanyListId:
            this.handleGetCompanyList(responseJson);
            break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    const complex_type = this.props.navigation.getParam("complex");
    this.setState({ complexId: complex_id, complexType: complex_type }, () => {
      this.getPendingList();
      this.getAllUser();
      if(complex_type === "Complex") {
        this.getBuildingsDataList(complex_id);
      } else {
        this.getUnitDataList(complex_id, "");
      }
    });
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getUserTypeEndPoint
    });
  };
  handleGetCompanyList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ companyList: responseJson.data.map((item: any) => 
        (
          { label: item?.attributes?.company_name,
           value: item?.id
          }
        ))}
      )
    }
  }
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({  userList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.attributes.name }))})
    }
  }
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data.map((item: any) =>
         ({ label: item.attributes.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countryList: responseJson?.data?.countries.map((item: any) => 
        ({ label: item, value: item })) });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ cityList: responseJson?.data?.cities.map((item: any) => 
        ({ label: item, value: item })) });
    }
  };
  handleUnitList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ unitList: responseJson.data.map((item: any) => ({
        label: item.attributes.apartment_name,
        value: item.id,
      })
      )});
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  getCompanyListData=async()=>{
    this.GetCompanyListId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getCompanyListEndPoint
    });
  }
  getComplexList = async (city:any ) => {
    this.GetComplexListId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint+`?city=${city}`
    });
  };
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getData = (value: any) => (value !== undefined && value !== "" && value !== "all" && value !== "undefined") ? `=${value}` : "";

  getPendingList = () => {
    this.setState({ loading: true }, async () => {
      this.getPendingJoinRequestList = await apiCall({
        contentType: configJSON.sellerDetailsApiContentType,
        method: configJSON.getSellersAPIMethod,
        endPoint: configJSON.getPendingJoinRequestListEndPoint + `?role${this.getData(this.state.filter.selectedType)}&company${this.getData(this.state.filter.selectedCompany)}&country${this.getData(this.state.filter.selectedCountry)}&city${this.getData(this.state.filter.selectedCity)}&complex${this.getData(this.state.filter.selectedComplex || this.state.complexId)}&building_management_id${this.getData(this.state.filter.selectedBuilding)}&apartment_management_id${this.getData(this.state.filter.selectedUnit)}`,
      });
    })
  }
  getCityList = async (country: any) => {
    this.GetCityList = await apiCall({
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`,
      method: configJSON.getSellersAPIMethod,
    })
  }
  getUnitDataList = async (id:any, bId: any) => {
    this.GetAllUnitDataCallId= await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${id}&building_management_id=${bId}`,
    });
  };
  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
      method: configJSON.getSellersAPIMethod,
    });
  };
 
  handleChangeValue=(event:any,keyState:any)=>{
    if (keyState === "selectedCountry") {
      this.getCityList(event.target.value)
    } else if (keyState === "selectedCity") {
      this.getComplexList(event.target.value)
    } else if (keyState === "selectedComplex") {
      const id=event.target.value;
      this.getBuildingsDataList(id);
    } else if (keyState === "selectedBuilding") {
      const value = event.target.value;
      this.getUnitDataList(this.state.complexId, value);
    }
    
    this.setState({ filter: { ...this.state.filter, [keyState]:  event.target.value} })
  }

  handleAccept=(value?:any)=>{
    this.setState({ isAcceptDialogOpen: !this.state.isAcceptDialogOpen, pendingJoinRequest:{
      id:value?.id,
      name: value?.attributes?.name,
      unit: value?.attributes?.unit_number
    } });
  }
  handleReject = (value?:any) => {
    this.setState({ isRejectDialogOpen: !this.state.isRejectDialogOpen, pendingJoinRequest:{
      id:value?.id,
      name: value?.attributes?.name,
      unit: value?.attributes?.unit_number
    } });
  };
  handleSubmit=async(status:any, id:any)=>{
    this.getInvitationResponse = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.acceptRejectJoineRequestEndPoint+`?id=${id}&status=${status}`,
    });
  }
  handlegetPendingJoinStatus=(responseJson:any)=>{
    this.setState({isAcceptDialogOpen:false,isRejectDialogOpen:false},()=>{
      this.getPendingList()
    })
  }
  handlegetPendingJoinRequestList=(responseJson:any)=>{
    this.setState({loading:false})
    if (responseJson) {
      this.setState({
        pendingJoinRequestList: responseJson.data ||[]
      })
    }
  }
  // Customizable Area End
}
