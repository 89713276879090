import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    Link,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { Invitations, Lease, Owner, Property, Subscriptions, login} from "./assets";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
// Customizable Area End

import ReportsController, {
    Props,
} from "./ReportsController";

// Customizable Area Start
export class Reports extends ReportsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
// Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        const { t }: any = this.props;  
        const { cardDetails }  = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
                <CEHeaderComponentWeb>
                    <BoxView style={addAnotherUnit.container}>
                    <BoxView style={{ display: "flex" }}>
                        <Typography variant="body1">
                            
                            {this.state.topMenu.map((tag, index) => {
                                if (index === this.state.topMenu.length - 1) {
                                    return <span style={{cursor: "pointer",color: "#2B6FED", }}>{t(tag)}</span>
                                } else {
                                    return <span style={{ cursor: "pointer" }} data-test-id="menuBarId">{`${t(tag)} / `}</span>
                                }
                            })}
                        </Typography>
                    </BoxView>
                    <br />
                        <Typography variant='h4' className="bold-text">{t("Reports")}</Typography>
                        <Grid container xs={12} spacing={2}>
                            <Grid item>
                                <Select
                                    displayEmpty
                                    disableUnderline
                                    style={addAnotherUnit.selectmenu}
                                    id="select-city1"
                                    className="select-input selectFloorChairmanInvoices"
                                    data-test-id="buildingsdropId"
                                    value={this.state.individualbuilding}
                                    onChange={(event) => this.handleCountryFilterChange(event)}
                                >
                                    <MenuItem disabled value="" id="selectStatusBudgetReport">
                                        {t("Complex / Individual Buildings")}
                                    </MenuItem>
                                    <MenuItem value="Complex" id="selectStatusBudgetReport">
                                        {t("Complex")}
                                    </MenuItem>
                                    <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                        {t("Individual building")}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <BoxView>
                                    <Select
                                        displayEmpty
                                        disableUnderline
                                        style={addAnotherUnit.selectmenu}
                                        data-test-id="selectCountryId"
                                        placeholder="Select Country"
                                        value={this.state.selectedCountry}
                                        className="select-input selectFloorChairmanInvoices"
                                        onChange={(event) => this.handleSelectCountryChange(event)}
                                        id="select-Country"
                                    >
                                        <MenuItem
                                            value=""
                                            disabled
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Select Country")}
                                        </MenuItem>
                                        <MenuItem
                                            value="All"
                                            id="selectStatusBudgetReportAll"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                        {this.state.selectCountry?.length > 0 &&
                                            this.state.selectCountry?.map((item: any) => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </BoxView>
                            </Grid>
                            <Grid item>
                                <Select displayEmpty
                                    disableUnderline
                                    data-test-id="selectCityId"
                                    value={this.state.selectedCity}
                                    style={addAnotherUnit.selectmenu}
                                    className="select-input selectUnitChairmanInvoices complexS"
                                    onChange={(event) => this.handleSelectCityChange(event)}
                                    id="select-city1"
                                >
                                    <MenuItem
                                        id="selectStatusBudgetReport"
                                        value=""
                                        disabled
                                    >
                                        {t("Select City")}
                                    </MenuItem>
                                    <MenuItem
                                            id="selectStatusBudgetReportAll" value="All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectCity?.length > 0 &&
                                        this.state.selectCity?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>

                            <Grid item>
                                <Select displayEmpty
                                    disableUnderline
                                    value={this.state.selectedComplex}
                                    onChange={(event) => this.handleSelectComplexChange(event)}
                                    id="select-city1"
                                    style={addAnotherUnit.selectmenu}
                                    className="select-input selectUnitChairmanInvoices complexS"
                                    data-test-id="selectComplexId"
                                >
                                    <MenuItem
                                        value=""
                                        id="selectStatusBudgetReport"
                                        disabled
                                    >
                                        {t("Select Complex")}
                                    </MenuItem>
                                    <MenuItem value="All" id="selectStatusBudgetReportAll"
                                            
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectComplex?.length > 0 &&
                                        this.state.selectComplex?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>
                            <Grid item>
                                <Select displayEmpty
                                    id="selectBuilding"
                                    disableUnderline
                                    className="select-input invoiceTypeFromChairmanInvoices"
                                    value={this.state.selectedBuilding}
                                    data-test-id="slctBuildingId"
                                    onChange={(event) => this.handleSelectBuildingChange(event)}
                                    style={addAnotherUnit.selectmenu}
                                >
                                    <MenuItem
                                        id="selectStatusBudgetReport"
                                        value=""
                                        disabled
                                    >
                                        {t("Select Building")}
                                    </MenuItem>
                                    <MenuItem
                                            value = "All"
                                        >
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.selectBuilding?.length > 0 &&
                                        this.state.selectBuilding?.map((item: any) => {
                                            return (
                                                <MenuItem key={item} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>

                            <Grid item>
                                <Select displayEmpty
                                    disableUnderline
                                    id="selectBuilding"
                                    value={this.state.selectSubscription}
                                    className="select-input invoiceTypeFromChairmanInvoices"
                                    style={addAnotherUnit.selectmenu}
                                    data-test-id="SubscriptionId"
                                    onChange={(event) => this.handleUserrrSubscription(event)}
                                >
                                    <MenuItem
                                        value=""
                                        disabled
                                        id="selectStatusBudgetReport"
                                    >
                                        {t("Select Subscription Type")}
                                    </MenuItem>
                                    <MenuItem id="selectStatusBudgetReportAll" value="All">
                                            {t("All")}
                                        </MenuItem>
                                    {this.state.userSubscription?.length > 0 &&
                                        this.state.userSubscription?.map((item: any) => {
                                            return (
                                                <MenuItem key={item.type} value={item.id}>
                                                    {item.attributes.title}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Grid>
                        </Grid>

                        <BoxView>
                            <Button
                                variant="contained"
                                className="btnMy"
                                data-test-id="searchButtonId"
                                style={addAnotherUnit.mybtn}
                                onClick={() => this.handleSearch()}

                            >
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                {t("Search")}
                            </Button>
                        </BoxView>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <BoxView
                                    style={addAnotherUnit.Box}
                                    data-test-id="userlogin"
                                    onClick={() => this.handleLogin()}
                                >
                                    <img src={login} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Login Reports")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.login_report}</Typography>
                                    </BoxView>
                                </BoxView>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                            <Link href="/CompanyEmployee/OwnersReports">
                                <BoxView
                                    style={addAnotherUnit.Box}
                                >
                                    <img src={Owner} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Owners' Reports")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.owners_reports}</Typography>
                                    </BoxView>
                                </BoxView>
                            </Link>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <BoxView
                                    style={addAnotherUnit.Box}
                                    onClick={this.handleLeaseReport}
                                    data-testId={"leaseReportBoxTestId"}
                                    id={"leaseReportBoxId"}
                                >
                                    <img src={Lease} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Lease Reports")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.lease_reports}</Typography>
                                    </BoxView>
                                </BoxView>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <Link href="/CompanyEmployee/InvitationReport">
                                    <BoxView
                                        style={addAnotherUnit.Box}
                                    >
                                        <img src={Invitations} />
                                        <BoxView>
                                            <Typography variant='h6' className="bold-text">{t("Invitations Reports")}</Typography>
                                            <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.invitation_reports}</Typography>
                                        </BoxView>
                                    </BoxView>
                                </Link>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                                <Link href="/CompanyEmployee/PropertyManagerReport">
                                <BoxView
                                    style={addAnotherUnit.Box}
                                >
                                    <img src={Property} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Property Managers Reports")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.property_manager_reports}</Typography>
                                    </BoxView>
                                </BoxView>
                                </Link>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4} >
                            <Link href="/CompanyEmployee/SubscriptionReports">
                                <BoxView
                                    style={addAnotherUnit.Box}
                                >
                                    <img src={Subscriptions} />
                                    <BoxView>
                                        <Typography variant='h6' className="bold-text">{t("Subscriptions Reports")}</Typography>
                                        <Typography style={addAnotherUnit.lable} className="bold-text">{cardDetails?.subscription_reports}</Typography>
                                    </BoxView>
                                </BoxView>
                                </Link>
                            </Grid>
                        </Grid>
                    </BoxView>
                </CEHeaderComponentWeb>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const addAnotherUnit = {
    container: {
        padding: "40px",
    },
    selectmenu: {
        border: "1px solid #F0F0F0",
        padding: "7px",
        background: "#fff",
        borderRadius: "15px",
        borderBottom: "none",
        width: "100%",
        gap: '10px',
        marginTop: "10px"
    },
    titleBox: {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    ButtonBox: {
        display:"flex",
        justifyContent:"flex-end", 
        padding:20,
        gap:15
    },
    cancleButton: {
        color:"#2B6FED",
        border:"1px solid #2B6FED", 
        padding:"5px 40px", 
        fontWeight:900
    },
    confirmButton: {
        color:"#fff",
        background:"#2B6FED", 
        padding:"5px 40px", 
        fontWeight:900
    },
    inputType : {
        border: "1px solid darkgray",
        padding: "7px",
        background: "#fff",
        borderRadius: "15px",
        width: "100%",
        gap: '10px',
        marginTop: "10px",
    },
    mybtn: {
        backgroundColor: "#2D6EED",
        color: "white",
        padding: "12px 16px",
        textTransform: "capitalize" as "capitalize",
        marginTop: "15px"
    },
    Box: {
        height: "200px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        paddingLeft: "30px",
        paddingRight: "30px",
        alignItems: "flex-start",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        marginTop: "25px",
        cursor:"pointer"    
    },
    lable: {
        borderRadius: "20px",
        padding: "5px 15px",
        color: '#EBA370',
        backgroundColor: "rgba(252, 132, 52, 0.1)",
        fontWeight: 700,
        width: "fit-content",
        marginTop: "5px"
    },
    searchButtons: {
        border: "1px solid #f0f0f0",
        background: "white",
        borderRadius: "5px",
    },
    main: {
        backgroundColor: "#fff",
        padding: "15px", height: "85%"
    },
    heading: {
        display: "flex",
        justifyContent: "space-between"
    },
    detail: {
        textAlign: "center" as "center",
        marginTop: "35px",
        backgroundColor: "rgba(221, 221, 221, 0.38)",
        color: "#191E26",
        padding: "10px 50px", borderRadius: "8px"
    },
    userData: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "83%"
    },
    tblData: {
        marginTop: "20px",
        boxShadow: "none"
    },
    tblpage: {
        display: "flex",
        justifyContent: "space-between"
    },
    selectTag: {
        marginTop: "35px",
        background: "#2B6FED",
        padding: "10px 20px",
        borderRadius: "20px",
        color: "#fff",
        cursor: "pointer"
    },
    unSelectTage: {
        marginTop: "35px",
        background: "#EEF0F8",
        padding: "10px 20px",
        borderRadius: "20px",
        color: "#8D8F98",
        cursor: "pointer"
    },
    downloadReports : {
        background: "#FC8434", 
        color: "#fff", 
        padding: "10px 20px", 
        borderRadius: "8px"
    }
}
export default withTranslation()(
    //@ts-ignore
    (withRouter(Reports))
);
// Customizable Area End
