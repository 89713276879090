// Customizable Area Start
import React from "react";
import { Container, Link, Typography, withStyles, Box, Button } from "@material-ui/core";
import ComplexDocumentViewController, { Props } from "./ComplexDocumentViewController.web";
import "@szhsin/react-menu/dist/core.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { DocumentReportStyleWeb } from "../../../Notes/src/DocumentReportStyle.web";

class ComplexDocumentView extends ComplexDocumentViewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.documentChairman}>
            <Box className="navigation">
              <Box>
                <Typography variant="body1">
                  <Link href="/CompanyEmployee/complexlisting">
                    {t("Complex")} / {t("Buildings")} /
                  </Link>{" "}
                  <Link href={`/CompanyEmployee/${this.state.details}/${this.state.complexId}`}>
                    {t("Complex Details")}
                  </Link>{" "}
                  /{" "}
                  <Link href={`/CompanyEmployee/${this.state.details}/${this.state.complexId}/Document/${this.state.docName}`}>
                    {t(this.state.docName == "Building_Plan" ? "Building Plans" : this.state.docName)}
                  </Link>{" "}
                  /{" "}
                  <Box component="span" style={{ color: "blue", wordBreak: "break-all" }}>
                    {this.state.title}
                  </Box>
                </Typography>
                <Box className="top-heading">
                  <Typography variant="h5" className="sub-heading bold-text">
                    {this.state.title}
                  </Typography>
                  <Link download={this.state.title} href={this.state.file} target="_blank">
                    <Button variant="contained">{t("Download")}</Button>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className="document-box document-card">
              {this.state.file && this.state.file.includes(".pdf") ? (
                <object data={`${this.state.file}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}></object>
              ) : (
                <div>{t("To view the document, Please download the document.")}</div>
              )}
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(withRouter(ComplexDocumentView)));
// Customizable Area End
