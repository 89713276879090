// Customizable Area Start
import React from "react";
import ComplexBuildingMainBlockController, { Props } from "./ComplexBuildingMainBlockController.web";
import "../../../dashboard/src/Company Employee/global.css";
import CompEmpComplexBuildingBlock from "./CompEmpComplexBuildingBlock.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { withRouter } from "react-router-dom";

class ComplexBuildingMainBlock extends ComplexBuildingMainBlockController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <CompEmpComplexBuildingBlock navigation={navigation} id={""} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(ComplexBuildingMainBlock);
// Customizable Area End
