import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
  navigation: any;
  id: string;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  match?: any;
  location?: any;
  history?: any;
}

interface S {}

interface SS {
  id: any;
}

export default class ComplexMainBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    window.history.pushState(null, document.title, window.location.href);
  }
}
// Customizable Area End
