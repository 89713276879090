// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import CEPropertyManagerReportDetailsController, { Props } from "./CEPropertyManagerReportDetailsController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CEReportStyle } from "./CEPropertyManagerReport.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import Pagination from "@material-ui/lab/Pagination";
import RoomIcon from "@material-ui/icons/Room";
import ListIcon from "@material-ui/icons/List";
// @ts-ignore
import { baseURL } from "../../../../framework/src/config.js";
import { downloadFileFromURL } from "../../../../components/src/APICallComponent/FileDownload.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import GoogleMapReact from "google-map-react";
// Customizable Area End

class CEPropertyManagerReportDetails extends CEPropertyManagerReportDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <CEHeaderComponentWeb>
          <Container className={classes.PropertyManager}>
            <Box className="navigation">
              <Typography variant="body1" className="">
                <Link href="/CompanyEmployee/dashboard">
                  {t("Documents & Reports")} / {t("Reports")} /
                </Link>{" "}
                <Link href="/CompanyEmployee/PropertyManagerReport">{t("Property Managers Reports")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {this.state.name}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {this.state.name}
                </Typography>
                {localStorage.getItem("role") === "account_manager" && (
                  <Box className="create-meeting">
                    <CommonButton
                      onClick={() =>
                        downloadFileFromURL(
                          `${baseURL}/bx_block_report/property_manager_report/download_manager_report.pdf?listing=false&manager_id=${
                            this.state.managerId
                          }`,
                          "report"
                        )
                      }
                      t={t}
                      label="Download Report"
                      color="orange"
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="top-bar-filter">
              <Box className="filter-head-box">
                <FilterSelect
                  value={this.state.filter.isComplex}
                  onChange={(e: any) => this.handleUnitFilterChange(e, "isComplex")}
                  t={t}
                  language={language}
                  option={[
                    { label: "All", value: 0 },
                    { label: "Complex", value: 1 },
                    { label: "Individual Building", value: 2 },
                  ]}
                  style={{ fontWeight: "bold", color: "black" }}
                />
                <FilterSelect
                  option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                  onChange={(e: any) => this.handleUnitFilterChange(e, "complex")}
                  t={t}
                  value={this.state.filter.complex}
                  language={language}
                  label={this.state.filter.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
                />
                {this.state.filter.isComplex !== 2 && (
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Select Building"
                    value={this.state.filter.building}
                    option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                    onChange={(e: any) => this.handleUnitFilterChange(e, "building")}
                  />
                )}
                <FilterSelect
                  t={t}
                  value={this.state.filter.accountManager}
                  language={language}
                  onChange={(e: any) => this.handleUnitFilterChange(e, "accountManager")}
                  label="Select Account Manager"
                  option={[{ label: "All", value: "all" }, ...this.state.accountManagerList]}
                />
                <Button
                  className="action-search-btn manager-detail-filter-btn"
                  startIcon={<SearchIcon />}
                  onClick={this.getManagerDetailsById}
                >
                  {t("Search")}
                </Button>
                {localStorage.getItem("role") === "sales_manager" && (
                  <Box
                    onClick={() =>
                      downloadFileFromURL(
                        `${baseURL}/bx_block_report/property_manager_report/download_manager_report.pdf?listing=false&manager_id=${
                          this.state.managerId
                        }`,
                        "report"
                      )
                    }
                    className="create-meeting"
                  >
                    <CommonButton t={t} label="Download Report" color="orange" />
                  </Box>
                )}
              </Box>
            </Box>

            <Grid className="table-box-card">
              <Grid item sm={12} md={12} xs={12}>
                <Box className="table-top">
                  <Box className="table-main-heading">
                    <Box className="map-list-icon-box">
                      <ListIcon
                        className={this.state.isListVisible ? "map-list-active" : "map-list-inactive"}
                        onClick={this.handleVisibleList}
                      />
                      <RoomIcon
                        className={this.state.isListVisible ? "map-list-inactive" : "map-list-active"}
                        onClick={this.handleVisibleMap}
                      />
                    </Box>
                    <h4 className="bold-text">
                      {t("Unit managed by")}: {this.state.name}
                    </h4>
                  </Box>
                  <Box className="filter-head-box" style={{ margin: "0" }}>
                    <FilterSelect
                      t={t}
                      language={language}
                      label="Sort By"
                      option={[{ label: "Rent", value: "rent" }]}
                      onChange={(e: any) =>
                        this.setState({ sortBy: e.target.value }, () => this.getManagerDetailsById())
                      }
                    />
                    <SearchInput
                      t={t}
                      language={language}
                      label="Search by owner"
                      onChange={(e: any) =>
                        this.setState({ search: e.target.value }, () => this.getManagerDetailsById())
                      }
                    />
                  </Box>
                </Box>
                {this.state.isListVisible ? (
                  <>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Complex Name")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Buildings")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Owner Name")}
                          </TableCell>
                          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                            {t("Unit Number")}
                          </TableCell>
                          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                            {t("Account Manager")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Rent")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.pmUnitList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={5}>{t("No Data Available!")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.pmUnitList.map((unit: any, index: number) => {
                          return (
                            <TableRow key={unit.id}>
                              <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="ellipse-one"
                                title={unit.attributes.complex_name}
                              >
                                {unit.attributes.complex_name}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                title={unit.attributes.building_name}
                                className="ellipse-one"
                              >
                                {unit.attributes.building_name}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {unit.attributes.owner_name}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {unit.attributes.unit_list.map((data: any) => data.apartment_name).toString()}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {unit.attributes.account_manager}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {unit.attributes.currency} {unit.attributes.total_rents}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className={LanguageAttributeHandler(language, "meeting-menu", "")}
                              >
                                <Menu
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                >
                                  <Link href={`/CompanyEmployee/UnitDetails/${unit.id}`}>
                                    <MenuItem>{t("View Unit Details")}</MenuItem>
                                  </Link>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <p>
                        {t("Showing")} <span className="current-page">{this.state.pmUnitList.length}</span> {t("of")}{" "}
                        <span className="total-page">
                          {this.state.pagination ? this.state.pagination.total_count : 0}
                        </span>{" "}
                        {t("results")}
                      </p>
                      {this.state.pagination && (
                        <Pagination
                          page={this.state.pagination.current_page}
                          count={this.state.pagination.total_pages}
                          siblingCount={2}
                          shape="rounded"
                          onChange={this.handleUnitPagination}
                          variant="outlined"
                        />
                      )}
                    </Box>
                  </>
                ) : (
                  <Box className="report-map-box">
                    <GoogleMapReact
                      key={new Date().getTime()}
                      defaultCenter={{
                        lat: 12,
                        lng: 12,
                      }}
                      defaultZoom={10}
                    >
                      {this.state.pmUnitList.map((unit: any, index: number) => {
                        return (
                          <>
                            <IconButton>
                              <RoomIcon style={{ color: "#DD946A" }} />
                            </IconButton>
                            <Box
                              style={{
                                width: "200px",
                                background: "white",
                                textAlign: "center",
                                borderRadius: "8px",
                                padding: "8px",
                                fontSize: "14px",
                              }}
                              // @ts-ignore
                              lat={unit.attributes.latitude}
                              lng={unit.attributes.longitude}
                            >
                              <p>{unit.attributes.building_name}</p>
                              <p>{unit.attributes.owner_name}</p>
                              {unit.attributes.unit_list.map((data: any) => {
                                return <p style={{ fontWeight: "bold" }}>Unit {data.apartment_name}</p>;
                              })}
                            </Box>
                          </>
                        );
                      })}
                    </GoogleMapReact>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(CEReportStyle)(withRouter(CEPropertyManagerReportDetails)));
// Customizable Area End
