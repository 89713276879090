import React from "react";
// Customizable Area Start
import {
    Table,
    TableBody,
    TableCell,
    TableContainer as TableDataContainer,
    TableHead,
    TableRow,
    Box,
    Breadcrumbs,
    Button,
    Paper,
    MenuItem,
    Select,
    Typography,
    FormControl,
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    Popover,
    Modal,
    Grid,
    InputLabel,
    TextareaAutosize,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Pagination } from "@material-ui/lab";
import { CompanyItem, TFunction, AccountManagerListItem, SalesManagerListItem,I18nInterface } from './MyTeamController.web';
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import AlertError from "../../../components/src/AlertError.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import { Link as NavLink } from "react-router-dom";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";


// Customizable Area End

import MyTeamController, { Props, } from "./MyTeamController.web";

class MyTeam extends MyTeamController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCompaniesData = () => {
        const open = Boolean(this.state.openMenu);
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const pageData=this.state.page as number
        return (
            <TableWrapper>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb"  dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="navigateLink" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateLink" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="tabActive" data-testId={"myTeamHeadingTestId"}>{t("Total Companies")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Companies")}</Typography>
                <Box className="headerContainer" dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"myTeamCountrySelectTestId"}
                            onChange={this.handleCountryChange}
                            displayEmpty
                            value={this.state.country}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return (<MenuItem value={country} key={countryIndex} data-testId={`countryItemTestId${countryIndex}`}>{country}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            id="demo-simple-select-outlined"
                            IconComponent={ExpandMoreIcon}
                            labelId="complexLabel"
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"myTeamCitySelectTestId"}
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return (<MenuItem value={city} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{city}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            displayEmpty
                            value={this.state.status}
                            data-testId={"myTeamStatusSelectTestId"}
                            onChange={this.handleStatusChange}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select Status")}</MenuItem>
                            <MenuItem value={"pending_approval"}>{t("Pending")}</MenuItem>
                            <MenuItem value={"approved"}>{t("Approved")}</MenuItem>
                            <MenuItem value={"rejected"}>{t("Rejected")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Button
                        className="searchButton"
                        variant="contained"
                        onClick={()=>this.getCompanyList("1")}
                        startIcon={<SearchIcon />}
                        color="primary"
                        data-testId={"cooSearchBtnTestId"}
                        dir={languageCondition(language, "rtl", "ltr")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper className="listContainer">
                    <Box className="listHeader">
                        <Typography className="listPara" dir={languageCondition(language, "rtl", "ltr")}>
                            {t("List of managed real estate companies")}
                        </Typography>
                        <SearchField
                            placeholder={t("Search by company name")}
                            variant="outlined"
                            value={this.state.searchText}
                            onChange={this.handleSearchInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="searchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                            data-testId={"searchFieldTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableDataContainer>
                            <Table dir={languageCondition(language, "rtl", "ltr")}>
                                <TableHead>
                                    <TableRow className="tableCellHeading">
                                        <TableCell className="tableCellHeading" align="left">{t("#")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("Company")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("Regd. No.")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("City")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("Location")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("Status")}</TableCell>
                                        <TableCell className="tableCellHeading" align="left">{t("Managed By")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((companyItem: CompanyItem, nameListTypeIndex: number) => (
                                       
                                        <TableRow>
                                            <TableCell className="tableCellHeading" align="left">{(pageData - 1) * 10 + nameListTypeIndex + 1}</TableCell>
                                            <TableCell component="th" align="left" scope="row" data-testId={`tableCompanyNameTestId${nameListTypeIndex}`}>
                                                {companyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell align="left" component="p">{companyItem.id}</TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.city}</TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.country}</TableCell>
                                            <TableCell align="left" component="p">
                                                <Button className={companyItem.attributes.status === "approved"? "statusField": "errorMsg"}>{companyItem.attributes.status}</Button>
                                            </TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.managed_by === "" ? "-" : companyItem.attributes.managed_by}</TableCell>
                                            <TableCell align="left" component="p">
                                                <IconButton onClick={this.handleOpenMenu} data-testId={`myteamTableMenuTestId${nameListTypeIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
<Paper>
                                                    <Popover
                                                        open={open}
                                                        anchorEl={this.state.openMenu}
                                                        data-testId={`popoverCloseTestId${nameListTypeIndex}`}
                                                        onClose={this.handleCloseMenu}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                        anchorOrigin={{
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                marginLeft: "20px",
                                                            },
                                                        }}
                                                        elevation={1}
                                                    >
                                                    <NavLink to={`/CompanyEmployee/realestate-company-details/${companyItem.id}`}>
                                                        <MenuItem dense data-testId={`tableMenuItemTestId${nameListTypeIndex}`}>
                                                            {t("View Details")}
                                                        </MenuItem>
                                                    </NavLink>
                                                    {companyItem.attributes.status === "approved" &&
                                                        <MenuItem dense onClick={() => this.handleReassign(companyItem.id)} data-testId={`cooReassignBtn${nameListTypeIndex}`}>{t("Reassign")}</MenuItem>
                                                    }
                                                </Popover>
</Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationData dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} data-testId={"paginationTestId"} />
                            </PaginationData>
                        </TableDataContainer>
                    </Box>
                </Paper>
            </TableWrapper>
        );
    };

    renderRealEstateData = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <RealEstateWrapper  dir={languageCondition(language, "rtl", "ltr")}>
                <Box>
                    <Breadcrumbs>
                        <Typography className="navigateRoute" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateRoute" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography color="primary" data-testId={"salesManagerHeadingTestId"}>{t("Companies Assigned to Me")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="realEstateHeading">
                    {t("Real Estate Companies I am Managing")}
                </Typography>
                <Box className="selectContainer"  dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <Paper>
                            <SelectData
                             dir={languageCondition(language, "rtl", "ltr")}
                                IconComponent={ExpandMoreIcon}
                                labelId="complexLabel"
                                id="demo-simple-select-outlined"
                                data-testId={"myTeamCountrySelectTestId"}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                displayEmpty
                                onChange={this.handleCountryChange}
                                value={this.state.country}
                            >
                                <MenuItem value="">{t("Select Country")}</MenuItem>
                                {this.state.countryList.map((country: string, countryIndex: number) => {
                                    return (<MenuItem value={country} key={countryIndex} data-testId={`countryItemTestId${countryIndex}`}>{country}</MenuItem>)
                                })}
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <Paper>
                            <SelectData
                             dir={languageCondition(language, "rtl", "ltr")}
                                labelId="complexLabel"
                                id="demo-simple-select-outlined"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                data-testId={"myTeamCitySelectTestId"}
                                value={this.state.city}
                                onChange={this.handleCityChange}
                                disabled={this.state.country ? false : true}
                            >
                                <MenuItem value="">{t("Select City")}</MenuItem>
                                {this.state.cityList.map((city: string, cityindex: number) => {
                                    return (<MenuItem value={city} key={cityindex} data-testId={`cityItemTestId${cityindex}`}>{city}</MenuItem>)
                                })}
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <Paper>
                            <SelectData
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                IconComponent={ExpandMoreIcon}
                                displayEmpty
                                data-testId={"myTeamStatusSelectTestId"}
                                value={this.state.status}
                                onChange={this.handleStatusChange}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="">{t("Select Status")}</MenuItem>
                                <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                                <MenuItem value={"Closed"}>{t("Closed")}</MenuItem>
                                <MenuItem value={"Terminated"}>{t("Terminated")}</MenuItem>
                                <MenuItem value={"Draft"}>{t("Draft")}</MenuItem>
                                <MenuItem value={"Active"}>{t("Active")}</MenuItem>
                                <MenuItem value={"Start"}>{t("Start")}</MenuItem>
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <Button
                        className="searchBtn"
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={() => this.getCompanyList("1")}
                        data-testId={"salesSearchBtnTestId"}
                        dir={languageCondition(language, "rtl", "ltr")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper className="listContainer">
                    <Box className="listHeading">
                        <Typography className="listText">
                            {t("List of managed real estate companies")}
                        </Typography>
                        <SearchTextField
                            variant="outlined"
                            placeholder={t("Search by company name")}
                            dir={languageCondition(language, "rtl", "ltr")}
                            value={this.state.searchText}
                            onChange={this.handleSearchInputChange}
                            data-testId={"searchFieldTestId"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="searchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableDataContainer>
                            <Table dir={languageCondition(language, "rtl", "ltr")}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeader">{t("Company")}</TableCell>
                                        <TableCell className="tableHeader">{t("Regd. No.")}</TableCell>
                                        <TableCell className="tableHeader">{t("City")}</TableCell>
                                        <TableCell className="tableHeader">{t("Location")}</TableCell>
                                        <TableCell className="tableHeader">{t("Status")}</TableCell>
                                        <TableCell className="tableHeader">{t("Managed By")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((CompanyItem: CompanyItem, companyItemIndex: number) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row" data-testId={`tableCompanyNameTestId${companyItemIndex}`}>
                                                {CompanyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell>{CompanyItem.id}</TableCell>
                                            <TableCell>{CompanyItem.attributes.city}</TableCell>
                                            <TableCell>{CompanyItem.attributes.country}</TableCell>
                                            <TableCell>
                                                <Button
                                                    className={
                                                        CompanyItem.type === "approved"
                                                            ? "successMsg"
                                                            : "errorMsg"
                                                    }
                                                >
                                                    {CompanyItem.attributes.status}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{CompanyItem.attributes.managed_by === "" ? "-" : CompanyItem.attributes.managed_by}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <FilterData dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} />
                            </FilterData>
                        </TableDataContainer>
                    </Box>
                </Paper>
            </RealEstateWrapper>
        );
    };

    renderTeamManagingData = () => {
        const openPopover = Boolean(this.state.openPopOver);
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <TeamManagingWrapper>
                <Box>
                    <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")} aria-label="breadcrumb">
                        <Typography className="navigateData" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateData" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography color="primary" data-testId={"accountManagerHeadingTestId"}>
                            {t("Real estate companies my team is managing")}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText"  dir={languageCondition(language, "rtl", "ltr")}>
                    {t("Real estate companies my team is managing")}
                </Typography>
                <Box className="selectContainer"  dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            value={this.state.country}
                            data-testId={"myTeamCountrySelectTestId"}
                            displayEmpty
                            onChange={this.handleCountryChange}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">
                                {t("Select Country")}
                            </MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return (<MenuItem value={country} key={countryIndex} data-testId={`countryItemTestId${countryIndex}`}>{country}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"myTeamCitySelectTestId"}
                            value={this.state.city}
                            onChange={this.handleCityChange}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">
                                {t("Select City")}
                            </MenuItem>
                            {this.state?.cityList?.map((city: string, cityIndex: number) => {
                                return (<MenuItem value={city} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{city}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            value={this.state.status}
                            displayEmpty
                            data-testId={"myTeamStatusSelectTestId"}
                            onChange={this.handleStatusChange}
                            IconComponent={ExpandMoreIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select Status")}</MenuItem>
                            <MenuItem value={"Draft"}>{t("Draft")}</MenuItem>
                            <MenuItem value={"Active"}>{t("Active")}</MenuItem>
                            <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                            <MenuItem value={"Terminated"}>{t("Terminated")}</MenuItem>
                            <MenuItem value={"Start"}>{t("Start")}</MenuItem>
                            <MenuItem value={"Closed"}>{t("Closed")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Button
                        className="searchButton"
                        variant="contained"
                        startIcon={<SearchIcon />}
                        color="primary"
                        onClick={() => this.getCompanyList("1")}
                        data-testId={"accountSearchBtnTestId"}
                        dir={languageCondition(language, "rtl", "ltr")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper className="listContainer" style={{ borderRadius: "10px" }}>
                    <Box className="listHeader"  dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="listPara" dir={languageCondition(language, "rtl", "ltr")}>
                            {t("List of managed real estate companies")}
                        </Typography>
                        <SearchField
                            variant="outlined"
                            placeholder={t("Search by company name")}
                            value={this.state.searchText}
                            onChange={this.handleSearchInputChange}
                            data-testId={"searchFieldTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="searchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableDataContainer>
                            <Table dir={languageCondition(language, "rtl", "ltr")}>
                                <TableHead>
                                    <TableRow className="tableCellHeading">
                                        <TableCell component="p" className="tableCellHeading">{t("#")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("Company")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("Regd. No.")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("City")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("Location")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("Status")}</TableCell>
                                        <TableCell component="p" className="tableCellHeading">{t("Managed By")}</TableCell>
                                        <TableCell component="p"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.length === 0 ?<Typography>{t("No data found")}</Typography> :this.state.companiesData.map((companyItem: CompanyItem, nameListTypeIndex: number) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row" data-testId={`tableCompanyNameTestId${nameListTypeIndex}`}>
                                                {companyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell component="p">{companyItem.id}</TableCell>
                                            <TableCell component="p">{companyItem.attributes.city}</TableCell>
                                            <TableCell component="p">{companyItem.attributes.country}</TableCell>
                                            <TableCell component="p">
                                                <Button
                                                    className={
                                                        companyItem.type === "approved"
                                                            ? "approvedMsg"
                                                            : "pendingMsg"
                                                    }
                                                >
                                                    {companyItem.attributes.status}
                                                </Button>
                                            </TableCell>
                                            <TableCell component="p">{companyItem.attributes.managed_by === "" ? "-" : companyItem.attributes.managed_by}</TableCell>
                                            <TableCell component="p">
                                                <IconButton onClick={this.handleOpenPopover} data-testId={`myteamTableMenuTestId${nameListTypeIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
                                                <Paper>
                                                    <Popover
                                                        open={openPopover}
                                                        anchorEl={this.state.openPopOver}
                                                        data-testId={`popoverCloseTestId${nameListTypeIndex}`}
                                                        onClose={this.handleClosePopover}
                                                        anchorOrigin={{
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                marginLeft: "20px",
                                                            },
                                                        }}
                                                        elevation={1}
                                                    >
                                                        <NavLink to={`/CompanyEmployee/realestate-company-details/${companyItem.id}`}>
                                                            <MenuItem divider data-testId={`tableMenuItemTestId${nameListTypeIndex}`}>
                                                                {t("View Details")}
                                                            </MenuItem>
                                                        </NavLink>
                                                        {companyItem.attributes.status === "approved" &&
                                                            <MenuItem divider onClick={() => this.handleReassign(companyItem.id)} data-testId={`cooReassignBtn${nameListTypeIndex}`}>{t("Reassign")}</MenuItem>
                                                        }
                                                    </Popover>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box className="paginationData" dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule  pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} />
                            </Box>
                        </TableDataContainer>
                    </Box>
                </Paper>
            </TeamManagingWrapper>
        );
    };

    renderReassignModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box>
            <Modal open={this.state.openReassignModal} data-testId={"reassignModal"} onClose={this.handleReassignModal}>
                <MyTeamReassignForm>
                    <Paper elevation={1} className="modalContainer">
                        <Typography align="left" className="reassignModalHeading">{t("Assign to another Manager")}</Typography>
                        <Divider />
                        <Box className="reassignForm">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <InputLabel className="modalInputLabel" id="simple-select-filled-label">{t("Building ID")}</InputLabel>
                                    <TextField variant="outlined" fullWidth value={this.state.modalBuildingId} disabled className="modalInputField" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <InputLabel className="modalInputLabel" id="demo-select-filled-label">{t("Building Name")}</InputLabel>
                                    <TextField variant="outlined" fullWidth value={this.state.modalBuildingName} disabled className="modalInputField" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <InputLabel className="modalInputLabel" id="demo-simple-filled-label">{t("Current Sales Manager")}</InputLabel>
                                    <TextField variant="outlined" fullWidth value={this.state.modalCurrentSalesManager} disabled className="modalInputField" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <InputLabel className="modalInputLabel" id="demo-simple-select-label">{t("Current Account Manager")}</InputLabel>
                                    <TextField variant="outlined" fullWidth value={this.state.modalCurrentAccountManager} disabled className="modalInputField" />
                                </Grid>
                                {
                                    this.state.userRole === "coo" &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <InputLabel className="modalInputLabel" id="demo-simple-select-filled">{t("New Sales Manager")}</InputLabel>
                                        <FormControl variant="outlined" fullWidth className="selectAccountManager">
                                        <InputLabel id="account-manager-label">{t("Select option")}</InputLabel>
                                            <Select
                                                value={this.state.salesManagerAssign}
                                                onChange={this.handleSalesManagerChange}
                                                IconComponent={ExpandMoreIcon}
                                                data-testId={"reassignSalesManagerChangeTestId"}
                                                label= {t("Select option")}
                                            >
                                                <MenuItem value="">{t("Select option")}</MenuItem>
                                                {this.state.salesManagerList.map((sales: SalesManagerListItem, salesIndex: number)=>{
                                                return <MenuItem key={salesIndex} data-testId={`salesListItem${salesIndex}`} value={sales.full_name}>{sales.full_name}</MenuItem>
                                            })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <InputLabel className="modalInputLabel" id="demo-simple-select-filled-label">{t("New Account Manager")}</InputLabel>
                                    <FormControl variant="outlined" fullWidth className="selectAccountManager">
                                    <InputLabel id="account-manager-label">{t("Select option")}</InputLabel>
                                        <Select
                                            onChange={this.handleAccountManagerChange}
                                            value={this.state.accountManagerAssign}
                                            data-testId={"reassignAccountManagerChangeTestId"}
                                            IconComponent={ExpandMoreIcon}
                                            label= {t("Select option")}
                                        >
                                            <MenuItem value="">{t("Select option")}</MenuItem>
                                            {this.state.salesManagerList.map((account: AccountManagerListItem, accountIndex: number)=>{
                                                return <MenuItem key={accountIndex} data-testId={`accountListItem${accountIndex}`} value={account.full_name}>{account.full_name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <InputLabel className="modalInputLabel" id="demo-simple-select-filled-label">{t("Notes")}</InputLabel>
                                    <TextareaAutosize onChange={this.handleNoteChange} value={this.state.noteText} data-testId={"noteInpuTestId"} aria-label="minimum height" minRows={5} placeholder="Notes" className="noteInput" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box className="buttonContainer">
                                        <Button variant="outlined" className="cancelBtn" data-testId={"reassignCanceLButton"} onClick={this.handleReassignModal}>{t("Cancel")}</Button>
                                        <Button variant="contained" className="reassignBtn" data-testId={"reassignBtnTestID"} onClick={this.reassignToAnotherAccountManager}>{t("Reassign")}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </MyTeamReassignForm>
            </Modal>
        </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.state.userRole === "coo" && this.renderCompaniesData()}
                {this.state.userRole === "account_manager" && this.renderRealEstateData()}
                {this.state.userRole === "sales_manager" && this.renderTeamManagingData()}
                {this.renderReassignModal()}
                <AlertError show={this.state.showError} handleClose={this.handleAlertError} message={this.state.showErrorMessage} data-testId={"errorAlertTestId"} />
                <Loader loading={this.state.loader} />
                <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} data-testId={"successAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(MyTeam);
export { MyTeam };
const SelectData = styled(Select)({
    background: "white",
    border:"1px solid #EAEAEA",
    borderRadius:"10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "110px",
    },
});

const TableWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        backgroundColor: "#2B6FED"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateLink": {
        fontWeight: 400,
        fontSize: "16px",
        color: "black",
        cursor: "pointer"
    },
    "& .headerContainer": {
        display: "flex"
    },
    "& .tabActive": {
        color: "#2b6fed",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .textOrange": {
        color: "orange",
        fontWeight: 700
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .companiesText": {
        fontWeight: 700,
        lineHeight: "27px",
        fontSize: "28px",
        marginBottom: "15px",
        marginTop: "15px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableCellHeading": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .statusField": {
        color: "#40CE74",
        backgroundColor: "#E4F8EB",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
        padding: "5px 15px",
    },
    "& .errorMsg": {
        color: "#FC924B",
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
        padding: "5px 15px",
    },
    "& .complexSelect": {
        marginRight: "15px",
        marginLeft: "25px",
    },
});

const PaginationData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff",
    },
});

const SearchField = styled(TextField)({
    width: "290px",
    border:"1px solid #EAEAEA",
    borderRadius:"10px",
    "& .MuiOutlinedInput-input": {
        paddingLeft: "40px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
    "& .MuiOutlinedInput-adornedStart":{
        paddingLeft:"0px"
    }
});

const RealEstateWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchBtn": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
    },
    "& .listHeading": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .selectContainer": {
        display: "flex"
    },
    "& .navigateRoute": {
        color: "black",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .textOrange": {
        fontWeight: 700,
        color: "orange",
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .realEstateHeading": {
        fontSize: "28px",
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "15px",
        lineHeight: "27px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .successMsg": {
        fontWeight: 700,
        backgroundColor: "#E4F8EB",
        color: "#40CE74",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
    "& .errorMsg": {
        color: "#FC924B",
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
    },
    "& .complexSelect": {
        marginRight: "15px",
        marginLeft: "25px",
    },
});

const FilterData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff",
    },
});

const SearchTextField = styled(TextField)({
    width: "260px",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 0px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
});

const TeamManagingWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        backgroundColor: "#2B6FED"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateData": {
        fontWeight: 400,
        fontSize: "16px",
        color: "black",
    },
    "& .companiesText": {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "27px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .selectContainer": {
        display: "flex"
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .textOrange": {
        color: "orange",
        fontWeight: 700
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .listPara": {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27px",
    },
    "& .tableCellHeading": {
        fontWeight: 700,
        fontSize: "18px",
        color: "black",
    },
    "& .approvedMsg": {
        fontWeight: 700,
        backgroundColor: "#E4F8EB",
        color: "#40CE74",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
    "& .pendingMsg": {
        fontWeight: 700,
        backgroundColor: "#FEF0E6",
        color: "#FC924B",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
        "& .complexSelect": {
        marginLeft: "25px",
        marginRight: "15px",
    },
    "& .paginationData": {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px",
    },
});

const PaginationBox = styled(Pagination)({
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff",
    },
});

const MyTeamReassignForm = styled(Box)({
    alignItems: "center",
    display: "flex",
    height: "100vh",
    width: "50%",
    borderRadius: "20px",
    margin: "auto",
    "& .reassignModalHeading": {
        fontWeight: 900,
        fontSize: "25px",
        margin: "20px 0px 20px 20px",
        textAlign: "left",
    },
    "& .reassignForm": {
        padding: "20px"
    },
    "& .MuiDivider-root": {
        width: "100%"
    },
    "& .modalInputField": {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
    },
    "& .noteInput": {
        padding: "10px",
        width: "100%",
        backgroundColor: "#f0f0f0",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "10px",
    },
    "& .selectAccountManager": {
        borderRadius: "10px",
        backgroundColor: "#f0f0f0",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .modalInputLabel": {
        marginBottom: "10px",
        color: "black"
    },
    "& .modalContainer": {
        overflow:"scroll",
        borderRadius: "20px",
        height:"90%",
    },
    "& .buttonContainer": {
        justifyContent: "flex-end",
        gap: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .reassignBtn": {
        textTransform: "capitalize",
        borderRadius: "7px",
        padding: "9px 0px",
        backgroundColor: "#2B6FED",
        fontSize: "18px",
        color: "#fff",
        width: "150px",
        fontWeight: 500,
    },
    "& .cancelBtn": {
        borderRadius: "7px",
        border: "1px solid #2B6FED",
        padding: "9px 0px",
        textTransform: "capitalize",
        fontSize: "18px",
        color: "#2B6FED",
        width: "150px",
        fontWeight: 500,
    },
});
// Customizable Area End
