import { BlockComponent } from "framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";
const navigation = require('react-navigation')
// Customizable Area Start
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18Interface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    complex: string;
    selectedComplex: string;
    country: string;
    city: string;
    building: string;
    year: string;
    salesManager: string;
    accountManager: string;
    complexList: Array<string>;
    selectedComplexList: Array<SelectedComplexListItem>;
    countryList: Array<string>;
    cityList: Array<string>;
    buildingList: Array<BuildingListItem>;
    yearList: Array<number>;
    salesManagerList: Array<SalesManagerItem>;
    accountManagerList: Array<AccountManagerItem>;
    societyManagementId: string;
    complexId: string;
    buildingId: string;
    salesManagerId: number;
    accountManagerId: number;
    showError: boolean;
    error: string;
    searchData: SearchSuccessResponse;
    contractList: Array<ContractListItem>;
    page: number | null;
    contractTotalItem: number | null;
    currentPage: number | null;
    contractPages: number | null;
    loader: boolean;
    serachText: string;
    paginationData: PaginationData;
    leaseReport : string;
    // Customizable Area End
};

// Customizable Area Start

export interface I18Interface {
    language: string;
}
export interface SelectedComplexListItem {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        complex_area: string,
        measurement_unit: null,
        maintenance_per_square_feet: null,
        is_building: boolean,
        total_floor: null,
        total_unit: null,
        currency_id: null,
        account_manager_id: null,
        language: null,
        status: null,
        logo: {
            url: string,
            content_type: string,
            file_name: string
        },
        photos: [
            {
                url: string,
                content_type: string,
                file_name: string
            }
        ],
        subscription_end_in: null,
        subscription_id: null,
        is_building_list: null,
        building_list: [],
        shared_area: [
            {
                id: number,
                name: string,
                society_management_id: number,
                created_at: string,
                updated_at: string,
                details: string,
                total_area: string,
                currency_id: null,
                reservation_fee: number
            }
        ],
        documents: [],
        building_documents: [],
        incident_categories: {
            Plumbing: number,
            Carpenter: number,
            Electrician: number
        },
        total_buildings: number,
        active_users: null,
        inactive_users: null,
        pending_ownership_requests: number,
        collected_management_fee: null,
        company_name: null,
        rented: number,
        total_polls: number,
        last_date_of_polls: null,
        total_surveys: number,
        last_date_of_surveys: null,
        building_ownership_rate: null,
        ownership_onboarded: {
            ownership_percent: string,
            ownership_count: number
        },
        tenant_resquests: {
            tenant_percent: number,
            tenant_count: number
        },
        total_units: [],
        account_manager: null,
        sales_manager: null,
        country: string,
        city: string,
        lat: null,
        long: null,
        streetname: string,
        zipcode: string,
        landmark: string,
        locationlink: null,
        complex_lat: null,
        complex_long: null,
        currency: {
            id: number,
            currency: string,
            created_at: string,
            updated_at: string,
            currency_ar: string
        },
        subscription_type: null,
        plan_id: null,
        paying_month: null,
        googlemaplocation: null,
        jurisdiction: {
            data: {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    jurisdiction_id: number,
                    jurisdiction_name: string,
                    currency: string,
                    country: string,
                    timezone: string,
                    unit_of_measurement: string,
                    mobile_number_length: string,
                    formula: string,
                    value_added_tax: number,
                    zone_multiplier: number,
                    complex: [
                        {
                            id: number,
                            name: string,
                            description: string,
                            created_at: string,
                            updated_at: string,
                            complex_area: string,
                            maintenance_per_square_feet: null,
                            measurement_unit: null,
                            ticket_days_configuration: number,
                            contigency: null,
                            zone_multiplier: null,
                            late_charge_percentage: null,
                            latitude: null,
                            longitude: null,
                            value_added_tax: null,
                            is_building: boolean,
                            real_estate_company_id: null,
                            total_floor: null,
                            total_unit: null,
                            currency_id: null,
                            account_manager_id: null,
                            language: null,
                            status: null
                        }
                    ],
                    total_complex: number,
                    total_buildings: number,
                    total_units: number,
                    contracts: []
                }
            }
        }
    }
};

export interface SelectedComplexList {
    data: Array<SelectedComplexListItem>
};

export interface CountryListSuccessResponse {
    data: {
        countries: Array<string>
    }
}

export interface CityListSuccessReponse {
    data: {
        cities: Array<string>
    }
};

export interface BuildingListItem {
    id: number,
    name: string,
    society_management_id: number,
    description: null,
    created_at: string,
    updated_at: string,
    per_floor_unit: number,
    generation_methods: string,
    number_of_floor: number,
    building_area: string,
    account_id: null,
    lat: null,
    long: null,
    city: null,
    country: null,
    googlemaplocation: null,
    real_estate_company_id: null
};

export interface BuildingListSuccessResponse {
    data: {
        buildings: Array<BuildingListItem>
    }
};

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface SelectedComplexFailureResponse {
    errors: string
}

export interface ApiFailureResponseError {
    contract: string
};

export interface ApiPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
};

export interface SalesManagerSuccessResponse {
    sales_manager_listing: Array<SalesManagerItem>
};

export interface SalesManagerItem {
    id: number,
    first_name: string,
    last_name: string | null,
    full_phone_number: string | null,
    phone_number: string | number | null,
    email: string | null,
    activated: boolean | null,
    device_id: null,
    unique_auth_id: null,
    password_digest: string | null,
    role: string | null,
    created_at: string | null,
    updated_at: string | null,
    nationality: string | null,
    country: string | null,
    city: string | null,
    national_id: null,
    full_name: string | null
};

export interface AccountManagerSuccessResponse {
    account_manager_listing: Array<AccountManagerItem>
};

export interface AccountManagerItem {
    id: number | null,
    first_name: string,
    last_name: string | null,
    full_phone_number: string | null,
    phone_number: null,
    email: string | null,
    activated: boolean | null,
    device_id: null,
    unique_auth_id: null,
    password_digest: string | null,
    role: string | null,
    created_at: string | null,
    updated_at: string | null,
    nationality: string | null,
    country: string | null,
    city: string | null,
    national_id: number | null,
    full_name: null
};

export interface SearchSuccessResponse {
    average_prices: {
        all: number,
        country: number,
        city: number,
        complex: number,
        building: number,
        year: number,
        sales_manager: number,
        account_manager: number
    },
    lease_rate_and_occupancy_rates: {
        all: {
            lease: number,
            occupancy: number
        },
        country: {
            lease: number,
            occupancy: number
        },
        city: {
            lease: number,
            occupancy: number
        },
        complex: {
            lease: number,
            occupancy: number
        },
        building: {
            lease: number,
            occupancy: number
        },
        year: {
            lease: number,
            occupancy: number
        },
        sales_manager: {
            lease: number,
            occupancy: number
        },
        account_manager: {
            lease: number,
            occupancy: number
        }
    }
};

export type TFunction = (value: string) => string;

export interface ContractListSuccesResponse{
    data:Array<string>
};

export interface ContractListResponse {
    data: Array<ContractListItem>;
    meta: {
        pagination: PaginationData | null;
    }
};

export interface ContractListItem {
    attributes: {
        id: number;
        year: string;
        complex: {
            name: string;
        } | null;
        building: {
            name: string;
        } | null;
        unit: {
            apartment_name: string;
        } | null;
        generated_by: {
            full_name: string | null;
        };
        role: Array<{
            name: string;
        }>;
        lease_issuer: LeaseIssuerInterface;
        generated_on: string;
        expiring_in: string;
    };
};

export type LeaseIssuerInterface = null | string | Array<string>;

export interface PaginationData {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface DownloadLeaseReportResponse {
    downloade_link: string;
}

// Customizable Area End

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class LeaseReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    selectedComplexApiCallId: string = "";
    buildingListApiCallId: string = "";
    salesManagerApiCallId: string = "";
    accountManagerApiCallId: string = "";
    searchContractDetailsApiCallId: string = "";
    contractListApiCallId: string = "";
    searchLeaseReportApiCallId: string = "";
    downloadLeaseReportApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            complex: "",
            selectedComplex: "",
            country: "",
            city: "",
            building: "",
            year: "",
            salesManager: "",
            accountManager: "",
            complexList: ["Complex", "Individual Building"],
            selectedComplexList: [],
            countryList: [],
            cityList: [],
            buildingList: [],
            yearList: [],
            salesManagerList: [],
            accountManagerList: [],
            societyManagementId: "",
            complexId: "",
            buildingId: "",
            salesManagerId: 0,
            accountManagerId: 0,
            showError: false,
            error: "",
            contractList: [],
            searchData: {
                average_prices: {
                    all: 0,
                    country: 0,
                    city: 0,
                    complex: 0,
                    building: 0,
                    year: 0,
                    sales_manager: 0,
                    account_manager: 0
                },
                lease_rate_and_occupancy_rates: {
                    all: {
                        lease: 0,
                        occupancy: 0
                    },
                    country: {
                        lease: 0,
                        occupancy: 0
                    },
                    city: {
                        lease: 0,
                        occupancy: 0
                    },
                    complex: {
                        lease: 0,
                        occupancy: 0
                    },
                    building: {
                        lease: 0,
                        occupancy: 0
                    },
                    year: {
                        lease: 0,
                        occupancy: 0
                    },
                    sales_manager: {
                        lease: 0,
                        occupancy: 0
                    },
                    account_manager: {
                        lease: 0,
                        occupancy: 0
                    }
                }
            },
            page: 0,
            contractTotalItem: 0,
            currentPage: 0,
            contractPages: 0,
            loader: false,
            serachText: "",
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },
            leaseReport: ""
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.apiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getContractList(1);
        this.generateYearArray();
        this.getCountryList();
        this.getAccountManagerListing();
        this.getSalesManagerListing();
        // Customizable Area End
    }

    // Customizable Area Start

    leaseReportApiCall = async (data: ApiPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = data;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let leaseReportRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        leaseReportRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        leaseReportRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        leaseReportRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        leaseReportRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? leaseReportRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : leaseReportRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(leaseReportRequestMessage.id, leaseReportRequestMessage);
        return leaseReportRequestMessage.messageId;
    };

    apiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: SelectedComplexList &
            CountryListSuccessResponse &
            CityListSuccessReponse &
            BuildingListSuccessResponse &
            SalesManagerSuccessResponse &
            AccountManagerSuccessResponse &
            SearchSuccessResponse & 
            ContractListResponse & DownloadLeaseReportResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.selectedComplexApiCallId) {
            this.selectedComplexSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerApiCallId) {
            this.accountManagerSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.searchContractDetailsApiCallId) {
            this.searchContractDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractListApiCallId) {
            this.contractListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.searchLeaseReportApiCallId) {
            this.searchLeaseReportSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.downloadLeaseReportApiCallId) {
            this.downloadLeaseReportSuccessCallBack(responseJson);
        }
    };

    apiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse & SelectedComplexFailureResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.selectedComplexApiCallId) {
            this.selectedComplexFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerApiCallId) {
            this.accountManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.searchContractDetailsApiCallId) {
            this.searchContractDetailsListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractListApiCallId) {
            this.contractListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.searchLeaseReportApiCallId) {
            this.searchLeaseReportFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.downloadLeaseReportApiCallId) {
            this.downloadLeaseReportFailureCallBack(responseJson);
        }
    };

    getCountryList = async () => {
        this.countryListApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: configJSON.countryListEndPoint,
            contentType: configJSON.validationApiContentType
        });
    };

    countryListSucessCallBack = (response: CountryListSuccessResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityListApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: `${configJSON.cityListEndPoint}${this.state.country}`,
        });
    };

    cityListSucessCallBack = (response: CityListSuccessReponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getSelectedComplexListing = async (complex: boolean) => {
        this.selectedComplexApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: `${configJSON.selectedComplexEndPoint}city=${this.state.city}&is_building=${complex}`,
        });
    };

    selectedComplexSucessCallBack = (response: SelectedComplexList) => {        
        this.setState({ selectedComplexList: response.data });
    };

    selectedComplexFailureCallBack = (response: SelectedComplexFailureResponse) => {        
        this.setState({ showError: false, error: response.errors });
    };

    getBuildingListing = async () => {
        this.buildingListApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: `${configJSON.buildingListEndPoint}${this.state.societyManagementId}`,
        });
    };

    buildingListSucessCallBack = (response: BuildingListSuccessResponse) => {
        this.setState({ buildingList: response.data.buildings });
    };

    buildingListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getSalesManagerListing = async () => {
        this.salesManagerApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: configJSON.salesManagerEndPoint,
        });
    };

    salesManagerSuccessCallBack = (response: SalesManagerSuccessResponse) => {
        this.setState({ salesManagerList: response.sales_manager_listing })
    };

    salesManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getAccountManagerListing = async () => {
        this.accountManagerApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: configJSON.accountManagerEndPoint
        });
    };

    accountManagerSuccessCallBack = (response: AccountManagerSuccessResponse) => {
        this.setState({ accountManagerList: response.account_manager_listing })
    };

    accountManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    searchLeaseDataDetails = async () => { 
        let searchLeaseDataEndPoint = configJSON.searchDataEndPoint
        const { city, country, buildingId, societyManagementId, year, salesManagerId, accountManagerId } = this.state
        if (country) {
            searchLeaseDataEndPoint += `country=${country}`;
        }
        if (city) {
            searchLeaseDataEndPoint += `&city=${city}`;
        } 
        if (societyManagementId) {
            searchLeaseDataEndPoint += `&complex=${societyManagementId}`;
        }       
        if (buildingId) {
            searchLeaseDataEndPoint += `&building=${buildingId}`;
        }
        if (year) {
            searchLeaseDataEndPoint += `&year=${year}`;
        }
        if (salesManagerId) {
            searchLeaseDataEndPoint += `&sales_manager=${salesManagerId}`;
        }
        if (accountManagerId) {
            searchLeaseDataEndPoint += `&account_manager=${accountManagerId}`;
        }  
        this.searchContractDetailsApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: searchLeaseDataEndPoint,
        });
    };

    searchContractDetailsSuccessCallBack = (response: SearchSuccessResponse) => {        
        this.setState({ searchData: response }, () => {
            this.getContractList(this.state.page)
        });
    };

    searchContractDetailsListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract });
    };

    getContractList = async (page: number | null) => {
        this.setState({ loader: true });
        let leaseReportContractListEndPoint = `${configJSON.leaseReportContractListEndPoint}?page=${page}`
        const { city, country, buildingId, societyManagementId, year, salesManagerId, accountManagerId } = this.state
        if (city) {
            leaseReportContractListEndPoint += `&city=${city}`;
        }
        if (country) {
            leaseReportContractListEndPoint += `&country=${country}`;
        }

        if (buildingId) {
            leaseReportContractListEndPoint += `&building=${buildingId}`;
        }

        if (societyManagementId) {
            leaseReportContractListEndPoint += `&complex=${societyManagementId}`;
        }

        if (salesManagerId) {
            leaseReportContractListEndPoint += `&sales_manager=${salesManagerId}`;
        }
        if (year) {
            leaseReportContractListEndPoint += `&year=${year}`;
        }
        if (accountManagerId) {
            leaseReportContractListEndPoint += `&account_manager=${accountManagerId}`;
        }  
        this.contractListApiCallId = await this.leaseReportApiCall({
            method: configJSON.leaseReportGetMethod,
            endPoint: leaseReportContractListEndPoint
        });
    };

    contractListSuccessCallBack = (response: ContractListResponse) => {
        this.setState({
            contractList: response?.data,
            loader: false,
        }, () => {
            if (response.meta.pagination !== null) {
                this.setState({
                    contractTotalItem: response.meta.pagination.total_count,
                    contractPages: response.meta.pagination.total_pages,
                    currentPage: response.meta.pagination.current_page,
                    paginationData: response.meta.pagination,
                    page: response.meta.pagination.current_page
                });
            }
        });
    };

    contractListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract, loader: false });
    };

    searchLeaseReport = async (searchInput: string) => {
        this.setState({ loader: true });
        this.searchLeaseReportApiCallId = await this.leaseReportApiCall({
            method: configJSON.searchLeaseReportMethod,
            endPoint: `${configJSON.searchLeaseReportEndPoint}${searchInput}`
        });
    };

    searchLeaseReportSuccessCallBack = (response: ContractListResponse) => {
        this.setState({
            contractList: response.data,
            loader: false
        }, () => {
            if (response.meta.pagination !== null) {
                this.setState({
                    paginationData: response.meta.pagination,
                    contractTotalItem: response.meta.pagination.total_count,
                    contractPages: response.meta.pagination.total_pages,
                    currentPage: response.meta.pagination.current_page,
                });
            }
        });
    };

    searchLeaseReportInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ serachText: event.target.value }, () => {
            this.searchLeaseReport(this.state.serachText)
        });
    };

    searchLeaseReportFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract, loader: false });
    };

    getDownloadLeaseReport = async () => {
        this.setState({ loader: true });
        let endpoint = `${configJSON.downloadLeaseReportEndpoint}?page=`;
        const { city, country, buildingId, societyManagementId, year, salesManagerId, accountManagerId } = this.state;
        if (country) {
            endpoint += `&country=${country}`;
        }
        if (city) {
            endpoint += `&city=${city}`;
        }
        if (societyManagementId) {
            endpoint += `&complex=${societyManagementId}`;
        }
        if (buildingId) {
            endpoint += `&building=${buildingId}`;
        }
        if (year) {
            endpoint += `&year=${year}`;
        }
        if (salesManagerId) {
            endpoint += `&sales_manager=${salesManagerId}`;
        }
        if (accountManagerId) {
            endpoint += `&account_manager=${accountManagerId}`;
        }
        this.downloadLeaseReportApiCallId = await this.leaseReportApiCall({
            method: configJSON.searchLeaseReportMethod,
            endPoint: endpoint
        });
    };

    downloadLeaseReportSuccessCallBack = (response: DownloadLeaseReportResponse) => {
        this.setState({ leaseReport: response.downloade_link, loader: false }, () => {
            this.handleDownloadFile();
        });
    };

    downloadLeaseReportFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, error: response.errors[0].contract, loader: false });
    };

    handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ 
            complex: event.target.value as string, 
            selectedComplex: "",
            selectedComplexList: [],
            building: "",
            buildingList: []
         }, () => {
            if (this.state.complex === "Complex") {
                this.getSelectedComplexListing(false);
            } else if (this.state.complex === "Individual Building") {
                this.getSelectedComplexListing(true);
            }
        });
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            country: event.target.value as string,
            city: "", 
            cityList: [], 
            selectedComplex: "",
            selectedComplexList: [],
            building: "",
            buildingList: []
        },
            () => {
                this.getCityList();
            });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ 
            city: event.target.value as string,
            selectedComplex: "",
            selectedComplexList: [],
            building: "",
            buildingList: []
         }, () => {
            if (this.state.complex === "Complex") {
                this.getSelectedComplexListing(false);
            } else if (this.state.complex === "Individual Building") {
                this.getSelectedComplexListing(true);
            }
        });
    };

    handleCompleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let complexData = this.state.selectedComplexList.find((complex) =>  complex.attributes.name === event.target.value );
        if (complexData) {
            this.setState({
                selectedComplex: event.target.value as string,
                societyManagementId: complexData.id,
                building: "",
                buildingList: []
            }, () => {
                this.getBuildingListing();
            })
        }
    };

    handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let building = this.state.buildingList.find((building) => { return building.name === event.target.value })
        if (building) {
            this.setState({ building: event.target.value as string, buildingId: String(building.id) });
        }
    };

    handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ year: event.target.value as string });
    };

    handleSalesManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let salesManagerData = this.state.salesManagerList.find((salesManager) => { return salesManager.first_name === event.target.value });
        if (salesManagerData) {
            this.setState({ salesManager: event.target.value as string, salesManagerId: salesManagerData.id });
        }
    };

    handleAccountManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let accountManagerData = this.state.accountManagerList.find((accountManager) => { return accountManager.first_name === event.target.value });
        if (accountManagerData) {
            this.setState({ accountManager: event.target.value as string, accountManagerId: Number(accountManagerData.id) });
        }
    };

    handleCloseAlertError = () => {
        this.setState({ showError: false, error: "" });
    };

    generateYearArray = () => {
        const currentYear = new Date().getFullYear();
        let years = Array.from({ length: currentYear - 2000 + 1 }, () => currentYear);
        years = years.map((year, index) => year - index);
        this.setState({ yearList: years });
    };    

    handleDocumentAndReportRoute = (route:string) => {
        const msgData = new Message(getName(MessageEnum.NavigationMessage));
        msgData.addData(
            getName(MessageEnum.NavigationTargetMessage),
            route
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgData);
    };

    handleContractListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ page: newPageCount });
        this.getContractList(newPageCount);
    }; 
    
    handleDownloadFile = () => {
        const download = document.createElement('a');
        download.href = this.state.leaseReport;
        download.download = 'document.pdf';
        download.target = '_blank';
        document.body.appendChild(download);
        download.click();
        document.body.removeChild(download);
    };

    handleDecimalValues = (value: number) => {
        return (Math.floor(value * 100) / 100).toFixed(2)
    };

    handleRole = (value: string | Array<string> | null) => {
        if (Array.isArray(value)) {
            return value[0]
        } else if (value === null) {
            return "-"
        } else {
            return value;
        }
    };
    // Customizable Area End
}
