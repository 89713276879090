import React from "react";
// Customizable Area Start

// Customizable Area End

import RequiredActivityController, { Props, } from "./RequiredActivityController.web";

class RequiredActivity extends RequiredActivityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div>    
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default RequiredActivity;
export { RequiredActivity };
// Customizable Area End