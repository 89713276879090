// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

import * as Yup from "yup";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import { handleFilterComplexList } from "../../../../components/src/UtilFunction.web";

export interface Props {
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  step: number;
  complexId: null | string | undefined;
  buildingId: string | number | null;
  handleBack: () => void;
  handleNext: () => void;
  navigation: any;
}

interface SelectOption {
  label: string;
  value: string;
}

export type StyleObject = {
  [key: string]: {
    [key: string]: string;
  };
};

interface S {
  loading: boolean;

  isCoreMembersOpen: boolean;
  isNewCoreMembersOpen: boolean;

  userValues: UserFormValue;
  memberValues: MemberFormValue;

  userList: SelectOption[];
  roleList: SelectOption[];
  allRoleList: SelectOption[];

  coreMembersList: any[];

  countriesList: SelectOption[];
  citiesList: SelectOption[];
  complexList: any[];
  filterComplexList: SelectOption[];
  buildingList: SelectOption[];
  unitList: SelectOption[];

  showError: boolean;
  error: string;
}

interface MemberFormValue {
  userType: string;
  name: string;
  email: string;
  countryCode: string;
  phone: string;
  country: string;
  city: string;
  isComplex: number;
  complex: string;
  building: string;
  unit: string;
}

interface UserFormValue {
  account_id: string;
  role_id: string;
}

interface SS {
  id: any;
}

export interface MemberDetails {
  id: string;
  attributes: {
    name: string;
    role: string;
    role_list: string[];
    user_profile: {
      data: {
        attributes: {
          profile_pic: null | string;
        };
      };
    };
  };
}

export default class CompEmpComplexBuildingBlockStep5Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetUserListCallId: string = "";
  GetRoleListCallId: string = "";
  GetAllRoleListCallId: string = "";
  CreateCoreMemberCallId: string = "";
  GetCoreMemberListCallId: string = "";
  AddNewCoreMemberCallId: string = "";
  DeleteCoreMemberCallId: string = "";
  GetMemberCountryCallId: string = "";
  GetMemberCitiesCallId: string = "";
  GetMemberComplexCallId: string = "";
  GetMemberBuildingCallId: string = "";
  GetMemberUnitCallId: string = "";
  GetComplexDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      isCoreMembersOpen: false,
      isNewCoreMembersOpen: false,

      userValues: {
        account_id: "",
        role_id: "",
      },

      memberValues: {
        userType: "",
        name: "",
        email: "",
        countryCode: "+971",
        phone: "",
        country: "",
        city: "",
        isComplex: 1,
        complex: "",
        building: "",
        unit: "",
      },

      userList: [],
      roleList: [],
      allRoleList: [],

      coreMembersList: [],

      countriesList: [],
      citiesList: [],
      complexList: [],
      filterComplexList: [],
      buildingList: [],
      unitList: [],

      showError: false,
      error: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetUserListCallId:
          return this.handleUserListResponse(responseJsonBlock);
        case this.GetRoleListCallId:
          return this.handleRoleList(responseJsonBlock);
        case this.GetAllRoleListCallId:
          return this.handleAllRoleList(responseJsonBlock);
        case this.CreateCoreMemberCallId:
          return this.handleCoreMemberSuccess();
        case this.AddNewCoreMemberCallId:
          return this.handleAddNewCoreMemberSuccess();
        case this.DeleteCoreMemberCallId:
          return this.handleDeleteCoreMemberSuccess();
        case this.GetCoreMemberListCallId:
          return this.handleCoreMemberListResponse(responseJsonBlock);
        case this.GetMemberCountryCallId:
          return this.handleMemberCountryResponse(responseJsonBlock);
        case this.GetMemberCitiesCallId:
          return this.handleMemberCityListResponse(responseJsonBlock);
        case this.GetMemberComplexCallId:
          return this.handleMemberComplexListResponse(responseJsonBlock);
        case this.GetMemberBuildingCallId:
          return this.handleMemberBuildingListResponse(responseJsonBlock);
        case this.GetMemberUnitCallId:
          return this.handleMemberUnitListResponse(responseJsonBlock);
        case this.GetComplexDetailsCallId:
          return this.handleComplexDetailsResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, async () => {
      await this.getRoleList();
      await this.getAllRoleList();
      await this.getCoreMemberList();
      await this.getMemberCountryList();
      await this.getComplexDetails();
    });
  }

  handleSave = () => {
    const complexId = sessionStorage.getItem("createComplexId");
    if (complexId) {
      this.props.navigation.navigate("EditComplexMainBlock", { id: complexId });
    } else {
      this.props.handleNext();
    }
  };

  getComplexDetails = async () => {
    this.GetComplexDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/${this.props.complexId}`,
    });
  };

  handleComplexDetailsResponse = (response: { data: any }) => {
    if (response && response.data) {
      const complex = response.data;
      this.setState(
        {
          ...this.state,
          memberValues: {
            ...this.state.memberValues,
            country: complex.attributes.country,
            city: complex.attributes.city,
            isComplex: 1,
            complex: complex.id,
          },
        },
        () => {
          this.getMemberCityList(complex.attributes.country);
          this.getMemberComplexList(complex.attributes.country, complex.attributes.city);
          this.getMemberBuildingList(complex.id);
        }
      );
    }
  };

  getCoreMemberList = async () => {
    this.GetCoreMemberListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/core_member_listing?id=${this.props.complexId}`,
    });
  };

  handleCoreMemberListResponse = (response: { data: MemberDetails[] }) => {
    this.setState({ loading: false }, () => {
      if (response && response.data) {
        this.setState({ coreMembersList: response.data });
      }
    });
  };

  getMemberCountryList = async () => {
    this.GetMemberCountryCallId = await apiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleMemberCountryResponse = (responseJson: { data: { countries: string[] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        countriesList: responseJson.data.countries.map((item: string) => ({ label: item, value: item })),
      });
    }
  };

  getMemberCityList = async (country: string | number) => {
    this.GetMemberCitiesCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`,
    });
  };

  handleMemberCityListResponse = (responseJson: { city_list: { city: string }[] }) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        citiesList: responseJson.city_list.map((item: { city: string }) => ({ label: item.city, value: item.city })),
      });
    }
  };

  getMemberComplexList = async (country?: string, city?: string) => {
    this.GetMemberComplexCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?country=${country}&city=${city}`,
    });
  };

  handleMemberComplexListResponse = (responseJson: { complexes: { data: [] } }) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.setState({ filterComplexList: handleFilterComplexList(1, this.state.complexList) });
      });
    }
  };

  handleIsComplex = (value: string | number) => {
    this.setState({
      filterComplexList: handleFilterComplexList(Number(value), this.state.complexList),
    });
  };

  getMemberBuildingList = async (complexId: string | number) => {
    this.GetMemberBuildingCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleMemberBuildingListResponse = (responseJson: { buildings: { name: string; id: number | string }[] }) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: { name: string; id: number | string }) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  getMemberUnitList = async (complexId: string | number, buildingId: string | number) => {
    this.GetMemberUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${complexId}&building_management_id=${buildingId}`,
    });
  };

  handleMemberUnitListResponse = (responseJsonBlock: any) => {
    this.setState({
      unitList: responseJsonBlock.data.map((item: any) => ({ value: item.id, label: item.attributes.apartment_name })),
    });
  };

  getUserList = async () => {
    this.GetUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/member_list?id=${this.props.complexId}`,
    });
  };

  handleUserListResponse = (responseJson: { members: { id: number | string; full_name: string }[] }) => {
    if (responseJson && responseJson.members) {
      this.setState({
        userList: responseJson.members.map((data: { id: number | string; full_name: string }) => ({
          label: data.full_name,
          value: data.id + "",
        })),
      });
    }
  };

  getRoleList = async () => {
    this.GetRoleListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_society_management/company_employee/complexes/role_list?core_member=true`,
      method: "GET",
    });
  };

  handleRoleList = (responseJson: { roles: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.roles) {
      this.setState({
        roleList: responseJson.roles.map((data: { id: number | string; name: string }) => ({
          label: data.name,
          value: data.id + "",
        })),
      });
    }
  };

  getAllRoleList = async () => {
    this.GetAllRoleListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/role_list?core_member=false`,
    });
  };

  handleAllRoleList = (responseJson: { roles: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.roles) {
      this.setState({
        allRoleList: responseJson.roles.map((data: { id: number | string; name: string }) => ({
          value: data.id + "",
          label: data.name,
        })),
      });
    }
  };

  createCoreMember = async (value: MemberFormValue) => {
    const formData = new FormData();
    formData.append("member_invitation[role_id]", value.userType);
    formData.append("member_invitation[full_name]]", value.name);
    formData.append("member_invitation[email_address]", value.email);
    formData.append("member_invitation[phone_number]", value.country + "-" + value.phone);
    formData.append("member_invitation[society_management_id]", value.complex);
    formData.append("member_invitation[building_management_id]", value.building);
    formData.append("member_invitation[apartment_management_id]", value.unit);

    this.CreateCoreMemberCallId = await apiCall({
      body: formData,
      method: "POST",
      endPoint: `bx_block_request_management/member_invitations`,
    });
  };

  handleCoreMemberSuccess = () => {
    this.setState({ loading: false, showError: true, error: "Core member invited successfully" }, () => {
      this.getCoreMemberList();
    });
  };

  addNewCoreMember = async (values: UserFormValue) => {
    const data = {
      role_id: Number(values.role_id),
      account_id: Number(values.account_id),
      complex_id: this.props.complexId,
    };

    this.AddNewCoreMemberCallId = await apiCall({
      body: JSON.stringify(data),
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_society_management/company_employee/complexes/add_member`,
    });
  };

  handleAddNewCoreMemberSuccess = () => {
    this.setState({ loading: false }, () => {
      this.getCoreMemberList();
    });
  };

  handleDeleteMember = (id: string | number) => {
    this.setState({ loading: true }, async () => {
      this.DeleteCoreMemberCallId = await apiCall({
        method: "DELETE",
        endPoint: `bx_block_my_team/team_members/${id}`,
      });
    });
  };

  handleDeleteCoreMemberSuccess = () => {
    this.setState({ loading: false }, () => {
      this.getCoreMemberList();
    });
  };

  handleAddMemberDialog = () => {
    this.setState({ isCoreMembersOpen: !this.state.isCoreMembersOpen }, () => {
      this.getUserList();
    });
  };

  handleCoreMemberDialog = () => {
    this.setState({ isCoreMembersOpen: !this.state.isCoreMembersOpen });
  };

  handleNewCoreMemberDialog = () => {
    this.setState({ isCoreMembersOpen: false, isNewCoreMembersOpen: !this.state.isNewCoreMembersOpen });
  };

  validationSchema = Yup.object().shape({
    account_id: Yup.string().required("Username is required"),
    role_id: Yup.string().required("Role is required"),
  });

  validationCreateSchemaBlock = Yup.object().shape({
    userType: Yup.string().required("User type is required"),
    name: Yup.string()
      .required("Full name is required")
      .matches(/\S/, "Required")
      .matches(/^[A-Za-z\s]+$/, "Enter valid name"),
    email: Yup.string().required("Email address is required").email("Enter valid email"),
    phone: Yup.string().required("Phone number is required"),
    unit: Yup.string().required("Unit is required"),
  });
}

// Customizable Area End
