// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Input,
  FormControl,
  Divider,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  BuildingImage,
  GreyTenantType,
  GreyTenantName,
  GreyEmailIcon,
  GreyBuildingName,
  GreyUnitNumber,
  GreyIdType,
  GreyIdNumber,
  GreyCalenderIcon,
  UploadIcon,
  GreyPhoneNumber,
  PdfIcon,
  DocumentIcon,
  ComplexIcon,
} from "./assets";
import { Formik, Form } from "formik";
import RegisterTenantController, { Props } from "./RegisterTenantController.web";
import { withTranslation } from "react-i18next";
import { TenantStyle } from "./TenantStyle.web";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PhoneInput from "react-phone-input-2";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { withRouter } from "react-router";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class RegisterTenant extends RegisterTenantController {
  constructor(props: Props) {
    super(props);
  }


  handleErrorMessage = (errors: any, touched: any, t: any) => {
    if (errors && touched) {
      return <p className="error">{t(errors)}</p>;
    }
  };

  handleBackRegisterButton = () => {
    if (localStorage.getItem("isComingFromContract") === "IssueContract") {
      localStorage.removeItem("isComingFromContract");
      this.props.navigation.navigate("IssueContract");
    } else {
      this.props.navigation.navigate("TenantList");
    }
  };

  handleBackPdfButton = () => {
    if (this.state.contract) {
      this.setState({ contract: null });
    } else {
      this.handleChangePage();
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

    return (
      <>
        <Loader loading={this.state.loading} />

        {this.state.isRegisterTenantOpen ? (
          <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.selectTemplate}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box className={languageCondition(language, "arabic-grid", "")}>
                  <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                    <div className="left-icon">
                      <IconButton onClick={() => this.handleBackRegisterButton()}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      <span className="bold-text">{t("Register A Tenant")}</span>
                    </div>
                  </Box>
                  <Container className="page-container">
                    <Box className="issue-lease-content" style={{ marginBottom: "50px" }}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={this.state.registerTenantForm}
                        validationSchema={this.validationRegisterTenantFormSchema}
                        onSubmit={(values, { resetForm }) => this.handleSubmitAddTenant(values)}
                      >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                          return (
                            <Form onSubmit={handleSubmit} translate="yes" className="register-tenant-form">
                              <Box className="select-input-box register-tenant-form-content-box">
                                <FormControl fullWidth>
                                  <Box className="select-box register-tenant-tenant-type-box">
                                    <Select
                                      value={values.tenantType}
                                      onChange={(e: any) => {
                                        setFieldValue("tenantType", e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      displayEmpty
                                      input={<OutlinedInput />}
                                      fullWidth
                                      className="select-input register-tenant-tenant-type"
                                      name="tenantType"
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Type of Tenant")}
                                      </MenuItem>
                                      <MenuItem value="Individual" className={languageCondition(language, "select-arabic-menu", "")}>{t("Individual Person")}</MenuItem>
                                      <MenuItem value="company" className={languageCondition(language, "select-arabic-menu", "")}>{t("Company")}</MenuItem>
                                    </Select>
                                    <img src={GreyTenantType} alt="type-icon" />
                                  </Box>
                                  {this.handleErrorMessage(errors.tenantType, touched.tenantType, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Input
                                    value={values.tenantName}
                                    onChange={(e: any) => {
                                      setFieldValue("tenantName", e.target.value);
                                    }}
                                    placeholder={t("Tenant Name")}
                                    onBlur={handleBlur}
                                    name="tenantName"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={GreyTenantName} alt="tenant-name-icon" />
                                      </InputAdornment>
                                    }
                                    className="select-input input register-tenant-name"
                                  />
                                  {this.handleErrorMessage(errors.tenantName, touched.tenantName, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Box className="mobile-box register-tenant-country-code-selector">
                                    <Box className="register-tenant-select-box">
                                      <FormControl variant="outlined">
                                        <PhoneInput
                                          onChange={(e: any) => {
                                            setFieldValue("tenantCountryCode", `+${e}`);
                                          }}
                                          country={"us"}
                                          inputProps={{ name: "selectCode" }}
                                          enableSearch={true}
                                          value={values.tenantCountryCode}
                                        />
                                      </FormControl>
                                    </Box>
                                    <Box className="divider" />
                                    <Input
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <img src={GreyPhoneNumber} alt="" />
                                        </InputAdornment>
                                      }
                                      name="tenantMobile"
                                      className="mobile-input register-tenant-mobile-number"
                                      value={values.tenantMobile}
                                      onChange={(e: any) => {
                                        setFieldValue("tenantMobile", e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      placeholder={t("Tenant Mobile")}
                                    />
                                  </Box>
                                  {this.handleErrorMessage(errors.tenantMobile, touched.tenantMobile, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Input
                                    value={values.tenantEmail}
                                    onChange={(e: any) => {
                                      setFieldValue("tenantEmail", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    name="tenantEmail"
                                    placeholder={t("Tenant Email ID")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={GreyEmailIcon} alt="" />
                                      </InputAdornment>
                                    }
                                    className="select-input input register-tenant-email-id"
                                  />
                                  {this.handleErrorMessage(errors.tenantEmail, touched.tenantEmail, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Box className="select-box">
                                    <Select
                                      value={values.complex}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      displayEmpty
                                      name="complex"
                                      fullWidth
                                      className="select-input register-tenant-complex"
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Complex")}
                                      </MenuItem>
                                      {this.state.complexList.map((complex: any) => {
                                        return (
                                          <MenuItem
                                            className={languageCondition(language, "select-arabic-menu", "")}
                                            value={complex.id}
                                            key={complex.id}
                                            onClick={() => this.handleRegisterTenantComplex(complex, setFieldValue)}
                                          >
                                            {complex.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <img src={ComplexIcon} alt="" />
                                  </Box>
                                  {this.handleErrorMessage(errors.complex, touched.complex, t)}
                                </FormControl>
                                {this.state.isBuilding && (
                                  <FormControl fullWidth>
                                    <Box className="select-box">
                                      <Select
                                        value={values.building}
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        onChange={(e: any) => {
                                          setFieldValue("building", e.target.value);
                                          this.getUnitList(values.complex, e.target.value);
                                        }}
                                        displayEmpty
                                        name="building"
                                        fullWidth
                                        className="select-input register-tenant-building"
                                      >
                                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                          {t("Select Building")}
                                        </MenuItem>
                                        {this.state.buildingList.map((building: any) => {
                                          return (
                                            <MenuItem value={building.id} key={building.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                              {building.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                      <img src={GreyBuildingName} alt="" />
                                    </Box>
                                    {this.handleErrorMessage(errors.building, touched.building, t)}
                                  </FormControl>
                                )}
                                <FormControl fullWidth>
                                  <Box className="select-box">
                                    <Select
                                      displayEmpty
                                      value={values.unit}
                                      className="select-input register-tenant-unit-id"
                                      input={<OutlinedInput />}
                                      onChange={(e: any) => {
                                        setFieldValue("unit", e.target.value);
                                      }}
                                      name="unit"
                                      onBlur={handleBlur}
                                      fullWidth
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Unit")}
                                      </MenuItem>
                                      {this.state.unitList.map((unit: any) => {
                                        return (
                                          <MenuItem value={unit.id} key={unit.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                            {unit.apartment_name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <img src={GreyUnitNumber} alt="" />
                                  </Box>
                                  {this.handleErrorMessage(errors.unit, touched.unit, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Box className="select-box">
                                    <Select
                                      value={values.idType}
                                      displayEmpty
                                      onBlur={handleBlur}
                                      name="idType"
                                      fullWidth
                                      className="select-input register-tenant-id-type-list"
                                      onChange={(e: any) => {
                                        setFieldValue("idType", e.target.value);
                                      }}
                                      input={<OutlinedInput />}
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("ID Type")}
                                      </MenuItem>
                                      {this.state.idTypeList.map((idType: any) => {
                                        return (
                                          <MenuItem value={idType.id} key={idType.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                            {LanguageAttributeHandler(language, idType.name, idType.name_ar)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <img src={GreyIdType} alt="" />
                                  </Box>
                                  {this.handleErrorMessage(errors.idType, touched.idType, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Input
                                    value={values.idNumber}
                                    name="idNumber"
                                    className="select-input input register-tenant-id-number"
                                    placeholder={t("ID Number")}
                                    onChange={(e: any) => {
                                      setFieldValue("idNumber", e.target.value);
                                    }}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={GreyIdNumber} alt="" />
                                      </InputAdornment>
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {this.handleErrorMessage(errors.idNumber, touched.idNumber, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Input
                                    value={values.idDate}
                                    onBlur={handleBlur}
                                    name="idDate"
                                    onChange={(e: any) => {
                                      setFieldValue("idDate", e.target.value);
                                    }}
                                    type="date"
                                    className={this.handleMobileSelectClass(values.idDate, is_mobile)}
                                    placeholder={t("ID Expiration Date")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={GreyCalenderIcon} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                  {this.handleErrorMessage(errors.idDate, touched.idDate, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Box
                                    className="upload-box register-tenant-id-upload-box"
                                    onClick={() => this.uploadIDCard.click()}
                                  >
                                    <img src={UploadIcon} alt="" />
                                    <p>{t("Upload Tenant ID Card Copy")}</p>
                                  </Box>
                                  <input
                                    className="register-tenant-upload-id-card"
                                    accept=".pdf,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    name="idCard"
                                    type="file"
                                    onChange={(e: any) => {
                                      if (this.handleCompressFileValidation(e, values.idCard)) {
                                        setFieldValue("idCard", e.target.files);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    ref={(ref: any) => (this.uploadIDCard = ref)}
                                    style={{ display: "none" }}
                                  />
                                  {[...values.idCard].map((file: any) => {
                                    return (
                                      <Box className="pdf-box">
                                        <img src={DocumentIcon} alt="" />
                                        <Box className="pdf-info">
                                          <h4>{file.name}</h4>
                                          <CloseIcon
                                            onClick={() => {
                                              setFieldValue("idCard", []);
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                  {this.handleErrorMessage(errors.idCard, touched.idCard, t)}
                                </FormControl>
                                <FormControl fullWidth>
                                  <Box
                                    className="upload-box register-tenant-other-upload"
                                    onClick={() => this.uploadOtherDocument.click()}
                                  >
                                    <img src={UploadIcon} alt="" />
                                    <p>{t("Upload Other Tenant Documents")}</p>
                                  </Box>
                                  <input
                                    className="register-tenant-other-document-input"
                                    style={{ display: "none" }}
                                    accept=".pdf,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    ref={(ref: any) => (this.uploadOtherDocument = ref)}
                                    onBlur={handleBlur}
                                    type="file"
                                    onChange={(e: any) => {
                                      if (this.handleCompressFileValidation(e, values.otherDocument)) {
                                        const document: any = [...values.otherDocument, ...e.target.files];
                                        setFieldValue("otherDocument", document);
                                      }
                                    }}
                                    name="otherDocument"
                                    multiple
                                  />
                                  {[...values.otherDocument].map((file: any, index: number) => {
                                    return (
                                      <Box className="pdf-box other-doc-content-box">
                                        <img src={DocumentIcon} alt="doc-icon" />
                                        <Box className="pdf-info other-doc-content">
                                          <h4 className="file-name">{file.name}</h4>
                                          <CloseIcon
                                            className="close-icon"
                                            onClick={() => {
                                              const remainFile = [...values.otherDocument].filter(
                                                (file: any, idx: number) => idx !== index
                                              );
                                              setFieldValue("otherDocument", remainFile);
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                  {this.handleErrorMessage(errors.otherDocument, touched.otherDocument, t)}
                                </FormControl>
                              </Box>
                              <Box className="next-button submit-button register-tenant-next-button">
                                <Button type="submit" className="submit-next-button">
                                  {t("Next")}
                                </Button>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Box>
                  </Container>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box className="right-block right-image register-side-image-none" display={{ xs: "none", md: "flex" }}>
                  <img src={BuildingImage.default} className="building-logo" alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            style={{
              background: this.state.contract ? "#F7F9FE" : "white",
              height: "100vh",
              overflowY: "hidden",
            }}
            className={classes.selectTemplate}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box>
                  <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                    <div className="left-icon">
                      <IconButton onClick={() => this.handleBackPdfButton()}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      <span className="bold-text">{t("Register A Tenant")}</span>
                    </div>
                  </Box>

                  {this.state.contract ? (
                    <Box className="pdf-submit">
                      <Container>
                        <Box className="pdf-preview">
                          <Box className="pdf-box">
                            <img src={PdfIcon} alt="" />
                            <Box className="pdf-info">
                              <Box className="heading">
                                <h4 className="bold-text">{this.state.contract.name}</h4>
                                <div className="right-menu unit-menu">
                                  <Menu
                                    menuButton={
                                      <IconButton>
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  >
                                    <MenuItemMenu onClick={() => this.setState({ contract: null })}>
                                      {t("Delete")}
                                    </MenuItemMenu>
                                  </Menu>
                                </div>
                              </Box>
                              <Box className="data">
                                <span>{this.state.contractPageCount}</span> {t("pages")}{" "}
                                <span>{this.niceBytes(this.state.contract.size)}</span>{" "}
                                {moment(this.state.contract.astModifiedDate).format("MMMM DD, YYYY")}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="submit-button-box">
                            <Button onClick={() => this.handleSubmitTenantForContract(this.state.registerTenantForm)}>
                              {t("Submit")}
                            </Button>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  ) : (
                    <Container className="page-container">
                      <Box className="issue-lease-content">
                        <Box className="select-input-box">
                          <FormControl fullWidth>
                            <Box className="upload-box" onClick={() => this.uploadContract.click()}>
                              <img src={UploadIcon} alt="" />
                              <p>{t("Upload Rent Contract")}</p>
                            </Box>
                            <input
                              onChange={(e: any) => {
                                const file = e.target.files[0];
                                let reader: any = new FileReader();
                                reader.onloadend = () => {
                                  const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                                  this.setState({ contract: file, contractPageCount: count });
                                };
                                reader.readAsBinaryString(file);
                              }}
                              ref={(ref: any) => (this.uploadContract = ref)}
                              accept=".pdf"
                              type="file"
                              style={{ display: "none" }}
                              multiple
                            />
                          </FormControl>

                          <Box className="divider-box">
                            <Divider />
                            <span>{t("OR")}</span>
                            <Divider />
                          </Box>

                          <Box className="register-button-box">
                            <Button
                              className="now"
                              onClick={() => this.handleSubmitRegisterTenant(this.state.registerTenantForm, true)}
                            >
                              {t("Issue A Lease Now")}
                            </Button>
                            <Button
                              className="later"
                              onClick={() => this.handleSubmitRegisterTenant(this.state.registerTenantForm, false)}
                            >
                              {t("Issue A Lease Later")}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Container>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box className="right-block right-image" display={{ xs: "none", md: "flex" }}>
                  <img src={BuildingImage.default} className="building-logo" alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(TenantStyle)(withRouter(RegisterTenant)));


// Customizable Area End
