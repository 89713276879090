// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";

import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (val: string) => string;
  history: any;
  match: any;
  classes: any;
  location: any;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  loading: boolean;

  userId: string;
  inviteUserDetail: ProfileDetail | null;

  link: {
    twitter: string;
    instagram: string;
    facebook: string;
    snapchat: string;
  };
}

interface ProfileDetail {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: { name: string };
    bio: { bio: string | null };
    full_phone_number: { full_phone_number: null | string };
    email: string | null;
    units: string | null;
    gender: string | null;
    date_of_birth: string | null;
    hobbies: string[];
    website: [{ twitter_link: string }, { instagram_link: string }, { fb_link: string }, { snapchat_link: string }];
    profile_pic: {
      url: string;
    } | null;
  };
}

interface SS {
  id: any;
}

export default class CEInviteUserProfileController extends BlockComponent<Props, S, SS> {
  GetInviteProfileDetailCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      userId: "",
      inviteUserDetail: null,

      link: {
        twitter: "",
        instagram: "",
        facebook: "",
        snapchat: "",
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.GetInviteProfileDetailCallId) {
        this.handleProfileResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const user_id = this.props.navigation.getParam("id");
    this.setState({ userId: user_id, loading: true }, () => {
      this.getInviteProfileDetails();
    });
  }

  getInviteProfileDetails = async () => {
    this.GetInviteProfileDetailCallId = await CEApiCall({
      endPoint: `bx_block_profile/profiles/specific_user_profile?invition_id=${this.state.userId}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  handleProfileResponse = (responseJson: { data: ProfileDetail }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({
          inviteUserDetail: responseJson.data,
          link: {
            twitter: responseJson.data.attributes.website[0].twitter_link,
            instagram: responseJson.data.attributes.website[1].instagram_link,
            facebook: responseJson.data.attributes.website[2].fb_link,
            snapchat: responseJson.data.attributes.website[3].snapchat_link,
          },
        });
      }
    });
  };
}

// Customizable Area End
