//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Button,
    Hidden, InputAdornment, IconButton,
    Grid, MenuItem, Menu, Typography,
} from "@material-ui/core";

import TextField from '@material-ui/core/TextField';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';


import {
    Tenant_Logo, Building1,
    Lock_User_Icon, globalIcon, downArrow
} from "../../src/assets";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import CompEmpConfirmPassController, {
    Props,
} from "./CompEmpConfirmPassController.web";

const validationschema = Yup.object().shape({
    password1: Yup.string()
        .min(8, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character,Minimum length of the password should be 8')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character,Minimum length of the password should be 8'
        ).max(128, 'password should not be long than 128 characters'),

    password2: Yup.string()
        .oneOf([Yup.ref('password1'), null], 'Passwords must match')
        .required('Confirm Password is required')
        .max(128, 'password should not be long than 128 characters'),
})

export default class CompEmpConfirmPass extends CompEmpConfirmPassController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <Box>
                <Grid container spacing={5}>
                    <Grid item md={7} sm={12} xs={12} style={{height: '100%'}} className="rightbox k">
                        <Hidden>
                            <Box p={2} style={{height: '100%'}} className="rightbox l">
                                <Box style={{
                                    display: 'flex',
                                    height: '80px',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row'
                                }}>
                                    <img src={Tenant_Logo.default} style={webStyle.login_img1}/>
                                    <Box style={{height: '25px'}} onClick={(e) => {
                                        this.handleOpen(e)
                                    }} data-test-id='menu' id='value'>
                                        <p style={{verticalAlign: 'middle'}}>
                                            <img src={globalIcon} alt="GlobalIcon"
                                                 style={{position: 'relative', top: '5px'}}
                                                 className="login-h1 bold-text "/>
                                            <span style={{fontWeight: 'bolder', paddingLeft: '3px'}}
                                                  className="login-h1 bold-text ">{this.state.language1} </span>
                                            <img src={downArrow}
                                                 style={{position: 'relative', height: '7px', color: 'grey',top:"-2px"}}/>
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                            <Menu open={this.state.open1}

                                  anchorOrigin={{
                                      vertical: 'bottom', // Change this to 'bottom'
                                      horizontal: 'left',
                                  }}
                                  style={{
                                      position: 'absolute',
                                      top: '30px'
                                  }}
                                  transformOrigin={{
                                      vertical: 'bottom', // Change this to 'top'
                                      horizontal: 'left',
                                  }}
                                  anchorEl={this.state.field}>
                                <MenuItem onClick={() => {
                                    this.setState({language1: 'ENG', open1: false})
                                }} data-test-id="english">English</MenuItem>
                                <MenuItem onClick={() => {
                                    this.setState({language1: 'ARB', open1: false})
                                }} data-test-id="arabic">Arabic</MenuItem>
                            </Menu>
                            <Box className="middle_box n">
                                <Box className="middleinner_box o">
                                    <Formik initialValues={this.state.initialValues} validationSchema={validationschema}
                                            onSubmit={(values) => {this.confirmPass(values)}}>
                                        {({values, errors}) => (
                                            <Form translate="yes">
                                                <Box>
                                                    <h1 style={{fontSize: '32px',marginBottom:"10px"}}
                                                        className="login-h1 bold-text ">Change Password</h1>
                                                    <p style={{fontSize: '15px'}}>You need to change
                                                        your Password. Please enter a new password </p>
                                                </Box>
                                                <br/>
                                                <Box style={{marginBottom: "5px"}}>
                                                    <Field as={TextField} variant='outlined'
                                                           className="compempemail" label="Password" name="password1"
                                                           helperText={<div style={{color: 'red', fontSize: '15px'}}>
                                                               <ErrorMessage name="password1" component="div"/></div>}
                                                           placeholder="New Password" fullWidth
                                                           data-test-passinp="passwordInput"
                                                           type={this.state.showPassword1 ? 'text' : 'password'}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={Lock_User_Icon} className="frm-icons" alt="Email Icon" style={{ marginTop: "-2px" }} />
                                  </InputAdornment>

                                ),
                                endAdornment: (
                                  <IconButton
                                    data-test-password="ShowPassword"
                                    edge="end"

                                    aria-label="toggle password visibilityy"
                                    onClick={this.handleClickShowPassword1}>
                                    {this.state.showPassword1 ? <VisibilityOutlinedIcon style={{ color: '#b8b4b4' }} id="imageShowDialog"/> : <VisibilityOffOutlinedIcon style={{ color: '#b8b4b4' }} id="imageShowDialog2"/>}
                                  </IconButton>
                                ),
                                style: {
                                  width: '360px',
                                  height: '56px',
                                  borderRadius: '12px',
                                  border: '1px solid #d1d1d1',
                                  background: '#f9f9f9',
                                }
                              }}
                            />

                                                </Box>
                                                <Box style={{marginBottom: "5px"}}>
                                                    <Field as={TextField} variant='outlined' className="compempemail"
                                                           label="Password" name="password2"
                                                           helperText={<div style={{color: 'red', fontSize: '15px'}}>
                                                               <ErrorMessage name="password2" component="div"/></div>}
                                                           placeholder="Confirm Password" fullWidth
                                                           data-test-passinp="passwordInput"
                                                           type={this.state.showPassword2 ? 'text' : 'password'}

                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={Lock_User_Icon} className="frm-icons p" alt="Email Icon" style={{ marginTop: "-2px" }} />
                                  </InputAdornment>

                                ),
                                endAdornment: (
                                  <IconButton
                                    data-test-password="ShowPassword"
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword2}>
                                    {this.state.showPassword2? <VisibilityOutlinedIcon style={{ color: '#b8b4b4' }} /> : <VisibilityOffOutlinedIcon style={{ color: '#b8b4b4' }} />}
                                  </IconButton>
                                ),
                                style: {
                                  width: '360px',
                                  height: '56px',
                                  borderRadius: '12px',
                                  border: '1px solid #d1d1d1',
                                  background: '#f9f9f9',
                                }
                              }}
                            />

                                                </Box>
                                                <br/>
                                                <Button color="primary" variant="contained" style={{
                                                    height: '56px',
                                                    backgroundColor: '#2B6FED',
                                                    color: '#ffffff',
                                                    borderRadius: '10px',
                                                    fontSize: '16px',
                                                    lineHeight: '20px'
                                                }} data-test-id='LogIn' fullWidth size="large"
                                                        type="submit"> <Typography
                                                    style={{fontWeight: 'bolder', color: '#ffffff'}}
                                                    className="login-h1 bold-text "> CHANGE
                                                    PASSWORD</Typography></Button>
                                            </Form>)}
                                    </Formik>

                                </Box>
                            </Box>
                        </Hidden>
                    </Grid>
                    <Grid item md={5} className="leftBox q">
                        <Hidden smDown>
                            <Box pt={2} className="right_image r" visibility={{xs: 'none', md: 'flex'}}>
                                <img src={Building1.default} style={webStyle.side_img}/>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const webStyle = {

    login_img1: {
        width: '116px',
        height: '45px',
    },
    side_img: {
        height: '95vh',
        width: '39vw',
        borderRadius: '40px'

    },
};
// Customizable Area End

