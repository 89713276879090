// Customizable Area Start
import React from "react";
import ComplexListingController, { Props } from "./ComplexListingController.web";
import { withRouter } from "react-router-dom";
import ComplexListingTable from "./ComplexListingTable.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class ComplexListing extends ComplexListingController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <ComplexListingTable navigation={navigation} id={"complex_listings"} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(ComplexListing);
// Customizable Area End
