// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Container,
  MenuItem,
  Divider,
  withStyles,
  Tooltip,
  TableContainer,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import JurisdictionFeedController, { Props } from "./JurisdictionFeedController.web";
import "./jurisdiction.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { withTranslation } from "react-i18next";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import Loader from "../../../../components/src/Loader.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { Form, Formik } from "formik";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { Menu as ReactMenu } from "@szhsin/react-menu";
import { desigenImg } from "../../../customform/src/assets";

export class JurisdictionFeed extends JurisdictionFeedController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes }: any = this.props;

    return (
      <MainScreen>
        <Dialog maxWidth="md" open={this.state.genericModalOpen} scroll="paper" fullWidth>
          <DialogHeader t={t} title="Add New Jurisdiction" onClose={this.handleModalClose} />
          <Formik
            initialValues={{
              name: "",
              country: "",
              currency: "",
              timezone: "",
              measurement: "",
              mobile: "",
              tax: "",
              zone: "",
              formula: "",
            }}
            enableReinitialize={true}
            validationSchema={this.validation}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, genericModalOpen: false }, () => {
                this.addJurisdiction(values);
                resetForm();
              });
            }}
          >
            {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid spacing={2} container>
                      <Grid item xs={6}>
                        <NormalTextField id="name" t={t} isLabel label="Jurisdiction Name" {...getFieldProps("name")} />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          id="currencySelect"
                          t={t}
                          label="Country"
                          option={this.state.countryList}
                          {...getFieldProps("country")}
                          onChange={(event: any) => {
                            const value = event.target.value;
                            setFieldValue("country", value);
                            this.getTimezoneList(value);
                          }}
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Currency"
                          option={this.state.currencyList}
                          {...getFieldProps("currency")}
                        />
                        {ErrorHandler(t, touched.currency, errors.currency)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Timezone"
                          option={this.state.timezoneList}
                          disabled={!values.country}
                          {...getFieldProps("timezone")}
                        />
                        {ErrorHandler(t, touched.timezone, errors.timezone)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Unit of Measurement"
                          option={[{ label: "Sq ft", value: "Sqft" }, { label: "Sq m", value: "Sqm" }]}
                          {...getFieldProps("measurement")}
                        />
                        {ErrorHandler(t, touched.measurement, errors.measurement)}
                      </Grid>
                      <Grid xs={6} item>
                        <NormalSelect
                          t={t}
                          label="Mobile Number Length"
                          option={[
                            { label: "9", value: "9" },
                            { label: "10", value: "10" },
                            { label: "11", value: "11" },
                          ]}
                          {...getFieldProps("mobile")}
                        />
                        {ErrorHandler(t, touched.mobile, errors.mobile)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField t={t} isLabel label="Value added tax" {...getFieldProps("tax")} />
                        {ErrorHandler(t, touched.tax, errors.tax)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          type="number"
                          t={t}
                          isLabel
                          label="Zone multipler"
                          {...getFieldProps("zone")}
                        />
                        {ErrorHandler(t, touched.zone, errors.zone)}
                      </Grid>
                      <Grid xs={12} item>
                        <NormalSelect
                          t={t}
                          label="Formula"
                          option={[
                            {
                              label: "Total building budget + contingency / area_of_complex * unit_size",
                              value: "Total building budget + contingency / area_of_complex * unit_size",
                            },
                            { label: "unit_size * zone_multiplier", value: "unit_size * zone_multiplier" },
                          ]}
                          {...getFieldProps("formula")}
                        />
                        {ErrorHandler(t, touched.formula, errors.formula)}
                      </Grid>
                      <Grid xs={12} item>
                        <Box className="add-meeting">
                          <Box className="create-audience" style={{ margin: 0 }}>
                            <p>{t("Default Renting Contract")}</p>
                            <span>{t("+ Add New Template")}</span>
                          </Box>
                          <Box style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                            {this.state.contractList.map((lease: any, index: number) => {
                              return (
                                <Box
                                  onClick={() => this.handleSelectedTemplate(lease.id)}
                                  key={index}
                                  style={{ cursor: "pointer", position: "relative" }}
                                >
                                  <Box sx={webStyle.editOrange}>
                                    <ReactMenu menuButton={<MoreVertIcon />}>
                                      <MenuItem className="edit-group-menu">{t("Edit")}</MenuItem>
                                      <MenuItem className="delete-group-menu">{t("Delete")}</MenuItem>
                                    </ReactMenu>
                                  </Box>
                                  <Box
                                    sx={
                                      Number(this.state.contractSelected) === Number(lease.id)
                                        ? webStyle.rentSelected
                                        : webStyle.rentStyle
                                    }
                                  >
                                    <Box className="leaseTemplate" style={webStyle.rentImageContainer}>
                                      <img className="leaseTemplate" src={desigenImg} />
                                    </Box>
                                    <Box
                                      sx={
                                        Number(this.state.contractSelected) === Number(lease.id)
                                          ? webStyle.rentSelectedTitle
                                          : webStyle.rentTitleStyle
                                      }
                                    >
                                      {lease.attributes.title}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleModalClose}>
                      {t("Cancel")}
                    </Button>
                    <Button id="btn-add" type="submit" className="add-button">
                      {t("Add")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <Box style={{ background: "#F4F7FF" }} className={classes.reportList}>
          <Grid item xs={12} md={12} sm={12} style={{ paddingTop: 35, minHeight: "95vh" }}>
            <Container>
              <Box className="navigation">
                <Box>
                  <Typography variant="body1">
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {t(" My Dashboards")}
                    </Box>{" "}
                    /{" "}
                    <Box className="reports-BudgetReportmain" component="span" style={{ color: "#2B6FED" }}>
                      {t("Manage Jurisdiction")}
                    </Box>
                  </Typography>

                  <Typography
                    variant="h5"
                    className="sub-heading bold-text reports-BudgetReportmain"
                    style={{ fontSize: "32px" }}
                  >
                    {t("Manage Jurisdiction")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box className="filter-head-box">
                  <FilterSelect
                    option={[{ label: "All", value: "all" }, ...this.state.countryList]}
                    t={t}
                    label="Select Company"
                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                      this.setState({ searchCountry: event.target.value })
                    }
                    value={this.state.searchCountry}
                    id="countrySearch"
                  />
                  <Button
                    onClick={this.handleFilterSubmit}
                    startIcon={<SearchIcon />}
                    className="await-search-filter-btn"
                  >
                    {t("Search")}
                  </Button>
                </Box>
                <CommonButton
                  onClick={() => {
                    this.setState({ genericModalOpen: true });
                  }}
                  t={t}
                  label="Add New Jurisdiction"
                  color="orange"
                  id="addJurisdiction"
                />
              </Box>

              <Grid className="table-box-card">
                <Grid item sm={12} md={12} xs={12}>
                  <Box className="table-top coo-table-head-box">
                    <h4 className="bold-text coo-table-heading">{t("List of Jurisdiction")}</h4>
                    <Box className="filter-head-box" style={{ margin: "0" }}>
                      <SearchInput onChange={this.handleSearchKeyUp} label="Search by ID" t={t} />
                    </Box>
                  </Box>
                  <Divider />
                  <TableContainer className="table-container" style={{ boxShadow: "none" }}>
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell className="bold-text">{t("#")}</TableCell>
                          <TableCell className="bold-text">{t("Jurisdiction Name")}</TableCell>
                          <TableCell className="bold-text">{t("Jurisdiction ID")}</TableCell>
                          <TableCell className="bold-text">{t("Country")}</TableCell>
                          <TableCell className="bold-text">{t("Total Complexes")}</TableCell>
                          <TableCell className="bold-text">{t("Total Buildings")}</TableCell>
                          <TableCell className="bold-text">{t("Currency")}</TableCell>
                          <TableCell className="bold-text">{t("Timezone")}</TableCell>
                          <TableCell className="bold-text" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.jurisdictionList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={6}>{t("No Jurisdiction Reports Available")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.jurisdictionList.map((item: any, key: any) => {
                          return (
                            <TableRow>
                              <TableCell className="tablecell">{key + 1}</TableCell>
                              <TableCell className="tablecell">
                                <Tooltip title={item && item.attributes && item.attributes.jurisdiction_name}>
                                  <div>
                                    {item && item.attributes && item.attributes.jurisdiction_name.length <= 10
                                      ? item && item.attributes && item.attributes.jurisdiction_name
                                      : item &&
                                        item.attributes &&
                                        item.attributes.jurisdiction_name.substring(0, 10).concat("..")}
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell className="tablecell">
                                {item && item.attributes && item.attributes.jurisdiction_id}
                              </TableCell>
                              <TableCell className="tablecell">
                                {item && item.attributes && item.attributes.country}
                              </TableCell>
                              <TableCell className="tablecell">
                                {item && item.attributes && item.attributes.total_complex}
                              </TableCell>
                              <TableCell className="tablecell">
                                {item && item.attributes && item.attributes.total_buildings}
                              </TableCell>
                              <TableCell className="tablecell">
                                {item && item.attributes && item.attributes.currency?.currency}
                              </TableCell>
                              <TableCell className="tablecell" style={{ textOverflow: "ellipsis" }}>
                                <Tooltip title={item.attributes && item.attributes.timezone}>
                                  <Box>
                                    {item && item.attributes.timezone.length <= 10
                                      ? item.attributes.timezone
                                      : item &&
                                        item.attributes &&
                                        item.attributes.timezone.substring(0, 10).concat("..")}
                                  </Box>
                                </Tooltip>
                              </TableCell>
                              <TableCell className="tablecell">
                                <ReactMenu menuButton={<MoreVertIcon />}>
                                  <MenuItem className="edit-group-menu">
                                    <Link to={`/CompanyEmployee/ViewJurisdiction/${item.id}`}>{t("View Details")}</Link>
                                  </MenuItem>
                                </ReactMenu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="table-bottom">
                    <PaginationModule
                      pagination={this.state.pagination}
                      handlePagination={this.handleJurisdictionPagination}
                      page={this.state.page}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Loader loading={this.state.loading} />
        </Box>
      </MainScreen>
    );
  }
}
const webStyle = {
  rentSelectedTitle: {
    marginBottom: "10px",
    color: "#2B6FED",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  rentImageContainer: {
    width: "max-content",
    marginBottom: "10px",
    padding: "20px",
    borderRadius: "100%",
    border: "5px solid #f7f9fe",
  },
  rentSelected: {
    alignItems: "center",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "8px",
    height: "127",
    width: "168",
    border: "2px solid #2b6fed",
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "fit-content",
    left: "-80px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  rentTitleStyle: {
    marginBottom: "10px",
    color: "#181d25",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  rentStyle: {
    marginRight: "10px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "8px",
    width: "168",
    border: "1px dashed #e4e4e4",
    height: "127",
  },
  editOrange: {
    position: "absolute",
    top: "10px",
    right: "20px",
    width: "15px",
    height: "15px",
  },
};

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(JurisdictionFeed)));
// Customizable Area End
