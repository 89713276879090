// Customizable Area Start
import React from "react";
import { Container, Typography, FormControl, withStyles, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FaqChairmanStyleWeb } from "./FaqChairmanStyle.web";
import ContactUsController, { Props } from "./ContactusController.web";
import { withTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { withRouter } from "react-router";
import { ErrorHandler, LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

class ContactUsChairman extends ContactUsController {
  constructor(props: Props) {
    super(props);
  }

  submitContactUs = async (value: any) => {
    const body = {
      data: {
        title: value.title,
        society_management_id: localStorage.getItem("society_id"),
        message: value.message,
        contact_us_category_id: value.category,
        mobile_number: value.mobile,
      },
    };

    this.CreateContactUsCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_contact_us/contacts`,
      body: JSON.stringify(body),
    });
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    // @ts-ignore
    const language = i18n?.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
          <Container className={classes.contactUs}>
            <Box className="navigation">
              <Box>
                <Typography variant="body1">
                  {t("Help")} /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Contact Us")}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text">
                  {t("Contact Us")}
                </Typography>
              </Box>
            </Box>
            <Box className="contact-us-form">
              <Typography variant="body1" style={{ color: "black", marginBottom: "15px" }}>
                {t(
                  "Thank you for contacting us and for being a loyal customer. Please use the form below to provide us with you inquiries or comments. Your input will be directed to the proper team for analysis and action."
                )}
              </Typography>

              <Grid xs={6} style={{ paddingTop: 35 }}>
                <Formik
                  initialValues={{
                    title: "",
                    category: "",
                    mobile: "",
                    message: "",
                  }}
                  validationSchema={this.contactUsValidation}
                  onSubmit={(values, { resetForm }) => {
                    this.submitContactUs(values);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit} translate="yes">
                        <FormControl fullWidth>
                          <input
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="title"
                            placeholder={t("Title")}
                            className="title-input"
                          />
                          {ErrorHandler(t, touched.title, errors.title)}
                        </FormControl>
                        <FormControl fullWidth>
                          <select className="select-input" value={values.category} onChange={handleChange} onBlur={handleBlur} name="category">
                            <option value="" disabled>
                              {t("Select Category")}
                            </option>
                            {this.state.categoryList.map((category: any) => {
                              return (
                                <option value={category.id} key={category.id}>
                                  {LanguageAttributeHandler(language, category.title, category.title_ar)}
                                </option>
                              );
                            })}
                          </select>
                          {ErrorHandler(t, touched.category, errors.category)}
                        </FormControl>
                        <FormControl fullWidth>
                          <input
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="mobile"
                            placeholder={t("Mobile Number")}
                            className="title-input"
                          />
                          {ErrorHandler(t, touched.mobile, errors.mobile)}
                        </FormControl>
                        <FormControl fullWidth>
                          <textarea
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="message"
                            placeholder={t("Message")}
                            className="textarea-input"
                          />
                          {ErrorHandler(t, touched.message, errors.message)}
                        </FormControl>
                        <Button variant="contained" fullWidth type="submit">
                          {t("Send Message")}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Box>
          </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(FaqChairmanStyleWeb)(withRouter(ContactUsChairman)));
// Customizable Area End
