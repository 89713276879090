// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  InputAdornment,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { desigenImg, editOrangeIcon } from "../assets";
import CompEmpComplexBlockStep2Controller, { Props } from "./CompEmpComplexBlockStep2Controller.web";
import { Formik, Form } from "formik";
import "../../assets/commonForm.css";
import { CloseIcon, Upload_Icon } from "../../../ContentManagement/src/assets";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import Loader from "../../../../components/src/Loader.web";
import AlertError from "../../../../components/src/AlertError.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";

export default class CompEmpComplexBlockStep2 extends CompEmpComplexBlockStep2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Formik
          initialValues={this.state.complexValues}
          validationSchema={this.validationSchema}
          enableReinitialize
          data-test-id="create-complex-form"
          onSubmit={(values) => this.setState({ loading: true }, () => this.handleSubmitComplex(values))}
        >
          {({ values, touched, errors, setFieldValue, handleChange, handleBlur }) => {
            return (
              <Form translate="yes" className="commonForms">
                <Box className="topBarFormik">
                  <Box>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Box>
                        <Typography
                          className="bold-text"
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                          }}
                        >
                          {t("Basic Details")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="Step" style={{ fontSize: "16px" }}>
                          {t("Step")} {this.props.step + 1} {t("of 7")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="step2forms step2Formik"
                      style={{
                        margin: "28px 0",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {values.logo ? (
                        <img
                          data-testid="preview_img"
                          src={typeof values.logo === "string" ? values.logo : URL.createObjectURL(values.logo)}
                          className="complex_building_step1"
                          alt="Preview"
                          style={webStyle.logoDisplay}
                        />
                      ) : (
                        <Box className="complex_building_step1" style={webStyle.logoDisplay} />
                      )}
                      <label htmlFor="logoChange" className="complex_building_step1">
                        <span style={{ color: "#FC8434" }} className="bold-text">
                          {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                        </span>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="logoChange"
                        className="step2Formik"
                        name="logo"
                        data-test-id="logo-upload"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const files = event.target.files;
                          if (files) {
                            const file = files[0];
                            setFieldValue("logo", file);
                          }
                        }}
                        style={{ display: "none" }}
                      />
                      {ErrorHandler(t, touched.logo, errors.logo)}
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.complexName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="complexName"
                          t={t}
                          language={language}
                          label={t("Complex Name")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.complexName, errors.complexName)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.totalArea}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="totalArea"
                          t={t}
                          language={language}
                          label={t("Total Area")}
                          isLabel
                          type="number"
                          endAdornment={
                            <InputAdornment position="end" className="measurement-adornment-select">
                              <Typography className="bold-text complex-measurement" style={webStyle.measurementText}>
                                {t(this.props.measurement)}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                      </Grid>
                      <Grid item xs={12}>
                        <label htmlFor="uploadPhotos" style={{ marginBottom: "8px", color: "black", fontSize: "14px" }}>
                          {t("Upload Photos")}
                        </label>
                        <Grid container spacing={4}>
                          <Grid item xs={3} style={{ marginBottom: "5px" }} className="edit-building">
                            <Box className="upload-photo" onClick={() => this.uploadImages.click()}>
                              <img src={Upload_Icon} alt="edit-complex-upload-icon" />
                            </Box>
                            <input
                              ref={(ref: unknown) => (this.uploadImages = ref)}
                              className="complex-details-image"
                              style={{ display: "none" }}
                              name="photos"
                              data-test-id="complex-block-file"
                              onBlur={handleBlur}
                              multiple
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files: any = e.target.files;
                                setFieldValue("images", [...values.images, ...files]);
                              }}
                              accept="image/*"
                              type="file"
                            />
                          </Grid>
                          {[...values.images].map((image: any, index: number) => {
                            return (
                              <Grid item xs={3} key={index}>
                                <Box className="building-image" style={{ position: "relative" }}>
                                  <img
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      right: "5px",
                                      top: "5px",
                                      width: "35px",
                                      height: "35px",
                                    }}
                                    className="delete-image"
                                    src={CloseIcon}
                                    onClick={() => {
                                      const remainImage = [...values.images].filter(
                                        (img: File, idx: number) => idx !== index
                                      );
                                      setFieldValue("images", remainImage);
                                    }}
                                  />
                                  <img
                                    src={typeof image === "string" ? image : URL.createObjectURL(image)}
                                    alt=""
                                    style={{ height: "124px", width: "100%", borderRadius: "8px" }}
                                  />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {ErrorHandler(t, touched.images, errors.images)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.totalBuilding}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="totalBuilding"
                          t={t}
                          language={language}
                          label={t("Total Buildings")}
                          type="number"
                          isLabel
                        />
                        {ErrorHandler(t, touched.totalBuilding, errors.totalBuilding)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.totalUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="totalUnit"
                          t={t}
                          language={language}
                          label={t("Total Units")}
                          type="number"
                          isLabel
                        />
                        {ErrorHandler(t, touched.totalUnit, errors.totalUnit)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextArea
                          value={values.aboutUs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="aboutUs"
                          t={t}
                          language={language}
                          label={t("About Us")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.aboutUs, errors.aboutUs)}
                      </Grid>
                      <Grid item xs={this.state.role !== "account_manager" ? 6 : 12}>
                        <NormalSelect
                          t={t}
                          language={language}
                          label="Currency"
                          value={values.currency}
                          name="currency"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          option={this.state.currencyList}
                          disabled={this.props.currency?.id}
                        />
                        {ErrorHandler(t, touched.currency, errors.currency)}
                      </Grid>
                      {this.state.role !== "account_manager" && (
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label="Account Manager"
                            value={values.accountManager}
                            name="accountManager"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            option={this.state.managerList}
                          />
                          {ErrorHandler(t, touched.accountManager, errors.accountManager)}
                        </Grid>
                      )}
                    </Grid>
                    <Box style={{ marginTop: "20px" }}>
                      <Box style={{ width: "100%" }}>
                        <InputLabel className="complex-rent-label">{t("Default Renting Contract:")}</InputLabel>
                        <Box className="leaseTemplate" style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                          {this.state.leaseList.map((lease: any, index: number) => {
                            return (
                              <Box
                                onClick={() => this.handleSelectedTemplate(lease)}
                                data-testid="leaseOpen"
                                key={index}
                                style={{ cursor: "pointer", position: "relative" }}
                              >
                                <img src={editOrangeIcon} style={webStyle.editOrange} />
                                <Box
                                  style={
                                    Number(this.state.rentalSelected) === Number(lease.id)
                                      ? webStyle.rentSelected
                                      : webStyle.rentStyle
                                  }
                                >
                                  <Box className="leaseTemplate" style={webStyle.rentImageContainer}>
                                    <img className="leaseTemplate" src={desigenImg} />
                                  </Box>
                                  <Box
                                    style={
                                      Number(this.state.rentalSelected) === Number(lease.id)
                                        ? webStyle.rentSelectedTitle
                                        : webStyle.rentTitleStyle
                                    }
                                  >
                                    {lease.attributes.title}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="step2BtnBox" style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
                  <Grid
                    className="step2Grid"
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      gap: "20px",
                    }}
                  >
                    <Grid className="step2Grid" style={{ marginTop: "30px" }}>
                      <Button style={webStyle.buttonSave} type="submit">
                        <span className="bold-text">{t("NEXT")}</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          data-test-id="complex-alert"
          message={t(this.state.error)}
        />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isTemplateOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title="Lease Template Preview" onClose={this.handleTemplateToggle} />
          <DialogContent dividers>
            <Box className="complex-lease-templete" style={{ maxHeight: "400px", overflow: "auto" }}>
              <div
                className="complex-template-html"
                dangerouslySetInnerHTML={{
                  __html: this.state.template?.attributes?.template,
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions className="complex-template-action dialog-button-group" style={{ gap: "10px" }}>
            <Button onClick={this.handleTemplateToggle} className="cancel-button">
              {t("Cancel")}
            </Button>
            <Button onClick={this.handleSelectTemplate} style={{ margin: "0" }} className="add-button select-btn">
              {t("Select")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const webStyle: any = {
  closeIcon: {
    right: "25px",
    position: "absolute",
    fill: "red",
    top: "5px",
    cursor: "pointer",
  },
  buttonSave: {
    width: "177px",
    height: "56px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
  },
  logoDisplay: {
    width: "66px",
    height: "66px",
    borderRadius: "50%",
    background: "#dcdcdc",
  },
  rentSelected: {
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "127",
    width: "168",
    border: "2px solid #2b6fed",
  },
  rentStyle: {
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "127",
    width: "168",
    border: "1px dashed #e4e4e4",
    boxShadow: "0px 6px 14px rgba(43,111,237,0.12)",
  },
  rentSelectedTitle: {
    marginBottom: "10px",
    color: "#2B6FED",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  rentTitleStyle: {
    marginBottom: "10px",
    color: "#181d25",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  measurement: { color: "rgb(140, 140, 140)" },
  rentImageContainer: {
    marginBottom: "10px",
    border: "5px solid #f7f9fe",
    padding: "20px",
    width: "max-content",
    borderRadius: "100%",
  },
  editOrange: {
    position: "absolute",
    top: "10px",
    right: "20px",
    width: "15px",
    height: "15px",
  },
};

// Customizable Area End
