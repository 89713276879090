// Customizable Area Start
import React from "react";
import ComplexAddUnitController, { Props } from "./ComplexAddUnitController.web";
import { withRouter } from "react-router-dom";
import ComplexAddUnitForm from "./ComplexAddUnitForm.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class ComplexAddUnit extends ComplexAddUnitController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <ComplexAddUnitForm companyId={this.state.complexId} navigation={navigation} id="complex_add_unit" />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(ComplexAddUnit);
// Customizable Area End
