// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
  companyId?: any;
  t: any;
  i18n: any;
}

interface S {}

interface SS {
  id: any;
}

export default class BuildingMainBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    window.history.pushState(null, document.title, window.location.href);
  }
}
// Customizable Area End
