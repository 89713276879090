// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { debounce } from "../../../../components/src/helperFunctions.web";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  complexId: string | number;
  type: string;
  complexType: string;

  invitationList: IInvitationDetail[];
  pagination: any;
  page: number;

  filter: {
    building: string;
    unit: string;
    search: string;
  };

  invitation: IInvitationDetail;
  isViewInvitationOpen: boolean;

  buildingList: any[];
  unitList: any[];
}

interface IInvitationDetail {
  name: string;
  country: string;
  city: string;
  complex: string;
  unit: string;
  sentOn: string;
  phone: string;
  role: string;
  status: string;
}

interface SS {
  id: any;
}

export default class CETotalAcceptRejectInviteController extends BlockComponent<Props, S, SS> {
  GetBuildingCallId: string = "";
  GetUnitCallId: string = "";
  GetTotalAcceptRejectListCallId: string = "";

  [key: string]: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.debouncedHandleFilterSubmit = debounce(this.handleFilterSubmit.bind(this), 300);

    this.state = {
      loading: false,
      complexId: "",
      type: "",
      complexType: "",

      invitationList: [],
      pagination: null,
      page: 1,

      filter: {
        building: "",
        unit: "",
        search: "",
      },

      invitation: {
        name: "",
        country: "",
        city: "",
        complex: "",
        unit: "",
        sentOn: "",
        phone: "",
        role: "",
        status: "",
      },
      isViewInvitationOpen: false,

      buildingList: [],
      unitList: [],
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetTotalAcceptRejectListCallId:
          return this.handleTableListResponse(responseJsonBlock);
        case this.GetBuildingCallId:
          return this.handleBuildingListResponse(responseJsonBlock);
        case this.GetUnitCallId:
          return this.handleUnitListResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    const page_type = this.props.navigation.getParam("type");
    const complex_type = this.props.navigation.getParam("complex");
    this.setState({ complexId: complex_id, type: page_type, complexType: complex_type }, () => {
      this.getTableList();

      if (complex_type === "Complex") {
        this.getBuildingList(complex_id);
      } else {
        this.getUnitList(complex_id, "");
      }
    });
  }

  getTableList = async () => {
    let endPoint = "";
    if (this.state.type === "Total") {
      endPoint = "bx_block_request_management/employee_request_mangements/recieved_joinee_request";
    } else if (this.state.type === "Accept") {
      endPoint = "bx_block_request_management/employee_request_mangements/accepted_invitation";
    } else {
      endPoint = "bx_block_request_management/employee_request_mangements/rejected_invitation";
    }
    this.GetTotalAcceptRejectListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${endPoint}?complex=${this.state.complexId}&building_id=${this.state.filter.building}&apartment_management_id=${this.state.filter.unit}&search_term=${this.state.filter.search}&page=${this.state.page}`,
    });
  };

  handleTableListResponse = (responseJson: any) => {
    if (this.state.type !== "Total") {
      if (responseJson && responseJson.member_invitations) {
        this.setState({
          invitationList: responseJson.member_invitations.data.map((item: any) => ({
            name: item.attributes.full_name,
            country: item.attributes.country,
            city: item.attributes.city,
            complex: item.attributes.complex.name,
            unit: item.attributes?.apartment_management?.apartment_name || "-",
            sentOn: item.attributes.created_at,
            phone: item.attributes.phone_number,
            role: item.attributes.role.name,
            status: item.attributes.status,
          })),
          pagination: responseJson.meta.pagination,
        });
      }
    } else {
      if (responseJson && responseJson.requests) {
        this.setState({
          invitationList: responseJson.requests.data.map((item: any) => ({
            name: item.attributes.name,
            country: item.attributes.country,
            city: item.attributes.city,
            complex: item.attributes.society_management.name,
            unit: item.attributes.unit_number,
            sentOn: item.attributes.created_at,
            phone: item.attributes.phone,
            role: item.attributes.role,
            status: item.attributes.status,
          })),
          pagination: responseJson.meta.pagination,
        });
      }
    }
  };

  handleDebounceSearch = (event: any) => {
    this.setState({ filter: { ...this.state.filter, search: event.target.value } }, () => {
      this.debouncedHandleFilterSubmit();
    });
  };

  handleFilterSubmit = () => {
    this.getTableList();
  };

  handleHeader = () => {
    if (this.state.type === "Total") {
      return "Total received join requests";
    }
    return this.state.type === "Accept" ? "Accepted Invitations by users" : "Rejected Invitation by users";
  };

  handleViewInvitationModal = () => {
    this.setState({ isViewInvitationOpen: !this.state.isViewInvitationOpen });
  };

  viewDetail = (invitation: any) => {
    this.setState({ invitation: invitation, isViewInvitationOpen: true });
  };

  getBuildingList = async (complexId: string | number) => {
    this.GetBuildingCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleBuildingListResponse = (responseJson: { buildings: { name: string; id: number | string }[] }) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: { name: string; id: number | string }) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  getUnitList = async (complexId: string | number, buildingId: string | number) => {
    this.GetUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${complexId}&building_management_id=${buildingId}`,
    });
  };

  handleUnitListResponse = (responseJsonBlock: any) => {
    this.setState({
      unitList: responseJsonBlock.data.map((item: any) => ({ value: item.id, label: item.attributes.apartment_name })),
    });
  };

  handleChangeValue = (event: any, stateParam: string) => {
    const value = event.target.value;
    if (stateParam === "Building") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            building: value,
            unit: "",
          },
        },
        () => {
          this.getUnitList(this.state.complexId, value);
        }
      );
    } else if (stateParam === "Unit") {
      this.setState({
        filter: {
          ...this.state.filter,
          unit: value,
        },
      });
    }
  };

  handleInvitePagination = (event: any, value: any) => {
    this.setState({ page: value }, () => {
      this.getTableList();
    });
  };
}
// Customizable Area End
