import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require('react-navigation');
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id:string;
    navigation: typeof navigation;
    t: (value: string) => string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    sortBy: string;
    popoverAncher: HTMLElement | null;
    residentsData: Array<RegisterOwnerResponseItem>;
    country: string;
    status: string;
    building: string;
    buildingId: string;
    unit: string;
    unitId: string;
    complex: string;
    selectComplex: string;
    city: string;
    searchName: string;
    tableData: number;
    showError: boolean;
    errorMessage: string;
    loader: boolean;
    page: number | null;
    paginationData: PaginationData | null;
    countryList: Array<string>;
    cityList: Array<string>;
    complexList: Array<ComplexListItem>;
    buildingList: Array<BuildingListItem>;
    societyManagementId: string;
    // Customizable Area End
}
// Customizable Area Start

export interface CountryListResponse {
    data: {
        countries: Array<string>
    }
}

export interface CityListResponse {
    data: {
        cities: Array<string>;
    }
}

export interface ComplexListResponse {
    data: Array<ComplexListItem>;
}

export interface ComplexListItem {
    id: string;
    attributes: {
        name: string;
    }
}

export interface BuildingListResponse {
    data: {
        buildings: Array<BuildingListItem>;
    }
}

export interface BuildingListItem {
    id: number;
    name: string;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface RegisterOwnerResponseItem {
    id: string;
    type: string;
    attributes: {
        id: number;
        resident_name: string | null;
        resident_id: number;
        complex_name: {
            id: number;
            name: string | null;
        },
        building_name: {
            id: number;
            name: string | null;
        },
        status: string;
    }
};

export type TFunction = (value: string) => string;

export interface RegisterResidentsResponse {
    data: Array<RegisterOwnerResponseItem>;
    meta: {
        pagination: {
            current_page: null | number;
            next_page: null | number;
            prev_page: null | number;
            total_pages: null | number;
            total_count: null | number;
        }
    }
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
    contract: string
};

export interface PaginationData {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class RegisteredResidentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    registerResidentsDataApiCallId: string = "";
    countryApiCallId: string = "";
    cityApiCallId: string = "";
    complexListApiCallId: string = "";
    buildingListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            sortBy: "",
            popoverAncher: null,
            residentsData:[],
            country: "",
            status: "",
            building: "",
            buildingId: "",
            complex: "",
            unit: "",
            unitId: "",
            city: "",
            selectComplex: "",
            searchName: "",
            tableData: 1,
            showError: false,
            errorMessage: "",
            loader: false,
            page: 0,
            paginationData: {
                "current_page": null,
                "next_page": null,
                "prev_page": null,
                "total_pages": null,
                "total_count": null
            },
            countryList:[],
            cityList:[],
            complexList:[],
            buildingList:[],
            societyManagementId: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson?.errors) || responseJson?.contracts) {
                this.registeredResidentsApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.registeredResidetnsApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    registeredOwnerApiCall = async (data: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = data;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    registeredResidentsApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: RegisterResidentsResponse & CountryListResponse & CityListResponse & ComplexListResponse & BuildingListResponse) => {
        if (apiRequestCallId === this.registerResidentsDataApiCallId) {
            this.registeredResidentsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListSucessCallBack(responseJson);
        }
    };

    registeredResidetnsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.registerResidentsDataApiCallId) {
            this.registeredResidentsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListFailureCallBack(responseJson);
        }
    };

    async componentDidMount() {
        super.componentDidMount();
        this.getCountryList();
        this.getRegisteredResidents("1")
    };

    getRegisteredResidents = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.registeredResidents}page=${page}`;
        let { country, city, selectComplex, building, status, searchName, sortBy } = this.state;
        if (country) {
            endPoint = endPoint + `&country=${country}`
        }
        if (city) {
            endPoint = endPoint + `&city=${city}`
        }
        if (selectComplex) {
            endPoint = endPoint + `&complex=${selectComplex}`
        }
        if (building) {
            endPoint = endPoint + `&building=${building}`
        }
        if (searchName) {
            endPoint = endPoint + `&searchName=${searchName}`
        }
        if (status) {
            endPoint = endPoint + `&status=${status}`
        }
        if (sortBy) {
            endPoint = endPoint + `&sortBy=${sortBy}`
        }
        this.registerResidentsDataApiCallId = await this.registeredOwnerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    registeredResidentsSucessCallBack = (response: RegisterResidentsResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ residentsData: response.data, paginationData: response.meta.pagination  });
        } else {
            this.setState({
                residentsData: response.data,
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                },
                loader: false
            });
        }
    };

    registeredResidentsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCountryList = async () => {
        this.countryApiCallId = await this.registeredOwnerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint
        });
    };

    countryListSucessCallBack = (response: CountryListResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityApiCallId = await this.registeredOwnerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndpoint}${this.state.country}`
        });
    };

    cityListSucessCallBack = (response: CityListResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getComplexList = async (complex: boolean) => {
        this.complexListApiCallId = await this.registeredOwnerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.complexListEndPoint}city=${this.state.city}&is_building=${complex}`
        });
    };

    complexListSucessCallBack = (response: ComplexListResponse) => {
        this.setState({ complexList: response.data });
    };

    complexListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getBuildingList = async () => {
        this.buildingListApiCallId = await this.registeredOwnerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.buidlingListEndPoint}${this.state.societyManagementId}`
        });
    };

    buildingListSucessCallBack = (response: BuildingListResponse) => {
        this.setState({ buildingList: response.data.buildings });
    };

    buildingListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ popoverAncher: event.currentTarget });
    };

    handleClosePopover = () => {
        this.setState({ popoverAncher: null});
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            country: event.target.value as string,
        }, () => {
            this.getCityList();        
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string });
    };
    
    handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ complex: event.target.value as string },()=>{
            if(this.state.complex === "Complex"){
                this.getComplexList(true)
            }else if(this.state.complex === "Individual Building"){
                this.getComplexList(false)
            }
        });
    };
    
    handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ building: event.target.value as string });
    };

    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ status: event.target.value as string });
    };

    handleSelectComplex = (event: React.ChangeEvent<{ value: unknown }>) => {
        let complex = this.state.complexList.find((complex) => {
            return complex.attributes.name === event.target.value as string
        });
        if (complex) {
            this.setState({ selectComplex: event.target.value as string, societyManagementId: complex?.id }, () => {
                this.getBuildingList();
            });
        }
    };

    handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string }, () => {
            this.getRegisteredResidents("1");
        });
    };

    handleSearchName = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ searchName: event.target.value as string }, ()=> {
            this.getRegisteredResidents("1")
        });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getRegisteredResidents(String(value))
    };

    handleErrorAlert = () => {
        this.setState({ showError: false });
    };

    handleViewDetails = async (userId: string) => {
        await setStorageData("userId", userId);
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "DashboardProfile");
        this.send(msgData);
    };

    handleNavigation = (path: string) => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        this.send(msgData);
    };
    // Customizable Area End
}
