// Customizable Area Start
import React from "react";
import { Container, Typography, Button, withStyles, Card } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CEActionsMeController, { Props } from "./CEActionsMeController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEBuildingIcon, CECalendarIcon, CERealEstateIcon, CEUserIcon } from "../assets";
import moment from "moment";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
// Customizable Area End

class CEActionsMe extends CEActionsMeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-header-box">
              <Typography variant="body1" className="action-me-header-text">
                <span data-test-id="me-navigation" onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>{t("My Dashboards")}</span> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Actions assigned to me")}
                </Box>
              </Typography>
              <Box className="sub-heading-box action-me-heading-text">
                <Typography variant="h5" className="bold-text">
                  {t("Actions assigned to me")}
                </Typography>
              </Box>
              <Box className="filter-head-box action-me-select-filter-box">
                <FilterSelect
                  t={t}
                  language={language}
                  label="Real Estate Company"
                  value={this.state.reactEstate}
                  onChange={(event: any) => this.setState({ reactEstate: event.target.value })}
                  option={[{ label: "All", value: "all" }, ...this.state.meReactStateList.map((item: any) => ({ label: item.company_name, value: item.id }))]}
                  data-test-id="action-select-real-estate"
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Complex"
                  value={this.state.complex}
                  onChange={(event: any) => this.setState({ complex: event.target.value })}
                  data-test-id="action-select-complex"
                  option={[{ label: "All", value: "all" }, ...this.state.meComplexList.map((item: any) => ({ label: item.name, value: item.id }))]}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Building"
                  value={this.state.building}
                  data-test-id="action-select-building"
                  onChange={(event: any) => this.setState({ building: event.target.value })}
                  option={[{ label: "All", value: "all" }, ...this.state.meBuildingList.map((item: any) => ({ label: item.name, value: item.id }))]}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Assign By"
                  data-test-id="action-select-assign-by"
                  value={this.state.assign}
                  onChange={(event: any) => this.setState({ assign: event.target.value })}
                  option={[{ label: "All", value: "all" }, ...this.state.meAssignList.map((item: any) => ({ label: item.full_name, value: item.id }))]}
                />
                <Button
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterValue: {
                        reactEstate: this.state.reactEstate,
                        complex: this.state.complex,
                        building: this.state.building,
                        assign: this.state.assign,
                      },
                    });
                  }}
                  className="action-search-btn action-me-search"
                  startIcon={<SearchIcon />}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="action-content-main-box">
              <Grid container spacing={2}>
                {this.state.actionAssignTaskList.map((task: any) => {
                  return (
                    <Grid item sm={12} className="action-me-content-box" key={task.id}>
                      <Card className="action-card">
                        <h4 className="bold-text">{task.attributes.title}</h4>
                        <p className="description">{task.attributes.description}</p>
                        <Box className="action-info-box action-me-info-content">
                          <Box className="action-content-box">
                            <Box className="action-column-box">
                              <Box className="action-info action-me-action-box">
                                <img src={CERealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{this.handleMeCompanyName(task.attributes)}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-me-action-box">
                                <img src={CEUserIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned by")}</span>
                                  <p className="bold-text">{task.attributes.assign_by}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box">
                              <Box className="action-info action-me-action-box">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Complex")}</span>
                                  <p className="bold-text">{task.attributes.society_management?.name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-me-action-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned on")}</span>
                                  <p className="bold-text">
                                    {moment(task.attributes.assign_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}
                                  </p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box action-me-action-box">
                              <Box className="action-info">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Building")}</span>
                                  <p className="bold-text">{task.attributes.building_management?.name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info">
                                <img src={CECalendarIcon} alt="calender-icon" />
                                <Box className="action-me-action-box">
                                  <span>{t("Due on")}</span>
                                  <p className="bold-text action-date">
                                    {moment(task.attributes.due_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}
                                  </p>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CEActionsMeStyleWeb: any = {
  generalDashboard: {
    background: "rgb(244, 247, 255)",
    "& .navigation": {
      flexDirection: "column",
      alignItems: "flex-start",
      display: "flex",
      "& .sub-heading-box": {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        alignItems: "center",
        marginTop: "14px",
        "& h5": {
          fontSize: "28px",
          fontWeight: "600",
        },
      },
    },
    "& .action-heading-btn-box": {
      alignItems: "center",
      display: "flex",
      gap: "14px",
      "& button": {
        borderRadius: "8px",
        minWidth: "auto",
        fontSize: "16px",
        padding: "6px 20px",
        boxShadow: "none",
        fontWeight: "600",
        border: "1px solid #FE8335",
        minHeight: "auto",
      },
      "& .action-progress-btn": {
        color: "#FE8335",
        background: "white",
      },
      "& .action-assign-btn": {
        color: "white",
        background: "#FE8335",
      },
    },
    "& .select-box": {
      alignItems: "center",
      display: "flex",
      gap: "14px",
      margin: "0",
    },
    "& .action-content-main-box": {
      margin: "10px 0 50px"
    },
    "& .action-card": {
      borderRadius: 8,
      background: "#fff",
      padding: "20px",
      boxShadow: "4px 0px 14px #e9e9e9",
      "& .action-info-box": {
        alignItems: "end",
        display: "flex",
        justifyContent: "space-between",
        "& .action-info": {
          alignItems: "center",
          display: "flex",
          gap: "15px",
          "& span": {
            color: "#FC8434",
            fontSize: "14px",
          },
          "& p": {
            fontWeight: "600",
          },
        },
        "& button": {
          borderRadius: "8px",
          background: "#2B6FED",
          color: "white",
          fontSize: "14px",
          padding: "8px 20px",
          textTransform: "capitalize",
          fontWeight: "600",
        },
        "& .action-content-box": {
          alignItems: "center",
          display: "flex",
          gap: "150px",
        },
        "& .action-column-box": {
          flexDirection: "column",
          display: "flex",
          gap: "14px",
        },
      },
      "& p.description": {
        margin: "16px 0",
      },
    },
    "& a": {
      textDecoration: "none !important",
      color: "black",
    },

    "& .content-boxes .top-content .heading": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      "& span": {
        color: "#FC8434",
        fontWeight: "600",
      },
    },
    "& .content-boxes .top-content .right-content": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    "& .content-boxes": {
      borderRadius: "8px",
      background: "white",
      marginTop: "15px",
      marginBottom: "30px",
      boxShadow: "4px 0px 14px #e9e9e9",
    },
    "& .content-boxes .top-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    "& .content-boxes .top-content .right-content span": {
      color: "#FC8434",
      fontWeight: 600,
    },
    "& .content-boxes .top-content .heading h2": {
      fontWeight: 600,
      fontSize: "22px",
    },
    "& .content-boxes .unit-table th": {
      fontWeight: 600,
    },
    "& .content-boxes .unit-select": {
      borderRadius: "8px",
      padding: "12px 40px 12px 10px",
      fontWeight: 600,
      border: "1px solid lightgray",
    },
    "& .content-boxes .search-unit .MuiInput-root": {
      border: "1px solid lightgray",
      padding: "5px 10px",
      borderRadius: "8px",
    },
    "& .content-boxes .MuiTableCell-root": {
      borderBottom: "1px solid #f7f7f7",
      padding: "12px 16px",
    },
    "& .content-boxes .search-unit svg": {
      fill: "darkgrey",
    },
    "& .content-boxes .unit-pagination": {
      display: "flex",
      padding: "20px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .content-boxes .unit-pagination p": {
      fontWeight: 600,
    },
    "& .content-boxes .unit-pagination p span": {
      color: "#FC8434",
      fontWeight: 600,
    },
    "& .content-boxes .search-unit .MuiInput-root::after": {
      outline: "none",
      content: "",
      position: "unset",
      border: 0,
    },
    "& .content-boxes.ticket-table input": {
      width: "220px",
    },
    "& .upcoming-events-box": {
      marginBottom: "30px",
    },
    "& input::placeholder": {
      opacity: 1,
      color: "black",
    },
    "& input:-ms-input-placeholde": {
      color: "black",
    },
    "& .content-boxes .search-unit .MuiInput-root::before": {
      outline: "none",
      border: 0,
      position: "unset",
      content: "",
    },
    "& .content-boxes .MuiPaginationItem-page.Mui-selected": {
      border: "0",
      background: "#FC8434",
      color: "white",
    },
    "& .content-boxes.ticket-table": {
      marginTop: "0",
    },
    "& input::-ms-input-placeholder": {
      color: "black",
    },
    "& .action-progress-table .action-view-menu button": {
      padding: "0",
    },
  },
};
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsMe)));
// Customizable Area End
