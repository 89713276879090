// Customizable Area Start
import React from "react";
import "./MyTeam.web.css"
import {
    Backdrop,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Fade,
    IconButton,
    Link,
    Modal,
    Typography,
} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {chat, email, profileExp, telephone} from "./assets"
import {CheckIcon} from "../../user-profile-basic/src/assets"
import {teamUser} from "./assets";
// Icons

import MoreVertIcon from '@material-ui/icons/MoreVert';
// Icons
import MyTeamController, {Props,} from "./MyTeamController";
import "../../../web/src/assets/css/style.scss";
import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import {useTranslation, withTranslation} from 'react-i18next';
import '../../../web/src/i18n.js';
import { Menu, MenuItem } from "@szhsin/react-menu";
import {withStyles} from "@material-ui/core/styles";
import AddTeamModal from "./AddTeamModal.web";
import {ROLE} from "../../../framework/src/Enum";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class MyTeamMain extends MyTeamController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
    const {t,i18n} = this.props
    const {language} = i18n
    return (
      <>
    <GeneralHeader>
            <Container className="link-decoration">
                <Box className="navigation">
                    <Box>
                        <Typography variant="body1" >
                            {t("My Team")} / <Box component="span" style={{color: "blue"}}>{t("Team Members")}</Box>
                        </Typography>
                        <Typography variant="h5" className="subHeading bold-text myTeamHeading" style={{fontSize:"28px"}}>{t("Team Members")}</Typography>
                    </Box>
                    <Box>
                        <AcceptButton variant="outlined" className="myTeamMain" onClick={(e) => this.setState({setOpen:true , editId: ''})} style={{width:"220px",height:"40px",fontSize:"16px",border:"none"}}>{t("Create new Member")}</AcceptButton>
                    </Box>
                </Box>
                <Box className="RecentItems">
                    <Typography className="Recenttitle bold-text" style={{fontSize:"22px"}}>{t("Pending Request")}({this.state.pendingReq.length})</Typography>
                </Box>

                <Grid container spacing={3} style={{marginTop: 5, marginBottom:30}}>
                    {
                        this.state.pendingReq.length > 0 ?
                            this.state.pendingReq.map((item:any,key:any)=> {
                                if(key < 4){
                                    return(
                                        <TeamCard key={key} data={item.attributes} history={this.props.history} approval={true} approvalFnc={(type:any,id:any)=> this.approvalFnc(type,id)} handleDelete={(id:any) => this.handleDeleteModal(id)} openChat={this.openChat} handleEdit={(id:any) => this.handleEdit(id)}/>
                                    )
                                }
                          })
                          :
                          <Box style={{marginLeft:"25px"}}>
                              <Typography variant={"body1"} style={{fontWeight:"bold"}} color="textSecondary" >
                                  {t("No Pending Request!")}
                              </Typography>
                          </Box>
                    }
                </Grid>
                <Box className="RecentItems">
                    <Typography className="Recenttitle bold-text" style={{fontSize:"22px"}}>{t("Core Members")}({this.state.coreMembers.length})</Typography>
                    {
                        this.state.coreMembers.length > 3 &&
                        <Link href="/TeamMembers/Core_member" >
                            <Typography className="ViewAll">{t("View All")}</Typography>
                        </Link>
                    }
                </Box>
                <Grid container spacing={3} style={{marginTop: 5, marginBottom:30}}>
                    {
                        this.state.coreMembers.length > 0 ?
                        this.state.coreMembers.map((item:any,key:any)=> {
                            if(key < 4){
                                return(
                                    <TeamCard data={item.attributes} history={this.props.history} approval={false} handleDelete={(id:any) => this.handleDeleteModal(id)} openChat={this.openChat} handleEdit={(id:any) => this.handleEdit(id)}/>
                                )
                            }
                        })
                        :
                        <Box style={{marginLeft:"25px"}}>
                            <Typography variant={"body1"} style={{fontWeight:"bold"}} color="textSecondary" >
                                {t("No User Found")}
                            </Typography>
                        </Box>
                    }
                </Grid>
                <Box className="RecentItems">
                    <Typography className="Recenttitle bold-text" style={{fontSize:"22px"}}>{t("Sub Team")} ({this.state.subTeam.length})</Typography>
                    {
                        this.state.subTeam.length > 3 &&
                        <Link href="/TeamMembers/Sub_team" >
                            <Typography className="ViewAll">{t("View All")}</Typography>
                        </Link>
                    }
                </Box>
                <Grid container spacing={3} style={{marginTop: 5, marginBottom:30}}>
                    {
                        this.state.subTeam.length > 0 ?
                            this.state.subTeam.map((item:any,key:any)=> {
                                if(key < 4) {
                                    return (
                                        <TeamCard key={key} data={item.attributes} history={this.props.history} approval={false} handleDelete={(id:any) => this.handleDeleteModal(id)} openChat={this.openChat} handleEdit={(id:any) => this.handleEdit(id)}/>
                                    )
                                }
                            })
                            :
                            <Box style={{marginLeft:"25px"}}>
                                <Typography variant={"body1"} style={{fontWeight:"bold"}} color="textSecondary" >
                                    {t("No User Found")}
                                </Typography>
                            </Box>
                    }
                </Grid>
                <Box className="RecentItems">
                    <Typography className="Recenttitle bold-text" style={{fontSize:"22px"}}>{t("Service Providers")}{" "}{"("}{this.state.providers.length}{")"}</Typography>
                    {
                        this.state.providers.length > 3 &&
                            <Link href="/TeamMembers/Service_provider" >
                                <Typography className="ViewAll">{t("View All")}</Typography>
                            </Link>
                    }
                </Box>
                <Grid container spacing={3} style={{marginTop: 5, marginBottom:30}}>
                    {
                        this.state.providers.length > 0 ?
                            this.state.providers.map((item:any,key:any)=> {
                                if(key < 4) {
                                    return (
                                        <TeamCard data={item.attributes} history={this.props.history} approval={false} handleDelete={(id:any) => this.handleDeleteModal(id)} openChat={this.openChat} handleEdit={(id:any) => this.handleEdit(id)}/>
                                    )
                                }
                            })
                            :
                            <Box style={{marginLeft:"25px"}}>
                                <Typography variant={"body1"} style={{fontWeight:"bold"}} color="textSecondary" >
                                    {t("No User Found")}
                                </Typography>
                            </Box>
                    }
                </Grid>
            </Container>
    </GeneralHeader>
      <Modal
          style={dashBoard.modal}
          open={Boolean(this.state.setOpen)}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          dir={languageCondition(language,"rtl","ltr")}
          BackdropProps={{
              timeout: 500,
          }}>
          <Fade in={Boolean(this.state.setOpen)}>
              {/*@ts-ignore*/}
              <AddTeamModal editId={this.state.editId}/>
          </Fade>
      </Modal>
      <Dialog
          fullWidth
          onClose={() => this.setState({deleteModal:false})}
          open={this.state.deleteModal}
          className="cancel-meeting-dialog"
          dir={languageCondition(language,"rtl","ltr")}
      >
          <DialogContent style={{ margin: "15px 0" }}>
              <Box textAlign="center">
                  <img className="comment-image" src={CheckIcon} alt="check" />
                  <Typography variant="h6" className="bold-text" style={{fontSize:"22px"}}>{t("Remove Team Member")}</Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px",fontSize:"16px" }}>
                      {t("User will be removed from the team")}<br/>
                      {t("Are you sure you want to remove user?")}
                  </Typography>
                  <DialogActions className="dialog-button-group">
                      <Button className="cancel-button" style={{ width: "200px",height:"55px",marginRight:"10px",marginLeft:"10px" }} onClick={() => this.setState({deleteModal:false})}>
                          {t("Close")}
                      </Button>
                      <Button style={{ width: "200px",height:"55px" }} className="add-button" onClick={()=> this.deleteMember(this.state.deleteId)}>
                          {t("Remove")}
                      </Button>
                  </DialogActions>
              </Box>
          </DialogContent>
      </Dialog>
        <Loader loading={this.state.loading} />
     </>
      );
  }
}

const dashBoard = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHeading: {
        fontWeight: 600,
        // marginTop: 15,
    },
    invitationCont:{
        fontWeight: 600,
        margin:'10px 0px 10px 0px'
    },
    inviteTitle:{
        margin:'10px 0px 10px 0px'
    },
    SideBar: {
        background: "#f9f6f6",
        position: "relative",
        paddingBottom: 150,
    },
    gaMemberCard:{
        display: "grid",
        gridTemplateColumns: "4fr 4fr 4fr",
        gap: 20
    },
    managementPaper:{
        padding:20
    },
    imgRound:{
        border: "2px solid #F7F9FE",
        borderRadius: "100%",
        height: 50,
        width: 50
    },
    mailIcon:{
        padding:8
    },
    invitemember:{
        border: "2px solid #F7F9FE",
        borderRadius: "100%",
        height: 50,
        width: 50,
        backgroundColor:"#FC8434"
    },
    inviteIcon:{
        padding:13
    },
    cancleIcon:{
        position:"absolute",
        top:15,
        right:15
    },
    modalCacle:{
        top:15,
        right:15,
        float:"right",
        cursor:"pointer"
    },
    invitationReq:{
        marginTop:30
    },
    facility: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom:"1px solid #f8f8f8",
        cursor:"pointer"
    },
    modal:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#fff",
        borderRadius: '10px',
        // boxShadow: theme.shadows[5],
        padding: "16px 32px 24px",
        width:"700px"
    },
    formLabels:{
        paddingLeft:35
    },
    labelsStyle:{
        color:"#212121",
        margin:"10px 0px 10px 0px"
    },
    formLeftIcn:{
        position:"absolute",
        left: 20,
        top: 44,
        color: "#b9b9b9"
    },
    inviteInput:{
        padding: "18px 18px 18px 50px",
        color: "#b5b5b5",
        borderRadius: "10px",
        border: "1px solid #e9dede",
        backgroundColor: "#f9f9f9",
        fontSize: "16px",
        outline: 0,
        width:"100%"
    }
};


const TeamCard = (props:any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userType = localStorage.getItem("selectUserType")
    const {data} = props
    const { t,i18n } = useTranslation();
    const language = i18n.language;
    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setAnchorEl(null);
        props.handleEdit(data)
    }

    const handleDelete = () => {
        setAnchorEl(null);
        props.handleDelete(data.id)
    }

    const approval = (type:any) => {
        props.approvalFnc(type,data.id)
    }

    return(
        <Grid item sm={4} md={3} xs={12} style={{position:"relative",height:"100%"}}>
            <Box style={{position:"absolute",top:"10px",right:"10px"}}>
                {
                    (data?.role !== ROLE.CHAIRMAN && data?.role !== ROLE.VICE_CHAIRMAN) &&
                    <div className="myTeam-menu" style={{position:"relative",top:"5px",right:"5px"}}>
                        <Menu
                            menuButton={
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        >
                            <MenuItem onClick={handleEdit}>{t("Edit Details")}</MenuItem>
                            <MenuItem onClick={handleDelete}>{t("Remove Member")}</MenuItem>
                        </Menu>
                    </div>

                }
            </Box>
            <Card className="EventsCards" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                <Box style={{width:"100%",display:'flex',justifyContent:"center",alignItems:"center",flexDirection:"column",marginTop:"15px"}} onClick={() => props.history.push(`/TeamMember/userDetails?id=${data.id}`)}>
                    {
                        props.approval && userType === "Manager" &&
                        <Typography variant="subtitle2" className={"statusOngoingRed"} gutterBottom style={{marginBottom: "12px"}}>{t("Pending Approval")}</Typography>
                    }
                    <img className="myTeamMainProfilePic" src={data?.user_profile?.data?.attributes?.profile_pic?.url || teamUser} height="60px" width="60px" style={{borderRadius:"100px"}}  />
                    <Typography variant="h6" style={{fontWeight:"bold",marginBottom:"5px"}}>{t(data?.role)}</Typography>
                    <Typography variant="h6" gutterBottom style={{marginBottom:"10px"}}>{data?.name}</Typography>
                    <Grid container spacing={1} style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",minHeight:"90px"}}>
                        {
                            data?.role_list?.length > 0 &&
                                data?.role_list?.map((item:any,key:any)=> {
                                    if(key < 3){
                                        return(
                                            <Grid item key={key} style={{marginBottom:"10px"}}>
                                                <Typography  key={key} variant="subtitle2" className={"statusOngoingBlue"} gutterBottom>{t(item)}</Typography>
                                            </Grid>
                                        )
                                    }
                                })
                        }
                    </Grid>
                </Box>
                <Box style={{width:"100%",display:'flex',justifyContent:"center",alignItems:"center",flexDirection:"column",marginTop:"15px"}}>
                    <Box style={{display:'flex'}}>
                        <IconButton id="teamChatButton" style={{backgroundColor:"rgba(252,52,52,.1)",marginRight:"8px"}} onClick={()=>props.openChat(data)} >
                            <img src={chat} />
                        </IconButton>
                        <IconButton style={{backgroundColor:"rgba(252,52,52,.1)",marginRight:"8px"}} onClick={()=> window.location.href = `mailto:${data.email}`}>
                            <img src={email} />
                        </IconButton>
                        <IconButton style={languageCondition(language,{backgroundColor:"rgba(252,52,52,.1)",marginRight:"8px"},{backgroundColor:"rgba(252,52,52,.1)"})} onClick={()=> window.location.href = `tel:${data.phone_number}`}>
                            <img src={telephone} />
                        </IconButton>
                    </Box>
                    {
                        props.approval && userType === "Chairman" &&
                        <Grid container spacing={2} style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-evenly",marginBottom:"0px",marginTop:"10px"}}>
                                <DeclineButton variant="contained" style={{width:"110px"}} fullWidth onClick={()=> approval("Decline")}>{t("Decline")}</DeclineButton>
                                <AcceptButton variant="contained" style={{width:"110px"}} fullWidth onClick={()=> approval("Accept")}>{t("Accept")}</AcceptButton>
                        </Grid>
                    }
                </Box>
            </Card>
        </Grid>
    )
}

const DeclineButton = withStyles((theme) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        border:"1px solid #2b6fed",
        fontWeight:"bold",
        borderRadius:"10px",
        '&:hover': {
            backgroundColor: "white",
        },
    },
}))(Button);

const AcceptButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2B6FED",
        fontWeight:"bold",
        borderRadius:"10px",
        '&:hover': {
            backgroundColor: "#2B6FED",
        },
    },
}))(Button);

//@ts-ignore
export default withTranslation()(withStyles(dashBoard)(withRouter(MyTeamMain)));

// Customizable Area End
