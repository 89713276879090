import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
const navigation = require('react-navigation');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id:string;
    navigation: typeof navigation;
    t:(value:string)=> string;
    i18n: I18nInterface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    companiesData: Array<CompanyItem>;
    openMenu: HTMLElement | null;
    openPopOver: HTMLElement | null;
    userRole: string | null;
    complex: string;
    country: string;
    city: string;
    status: string;
    searchText: string;
    countryList: Array<string>;
    cityList: Array<string>;
    showError: boolean;
    showErrorMessage: string;
    showSuccess: boolean;
    showSuccessMessage: string;
    page: PageInterface;
    paginationData: PaginationData | null;
    loader: boolean;
    openReassignModal: boolean;
    modalBuildingId: number | null;
    modalBuildingName:string | null;
    modalCurrentSalesManager:string | null;
    modalCurrentAccountManager:string | null;
    accountManager: string;
    ownerDetails: OwnerDetailsResponse;
    salesManagerList: Array<SalesManagerListItem>;
    accountManagerList: Array<AccountManagerListItem>;
    accountManagerAssign: string;
    salesManagerAssign: string;
    noteText: string;
    employeeAccountId: number | null;
    // Customizable Area End
}
// Customizable Area Start

export interface I18nInterface {
    language: string;
}
export type TFunction = (value: string) => string;

export type PageInterface =  null | string | number | undefined;

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface CountryResponse {
    data: {
        countries: Array<string>;
    }
}

export interface CityResponse {
    data: {
        cities: Array<string>;
    }
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>;
};

export interface ApiFailureResponseError {
    contract: string;
};

export interface CompaniesDataResponse{
    data: Array<CompanyItem>;
    meta: {
        pagination: PaginationData | null;
    }
}

export interface CompanyItem {
    id: string;
    type: string;
    attributes: {
        id: number;
        company_name: string;
        managed_by: string;
        country: string;
        city: string;
        status: string;
    }
}

export interface PaginationData {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface OwnerDetailsResponse {
    data: {
        reporting_person: string | null;
        employee_account: string | null;
        object_id: number | null;
        object_name: string | null;
    }
}

export interface AccountListResponse {
    manager_liting: Array<AccountManagerListItem>;
}

export interface SalesListResponse {
    manager_liting: Array<SalesManagerListItem>;
}

export interface AccountManagerListItem {
    id: number | null;
    full_name: string;
}

export interface SalesManagerListItem {
    id: number | null;
    full_name: string;
}

export interface ReassignToAnotherManagerResponse {
    manager_listing: string;
}

export interface CompaniesDataErrorResponse {
    errors: Array<string>;
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class MyTeamController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    companiesListApiCallId: string = "";
    ownerDetailsApiCallId: string = "";
    accountListApiCallId: string = "";
    salesListApiCallId: string = "";
    reassignAnotherManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start 
            country: "",
            city: "",
            status: "",
            searchText: "",
            companiesData: [],
            openMenu: null,
            openPopOver: null,
            userRole: "",
            complex: "",
            countryList: [],
            cityList: [],
            showError: false,
            showErrorMessage: "",
            showSuccess: false,
            showSuccessMessage: "",
            page: 1,
            paginationData: {
                current_page: null,
                next_page: null,
                prev_page: null,
                total_pages: null,
                total_count: null,
            },
            loader: false,
            openReassignModal: false,
            modalBuildingId: null,
            modalBuildingName: "",
            modalCurrentSalesManager: "",
            modalCurrentAccountManager: "",
            accountManager: "",
            noteText: "",
            ownerDetails: {
                data: {
                    reporting_person: null,
                    employee_account: null,
                    object_id: null,
                    object_name: null
                }
            },
            salesManagerList: [],
            accountManagerList: [],
            accountManagerAssign: "",
            salesManagerAssign: "",
            employeeAccountId: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getCompanyList("1");
        this.getCountryListItems();
        this.getUserType();
    };

    myTeamApiCall = async (data: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = data;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let myTeamrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        body && type !== "formData"
            ? myTeamrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : myTeamrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(myTeamrequestMessage.id, myTeamrequestMessage);
        return myTeamrequestMessage.messageId;
    };

    contractDetailsApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CountryResponse & CityResponse & CompaniesDataResponse & OwnerDetailsResponse & AccountListResponse & SalesListResponse & ReassignToAnotherManagerResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerSucessCallBack(responseJson);
        }
    };

    contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse & CompaniesDataErrorResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListFailureResponseCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerFailureCallBack(responseJson);
        }
    };

    getCountryListItems = async () => {
        this.countryListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint,
        });
    };

    countryListSucessCallBack = (response: CountryResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureResponseCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCityListItems = async () => {
        this.cityListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndPoint}${this.state.country}`
        });
    };

    cityListItemsSucessCallBack = (response: CityResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListItemsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCompanyList = async (page: string) => {
        this.setState({loader:true});
        let endPoint = `${configJSON.companiesDataEndPoint}page=${page}`;
        if(this.state.city){
            endPoint += `&city=${this.state.city}`;
        }
        if(this.state.status){
            endPoint += `&status=${this.state.status}`;
        }
        if(this.state.searchText){
            endPoint += `&search=${this.state.searchText}`;
        }
        this.companiesListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    companyListSucessCallBack = (response: CompaniesDataResponse) => {  
        this.setState({ companiesData: response.data, paginationData: response.meta.pagination, page: response.meta.pagination?.current_page, loader: false });
            };

    companyListFailureCallBack = (response: CompaniesDataErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0], loader: false });
    }

    getOwnerDetails = async (companyId: string | number) => {
        this.setState({ loader: true });
        this.ownerDetailsApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.ownerDetailsEndpoint}${companyId}`
        });
    };

    ownerDetailsSucessCallBack = (response: OwnerDetailsResponse) => {   
        this.setState({ 
            ownerDetails: response, 
            loader: false, 
            openReassignModal: true,
            modalBuildingId: response.data.object_id,
            modalBuildingName: response.data.object_name,
            modalCurrentSalesManager: response.data.reporting_person,
            modalCurrentAccountManager: response.data.employee_account
        });
    };

    ownerDetailsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getAccountList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.accountListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    accountListSucessCallBack = (response: AccountListResponse) => {
        this.setState({ accountManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    accountListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getSalesList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.salesListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    salesListSucessCallBack = (response: SalesListResponse) => {
        this.setState({ salesManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    salesListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    reassignToAnotherAccountManager = async () => {
        this.setState({ loader: true });
        this.reassignAnotherManagerApiCallId = await this.myTeamApiCall({
            method: configJSON.httpPut,
            endPoint: `${configJSON.reassignAccountManagerEndpoint}employee_account_id=${this.state.employeeAccountId}&object_id=${this.state.modalBuildingId}&note=${this.state.noteText}`
        });
    };

    reassignToAnotherAccountManagerSucessCallBack = (response: ReassignToAnotherManagerResponse) => {
        this.setState({ showSuccessMessage: response.manager_listing,openReassignModal: false, showSuccess: true,loader:false });
    };

    reassignToAnotherAccountManagerFailureCallBack = async (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole })
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string, cityList: [], city: "" }, () => {
            this.getCityListItems();
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string });
    };

    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ status: event.target.value as string });
    };

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getCompanyList("1")
        });
    };

    handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openMenu: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ openMenu: null });
    };

    handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openPopOver: event.currentTarget });
    };

    handleClosePopover = () => {
        this.setState({ openPopOver: null });
    };

    handleNavigation = (path: string) => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        this.send(msgData);
    };

    handleAlertError = () => {
        this.setState({ showError: false });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ page: newPageCount }, () => {
            this.getCompanyList(JSON.stringify(newPageCount));
        });
    };

    handleAccountManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let accountManager = this.state.accountManagerList.find((account) => { return account.full_name === event.target.value });
        if (accountManager) {
            this.setState({ accountManagerAssign: event.target.value as string, salesManagerAssign: "", employeeAccountId: accountManager.id });
        }
    };

    handleSalesManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.salesManagerList.find((sales) => { return sales.full_name === event.target.value });
        if (salesManager) {
            this.setState({
                salesManagerAssign: event.target.value as string,
                employeeAccountId: salesManager.id,
                accountManagerAssign: "",
            });
        }
    };

    handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ noteText: event.target.value });
    };

    handleReassignModal = () => {
        this.setState({ openReassignModal: false });
    };

    handleReassign = (companyId: string | number) => {
        this.setState({openMenu: null});
        this.getOwnerDetails(companyId);
        if(this.state.userRole === 'coo'){   
            this.getAccountList(configJSON.cooProfileAccountManagerListingEndpoint)
            this.getSalesList(configJSON.cooProfileSalesManagerListingEndpoint)
        }else {
            this.getAccountList(configJSON.salesProfileAccountManagerListingEndpoint)
        }
    };

    handleSuccessAlert = () => {
        this.setState({ showSuccess: false });
    };
    // Customizable Area End
}
