// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {}

interface SS {
  id: any;
}

export default class ComplexBuildingMainBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {};
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();

    window.history.pushState(null, document.title, window.location.href);
  }
}
// Customizable Area End
