// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpComplexBuildingBlockStep2Controller, {
  Props,
  StyleObject,
} from "./CompEmpComplexBuildingBlockStep2Controller.web";
import { Upload_Icon, CrossIcon } from "../assets";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";

class CompEmpComplexBuildingBlockStep2 extends CompEmpComplexBuildingBlockStep2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box>
          <Box>
            <Box className="complex">
              <Typography style={webStyle.heading}>{t("Complex")}</Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "25px" }}>
              <Typography className="bold-text" style={{ fontSize: "25px", fontWeight: "bold" }}>
                {t("Documents")}
              </Typography>
              <Typography style={{ fontSize: "16px" }}>
                {t("Step")} {this.props.step + 1} {t("of")} 5
              </Typography>
            </Box>
            <Box style={{ marginTop: "30px" }} className="policy-box">
              <Box style={{ width: "100%" }} className="policy-content-box">
                <Typography className="typographyLable">{t("Policy")}</Typography>
                <Box style={webStyle.docBox}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                      {this.state.documentsList.policy.map((document: { title: string }, index: number) => {
                        return (
                          <Box style={webStyle.docName} key={index}>
                            {document.title}
                            <Box
                              style={{ paddingLeft: "22px", cursor: "pointer" }}
                              onClick={() => this.handleRemoveFile("policy", index)}
                              className="policy-remove-doc"
                            >
                              <img src={CrossIcon} />
                            </Box>
                          </Box>
                        );
                      })}
                      <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                        {this.state.documentsList.policy.length === 0 && t("Upload Policy Documents")}
                      </Box>
                    </Box>
                    <Box
                      className="policy-upload-doc upload-button"
                      onClick={() => this.handleFileChanges("policy")}
                      style={{ margin: "8px 0px", cursor: "pointer" }}
                    >
                      <img src={Upload_Icon} alt="upload" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <Box style={{ width: "100%" }}>
                <Box>
                  <Typography className="typographyLable">{t("Guidelines")}</Typography>
                  <Box style={webStyle.docBox}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                        {this.state.documentsList.guidelines.map((document: { title: string }, index: number) => {
                          return (
                            <Box style={webStyle.docName} key={index}>
                              {document.title}
                              <Box
                                style={{ paddingLeft: "22px", cursor: "pointer" }}
                                onClick={() => this.handleRemoveFile("guidelines", index)}
                                className="guidelines-remove-doc"
                              >
                                <img src={CrossIcon} />
                              </Box>
                            </Box>
                          );
                        })}
                        <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                          {this.state.documentsList.guidelines.length === 0 && t("Upload Guidelines Documents")}
                        </Box>
                      </Box>
                      <Box
                        className="upload-button guidelines-upload-doc "
                        onClick={() => this.handleFileChanges("guidelines")}
                        style={{ margin: "8px 0px", cursor: "pointer" }}
                      >
                        <img src={Upload_Icon} alt="upload" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <Box style={{ width: "100%" }} className="role-box">
                <Typography className="typographyLable">{t("Roles")}</Typography>
                <Box style={webStyle.docBox}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                      {this.state.documentsList.roles.map((document: { title: string }, index: number) => {
                        return (
                          <Box style={webStyle.docName} key={index}>
                            {document.title}
                            <Box
                              style={{ paddingLeft: "22px", cursor: "pointer" }}
                              onClick={() => this.handleRemoveFile("roles", index)}
                              className="roles-remove-doc"
                            >
                              <img src={CrossIcon} />
                            </Box>
                          </Box>
                        );
                      })}
                      <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                        {this.state.documentsList.roles.length === 0 && t("Upload Roles Documents")}
                      </Box>
                    </Box>
                    <Box
                      className="roles-upload-doc upload-button"
                      onClick={() => this.handleFileChanges("roles")}
                      style={{ margin: "8px 0px", cursor: "pointer" }}
                    >
                      <img src={Upload_Icon} alt="upload" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <Box style={{ width: "100%" }}>
                <Box>
                  <Typography className="typographyLable">{t("Resolutions")}</Typography>
                  <Box style={webStyle.docBox}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                        {this.state.documentsList.resolutions.map((document: { title: string }, index: number) => {
                          return (
                            <Box style={webStyle.docName} key={index}>
                              {document.title}
                              <Box
                                style={{ paddingLeft: "22px", cursor: "pointer" }}
                                className="resolutions-remove-doc"
                                onClick={() => this.handleRemoveFile("resolutions", index)}
                              >
                                <img src={CrossIcon} />
                              </Box>
                            </Box>
                          );
                        })}
                        <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                          {this.state.documentsList.resolutions.length === 0 && t("Upload Resolutions Documents")}
                        </Box>
                      </Box>
                      <Box
                        className="upload-button resolutions-upload-doc"
                        onClick={() => this.handleFileChanges("resolutions")}
                        style={{ margin: "8px 0px", cursor: "pointer" }}
                      >
                        <img src={Upload_Icon} alt="upload" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }} className="building-plan-box">
              <Box style={{ width: "100%" }}>
                <Typography className="typographyLable">{t("Building Plans")}</Typography>
                <Box style={webStyle.docBox}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                      {this.state.documentsList.buildingPlans.map((document: { title: string }, index: number) => {
                        return (
                          <Box style={webStyle.docName} key={index}>
                            {document.title}
                            <Box
                              className="buildingPlans-remove-doc"
                              style={{ paddingLeft: "22px", cursor: "pointer" }}
                              onClick={() => this.handleRemoveFile("buildingPlans", index)}
                            >
                              <img src={CrossIcon} />
                            </Box>
                          </Box>
                        );
                      })}
                      <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                        {this.state.documentsList.buildingPlans.length === 0 && t("Upload Building Plans Documents")}
                      </Box>
                    </Box>
                    <Box
                      className="upload-button buildingPlans-upload-doc"
                      onClick={() => this.handleFileChanges("buildingPlans")}
                      style={{ margin: "8px 0px", cursor: "pointer" }}
                    >
                      <img src={Upload_Icon} alt="upload" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="step3BtnBox" style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "10px" }}>
          <Grid className="step3Grid" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button className="step3Prev" style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("Prev")}</span>
            </Button>
          </Grid>
          <Grid className="step3Grid" style={{ marginTop: "30px" }}>
            <Button
              className="step3Next edit-document-next"
              style={webStyle.buttonSave}
              data-testid="handleNextStep3"
              onClick={this.handleSubmitDocument}
            >
              <span className="bold-text">{t("Next")}</span>
            </Button>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(CompEmpComplexBuildingBlockStep2);

const webStyle: StyleObject = {
  docBox: {
    borderRadius: "8px",
    marginBottom: "0px",
    border: "1px solid #f0f0f0",
    backgroundColor: "#f9f9f9",
    padding: "8px 25px 8px 8px",
  },
  buttonSave: {
    width: "177px",
    height: "50px",
    textTransform: "uppercase",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#2B6FED",
    fontWeight: "bold",
    padding: "0px 20.5px",
  },
  prevButton: {
    color: "#2B6FED",
    padding: "0px 20.5px",
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
    height: "50px",
    borderRadius: "8px",
    border: "1px solid #2B6FED",
    display: "flex",
    width: "177px",
    justifyContent: "center",
  },
  heading: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "25px",
    borderBottom: "1px solid #F0F0F0",
  },
  docName: {
    border: "1px solid #F0F0F0",
    borderRadius: "8px",
    marginRight: "15px",
    display: "flex",
    background: "#FFF",
    alignItems: "center",
    padding: "8px 15px",
  },
  step6: {
    margin: "11px 0px",
    color: "black",
    fontSize: "16px",
  },
};
// Customizable Area End
