// Customizable Area Start
import React from "react";
import { Container, IconButton, Typography, withStyles, Box, Grid, Card } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ComplexDocumentController, { Props } from "./ComplexDocumentController.web";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import ShareDocumentModal from "../../../../components/src/DocumentComponent/ShareModal.web";
import { DocumentReportStyleWeb } from "../../../Notes/src/DocumentReportStyle.web";
import { Document } from "./assets";
import { formatDate, handleDownloadLink, navigationFunc } from "../../../../components/src/helperFunctions.web";
import { DownloadDocIcon, PdfImage, ShareIcon } from "../assets";

class ComplexDocument extends ComplexDocumentController {
  constructor(props: Props) {
    super(props);
  }

  resolutionList = (t: any) => {
    return this.state.documentList.map((resolution: any) => {
      return (
        <Grid item xs={12} md={6} lg={4} key={resolution.id}>
          <Card className="card-item resolution-card">
            <div className="heading">
              <h4 className="bold-text">{resolution.attributes.title}</h4>
              <div className="menu resolution-menu">
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <MenuItem
                    className="download-menu"
                    onClick={() => handleDownloadLink(resolution.attributes.attachments[0].url)}
                  >
                    {t("Download")}
                  </MenuItem>
                  <MenuItem
                    className="share-menu"
                    onClick={() => this.handleOpenShareModal(resolution.attributes.attachments[0].url)}
                  >
                    {t("Share")}
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className="res-info">
              <div className="info-item">
                <p>{t("Date & Time")}</p>
                <span>{formatDate(resolution.attributes.created_at)}</span>
              </div>
              <div className="info-item">
                <p>{t("Building")}</p>
                <span>{resolution.attributes.buidling_name || "All"}</span>
              </div>
            </div>
            {resolution.attributes.meeting_mins_pdf && (
              <div className="item">
                <div className="item-title">
                  <img src={PdfImage} style={{ width: "28px", height: "28px" }} />
                  <h6 className="bold-text">
                    {t("Meeting Minute")} {formatDate(resolution.attributes.meeting_date_time)}
                  </h6>
                </div>
                <div className="icons">
                  <img
                    src={ShareIcon}
                    className="share-meeting"
                    onClick={() => this.handleOpenShareModal(resolution.attributes?.meeting_mins_pdf?.url)}
                  />
                  <img
                    onClick={() => handleDownloadLink(resolution.attributes?.meeting_mins_pdf?.url)}
                    src={DownloadDocIcon}
                    className="download-meeting"
                  />
                </div>
              </div>
            )}
          </Card>
        </Grid>
      );
    });
  };

  documentList = (t: any) => {
    return this.state.documentList.map((document: any) => {
      return (
        <Grid item xs={12} md={12} lg={12}>
          <Box className="item">
            <div className="heading" style={{ gap: "10px" }}>
              <img src={Document} />
              <h4 className="bold-text" style={{ marginLeft: "0" }}>
                {document.attributes.title}
              </h4>
            </div>
            <div className="menu document-menu">
              <Menu
                menuButton={
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <MenuItem className="view-menu" onClick={() => this.handleDocumentView(document.id)}>
                  {t("View")}
                </MenuItem>
                <MenuItem
                  className="download-menu"
                  onClick={() => handleDownloadLink(document.attributes.images[0].download_url)}
                >
                  {t("Download")}
                </MenuItem>
                <MenuItem
                  className="share-menu"
                  onClick={() => this.handleOpenShareModal(document.attributes.images[0].url)}
                >
                  {t("Share")}
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Grid>
      );
    });
  };

  render() {
    const { t, classes, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.documentChairman}>
            <Box className="navigation">
              <Box>
                <Typography variant="body1">
                  <span className="complex-navigation" onClick={() => navigationFunc(this.props, "ComplexListing")}>
                    {t("Complex")} / {t("Buildings")} /
                  </span>{" "}
                  <span className="complex-detail-navigation" onClick={this.handleDynamicRoute}>
                    {t("Complex Details")}
                  </span>{" "}
                  /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t(this.state.docName == "Building_Plan" ? "Building Plans" : this.state.docName)}
                  </Box>
                </Typography>
                <Box className="top-heading" style={{ marginTop: "10px" }}>
                  <Typography variant="h5" className="sub-heading bold-text">
                    {t(this.state.docName == "Building_Plan" ? "Building Plans" : this.state.docName)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              className={`document-box ${this.documentClass(this.state.docName.toLowerCase())}`}
              style={{ marginBottom: "85px", marginTop: "10px" }}
            >
              <Grid container spacing={2}>
                {this.state.documentList.length === 0 && (
                  <Grid item xs={12} md={12} lg={12}>
                    <span>{t("No Document Available")}</span>
                  </Grid>
                )}
                {this.state.docName.toLowerCase() === "resolution" ? this.resolutionList(t) : this.documentList(t)}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(withRouter(ComplexDocument)));
// Customizable Area End
