// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: any;
  i18n: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  showDialog: boolean;
  incidentDetails: any;
  statusDetail: any;
  loading: boolean;
  error: any;
  chatDrawer: boolean;
  showError: any;
  providerList: any;
  assignProvider: any;
  assignNameList: any;
  assignedData: any;
  assignProviderName: any;
  selectProviderError: any;
  userRole:any;
  statusShowDialog:any;
  providerInfo:any;
  cardValue:boolean;
  openBuildingModal: boolean;
  latitude: LocationInterface;
  longitude: LocationInterface;
}

export type LocationInterface = null | number | string

interface SS {
  id: any;
}

export default class COOIncidentListingController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  getIncidentApiCallId: any;
  createChatRoomAPIId: any;
  getProviderApiCallId: any;
  getProvidenameApiCallId: any;
  assignIncidentToProvider: any;
  updateProviderDataApi: any;
  updateStatusCallId:any;
  getProviderInfoApiCallId:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      showDialog: false,
      incidentDetails: null,
      statusDetail: "",
      loading: true,
      error: null,
      chatDrawer: false,
      showError: false,
      providerList: null,
      assignProvider: "",
      assignNameList: null,
      assignedData: null,
      assignProviderName: "",
      selectProviderError: "",
      userRole:null,
      statusShowDialog:false,
      providerInfo:[],
      cardValue:false,
      openBuildingModal: false,
      latitude: null,
      longitude: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
   
    this.getIncidentById();
   
    let  accountRole=localStorage.getItem("role")
    if(accountRole){
     
      this.setState({ userRole:  accountRole?.replace(/"/g, '')})
    }
    
    
  }
  assignProvider = () => {
    this.setState({ showDialog: true });
  };
  closeAssignDialog = () => {
    this.setState({
      showDialog: false,
      assignProvider: "",
      assignProviderName: "",
      cardValue:false
    });
  };
  getIncidentById = async () => {
 
    const match: any = this.props.match;
    const id = match?.params.id;
    this.getIncidentApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/${id}`,
    });
  };
  getProviderName = async (id: any, assignName: any) => {
    this.setState({ loading: true });
    this.getProvidenameApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/assign_incidents/provider_account_list?society_management_id=${id}&role_id=${assignName}`,
    });
  };

  getProviderList = async () => {
    this.getProviderApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/assign_incidents/provider_role_list`,
    });
  };
 

  getProviderInfo = async() => {
  
    const provider_id = this.state.assignProviderName
    const formData = new FormData();
    formData.append("id",  provider_id);
    this.getProviderInfoApiCallId = await apiCall({   
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/assign_incidents/accountdata_show?id=${provider_id}`,
     
    });
   
  };
  onChange = (e: any) => {
    //@ts-ignore
    this.setState({ [e.target?.name]: e.target?.value });
  };
  createChatRoom = async () => {
    const match: any = this.props.match;
    const id = match?.params.id;
    const formData = new FormData();
    formData.append("chat[chatable_type]", "BxBlockCustomForm::Incident");

    formData.append("chat[chatable_id]", id);
    this.createChatRoomAPIId = await apiCall({
      method: "POST",
      endPoint: `bx_block_chat/chats`,
      body: formData,
    });
  };

  showError = () => {
   
    if (this.state.error) {
     
      this.setState({
        showError: true,
        loading: false,
      });
    }
  };
  handleDetails = (responseJson: any) => {
    this.setState({
      incidentDetails: responseJson.data,
      statusDetail: responseJson.data.attributes.incident_status,
      loading: false,
    });
  };

  
  handleProvider = () => {
    this.setState({ showDialog: true });
   
    this.getProviderList();
  };
  handleProviderResponseList = (response: any) => {
    this.setState({ providerList: response.data });
  };
  handleAssignName = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({ assignNameList: responseJson?.data, loading: false });
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
   
    this.showError();
  };
  updatedStatusValue=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({loading: false})
  } else if (responseJson?.errors) {
    
      this.setState({error:responseJson.errors});
  } else {
      this.setState({error: responseJson?.error || "Something went wrong!"});
  }
  this.showError()
  }
  updateProviderDetails = async (assignid: any) => {
    this.setState({cardValue:false})
    const match: any = this.props.match;
    const id = match?.params.id;
    const formData = new FormData();
    const provider_id = this.state.assignProviderName;
    formData.append("assign_incident[incident_id]", id);
    formData.append("assign_incident[account_id]", provider_id);
    this.updateProviderDataApi = await apiCall({
      method: "PUT",
      endPoint: `bx_block_custom_form/assign_incidents/${assignid}`,
      body: formData,
    });
  };
  updateStatus=async(val:any)=>{
    
    this.setState({loading: true, statusShowDialog: false})
    const match: any = this.props.match;
    const id = match?.params.id;
    this.updateStatusCallId = await apiCall({
      method: "PUT",
      endPoint: `bx_block_custom_form/incident_managements/${id}?status=${val}`,
     
    });
  }
  assignIncidentToProviderList = async () => {
   
    this.setState({ loading: true, showDialog: false,cardValue:false });
    const match: any = this.props.match;
    const id = match?.params.id;
    const provider_id = this.state.assignProviderName;
    const formData = new FormData();
    formData.append("assign_incident[incident_id]", id);
    formData.append("assign_incident[account_id]", provider_id);
    this.assignIncidentToProvider = await apiCall({
      method: "POST",
      endPoint: `bx_block_custom_form/assign_incidents`,
      body: formData,
    });
  };
  createChatRoomResponseData = (responseJson: any) => {
   
    if (responseJson?.data) {
      
      localStorage.setItem("selectedChat", JSON.stringify(responseJson.data));
      this.setState({ loading: false, chatDrawer: true });
    } else if (responseJson?.errors) {
      
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.showError();
    this.setState({ loading: false, error: null });
  };
  providerInfoSucc=(response:any)=>{
   
    if(response.data){
      
      this.setState({providerInfo:response.data,cardValue:true})
    }
    
  }
  handleUpdateProviderDetails = (responseJson: any) => {
   
    if (responseJson && responseJson.data) {
      
      this.getIncidentById();
      this.setState({ loading: false, showDialog: false });
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    
    this.showError();
  };
  assignedIncident = (responseJson: any) => {
    

    if (responseJson && responseJson?.data) {
     
      this.setState({
        assignedData: responseJson,
        loading: false,
        assignProvider: "",
      });
      this.getIncidentById();
    } else if (responseJson?.errors) {
     
     
      this.setState({ showError: true, error: "Something went wrong" });
    } else {
     
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }

    this.showError();
  };
  async receive(from: string, message: Message) {
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
       
        switch (apiRequestCallId) {
        
          case this.getIncidentApiCallId:
            return this.handleDetails(responseJson);
          case this.createChatRoomAPIId:
            return this.createChatRoomResponseData(responseJson);
          case this.getProviderApiCallId:
            return this.handleProviderResponseList(responseJson);
          case this.getProvidenameApiCallId:
            return this.handleAssignName(responseJson);
          case this.assignIncidentToProvider:
            return this.assignedIncident(responseJson);
          case this.updateProviderDataApi:
            return this.handleUpdateProviderDetails(responseJson);
          case this.updateStatusCallId:
            return this.updatedStatusValue(responseJson)
          case this.getProviderInfoApiCallId:
           
            return this.providerInfoSucc(responseJson)
        }
      }
    }
  }
  handleOpenmapModal=()=>{
    const {incidentDetails} = this.state
    this.setState({
        openBuildingModal: !this.state.openBuildingModal,
        latitude: incidentDetails && incidentDetails.attributes && incidentDetails.attributes.building_management&& incidentDetails.attributes.building_management.lat,
        longitude: incidentDetails && incidentDetails.attributes && incidentDetails.attributes.building_management&& incidentDetails.attributes.building_management.long
    })
  }

  handleCloseMap = () => {
    this.setState({ openBuildingModal: !this.state.openBuildingModal })
  };
}
// Customizable Area End
