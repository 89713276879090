import React from "react";
// Customizable Area Start
// Customizable Area End

import ManagementTeamController, { Props, } from "./ManagementTeamController";

class ManagementTeam extends ManagementTeamController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default ManagementTeam;
export {ManagementTeam};
// Customizable Area End