// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import CompEmpComplexBlockStep6Controller, { Props } from "./CompEmpComplexBlockStep6Controller.web";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import "../../assets/commonForm.css";
import Loader from "../../../../components/src/Loader.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { del_image, upload } from "../assets";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";

export default class CompEmpComplexBlockStep6 extends CompEmpComplexBlockStep6Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box className="topBarFormik">
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="List bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Shared Facilities")}
            </Typography>
            <Typography className="Liststep" style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of 7")}
            </Typography>
          </Box>
          <Grid container spacing={4} style={{ marginTop: "15px" }}>
            {this.state.facilityList.map((item: any) => {
              return (
                <Grid item xs={4} key={item.id}>
                  <Box style={webStyle.sharedContainer}>
                    <span
                      onClick={() => this.handleEditSharedArea(item)}
                      className="bold-text"
                      data-test-id="edit-facility"
                      style={webStyle.shareTitle}
                    >
                      {item.attributes.name}
                    </span>
                    <CloseIcon
                      onClick={() => this.handleComplexDeleteFacility(item.id)}
                      data-test-id="delete-facility"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <Box style={{ marginTop: "20px", border: "1px solid #2B6FED", borderRadius: "8px" }}>
              <Box
                data-test-id="add-shared-btn"
                style={webStyle.addBtn}
                onClick={this.handleSharedFacilityModal}
                className="bold-text"
              >
                {t("+ ADD NEW SHARED FACILITY")}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="step6BtnBox" style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
          <Grid className="step6Grid" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button className="step6Prev" style={webStyle.backBtn} onClick={this.props.handleBack}>
              <span className="bold-text">{t("PREV")}</span>
            </Button>
          </Grid>
          <Grid className="step6Grid" style={{ marginTop: "30px" }}>
            <Button className="step6Next" style={webStyle.submitBtn} type="submit" onClick={this.props.handleNext}>
              <span className="bold-text">{t("NEXT")}</span>
            </Button>
          </Grid>
        </Box>

        <Dialog
          open={this.state.isSharedFacilityOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          maxWidth="md"
          fullWidth
        >
          <DialogHeader
            t={t}
            onClose={this.handleSharedFacilityModal}
            title={this.state.editId ? t("Edit Shared Area") : t("Add New Shared Area")}
          />
          <Formik
            initialValues={this.state.facilityFormValues}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            onSubmit={(values, { resetForm }) => {
              this.handleSubmitForm(values, resetForm);
            }}
            data-test-id="complex-facility-form"
          >
            {({ values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      {!this.state.editId && (
                        <Grid item xs={12}>
                          <NormalTextField
                            name="name"
                            t={t}
                            language={language}
                            onChange={handleChange}
                            value={values.name}
                            isLabel={true}
                            label="Shared Facility's Name"
                            onBlur={handleBlur}
                          />
                          {ErrorHandler(t, touched.name, errors.name)}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <InputLabel>{t("Upload Photos")}</InputLabel>
                        <Grid container spacing={2} className="edit-building">
                          <Grid item xs={3}>
                            <label htmlFor="shared-images">
                              <Box className="upload-photo">
                                <img src={upload} alt="area-images" />
                              </Box>
                            </label>
                            <input
                              onBlur={handleBlur}
                              style={{ display: "none" }}
                              accept="image/*"
                              type="file"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files = e.target.files;
                                if (files) {
                                  setFieldValue("images", [...files, ...values.images]);
                                }
                              }}
                              id="shared-images"
                              name="images"
                              multiple
                              data-test-id="shared-image-upload"
                            />
                          </Grid>
                          {[...values.images].map((image: any, index: number) => {
                            return (
                              <Grid item xs={3} key={index}>
                                <Box className="building-image">
                                  <img
                                    onClick={() => {
                                      const remainImage = [...values.images].filter(
                                        (_: any, idx: number) => idx !== index
                                      );
                                      setFieldValue("images", remainImage);
                                    }}
                                    className="delete-image"
                                    src={del_image}
                                  />
                                  <img src={typeof image === "string" ? image : URL.createObjectURL(image)} alt="" />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {ErrorHandler(t, touched.images, errors.images)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextArea
                          name="about"
                          t={t}
                          isLabel={true}
                          onBlur={handleBlur}
                          label={t("Details")}
                          value={values.about}
                          onChange={handleChange}
                          language={language}
                        />
                        {ErrorHandler(t, touched.about, errors.about)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextField
                          name="area"
                          language={language}
                          value={values.area}
                          t={t}
                          type="number"
                          isLabel={true}
                          label={t("Total Area")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          startAdornment={
                            <InputAdornment position="end">
                              <Typography className="bold-text" style={webStyle.unitText}>
                                {t(this.props.measurement)}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.area, errors.area)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextField
                          name="fees"
                          type="number"
                          language={language}
                          value={values.fees}
                          t={t}
                          isLabel={true}
                          label={t("Reservation Fees (Per hour)")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          startAdornment={
                            <InputAdornment position="start">
                              <Typography className="bold-text" style={webStyle.unitText}>
                                {t(this.props.currency?.currency)}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.fees, errors.fees)}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>{t("Floor Plan")}</InputLabel>
                        <Box className="floor-plan-box">
                          <input
                            className="floor-plan-pdf"
                            style={{ display: "none" }}
                            type="file"
                            onBlur={handleBlur}
                            name="document"
                            id="floor-doc"
                            accept=".pdf"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const files = e.target.files;
                              if (files) {
                                setFieldValue("document", files[0]);
                              }
                            }}
                            data-test-id="shared-floor-upload"
                          />
                          {!values.document ? (
                            <span className="placeholder">{t("Floor Plan")}</span>
                          ) : (
                            <Chip onDelete={() => setFieldValue("document", null)} label={values.document.name} />
                          )}
                          <label htmlFor="floor-doc">
                            <img className="shared-floor-upload" src={upload} />
                          </label>
                        </Box>
                        {ErrorHandler(t, touched.document, errors.document)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button onClick={this.handleSharedFacilityModal} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" style={{ margin: "0" }} className="add-button">
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  }
}

const webStyle: any = {
  unitText: { color: "rgb(140, 140, 140)" },
  sharedContainer: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E4E4",
    borderRadius: "12px",
    padding: "15px",
  },
  shareTitle: { color: "#181D25", textTransform: "capitalize", cursor: "pointer" },
  backBtn: {
    width: "177px",
    color: "#2B6FED",
    padding: "0px 20.5px",
    textTransform: "capitalize",
    height: "56px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    textAlign: "center",
  },
  submitBtn: {
    display: "flex",
    width: "177px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    justifyContent: "center",
    fontWeight: "bold",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "0px 20.5px",
    height: "56px",
    textTransform: "capitalize",
  },
  addBtn: {
    color: "#2B6FED",
    fontSize: "15px",
    fontWeight: 400,
    padding: "10px 25px",
    cursor: "pointer",
  },
};

// Customizable Area End
