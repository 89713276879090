// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  withStyles,
  Container,
  Divider,
  Card,
  Button,
  Link as NavLink,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import CEMeetingDetailController, { Props } from "./CEMeetingDetailController.web";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import Loader from "../../../../components/src/Loader.web";
import { CheckIcon, DownloadIcon, PdfIcon } from "../assets";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";

class CEMeetingDetail extends CEMeetingDetailController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <CEHeaderComponentWeb>
          <Loader loading={this.state.loading} />

          <Container className={classes.scheduledMeetingDetails}>
            <Box className="navigation">
              <Box>
                <Typography variant="body1">
                  <Link to={"/CompanyEmployee/CEMeetingMinutes"}>
                    {t("Activities")} / {t("Meeting Minutes")} /{" "}
                  </Link>
                  <Box component="span" style={{ color: "blue" }}>
                    {this.state.minuteDetails.title}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text">
                  {t("Meeting Minutes Details")}
                </Typography>
              </Box>
            </Box>

            {this.state.minuteDetails.pdfUrl ? (
              <Box className="meeting-detail-box">
                <Box className="meeting-top">
                  <h4 className="bold-text">
                    {t("Meeting Minutes")} {this.state.minuteDetails.date}
                  </h4>
                  <span className={this.state.minuteDetails.status}>{t(this.state.minuteDetails.status)}</span>
                </Box>
                <Divider />
                <Box className="meeting-minute-details">
                  <Box className="resolution">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.minuteDetails.note,
                      }}
                    />
                  </Box>
                  <Box className="pdf-detail">
                    <div className="heading">
                      <img src={PdfIcon} alt="pdf" />
                      <h6 className="bold-text">
                        {t("Meeting Minutes")} {this.state.minuteDetails.date}
                      </h6>
                    </div>
                    <NavLink href={this.state.minuteDetails.pdfUrl} target="_blank">
                      <img src={DownloadIcon} alt="download" />
                    </NavLink>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="no-available">
                <Card>{t("No Meeting Minute Available!!")}</Card>
              </Box>
            )}

            {this.state.minuteDetails.status === "rejected" && (
              <Box className="rejection-box">
                <Card>
                  <h4 className="bold-text">{t("Rejection Reason")}</h4>
                  <p>{this.state.minuteDetails.rejectNote}</p>
                </Card>
              </Box>
            )}

            {this.state.minuteDetails.status !== "pending" && (
              <Box className="button-box">
                <Button className="edit" onClick={this.handleEditMinute}>
                  {t("Edit")}
                </Button>
              </Box>
            )}

            {this.state.minuteDetails.status === "pending" && (
              <>
                <Box className="button-box">
                  <Button className="cancel" onClick={this.handleMinuteRejectDialog}>
                    {t("Reject")}
                  </Button>
                  <Button className="edit" onClick={this.handleMinuteApproveDialog}>
                    {t("Approve")}
                  </Button>
                </Box>
              </>
            )}
          </Container>
        </CEHeaderComponentWeb>

        <AlertDialog
          IsOpen={this.state.isMinuteApproveOpen}
          Image={CheckIcon}
          CloseDialog={this.handleMinuteApproveDialog}
          Header={`${t("Approve meeting minutes")} ${this.state.minuteDetails.date}`}
          Content={`<p>${t("Are you sure you want to approve meeting minutes")} ${this.state.minuteDetails.date}?</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleMinuteApproveDialog}
          AcceptFun={() => this.setState({ isMinuteApproveOpen: false }, () => this.handleStatusChange("approved", ""))}
        />

        <Dialog
          open={this.state.isMinuteRejectOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <DialogHeader
            t={t}
            title={`${t("Reject meeting minutes")} ${this.state.minuteDetails.date}`}
            onClose={this.handleMinuteRejectDialog}
          />
          <Formik
            enableReinitialize={true}
            initialValues={{ note: "" }}
            validationSchema={this.rejectNoteValidation}
            onSubmit={(values, { resetForm }) => {
              this.setState({ isMinuteRejectOpen: false }, () => {
                this.handleStatusChange("rejected", values.note);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <FormControl fullWidth>
                      <NormalTextArea
                        t={t}
                        language={language}
                        label="Add Notes"
                        value={values.note}
                        name="note"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        maxRows={10}
                        minRows={10}
                      />
                      {ErrorHandler(t, touched.note, errors.note)}
                    </FormControl>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleMinuteRejectDialog}>
                      {t("Close")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Confirm")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEMeetingDetail)));
// Customizable Area End
