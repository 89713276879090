//Customizable Area Start
import React from "react";
import { Grid, Button, Dialog, DialogContent, DialogActions } from "@material-ui/core";
export const image_realEstateCompany = require("../../assets/pollsurvey.png");
import CEReassignBuildController, { Props } from "./CEReassignBuildController.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
export const emailImg = require("../../assets/email.png");

class CEReassignBuild extends CEReassignBuildController {
  constructor(props: Props) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    NewSalesMan: this.state.role !== "sales_manager" ? Yup.string().required("New sales manager is required") : Yup.string().notRequired(),
    notes: Yup.string().required("Notes is required"),
    NewAccountMan: Yup.string().required("New account manager is required"),
  });

  render() {
    const { t, i18n, dataFromComponent1, handleModalClose }: any = this.props;
    const language = i18n.language;

    return (
      <Dialog
        dir={languageCondition(language, "rtl", "ltr")}
        open={dataFromComponent1}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogHeader
          t={t}
          onClose={handleModalClose}
          title={`Reassign ${this.getBuildingLabel()} to another Account Manager`}
        />
        <Formik
          onSubmit={(values, { resetForm }) => {
            this.handleSubmit(values);
            resetForm();
          }}
          initialValues={this.state.initialValues}
          validationSchema={this.validationSchema}
          enableReinitialize={true}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} translate="yes">
                <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label={`${this.getBuildingLabel()} ID`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.BuildingID}
                        data-test-id="building-id"
                        name="BuildingID"
                        disabled={true}
                        isLabel
                      />
                      {ErrorHandler(t, touched.BuildingID, errors.BuildingID)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label={`${this.getBuildingLabel()} Name`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.BuildingName}
                        data-test-id="building-name"
                        name="BuildingName"
                        isLabel
                        disabled={true}
                      />
                      {ErrorHandler(t, touched.BuildingName, errors.BuildingName)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Sales Manager"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.SalesManager}
                        data-test-id="sales-manager"
                        name="SalesManager"
                        isLabel
                        disabled={true}
                      />
                      {ErrorHandler(t, touched.SalesManager, errors.SalesManager)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Current Account Manager"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.CurrentAccountManager}
                        data-test-id="current-account-manager"
                        name="CurrentAccountManager"
                        isLabel
                        disabled={true}
                      />
                      {ErrorHandler(t, touched.CurrentAccountManager, errors.CurrentAccountManager)}
                    </Grid>
                    {this.state.role !== "sales_manager" && (
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          label="New Sales Manager"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.NewSalesMan}
                          name="NewSalesMan"
                          data-test-id="new-sales-manager"
                          option={this.state.salesManagerList}
                        />
                        {ErrorHandler(t, touched.NewSalesMan, errors.NewSalesMan)}
                      </Grid>
                    )}
                    <Grid item xs={this.state.role !== "sales_manager" ? 6 : 12}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="New Account Manager"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.NewAccountMan}
                        name="NewAccountMan"
                        data-test-id="new-account-manager"
                        option={this.state.accountManagerList}
                      />
                      {ErrorHandler(t, touched.NewAccountMan, errors.NewAccountMan)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextArea
                        t={t}
                        language={language}
                        label="Notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                        isLabel
                        data-test-id="notes"
                        name="notes"
                      />
                      {ErrorHandler(t, touched.notes, errors.notes)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={handleModalClose}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("REASSIGN")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
}

export default withTranslation()(CEReassignBuild);
// Customizable Area End
