// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Input,
  FormControl,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  BuildingImage,
  GreyTenantType,
  GreyTenantName,
  GreyEmailIcon,
  GreyBuildingName,
  GreyUnitNumber,
  GreyIdType,
  GreyIdNumber,
  GreyCalenderIcon,
  UploadIcon,
  GreyPhoneNumber,
  DocumentIcon,
  ComplexIcon,
} from "./assets";
import { Formik, Form } from "formik";
import RegisterTenantController, { Props } from "./RegisterTenantController.web";
import { withTranslation } from "react-i18next";
import { TenantStyle } from "./TenantStyle.web";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../components/src/Loader.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import PhoneInput from "react-phone-input-2";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { withRouter } from "react-router";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class EditTenant extends RegisterTenantController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const tenant_id = this.props.navigation.getParam("id");
    this.setState({ tenantId: tenant_id }, () => {
      this.getTenantDetailsForEdit();
      this.getIdTypeList();
    });
  }

  handleEditErrorMessage = (errors: any, touched: any, t: any) => {
    if (errors && touched) {
      return <p className="error">{t(errors)}</p>;
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh" }} className={classes.selectTemplate}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className={languageCondition(language, "arabic-grid", "")}>
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <Link to={`/Tenant/${this.state.tenantId}`}>
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")}/>
                      </IconButton>
                    </Link>
                    <span className="bold-text">{t("Edit A Tenant")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content" style={{ marginBottom: "50px" }}>
                    <Formik
                      enableReinitialize
                      initialValues={this.state.registerTenantForm}
                      validationSchema={this.validationRegisterTenantFormSchema}
                      onSubmit={(values, { resetForm }) => {
                        if (this.checkPhoneValidation(values)) {
                          this.handleEditTenant(values);
                        }
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                          <Form onSubmit={handleSubmit} translate="yes">
                            <Box className="select-input-box">
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.tenantType}
                                    onChange={(e: any) => {
                                      setFieldValue("tenantType", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    name="tenantType"
                                    fullWidth
                                    className="select-input"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Type of Tenant")}
                                    </MenuItem>
                                    <MenuItem value="Individual" className={languageCondition(language, "select-arabic-menu", "")}>{t("Individual Person")}</MenuItem>
                                    <MenuItem value="company" className={languageCondition(language, "select-arabic-menu", "")}>{t("Company")}</MenuItem>
                                  </Select>
                                  <img src={GreyTenantType} alt="" />
                                </Box>
                                {this.handleEditErrorMessage(errors.tenantType, touched.tenantType, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.tenantName}
                                  onChange={(e: any) => {
                                    setFieldValue("tenantName", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  name="tenantName"
                                  className="select-input input"
                                  placeholder={t("Tenant Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyTenantName} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleEditErrorMessage(errors.tenantName, touched.tenantName, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="mobile-box">
                                  <Box>
                                    <FormControl variant="outlined">
                                      <PhoneInput
                                        inputProps={{ name: "selectCode" }}
                                        enableSearch={true}
                                        value={values.tenantCountryCode}
                                        onChange={(e: any) => {
                                          setFieldValue("tenantCountryCode", `+${e}`);
                                        }}
                                        country={"us"}
                                      />
                                    </FormControl>
                                  </Box>
                                  <Box className="divider" />
                                  <Input
                                    value={values.tenantMobile}
                                    onChange={(e: any) => {
                                      setFieldValue("tenantMobile", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    name="tenantMobile"
                                    className="mobile-input"
                                    placeholder={t("Tenant Mobile")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={GreyPhoneNumber} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                </Box>
                                {this.handleEditErrorMessage(errors.tenantMobile, touched.tenantMobile, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.tenantEmail}
                                  name="tenantEmail"
                                  className="select-input input disable-input"
                                  placeholder={t("Tenant Email ID")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyEmailIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                                {this.handleEditErrorMessage(errors.tenantEmail, touched.tenantEmail, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.complex}
                                  name="complex"
                                  className="select-input input disable-input"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={ComplexIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.building}
                                  name="building"
                                  className="select-input input disable-input"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyBuildingName} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.unit}
                                  name="unit"
                                  className="select-input input disable-input"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyUnitNumber} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.idType}
                                    onChange={(e: any) => {
                                      setFieldValue("idType", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    name="idType"
                                    fullWidth
                                    className="select-input edit-tenant-id-type-select"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("ID Type")}
                                    </MenuItem>
                                    {this.state.idTypeList.map((idType: any) => {
                                      return (
                                        <MenuItem value={idType.id} key={idType.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                           {LanguageAttributeHandler(language, idType.name, idType.name_ar)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={GreyIdType} alt="" />
                                </Box>
                                {this.handleEditErrorMessage(errors.idType, touched.idType, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.idNumber}
                                  onChange={(e: any) => {
                                    setFieldValue("idNumber", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  name="idNumber"
                                  className="select-input input edit-tenant-id-number"
                                  placeholder={t("ID Number")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyIdNumber} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleEditErrorMessage(errors.idNumber, touched.idNumber, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.idDate}
                                  onChange={(e: any) => {
                                    setFieldValue("idDate", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  name="idDate"
                                  className="select-input input edit-tenant-expiry"
                                  placeholder={t("ID Expiration Date")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={GreyCalenderIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleEditErrorMessage(errors.idDate, touched.idDate, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="upload-box" onClick={() => this.uploadIDCard.click()}>
                                  <img src={UploadIcon} alt="" />
                                  <p>{t("Upload Tenant ID Card Copy")}</p>
                                </Box>
                                <input
                                  className="edit-tenant-upload-id-card"
                                  onChange={(e: any) => {
                                    if (this.handleCompressFileValidation(e, values.idCard)) {
                                      setFieldValue("idCard", e.target.files);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  name="idCard"
                                  style={{ display: "none" }}
                                  ref={(ref: any) => (this.uploadIDCard = ref)}
                                  accept=".pdf,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                  type="file"
                                />
                                {[...values.idCard].map((file: any) => {
                                  return (
                                    <Box className="pdf-box">
                                      <img src={DocumentIcon} alt="" />
                                      <Box className="pdf-info">
                                        <h4>{file.name}</h4>
                                        <CloseIcon
                                          onClick={() => {
                                            setFieldValue("idCard", []);
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                                {this.handleEditErrorMessage(errors.idCard, touched.idCard, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="upload-box" onClick={() => this.uploadOtherDocument.click()}>
                                  <img src={UploadIcon} alt="" />
                                  <p>{t("Upload Other Tenant Documents")}</p>
                                </Box>
                                <input
                                  className="edit-tenant-other-document-input"
                                  onChange={(e: any) => {
                                    if (this.handleCompressFileValidation(e, values.otherDocument)) {
                                      const document: any = [...values.otherDocument, ...e.target.files];
                                      setFieldValue("otherDocument", document);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  name="otherDocument"
                                  ref={(ref: any) => (this.uploadOtherDocument = ref)}
                                  accept=".pdf,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                  type="file"
                                  style={{ display: "none" }}
                                  multiple
                                />
                                {[...values.otherDocument].map((file: any, index: number) => {
                                  return (
                                    <Box className="pdf-box">
                                      <img src={DocumentIcon} alt="" />
                                      <Box className="pdf-info">
                                        <h4>{file.name}</h4>
                                        <CloseIcon
                                          onClick={() => {
                                            const remainFile = [...values.otherDocument].filter(
                                              (file: any, idx: number) => idx !== index
                                            );
                                            setFieldValue("otherDocument", remainFile);
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                                {this.handleEditErrorMessage(errors.otherDocument, touched.otherDocument, t)}
                              </FormControl>
                            </Box>
                            <Box className="next-button submit-button edit-tenant-button">
                              <Button
                                className="cancel"
                                onClick={() => this.props.navigation.navigate("TenantDetails", { id: this.state.tenantId })}
                              >
                                {t("Cancel")}
                              </Button>
                              <Button type="submit">{t("Save")}</Button>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="right-block right-image" display={{ xs: "none", md: "flex" }}>
                <img src={BuildingImage.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(TenantStyle)(withRouter(EditTenant)));
// Customizable Area End
