// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { removeStorageData } from "../../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  companiesList: any;
  countriesList: any;
  cityList: any;
  regionList: any;
  statusList: any;
  companyFilter: string;
  countryFilter: string;
  cityFilter: string;
  regionFilter: string;
  statusFilter: string;
  complexList: any;
  deleteConfirmModal: boolean;
  tabNo: number;
  page: number;
  buildpage: number;
  pagination: any;
  buildpagination: any;
  mapSwitch: boolean;
  accountType: string;
  openOptions: number;
  mapPoints: any;
  mapPointClick: number;
  registerModal: boolean;
  accountManagerList: any;
  accountManagerFilter: any;
  searchQuery: any;
  buildingList: any;
  salesManagerFilter: any;

  sortBy: string;
  isRejectionModalOpen: boolean;

  isMapModalOpen: boolean;
  lat: number;
  long: number;

  loading: boolean;

  rejectNote: string;

  isCreateAccess: boolean;
}

interface SS {
  id: any;
}

export default class ComplexListingTableController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompaniesFilterListComplex: string = "";
  GetCountriesFilterListComplex: string = "";
  GetRegionsFilterListComplex: string = "";
  GetCityFilterListComplex: string = "";
  GetStatusFilterListComplex: string = "";
  GetAccountManagerFilterList: string = "";
  GetFilterApi: string = "";
  GetCompanyDetails: string = "";
  GetComplexes: string = "";
  DeleteCompany: string = "";
  timeout: any;
  GetBuildings: string = "";
  CheckCreatePermissionCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      companiesList: [],
      countriesList: [],
      cityList: [],
      regionList: [],
      statusList: [],
      complexList: [],
      accountManagerList: [],
      accountManagerFilter: "",
      mapPointClick: 0,
      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      regionFilter: "",
      statusFilter: "",
      accountType: "",
      openOptions: 0,
      registerModal: false,
      mapPoints: [],
      page: 1,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      buildpage: 1,
      buildpagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      mapSwitch: false,
      deleteConfirmModal: false,
      tabNo: 0,
      searchQuery: "",
      buildingList: [],

      salesManagerFilter: "",

      sortBy: "",
      isRejectionModalOpen: false,

      isMapModalOpen: false,
      lat: 0,
      long: 0,

      loading: false,

      rejectNote: "",

      isCreateAccess: true,
    };
    this.timeout = null;
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompaniesFilterListComplex:
          return this.handleFiltersList(responseJson, "companiesList", "company_list");
        case this.GetCountriesFilterListComplex:
          return this.handleCountryFiltersList(responseJson);
        case this.GetCityFilterListComplex:
          return this.handleCityFiltersList(responseJson);
        case this.GetRegionsFilterListComplex:
          return this.handleRegionFiltersList(responseJson);
        case this.GetStatusFilterListComplex:
          return this.handleFiltersList(responseJson, "statusList", "status");
        case this.GetAccountManagerFilterList:
          return this.handleFiltersList(responseJson, "accountManagerList", "account_managers");
        case this.GetComplexes:
          return this.handleComplexList(responseJson);
        case this.GetBuildings:
          return this.handleBuildingList(responseJson);
        case this.CheckCreatePermissionCallId:
          return this.handleCheckCreatePermission(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    let filterCallArr = [
      {
        name: "GetCompaniesFilterListComplex",
        endPoint: "all_company_name",
      },
      {
        name: "GetStatusFilterListComplex",
        endPoint: "list_status",
      },
      {
        name: "GetAccountManagerFilterList",
        endPoint: "account_manager_list",
      },
    ];

    const accountRole = localStorage.getItem("role");

    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, "");
      this.setState({ accountType: accountRoleName });

      if (accountRoleName === "coo") {
        filterCallArr = [
          ...filterCallArr,
          {
            name: "GetAccountManagerFilterList",
            endPoint: "account_manager_list",
          },
        ];
      }
    }

    filterCallArr.forEach((filter) => {
      this.getFilterList(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`);
    });

    this.setState({ loading: true }, () => {
      this.getComplexList();
      this.getBuildingList();
    });

    this.getFilterList("GetCountriesFilterListComplex", `bx_block_address/country_list`);
    this.checkCreatePermission();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.mapSwitch !== this.state.mapSwitch || prevState.tabNo !== this.state.tabNo) {
      if (this.state.mapSwitch && this.state.tabNo === 0) {
        const mapPoints = this.state.complexList.map((complex: any) => {
          const locationObj = {
            lat: complex.attributes?.lat,
            lng: complex.attributes?.long,
            complex: complex.attributes?.name,
            complexId: complex.id,
            chairman: complex.attributes?.chairman?.full_name,
            totalUnits: complex.attributes?.total_units[0],
            subscription: complex.attributes?.subscription_end_in,
          };
          return locationObj;
        });
        this.setState({ mapPoints });
      }
      if (this.state.mapSwitch && this.state.tabNo === 1) {
        const mapPoints = this.state.buildingList.map((building: any) => {
          const locationObj = {
            lat: building.attributes?.lat,
            lng: building.attributes?.long,
            complex: building.attributes?.name,
            complexId: building.id,
            chairman: building.attributes?.chairman?.full_name,
            totalUnits: building.attributes?.total_units[0],
            subscription: building.attributes?.subscription_end_in,
          };
          return locationObj;
        });
        this.setState({ mapPoints });
      }
    }

    if (prevState.page !== this.state.page) {
      await this.getComplexList();
    }

    if (prevState.buildpage !== this.state.buildpage) {
      await this.getBuildingList();
    }
  }

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;
    this.setState({ ...this.state, [stateParam]: value }, () => {
      if (stateParam === "countryFilter") {
        this.setState({ regionFilter: "", cityFilter: "" }, () => {
          this.getFilterList(
            "GetCityFilterListComplex",
            `bx_block_address/city_list?country=${this.handleAllFilter(value)}`
          );
          this.getFilterList(
            "GetRegionsFilterListComplex",
            `bx_block_address/state_list?country_name=${this.handleAllFilter(value)}`
          );
        });
      }
    });
  };

  handleBuildingList = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.society_management && responseJson.society_management.data.length > 0) {
        this.setState({
          buildingList: responseJson.society_management.data,
          buildpage: responseJson?.meta?.pagination.current_page || 1,
          buildpagination: responseJson?.meta?.pagination,
        });
      } else {
        this.setState({ buildingList: [], buildpage: 1, buildpagination: null });
      }
    });
  };

  handleCloseRegisterModal = () => {
    this.setState({ registerModal: false });
  };

  handleOpenRegisterModal = () => {
    this.setState({ registerModal: true });
    removeStorageData("pathName");
    removeStorageData("editComplex");
  };

  handleVistorPagination = (e: any, value: any) => {
    this.setState({ loading: true, page: value });
  };

  buildingListPagination = (e: any, value: any) => {
    this.setState({ buildpage: value, loading: true });
  };

  handleMapSwitch = (e: any, val: boolean) => {
    this.setState({
      mapSwitch: val,
    });
  };

  handleOpenOptions = (id: number) => {
    this.setState({ openOptions: id });
  };
  handleBuildOpenOptions = (id: number) => {
    this.setState({ openOptions: id });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({
      tabNo: newValue,
      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      regionFilter: "",
      statusFilter: "",
      accountManagerFilter: "",
    });
  };

  handleComplexList = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.society_management && responseJson.society_management.data.length > 0) {
        this.setState({
          complexList: responseJson.society_management?.data,
          page: responseJson?.meta?.pagination?.current_page,
          pagination: responseJson?.meta?.pagination,
        });
      } else {
        this.setState({ complexList: [], page: 1, pagination: null });
      }
    });
  };

  handleFiltersList = (responseJson: any, stateParam: string, resParam: string) => {
    if (responseJson) {
      this.setState({ ...this.state, [stateParam]: responseJson[resParam] });
    }
  };

  handleCountryFiltersList = (responseJson: { data: { countries: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, countriesList: responseJson.data.countries });
    }
  };

  handleRegionFiltersList = (responseJson: []) => {
    this.setState({ regionList: responseJson });
  };

  handleCityFiltersList = (responseJson: { data: { cities: [] } }) => {
    if (responseJson && responseJson.data && responseJson.data.cities) {
      this.setState({ cityList: responseJson.data.cities });
    }
  };

  nerfNames = (data: any) => {
    if (data) {
      const charLength = 10;
      if (data.length > 8) {
        const processedString = data.substring(0, charLength).concat("..");
        return processedString.length <= 11 ? data : processedString;
      }
    }
    return data;
  };

  handleComplexFilterDataKeyUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    clearTimeout(this.timeout);

    this.setState({ searchQuery }, () => {
      if (this.state.tabNo) {
        this.getBuildingList();
      } else {
        this.getComplexList();
      }
    });
  };

  handleSelectedSortBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sortBy: e.target.value, loading: true }, () => {
      if (this.state.tabNo) {
        this.getBuildingList();
      } else {
        this.getComplexList();
      }
    });
  };

  handleFilterSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.tabNo) {
      this.getBuildingList();
    } else {
      this.getComplexList();
    }
  };

  getComplexList = async () => {
    this.GetComplexes = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes?page=${
        this.state.page
      }&per_page=10&search=${this.handleAllFilter(this.state.searchQuery)}&country=${this.handleAllFilter(
        this.state.countryFilter
      )}&city=${this.handleAllFilter(this.state.cityFilter)}&state=${this.handleAllFilter(
        this.state.regionFilter
      )}&status=${this.handleAllFilter(this.state.statusFilter.trim())}&account_manager_id=${this.handleAllFilter(
        this.state.accountManagerFilter
      )}&company_name=${this.handleAllFilter(this.state.companyFilter)}&is_building=false&sort_by=${this.state.sortBy}`,
    });
  };

  handleAllFilter = (value: string | number) => (value === "all" ? "" : value);

  getBuildingList = async () => {
    this.GetBuildings = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes?page=${
        this.state.buildpage
      }&per_page=10&search=${this.handleAllFilter(this.state.searchQuery)}&country=${this.handleAllFilter(
        this.state.countryFilter
      )}&city=${this.handleAllFilter(this.state.cityFilter)}&state=${this.handleAllFilter(
        this.state.regionFilter
      )}&account_manager_id=${this.handleAllFilter(this.state.accountManagerFilter)}&status=${this.handleAllFilter(
        this.state.statusFilter
      )}&company_name=${this.handleAllFilter(this.state.companyFilter)}&is_building=true&sort_by=${this.state.sortBy}`,
    });
  };

  getFilterList = async (method: string, endPoint: string) => {
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  };

  handleMapPointClick = (key: any) => {
    this.setState({ mapPointClick: key });
  };

  handleRejectionModal = () => {
    this.setState({ isRejectionModalOpen: !this.state.isRejectionModalOpen });
  };

  handleMapModal = () => {
    this.setState({ isMapModalOpen: !this.state.isMapModalOpen });
  };

  checkCreatePermission = async () => {
    this.CheckCreatePermissionCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_roles_permissions/user_permissions/check_permission?action_name=create&module_name=BxBlockSocietyManagement::SocietyManagement`,
    });
  };

  handleCheckCreatePermission = (response: any) => {
    if (response) {
      this.setState({ isCreateAccess: response.access });
    }
  };
}
// Customizable Area End
