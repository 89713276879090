// Customizable Area Start
import React from "react";
import ComplexDetailsController, { Props } from "./ComplexDetailsController.web";
import { withRouter } from "react-router-dom";
import ComplexDetailsPage from "./ComplexDetailsPage.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class ComplexDetails extends ComplexDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;

    return (
      <>
        <CEHeaderComponentWeb>
          <ComplexDetailsPage companyId={this.state.complexId} navigation={navigation} id={"complex_listings"} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(ComplexDetails);
// Customizable Area End
