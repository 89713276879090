//Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { RouteComponentProps } from "react-router-dom";
import { setStorageData } from "../../../../framework/src/Utilities";

export interface Props extends RouteComponentProps {
  navigation: {
    getParam: (val: string) => string;
  };
  id: string;
}

interface S {
  complexId: string;
}

interface SS {
  id: any;
}

export default class ComplexDetailsController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      complexId: this.props.navigation.getParam("id"),
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const ComplexId = this.props.navigation.getParam("id");
    this.setState({ complexId: ComplexId });
    setStorageData("complexId",this.state.complexId)
  }
}
// Customizable Area End
