// Customizable Area Start
import React from "react";
import { Container, Typography, withStyles, Card, Divider, Grid, Link } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import { withRouter } from "react-router";
import CEUnitDetailsController, { Props } from "./CEUnitDetailsController.web";
import {
  CityIcon,
  ConfigIcon,
  CountryIcon,
  Currency_Icon,
  FlagIcon,
  FloorIcon,
  PurchaseDateIcon,
  PurchasePriceIcon,
  RegionIcon,
  SizeIcon,
  UserProfileIcon,
  ValuationIcon,
} from "../assets";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { BuildingApartmentStyle } from "../../../LeadManagement/src/BuildingApartmentStyle.web";
import LocationCard from "../../../../components/src/ComplexAndApartment/LocationCard.web";
import { nextIcon, previousIcon } from "./assets";

const settings = {
  infinite: false,
  slidesToShow: 5,
  swipeToSlide: true,
};

class CEUnitDetails extends CEUnitDetailsController {
  constructor(props: Props) {
    super(props);
  }

  buildingLocation = (t: (val: string) => string) => {
    return (
      <Box className="location-details">
        <Box className="heading">
          <h4 className="bold-text">{t("Building Location Details")}</h4>
        </Box>
        <Box className="location-data">
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <LocationCard
                image={CountryIcon}
                style={dashBoard.locationIcon}
                heading={t("Country")}
                value={this.handleUnitDetailText(this.state.unitData.country)}
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={RegionIcon}
                style={dashBoard.locationIcon}
                heading={t("Region")}
                value={this.handleUnitDetailText(this.state.unitData.region)}
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={CityIcon}
                style={dashBoard.locationIcon}
                heading={t("City")}
                value={this.handleUnitDetailText(this.state.unitData.city)}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  unitDetails = (t: (val: string) => string) => {
    return (
      <Box className="location-details unit-details">
        <Box className="heading">
          <h4 className="bold-text">{t("Unit Details")}</h4>
        </Box>
        <Box className="location-data">
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <LocationCard
                image={FloorIcon}
                style={dashBoard.locationIcon}
                heading={t("Floor Number")}
                value={this.handleUnitDetailText(this.state.unitData.floor)}
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={SizeIcon}
                style={dashBoard.locationIcon}
                heading={t("Size")}
                value={
                  Number(this.handleUnitDetailText(this.state.unitData.size || "0")).toLocaleString() +
                  " " +
                  (this.state.unitData.measurement || "")
                }
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={ConfigIcon}
                style={dashBoard.locationIcon}
                heading={t("Configuration")}
                value={this.handleUnitDetailText(this.state.unitData.configuration)}
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={PurchasePriceIcon}
                style={dashBoard.locationIcon}
                heading={t("Purchase Price")}
                value={
                  (this.state.unitData.currency || "") +
                  " " +
                  Number(this.handleUnitDetailText(this.state.unitData.purchasePrice || "0")).toLocaleString()
                }
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={PurchaseDateIcon}
                style={dashBoard.locationIcon}
                heading={t("Purchase Date")}
                value={
                  this.state.unitData.purchaseDate
                    ? moment(this.state.unitData.purchaseDate).format("MMMM DD, YYYY")
                    : "-"
                }
              />
            </Grid>
            <Grid item sm={4}>
              <LocationCard
                image={ValuationIcon}
                style={dashBoard.locationIcon}
                heading={t("Current Valuation")}
                value={
                  (this.state.unitData.currency || "") +
                  " " +
                  Number(this.handleUnitDetailText(this.state.unitData.currentValuation || "0")).toLocaleString()
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  familyList = (t: (val: string) => string) => {
    if (this.state.unitData.familyList.length > 0) {
      return (
        <Box className="related-people family-details">
          <Box className="heading">
            <h4 className="bold-text">{t("Family Members")}</h4>
          </Box>
          <Grid container spacing={2}>
            {this.state.unitData.familyList.map((family: any) => {
              return (
                <Grid item sm={4} key={family.id}>
                  <Card className="user-details">
                    <Box className="heading">
                      <h4 className="bold-text">{family.attributes.name}</h4>
                    </Box>
                    <p className="label">{t("Relation")}:</p>
                    <Box className="user-info">
                      <p>{family.attributes.relation.name}</p>
                      <p>{family.attributes.id_number || ""}</p>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      );
    }
  };

  rentHistory = (t: (val: string) => string) => {
    if (this.state.unitData.rentHistory.length > 0) {
      return (
        <Box className="rent-history">
          <Box className="heading">
            <h4 className="bold-text">{t("Rent History")}</h4>
          </Box>
          <Box className="history-data">
            <Grid container spacing={2}>
              {this.state.unitData.rentHistory.map((rent: any) => {
                return (
                  <Grid item sm={6} key={rent.id}>
                    <Card>
                      <h4 className="bold-text">{rent.attributes.tenant_name}</h4>
                      <p className="date">
                        {moment(rent.attributes.start_date, "YYYY-MM-DD").format("MMMM YY")} to{" "}
                        {moment(rent.attributes.end_date, "YYYY-MM-DD").format("MMMM YY")}
                      </p>
                      <Divider />
                      <Box className="history-info" style={{ marginTop: "8px" }}>
                        <p>{t("Rent Amount (Monthly)")}</p>
                        <p>
                          <span>
                            {this.state.unitData.currency || ""}{" "}
                            {Number(rent.attributes.rent_amount || 0).toLocaleString()}
                          </span>
                        </p>
                      </Box>
                      <Box className="history-info">
                        <p>{t("Received Amount")}</p>
                        <p>
                          <span>
                            {this.state.unitData.currency || ""}{" "}
                            {Number(rent.attributes.received_amount || 0).toLocaleString()}
                          </span>
                        </p>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      );
    }
  };

  unitPhotos = (t: (val: string) => string) => {
    if (this.state.unitData.photos.length > 0) {
      return (
        <Box className="building-info">
          <Box className="heading">
            <h4 className="bold-text">{t("Unit Pictures")}</h4>
          </Box>
          <Card>
            <Box className="building-info-bottom">
              <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                {this.state.unitData.photos.map((image: any, index: number) => {
                  return (
                    <div className="slider-image" onClick={() => this.setState({ imageBox: true, photoIndex: index })}>
                      <img src={image.url} alt="" />
                    </div>
                  );
                })}
              </Slider>
              <Box className="slick-bottom">
                <Box className="button prev" onClick={this.previousImage}>
                  <img src={previousIcon} alt="" />
                </Box>
                <Box className="button next" onClick={this.nextImage}>
                  <img src={nextIcon} alt="" />
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      );
    }
  };

  rentStatus = (t: (val: string) => string) => {
    return (
      <Box className="location-details unit-details rent-status">
        <Box className="heading">
          <h4 className="bold-text">{t("Rent Status")}</h4>
        </Box>
        <Box className="location-data">
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Card>
                <img src={FlagIcon} style={dashBoard.locationIcon} />
                <Box className="location-info">
                  <p>{t("Unit Status")}</p>
                  <h4 className="bold-text">
                    {this.state.unitData.rentStatus === "No-Own" ? "Not Owned" : this.state.unitData.rentStatus}
                  </h4>
                </Box>
              </Card>
            </Grid>
            {this.state.unitData.rentStatus === "rented" && (
              <>
                <Grid item sm={4}>
                  <Card>
                    <img src={UserProfileIcon} style={dashBoard.locationIcon} />
                    <Box className="location-info">
                      <p>{t("Tenant Name")}</p>
                      <h4 className="bold-text" style={{ fontWeight: 600, color: "#FC8434" }}>
                        {this.state.unitData.tenantName || "-"}
                      </h4>
                    </Box>
                  </Card>
                </Grid>
                <Grid item sm={4}>
                  <Card>
                    <img src={Currency_Icon} style={dashBoard.locationIcon} />
                    <Box className="location-info">
                      <p>{t("Rent Amount")}</p>
                      <h4 className="bold-text">
                        {this.state.unitData.currency || ""}{" "}
                        {Number(this.state.unitData.rentAmount || "0").toLocaleString()} / Month
                      </h4>
                    </Box>
                  </Card>
                </Grid>
                <Grid item sm={4}>
                  <Card>
                    <img src={PurchaseDateIcon} style={dashBoard.locationIcon} />
                    <Box className="location-info">
                      <p>{t("Rent Tenure")}</p>
                      <h4 className="bold-text">
                        {this.state.unitData.rentStartDate &&
                          moment(this.state.unitData.rentStartDate, "YYYY-MM-DD").format("MMM DD, YYYY")}{" "}
                        -
                        {this.state.unitData.rentEndDate &&
                          moment(this.state.unitData.rentEndDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
                      </h4>
                    </Box>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    );
  };

  render() {
    const { t, classes }: any = this.props;

    return (
      <>
        <Loader loading={this.state.loading} />

        <CEHeaderComponentWeb>
          <Container className={classes.unitDetails}>
            <Box style={dashBoard.navigation}>
              <Typography variant="body1">
                <Link href={`/CompanyEmployee/complexlisting`}>{t("Complex / Buildings")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Unit")} {this.state.unitData.unitName}
                </Box>
              </Typography>
            </Box>
            <Typography variant="h5" style={dashBoard.subHeading} className="bold-text">
              {t("Unit")} {this.state.unitData.unitName}
            </Typography>

            {this.buildingLocation(t)}

            {this.unitDetails(t)}

            {this.familyList(t)}

            {this.rentStatus(t)}

            {this.rentHistory(t)}

            {this.unitPhotos(t)}

            {this.state.imageBox && this.state.unitData.photos.length > 0 && (
              <Lightbox
                imagePadding={120}
                mainSrc={this.state.unitData.photos[this.state.photoIndex].url}
                nextSrc={
                  this.state.unitData.photos[(this.state.photoIndex + 1) % this.state.unitData.photos.length].url
                }
                prevSrc={
                  this.state.unitData.photos[
                    (this.state.photoIndex + this.state.unitData.photos.length - 1) % this.state.unitData.photos.length
                  ].url
                }
                onCloseRequest={() => this.setState({ imageBox: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + this.state.unitData.photos.length - 1) %
                      this.state.unitData.photos.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (this.state.photoIndex + 1) % this.state.unitData.photos.length,
                  })
                }
              />
            )}
          </Container>
        </CEHeaderComponentWeb>
      </>
    );
  }
}

const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 20,
    fontSize: "26px",
  },
  locationIcon: {
    height: 25,
    width: 25,
  },
};

export default withTranslation()(withStyles(BuildingApartmentStyle)(withRouter(CEUnitDetails)));
// Customizable Area End
