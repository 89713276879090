import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  Button,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Divider,
  styled,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { Menu } from "@szhsin/react-menu";
import { wrongIcon } from "./assets";
import { ErrorHandler, navigationLink } from "../../../components/src/UtilFunction.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import AlertDialog from "../../../components/src/MuiDialog/AlertDialog.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Formik } from "formik";
import NormalSelect from "../../../components/src/CommonComponents/NormalSelect.web";
import FilterDateField from "../../../components/src/CommonComponents/FilterDateField.web";
import { formatOnlyDate } from "../../../components/src/helperFunctions.web";
// Customizable Area End

import DashboardsGrantedController, {
    Props,
} from "./DashboardsGrantedController.web";

// Customizable Area Start
export class DashboardsGranted extends DashboardsGrantedController {
  constructor(props: Props) {
    super(props);
  }

  handleBoxgrid = (t: any, language: any) => {
    return (
      <Box>
        <Grid container xs={12} spacing={2}>
          {this.state.userRole !== "coo" && (
            <Grid item>
              <Typography
                style={this.state.tags === "Granted to Me" ? tagsCss.selectTag : tagsCss.unSelectTag}
                data-test-id="subscriptionId"
                onClick={() => this.handleTag("Granted to Me", "")}
                className="bold-text"
              >
                {t("Granted to Me")}
              </Typography>
            </Grid>
          )}
          {this.state.userRole !== "sales_manager" && (
            <Grid item>
              <Typography
                style={
                  this.state.tags === "Granted to Sales Managers"
                    ? tagsCss.selectTag
                    : tagsCss.unSelectTag
                }
                data-test-id="subscriptionId"
                onClick={() => this.handleTag("Granted to Sales Managers", "sales_manager")}
                className="bold-text"
              >
                {t("Granted to Sales Managers")}
              </Typography>
            </Grid>
          )}
          {this.state.userRole !== "account_manager" && (
            <Grid item>
              <Box>
                <Typography
                  onClick={() => this.handleTag("Granted to Account Managers", "account_manager")}
                  style={
                    this.state.tags === "Granted to Account Managers"
                      ? tagsCss.selectTag
                      : tagsCss.unSelectTag
                  }
                  data-test-id="IndividualId"
                  className="bold-text"
                >
                  {t("Granted to Account Managers")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    )
  }

  handleTableboxGrid=(t: any)=>{
    return(
      <Grid className="table-box-card">
      <Grid item sm={12} md={12} xs={12}>
        <h4 className="bold-text table-top">{t("Granted Permissions")}</h4>
        <Divider />
        <TableContainer className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
               
                <TableCell className="bold-text">{(this.state.userRole==="sales_manager")?t("Granted Permission"):t("Permission Type")}</TableCell>
                {this.state.tags === "Granted to Sales Managers" && (
                  <TableCell className="bold-text">{t("Sales Manager")}</TableCell>
                )}
                {this.state.tags === "Granted to Account Managers" && (
                  <TableCell className="bold-text">{t("Account Manager")}</TableCell>
                )}
                <TableCell className="bold-text">{t("Granted By")}</TableCell>
                <TableCell className="bold-text">{t("Granted On")}</TableCell>
                {(this.state.userRole!=="sales_manager"||this.state.tags !== "Granted to Me")&&
                <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.permissionList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>{t("No data found")}</TableCell>
                </TableRow>
              )}
              {this.state.permissionList.map((permission: any ,permissionIndex:number) => {
                return (
                  <TableRow key={permission.id}>
                    <TableCell>{(this.state.page - 1) * 10 + permissionIndex + 1}
                    </TableCell>
                    <TableCell>{permission.attributes.permission_type.permission_name}</TableCell>
                    {(this.state.userRole!=="sales_manager"||this.state.tags !== "Granted to Me")?
                    <TableCell className="ellipse-one">
                      {permission.attributes.manager.full_name}
                    </TableCell>:""}
                    <TableCell>{permission.attributes.granted_by.full_name}</TableCell>
                    <TableCell>{formatOnlyDate(permission.attributes.granted_on)}</TableCell>
                      {(this.state.userRole!=="sales_manager"||this.state.tags !== "Granted to Me")&&
                    <TableCell className="ce-pm-report-menu">
                      <Menu
                      onClick={()=>{this.handleRevokedata(permission)}}
                      data-test-id="MoreVertIcon"
                        menuButton={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                      >
                        <MenuItem
                          className="ce-pm-view-report"
                          data-test-id="viewProfileId"
                          onClick={() =>
                            this.setState({ permissionId: permission.id }, () =>
                              this.handleRevokeDialog()
                            )
                          }
                        >
                          {t("Revoke")}
                        </MenuItem>
                      </Menu>
                    </TableCell>}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="table-bottom">
          <p>
            {t("Showing")} <span className="current-page">{this.state.permissionList.length}</span>{" "}
            {t("of")}{" "}
            <span className="total-page">
              {this.state.pagination ? this.state.pagination.total_count : 0}
            </span>{" "}
            {t("results")}
          </p>
          {this.state.pagination && (
            <Pagination
              variant="outlined"
              onChange={this.handlePagination}
              siblingCount={2}
              count={this.state.pagination.total_pages}
              shape="rounded"
              page={this.state.pagination.current_page}
              data-test-id="grant-permission-pagination"
            />
          )}
        </Box>
      </Grid>
    </Grid>
    )
  }

  handleSalesmanagerGrated = (t:any,language:any,
    values: any, touched: any, errors: any, handleBlur: any,
    handleChange: any, 
  ) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <NormalSelect
            t={t}
            language={language}
            label="Permission"
            value={values.permission}
            name="permission"
            onBlur={handleBlur}
            data-test-id="Permission"
            onChange={(e: { target: { value: string; }; }) => {
              handleChange(e);
              this.handlePermissionSelect(e.target.value);
            }}
            option={this.state.formPermissionList}
          />
          {ErrorHandler(t, touched.permission, errors.permission)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <NormalSelect
            t={t}
            language={language}
            label="Account Manager"
            value={values.employee}
            name="employee"
            onBlur={handleBlur}
            onChange={handleChange}
            data-test-id="Account Manager"
            option={this.state.managerListData}
          />
          {ErrorHandler(t, touched.role, errors.role)}
        </Grid>
      </Grid>
    )
  }
 
  
// Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n } = this.props;
    const language = i18n.language;
        // Customizable Area End
            return (
              // Customizable Area Start
              <CEHeaderComponentWeb>
                <DashboardApprovalCss>
                  <Box className="containerBox">
                    <Box className="navigation">
                      <Typography variant="body1" className="breadcrumb-link">
                        <Box
                          data-test-id="dashboard-link"
                          onClick={() => navigationLink("DashboardCompanyEmployee", this)}
                        >
                          {t("My Dashboards")}
                        </Box>{" "}
                        / <Box component="span"  data-test-id="approvalDashboardnavigate" onClick={this.handleApprovalroute}>{t("Approval Dashboard")}</Box> /{" "}
                        <Box component="span" className="tabColor">
                          {t("Granted Permissions")}
                        </Box>
                      </Typography>
                    </Box>
                    <br />
                    <Box className="header-title">
                      <Typography variant="h4" className="bold-text">
                        {t("Granted Permissions")}
                      </Typography>
                      <Typography
                        className="bold-text downloadButton grant-permission-btn"
                        onClick={this.handleGrantPermission}
                        data-test-id="downloadButtonId1"
                      >
                        {t("GRANT PERMISSION")}
                      </Typography>
                    </Box>
                    <Box>
                    {this.handleBoxgrid(t,language)}
                      <Grid container xs={12} spacing={2}>
                        <Box className="filter-head-box">
                          {this.state.tags === "Granted to Sales Managers" && (
                            <FilterSelect
                              option={[{ label: "All", value: "all" }, ...this.state.managerList]}
                              language={language}
                              t={t}
                              label="Select Sales Manager"
                              onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                this.handleGrantedFilterChange(event, "salesManager")
                              }
                              value={this.state.filter.salesManager}
                              data-test-id="selectSalesManagerId"
                            />
                          )}
                          {this.state.tags === "Granted to Account Managers" && (
                            <FilterSelect
                              option={[{ label: "All", value: "all" }, ...this.state.managerList]}
                              language={language}
                              t={t}
                              label="Select Account Manager"
                              onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                this.handleGrantedFilterChange(event, "accountManager")
                              }
                              value={this.state.filter.accountManager}
                              data-test-id="selectCountryId"
                            />
                          )}
                          <FilterSelect
                            option={[{ label: "All", value: "all" }, ...this.state.grantedBy]}
                            language={language}
                            t={t}
                            label="Granted By"
                            onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                              this.handleGrantedFilterChange(event, "grantedBy")
                            }
                            value={this.state.filter.grantedBy}
                            data-test-id="selectCityId"
                          />
                          <FilterDateField
                          style={{width:"177px"}}
                            language={language}
                            t={t}
                            label="Granted On"
                            onChange={(date: string) =>
                              this.handleGrantedFilterChange({ target: { value: date } }, "grantedOn")
                            }
                            value={this.state.filter.grantedOn}
                            data-test-id="selectUserTypeId"
                          />
                          {this.state.userRole === "coo" && (
                            <FilterSelect
                              option={[{ label: "All", value: "all" }, ...this.state.formPermissionList]}
                              language={language}
                              t={t}
                              label="Permission Type"
                              onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                                this.handleGrantedFilterChange(event, "permissionType")
                              }
                              value={this.state.filter.permissionType}
                              data-test-id="selectRequestorId"
                            />
                          )}
                          <Button onClick={this.getAllPermissionList} startIcon={<SearchIcon />}>
                            {t("Search")}
                          </Button>
                        </Box>
                      </Grid>
                     {this.handleTableboxGrid(t)}
                    </Box>
                  </Box>

                  <Dialog
                    open={this.state.isGrantPermission}
                    dir={languageCondition(language, "rtl", "ltr")}
                    PaperProps={{
                      style: {
                      
                        borderRadius: '12px', 
                      },
                    }}
                    className="dialogBox"
                    scroll="paper"
                    fullWidth
                    
                    data-test-id="grant-permission-modal"
                  >
                    <DialogHeader t={t} title="Grant Permission" onClose={this.handleGrantPermission} />
                    <Box style={{borderBottom:"1px solid #b3b3b3"}}></Box>
                    <Formik
                      enableReinitialize
                      initialValues={this.state.grantPermissionForm}
                      onSubmit={(values, { resetForm }) => {
                        this.setState({ loading: true, isGrantPermission: false }, () => {
                          this.createPermission(values);
                          resetForm();
                        });
                      }}
                      data-test-id="grant-permission-modal-formik"
                    >
                      {({ values, touched, errors, handleBlur, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                          <form onSubmit={handleSubmit} className="">
                            <DialogContent >
                            {this.state.userRole==="sales_manager"?
                             <>
                             {this.handleSalesmanagerGrated(t,language,values, touched, errors, handleBlur, handleChange)}
                             </>
                              :<Grid container spacing={2}>
                                <Grid item xs={12} sm={12}  >
                                  <NormalSelect
                                    t={t}
                                    language={language}
                                    label="Permission"
                                    value={values.permission}
                                    name="permission"
                                    onBlur={handleBlur}
                                    data-test-id="grant-permission"
                                    onChange={(e: { target: { value: string; }; }) => {
                                      handleChange(e); 
                                      this.handlePermissionSelect(e.target.value); 
                                    }}
                                    option={this.state.formPermissionList}
                                  />
                                  {ErrorHandler(t, touched.permission, errors.permission)}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <NormalSelect
                                    t={t}
                                    language={language}
                                    label="Role"
                                    value={values.role}
                                    name="role"
                                    onBlur={handleBlur}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue("role", event.target.value);
                                      this.getEmployeeList(event.target.value);
                                    }}
                                    data-test-id="grant-permission-role"
                                    option={[
                                      { label: "Sales Manager", value: "sales_manager" },
                                      { label: "Account Manager", value: "account_manager" },
                                    ]}
                                  />
                                  {ErrorHandler(t, touched.role, errors.role)}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <NormalSelect
                                    t={t}
                                    language={language}
                                    label="Employee Name DashboardGranted"
                                    value={values.employee}
                                    name="employee"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    option={this.state.employeeList}
                                  />
                                  {ErrorHandler(t, touched.employee, errors.employee)}
                                </Grid>
                              </Grid>}
                            </DialogContent>
                            <DialogActions className="dialog__button_group">
                              <Button className="cancel-button" onClick={this.handleGrantPermission}>
                                {t("Cancel")}
                              </Button>
                              {this.state.userRole==="sales_manager"?
                              <Button type="submit" className="add-button">
                                {t("Assign")}
                              </Button>:
                              <Button type="submit" className="add-button">
                                {t("SAVE")}
                              </Button>}
                            </DialogActions>
                          </form>
                        );
                      }}
                    </Formik>
                  </Dialog>

                  <AlertDialog
                    IsOpen={this.state.isRevokePermission}
                    Image={wrongIcon}
                    CloseDialog={this.handleRevokeDialog}
                    Header={t("Revoke Permission?")}
                    Content={`<p>${t(
                      `Are you sure want to revoke ${this.state.revokedDatapermission} permission from`
                    )} ${this.state.revokeSalemanager}?</p>`}
                    DeclineText={t("Close")}
                    AcceptText={t("Confirm")}
                    DeclineFun={this.handleRevokeDialog}
                    AcceptFun={() =>
                      this.setState({ isRevokePermission: false, loading: true }, () => this.handleRevokePermission())
                    }
                    data-test-id="alert-dialog"
                  />
                </DashboardApprovalCss>
              </CEHeaderComponentWeb>
              // Customizable Area End
            );
        }
}

// Customizable Area Start
export const DashboardApprovalCss = styled(Box)({
  "& .containerBox": {
    padding: "0px 40px",
  },
  "& .breadcrumb-link": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  "& .selectmenu": {
    border: "1px solid #F0F0F0",
    padding: "7px",
    background: "#fff",
    borderRadius: "15px",
    borderBottom: "none",
    width: "100%",
    gap: "10px",
    marginTop: "10px",
  },
  "& .mybtn": {
    backgroundColor: "#2D6EED",
    color: "white",
    padding: "8px 16px",
    marginTop: "11px",
    textTransform: "capitalize",
  },
  "& .horizontalLine":{
 borderBottom:"1px solid #b3b3b3"
  },
  "& .Box": {
    borderRadius: "10px",
    backgroundColor: "#fff",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingLeft: "30px",
    alignItems: "flex-start",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    marginTop: "25px",
  },
  "& .selectTag": {
    padding: "10px 20px",
    background: "#2B6FED",
    color: "#fff",
    marginTop: "35px",
    cursor: "pointer",
    borderRadius: "20px",
  },
  "& .unSelectTage": {
    marginTop: "35px",
    background: "#EEF0F8",
    color: "#8D8F98",
    borderRadius: "20px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  "& .header-title": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .table-container": {
    marginTop: "20px",
    boxShadow: "none",
  },
  "& .tabColor": {
    color: "rgb(43, 111, 237)",
  },
  "& .table-top": {
    margin: "0px 20px 0px auto",
  },
  "& .downloadButton": {
    padding: "10px 20px",
    background: "#FC8434",
    color: "#fff",
    borderRadius: "8px",
    cursor: "pointer",
  },
});

export const DialogActionsCss = styled(Dialog)({

  "& .horizontalLine":{
    borderBottom:"1px solid #b3b3b3"
     },
  "& .cancel-button": {
    border: "1px solid #2b6fed",
    backgroundColor: "white",
    color: "#2b6fed",
    borderRadius: "8px",
    fontWeight: 600,
    padding: "10px 30px",
    width: "200px",
  },
  "& .add-button": {
    backgroundColor: "#2b6fed",
    color: "white",
    borderRadius: "8px",
    fontWeight: 600,
    margin: "0",
    width: "200px",
    padding: "10px 30px",
  },
  "& .dialog-button-group": {
    gap: "10px",
  },
  "& .body-text": {
    marginBottom: "8px",
  },
  "& .dialog-content": {
    margin: "15px 0",
  },
  "& .lableText": {
    color: "#fc8434",
    fontSize: "14px",
  },
  "& .selectButton": {
    color: "white",
    border: "1px solid #2B6FED",
    padding: "15px 60px",
    fontWeight: 900,
    alignItems: "end",
    backgroundColor: "#2B6FEC",
    borderRadius: "8px",
  },
  "& .cancleButton": {
    color: "#2B6FED",
    border: "1px solid #2B6FED",
    padding: "15px 50px",
    fontWeight: 900,
    alignItems: "end",
    borderRadius: "8px",
  },
  "& .titleBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "852px",
  },
  "& .buttons": {
    flexDirection: "unset",
    gap: "15px",
    margin: "20px 35px 10px 0px ",
  },
  "& .selectmenu": {
    border: "1px solid #F0F0F0",
    padding: "15px",
    background: "#C8C8C838",
    borderRadius: "15px",
    borderBottom: "none",
    width: "100%",
    gap: "10px",
    marginTop: "10px",
  },
  "& .closeIcon": {
    cursor: "pointer",
  },
});

const tagsCss = {
  selectTag: {
    background: "#2B6FED",
    padding: "10px 20px",
    marginTop: "20px",
    color: "#fff",
    borderRadius: "20px",
    cursor: "pointer",
  },
  unSelectTag: {
    background: "#EEF0F8",
    marginTop: "20px",
    color: "#8D8F98",
    padding: "10px 20px",
    borderRadius: "20px",
    cursor: "pointer",
  },
};

export default withTranslation()(withRouter(DashboardsGranted));
// Customizable Area End