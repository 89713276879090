// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

import * as Yup from "yup";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleImages } from "../../../../components/src/UtilFunction.web";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export type StyleObject = {
  [key: string]: {
    [key: string]: string;
  };
};

interface BuildingValue {
  logo: Blob | MediaSource | string;
  images: any[];
  buildingName: string;
  totalFloors: string;
  totalArea: string;
  totalUnits: string;
  locationLink: string;
  latitude: string;
  longitude: string;
  aboutUs: string;
}

export interface Props {
  step: number;
  handleNext: () => void;
  updateEditBuilding: (id: number | string) => void;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  complexId: null | string | undefined;
  buildingId: string | number | null;
  handleChangeCurrency: any;
  handleChangeMeasurement: any;
}

interface S {
  loading: boolean;

  measurement: any;

  buildingValues: BuildingValue;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBuildingBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  uploadImages: any;
  addBasicApiCall: string = "";
  GetBasicDetailApiCallId: string = "";
  getJurisdictionDetailCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.uploadImages = React.createRef();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      measurement: "",

      buildingValues: {
        logo: "",
        images: [],
        buildingName: "",
        totalFloors: "",
        totalArea: "",
        totalUnits: "",
        locationLink: "",
        latitude: "",
        longitude: "",
        aboutUs: "",
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.addBasicApiCall:
          return this.basicApiCallSucc(responseJsonBlock);
        case this.GetBasicDetailApiCallId:
          return this.handleBuildingDetailResponse(responseJsonBlock);
        case this.getJurisdictionDetailCallId:
          return this.handleJurisdictionDetailResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    if (this.props.buildingId) {
      this.setState({ loading: true }, () => {
        this.getBuildingDetails(this.props.buildingId);
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.buildingId !== this.props.buildingId) {
      this.setState({ loading: true }, () => {
        this.getBuildingDetails(this.props.buildingId);
      });
    }
  }

  getBuildingDetails = async (buildingId: number | string | null) => {
    this.GetBasicDetailApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_settings/building_managements/${buildingId}`,
    });
  };

  handleBuildingDetailResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const building = responseJson.data;
      let photos: any[] = [];
      if (building.attributes.photos.length > 0) {
        photos = await handleImages(building.attributes.photos);
      }
      let logo: any = null;
      if (building.attributes.logo) {
        const logos = await handleImages([building.attributes.logo]);
        logo = logos[0];
      }

      this.setState(
        {
          loading: false,
          buildingValues: {
            logo: logo,
            images: photos,
            buildingName: building.attributes.name,
            totalFloors: building.attributes.total_floors,
            totalArea: building.attributes.building_area,
            totalUnits: building.attributes.per_floor_unit,
            locationLink: building.attributes.googlemaplocation,
            latitude: "",
            longitude: "",
            aboutUs: building.attributes.description,
          },
        },
        () => {
          this.getJurisdiction(building.attributes.jurisdiction.data.id);
        }
      );
    }
  };

  getJurisdiction = async (id: number | string) => {
    this.getJurisdictionDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleJurisdictionDetailResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data;
      const currency = jurisdiction.attributes.currency.currency;
      const measurement = jurisdiction.attributes.unit_of_measurement;
      this.props.handleChangeCurrency(currency);
      this.props.handleChangeMeasurement(measurement);

      this.setState({ measurement: measurement });
    }
  };

  validationSchema = Yup.object().shape({
    buildingName: Yup.string()
      .min(3, "Building name must be at least 3 characters")
      .max(50, "Building name must be at most 50 characters")
      .required("Building Name is required"),
    totalArea: Yup.string().required("Total area is required").nullable(),
    totalFloors: Yup.string().required("Total floor is required"),
    totalUnits: Yup.string().required("Total units is required").nullable(),
    aboutUs: Yup.string().required("About us is required"),
    locationLink: Yup.string().required("Location is required"),
    logo: Yup.mixed().required("Please upload a logo"),
    images: Yup.array().min(1, "At least one image is required").nullable(),
  });

  handleSubmitBuildingDetail = (values: any) => {
    this.setState({ buildingValues: values, loading: true }, () => {
      this.handleAddBasic(values);
    });
  };

  handleAddBasic = async (values: any) => {
    const data = new FormData();
    data.append("society_management_id", this.props.complexId + "");
    data.append("building_management[name]", values.buildingName);
    data.append("building_management[description]", values.aboutUs);
    data.append("building_management[building_area]", values.totalArea);
    data.append("building_management[total_floor]", values.totalFloors);
    data.append("building_management[total_units]", values.totalUnits);
    data.append("building_management[googlemaplocation]", values.locationLink);
    data.append("building_management[logo]", values.logo);

    [...values.images].length &&
      [...values.images].forEach((image: any) => {
        data.append("building_management[photos][]", image);
      });

    if (this.props.buildingId !== "") {
      this.addBasicApiCall = await apiCall({
        method: "PUT",
        endPoint: `bx_block_society_management/company_employee/complexes/update_complex_building?building_management_id=${this.props.buildingId}`,
        body: data,
      });
    } else {
      this.addBasicApiCall = await apiCall({
        method: "POST",
        endPoint: `/bx_block_society_management/company_employee/complexes/create_complex_building`,
        body: data,
      });
    }
  };

  basicApiCallSucc = (responseJson: { data: { id: string | number } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.props.updateEditBuilding(responseJson.data.id);
      }
    });
  };
}

// Customizable Area End
