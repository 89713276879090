// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  MenuItem,
  Divider,
  Dialog,
  DialogActions,
  InputLabel,
  DialogContent,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Formik, Form } from "formik";
import CompEmpBuildingBlockStep5Controller, { Props } from "./CompEmpBuildingBlockStep5Controller.web";
import "../../assets/commonForm.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu } from "@szhsin/react-menu";
import { Pagination } from "@material-ui/lab";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import NormalDateField from "../../../../components/src/CommonComponents/NormalDateField.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import Loader from "../../../../components/src/Loader.web";

export default class CompEmpBuildingBlockStep5 extends CompEmpBuildingBlockStep5Controller {
  constructor(props: Props) {
    super(props);
  }

  handleStyleBox = (value: any, style: any) => {
    if (value) {
      return style["modalFieldBlack"];
    } else {
      return style["modalField"];
    }
  };

  handleStyleText = (value: any, style: any) => {
    if (value) {
      return style["modalFieldTitleBlack"];
    } else {
      return style["modalFieldTitle"];
    }
  };

  rowDetailValue = (t: (val: string) => string, label: string, value: string, xs: any) => {
    return (
      <Grid item xs={xs}>
        <Typography variant="body2">{t(label)}</Typography>
        <Typography variant="body2" style={{ color: "grey" }}>
          {value}
        </Typography>
      </Grid>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box className="topBarFormik">
          <Box style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
            <Typography className="Units bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Units")}
            </Typography>
            <Typography className="stepUnits" style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of 7")}
            </Typography>
          </Box>
          <Box style={webStyle.tableContainer} className="table-box-card">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="# bold-text" style={{ fontWeight: "bold" }}>
                      {t("#")}
                    </TableCell>
                    <TableCell className="Unit bold-text" style={{ fontWeight: "bold" }}>
                      {t("Unit Number")}
                    </TableCell>
                    <TableCell className="Floor bold-text" style={{ fontWeight: "bold" }}>
                      {t("Floor Number")}
                    </TableCell>
                    <TableCell className="Floor bold-text" style={{ fontWeight: "bold" }}>
                      {t("Configuration")}
                    </TableCell>
                    <TableCell className="Resident bold-text" style={{ fontWeight: "bold" }}>
                      {t("Resident Name")}
                    </TableCell>
                    <TableCell className="Owner bold-text" style={{ fontWeight: "bold" }}>
                      {t("Owner")}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.IBuildingUnitList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7}>{t("No unit found")}</TableCell>
                    </TableRow>
                  )}
                  {this.state.IBuildingUnitList.map((unitKey: any, i: number) => (
                    <TableRow key={unitKey.id}>
                      <TableCell className="#">{unitKey.id}</TableCell>
                      <TableCell className="Unit">{unitKey.attributes.apartment_name}</TableCell>
                      <TableCell className="Floor">{unitKey.attributes.floor_number}</TableCell>
                      <TableCell className="Floor">{unitKey.attributes.configuration}</TableCell>
                      <TableCell className="Resident">{unitKey.attributes.resident?.full_name}</TableCell>
                      <TableCell className="Owner">{unitKey.attributes.owner?.full_name}</TableCell>
                      <TableCell className="complex-unit-menu">
                        <Menu menuButton={<MoreVertIcon />}>
                          <MenuItem data-test-id="view-unit-menu" onClick={() => this.handleViewUnitOpenModal(unitKey)}>
                            {t("View Detail")}
                          </MenuItem>
                          <MenuItem data-test-id="edit-unit-menu" onClick={() => this.handleEditUnitOpenModal(unitKey)}>
                            {t("Edit Detail")}
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
              <Box className="table-bottom">
                <p>
                  {t("Showing")} <span className="current-page">{this.state.IBuildingUnitList.length}</span> {t("of")}{" "}
                  <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span>{" "}
                  {t("results")}
                </p>
                {this.state.pagination && (
                  <Pagination
                    count={this.state.pagination.total_pages}
                    onChange={this.handlePagination}
                    siblingCount={2}
                    variant="outlined"
                    page={this.state.pagination.current_page}
                    shape="rounded"
                  />
                )}
              </Box>
            </TableContainer>
            <Box style={{ borderRadius: "8px", marginTop: "20px", border: "1px solid #2B6FED" }}>
              <Box
                style={webStyle.addUnitBtn}
                className="bold-text add-unit-button"
                data-test-id="add-unit-button"
                onClick={this.handleCloseAddEditFormModal}
              >
                {t("+ ADD NEW UNIT")}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "10px" }}>
          <Grid className="step-three-prev-btn" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("Prev")}</span>
            </Button>
          </Grid>
          <Grid className="step-three-next-btn" style={{ marginTop: "30px" }}>
            <Button style={webStyle.buttonSave} onClick={this.props.handleNext}>
              <span className="bold-text">{t("Next")}</span>
            </Button>
          </Grid>
        </Box>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isIBuildingFindPersonOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title={t("Find Person")} onClose={this.handleFindPersonModal} />
          {!this.state.isUserSelected && (
            <Box style={webStyle.subContent}>
              <Box style={{ display: "flex", alignItems: "flex-end", gap: "10px", justifyContent: "space-between" }}>
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("ID Number")}
                  isLabel
                  value={this.state.person.id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, id: e.target.value } })
                  }
                  data-test-id="id-number-filter"
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Passport Number")}
                  isLabel
                  value={this.state.person.passport}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, passport: e.target.value } })
                  }
                  data-test-id="passport-filter"
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Email ID")}
                  isLabel
                  value={this.state.person.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, email: e.target.value } })
                  }
                  data-test-id="email-filter"
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Phone Number")}
                  isLabel
                  value={this.state.person.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, phone: e.target.value } })
                  }
                  data-test-id="phone-filter"
                />
                <CommonButton
                  onClick={this.getFindUserList}
                  t={t}
                  data-test-id="search-filter-btn"
                  label={t("Search")}
                  startIcon={<SearchIcon />}
                  color="blue"
                />
              </Box>
            </Box>
          )}
          {!this.state.isUserSelected && this.state.ownerResidentList.length > 0 && (
            <>
              <Divider />
              <Box style={webStyle.subContent} className="select-meeting">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Name")}</TableCell>
                        <TableCell>{t("ID Number")}s</TableCell>
                        <TableCell>{t("Phone Number")}</TableCell>
                        <TableCell>{t("Email")}</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ownerResidentList.map((user) => {
                        return (
                          <TableRow key={user.id}>
                            <TableCell>{t(user.attributes.full_name)}</TableCell>
                            <TableCell>{t(user.id + "")}</TableCell>
                            <TableCell>{t(user.attributes.full_phone_number)}</TableCell>
                            <TableCell>{t(user.attributes.email)}</TableCell>
                            <TableCell className="unit-user-menu">
                              <Menu menuButton={<MoreVertIcon />}>
                                <MenuItem data-test-id="view-menu" onClick={() => this.getFindUserDetail(user.id)}>
                                  {t("View Detail")}
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
          {this.state.isUserSelected && (
            <Box style={webStyle.subContent} className="select-meeting">
              <Grid container spacing={2}>
                {this.rowDetailValue(t, "Name", this.state.userDetail.name, 3)}
                {this.rowDetailValue(t, "Complex", this.state.userDetail.complex, 3)}
                {this.rowDetailValue(t, "ID Number", this.state.userDetail.idNumber + "", 3)}
                <Grid item xs={3}>
                  <Typography variant="body2">{t("")}</Typography>
                  <img src={this.state.userDetail.idUrl} />
                </Grid>
                {this.rowDetailValue(t, "Email", this.state.userDetail.email, 3)}
                {this.rowDetailValue(t, "Address", this.state.userDetail.address, 9)}
              </Grid>
            </Box>
          )}
          <DialogActions className="dialog__button_group">
            <Button className="cancel-button" onClick={this.handleFindPersonModal}>
              {t("Cancel")}
            </Button>
            {this.state.isUserSelected && (
              <Button onClick={this.handleSelectUser} className="add-button">
                {t("Select")}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isIBuildingUnitFormOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader
            t={t}
            title={this.state.isEditUnitFormOpen ? "Edit Unit" : "Add New Unit"}
            onClose={this.handleCloseAddEditFormModal}
          />
          <Formik
            data-test-id="building-unit-form"
            enableReinitialize
            initialValues={this.state.addEditFormValue}
            validationSchema={this.validationSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isIBuildingUnitFormOpen: !this.state.isIBuildingUnitFormOpen }, () => {
                this.addEditUnitFormSubmit(values);
                resetForm();
              });
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NormalTextField
                          language={language}
                          label={t("Unit Number")}
                          name="unit"
                          isLabel={true}
                          value={values.unit}
                          t={t}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          language={language}
                          label={t("Floor Number")}
                          name="floor"
                          isLabel={true}
                          value={values.floor}
                          t={t}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {ErrorHandler(t, touched.floor, errors.floor)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="input_icon__form_box">
                          <InputLabel>{t("Owner Name")}</InputLabel>
                          <Box
                            onClick={() => this.handleOpenOwnerResidentModal("ownerName")}
                            data-test-id="add-owner-dialog-btn"
                            style={this.handleStyleBox(this.state.ownerName, webStyle)}
                          >
                            <Typography style={this.handleStyleText(this.state.ownerName, webStyle)}>
                              {this.state.ownerName ? this.state.ownerName : t("+ Add Owner")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="input_icon__form_box">
                          <InputLabel>{t("Resident Name (Optional)")}</InputLabel>
                          <Box
                            style={this.handleStyleBox(this.state.residentName, webStyle)}
                            onClick={() => this.handleOpenOwnerResidentModal("residentName")}
                            data-test-id="add-resident-dialog-btn"
                          >
                            <Typography style={this.handleStyleText(this.state.residentName, webStyle)}>
                              {this.state.residentName ? this.state.residentName : t("+ Add Resident")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          value={values.config}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="config"
                          t={t}
                          language={language}
                          label={t("Configuration")}
                          option={this.state.configList}
                        />
                        {ErrorHandler(t, touched.config, errors.config)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.size}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="size"
                          t={t}
                          language={language}
                          label={t("Size")}
                          isLabel
                          endAdornment={
                            <InputAdornment position="end">
                              <Typography className="bold-text" style={webStyle.unitText}>
                                {t("Sqft")}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.size, errors.size)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.purchasePrice}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="purchasePrice"
                          t={t}
                          language={language}
                          label={t("Purchase Price (optional)")}
                          isLabel
                        />
                        {ErrorHandler(t, touched.purchasePrice, errors.purchasePrice)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalDateField
                          language={language}
                          t={t}
                          label="Purchase Date (optional)"
                          value={values.purchaseDate}
                          name="purchaseDate"
                          onChange={(val: string) => setFieldValue("purchaseDate", val)}
                          onBlur={handleBlur}
                          isLabel
                          data-test-id="unit-form-date"
                        />
                        {ErrorHandler(t, touched.purchaseDate, errors.purchaseDate)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label={t("Current Valuation (optional)")}
                          value={values.valuation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="valuation"
                          isLabel
                        />
                        {ErrorHandler(t, touched.valuation, errors.valuation)}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="buttonCancel bold-text addAnotherBtn"
                          data-test-id="add-another-btn"
                          onClick={() =>
                            this.setState({ isIBuildingUnitFormOpen: !this.state.isIBuildingUnitFormOpen }, () =>
                              handleSubmit()
                            )
                          }
                        >
                          {t("+ Create Another Unit")}
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleCloseAddEditFormModal}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("SAVE")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isViewIBuildingUnitOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title="View Unit" onClose={this.handleViewUnitModal} />
          <Box style={webStyle.subContent} className="select-meeting">
            <Grid container spacing={2}>
              {this.rowDetailValue(t, "Unit Number", this.state.unitDetails.unit, 4)}
              {this.rowDetailValue(t, "Floor Number", this.state.unitDetails.floor, 4)}
              {this.rowDetailValue(t, "Size", this.state.unitDetails.size, 4)}
              {this.rowDetailValue(t, "Configuration", this.state.unitDetails.config, 4)}
              {this.rowDetailValue(t, "Purchase Price", this.state.unitDetails.purchasePrice, 4)}
              {this.rowDetailValue(t, "Purchase Date", this.state.unitDetails.purchaseDate, 4)}
              {this.rowDetailValue(t, "Current Valuation", this.state.unitDetails.valuation, 4)}
            </Grid>
          </Box>
        </Dialog>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle: any = {
  heading: {
    fontWeight: "bold",
    fontSize: "20px",
    paddingBottom: "25px",
    borderBottom: "1px solid #F0F0F0",
  },
  tableContainer: {
    display: "flex",
    width: "100%",
    boxShadow: "none",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  modalField: {
    marginTop: "8px",
    border: "1px solid rgb(252, 132, 52)",
    borderRadius: "8px",
    padding: "12px 15px",
    cursor: "pointer",
  },
  prevButton: {
    color: "#2B6FED",
    display: "flex",
    fontWeight: "bold",
    border: "1px solid #2B6FED",
    padding: "0px 20.5px",
    height: "50px",
    borderRadius: "8px",
    textTransform: "uppercase",
    width: "177px",
    justifyContent: "center",
    textAlign: "center",
  },
  addUnitBtn: {
    color: "#2B6FED",
    padding: "10px 25px",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "bold",
  },
  subContent: { padding: "20px 30px" },
  modalFieldTitle: { color: "rgb(252, 132, 52)", fontSize: "14px !important" },
  modalFieldTitleBlack: { color: "black", fontSize: "14px !important" },
  buttonSave: {
    borderRadius: "8px",
    width: "177px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
    height: "50px",
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: "#2B6FED",
    padding: "0px 20.5px",
  },
  modalFieldBlack: {
    marginTop: "8px",
    border: "1px solid #f0f0f0",
    borderRadius: "8px",
    padding: "12px 15px",
    cursor: "pointer",
    background: "#f9f9f9",
  },
};
// Customizable Area End
