// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { RouteComponentProps } from "react-router-dom";

export interface Props extends RouteComponentProps {
  navigation: {
    getParam: (val: string) => string;
  };
  id: string;
}

interface S {
  complexId: string | number;
}

interface SS {
  id: any;
}

export default class ComplexAddUnitController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      complexId: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const id = this.props.navigation.getParam("id");
    this.setState({ complexId: id });
  }
}
// Customizable Area End
