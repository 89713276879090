export const complexListMock = {
    "complex": [
        {
            "id": 28,
            "name": "The Urban Haven",
            "description": "",
            "created_at": "2023-08-08T04:08:51.639Z",
            "updated_at": "2023-08-08T04:08:51.639Z",
            "complex_area": "70000",
            "maintenance_per_square_feet": 5000,
            "measurement_unit": "Sqft",
            "ticket_days_configuration": 0,
            "contigency": 7,
            "zone_multiplier": 10,
            "late_charge_percentage": "12.0",
            "latitude": null,
            "longitude": null,
            "value_added_tax": "5.0",
            "is_building": true,
            "real_estate_company_id": null,
            "is_building_list": [
                {
                    "id": 39,
                    "name": "The Urban Haven",
                    "society_management_id": 28,
                    "description": null,
                    "created_at": "2023-08-08T04:08:51.655Z",
                    "updated_at": "2023-08-08T04:08:51.655Z",
                    "per_floor_unit": 2,
                    "generation_methods": "A-101, A-102, A-103",
                    "number_of_floor": 2,
                    "building_area": "70000",
                    "account_id": null,
                    "lat": null,
                    "long": null,
                    "city": null
                }
            ]
        },
        {
            "id": 11,
            "name": "Nilamber prime",
            "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
            "created_at": "2022-09-23T10:22:40.716Z",
            "updated_at": "2023-07-11T10:45:07.859Z",
            "complex_area": "255000",
            "maintenance_per_square_feet": 20,
            "measurement_unit": "Sqft",
            "ticket_days_configuration": 1,
            "contigency": 7,
            "zone_multiplier": 8,
            "late_charge_percentage": "15.0",
            "latitude": null,
            "longitude": null,
            "value_added_tax": "5.0",
            "is_building": null,
            "real_estate_company_id": null,
            "is_building_list": null
        }
    ]
}
export const buildingListMock = {
    "data": {
        "buildings": [
            {
                "id": 10,
                "name": "Nilamber Prime-1",
                "society_management_id": 11,
                "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                "created_at": "2022-09-23T10:22:40.723Z",
                "updated_at": "2022-10-07T06:43:36.533Z",
                "per_floor_unit": 4,
                "generation_methods": "1, 2, 3",
                "number_of_floor": 10,
                "building_area": "100000",
                "account_id": null,
                "lat": null,
                "long": null,
                "city": null
            }
        ]
    }
}

export const unitListMock = {
    "data": {
        "apartment_list": [
            {
                "id": 264,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.767Z",
                "updated_at": "2023-06-21T09:56:04.563Z",
                "apartment_name": "3",
                "floor_number": 1,
                "size": "500",
                "purchase_price": "10000",
                "configuration": "",
                "purchase_date": "2023-06-14",
                "unit_type": "Non-Rented",
                "status": "Empty",
                "current_valuation": "20000",
                "monthly_renting_income": "",
                "account_id": null
            },
            {
                "id": 268,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.815Z",
                "updated_at": "2023-02-07T09:54:41.066Z",
                "apartment_name": "7",
                "floor_number": 2,
                "size": "10000",
                "purchase_price": "200000",
                "configuration": "3 BHK",
                "purchase_date": "2023-01-25",
                "unit_type": "Rented",
                "status": "Empty",
                "current_valuation": "200000",
                "monthly_renting_income": "2000",
                "account_id": null
            },
            {
                "id": 265,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.783Z",
                "updated_at": "2023-04-05T08:50:59.569Z",
                "apartment_name": "4",
                "floor_number": 1,
                "size": "1346",
                "purchase_price": "100000",
                "configuration": "1 BHK",
                "purchase_date": "2023-04-03",
                "unit_type": "Rented",
                "status": "Empty",
                "current_valuation": "10000",
                "monthly_renting_income": "1000",
                "account_id": null
            },
            {
                "id": 264,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.767Z",
                "updated_at": "2023-06-21T09:56:04.563Z",
                "apartment_name": "3",
                "floor_number": 1,
                "size": "500",
                "purchase_price": "10000",
                "configuration": "",
                "purchase_date": "2023-06-14",
                "unit_type": "Non-Rented",
                "status": "Empty",
                "current_valuation": "20000",
                "monthly_renting_income": "",
                "account_id": null
            },
            {
                "id": 262,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.742Z",
                "updated_at": "2023-07-17T09:35:48.145Z",
                "apartment_name": "1",
                "floor_number": 1,
                "size": "",
                "purchase_price": "",
                "configuration": "",
                "purchase_date": null,
                "unit_type": "Rented",
                "status": "Empty",
                "current_valuation": "19919",
                "monthly_renting_income": "1234567",
                "account_id": null
            },
            {
                "id": 266,
                "society_management_id": 11,
                "building_management_id": 10,
                "created_at": "2022-09-23T10:22:40.794Z",
                "updated_at": "2023-08-17T06:18:10.642Z",
                "apartment_name": "5",
                "floor_number": 2,
                "size": "500",
                "purchase_price": "10000",
                "configuration": "2 BHK",
                "purchase_date": "2023-08-31",
                "unit_type": "Non-Rented",
                "status": "Empty",
                "current_valuation": "100",
                "monthly_renting_income": "",
                "account_id": null
            }
        ]
    }
}


export const visitorData = {
    visitorName:"Test",
    phone: 534567890,
    date: "2021-09-23",
    time: "10:00",
    withCar: true,
    carPlateNo: "123456",
    ComplexId: 11
}

export const visitorDetailsMock = {
    "code": 200,
    "visitor": {
        "data": {
            "id": "82",
            "type": "visitor",
            "attributes": {
                "name": "Test",
                "comming_with_vehicle": false,
                "schedule_date": "2023-08-18",
                "schedule_time": "02:53",
                "status": "approved",
                "society_management_id": 28,
                "created_at": "2023-08-17T06:21:01.855Z",
                "vehicle_detail": {
                    "car_number": ""
                },
                "account": {
                    "id": 294,
                    "first_name": null,
                    "last_name": null,
                    "full_phone_number": "919898016590",
                    "country_code": null,
                    "phone_number": 7859532614,
                    "email": "ownerkt@yopmail.com",
                    "activated": true,
                    "device_id": "",
                    "unique_auth_id": null,
                    "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                    "created_at": "2022-10-06T05:55:49.581Z",
                    "updated_at": "2023-07-07T10:28:35.060Z",
                    "user_name": null,
                    "platform": null,
                    "user_type": null,
                    "app_language_id": null,
                    "last_visit_at": null,
                    "is_blacklisted": false,
                    "suspend_until": null,
                    "status": "regular",
                    "stripe_id": null,
                    "stripe_subscription_id": null,
                    "stripe_subscription_date": null,
                    "role_id": null,
                    "full_name": "Ownerkt",
                    "gender": "Female",
                    "date_of_birth": "2022-11-26",
                    "age": null,
                    "is_paid": false,
                    "company_name": null,
                    "manager_full_name": null,
                    "owner_full_name": null,
                    "owner_email": null,
                    "owner_phone_number": null,
                    "public_field": null,
                    "disable_chat": false,
                    "building_management_id": null,
                    "language_type": null
                },
                "mobile_number": {
                    "country_code": "966",
                    "mobile_number": "557798634",
                    "full_mobile_number": "+966557798634"
                },
                "resident_name": "Ownerkt",
                "owner_name": "Ownerkt",
                "society_management": {
                    "id": 11,
                    "name": "Nilamber prime",
                    "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                    "created_at": "2022-09-23T10:22:40.716Z",
                    "updated_at": "2023-07-11T10:45:07.859Z",
                    "complex_area": "255000",
                    "maintenance_per_square_feet": 20,
                    "measurement_unit": "Sqft",
                    "ticket_days_configuration": 1,
                    "contigency": 7,
                    "zone_multiplier": 8,
                    "late_charge_percentage": "15.0",
                    "latitude": null,
                    "longitude": null,
                    "value_added_tax": "5.0",
                    "is_building": null,
                    "real_estate_company_id": null
                },
                "building_management": [
                    "Nilamber Prime-1",
                    "The Urban Haven"
                ],
                "unit_number": [
                    "2",
                    "3",
                    "7",
                    "8",
                    "9",
                    "6",
                    "4",
                    "18",
                    "17",
                    "1",
                    "109",
                    "5"
                ],
                "image": null
            }
        }
    }
}

export const visitorDetailsMockData = {
    "code": 200,
    "visitor": {
        "data": {
            "id": "94",
            "type": "visitor",
            "attributes": {
                "name": "Test",
                "comming_with_vehicle": false,
                "schedule_date": "2023-09-08",
                "schedule_time": "18:02",
                "status": "approved",
                "society_management_id": 11,
                "building_management": {
                    "id": 10,
                    "name": "Nilamber Prime-1",
                    "society_management_id": 11,
                    "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                    "created_at": "2022-09-23T10:22:40.723Z",
                    "updated_at": "2022-10-07T06:43:36.533Z",
                    "per_floor_unit": 4,
                    "generation_methods": "1, 2, 3",
                    "number_of_floor": 10,
                    "building_area": "100000",
                    "account_id": null,
                    "lat": null,
                    "long": null,
                    "city": null
                },
                "apartment_management": {
                    "id": 264,
                    "society_management_id": 11,
                    "building_management_id": 10,
                    "created_at": "2022-09-23T10:22:40.767Z",
                    "updated_at": "2023-06-21T09:56:04.563Z",
                    "apartment_name": "3",
                    "floor_number": 1,
                    "size": "500",
                    "purchase_price": "10000",
                    "configuration": "",
                    "purchase_date": "2023-06-14",
                    "unit_type": "Non-Rented",
                    "status": "Empty",
                    "current_valuation": "20000",
                    "monthly_renting_income": "",
                    "account_id": null
                },
                "created_at": "2023-09-08T09:28:33.758Z",
                "vehicle_detail": {
                    "car_number": ""
                },
                "account": {
                    "id": 294,
                    "first_name": null,
                    "last_name": null,
                    "full_phone_number": "919898016590",
                    "country_code": null,
                    "phone_number": 7859532614,
                    "email": "ownerkt@yopmail.com",
                    "activated": true,
                    "device_id": "",
                    "unique_auth_id": null,
                    "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                    "created_at": "2022-10-06T05:55:49.581Z",
                    "updated_at": "2023-07-07T10:28:35.060Z",
                    "user_name": null,
                    "platform": null,
                    "user_type": null,
                    "app_language_id": null,
                    "last_visit_at": null,
                    "is_blacklisted": false,
                    "suspend_until": null,
                    "status": "regular",
                    "stripe_id": null,
                    "stripe_subscription_id": null,
                    "stripe_subscription_date": null,
                    "role_id": null,
                    "full_name": "Ownerkt",
                    "gender": "Female",
                    "date_of_birth": "2022-11-26",
                    "age": null,
                    "is_paid": false,
                    "company_name": null,
                    "manager_full_name": null,
                    "owner_full_name": null,
                    "owner_email": null,
                    "owner_phone_number": null,
                    "public_field": null,
                    "disable_chat": false,
                    "building_management_id": null,
                    "language_type": null
                },
                "mobile_number": {
                    "country_code": "378",
                    "mobile_number": "5460606040",
                    "full_mobile_number": "+3785460606040"
                },
                "resident_name": "Ownerkt",
                "owner_name": "Ownerkt",
                "society_management": {
                    "id": 11,
                    "name": "Nilamber prime",
                    "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                    "created_at": "2022-09-23T10:22:40.716Z",
                    "updated_at": "2023-07-11T10:45:07.859Z",
                    "complex_area": "255000",
                    "maintenance_per_square_feet": 20,
                    "measurement_unit": "Sqft",
                    "ticket_days_configuration": 1,
                    "contigency": 7,
                    "zone_multiplier": 8,
                    "late_charge_percentage": "15.0",
                    "latitude": null,
                    "longitude": null,
                    "value_added_tax": "5.0",
                    "is_building": null,
                    "real_estate_company_id": null
                },
                "image": null
            }
        }
    }
}

export const visitorDetailsMockData1 = {
    "code": 200,
    "visitor": {
        "data": {
            "id": "94",
            "type": "visitor",
            "attributes": {
                "name": "Test",
                "comming_with_vehicle": true,
                "schedule_date": "2023-09-08",
                "schedule_time": "18:02",
                "status": "approved",
                "society_management_id": 11,
                "building_management": {
                    "id": 10,
                    "name": "Nilamber Prime-1",
                    "society_management_id": 11,
                    "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                    "created_at": "2022-09-23T10:22:40.723Z",
                    "updated_at": "2022-10-07T06:43:36.533Z",
                    "per_floor_unit": 4,
                    "generation_methods": "1, 2, 3",
                    "number_of_floor": 10,
                    "building_area": "100000",
                    "account_id": null,
                    "lat": null,
                    "long": null,
                    "city": null
                },
                "apartment_management": {
                    "id": 264,
                    "society_management_id": 11,
                    "building_management_id": 10,
                    "created_at": "2022-09-23T10:22:40.767Z",
                    "updated_at": "2023-06-21T09:56:04.563Z",
                    "apartment_name": "3",
                    "floor_number": 1,
                    "size": "500",
                    "purchase_price": "10000",
                    "configuration": "",
                    "purchase_date": "2023-06-14",
                    "unit_type": "Non-Rented",
                    "status": "Empty",
                    "current_valuation": "20000",
                    "monthly_renting_income": "",
                    "account_id": null
                },
                "created_at": "2023-09-08T09:28:33.758Z",
                "vehicle_detail": {
                    "car_number": "123456"
                },
                "account": {
                    "id": 294,
                    "first_name": null,
                    "last_name": null,
                    "full_phone_number": "919898016590",
                    "country_code": null,
                    "phone_number": 7859532614,
                    "email": "ownerkt@yopmail.com",
                    "activated": true,
                    "device_id": "",
                    "unique_auth_id": null,
                    "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                    "created_at": "2022-10-06T05:55:49.581Z",
                    "updated_at": "2023-07-07T10:28:35.060Z",
                    "user_name": null,
                    "platform": null,
                    "user_type": null,
                    "app_language_id": null,
                    "last_visit_at": null,
                    "is_blacklisted": false,
                    "suspend_until": null,
                    "status": "regular",
                    "stripe_id": null,
                    "stripe_subscription_id": null,
                    "stripe_subscription_date": null,
                    "role_id": null,
                    "full_name": "Ownerkt",
                    "gender": "Female",
                    "date_of_birth": "2022-11-26",
                    "age": null,
                    "is_paid": false,
                    "company_name": null,
                    "manager_full_name": null,
                    "owner_full_name": null,
                    "owner_email": null,
                    "owner_phone_number": null,
                    "public_field": null,
                    "disable_chat": false,
                    "building_management_id": null,
                    "language_type": null
                },
                "mobile_number": {
                    "country_code": "378",
                    "mobile_number": "5460606040",
                    "full_mobile_number": "+3785460606040"
                },
                "resident_name": "Ownerkt",
                "owner_name": "Ownerkt",
                "society_management": {
                    "id": 11,
                    "name": "Nilamber prime",
                    "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                    "created_at": "2022-09-23T10:22:40.716Z",
                    "updated_at": "2023-07-11T10:45:07.859Z",
                    "complex_area": "255000",
                    "maintenance_per_square_feet": 20,
                    "measurement_unit": "Sqft",
                    "ticket_days_configuration": 1,
                    "contigency": 7,
                    "zone_multiplier": 8,
                    "late_charge_percentage": "15.0",
                    "latitude": null,
                    "longitude": null,
                    "value_added_tax": "5.0",
                    "is_building": null,
                    "real_estate_company_id": null
                },
                "image": null
            }
        }
    }
}

export const scheduledVisitors = {
    "visitors": {
        "data": [
            {
                "id": "96",
                "type": "visitor",
                "attributes": {
                    "name": "Test",
                    "comming_with_vehicle": false,
                    "schedule_date": "2023-10-06",
                    "schedule_time": "17:46",
                    "status": "approved",
                    "society_management_id": 10,
                    "building_management": {
                        "id": 9,
                        "name": "Sona B",
                        "society_management_id": 10,
                        "description": null,
                        "created_at": "2022-09-23T10:17:33.386Z",
                        "updated_at": "2022-09-23T10:17:50.466Z",
                        "per_floor_unit": 5,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 6,
                        "building_area": "1000000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null
                    },
                    "apartment_management": {
                        "id": 232,
                        "society_management_id": 10,
                        "building_management_id": 9,
                        "created_at": "2022-09-23T10:17:33.400Z",
                        "updated_at": "2023-09-22T09:34:16.871Z",
                        "apartment_name": "30",
                        "floor_number": 1,
                        "size": "100",
                        "purchase_price": "100",
                        "configuration": "2 BHK",
                        "purchase_date": "2023-09-19",
                        "unit_type": "Non-Rented",
                        "status": "Empty",
                        "current_valuation": "100",
                        "monthly_renting_income": "",
                        "account_id": null
                    },
                    "created_at": "2023-09-28T08:12:02.627Z",
                    "vehicle_detail": {
                        "car_number": ""
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "966",
                        "mobile_number": "546060604",
                        "full_mobile_number": "+966546060604"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 10,
                        "name": "Sona mahel",
                        "description": "",
                        "created_at": "2022-09-23T10:15:39.804Z",
                        "updated_at": "2023-04-05T13:23:38.327Z",
                        "complex_area": "5000000",
                        "maintenance_per_square_feet": 10,
                        "measurement_unit": "Sqm",
                        "ticket_days_configuration": null,
                        "contigency": 8,
                        "zone_multiplier": 7,
                        "late_charge_percentage": null,
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": null,
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": {
                        "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2NJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e08b27e7a52e00aa486834081a89393bc1ca4692/Screenshot%20from%202023-09-25%2016-51-43.png"
                    }
                }
            }
        ]
    }
}

export const PastVisitorDetailsMock = {
    "visitors": {
        "data": [
            {
                "id": "59",
                "type": "visitor",
                "attributes": {
                    "name": "Test",
                    "comming_with_vehicle": false,
                    "schedule_date": "2023-06-06",
                    "schedule_time": "15:39",
                    "status": "approved",
                    "society_management_id": 11,
                    "building_management": null,
                    "apartment_management": null,
                    "created_at": "2023-06-05T09:08:35.433Z",
                    "vehicle_detail": {
                        "car_number": ""
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "966",
                        "mobile_number": "560606040",
                        "full_mobile_number": "+966560606040"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 11,
                        "name": "Nilamber prime",
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.716Z",
                        "updated_at": "2023-07-11T10:45:07.859Z",
                        "complex_area": "255000",
                        "maintenance_per_square_feet": 20,
                        "measurement_unit": "Sqft",
                        "ticket_days_configuration": 1,
                        "contigency": 7,
                        "zone_multiplier": 8,
                        "late_charge_percentage": "15.0",
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": "5.0",
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": {
                        "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2tHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b73d44cfc9e9c231080ad7ae035579c96c294b4a/2.Building%20Announcements-owner.jpg"
                    }
                }
            },
            {
                "id": "76",
                "type": "visitor",
                "attributes": {
                    "name": "Test",
                    "comming_with_vehicle": false,
                    "schedule_date": "2023-08-07",
                    "schedule_time": "14:54",
                    "status": "approved",
                    "society_management_id": 11,
                    "building_management": null,
                    "apartment_management": null,
                    "created_at": "2023-08-06T06:21:34.146Z",
                    "vehicle_detail": {
                        "car_number": ""
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "966",
                        "mobile_number": "546060604",
                        "full_mobile_number": "+966546060604"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 11,
                        "name": "Nilamber prime",
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.716Z",
                        "updated_at": "2023-07-11T10:45:07.859Z",
                        "complex_area": "255000",
                        "maintenance_per_square_feet": 20,
                        "measurement_unit": "Sqft",
                        "ticket_days_configuration": 1,
                        "contigency": 7,
                        "zone_multiplier": 8,
                        "late_charge_percentage": "15.0",
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": "5.0",
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": null
                }
            },
            {
                "id": "83",
                "type": "visitor",
                "attributes": {
                    "name": "rahul",
                    "comming_with_vehicle": true,
                    "schedule_date": "2022-03-20",
                    "schedule_time": "2022-07-20T13:34:00.000",
                    "status": "approved",
                    "society_management_id": 11,
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null
                    },
                    "apartment_management": {
                        "id": 262,
                        "society_management_id": 11,
                        "building_management_id": 10,
                        "created_at": "2022-09-23T10:22:40.742Z",
                        "updated_at": "2023-07-17T09:35:48.145Z",
                        "apartment_name": "1",
                        "floor_number": 1,
                        "size": "",
                        "purchase_price": "",
                        "configuration": "",
                        "purchase_date": null,
                        "unit_type": "Rented",
                        "status": "Empty",
                        "current_valuation": "19919",
                        "monthly_renting_income": "1234567",
                        "account_id": null
                    },
                    "created_at": "2023-08-18T05:41:54.555Z",
                    "vehicle_detail": {
                        "car_number": "Mp-33-33333"
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "91",
                        "mobile_number": "4384383932",
                        "full_mobile_number": "914384383932"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 11,
                        "name": "Nilamber prime",
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.716Z",
                        "updated_at": "2023-07-11T10:45:07.859Z",
                        "complex_area": "255000",
                        "maintenance_per_square_feet": 20,
                        "measurement_unit": "Sqft",
                        "ticket_days_configuration": 1,
                        "contigency": 7,
                        "zone_multiplier": 8,
                        "late_charge_percentage": "15.0",
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": "5.0",
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": null
                }
            },
            {
                "id": "84",
                "type": "visitor",
                "attributes": {
                    "name": "Test",
                    "comming_with_vehicle": false,
                    "schedule_date": "2023-08-25",
                    "schedule_time": "03:19",
                    "status": "approved",
                    "society_management_id": 11,
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null
                    },
                    "apartment_management": {
                        "id": 268,
                        "society_management_id": 11,
                        "building_management_id": 10,
                        "created_at": "2022-09-23T10:22:40.815Z",
                        "updated_at": "2023-09-07T05:32:38.202Z",
                        "apartment_name": "7",
                        "floor_number": 2,
                        "size": "10000",
                        "purchase_price": "200000",
                        "configuration": "3 BHK",
                        "purchase_date": "2023-01-25",
                        "unit_type": "Rented",
                        "status": "rented",
                        "current_valuation": "200000",
                        "monthly_renting_income": "2000",
                        "account_id": null
                    },
                    "created_at": "2023-08-18T05:46:12.220Z",
                    "vehicle_detail": {
                        "car_number": ""
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "966",
                        "mobile_number": "546060604",
                        "full_mobile_number": "+966546060604"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 11,
                        "name": "Nilamber prime",
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.716Z",
                        "updated_at": "2023-07-11T10:45:07.859Z",
                        "complex_area": "255000",
                        "maintenance_per_square_feet": 20,
                        "measurement_unit": "Sqft",
                        "ticket_days_configuration": 1,
                        "contigency": 7,
                        "zone_multiplier": 8,
                        "late_charge_percentage": "15.0",
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": "5.0",
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": null
                }
            },
            {
                "id": "94",
                "type": "visitor",
                "attributes": {
                    "name": "Test",
                    "comming_with_vehicle": false,
                    "schedule_date": "2023-09-08",
                    "schedule_time": "18:02",
                    "status": "approved",
                    "society_management_id": 11,
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null
                    },
                    "apartment_management": {
                        "id": 264,
                        "society_management_id": 11,
                        "building_management_id": 10,
                        "created_at": "2022-09-23T10:22:40.767Z",
                        "updated_at": "2023-06-21T09:56:04.563Z",
                        "apartment_name": "3",
                        "floor_number": 1,
                        "size": "500",
                        "purchase_price": "10000",
                        "configuration": "",
                        "purchase_date": "2023-06-14",
                        "unit_type": "Non-Rented",
                        "status": "Empty",
                        "current_valuation": "20000",
                        "monthly_renting_income": "",
                        "account_id": null
                    },
                    "created_at": "2023-09-08T09:28:33.758Z",
                    "vehicle_detail": {
                        "car_number": ""
                    },
                    "account": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-09-11T08:37:41.630Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "mobile_number": {
                        "country_code": "378",
                        "mobile_number": "5460606040",
                        "full_mobile_number": "+3785460606040"
                    },
                    "resident_name": "Ownerkt",
                    "owner_name": "Ownerkt",
                    "society_management": {
                        "id": 11,
                        "name": "Nilamber prime",
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.716Z",
                        "updated_at": "2023-07-11T10:45:07.859Z",
                        "complex_area": "255000",
                        "maintenance_per_square_feet": 20,
                        "measurement_unit": "Sqft",
                        "ticket_days_configuration": 1,
                        "contigency": 7,
                        "zone_multiplier": 8,
                        "late_charge_percentage": "15.0",
                        "latitude": null,
                        "longitude": null,
                        "value_added_tax": "5.0",
                        "is_building": null,
                        "real_estate_company_id": null
                    },
                    "image": null
                }
            }
        ]
    }
}

export const visitorDetailMockData = {
    "code": 200,
    "visitor": {
        "data": {
            "id": "96",
            "type": "visitor",
            "attributes": {
                "name": "Test",
                "comming_with_vehicle": false,
                "schedule_date": "2023-10-06",
                "schedule_time": "17:46",
                "status": "approved",
                "society_management_id": 10,
                "building_management": {
                    "id": 9,
                    "name": "Sona B",
                    "society_management_id": 10,
                    "description": null,
                    "created_at": "2022-09-23T10:17:33.386Z",
                    "updated_at": "2022-09-23T10:17:50.466Z",
                    "per_floor_unit": 5,
                    "generation_methods": "1, 2, 3",
                    "number_of_floor": 6,
                    "building_area": "1000000",
                    "account_id": null,
                    "lat": null,
                    "long": null,
                    "city": null
                },
                "apartment_management": {
                    "id": 232,
                    "society_management_id": 10,
                    "building_management_id": 9,
                    "created_at": "2022-09-23T10:17:33.400Z",
                    "updated_at": "2023-09-22T09:34:16.871Z",
                    "apartment_name": "30",
                    "floor_number": 1,
                    "size": "100",
                    "purchase_price": "100",
                    "configuration": "2 BHK",
                    "purchase_date": "2023-09-19",
                    "unit_type": "Non-Rented",
                    "status": "Empty",
                    "current_valuation": "100",
                    "monthly_renting_income": "",
                    "account_id": null
                },
                "created_at": "2023-09-28T08:12:02.627Z",
                "vehicle_detail": {
                    "car_number": ""
                },
                "account": {
                    "id": 294,
                    "first_name": null,
                    "last_name": null,
                    "full_phone_number": "919898016590",
                    "country_code": null,
                    "phone_number": 7859532614,
                    "email": "ownerkt@yopmail.com",
                    "activated": true,
                    "device_id": "",
                    "unique_auth_id": null,
                    "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                    "created_at": "2022-10-06T05:55:49.581Z",
                    "updated_at": "2023-09-11T08:37:41.630Z",
                    "user_name": null,
                    "platform": null,
                    "user_type": null,
                    "app_language_id": null,
                    "last_visit_at": null,
                    "is_blacklisted": false,
                    "suspend_until": null,
                    "status": "regular",
                    "stripe_id": null,
                    "stripe_subscription_id": null,
                    "stripe_subscription_date": null,
                    "role_id": null,
                    "full_name": "Ownerkt",
                    "gender": "Female",
                    "date_of_birth": "2022-11-26",
                    "age": null,
                    "is_paid": false,
                    "company_name": null,
                    "manager_full_name": null,
                    "owner_full_name": null,
                    "owner_email": null,
                    "owner_phone_number": null,
                    "public_field": null,
                    "disable_chat": false,
                    "building_management_id": null,
                    "language_type": null,
                    "ticket_days_configuration": 7
                },
                "mobile_number": {
                    "country_code": "966",
                    "mobile_number": "546060604",
                    "full_mobile_number": "+966546060604"
                },
                "resident_name": "Ownerkt",
                "owner_name": "Ownerkt",
                "society_management": {
                    "id": 10,
                    "name": "Sona mahel",
                    "description": "",
                    "created_at": "2022-09-23T10:15:39.804Z",
                    "updated_at": "2023-04-05T13:23:38.327Z",
                    "complex_area": "5000000",
                    "maintenance_per_square_feet": 10,
                    "measurement_unit": "Sqm",
                    "ticket_days_configuration": null,
                    "contigency": 8,
                    "zone_multiplier": 7,
                    "late_charge_percentage": null,
                    "latitude": null,
                    "longitude": null,
                    "value_added_tax": null,
                    "is_building": null,
                    "real_estate_company_id": null
                },
                "image": {
                    "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2NJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e08b27e7a52e00aa486834081a89393bc1ca4692/Screenshot%20from%202023-09-25%2016-51-43.png"
                }
            }
        }
    }
}

export const unitDataMock = {
    "code": 200,
    "apartment_managements": {
        "data": [
            {
                "id": "275",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 275,
                    "apartment_name": "14",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "resident": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "278",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 278,
                    "apartment_name":null,
                    "building_management": {
                        "id": 10,
                        "name": null,
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-10-13T08:34:47.724Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": null,
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "resident": {
                        "id": 982,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": null,
                        "country_code": null,
                        "phone_number": null,
                        "email": "mohsinTan1@yopmail.com",
                        "activated": true,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$UcatoEFkhG5nW/nGtdfUG.0ZhKdSqQ5rqkiiP9z.Ya5wqg5Jmgw8e",
                        "created_at": "2023-07-17T09:40:32.047Z",
                        "updated_at": "2023-07-17T09:40:32.047Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": null,
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "279",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 279,
                    "apartment_name": "18",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 294,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+919898016590",
                        "country_code": null,
                        "phone_number": 7859532614,
                        "email": "ownerkt@yopmail.com",
                        "activated": true,
                        "device_id": "",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$gBNID.u04gPTqr.7Lc1/7.Vum/YZnthArsBB95.k1HntUZCWVWnVu",
                        "created_at": "2022-10-06T05:55:49.581Z",
                        "updated_at": "2023-10-13T08:34:47.724Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Ownerkt",
                        "gender": "Female",
                        "date_of_birth": "2022-11-26",
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 7
                    },
                    "resident": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "280",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 280,
                    "apartment_name": "19",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 848,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+1 7779766333",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari29@yopmail.com",
                        "activated": true,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$SJ1FojwtJsDs9y0xNhYn0.3.fJGcemLrUk3HrbUBpalaKmzj2Nx1e",
                        "created_at": "2023-05-22T05:17:35.228Z",
                        "updated_at": "2023-05-24T09:35:12.945Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 1,
                        "full_name": null,
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "resident": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "281",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 281,
                    "apartment_name": "20",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 499,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+966987897876",
                        "country_code": null,
                        "phone_number": 987897876,
                        "email": "poha@yopmail.com",
                        "activated": true,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$3AlrCz7kJtaEe.2B.byr/ePtSV0ck4J197jMQhjT68ZZfCF1mVcs.",
                        "created_at": "2023-02-18T11:24:53.885Z",
                        "updated_at": "2023-02-18T11:24:56.382Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "poha shah",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "resident": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "289",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 289,
                    "apartment_name": "28",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 460,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+966908890789",
                        "country_code": null,
                        "phone_number": 908890789,
                        "email": "kom@yopmail.com",
                        "activated": true,
                        "device_id": "eIXBOMbmAf5aPo_pxdYPv4:APA91bEw6i1rQmLOto5G02TJ9x3l253ddoRxT_UlGgWq4tYzr4jl3ta_t2_-_UunPAL-COcXJ2UiV82ZX5dNJwKI-6n2NuKt6ZNp6RUJONUONOPEuEArjESyDKeur-PuKAnzsvBvUqPD",
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$QCvBi0yGvmTJayJWW/JNIe2QL6NaWb.dmGvfKal/C28wVcFIVKywq",
                        "created_at": "2023-02-08T10:20:44.670Z",
                        "updated_at": "2023-02-13T09:52:45.510Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "kom",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "resident": {
                        "id": 854,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+91 7779766112",
                        "country_code": null,
                        "phone_number": null,
                        "email": "hari31@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$PRzZ75bBdGb1./dLKryjO.0XmHAexWLiTdrH0uQdwBGr5vntBA1l.",
                        "created_at": "2023-05-23T04:25:41.638Z",
                        "updated_at": "2023-07-19T04:15:44.370Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner resident",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 2,
                        "full_name": "Ram singh",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            },
            {
                "id": "301",
                "type": "security_apartment_managements",
                "attributes": {
                    "id": 301,
                    "apartment_name": "40",
                    "building_management": {
                        "id": 10,
                        "name": "Nilamber Prime-1",
                        "society_management_id": 11,
                        "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                        "created_at": "2022-09-23T10:22:40.723Z",
                        "updated_at": "2022-10-07T06:43:36.533Z",
                        "per_floor_unit": 4,
                        "generation_methods": "1, 2, 3",
                        "number_of_floor": 10,
                        "building_area": "100000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null,
                        "country": null,
                        "googlemaplocation": null
                    },
                    "owner": {
                        "id": 900,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+966 589805253",
                        "country_code": null,
                        "phone_number": null,
                        "email": "owner1345@yopmail.com",
                        "activated": true,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$0zLqXjA25L/hEvBydm8szuPrwMiOZ2OS/jXPE.cp2K4OhrXtisOyO",
                        "created_at": "2023-06-05T12:39:35.629Z",
                        "updated_at": "2023-06-05T12:39:35.629Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": "owner",
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": 1,
                        "full_name": "Mohsin Munshi",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "resident": {
                        "id": 901,
                        "first_name": null,
                        "last_name": null,
                        "full_phone_number": "+966-598989877",
                        "country_code": null,
                        "phone_number": null,
                        "email": "romit456@yopmail.com",
                        "activated": false,
                        "device_id": null,
                        "unique_auth_id": null,
                        "password_digest": "$2a$12$QPKJHTCnEipZMN.L3cgEwO.MruvJpN4u1WysIOpwo918eToQcp0XW",
                        "created_at": "2023-06-05T12:43:09.846Z",
                        "updated_at": "2023-07-18T06:39:11.705Z",
                        "user_name": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "suspend_until": null,
                        "status": "regular",
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "role_id": null,
                        "full_name": "Romitbhai ",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "is_paid": false,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "owner_phone_number": null,
                        "public_field": null,
                        "disable_chat": false,
                        "building_management_id": null,
                        "language_type": null,
                        "ticket_days_configuration": 0
                    },
                    "phone_number": "+91 7779766112",
                    "family_members": 0
                }
            }
        ]
    },
    "meta": {
        "pagination": {
            "current_page": 2,
            "next_page": null,
            "prev_page": 1,
            "total_pages": 2,
            "total_count": 17
        }
    }
}

export const buildingListMockData = {
    "data": {
        "buildings": [
            {
                "id": 10,
                "name": "Nilamber Prime-1",
                "society_management_id": 11,
                "description": "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
                "created_at": "2022-09-23T10:22:40.723Z",
                "updated_at": "2022-10-07T06:43:36.533Z",
                "per_floor_unit": 4,
                "generation_methods": "1, 2, 3",
                "number_of_floor": 10,
                "building_area": "100000",
                "account_id": null,
                "lat": null,
                "long": null,
                "city": null,
                "country": null,
                "googlemaplocation": null
            }
        ]
    }
}

export const UnitDataMock = {
    "resident": {
        "data": {
            "id": "464",
            "type": "security_apartment_managements_detail",
            "attributes": {
                "id": 464,
                "full_name": {
                    "name": "alex",
                    "publilc_access": true
                },
                "bio": {
                    "bio": "Bio for test",
                    "publilc_access": true
                },
                "apartment_number": {
                    "apartment_number": "1",
                    "publilc_access": true
                },
                "full_phone_number": {
                    "country_code": "971",
                    "phone_number": "890987879",
                    "full_phone_number": "+971890987879",
                    "publilc_access": true
                },
                "email": {
                    "email": "alex@yopmail.com",
                    "publilc_access": true
                },
                "gender": {
                    "gender": "Female",
                    "publilc_access": true
                },
                "date_of_birth": {
                    "date_of_birth": "Mar-31-2023",
                    "publilc_access": true
                },
                "hobbies": {
                    "hobbies": [
                        "test",
                        "test2",
                        "test3"
                    ],
                    "publilc_access": true
                },
                "website": [
                    {
                        "twitter_link": "https://twitter.com/discord",
                        "publilc_access": true
                    },
                    {
                        "instagram_link": "https://www.instagram.com/mohsin.r.memon/",
                        "publilc_access": true
                    },
                    {
                        "fb_link": "https://www.facebook.com/mohsin.r.memon/",
                        "publilc_access": true
                    },
                    {
                        "snapchat_link": "https://www.snapchat.com/mohsin.r.memon/",
                        "publilc_access": true
                    }
                ],
                "families": {
                    "families": [
                        {
                            "id": "97",
                            "type": "family",
                            "attributes": {
                                "id": 97,
                                "name": "Alex Junior",
                                "account_id": 464,
                                "relation": {
                                    "id": 3,
                                    "name": "Son",
                                    "name_ar": "ابن"
                                },
                                "id_proof": {
                                    "id": 1,
                                    "name": "Aadhar",
                                    "name_ar": "اضر"
                                },
                                "id_number": "123",
                                "member_pic": {
                                    "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcXNJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--72076d4ac957a719e17c4145a950647d9acea9c7/blob"
                                }
                            }
                        }
                    ],
                    "publilc_access": true
                },
                "profile_pic": {
                    "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaW9GIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c75cc8a531fd3298d64d06b303708230ed0fa186/blob",
                    "content_type": "image/jpeg",
                    "file_name": "blob"
                },
                "disable_chat": false
            }
        }
    },
    "owner": "Ownerkt"
}