// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {}

interface SS {
  id: any;
}

export default class RealEstateCreateCompanyController extends CommonApiCallForBlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {};
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
}
// Customizable Area End
