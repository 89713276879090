import React, { ReactNode } from "react";
import { Box, styled } from "@material-ui/core";

const StyledTooltipBox = styled(Box)({
  position: "relative",
  display: "inline-block",

  "&:hover .tooltip-text": {
    visibility: "visible",
  },
});

const StyledTooltipText = styled(Box)({
  visibility: "hidden",
  backgroundColor: "black",
  color: "#fff",
  textAlign: "center",
  borderRadius: "26px",
  padding: "6px 12px",
  position: "absolute",
  zIndex: 1,
  bottom: "105%",
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: "12px",
  lineHeight: "16px",

  "&::after": {
    content: "''",
    position: "absolute",
    top: "100%",
    left: "50%",
    marginLeft: "-5px",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "black transparent transparent transparent",
  },
});

interface TipProps {
  children: ReactNode;
  text: string;
}

const Tip: React.FC<TipProps> = ({ children, text }) => {
  return (
    <StyledTooltipBox>
      {children}
      <StyledTooltipText className="tooltip-text">{text}</StyledTooltipText>
    </StyledTooltipBox>
  );
};

export default Tip;
