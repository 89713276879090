import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { emptyFilterValue } from "../../../components/src/UtilFunction.web";
import * as Yup from "yup";

export interface Props {
  id: string;
  t: (value: string) => string;
  history: any;
  match: any;
  location: any;
  i18n: {
    language: string;
  };
}

interface SelectOption {
  label: string;
  value: string;
}

interface IApprovalList {
  id: string;
  complex: string;
  name: string;
  country: string;
  city: string;
  approvalType: string;
  type: string;
  requestedBy: string;
  item: any;
}

// Customizable Area End

export interface S {
    // Customizable Area Start
  loading: boolean;

  countryList: SelectOption[];
  cityList: SelectOption[];
  userTypeList: SelectOption[];
  typeList: SelectOption[];
  requestorList: SelectOption[];
  approvalTypeList: SelectOption[];
  nameList: SelectOption[];

  approvalList: IApprovalList[];
  pagination: any;
  page: number;
  dialogSucessstate:boolean

  isRejectModalOpen: boolean;
  isRejectReasonModalOpen: boolean;
  isAcceptModalOpen: boolean;
  openBuildingMap:boolean;
  latitude:number;
  longitude:number;

  isSubscriptionViewOpen: boolean;
  isCompanyViewOpen: boolean;
  selectNamelist:any;

  activeTab: {
    id: number;
    tab: string;
    table: string;
    title: string;
  };

  filter: {
    country: string;
    city: string;
    userType: string;
    type: string;
    name: string;
    requestor: string;
    approvalType: string;
  };

  subscriptionView: any | {

  };

  companyView: any | {

  };

  actionInfo: {
    id: string;
    title: string;
    name: string;
  };
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
  id: string;
    // Customizable Area End
}

export default class DashboardApprovalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  GetApproveSubscriptionApiCallId: string = "";
  GetCompComplexBuildingApiCallId: string = "";
  GetOwnerManagerApiCallId: string = "";
  GetMiscApiCallId: string = "";
  GetEmployeeApiCallId: string = "";

  AcceptRejectApiCallId: string = "";

  GetCountryApiCallId: string = "";
  GetNameApiCallId:string="";
  GetApprovalTypeApiCallId:string="";
  GetCityApiCallId: string = "";
  GetSubscriptionRequestorApiCallId: string = "";
  GetOwnerManagerRequestorApiCallId: string = "";
  GetNameListApiCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      loading: false,

      countryList: [],
      cityList: [],
      userTypeList: [],
      typeList: [],
      requestorList: [],
      approvalTypeList: [],
      nameList: [],

      approvalList: [],
      pagination: null,
      page: 1,
      openBuildingMap:false,
      latitude:0,
      longitude:0,

      isRejectModalOpen: false,
      isRejectReasonModalOpen: false,
      isAcceptModalOpen: false,
      dialogSucessstate:false,

      isSubscriptionViewOpen: false,
      isCompanyViewOpen: false,

      activeTab: {
        id: 1,
        tab: "Subscription",
        table: "Subscription Approval",
        title: "Subscription"
      },

      filter: {
        country: "",
        type: "",
        city: "",
        userType: "",
        name: "",
        approvalType: "",
        requestor: "",
      },

      subscriptionView: {
        title: "",
        userType: "",
        country: "",
        city: "",
        name: "",
        plan: "",
        upgradeTo: "",
        duration: "",
        complex: "",
        complexMap: {
          lat: "",
          long: "",
        },
        building: "",
        buildingMap: {
          lat: "",
          long: "",
        },
        noOfBuilding: "",
        totalUnit: "",
        refNumber: "",
        requestedBy: "",
        requestedOn: "",

        selectedItem: null,
      },

      companyView: {
        title: "",
        approvalType: "",
        country: "",
        city: "",
        type: "",
        name: "",
        requestedBy: "",
        requestedOn: "",

        selectedItem: null,
      },

      actionInfo: {
        id: "",
        title: "",
        name: "",
      },
      selectNamelist:[]
    };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetApproveSubscriptionApiCallId:
        return this.handleSubscriptionSuccess(responseJson);
      case this.GetCompComplexBuildingApiCallId:
      case this.GetOwnerManagerApiCallId:
      case this.GetMiscApiCallId:
      case this.GetEmployeeApiCallId:
        return this.handleCompComplexBuildingPropOwnerSuccess(responseJson);
      case this.GetCountryApiCallId:
        return this.handleCountrySuccess(responseJson);
      case this.GetCityApiCallId:
        return this.handleCitySuccess(responseJson);
      case this.GetSubscriptionRequestorApiCallId:
      case this.GetOwnerManagerRequestorApiCallId:
        return this.handleRequestorSuccess(responseJson);
        case this.GetNameListApiCallId:
          return this.handleNamelistSuccess(responseJson);
      case this.AcceptRejectApiCallId:
        return this.handleRecordActionSuccess();
      default:
        break;
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJson);
    }
    ApiCatchErrorResponse(errorResponse);
        // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount() {
    this.getApprovalCountryList();
    this.handleMainAPICall();
  }

  handleMainAPICall = () => {
    if (this.state.activeTab.id === 1) {
      this.getSubscriptionList();
      this.handleSubscriptionFilter();
    } else if (this.state.activeTab.id === 2) {
      this.getCompComplexBuildingList();
      this.handleComplexManagerFilter();
    } else if (this.state.activeTab.id === 3) {
      this.getOwnerManagerList();
      this.handleOwnerManagerFilter();
    } else if (this.state.activeTab.id === 4) {
      this.getMiscList();
      this.handleMiscFilter();
    } else {
      this.getEmployeeList();
      this.handleEmployeeFilter();

    }
  };

  handleOwnerManagerFilter = () => {
    this.setState({
      userTypeList: [{ label: "Owner", value: "Owner" }, { label: "Property Manager", value: "Property Manager" }, { label: "Owner Resident", value: "Owner Resident" }],
      approvalTypeList: [{ label: "New Profile", value: "New Profile" }],
    }, () => {
      this.getpropertyManagerRequestor()
      this.getNameList("owner")
    });
  };
  handleComplexManagerFilter = () => {
    this.setState({
      userTypeList: [{ label: "Complex", value: "complex" },
      { label: "Individual Building", value: "building" },
      { label: "Real Estate Company", value: "company" }],
      approvalTypeList: [{ label: "New Complex", value: "New Complex" },
      { label: "Delete Complex", value: "Delete Complex" }, { label: "New Company", value: "New Company" },
      { label: "Delete Company", value: "Delete Company" },
      { label: "New Building", value: "New Building" },
      { label: "Delete Building", value: "Delete Building" }
      ],
    }, () => {
      this.getOwnerManagerRequestor()
      this.getNameList("complex")
    });
  }

  handleMiscFilter=()=>{
    this.setState({
      userTypeList: [{ label: "Owner Resident", value: "Owner Resident" },
         { label: "Tenant", value: "Tenant" },
          { label: "Owner", value: "Owner" },{ label: "Property Manager", value: "Property Manager" }],
      approvalTypeList: [{ label: "New Facility", value: "New Facility" },{ label: "New Classified", value: "New Classified" }],
    }, () => {
      this.getpropertyManagerRequestor()
      this.getNameList("misc")
    }); 
  }

  handleEmployeeFilter=()=>{
    this.setState({
      userTypeList: [{ label: "coo", value: "coo" },
         { label: "account_manager", value: "account_manager" },
          { label: "sales_manager", value: "sales_manager" }],
      approvalTypeList: [{ label: "Start Nomination", value: "Start Nomination" }],
    }, () => {
      this.getOwnerManagerRequestor()
      this.getNameList("employee")
    }); 
  }

  handleSubscriptionFilter = () => {
    this.setState({
      userTypeList: [{ label: "Property Developer", value: "Property Developer" }, { label: "General Assembly", value: "General Assembly" }, { label: "Owner", value: "Owner" }, { label: "Property Manager", value: "Property Manager" }],
      approvalTypeList: [{ label: "Pending", value: "pending" }, { label: "Deactive Request", value: "deactive_request" }, { label: "Extend Request", value: "extend_request" }, { label: "Upgrade Request", value: "upgrade_request" }, { label: "Freeze Request", value: "freeze_request" }, { label: "Unfreeze Request", value: "unfreeze_request" }],
    }, () => {
      this.getSubscriptionRequestor()
    });
  };

  getOwnerManagerRequestor = async () => {
    this.GetOwnerManagerRequestorApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/requestore_list`,
    });
  }

  getNameList= async (typeName:string) => {
    this.GetNameListApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/approval_name_listing?type=${typeName}`,
    });
  }

  getpropertyManagerRequestor = async () => {
    this.GetOwnerManagerRequestorApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/account_requestore_list`,
    });
  }

  getSubscriptionRequestor = async () => {
    this.GetSubscriptionRequestorApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_custom_user_subs/requestore_list`,
    });
  }

  handleRequestorSuccess = (response: any) => {
    if (response && response.requester) {
      this.setState({ requestorList: response.requester.map((item: any) => ({ label: item.full_name, value: item.id })) })
    }
  }

  handleNamelistSuccess=(response:any)=>{
 if(response && response.name_listing){
  this.setState({ selectNamelist: response.name_listing.map((item: any) => ({ label: item, value: item })) })
 }
  }

  handleTag = (tab: { id: number; tab: string; table: string; title: string }) => {
    this.setState({
      activeTab: tab, page: 1, filter: {
        country: "",
        type: "",
        city: "",
        name: "",
        userType: "",
        approvalType: "",
        requestor: "",
      }
    }, () => this.handleMainAPICall());
  };

  handleViewDetails = (item: any) => {
    
    if (this.state.activeTab.id === 1) {
      const dataType=item?.item?.attributes
      this.setState({
         subscriptionView: {
          title: item.approvalType,
          userType:dataType.user_type,
          country:dataType.country,
          city:dataType.city,
          name:dataType.name,
          plan:dataType.subscription_plan&&dataType.subscription_plan.title,
          upgradeTo:dataType.upgratde_to,
          duration: dataType.complex_name?.data?.attributes?.subscription_end_in,
          complex: dataType.complex_name?.data?.attributes?.name,
          complexMap: {
            lat: dataType.complex_name?.data?.attributes?.lat,
            long: dataType.complex_name?.data?.attributes?.long,
          },
          building: dataType.building_name,
          buildingMap: {
            lat: "",
            long: "",
          },
          noOfBuilding: dataType.total_buildings,
          totalUnit: dataType.total_units,
          refNumber:dataType.payment_reference_number,
          requestedBy: dataType.requested_by,
          requestedOn: dataType.requested_on,

          selectedItem: item,
        }
      },()=>{this.setState({isSubscriptionViewOpen: true,})});
    }  if (this.state.activeTab.id === 2 || this.state.activeTab.id === 3 || this.state.activeTab.id === 4 || this.state.activeTab.id === 5) {
      this.setState({
        isCompanyViewOpen: true, companyView: {
          title: item.approvalType,
          approvalType: item.approvalType,
          country: item.country,
          city: item.city,
          type: item.type,
          name: item.name,
          requestedBy: item.requestedBy,
          requestedOn: item.item.attributes.requested_on,

          selectedItem: item,
        }
      });
    }
  }

  handleAcceptRequest = (item: any) => {
    this.setState({
      isAcceptModalOpen: true, isSubscriptionViewOpen: false, isCompanyViewOpen: false, actionInfo: {
        id: item.id,
        title: item.approvalType,
        name: item.name || "",
      }
    });
  }

  handleRejectRequest = (item: any) => {
    this.setState({
      isRejectModalOpen: true, isSubscriptionViewOpen: false, isCompanyViewOpen: false, actionInfo: {
        id: item.id,
        title: item.approvalType,
        name: item.name || "",
      }
    });
  }

  handleDialogClose = () => {
    if (this.state.activeTab.id === 1) {
      this.setState({ isSubscriptionViewOpen: false });
    } else {
      this.setState({ isCompanyViewOpen: false });
    }
  }

  handleAcceptModal = () => {
    this.setState({ isAcceptModalOpen: !this.state.isAcceptModalOpen });
  };

  handleRejectModal = () => {
    this.setState({ isRejectModalOpen: !this.state.isRejectModalOpen });
  };

  handleRejectReasonModal = () => {
    this.setState({ isRejectReasonModalOpen: !this.state.isRejectReasonModalOpen });
  };

  handlePagination = (event: any, value: any) => {
    this.setState({ page: value }, () => {
      this.handleMainAPICall();
    });
  };

  getSubscriptionList = async () => {
    const { page, filter } = this.state;
    this.GetApproveSubscriptionApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/subscription_request_list?page=${page}&country=${emptyFilterValue(filter.country)}&city=${emptyFilterValue(filter.city)}&requestor=${emptyFilterValue(filter.requestor)}&name=${emptyFilterValue(filter.name)
        }&approval_type=${emptyFilterValue(filter.approvalType)}&user_type=${emptyFilterValue(filter.userType)}`,
    });
  }

  handleSubscriptionSuccess = (responseJson: { subscriptions: { data: any[] }, meta: { pagination: any } }) => {
    if (responseJson && responseJson.subscriptions) {
      this.setState({
        approvalList: responseJson.subscriptions.data.map((item: any) => ({
          country: item.attributes.country||"-",
          id: item.id,
          complex: "",
          city: item.attributes.city||"-",
          approvalType: this.handleSubscriptionType(item.attributes.approval_type)||"-",
          name: item.attributes.name||"-",
          requestedBy: item.attributes?.requested_by||"-",
          type: item.attributes.user_type||"-",
          item: item
        })),
        pagination: responseJson.meta.pagination
      });
    }
  }

  handleSubscriptionType = (type: string) => {
    if (type === "pending") {
      return "New Subscription Request";
    } else if (type === "deactive_request") {
      return "Deactivate Plan";
    }
    return type
  }

  getCompComplexBuildingList = async () => {
    const { page, filter } = this.state;
    this.GetCompComplexBuildingApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/complex_request_list?page=${page}&country=${emptyFilterValue(filter.country)}&city=${emptyFilterValue(filter.city)}&requester_id=${emptyFilterValue(filter.requestor)}&name=${emptyFilterValue(filter.name)
        }&approval_type=${emptyFilterValue(filter.approvalType)}&type_building=${emptyFilterValue(filter.type)}`,
    });
  }

  handleCompComplexBuildingPropOwnerSuccess = (responseJson: { company_complexes_requests: { data: any[] }, meta: { pagination: any } }) => {
    if (responseJson && responseJson.company_complexes_requests) {
      this.setState({
        approvalList: responseJson.company_complexes_requests.data.map((item: any) => ({
          id: item.id,
          approvalType: item.attributes.approval_type||"-",
          complex: item.attributes.complex||"-",
          country: item.attributes.country||"-",
          name: item.attributes.name||"-",
          type: item.attributes.type||"-",
          requestedBy: item.attributes?.requested_by?.full_name||"-",
          city: item.attributes.city||"-",
          item: item
        })),
        pagination: responseJson.meta.pagination
      });
    }
  }

  getOwnerManagerList = async () => {
    const { page, filter } = this.state;
    this.GetOwnerManagerApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/owner_request_list?page=${page}&country=${emptyFilterValue(filter.country)}&city=${emptyFilterValue(filter.city)}&requester_id=${emptyFilterValue(filter.requestor)}&name=${emptyFilterValue(filter.name)
        }&approval_type=${emptyFilterValue(filter.approvalType)}&role=${emptyFilterValue(filter.userType)}`,
    });
  }

  getMiscList = async () => {
    const { page, filter } = this.state;
    this.GetMiscApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/user_misc_request?page=${page}&country=${emptyFilterValue(filter.country)}&city=${emptyFilterValue(filter.city)}&requester_id=${emptyFilterValue(filter.requestor)}&name=${emptyFilterValue(filter.name)
        }&approval_type=${emptyFilterValue(filter.approvalType)}&role=${emptyFilterValue(filter.userType)}`,
    });
  }


  getEmployeeList = async () => {
    const { page, filter } = this.state;
    this.GetEmployeeApiCallId = await CEApiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_dashboard/approval_dashbords/employee_request_list?page=${page}&country=${emptyFilterValue(filter.country)}&city=${emptyFilterValue(filter.city)}&requester_id=${emptyFilterValue(filter.requestor)}&name=${emptyFilterValue(filter.name)
        }&approval_type=${emptyFilterValue(filter.approvalType)}&role=${emptyFilterValue(filter.userType)}`,
    });
  }

  getApprovalCountryList = async () => {
    this.GetCountryApiCallId = await CEApiCall({
      method: "GET",
      endPoint: `bx_block_profile/profiles/country_list`,
      contentType: "application/json",
    });
  }

  handleCountrySuccess = (responseJson: { data: { countries: string[] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        countryList: responseJson.data.countries.map((item: string) => ({ label: item, value: item })),
      });
    }
  }

  getApprovalCityList = async (country: any) => {
    this.GetCityApiCallId = await CEApiCall({
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`,
      method: "GET",
      contentType: "application/json",
    });
  }

  handleCitySuccess = (responseJson: { city_list: { city: string }[] }) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        cityList: responseJson.city_list.map((item: { city: string }) => ({ label: item.city, value: item.city })),
      });
    }
  }

  handleRecordAction = (status: string, value: any,type:string) => {
    this.setState({ isAcceptModalOpen: false, isRejectModalOpen: false, isRejectReasonModalOpen: false, loading: true }, async () => {
      let endPoint = "";
      if (this.state.activeTab.id === 2) {
        endPoint = "bx_block_dashboard/approval_dashbords/accept_reject_request";
      } else if (this.state.activeTab.id === 3) {
        endPoint = `bx_block_dashboard/approval_dashbords/accept_reject_profile_request`;
      } else if (this.state.activeTab.id === 1) {
        endPoint = `bx_block_dashboard/approval_dashbords/accept_reject_subscription_request`
      } else if(this.state.activeTab.id === 5) {
        endPoint = `bx_block_dashboard/approval_dashbords/accept_reject_nomination_request`
      }
      else if (this.state.activeTab.id === 4) {
        if(type==="New Facility"){
         endPoint = `bx_block_dashboard/approval_dashbords/accept_reject_facility_request`
        }else{
        endPoint = `bx_block_dashboard/approval_dashbords/accept_reject_classified_request`
        }
      }
      const data = {
        "id": Number(this.state.actionInfo.id),
        "status": this.state.actionInfo.title==="New Subscription Request"?"active":status,
        "reject_note": value.note
      }

      this.AcceptRejectApiCallId = await CEApiCall({
        endPoint: endPoint,
        method: "PUT",
        contentType: "application/json",
        body: JSON.stringify(data)
      });
    });
  }

  handleRecordActionSuccess = () => {
    this.setState({
      loading: false,
      dialogSucessstate:true
    }
  )
  }
  handleDialogSucessstate=()=>{
    this.setState({
      dialogSucessstate:false,
      loading: false
    }
    ,() => {
      this.handleMainAPICall();
    }
  )
  }


  handleApprovalFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value as string;
    if (stateParam === "country") {
      this.setState({
        filter: {
          ...this.state.filter,
          country: value,
          name: "",
          userType: "",
          type: "",
          requestor: "",
          city: "",
          approvalType: "",
        },
      }, () => this.getApprovalCityList(value));
    } else if (stateParam === "city") {
      this.setState({
        filter: {
          ...this.state.filter,
          city: value,
          type: "",
          userType: "",
          requestor: "",
          name: "",
          approvalType: "",
        },
      });
    } else if (stateParam === "userType") {
      this.setState({
        filter: {
          ...this.state.filter,
          userType: value,
          name: "",
          requestor: "",
          type: "",
          approvalType: "",
        },
      });
    } else if (stateParam === "type") {
      this.setState({
        filter: {
          ...this.state.filter,
          type: value,
          requestor: "",
          name: "",
          approvalType: "",
        },
      });
    } else if (stateParam === "name") {
      this.setState({
        filter: {
          ...this.state.filter,
          name: value,
          approvalType: "",
          requestor: "",
        },
      });
    } else if (stateParam === "requestor") {
      this.setState({
        filter: {
          ...this.state.filter,
          requestor: value,
          approvalType: "",
        },
      });
    } else if (stateParam === "approvalType") {
      this.setState({
        filter: {
          ...this.state.filter,
          approvalType: value,
        },
      });
    }
  };

  rejectValidation: any = Yup.object().shape({
    note: Yup.string().required("Required").matches(/\S/, "Required"),
  });

  handleBuildingMap = (cooOpenTicketItem:any) => {
    this.setState({
      latitude:cooOpenTicketItem&&cooOpenTicketItem.lat,
      longitude:cooOpenTicketItem&&cooOpenTicketItem.long
    },()=>{this.handleCloseBuildingMap()});
  };

  handleCloseBuildingMap = () => {
    this.setState({ openBuildingMap: !this.state.openBuildingMap });
  };

    // Customizable Area End
}
