// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import * as Yup from "yup";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  step: number;
  handleNext: any;
  handleBack: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  jurisdiction: string | number;
  complexID: string | number;
  handleCountryCity: any;
}

interface S {
  loading: boolean;

  countriesList: any[];
  stateList: any[];
  cityList: any[];

  locationFormValue: ILocationForm;

  locationId: string;
}

interface ILocationForm {
  link: string;
  long: string;
  lat: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  landmark: string;
  street: string;
  address: string;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep4Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexCountryListCallId: string = "";
  GetComplexStateListCallId: string = "";
  GetComplexCityListCallId: string = "";
  GetAddressesDetailsCallId: string = "";
  AddEditLocationCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      countriesList: [],
      stateList: [],
      cityList: [],

      locationFormValue: {
        link: "",
        long: "",
        lat: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        landmark: "",
        street: "",
        address: "",
      },

      locationId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetComplexCountryListCallId:
          return this.handleCountryListResponse(responseJsonBlock);
        case this.GetComplexStateListCallId:
          return this.handleStateListResponse(responseJsonBlock);
        case this.GetComplexCityListCallId:
          return this.handleCityListResponse(responseJsonBlock);
        case this.GetAddressesDetailsCallId:
          return this.handleAddressesDetailsResponse(responseJsonBlock);
        case this.AddEditLocationCallId:
          return this.handleLocationSubmitResponse();
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getCountryList();
      this.getAddressesDetails();
    });
  }

  getCountryList = async () => {
    this.GetComplexCountryListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_address/addresses`,
    });
  };

  handleCountryListResponse = (responseJson: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({
          countriesList: responseJson.data.map((item: any) => ({
            value: item.attributes.name,
            label: item.attributes.name,
          })),
        });
      }
    });
  };

  getComplexStateList = async (id: string | number) => {
    this.GetComplexStateListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/state_list?country_name=${id}`,
    });
  };

  handleStateListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson) {
        this.setState({
          stateList: responseJson.map((item: any) => ({ value: item.name, label: item.name })),
        });
      }
    });
  };

  getComplexCityList = async (id: string | number) => {
    this.GetComplexCityListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_address/city_by_state?state=${id}`,
      method: "GET",
    });
  };

  handleCityListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson) {
        this.setState({
          cityList: responseJson.map((item: any) => ({ value: item.name, label: item.name })),
        });
      }
    });
  };

  getAddressesDetails = () => {
    this.setState({ loading: true }, async () => {
      this.GetAddressesDetailsCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_address/address_deatils_by_society?society_management_id=${this.props.complexID}`,
      });
    });
  };

  handleAddressesDetailsResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.address) {
        const address = responseJson.address;
        this.setState(
          {
            locationId: address.id,
            locationFormValue: {
              link: address.locationlink,
              long: address.longitude,
              lat: address.latitude,
              country: address.country,
              state: address.state,
              city: address.city,
              zipCode: address.zipcode,
              landmark: address.landmark,
              street: address.streetname,
              address: address.address,
            },
          },
          () => {
            this.getComplexStateList(address.country);
            this.getComplexCityList(address.state);
          }
        );
      } else {
        this.setState({ locationId: "" });
      }
    });
  };

  handleComplexLocationSubmit = async (values: ILocationForm) => {
    this.props.handleCountryCity(values.country, values.city);
    const formData = new FormData();
    formData.append("address[locationlink]", values.link);
    formData.append("address[latitude]", values.lat);
    formData.append("address[longitude]", values.long);
    formData.append("address[country]", values.country);
    formData.append("address[state]", values.state);
    formData.append("address[city]", values.city);
    formData.append("address[zipcode]", values.zipCode);
    formData.append("address[landmark]", values.landmark);
    formData.append("address[streetname]", values.street);
    formData.append("address[address]", values.street);
    formData.append("address[society_management_id]", this.props.complexID + "");

    if (this.state.locationId) {
      this.AddEditLocationCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_address/addresses/${this.state.locationId}`,
        body: formData,
      });
    } else {
      this.AddEditLocationCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_address/create_address`,
        body: formData,
      });
    }
  };

  handleLocationSubmitResponse = () => {
    this.setState({ loading: false }, () => {
      this.props.handleNext();
    });
  };

  validationSchema = Yup.object().shape({
    link: Yup.string().required(this.props.t("Location Link is required")).nullable(),
    long: Yup.string().required(this.props.t("Longitude is required")),
    lat: Yup.string().required(this.props.t("Latitude is required")),
    country: Yup.string().required(this.props.t("Country is required")),
    city: Yup.string().required(this.props.t("City is required")),
    state: Yup.string().required(this.props.t("State is required")),
    zipCode: Yup.string()
      .min(6, this.props.t("Zip Code must be at least 6 characters"))
      .max(7, this.props.t("Zip Code must be at most 7 characters"))
      .required(this.props.t("Zip Code is required"))
      .nullable(),
    landmark: Yup.string().required(this.props.t("Landmark is required")).nullable(),
    street: Yup.string().required(this.props.t("Street Name is required")).nullable(),
    address: Yup.string().required(this.props.t("Official Address is required")).nullable(),
  });
}
// Customizable Area End
