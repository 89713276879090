// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { dataURLtoFile } from "../../../../components/src/UtilFunction.web";
import { setStorageData } from "../../../../framework/src/Utilities";
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId: string;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface Jurisdiction {
  name: string;
  currency: string;
  timezone: string;
  measurement: string;
  formula: string;
  mobileLength: string;
  contract: string;
}

interface SharedArea {
  Details: string;
  sharedFacility: string;
  totalArea: string;
  fees: string;
  images: any[];
  floorDocument: File | null;
  floorPlanName: string;
}

export type MenuOption = {
  title: string;
  clickFn: () => void;
  testId?: string;
};

interface SubscriptionExtendDetails {
  date: string;
  isPaid: string;
  refNumber: string;
  reason: string;
}

interface PlanDetail {
  planId: string;
  isPaid: string;
  refNumber: string;
}

interface Select {
  label: string;
  value: string | number;
}

interface S {
  companyDetails: any;
  deleteConfirmModal: boolean;
  tabNo: number;
  proofFileName: any;
  page: number;
  pagination: any;
  mapSwitch: boolean;
  openOptions: boolean;
  jurisdicationModal: boolean;
  subscriptionModal: boolean;
  upgradesubscriptionModal: boolean;
  uploadModal: boolean;
  allJurisdiction: any;
  newJur: any;
  newJurId: any;
  newJurisdictionDetails: any;
  jId: any;
  openShared: boolean;

  sharedAreaList: SharedArea;
  sharedAdded: any;
  gridData: any;
  startDate: any;
  startDateName: any;
  refernceNo: any;
  subId: any;
  paidExtension: any;
  subscriptionExtend: any;
  error: any;
  showError: any;
  loading: any;
  upgradePaidExtension: any;
  upgradeRefNo: any;
  upgradeStartDate: any;
  updradeStartDateName: any;
  fileName: string;
  initialValues: any;
  searchQuery: any;

  document: {
    title: string;
    count: string | number;
    link: string;
  }[];

  isDeleteComplexOpen: boolean;
  isViewPlanOpen: boolean;
  isOpenMapOpen: boolean;

  location: {
    lat: number;
    long: number;
  };

  currentJurisdiction: Jurisdiction;
  newJurisdiction: Jurisdiction;

  planExtendDetail: {
    expiryData: string;
    status: string;
    day: string;
  };

  subscriptionExtendDetails: SubscriptionExtendDetails;
  planDetail: PlanDetail;

  planList: Select[];

  selectedPlan: {
    title: string;
    description: string;
  };
  complexId: number | null;

  userType: string;
}

interface SS {
  id: any;
}

export default class ComplexDetailsPageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompanyDetails: string = "";
  DeleteCompany: string = "";
  slider: any;
  getJurisidictionList: string = "";
  getJurisdictionIdApiCallId: string = "";
  getManageJurisdictionApiCallId: string = "";
  getSharedAreaCallId: string = "";
  addExtendApiCall: string = "";
  upgradeSubApiCall: string = "";
  getUploadDocumentList: string = "";
  getSearchBuilding: string = "";
  timeout: any;

  GetDocumentCountCallId: string = "";
  DeleteComplexCallId: string = "";
  GetSubscriptionDetailCallId: string = "";
  GetPlanListCallId: string = "";
  GetPlanDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.handleFileRemove = this.handleFileRemove.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      companyDetails: null,
      proofFileName: null,
      page: 1,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      mapSwitch: false,
      deleteConfirmModal: false,
      openOptions: false,
      jurisdicationModal: false,
      tabNo: 0,
      subscriptionModal: false,
      upgradesubscriptionModal: false,
      uploadModal: false,
      allJurisdiction: [],
      newJur: "",
      newJurId: "",
      newJurisdictionDetails: [],
      openShared: false,
      sharedAreaList: {
        Details: "",
        sharedFacility: "",
        totalArea: "",
        fees: "",
        images: [],
        floorDocument: null,
        floorPlanName: "",
      },
      sharedAdded: [],
      gridData: [],
      startDate: "",
      startDateName: "text",
      refernceNo: "",
      subId: null,
      paidExtension: null,
      subscriptionExtend: null,
      error: null,
      showError: false,
      loading: false,
      upgradePaidExtension: null,
      upgradeRefNo: "",
      upgradeStartDate: "",
      updradeStartDateName: "text",
      searchQuery: "",
      fileName: "",
      initialValues: {
        policyFile: "",
        roleFile: "",
        guideFile: "",
        resolveFile: "",
        buildingFile: "",
      },

      jId: "",
      document: [
        { title: "Policy", count: "0", link: "Policy" },
        { title: "Guidelines", count: "0", link: "Guideline" },
        { title: "Roles", count: "0", link: "Role" },
        { title: "Resolutions", count: "0", link: "Resolution" },
        { title: "Building Plans", count: "0", link: "Building_Plan" },
      ],

      isDeleteComplexOpen: false,
      isViewPlanOpen: false,
      isOpenMapOpen: false,

      location: {
        lat: 0,
        long: 0,
      },

      currentJurisdiction: {
        name: "",
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },
      newJurisdiction: {
        name: "",
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },

      planExtendDetail: {
        expiryData: "",
        status: "",
        day: "",
      },

      subscriptionExtendDetails: {
        date: "",
        isPaid: "true",
        refNumber: "",
        reason: "",
      },

      planDetail: {
        planId: "",
        isPaid: "true",
        refNumber: "",
      },

      planList: [],

      selectedPlan: {
        title: "",
        description: "",
      },
      complexId: null,

      userType: "coo",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompanyDetails:
          return this.handleCompanyDetails(responseJson);
        case this.getJurisdictionIdApiCallId:
          return this.handleJurisdictionById(responseJson);
        case this.getJurisidictionList:
          return this.handleAllJurisdiction(responseJson);
        case this.getManageJurisdictionApiCallId:
          return this.handleManageJurisdictionSucc();
        case this.GetDocumentCountCallId:
          return this.getDocumentCountResponse(responseJson);
        case this.getSharedAreaCallId:
          return this.succHandleShare(responseJson);
        case this.DeleteComplexCallId:
          return this.deleteComplexResponse();
        case this.GetSubscriptionDetailCallId:
          return this.getSubscriptionDetailResponse(responseJson);
        case this.upgradeSubApiCall:
          return this.handleUpgradeSubSucc();
        case this.addExtendApiCall:
          return this.handleExtendSubSuccess();
        case this.getUploadDocumentList:
          return this.uploadDocumentSuccess();
        case this.getSearchBuilding:
          return this.buildingSearchSucc(responseJson);
        case this.GetPlanListCallId:
          return this.handlePlanListResponse(responseJson);
        case this.GetPlanDetailsCallId:
          return this.handlePlanDetailsResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    const type = localStorage.getItem("role") || "";
    const ComplexId = this.props.navigation.getParam("id");
    setStorageData("pathName", window.location.pathname);
    this.setState({ complexId: ComplexId, userType: type, loading: true }, () => {
      this.getCompanyDetails();
      this.getDocumentCount();
      this.getPlanList();
    });
  }

  isValidValue = (value: any) => (value ? value : "-");

  confirmText = (t: any) =>
    this.state.userType !== "coo"
      ? t("Upon confirming the deletion of the complex, the request will be sent to the COO for approval")
      : t("Upon confirming the deletion of the complex, complex will not show on platform.");

  getCompanyDetails = async () => {
    const companyId = this.props.companyId;
    this.GetCompanyDetails = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/${companyId}`,
    });
  };

  handleCompanyDetails = (responseJson: any) => {
    this.setState({ loading: false });
    setStorageData("editComplex", JSON.stringify(responseJson.data.attributes));
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data.attributes.jurisdiction?.data;
      const fileName = responseJson.data.attributes.registration_certificate?.url;
      if (fileName) {
        const splitFlieName = fileName.split("/");
        this.setState({
          proofFileName: splitFlieName[splitFlieName.length - 1],
        });
      }

      if (jurisdiction) {
        this.setState({
          currentJurisdiction: {
            name: jurisdiction.attributes.jurisdiction_name,
            currency: jurisdiction.attributes.currency.currency,
            timezone: jurisdiction.attributes.timezone,
            measurement: jurisdiction.attributes.unit_of_measurement,
            formula: jurisdiction.attributes.formula,
            mobileLength: jurisdiction.attributes.mobile_number_length,
            contract: this.handleContract(jurisdiction.attributes.contracts),
          },
        });
      }

      const gridDetails = [
        {
          title: "Building Ownership Rate",
          value: this.handleCardData(responseJson.data?.attributes?.building_ownership_rate),
          extraTag: "Sold",
        },
        {
          title: "Rented Units",
          value: this.handleCardData(responseJson.data?.attributes?.rented),
        },
        {
          title: "Collected Management Fee",
          value: this.handleCardData(responseJson.data?.attributes?.collected_management_fee),
        },
        {
          title: "Registered Through Tenant",
          value: responseJson.data?.attributes?.tenant_resquests.tenant_count || "0",
          extraTag: `${responseJson.data?.attributes?.tenant_resquests.tenant_percent || "0"} %`,
        },
        {
          title: "Active Users",
          value: this.handleCardData(responseJson.data?.attributes?.active_users),
        },
        {
          title: "Inactive Users",
          value: this.handleCardData(responseJson.data?.attributes?.inactive_users),
        },
        {
          title: "Owners on boarded",
          value: this.handleCardData(responseJson.data?.attributes?.ownership_onboarded?.ownership_count),
          extraTag: `${responseJson.data?.attributes?.ownership_onboarded.ownership_percent || "0"} %`,
        },
        {
          title: "Pending ownership requests",
          value: this.handleCardData(responseJson.data?.attributes?.pending_ownership_requests),
        },
        {
          title: "Top 3 incidents categories",
          tags: Object.keys(responseJson.data?.attributes?.incident_categories),
        },
      ];
      this.setState({
        companyDetails: responseJson.data.attributes,
        gridData: gridDetails,
        subId: responseJson.data.attributes.subscription_id,
        jId: jurisdiction?.id || "",
        location: {
          lat: responseJson.data.attributes.lat,
          long: responseJson.data.attributes.long,
        },
      });
    }
  };

  handleCardData = (value: any) => (value ? value : "-");

  handleManageJurisdiction = async () => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    formData.append("jurisdiction_society[jurisdiction_id]", this.state.newJurId);
    formData.append("jurisdiction_society[society_management_id]", `${companyId}`);
    this.getManageJurisdictionApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_society_management/company_employee/jurisdiction_societies`,
      body: formData,
    });
  };

  handleManageJurisdictionSucc = () => {
    this.setState({ jurisdicationModal: false }, () => {
      this.getCompanyDetails();
    });
  };

  getAllJurisdiction = async () => {
    this.getJurisidictionList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions`,
    });
  };

  handleAllJurisdiction = (response: any) => {
    if (response && response.jurisdictions) {
      this.setState({
        allJurisdiction: response.jurisdictions.data.map((item: any) => ({
          label: item.attributes.jurisdiction_name,
          value: item.id,
        })),
      });
    }
  };

  getJurisdictionById = async (id: number | string) => {
    this.getJurisdictionIdApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleJurisdictionById = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data;
      this.setState({
        newJurisdiction: {
          name: jurisdiction.attributes.jurisdiction_name,
          currency: jurisdiction.attributes.currency.currency,
          timezone: jurisdiction.attributes.timezone,
          measurement: jurisdiction.attributes.unit_of_measurement,
          formula: jurisdiction.attributes.formula,
          mobileLength: jurisdiction.attributes.mobile_number_length,
          contract: this.handleContract(jurisdiction.attributes.contracts),
        },
      });
    }
  };

  handleContract = (contract: any) => {
    if (contract && contract.length > 0) {
      return contract[0].filename;
    }
    return "";
  };

  GetBuildingSearch = async () => {
    const companyId = this.props.companyId;
    this.getSearchBuilding = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_settings/building_managements/building_search?society_management_id=${companyId}&building_name=${this.state.searchQuery.trim()}`,
    });
  };

  buildingSearchSucc = (responseJson: { data: [] }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ companyDetails: { ...this.state.companyDetails, building_list: responseJson.data } });
      }
    });
  };

  documentUpload = async (values: any) => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    if (values.policyFile) {
      formData.append("policy", values.policyFile);
      formData.append("policy_title", values.policyFile.name);
    }
    if (values.roleFile) {
      formData.append("roles", values.roleFile);
      formData.append("roles_title", values.roleFile.name);
    }
    if (values.buildingFile) {
      formData.append("building_plan", values.buildingFile);
      formData.append("building_plan_title", values.buildingFile.name);
    }
    if (values.guideFile) {
      formData.append("guideline", values.guideFile);
      formData.append("guideline_title", values.guideFile.name);
    }
    if (values.resolveFile) {
      formData.append("resolution", values.resolveFile);
      formData.append("resolution_title", values.resolveFile.name);
    }

    this.getUploadDocumentList = await apiCall({
      method: "POST",
      endPoint: `bx_block_my_document/building_documents/bulk_upload?society_management_id=${companyId}`,
      body: formData,
    });
  };

  uploadDocumentSuccess = () => {
    this.getDocumentCount();
  };

  getDocumentCount = async () => {
    const companyId = this.props.companyId;
    this.GetDocumentCountCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${companyId}/bx_block_my_document/document_count`,
    });
  };

  getDocumentCountResponse = (responseJson: {
    data: {
      policy_count: string;
      guideline_count: string;
      role_count: string;
      resolution_count: string;
      building_plan_count: string;
    };
  }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        document: [
          { title: "Policy", count: responseJson.data.policy_count, link: "Policy" },
          { title: "Guidelines", count: responseJson.data.guideline_count, link: "Guideline" },
          { title: "Roles", count: responseJson.data.role_count, link: "Role" },
          { title: "Resolutions", count: responseJson.data.resolution_count, link: "Resolution" },
          { title: "Building Plans", count: responseJson.data.building_plan_count, link: "Building_Plan" },
        ],
      });
    }
  };

  handleShared = async (values: SharedArea) => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    formData.append("common_area[name]", values.sharedFacility);
    formData.append("common_area[society_management_id]", `${companyId}`);
    formData.append("common_area[details]", values.Details);
    formData.append("common_area[total_area]", values.totalArea);
    formData.append("common_area[reservation_fee]", values.fees);
    if (values.floorDocument) {
      formData.append("common_area[floor_plan]", values.floorDocument);
    }
    values.images.forEach((image: any) => {
      formData.append("common_area[photos][]", dataURLtoFile(image));
    });

    this.getSharedAreaCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_society_management/common_areas`,
      body: formData,
    });
  };

  succHandleShare = (response: { data: [] }) => {
    this.setState({ sharedAdded: response.data, openShared: false }, () => {
      this.getCompanyDetails();
    });
  };

  handleDeleteComplex = () => {
    this.setState({ loading: true, isDeleteComplexOpen: false }, async () => {
      const companyId = this.props.companyId;
      this.DeleteComplexCallId = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_society_management/company_employee/complexes/${companyId}`,
      });
    });
  };

  deleteComplexResponse = () => {
    this.props.navigation.navigate("ComplexListing");
  };

  handleOpenSubscription = () => {
    this.setState({ subscriptionModal: true, openOptions: false }, () => {
      if (this.state.subId) {
        this.getSubscriptionDetails();
      }
    });
  };

  getSubscriptionDetails = async () => {
    this.GetSubscriptionDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/subscriptions/${this.state.subId}`,
    });
  };

  getSubscriptionDetailResponse = (responseJson: {
    data: { attributes: { expiry_date: string; status: string; ends_in_days: string } };
  }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loading: false,
        planExtendDetail: {
          expiryData: responseJson.data.attributes.expiry_date,
          status: responseJson.data.attributes.status,
          day: responseJson.data.attributes.ends_in_days,
        },
      });
    }
  };

  upgradeSubscription = async (values: SubscriptionExtendDetails) => {
    const raw = JSON.stringify({
      expiry_date: values.date,
      paid_extension: values.isPaid === "true",
      payment_reference_number: values.refNumber,
      subscription_id: this.state.subId,
      reasion: values.reason,
    });

    this.upgradeSubApiCall = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_custom_user_subs/subscription_extend_requests`,
      body: raw,
    });
  };

  handleUpgradeSubSucc = () => {
    this.setState({ loading: false }, () => {
      this.getCompanyDetails();
      this.getSubscriptionDetails();
    });
  };

  handleExtendSub = async (values: PlanDetail) => {
    const raw = JSON.stringify({
      data: {
        plan_id: values.planId,
        payment_reference_number: values.refNumber,
      },
    });

    this.addExtendApiCall = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/upgrade_subscription_plan/${this.state.subId}`,
      body: raw,
    });
  };

  handleExtendSubSuccess = () => {
    this.setState({ loading: false }, () => {
      this.getCompanyDetails();
      this.getSubscriptionDetails();
    });
  };

  getPlanList = async () => {
    this.GetPlanListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_user_subs/plans`,
    });
  };

  handlePlanListResponse = (responseJson: { data: { id: number; attributes: { title: string } }[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ planList: responseJson.data.map((plan) => ({ label: plan.attributes.title, value: plan.id })) });
    }
  };

  handleOpenPlanDetails = () => {
    this.setState({ isViewPlanOpen: true, openOptions: false }, () => {
      this.getPlanDetails();
    });
  };

  getPlanDetails = async () => {
    this.GetPlanDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_plan?complex_id=${this.props.companyId}`,
    });
  };

  handlePlanDetailsResponse = (responseJson: {
    expiration_date: string;
    status: string;
    day_to_expire: string;
    plan_detail: {
      title: string;
      description: string;
    };
  }) => {
    if (responseJson) {
      this.setState({
        planExtendDetail: {
          expiryData: responseJson.expiration_date,
          status: responseJson.status,
          day: responseJson.day_to_expire,
        },
        selectedPlan: {
          title: responseJson.plan_detail.title,
          description: responseJson.plan_detail.description,
        },
      });
    }
  };

  nextImage = () => {
    this.slider.slickNext();
  };
  previousImage = () => {
    this.slider.slickPrev();
  };

  handleOpenOptions = () => {
    this.setState({ openOptions: !this.state.openOptions });
  };

  handleOpenJurisdication = () => {
    this.setState(
      {
        jurisdicationModal: true,
        openOptions: false,
        newJurId: "",
        newJurisdiction: {
          name: "",
          currency: "",
          timezone: "",
          measurement: "",
          formula: "",
          mobileLength: "",
          contract: "",
        },
      },
      () => {
        this.getAllJurisdiction();
      }
    );
  };

  handleDeactivateComplexDialog = () => {
    this.setState({ isDeleteComplexOpen: !this.state.isDeleteComplexOpen, openOptions: false });
  };

  handleSearchKeyUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    clearTimeout(this.timeout);

    this.setState({ searchQuery, loading: true }, () => {
      this.GetBuildingSearch();
    });
  };

  handleFileRemove = () => {
    this.setState({
      fileName: "",
    });
  };

  handleCloseJurisdication = () => {
    this.setState({
      jurisdicationModal: false,
      newJurId: "",
      newJurisdictionDetails: [],
      newJur: "",
      newJurisdiction: {
        name: "",
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },
    });
  };

  showError = () => {
    if (this.state.error) {
      this.setState({
        showError: true,
        loading: false,
      });
    }
  };
  handleSubmit = (values: SharedArea, resetForm: () => void) => {
    this.documentUpload(values);
  };

  handleUpgradeStartDate = () => {
    this.setState({
      updradeStartDateName: "date",
    });
  };

  handleSharedDetails = (values: SharedArea, resetForm: () => void) => {
    this.handleShared(values);
    resetForm();
  };

  handleUpgradePlan = () => {
    this.setState({ upgradesubscriptionModal: true, openOptions: false });
  };
  handleCloseUpgradeSubscription = () => {
    this.setState({
      upgradesubscriptionModal: false,
      upgradeStartDate: "",
      upgradeRefNo: "",
      upgradePaidExtension: null,
    });
  };

  handleCloseSubscription = () => {
    this.setState({ subscriptionModal: false, startDate: "", paidExtension: null, refernceNo: "" });
  };
  handleStartDate = () => {
    this.setState({
      startDateName: "date",
    });
  };
  handleOpenUploadModal = () => {
    this.setState({ uploadModal: true });
  };

  handleCloseUploadModal = () => {
    this.setState({ uploadModal: false });
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabNo: newValue });
  };

  handleAddBuilding = () => {
    const companyId = this.props.companyId;
    this.props.navigation.history?.push(`/CompanyEmployee/ComplexBuildingMainBlock/${companyId}`);
  };
  handleSharedArea = () => {
    this.setState({ openShared: true });
  };

  uploadFile: any;
  uploadGuideFile: any;
  uploadBuildingFile: any;
  uploadResolveFile: any;
  uploadRoleFile: any;

  handleSharedAreaNavigation = (id: number | string) => {
    this.props.navigation.navigate("SharedAreaDetails", {
      id: id,
      details: "ComplexDetails",
      cId: this.props.companyId,
    });
  };

  handleToggleMapDialog = () => {
    this.setState({ isOpenMapOpen: !this.state.isOpenMapOpen });
  };

  handleViewPlanDialog = () => {
    this.setState({ isViewPlanOpen: !this.state.isViewPlanOpen });
  };

  validationSchema = Yup.object().shape({
    Details: Yup.string().required("Details are required"),
    sharedFacility: Yup.string().required("Shared Facility required"),
    totalArea: Yup.string().required("Total Area is required"),
    fees: Yup.string().required("Reservation fees is required"),
    floorDocument: Yup.mixed().required("Please upload a Floor Document File"),
  });
}
// Customizable Area End
