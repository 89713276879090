// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import * as Yup from "yup";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleFilterComplexList } from "../../../../components/src/UtilFunction.web";

export interface Props {
  step: number;
  handleBack: any;
  jurisdiction: string | number;
  complexID: string | number;
  isEditComplex: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  navigation: any;
  country: any;
  city: any;
}

interface S {
  loading: boolean;
  isAddCoreMembersOpen: boolean;
  isCreateNewCoreMembersOpen: boolean;
  isComplexComplexOpen: boolean;

  coreMembersList: any[];
  coreUserList: any[];
  coreRoleList: any[];
  userTypeList: any[];
  complexList: any[];
  filterComplexList: any[];
  buildingList: any[];
  unitList: [];

  coreFormValues: ICoreFormValues;
  memberFormValues: IMemberFormValue;

  showError: boolean;
  error: string;

  role: string;
}

interface IMemberFormValue {
  type: string;
  name: string;
  email: string;
  countryCode: string;
  phone: string;
  country: string;
  city: string;
  isComplex: number;
  complex: string;
  building: string;
  unit: string;
}

interface ICoreFormValues {
  account: string;
  role: string;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep7Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexCoreMemberListCallId: string = "";
  DeleteCoreMemberCallId: string = "";
  GetCoreUserListCallId: string = "";
  GetCoreRoleListCallId: string = "";
  AddNewCoreMemberCallId: string = "";
  GetUserTypeListCallId: string = "";
  GetCoreComplexCallId: string = "";
  GetCoreBuildingCallId: string = "";
  GetCoreUnitCallId: string = "";
  InviteCoreMemberAPICallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isAddCoreMembersOpen: false,
      isCreateNewCoreMembersOpen: false,
      isComplexComplexOpen: false,

      coreMembersList: [],
      coreUserList: [],
      coreRoleList: [],
      userTypeList: [],
      complexList: [],
      filterComplexList: [],
      buildingList: [],
      unitList: [],

      coreFormValues: {
        account: "",
        role: "",
      },

      memberFormValues: {
        type: "",
        name: "",
        email: "",
        countryCode: "+971",
        phone: "",
        country: this.props.country,
        city: this.props.city,
        isComplex: 1,
        complex: "",
        building: "",
        unit: "",
      },

      showError: false,
      error: "",

      role: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetComplexCoreMemberListCallId:
          return this.handleComplexCoreMemberListResponse(responseJsonBlock);
        case this.DeleteCoreMemberCallId:
          return this.handleDeleteCoreMemberSuccess();
        case this.GetCoreUserListCallId:
          return this.handleCoreMemberUserListResponse(responseJsonBlock);
        case this.GetCoreRoleListCallId:
          return this.handleCoreRoleListResponse(responseJsonBlock);
        case this.AddNewCoreMemberCallId:
          return this.handleAddNewCoreMemberResponse();
        case this.GetUserTypeListCallId:
          return this.handleUserTypeListResponse(responseJsonBlock);
        case this.GetCoreComplexCallId:
          return this.handleCoreComplexListResponse(responseJsonBlock);
        case this.GetCoreBuildingCallId:
          return this.handleCoreBuildingListResponse(responseJsonBlock);
        case this.GetCoreUnitCallId:
          return this.handleCoreUnitListResponse(responseJsonBlock);
        case this.InviteCoreMemberAPICallId:
          return this.handleInviteCoreMemberResponse();
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    const user_role = localStorage.getItem("role") || "";
    this.setState(
      {
        loading: true,
        role: user_role,
        memberFormValues: {
          ...this.state.memberFormValues,
          complex: this.props.complexID + "",
          isComplex: 1,
        },
      },
      () => {
        this.getComplexCoreMemberList();
        this.getCoreRoleList();
        this.getUserTypeList();

        this.getCoreComplexList(this.props.country, this.props.city);
        this.getCoreBuildingList(this.props.complexID + "");
      }
    );
  }

  getComplexCoreMemberList = () => {
    this.setState({ loading: true }, async () => {
      this.GetComplexCoreMemberListCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_society_management/company_employee/complexes/core_member_listing?id=${this.props.complexID}`,
      });
    });
  };

  handleComplexCoreMemberListResponse = (response: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (response && response.data) {
        this.setState({ coreMembersList: response.data });
      }
    });
  };

  handleDeleteCoreMember = (id: string | number) => {
    this.setState({ loading: true }, async () => {
      this.DeleteCoreMemberCallId = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_my_team/team_members/${id}`,
      });
    });
  };

  handleDeleteCoreMemberSuccess = () => {
    this.setState({ loading: false }, () => {
      this.getComplexCoreMemberList();
    });
  };

  getCoreMemberUserList = async () => {
    this.GetCoreUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/member_list?id=${this.props.complexID}`,
    });
  };

  handleCoreMemberUserListResponse = (responseJson: { members: { id: number | string; full_name: string }[] }) => {
    if (responseJson && responseJson.members) {
      this.setState({
        coreUserList: responseJson.members.map((data: { id: number | string; full_name: string }) => ({
          label: data.full_name,
          value: data.id + "",
        })),
      });
    }
  };

  getCoreRoleList = async () => {
    this.GetCoreRoleListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_society_management/company_employee/complexes/role_list?core_member=true`,
      method: "GET",
    });
  };

  handleCoreRoleListResponse = (responseJson: { roles: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.roles) {
      this.setState({
        coreRoleList: responseJson.roles.map((data: { id: number | string; name: string }) => ({
          label: data.name,
          value: data.id + "",
        })),
      });
    }
  };

  submitNewCoreMember = async (values: ICoreFormValues) => {
    const data = {
      role_id: Number(values.role),
      complex_id: this.props.complexID,
      account_id: Number(values.account),
    };

    this.AddNewCoreMemberCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      body: JSON.stringify(data),
      endPoint: `bx_block_society_management/company_employee/complexes/add_member`,
    });
  };

  handleAddNewCoreMemberResponse = () => {
    this.setState({ loading: false }, () => {
      this.getComplexCoreMemberList();
    });
  };

  getUserTypeList = async () => {
    this.GetUserTypeListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/role_list?core_member=false`,
    });
  };

  handleUserTypeListResponse = (responseJson: { roles: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.roles) {
      this.setState({
        userTypeList: responseJson.roles.map((data: { id: number | string; name: string }) => ({
          value: data.id + "",
          label: data.name,
        })),
      });
    }
  };

  getCoreComplexList = async (country?: string, city?: string) => {
    this.GetCoreComplexCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?country=${country}&city=${city}`,
    });
  };

  handleCoreComplexListResponse = (responseJson: { complexes: { data: [] } }) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.setState({ filterComplexList: handleFilterComplexList(1, this.state.complexList) });
      });
    }
  };

  handleIsCoreComplex = (value: string | number) => {
    this.setState({
      filterComplexList: handleFilterComplexList(Number(value), this.state.complexList),
    });
  };

  getCoreBuildingList = async (complexId: string | number) => {
    this.GetCoreBuildingCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleCoreBuildingListResponse = (responseJson: { buildings: { name: string; id: number | string }[] }) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: { name: string; id: number | string }) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  getCoreUnitList = async (complexId: string | number, buildingId: string | number) => {
    this.GetCoreUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${complexId}&building_management_id=${buildingId}`,
    });
  };

  handleCoreUnitListResponse = (responseJsonBlock: any) => {
    this.setState({
      unitList: responseJsonBlock.data.map((item: any) => ({ value: item.id, label: item.attributes.apartment_name })),
    });
  };

  inviteCoreMemberForm = async (value: IMemberFormValue) => {
    const formData = new FormData();
    formData.append("member_invitation[role_id]", value.type);
    formData.append("member_invitation[full_name]]", value.name);
    formData.append("member_invitation[email_address]", value.email);
    formData.append("member_invitation[phone_number]", value.countryCode + "-" + value.phone);
    formData.append("member_invitation[society_management_id]", value.complex);
    formData.append("member_invitation[building_management_id]", value.building);
    formData.append("member_invitation[apartment_management_id]", value.unit);

    this.InviteCoreMemberAPICallId = await apiCall({
      body: formData,
      method: "POST",
      endPoint: `bx_block_request_management/member_invitations`,
    });
  };

  handleInviteCoreMemberResponse = () => {
    this.setState({ loading: false, showError: true, error: "Core member invited successfully" });
  };

  handleAddMemberDialog = () => {
    this.setState({ isAddCoreMembersOpen: !this.state.isAddCoreMembersOpen }, () => {
      this.getCoreMemberUserList();
    });
  };

  handleAddCoreMemberDialog = () => {
    this.setState({ isAddCoreMembersOpen: !this.state.isAddCoreMembersOpen });
  };

  handleCreateNewCoreMemberDialog = () => {
    this.setState({
      isAddCoreMembersOpen: false,
      isCreateNewCoreMembersOpen: !this.state.isCreateNewCoreMembersOpen,
      memberFormValues: {
        ...this.state.memberFormValues,
        type: "",
        name: "",
        email: "",
        countryCode: "+971",
        phone: "",
        building: "",
        unit: "",
      },
    });
  };

  handleComplexComplexDialog = () => {
    this.setState({ isComplexComplexOpen: !this.state.isComplexComplexOpen });
  };

  handleSubmitComplexComplexDialog = () => {
    const estate_id = sessionStorage.getItem("real_estate_id") || "";
    sessionStorage.clear();
    if (!estate_id) {
      this.props.navigation.navigate("ComplexListing");
    } else {
      this.props.navigation.navigate("RealEstateCompanyDetail", { id: estate_id });
    }
  };

  validationSchema = Yup.object().shape({
    account: Yup.string().required("Username is required"),
    role: Yup.string().required("Role is required"),
  });

  validationCreateSchema = Yup.object().shape({
    type: Yup.string().required("User type is required"),
    name: Yup.string()
      .required("Full name is required")
      .matches(/\S/, "Required")
      .matches(/^[A-Za-z\s]+$/, "Enter valid name"),
    email: Yup.string().required("Email address is required").email("Enter valid email"),
    phone: Yup.string().required("Phone number is required"),
    unit: Yup.string().required("Unit is required"),
  });

  handleMessage = () => {
    if (this.state.role === "coo") {
      if (this.props.isEditComplex) {
        return "Complex has been successfully edited on the platform.";
      } else {
        return "Complex has been successfully created on the platform.";
      }
    } else {
      return "Approval request has been sent to the COO once the request get approved.";
    }
  };
}
// Customizable Area End
