// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { dataURLtoFile } from "../../../../components/src/UtilFunction.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId: number;
  history: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface SharedArea {
  BuildDetails: string;
  sharedFacilityBuild: string;
  totalAreaBuild: string;
  feesBuild: string;
  images: any;
  floorDocument: File | null;
  floorPlanName: string;
}

export type MenuOption = {
  title: string;
  clickFn: () => void;
  testId?: string;
};

interface BuildingJurisdiction {
  name: string;
  currency: string;
  timezone: string;
  measurement: string;
  formula: string;
  mobileLength: string;
  contract: string;
}

interface SubBuildingExtendDetails {
  date: string;
  isPaid: string;
  refNumber: string;
  reason: string;
}

interface BuildingPlanDetail {
  planId: string;
  isPaid: string;
  refNumber: string;
}

interface S {
  buildingDetails: any;
  deleteConfirmModal: boolean;
  tabNo: number;
  proofFileName: any;
  page: number;
  unitpage: number;
  unitPagination: any;
  pagination: any;
  mapSwitch: boolean;
  openOptions: boolean;
  jurisdicationModal: boolean;
  subscriptionModal: boolean;
  upgradesubscriptionModal: boolean;
  uploadModal: boolean;
  allJurisdiction: any;
  newJur: any;
  newJurId: any;
  newJurisdictionDetails: any;
  jId: any;
  openShared: any;
  unitList: any[];
  sharedAreaBuildingList: SharedArea;
  sharedAdded: any;
  searchQuery: any;
  gridBuildingDetails: any;
  startDateName: any;
  unitStatus: any;
  societyId: any;
  status: any;
  initialValues: any;

  currentBuildingJurisdiction: BuildingJurisdiction;
  newBuildingJurisdiction: BuildingJurisdiction;

  subscriptionId: any;

  isDeleteComplexOpen: boolean;
  document: {
    title: string;
    count: string | number;
    link: string;
  }[];

  isOpenMapOpen: boolean;

  buildingLocation: {
    lat: number;
    long: number;
  };

  loading: boolean;

  subBuildingExtendDetails: SubBuildingExtendDetails;
  buildingPlanDetail: BuildingPlanDetail;

  planExtendDetail: {
    expiryData: string;
    status: string;
    day: string;
  };
  planList: Select[];

  isBuildingViewPlanOpen: boolean;
  selectedBuildingPlan: any;

  userType: string;
}

interface Select {
  label: string;
  value: string | number;
}

interface SS {
  id: any;
}

export default class BuildingDetailsPageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  slider: any;

  GetBuildingDetailsCallId: string = "";
  getUnitListId: string = "";
  GetUploadDocumentCallId: string = "";
  GetBuildingDocumentCountCallId: string = "";
  UpdateSharedAreaCallId: string = "";
  GetJurisdictionCallId: string = "";
  getBuildingJurisdictionIdApiCallId: string = "";
  getBuildManageJurisdictionApiCallId: string = "";
  DeleteIndividualBuildingCallId: string = "";
  GetPlanListCallId: string = "";
  GetSubscriptionDetailCallId: string = "";
  UpgradeSubApiCallId: string = "";
  ExtendBuildingPlanApiCallId: string = "";
  GetBuildingPlanDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      buildingDetails: null,
      proofFileName: null,
      page: 1,
      unitpage: 1,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      unitPagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      unitList: [],
      mapSwitch: false,
      deleteConfirmModal: false,
      openOptions: false,
      jurisdicationModal: false,
      tabNo: 0,
      subscriptionModal: false,
      upgradesubscriptionModal: false,
      uploadModal: false,
      allJurisdiction: [],
      newJur: "",
      newJurId: "",
      newJurisdictionDetails: [],
      jId: "",
      openShared: false,
      sharedAreaBuildingList: {
        BuildDetails: "",
        sharedFacilityBuild: "",
        totalAreaBuild: "",
        feesBuild: "",
        images: [],
        floorDocument: null,
        floorPlanName: "",
      },
      sharedAdded: [],
      searchQuery: "",
      gridBuildingDetails: [],
      startDateName: "text",
      unitStatus: "",
      societyId: null,
      status: "",
      initialValues: {
        policyFileData: "",
        roleFileData: "",
        guideFileData: "",
        resolveFileData: "",
        buildingFileData: "",
      },

      currentBuildingJurisdiction: {
        name: "",
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },
      newBuildingJurisdiction: {
        name: "",
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },

      subscriptionId: "",

      isDeleteComplexOpen: false,
      document: [
        { title: "Policy", count: "0", link: "Policy" },
        { title: "Guidelines", count: "0", link: "Guideline" },
        { title: "Roles", count: "0", link: "Role" },
        { title: "Resolutions", count: "0", link: "Resolution" },
        { title: "Building Plans", count: "0", link: "Building_Plan" },
      ],

      isOpenMapOpen: false,
      buildingLocation: {
        lat: 0,
        long: 0,
      },

      loading: false,

      buildingPlanDetail: {
        refNumber: "",
        planId: "",
        isPaid: "true",
      },
      subBuildingExtendDetails: {
        refNumber: "",
        date: "",
        reason: "",
        isPaid: "true",
      },
      planExtendDetail: {
        expiryData: "",
        status: "",
        day: "",
      },
      planList: [],

      isBuildingViewPlanOpen: false,
      selectedBuildingPlan: {
        title: "",
        description: "",
      },

      userType: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.getBuildManageJurisdictionApiCallId:
          return this.handleManageJurisdictionSuccB();
        case this.GetBuildingDetailsCallId:
          return this.handleBuildingDetails(responseJson);
        case this.getUnitListId:
          return this.handleUnitList(responseJson);
        case this.GetUploadDocumentCallId:
          return this.handleDocSucc();
        case this.GetBuildingDocumentCountCallId:
          return this.getBuildingDocumentCountResponse(responseJson);
        case this.UpdateSharedAreaCallId:
          return this.succHandleShareB(responseJson);
        case this.GetJurisdictionCallId:
          return this.handleAllJurisdictionB(responseJson);
        case this.getBuildingJurisdictionIdApiCallId:
          return this.handleBuildingJurisdictionById(responseJson);
        case this.DeleteIndividualBuildingCallId:
          return this.deleteIndividualBuildingResponse();
        case this.GetSubscriptionDetailCallId:
          return this.getSubscriptionDetailResponse(responseJson);
        case this.GetPlanListCallId:
          return this.handleSubscriptionPlanListResponse(responseJson);
        case this.UpgradeSubApiCallId:
          return this.handleUpgradeSubscriptionResponse();
        case this.ExtendBuildingPlanApiCallId:
          return this.handleBuildingExtendSubResponse();
        case this.GetBuildingPlanDetailsCallId:
          return this.handlePlanDetailsResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    const type = localStorage.getItem("role") || "";
    this.setState({ userType: type, loading: true }, () => {
      this.getBuildingDetails();
      this.getUnitList();
      this.getPlanList();
      this.getBuildingDocumentCount();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page || prevState.status !== this.state.status) {
      await this.getUnitList();
    }
  }

  getPlanList = async () => {
    this.GetPlanListCallId = await apiCall({
      endPoint: `bx_block_custom_user_subs/plans`,
      method: "GET",
      contentType: "application/json",
    });
  };

  handleSubscriptionPlanListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ planList: responseJson.data.map((plan: any) => ({ value: plan.id, label: plan.attributes.title })) });
    }
  };

  handleBuildingDetails = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      const fileName = responseJson.data.attributes.registration_certificate?.url;
      const jurisdiction = responseJson.data.attributes.jurisdiction?.data;

      if (fileName) {
        const splitFlieName = fileName.split("/");
        this.setState({
          proofFileName: splitFlieName[splitFlieName.length - 1],
        });
      }

      const gridDataDetails: any = [
        {
          titles: "Building Ownership rate",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.building_ownership_rate),
          extraTags: "Sold",
        },
        {
          titles: "Rented Units",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.rented),
        },
        {
          titles: "Collected Management Fee",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.collected_management_fee),
        },
        {
          titles: "Registered through Tenant",
          values: responseJson.data?.attributes?.tenant_resquests.tenant_count || "0",
          extraTags: `${responseJson.data?.attributes?.tenant_resquests.tenant_percent || "0"} %`,
        },
        {
          titles: "Active Users",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.active_users),
        },
        {
          titles: "Inactive Users",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.inactive_users),
        },
        {
          titles: "Owners on boarded",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.ownership_onboarded?.ownership_count),
          extraTags: `${responseJson.data?.attributes?.ownership_onboarded.ownership_percent || "0"} %`,
        },
        {
          titles: "Pending ownership requests",
          values: this.handleBuildingCardData(responseJson.data?.attributes?.pending_ownership_requests),
        },
        {
          titles: "Top 3 incidents categories",
          tags: Object.keys(responseJson.data?.attributes?.incident_categories),
        },
      ];

      if (jurisdiction) {
        this.setState({
          currentBuildingJurisdiction: {
            name: jurisdiction.attributes.jurisdiction_name,
            currency: jurisdiction.attributes.currency.currency,
            timezone: jurisdiction.attributes.timezone,
            measurement: jurisdiction.attributes.unit_of_measurement,
            formula: jurisdiction.attributes.formula,
            mobileLength: jurisdiction.attributes.mobile_number_length,
            contract: this.handleContract(jurisdiction.attributes.contracts),
          },
        });
      }

      this.setState({
        buildingDetails: responseJson.data.attributes,
        gridBuildingDetails: gridDataDetails,
        jId: jurisdiction?.id || "",
        buildingLocation: {
          lat: responseJson.data.attributes.lat,
          long: responseJson.data.attributes.long,
        },
        subscriptionId: responseJson.data.attributes.subscription_id,
      });
    }
  };

  handleBuildingCardData = (value: any) => (value ? value : "-");

  handleContract = (file: any) => {
    if (file && file.length > 0) {
      return file[0].filename;
    }
    return "";
  };

  getBuildingDetails = async () => {
    const companyId = this.props.companyId;
    this.GetBuildingDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/${companyId}`,
    });
  };

  getBuildingDocumentCount = async () => {
    const companyId = this.props.companyId;
    this.GetBuildingDocumentCountCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${companyId}/bx_block_my_document/document_count`,
    });
  };

  getBuildingDocumentCountResponse = (responseJson: {
    data: {
      resolution_count: string;
      policy_count: string;
      role_count: string;
      building_plan_count: string;
      guideline_count: string;
    };
  }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        document: [
          { title: "Policy", count: responseJson.data.policy_count, link: "Policy" },
          { title: "Guidelines", count: responseJson.data.guideline_count, link: "Guideline" },
          { title: "Roles", count: responseJson.data.role_count, link: "Role" },
          { title: "Resolutions", count: responseJson.data.resolution_count, link: "Resolution" },
          { title: "Building Plans", count: responseJson.data.building_plan_count, link: "Building_Plan" },
        ],
      });
    }
  };

  handleSubmit = (values: any, resetForm: () => void) => {
    this.documentUpload(values);
    resetForm();
  };

  documentUpload = async (values: any) => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    if (values.policyFileData) {
      formData.append("policy", values.policyFileData);
      formData.append("policy_title", values.policyFileData.name);
    }
    if (values.roleFileData) {
      formData.append("roles", values.roleFileData);
      formData.append("roles_title", values.roleFileData.name);
    }
    if (values.buildingFileData) {
      formData.append("building_plan", values.buildingFileData);
      formData.append("building_plan_title", values.buildingFileData.name);
    }
    if (values.guideFileData) {
      formData.append("guideline", values.guideFileData);
      formData.append("guideline_title", values.guideFileData.name);
    }
    if (values.resolveFileData) {
      formData.append("resolution", values.resolveFileData);
      formData.append("resolution_title", values.resolveFileData.name);
    }
    this.GetUploadDocumentCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_my_document/building_documents/bulk_upload?society_management_id=${companyId}`,
      body: formData,
    });
  };

  handleDocSucc = () => {
    this.getBuildingDocumentCount();
  };

  handleSharedDetails = (values: SharedArea, resetForm: () => void) => {
    this.handleBuildShared(values);
    resetForm();
  };

  handleBuildShared = async (values: SharedArea) => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    formData.append("common_area[name]", values.sharedFacilityBuild);
    formData.append("common_area[society_management_id]", `${companyId}`);
    formData.append("common_area[details]", values.BuildDetails);
    formData.append("common_area[total_area]", values.totalAreaBuild);
    formData.append("common_area[reservation_fee]", values.feesBuild);
    if (values.floorDocument) {
      formData.append("common_area[floor_plan]", values.floorDocument);
    }
    values.images.forEach((image: any) => {
      formData.append("common_area[photos][]", dataURLtoFile(image));
    });

    this.UpdateSharedAreaCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_society_management/common_areas`,
      body: formData,
    });
  };

  succHandleShareB = (response: { data: [] }) => {
    this.setState({ openShared: false }, () => {
      this.getBuildingDetails();
    });
  };

  getUnitList = async () => {
    const companyId = this.props.companyId;
    this.getUnitListId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/apartment_list?society_management_id=${companyId}&per_page=5&page=${
        this.state.page
      }&search_unit=${this.state.searchQuery}&owner_name${this.state.searchQuery}&status=${
        this.state.status === "-" ? "" : this.state.status
      }`,
    });
  };

  handleUnitList = (responseJson: {
    apartment_managements: { data: [] };
    meta: { pagination: { current_page: number; total_count: number; total_pages: number } };
  }) => {
    if (responseJson && responseJson.apartment_managements) {
      this.setState({
        unitList: responseJson.apartment_managements.data,
        unitPagination: {
          current_page: responseJson.meta.pagination?.current_page,
          total_count: responseJson.meta?.pagination?.total_count,
          total_pages: responseJson.meta?.pagination?.total_pages,
        },
      });
    }
  };

  getBuildJurisdictionById = async (id: number | string) => {
    this.getBuildingJurisdictionIdApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleBuildingJurisdictionById = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data;
      this.setState({
        newBuildingJurisdiction: {
          name: jurisdiction.attributes.jurisdiction_name,
          currency: jurisdiction.attributes.currency.currency,
          timezone: jurisdiction.attributes.timezone,
          measurement: jurisdiction.attributes.unit_of_measurement,
          formula: jurisdiction.attributes.formula,
          mobileLength: jurisdiction.attributes.mobile_number_length,
          contract: this.handleContract(jurisdiction.attributes.contracts),
        },
      });
    }
  };

  getAllJurisdiction = async () => {
    this.GetJurisdictionCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions`,
    });
  };

  handleAllJurisdictionB = (responseJson: {
    jurisdictions: { data: { id: number | string; attributes: { jurisdiction_name: string } }[] };
  }) => {
    if (responseJson && responseJson.jurisdictions) {
      this.setState({
        allJurisdiction: responseJson.jurisdictions.data.map(
          (item: { id: number | string; attributes: { jurisdiction_name: string } }) => ({
            label: item.attributes.jurisdiction_name,
            value: item.id,
          })
        ),
      });
    }
  };

  handleDeleteIndividualBuilding = () => {
    this.setState({ loading: true, isDeleteComplexOpen: false }, async () => {
      const companyId = this.props.companyId;
      this.DeleteIndividualBuildingCallId = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_society_management/company_employee/complexes/${companyId}`,
      });
    });
  };

  deleteIndividualBuildingResponse = () => {
    this.props.navigation.navigate("ComplexListing");
  };

  handleBuildOpenSubscription = () => {
    this.setState({ subscriptionModal: true, openOptions: false }, () => {
      if (this.state.subscriptionId) {
        this.getBuildingSubscriptionDetails();
      }
    });
  };

  getBuildingSubscriptionDetails = async () => {
    this.GetSubscriptionDetailCallId = await apiCall({
      endPoint: `bx_block_custom_user_subs/subscriptions/${this.state.subscriptionId}`,
      method: "GET",
      contentType: "application/json",
    });
  };

  getSubscriptionDetailResponse = (responseJson: {
    data: { attributes: { expiry_date: string; status: string; ends_in_days: string } };
  }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        planExtendDetail: {
          day: responseJson.data.attributes.ends_in_days,
          status: responseJson.data.attributes.status,
          expiryData: responseJson.data.attributes.expiry_date,
        },
        loading: false,
      });
    }
  };

  handleBuildingManageJurisdiction = async () => {
    const companyId = this.props.companyId;
    const formData = new FormData();
    formData.append("jurisdiction_society[jurisdiction_id]", this.state.newJurId);
    formData.append("jurisdiction_society[society_management_id]", `${companyId}`);
    this.getBuildManageJurisdictionApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_society_management/company_employee/jurisdiction_societies`,
      body: formData,
    });
  };

  handleManageJurisdictionSuccB = () => {
    this.setState({ jurisdicationModal: false }, () => {
      this.getBuildingDetails();
    });
  };

  upgradeBuildingSubscription = async (values: SubBuildingExtendDetails) => {
    const raw = JSON.stringify({
      paid_extension: values.isPaid === "true",
      reasion: values.reason,
      expiry_date: values.date,
      subscription_id: this.state.subscriptionId,
      payment_reference_number: values.refNumber,
    });

    this.UpgradeSubApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_custom_user_subs/subscription_extend_requests`,
      body: raw,
    });
  };

  handleUpgradeSubscriptionResponse = () => {
    this.setState({ loading: false }, () => {
      this.getBuildingDetails();
      this.getBuildingSubscriptionDetails();
    });
  };

  handleBuildingExtendSubscription = async (values: BuildingPlanDetail) => {
    const raw = JSON.stringify({
      data: {
        payment_reference_number: values.refNumber,
        plan_id: values.planId,
      },
    });

    this.ExtendBuildingPlanApiCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_user_subs/upgrade_subscription_plan/${this.state.subscriptionId}`,
      body: raw,
    });
  };

  handleBuildingExtendSubResponse = () => {
    this.setState({ loading: false }, () => {
      this.getBuildingDetails();
      this.getBuildingSubscriptionDetails();
    });
  };

  nextBuildingImage = () => {
    this.slider.slickNext();
  };

  previousBuildingImage = () => {
    this.slider.slickPrev();
  };

  handleBuildingOpenOptions = () => {
    this.setState({ openOptions: !this.state.openOptions });
  };

  handleBuildOpenJurisdication = () => {
    this.setState(
      {
        jurisdicationModal: true,
        openOptions: false,
        newJurId: "",
        newBuildingJurisdiction: {
          name: "",
          currency: "",
          timezone: "",
          measurement: "",
          formula: "",
          mobileLength: "",
          contract: "",
        },
      },
      () => {
        this.getAllJurisdiction();
      }
    );
  };

  uploadGuideFile: any;
  uploadBuildingFile: any;
  uploadResolveFile: any;
  uploadRoleFile: any;

  handleIncidentPagination = (e: any, value: any) => {
    this.setState({
      page: value,
    });
  };

  handleCloseJurisdication = () => {
    this.setState({
      jurisdicationModal: false,
      newJurId: "",
      newJurisdictionDetails: [],
      newJur: "",
    });
  };

  uploadImages: any;

  handleBuildUpgradePlan = () => {
    this.setState({ upgradesubscriptionModal: true, openOptions: false });
  };

  handleBuildCloseUpgradeSubscription = () => {
    this.setState({ upgradesubscriptionModal: false });
  };

  handleBuildCloseSubscription = () => {
    this.setState({ subscriptionModal: false });
  };

  handleDeactivateComplexDialog = () => {
    this.setState({ isDeleteComplexOpen: !this.state.isDeleteComplexOpen, openOptions: false });
  };

  confirmBuildingText = (t: any) => {
    return this.state.userType !== "coo"
      ? t("Upon confirming the deletion of the individual building, the request will be sent to the COO for approval")
      : t("Upon confirming the deletion of the individual building, individual building will not show on platform.");
  };

  handleBOpenUploadModal = () => {
    this.setState({ uploadModal: true });
  };

  handleBCloseUploadModal = () => {
    this.setState({ uploadModal: false });
  };

  handleBTabChange = (event: any, newValue: number) => {
    this.setState({ tabNo: newValue });
  };

  handleAddBuildingList = () => {
    this.setState({ openShared: true });
  };

  handleUnits = () => {
    const companyId = this.props.companyId;
    this.props.navigation.history?.push(`/CompanyEmployee/complex/add-unit/${companyId}`);
  };

  handleFilterDataKeyUp = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery }, async () => {
      await this.getUnitList();
    });
  };

  uploadFile: any;

  handleSharedAreaNavigation = (id: number | string) => {
    this.props.navigation.navigate("SharedAreaDetails", {
      id: id,
      details: "BuildingDetailsPage",
      cId: this.props.companyId,
    });
  };

  handleShareClose = () => {
    this.setState({ openShared: false });
  };

  handleToggleBuildingMapDialog = () => {
    this.setState({ isOpenMapOpen: !this.state.isOpenMapOpen });
  };

  handleBuildingViewPlanDialog = () => {
    this.setState({ isBuildingViewPlanOpen: !this.state.isBuildingViewPlanOpen });
  };

  handleOpenBuildingPlanDetails = () => {
    this.setState({ isBuildingViewPlanOpen: true, openOptions: false }, () => {
      this.getBuildingPlanDetails();
    });
  };

  getBuildingPlanDetails = async () => {
    this.GetBuildingPlanDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_plan?complex_id=${this.props.companyId}`,
    });
  };

  handlePlanDetailsResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        selectedBuildingPlan: {
          title: responseJson.plan_detail.title,
          description: responseJson.plan_detail.description,
        },
        planExtendDetail: {
          status: responseJson.status,
          day: responseJson.day_to_expire,
          expiryData: responseJson.expiration_date,
        },
      });
    }
  };
}
// Customizable Area End
