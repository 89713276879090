// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import CompEmpComplexBuildingBlockStep4Controller, {
  Props,
  SharedDetails,
  StyleObject,
} from "./CompEmpComplexBuildingBlockStep4Controller.web";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { Upload_Icon, CloseIcon as DeleteImageIcon } from "../assets";
import Loader from "../../../../components/src/Loader.web";

class CompEmpComplexBuildingBlockStep4 extends CompEmpComplexBuildingBlockStep4Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box>
          <Box className="complex complex_building_block_step4">
            <Typography className="complex_building_block_step4" style={webStyle.mainHeading}>
              {t("Complex")}
            </Typography>
          </Box>
          <Box>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "25px" }}>
              <Box className="complex_building_block_step4">
                <Typography className="List bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
                  {t("List of Shared Area")}
                </Typography>
              </Box>
              <Typography className="Liststep complex_building_block_step4" style={{ fontSize: "16px" }}>
                {t("Step")} {this.props.step + 1} {t("of")} 5
              </Typography>
            </Box>
            <Grid spacing={4} container style={{ marginTop: "15px" }}>
              {this.state.facilityList.map((facility: SharedDetails, i: number) => {
                return (
                  <Grid className="buildingBlockStep6 complex_building_block_step4" item xs={4} key={i}>
                    <Box style={webStyle.facilityCard} className="complex_building_block_step4">
                      <span
                        style={webStyle.facilityTitle}
                        className="bold-text facility-title"
                        onClick={() => this.handleEditSharedArea(facility)}
                      >
                        {facility.attributes.name}
                      </span>
                      <CloseIcon
                        onClick={() => this.handleDeleteFacility(facility.id)}
                        className="delete-shared-facility-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
              <Box style={{ marginTop: "20px", border: "1px solid #2B6FED", borderRadius: "8px" }}>
                <Box
                  className="add-new-share-btn bold-text"
                  style={webStyle.addFacilityBtn}
                  onClick={this.handleSharedFacilityDialog}
                >
                  {t("+ ADD NEW SHARED AREA")}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className="step-four-buttons"
          style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "10px" }}
        >
          <Grid style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("Prev")}</span>
            </Button>
          </Grid>
          <Grid className="step-four-next-button" style={{ marginTop: "30px" }}>
            <Button style={webStyle.buttonSave} onClick={this.props.handleNext}>
              <span className="bold-text">{t("Next")}</span>
            </Button>
          </Grid>
        </Box>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isFacilityOpen}
          fullWidth
          maxWidth="md"
          className="edit-profile complex-shared-area-dialog"
        >
          <DialogHeader
            t={t}
            title={this.state.editID ? t("Edit Shared Area") : t("Add New Shared Area")}
            onClose={this.handleSharedFacilityDialog}
          />
          <Formik
            initialValues={this.state.facilityValues}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            onSubmit={(values, { resetForm }) => this.handleSubmitForm(values, resetForm)}
          >
            {({ values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} className="commonForms">
                  <DialogContent dividers>
                    <Grid container spacing={2} className="edit-building">
                      {!this.state.editID && (
                        <Grid item xs={12}>
                          <NormalTextField
                            name="sharedFacility"
                            t={t}
                            language={language}
                            onChange={handleChange}
                            value={values.sharedFacility}
                            isLabel={true}
                            label="Shared Facility's Name"
                            onBlur={handleBlur}
                          />
                          {ErrorHandler(t, touched.sharedFacility, errors.sharedFacility)}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <InputLabel>{t("Upload Photos")}</InputLabel>
                        <Grid container spacing={4}>
                          <Grid item xs={3}>
                            <Box onClick={() => this.uploadImage.click()} className="upload-photo">
                              <img src={Upload_Icon} alt="area-images" />
                            </Box>
                            <input
                              onBlur={handleBlur}
                              style={{ display: "none" }}
                              accept="image/*"
                              type="file"
                              ref={(ref) => (this.uploadImage = ref)}
                              className="complex-input-upload-photos"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files = e.target.files;
                                if (files) {
                                  setFieldValue("images", files);
                                }
                              }}
                              name="photos"
                              multiple
                            />
                          </Grid>
                          {[...values.images].map((image: any, index: number) => {
                            return (
                              <Grid item xs={3} key={index}>
                                <Box className="building-image">
                                  <img
                                    onClick={() => {
                                      const remainImage = [...values.images].filter(
                                        (_: any, idx: number) => idx !== index
                                      );
                                      setFieldValue("images", remainImage);
                                    }}
                                    className="delete-image"
                                    src={DeleteImageIcon}
                                  />
                                  <img src={typeof image === "string" ? image : URL.createObjectURL(image)} alt="" />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {ErrorHandler(t, touched.images, errors.images)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextArea
                          name="Details"
                          t={t}
                          isLabel={true}
                          onBlur={handleBlur}
                          label={t("Details")}
                          value={values.Details}
                          onChange={handleChange}
                          language={language}
                        />
                        {ErrorHandler(t, touched.Details, errors.Details)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextField
                          name="totalArea"
                          language={language}
                          value={values.totalArea}
                          t={t}
                          isLabel={true}
                          label={t("Total Area")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          startAdornment={<InputAdornment position="start">{t(this.props.measurement)}</InputAdornment>}
                        />
                        {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>{t("Reservation Fees (Per hour)")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            onChange={handleChange}
                            className="input-with-icon"
                            placeholder={t("Reservation Fees (Per hour)")}
                            value={values.fees}
                            onBlur={handleBlur}
                            fullWidth
                            name="fees"
                            startAdornment={<InputAdornment position="start">{t(this.props.currency)}</InputAdornment>}
                          />
                        </Box>
                        {ErrorHandler(t, touched.fees, errors.fees)}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>{t("Floor Plan")}</InputLabel>
                        <Box className="floor-plan-box">
                          <input
                            className="floor-plan-pdf"
                            style={{ display: "none" }}
                            type="file"
                            onBlur={handleBlur}
                            name="floorDocument"
                            accept=".pdf"
                            ref={(ref) => (this.uploadFile = ref)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const files = e.target.files;
                              if (files) {
                                setFieldValue("floorDocument", files[0]);
                              }
                            }}
                          />
                          {!values.floorDocument ? (
                            <span className="placeholder">{t("Floor Plan")}</span>
                          ) : (
                            <Chip
                              onDelete={() => setFieldValue("floorDocument", null)}
                              label={values.floorDocument.name}
                            />
                          )}
                          <img
                            className="shared-floor-upload"
                            onClick={() => this.uploadFile.click()}
                            src={Upload_Icon}
                          />
                        </Box>
                        {ErrorHandler(t, touched.floorDocument, errors.floorDocument)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button onClick={this.handleSharedFacilityDialog} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" style={{ margin: "0" }} className="add-button">
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  }
}

const webStyle: StyleObject = {
  buttonSave: {
    width: "177px",
    height: "50px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "uppercase",
  },
  prevButton: {
    color: "#2B6FED",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "uppercase",
    width: "177px",
    height: "50px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  mainHeading: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "25px",
  },
  facilityCard: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E4E4",
    borderRadius: "12px",
    padding: "15px",
  },
  facilityTitle: {
    color: "#181D25",
    fontSize: "18px",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  addFacilityBtn: {
    color: "#2B6FED",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "10px 25px",
    cursor: "pointer",
  },
};

export default withTranslation()(CompEmpComplexBuildingBlockStep4);
// Customizable Area End
