// Customizable Area Start
import React from "react";
import { Container, withStyles, Grid, Card, IconButton, Link } from "@material-ui/core";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import OwnerBuildingsController, { Props } from "./OwnerBuildingsController.web";
import { withTranslation } from "react-i18next";
import "./style.css";
import { Document, BuildingLogo, ManagementChat, ManagementPhone, ManagementEmail } from "./assets";
import { BuildingApartmentStyle } from "./BuildingApartmentStyle.web";
import Slider from "react-slick";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { withRouter } from "react-router";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { handleLink } from "../../../components/src/UtilFunction.web";

const settings = {
  infinite: false,
  slidesToShow: 5,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

class OwnerBuildings extends OwnerBuildingsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.complexDetails}>
          <Grid container className="owner-building-container">
            <Grid item xs={12} md={7}>
              <Box className="owner-building-box">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <Link href={`/ComplexDetails/${this.state.complexId}`}>
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                    </Link>
                    <span className="bold-text">{t("Building Info & Rules")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="details-box">
                    <Box className="heading-box">
                      <Box className="heading-top">
                        <img src={this.state.buildingData.logo} alt="" />
                        <h4 className="bold-text">{this.state.buildingData.buildingName || "-"}</h4>
                      </Box>
                      <Box className="heading-bottom">
                        <Box className="heading">
                          <h4 className="bold-text">{t("About")}</h4>
                          <Link
                            href={`https://maps.google.com/?q=${this.state.buildingData.lat},${this.state.buildingData.long}`}
                            target="_blank"
                          >
                            <span className="bold-text">{t("See building on map")}</span>
                          </Link>
                        </Box>
                        <p>{this.state.buildingData.aboutBuilding || "-"}</p>
                        <Grid container>
                          <Grid item xs={6} className="info-item">
                            <span>{t("Building Area")}</span>
                            <p>
                              {Number(this.state.buildingData.buildingArea).toLocaleString() || "-"}{" "}
                              {this.state.buildingData.areaMeasure}
                            </p>
                          </Grid>
                          <Grid item xs={6} className="info-item">
                            <span>{t("Total Floor")}</span>
                            <p>{this.state.buildingData.totalFloor || 0}</p>
                          </Grid>
                          <Grid item xs={6} className="info-item">
                            <span>{t("Total Units")}</span>
                            <p>
                              {this.state.buildingData.totalUnit || 0} {t("Units")}
                            </p>
                          </Grid>
                          <Grid item xs={6} className="info-item">
                            <span>{t("City")}</span>
                            <p>{this.state.buildingData.city || "-"}</p>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    <Box className="images-box">
                      <h4 className="bold-text">{t("Photos")}</h4>
                      <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                        {this.state.buildingData.photos.length === 0 && <div>{t("No photos available")}</div>}
                        {this.state.buildingData.photos.map((photo: any, index: number) => {
                          return (
                            <div key={index} className="building-photo-box">
                              <img src={photo.url} alt="building-photo" />
                            </div>
                          );
                        })}
                      </Slider>
                    </Box>

                    <Box className="management-team building-team-box">
                      <h4 className="bold-text">{t("Management Team")}</h4>
                      <Grid container spacing={2}>
                        {this.state.managementTeam.length === 0 && (
                          <Grid item xs={12}>
                            <Card className="team-member-box building-team-empty">{t("No data available")}</Card>
                          </Grid>
                        )}
                        {this.state.managementTeam.map((team: any) => {
                          const role_building: any[] = team.roles.map((role: any) => role.name);
                          const role_building_ar: any[] = team.roles.map((role: any) => role.name_ar);
                          return (
                            <Grid item xs={6} key={team.id} className="building-team-grid">
                              <Card className="team-member-box">
                                <img src={team.user_profile?.url} alt="profile-url" />
                                <h4 className="team-name">{team.full_name}</h4>
                                <p>{languageCondition(language, role_building_ar.toString(), role_building.toString())}</p>
                                <Box className="icons building-team-contact-box">
                                  <img src={ManagementChat} alt="chat" className="building-team-chat" onClick={() => this.createBuildingTeamChatRoom(team.id)}/>
                                  <div />
                                  <span onClick={() => handleLink(`tel:${team.phone_number}`)}>
                                    <img src={ManagementPhone} alt="phone" />
                                  </span>
                                  <div />
                                  <span onClick={() => handleLink(`mailto:${team.email}`)}>
                                    <img src={ManagementEmail} alt="email" />
                                  </span>
                                </Box>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>

                    <Box className="document-boxes owner-building-document-box">
                      <h4 className="bold-text owner-building-document-heading">{t("Documents")}</h4>
                      <Grid container spacing={2} className="owner-building-document-container">
                        <Grid item xs={12}>
                          <Card 
                            className="document document-policy building-policy-document"
                            onClick={() => {
                              sessionStorage.setItem("isBuilding", "true");
                              sessionStorage.setItem("complexId", this.state.complexId);
                              sessionStorage.setItem("buildingId", this.state.buildingId);
                              this.props.navigation.navigate("BuildingDocumentList", { name: "Policy" });
                            }}
                          >
                            <img src={Document} alt="document-policy" />
                            <h6 className="bold-text">{t("Policy")}</h6>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card 
                            className="document building-resolution-document" 
                            onClick={() => {
                              sessionStorage.setItem("isBuilding", "true");
                              sessionStorage.setItem("complexId", this.state.complexId);
                              sessionStorage.setItem("buildingId", this.state.buildingId);
                              this.props.navigation.navigate("BuildingDocumentList", { name: "Resolutions" });
                            }} 
                          >
                            <img src={Document} alt="resolution-image" />
                            <h6 className="bold-text">{t("Resolution")}</h6>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="right-block right-image building-right-image" display={{ xs: "none", md: "flex" }}>
                <img src={BuildingLogo.default} className="building-logo" alt="building-info-photo" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(BuildingApartmentStyle)(withRouter(OwnerBuildings)));
// Customizable Area End
