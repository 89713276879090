// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CEScheduleMeetingController, { Props } from "./CEScheduleMeetingController.web";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { Formik } from "formik";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { BlueCheckIcon, DateIcon, Dots, GreyCheckIcon, SearchIconImage, TimeIcon } from "../assets";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { Menu } from "@szhsin/react-menu";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import Loader from "../../../../components/src/Loader.web";
// Customizable Area End

class CEScheduleMeetingDialog extends CEScheduleMeetingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getCountryListAPI();

    await this.getMeetingGroupList();
    await this.getMeetingGroupUserList();
    await this.getGroupComplexList();

    await this.getScheduleByList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (!prevProps.isEditFormOpen && this.props.isEditFormOpen) {
      this.getOwnerIdsList(this.props.formValue.complex);
      this.getResidentIdsList(this.props.formValue.complex);

      this.getCityFilterList(this.props.formValue.country);
      this.getComplexFilterList(this.props.formValue.country, this.props.formValue.city);
      this.getBuildingFilterList(this.props.formValue.complex);
    }
  }

  handleGroupHeader = (t: any, isEditGroupOpen: boolean) => {
    if (isEditGroupOpen) {
      return `${t("Edit Meeting Group")}`;
    } else {
      return `${t("Create Meeting Group")}`;
    }
  };

  handleComplexLabel = (value: number) => {
    if (value !== 2) {
      return "Complex";
    } else {
      return "Individual Building";
    }
  };

  handleGroupTickIcon = (condition: boolean) => {
    if (condition) {
      return <img src={BlueCheckIcon} alt="blue" />;
    } else {
      return <img src={GreyCheckIcon} alt="grey" />;
    }
  };

  handleFormSubmit = (isEditFormOpen: boolean, values: any, meetingId: any) => {
    if (isEditFormOpen) {
      this.editScheduleMeeting(values, meetingId);
    } else {
      this.createScheduleMeeting(values);
    }
  };

  handleActiveClass = (condition: boolean) => (condition ? "active" : "");

  handleOwnerGroupSelect = (role: any, setFieldValue: any, ownerIds: any) => {
    if (role.includes("owner")) {
      const roles = role.filter((item: any) => item !== "owner");
      setFieldValue("role", roles);
      setFieldValue("ownerIds", []);
    } else {
      setFieldValue("role", [...role, "owner"]);
      setFieldValue("ownerIds", ownerIds);
    }
  };

  handleResidentGroupSelect = (role: any, setFieldValue: any, residentIds: any) => {
    if (role.includes("resident")) {
      const roles = role.filter((item: any) => item !== "resident");
      setFieldValue("role", roles);
      setFieldValue("residentIds", []);
    } else {
      setFieldValue("role", [...role, "resident"]);
      setFieldValue("residentIds", residentIds);
    }
  };

  handleGroupSelect = (meetingGroupIds: any, setFieldValue: any, id: any) => {
    if (meetingGroupIds.includes(id.toString())) {
      const filterGroupId = meetingGroupIds.filter((item: any) => item !== id);
      setFieldValue("meetingGroupIds", filterGroupId);
    } else {
      setFieldValue("meetingGroupIds", [...meetingGroupIds, id]);
    }
  };

  handleDisplayGroup = (condition: boolean) => (condition ? "none" : "flex");

  handleSelectUser = (condition: boolean, user: any) => (condition ? this.addUser(user.id) : this.removeUser(user.id));

  handleSelectAllUser = (condition: boolean) => (condition ? this.selectAllUser() : this.removeAllUser());
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, i18n, isOpenForm, onFormClose, isEditFormOpen, formValue, meetingId }: Props = this.props;
    // @ts-ignore
    const language = i18n.language;

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <Dialog open={isOpenForm} scroll="paper" fullWidth maxWidth="md" dir={languageCondition(language, "rtl", "ltr")}>
          <DialogHeader t={t} title={isEditFormOpen ? "Edit New Meeting" : "Create New Meeting"} onClose={onFormClose} />
          <Formik
            enableReinitialize={true}
            initialValues={formValue}
            validationSchema={this.meetingValidation}
            onSubmit={(values, { resetForm }) => {
              this.handleFormSubmit(isEditFormOpen, values, meetingId);
              onFormClose();
              resetForm();
            }}
          >
            {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalSelect
                            t={t}
                            language={language}
                            label="Meeting Type"
                            value={values.type}
                            name="type"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("type", value);
                            }}
                            option={[
                              { label: "Regular Meeting", value: "regular_meeting" },
                              { label: "GA Meeting", value: "ga_meeting" },
                            ]}
                          />
                          {ErrorHandler(t, touched.type, errors.type)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalTextField
                            t={t}
                            language={language}
                            label="Title"
                            value={values.title}
                            name="title"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("title", value);
                            }}
                            isLabel={false}
                          />
                          {ErrorHandler(t, touched.title, errors.title)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <NormalTextField
                            t={t}
                            language={language}
                            label="Date"
                            value={values.date}
                            name="date"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("date", value);
                            }}
                            icon={DateIcon}
                            isLabel={false}
                            type="text"
                            onFocus={(e: any) => (e.target.type = "date")}
                          />
                          {ErrorHandler(t, touched.date, errors.date)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <NormalTextField
                            t={t}
                            language={language}
                            label="Time"
                            value={values.time}
                            name="time"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("time", value);
                            }}
                            icon={TimeIcon}
                            isLabel={false}
                            onFocus={(e: any) => (e.target.type = "time")}
                            type="text"
                          />
                          {ErrorHandler(t, touched.time, errors.time)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <NormalSelect
                            t={t}
                            language={language}
                            value={values.country}
                            name="country"
                            label="Country"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("country", value);
                              this.getCityFilterList(value);
                            }}
                            option={[...this.state.countriesList]}
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <NormalSelect
                            t={t}
                            language={language}
                            value={values.city}
                            name="city"
                            label="City"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("city", value);
                              this.getComplexFilterList(values.country, value);
                            }}
                            option={this.state.cityList}
                          />
                          {ErrorHandler(t, touched.city, errors.city)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalSelect
                            t={t}
                            language={language}
                            value={values.isComplex}
                            name="isComplex"
                            label="Complex / Individual Building"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              this.handleFilterComplexList(value);
                              setFieldValue("isComplex", value);
                            }}
                            option={[
                              { label: "Complex", value: 1 },
                              { label: "Individual Building", value: 2 },
                            ]}
                          />
                          {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalSelect
                            t={t}
                            language={language}
                            label={this.handleComplexLabel(values.isComplex)}
                            value={values.complex}
                            name="complex"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("complex", value);
                              this.getBuildingFilterList(value);

                              this.getOwnerIdsList(value);
                              this.getResidentIdsList(value);
                            }}
                            option={this.state.filterComplexList}
                          />
                          {ErrorHandler(t, touched.complex, errors.complex)}
                        </FormControl>
                      </Grid>
                      {values.isComplex !== 2 && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <NormalSelect
                              t={t}
                              language={language}
                              label="Building"
                              value={values.building}
                              name="building"
                              onBlur={handleBlur}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue("building", value);
                              }}
                              option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                            />
                            {ErrorHandler(t, touched.building, errors.building)}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalTextField
                            t={t}
                            language={language}
                            label="Place"
                            value={values.place}
                            name="place"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("place", value);
                            }}
                            isLabel={false}
                          />
                          {ErrorHandler(t, touched.place, errors.place)}
                        </FormControl>
                      </Grid>
                      {isEditFormOpen && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <NormalSelect
                              t={t}
                              language={language}
                              label="Scheduled By"
                              value={values.scheduledBy}
                              name="scheduledBy"
                              onBlur={handleBlur}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue("scheduledBy", value);
                              }}
                              option={this.state.scheduleByList}
                            />
                            {ErrorHandler(t, touched.scheduledBy, errors.scheduledBy)}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <NormalTextArea
                            t={t}
                            language={language}
                            label="Agenda"
                            value={values.agenda}
                            name="agenda"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("agenda", value);
                            }}
                          />
                          {ErrorHandler(t, touched.agenda, errors.agenda)}
                        </FormControl>
                      </Grid>
                      {isEditFormOpen && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <NormalSelect
                              t={t}
                              language={language}
                              label="Status"
                              value={values.status}
                              name="status"
                              onBlur={handleBlur}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldValue("status", value);
                              }}
                              option={[
                                { label: "Scheduled", value: "scheduled" },
                                { label: "Completed", value: "completed" },
                                { label: "Cancelled", value: "cancelled" },
                              ]}
                            />
                            {ErrorHandler(t, touched.status, errors.status)}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {/* Create Audience */}
                        <FormControl fullWidth className="add-meeting">
                          <Box className="create-audience" style={{ marginTop: 0 }}>
                            <p>{t("Select Meeting Attendees")}</p>
                            <span onClick={this.handleMeetingGroupDialog}>{t("+ Create New Group")}</span>
                          </Box>
                          <Box className="attendee-box">
                            <Box
                              className={`${this.handleActiveClass(values.role.includes("owner"))} attendee`}
                              onClick={() => this.handleOwnerGroupSelect(values.role, setFieldValue, this.state.ownerIdsList)}
                              style={{ display: this.handleDisplayGroup(values.complex === "") }}
                            >
                              {this.handleGroupTickIcon(values.role.includes("owner"))}
                              <span className="bold-text">{t("All Owner")}</span>
                            </Box>
                            <Box
                              className={`${this.handleActiveClass(values.role.includes("resident"))} attendee`}
                              onClick={() => this.handleResidentGroupSelect(values.role, setFieldValue, this.state.residentIdsList)}
                              style={{ display: this.handleDisplayGroup(values.complex === "") }}
                            >
                              {this.handleGroupTickIcon(values.role.includes("resident"))}
                              <span className="bold-text">{t("All Resident")}</span>
                            </Box>
                            {this.state.groupList.map((group: any) => {
                              return (
                                <Box
                                  key={group.id}
                                  onClick={() => this.handleGroupSelect(values.meetingGroupIds, setFieldValue, group.id)}
                                  className={`${this.handleActiveClass(values.meetingGroupIds.includes(group.id.toString()))} attendee custom-group`}
                                >
                                  {this.handleGroupTickIcon(values.meetingGroupIds.includes(group.id.toString()))}
                                  <span className="bold-text">{group.attributes.group_name}</span>
                                  <Box className="CE-schedule-group-menu">
                                    <Menu direction="top" align="end" menuButton={<img src={Dots} alt="|" />} className="attendee-menu">
                                      <MenuItem className="ce-edit-group" onClick={() => this.handleOpenEditGroup(group)}>
                                        {t("Edit")}
                                      </MenuItem>
                                      <MenuItem className="ce-delete-group" onClick={() => this.handleDeleteGroup(group.id)}>
                                        {t("Delete")}
                                      </MenuItem>
                                    </Menu>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                          {ErrorHandler(t, touched.meetingGroupIds, errors.meetingGroupIds)}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={onFormClose}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t(isEditFormOpen ? "Save" : "Create")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          open={this.state.isGroupDialogOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
          dir={languageCondition(language, "rtl", "ltr")}
          className="select-meeting scheduled-meeting meeting-group-dialog"
        >
          <DialogHeader t={t} title={this.handleGroupHeader(t, this.state.isEditGroupOpen)} onClose={this.handleMeetingGroupDialog} />
          <DialogContent dividers className="filter filter-head-box" style={{ padding: "16px 24px", marginTop: 0 }}>
            <FilterSelect
              t={t}
              language={language}
              option={[
                { label: "All", value: 0 },
                { label: "Complex", value: 1 },
                { label: "Individual Building", value: 2 },
              ]}
              value={this.state.filterGroup.isComplex}
              onChange={(e: any) => this.handleGroupFilterChange("isComplex", e.target.value)}
              style={{ fontWeight: "bold", color: "black" }}
            />
            <FilterSelect
              t={t}
              language={language}
              label="Select Complex"
              option={[{ label: "All", value: "all" }, ...this.state.groupFilterComplexList]}
              value={this.state.filterGroup.complex}
              onChange={(e: any) => this.handleGroupFilterChange("complex", e.target.value)}
            />
            {this.state.filterGroup.isComplex !== 2 && (
              <FilterSelect
                t={t}
                language={language}
                label="Select Building"
                option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                value={this.state.filterGroup.building}
                onChange={(e: any) => this.handleGroupFilterChange("building", e.target.value)}
              />
            )}
            <FilterSelect
              t={t}
              language={language}
              label="Select Type"
              option={[
                { label: "All", value: "all" },
                { label: "Chairman", value: "Chairman" },
                { label: "Manager", value: "Manager" },
                { label: "Owner", value: "Owner" },
                { label: "Owner Resident", value: "Owner Resident" },
                { label: "Tenant", value: "Tenant" },
                { label: "Manager", value: "Manager" },
                { label: "Property Manager", value: "Property Manager" },
              ]}
              value={this.state.filterGroup.userType}
              onChange={(e: any) => this.handleGroupFilterChange("userType", e.target.value)}
            />
            <Button className="filter-button" startIcon={<img src={SearchIconImage} />} onClick={this.handleGroupFilter}>
              {t("Search")}
            </Button>
          </DialogContent>
          <DialogContent>
            <TableContainer style={{ height: "400px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox checked={this.state.isSelectAllUser} onChange={(e: any) => this.handleSelectAllUser(e.target.checked)} />
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }} className="bold-text">
                      {t("Name")}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }} className="bold-text">
                      {t("Building")}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }} className="bold-text">
                      {t("Unit No.")}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }} className="bold-text">
                      {t("Floor Number")}
                    </TableCell>
                    <TableCell className="bold-text">{t("User Type")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.groupUserList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>{t("No User Available!!")}</TableCell>
                    </TableRow>
                  )}
                  {this.state.groupUserList.map((user: any) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell>
                          <Checkbox
                            checked={this.state.selectedUser.includes(user.id)}
                            onChange={(e: any) => this.handleSelectUser(e.target.checked, user)}
                          />
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>{user.attributes.full_name}</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {user.attributes.building_management.map((building: any) => building.name).toString()}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>{user.attributes.unit_number.toString()}</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>{user.attributes.floor_number.toString()}</TableCell>
                        <TableCell className="group-user-type">{user.attributes.user_type.toString()}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions className="dialog-button-group">
            <div className="selected-meeting">
              <h4 className="bold-text">
                <span>{this.state.selectedUser.length} </span>
                {t("user selected")}
              </h4>
            </div>
            <div className="button-group" style={{ gap: "10px" }}>
              <NormalTextField
                value={this.state.groupName}
                onChange={(e: any) => this.setState({ groupName: e.target.value })}
                t={t}
                language={language}
                label="Group Name"
                isLabel={false}
                style={{ padding: "6px 12px" }}
              />
              {this.state.isEditGroupOpen ? (
                <Button
                  className="add-button"
                  disabled={this.state.selectedUser.length === 0 || !this.state.groupName}
                  style={{ height: "46px", margin: "0" }}
                  onClick={this.handleEditGroup}
                >
                  {t("Edit Group")}
                </Button>
              ) : (
                <Button
                  className="add-button"
                  onClick={this.handleCreateGroup}
                  disabled={this.state.selectedUser.length === 0 || !this.state.groupName}
                  style={{ height: "46px", margin: "0" }}
                >
                  {t("Create Group")}
                </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEScheduleMeetingDialog)));
// Customizable Area End
