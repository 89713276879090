import React from "react";
// Customizable Area Start
import {

} from "@material-ui/core";

// Customizable Area End

import SubscriptionReportsDetailsController, {
    Props,
} from "./SubscriptionReportsDetailsController.web";

// Customizable Area Start
export default class SubscriptionReportsDetails extends SubscriptionReportsDetailsController {
    constructor(props: Props) {
        super(props);
    }

// Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
            return (
                // Customizable Area Start
                <>

                </>
                // Customizable Area End
            );
        }
}

// Customizable Area Start

// Customizable Area End