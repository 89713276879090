import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SubscriptionReportsDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
       
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
}
