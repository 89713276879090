// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { handleDocumentFile } from "../../../../components/src/helperFunctions.web";

export interface Props {
  step: number;
  handleNext: any;
  handleBack: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  jurisdiction: string | number;
  complexID: string | number;
}

type DocumentType = "policy" | "guidelines" | "roles" | "resolutions" | "buildingPlans";

interface DocumentListValue {
  roles: { title: string; file: File }[];
  policy: { title: string; file: File }[];
  resolutions: { title: string; file: File }[];
  buildingPlans: { title: string; file: File }[];
  guidelines: { title: string; file: File }[];
}

interface S {
  loading: boolean;

  documents: DocumentListValue;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep3Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexDocumentsApiCallId: string = "";
  UploadComplexDocumentApiCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      documents: {
        policy: [],
        roles: [],
        buildingPlans: [],
        guidelines: [],
        resolutions: [],
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetComplexDocumentsApiCallId:
          return this.handleGetDocumentResponse(responseJsonBlock);
        case this.UploadComplexDocumentApiCallId:
          return this.handleComplexDocumentResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getComplexDocuments();
    });
  }

  getComplexDocuments = async () => {
    this.GetComplexDocumentsApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_my_document/building_documents/coo_building_doc?society_management_id=${
        this.props.complexID
      }`,
    });
  };

  handleGetDocumentResponse = async (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const buildingPlan = responseJson.data.Building_plan;
      const policy = responseJson.data.Policy;
      const guideline = responseJson.data.Guideline;
      const role = responseJson.data.Role;
      const resolution = responseJson.data.Resolution;
      let guideline_file = [];
      let policy_file = [];
      let resolution_file = [];
      let role_file = [];
      let buildingPlan_file = [];
      if (guideline.length > 0) {
        guideline_file = await handleDocumentFile(guideline);
      }
      if (buildingPlan.length > 0) {
        buildingPlan_file = await handleDocumentFile(buildingPlan);
      }
      if (policy.length > 0) {
        policy_file = await handleDocumentFile(policy);
      }
      if (role.length > 0) {
        role_file = await handleDocumentFile(role);
      }
      if (resolution.length > 0) {
        resolution_file = await handleDocumentFile(resolution);
      }
      this.setState({
        loading: false,
        documents: {
          roles: role_file,
          policy: policy_file,
          resolutions: resolution_file,
          buildingPlans: buildingPlan_file,
          guidelines: guideline_file,
        },
      });
    }
  };

  handleFileChanges = (type: DocumentType) => {
    const inputFilePortable = document.createElement("input");
    inputFilePortable.multiple = true;
    inputFilePortable.accept = ".pdf, .doc, .docx";
    inputFilePortable.type = "file";
    inputFilePortable.click();
    inputFilePortable.onchange = (event: any) => {
      this.handleFilePortable(event, type);
    };
  };

  handleFilePortable = (event: React.ChangeEvent<HTMLInputElement>, type: DocumentType) => {
    const files = event.target.files;
    if (files) {
      const documentList = this.state.documents[type];
      const allFiles = [...files].map((file: File) => ({ title: file.name.split(".")[0], file: file }));
      this.setState({ documents: { ...this.state.documents, [type]: [...documentList, ...allFiles] } });
    }
  };

  handleRemoveFile = (type: DocumentType, index: number) => {
    const documentList = this.state.documents[type];
    if (documentList.length !== 0) {
      documentList.splice(index, 1);
      this.setState({ documents: { ...this.state.documents, [type]: documentList } });
    }
  };

  handleComplexSubmitDocument = () => {
    this.setState({ loading: true }, async () => {
      const formData = new FormData();

      this.state.documents.buildingPlans.forEach((item: { title: string; file: File }, index: number) => {
        formData.append(`building_plan[${index}]`, item.file);
        formData.append(`building_plan_titles[${index}]`, item.title);
      });
      this.state.documents.policy.forEach((item: { title: string; file: File }, index: number) => {
        formData.append(`policy[${index}]`, item.file);
        formData.append(`policy_titles[${index}]`, item.title);
      });
      this.state.documents.guidelines.forEach((item: { title: string; file: File }, index: number) => {
        formData.append(`guideline[${index}]`, item.file);
        formData.append(`guideline_titles[${index}]`, item.title);
      });
      this.state.documents.roles.forEach((item: { title: string; file: File }, index: number) => {
        formData.append(`roles[${index}]`, item.file);
        formData.append(`roles_titles[${index}]`, item.title);
      });
      this.state.documents.resolutions.forEach((item: { title: string; file: File }, index: number) => {
        formData.append(`resolution[${index}]`, item.file);
        formData.append(`resolution_titles[${index}]`, item.title);
      });

      this.UploadComplexDocumentApiCallId = await apiCall({
        endPoint: `bx_block_my_document/building_documents/bulk_update?society_management_id=${this.props.complexID}`,
        method: "PUT",
        body: formData,
      });
    });
  };

  handleComplexDocumentResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      this.props.handleNext();
    }
  };
}
// Customizable Area End
