// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import CETotalSentInviteController, { Props } from "./CETotalSentInviteController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
// Customizable Area End

class CETotalSentInvite extends CETotalSentInviteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  invitationDetailValue = (t: (val: string) => string, label: string, value: string, xs: any) => {
    return (
      <Grid item xs={xs}>
        <Typography variant="body2" className="send-label-p">{t(label)}</Typography>
        <Typography variant="body2" className="sent-value-p" style={{ color: "grey" }}>
          {value}
        </Typography>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n } = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation total-sent-head-box">
              <Typography variant="body1" className="total-invite-heading">
                <span data-test-id="com-navigation" onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>
                  {t("Community Management")}
                </span>{" "}
                /{" "}
                <span data-test-id="req-navigation" onClick={() => navigationFunc(this.props, "CERequestManagement")}>
                  {t("Requests Management")}
                </span>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }} className="total-invite-head">
                  {t("Total Sent Invitations")}
                </Box>
              </Typography>
              <Box className="sub-heading-box total-invite-sub-head">
                <Typography variant="h5" className="bold-text total-invite-text">
                  {t("Total Sent Invitations")}
                </Typography>
              </Box>
            </Box>
            <Box className="filter-head-box total-invite-filter-box">
              {this.state.complexType === "Complex" && (
                <FilterSelect
                  t={t}
                  language={language}
                  value={this.state.search.selectedBuilding}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                  label={t("Select Building")}
                  option={[{ label: "All", value: "all" }, ...this.state.buildingsDataList]}
                />
              )} 
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.search.selectedUnit}
                option={[{ label: "All", value: "all" }, ...this.state.unitDataList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedUnit")}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedStatus}
                onChange={(e: any) => this.handleChangeValue(e, "selectedStatus")}
                label={t("Select Status")}
                option={[{ label: "All", value: "all" }, ...this.state.statusData]}
              />
              <Button
                className="action-search-btn action-user-search"
                startIcon={<SearchIcon />}
                onClick={this.getTotalSentInvitationList}
              >
                {t("Search")}
              </Button>
            </Box>

            <Grid className="table-box-card">
              <Grid item sm={12} md={12} xs={12}>
                <Box className="table-top">
                  <h4 className="bold-text">
                    {t("Complex")} {this.state.complexName && `: ${this.state.complexName}`}
                  </h4>
                  <SearchInput
                    onChange={this.onChange}
                    t={t}
                    language={language}
                    label={t("Search by Name")}
                    data-test-id="search-query"
                  />
                </Box>
                <Divider />
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Name")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Unit Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Invitation sent on")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Phone Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Onboarding Status")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.totalSentInvitationList.map((item: any, i: number) => (
                      <TableRow>
                        <TableCell align={languageCondition(language, "right", "left")}>{i + 1}</TableCell>
                        <TableCell
                          align={languageCondition(language, "right", "left")}
                          className="ellipse-one"
                          title=""
                        >
                          {item.attributes.full_name}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                          {item.attributes?.apartment_management?.apartment_name || "-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {this.dateFormat(item.attributes.created_at)}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {item.attributes.phone_number}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {" "}
                          <span style={webStyle[item.attributes.status]}>{this.getStatus(item.attributes.status)}</span>
                        </TableCell>
                        <TableCell
                          align={languageCondition(language, "right", "left")}
                          className={LanguageAttributeHandler(language, "meeting-menu", "")}
                        >
                          <Menu
                            menuButton={
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            }
                          >
                            <MenuItem onClick={() => this.viewUserRequest(item)}>{t("View Details")}</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider />
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{this.state.totalSentInvitationList.length}</span>{" "}
                    {t("of")} <span className="total-page">{this.state.pagination?.total_count}</span> {t("results")}
                  </p>
                  {this.state.pagination && (
                    <Pagination
                      onChange={(event: any, value: any) => {
                        this.handleFilterPage(value);
                      }}
                      siblingCount={2}
                      variant="outlined"
                      shape="rounded"
                      count={this.state.pagination?.total_pages}
                      page={this.state.pagination?.current_page}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isViewInvitationOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title="View Details" onClose={this.handleViewInvitationModal} />
          <Box style={webStyle.subContent} className="select-meeting">
            <Grid container spacing={2}>
              {this.invitationDetailValue(t, "Name", this.state.invitationDetail.name, 4)}
              {this.invitationDetailValue(t, "Country", this.state.invitationDetail.country, 4)}
              {this.invitationDetailValue(t, "City", this.state.invitationDetail.city, 4)}
              {this.invitationDetailValue(t, "Complex", this.state.invitationDetail.complex, 4)}
              {this.invitationDetailValue(t, "Unit Number", this.state.invitationDetail.unit, 4)}
              {this.invitationDetailValue(t, "Sent On", this.state.invitationDetail.sentOn, 4)}
              {this.invitationDetailValue(t, "Phone Number", this.state.invitationDetail.phone, 4)}
              {this.invitationDetailValue(t, "Role", this.state.invitationDetail.role, 4)}
              {this.invitationDetailValue(t, "Status", this.state.invitationDetail.status, 4)}
            </Grid>
          </Box>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  Pending: {
    background: "rgb(252, 132, 52, 0.2)",
    color: "rgb(252, 132, 52)",
  },
  Accept: {
    background: "rgb(30,198,91,0.2)",
    color: "rgb(30 198 91)",
    padding: "5px 15px",
    borderRadius: "20px",
  },
  Decline: {
    background: "#FFEAEA",
    borderRadius: "20px",
    padding: "5px 15px",
    color: "#F21717",
  },
  subContent: { padding: "20px 30px" },
};

export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CETotalSentInvite)));
// Customizable Area End
