// Customizable Area Start
import React from "react";
import { Box, Button, Container, Grid, Typography, withStyles } from "@material-ui/core";
import CEPendingJoinRequestController, { Props } from "./CEPendingJoinRequestController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import PendingReqCard from "../../../../components/src/CommunityManagementCard/PendingReqCard.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { CheckIcon, CrossIcon } from "../assets";
import SearchIcon from "@material-ui/icons/Search";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
// Customizable Area End

class CEPendingJoinRequest extends CEPendingJoinRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes,language }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation">
              <Typography variant="body1" className="pending-join-heading-box">
                <span onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>{t("Community Management")}</span> /{" "}
                <span onClick={() => navigationFunc(this.props, "CERequestManagement")}>{t("Requests Management")}</span> /{" "}
                <Box component="span" style={{ color: "blue" }} className="pending-join-head-text">
                  {t("Pending Join Request")}
                </Box>
              </Typography>
              <Box className="sub-heading-box pending-join-head-box">
                <Typography variant="h5" className="bold-text pending-join-heading">
                  {t("Pending Join Request")}
                </Typography>
              </Box>
            </Box>
            <Box className="filter-head-box">
              {this.state.complexType === "Complex" && (
                <FilterSelect
                  t={t}
                  language={language}
                  label={t("Select Building")}
                  value={this.state.filter.selectedBuilding}
                  option={[{ label: "All", value: "all" }, ...this.state.buildingsList]}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                />
              )} 
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.filter.selectedUnit}
                option={[{ label: "All", value: "all" }, ...this.state.unitList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedUnit")}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select User Type")}
                value={this.state.filter.selectedType}
                option={[{ label: "All", value: "all" }, ...this.state.userList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedType")}
              />
              <Button startIcon={<SearchIcon />} className="await-search-filter-btn" onClick={this.getPendingList}>
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ margin: "15px 0 30px" }}>
              {this.state.pendingJoinRequestList.length === 0 && (
                <Grid item xs={12}>
                  <Box style={webStyle.noDataFound}>{t("No request found")}</Box>
                </Grid>
              )}
              {this.state.pendingJoinRequestList.map((item: any, index: any) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <PendingReqCard item={item} t={t} handleReject={this.handleReject} handleAccept={this.handleAccept} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </CEHeaderComponentWeb>

        <AlertDialog
          IsOpen={this.state.isRejectDialogOpen}
          Image={CrossIcon}
          CloseDialog={() => {}}
          Header={t("Reject Join Request")}
          Content={`<p>${t("Are you sure you want to reject invitation request")} <br/> ${t(
            "received from"
          )} <b>${this.state.pendingJoinRequest.name}</b> ${t("for Unit")} <b>${this.state.pendingJoinRequest.unit}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Reject")}
          DeclineFun={() => {this.handleReject()}}
          AcceptFun={() => {this.handleSubmit("Rejected",this.state.pendingJoinRequest?.id)}}
        />

        <AlertDialog
          IsOpen={this.state.isAcceptDialogOpen}
          Image={CheckIcon}
          CloseDialog={() => {}}
          Header={t("Accept Join Request")}
          Content={`<p>${t("Are you sure you want to accept the join request")} <br/> ${t(
            "received from"
          )} <b>${this.state.pendingJoinRequest.name}</b> ${t("for Unit")} <b>${this.state.pendingJoinRequest.unit}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Accept")}
          DeclineFun={() => {this.handleAccept()}}
          AcceptFun={() => {this.handleSubmit("Accepted",this.state.pendingJoinRequest?.id)}}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  noDataFound: {
    display: "flex",
    marginTop:"5%",
    justifyContent: "center",
    alignItems: "center",
  }
}
// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CEPendingJoinRequest)));
// Customizable Area End
