import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  withStyles,
  Button,
  IconButton,
  Select,
  MenuItem,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  InputBase,
  Box,
  Grid,
  Backdrop,
  Fade,
  Modal, DialogContent, DialogActions, Dialog, Paper,
} from "@material-ui/core";
import CENominationController, { Props } from "./CENominationController.web";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withTranslation } from "react-i18next";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import { SearchIconImage } from "../../../StoreCredits/src/assets";
import { dashBoardActions} from "../../../InvoiceBilling/src/chairmanUIStyles";
import AlertError from "../../../../components/src/AlertError.web";
import AlertSuccess from "../../../../components/src/AlertSuccess.web";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { withRouter } from "react-router-dom";
import {languageCondition} from "../../../../components/src/ConditionManager.web";
import {warningIcon} from "../assets";
import CECreateNominationWeb from "./CECreateNomination.web";
import {newMember} from "../../../user-profile-basic/src/assets";
import moment from "moment/moment";
import Tip from "../../../../components/src/CommonComponents/Tip";
// Customizable Area End

class CENomination extends CENominationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  checkResponse = (total_nomination:any) => {
    return total_nomination || 0
  }
  // Customizable Area Start

  handleBoxButton=(t:any)=>{
    return(
      <Box className="create-meeting CENomination">
      <Tip
        text={
          this.state.nominationAccessstate
            ? "Start Nomination Process"
            : "You don't have permission to start nomination"
        }
      >
        <Button className="StartNominationProcess" style={{opacity:this.state.nominationAccessstate?1:0.5}} disabled={!this.state.nominationAccessstate} data-test-id="StartNominationProcess" onClick={this.handleNominationModal}>{t("Start Nomination Process")}</Button>
      </Tip>
      </Box>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t, classes,i18n }: any = this.props;
    const {language} = i18n
    return (
        <div>
          <Container className={classes.reportList} style={{marginTop:"35px",minHeight:"95vh"}}>
            <Box className="navigation">
              <Box style={{width:"100%"}}>
                <Typography variant="body1">
                  {t("Home")} /{" "}
                  <Box className="CENomination" component="span" style={{ color: "blue" }}>
                    {t("Nomination")}
                  </Box>
                </Typography>
                <Box className="top-bar" style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                  <Typography variant="h5" className="sub-heading bold-text CENomination" style={{ fontSize: "28px" }}>
                    {t("Nomination")}
                  </Typography>
                  {this.handleBoxButton(t)}
                </Box>
                <Typography variant="body1" className="CENomination" style={{fontSize: "14px",marginBottom:"10px"}}>
                  {t("This screen allows you to see the upcoming scheduled dates for the nomination for a new Chairman and Vice Chairman for the complex and Building's General Assembly")}
                </Typography>
              </Box>
            </Box>
            <Box className={languageCondition(language, "arabic-grid top-bar CENomination", "top-bar CENomination")} style={{alignItems:"flex-start"}}>
              {
                !this.state.totalNominationListing &&
              <Grid container className="filter CENomination">
                <Select
                    displayEmpty
                    className="select-input selectCompanyNomination"
                    value={this.state.filterCompany}
                    onChange={(e: any) => this.setState({ filterCompany: e.target.value,filterCountry:"",filterCity:"",filterComplex:"" })}
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Company")}</MenuItem>
                  <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                  {this.state.companyList.map((item: any, key: any) => {
                    return (
                        <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                          {item.company_name}
                        </MenuItem>
                    );
                  })}
                </Select>
                <Select
                    displayEmpty
                    value={this.state.societyType}
                    className="select-input selectCompanyNomination"
                    onChange={(e: any) => this.setState({ societyType: e.target.value,filterCountry:"",filterCity:"",filterComplex:"" })}
                >
                  <MenuItem value="complex" className={languageCondition(language, "select-arabic-menu", "")}>{t("Complex")}</MenuItem>
                  <MenuItem value="individualBuilding" className={languageCondition(language, "select-arabic-menu", "")}>{t("Individual Building")}</MenuItem>
                </Select>
                <Select
                    displayEmpty
                    value={this.state.filterCountry}
                    onChange={(e: any) => this.setState({ filterCountry: e.target.value,filterCity:"",filterComplex:"" },()=> {
                      this.getCityList();
                      this.getComplexList();
                    })}
                    className="select-input CENomination selectCountry"
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "CENomination")}>{t("Select Country")}</MenuItem>
                  <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "CENomination")}>{t("All")}</MenuItem>
                  {this.state.countryList.map((item: any, key: any) => {
                    return (
                        <MenuItem key={key} value={item} className={languageCondition(language, "select-arabic-menu", "")}>
                          {item}
                        </MenuItem>
                    );
                  })}
                </Select>
                <Select
                    displayEmpty
                    className="select-input selectCityNomination"
                    value={this.state.filterCity}
                    onChange={(e: any) => this.setState({ filterCity: e.target.value,filterComplex:"" },()=> {this.getComplexList()})}
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select City")}</MenuItem>
                  <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                  {this.state.cityList.map((item: any, key: any) => {
                    return (
                        <MenuItem key={key} value={item.city} className={languageCondition(language, "select-arabic-menu", "")}>
                          {item.city}
                        </MenuItem>
                    );
                  })}
                </Select>
                <Select
                    displayEmpty
                    className="select-input selectComplexNomination"
                    value={this.state.filterComplex}
                    onChange={(e: any) => this.setState({ filterComplex: e.target.value })}
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Complex")}</MenuItem>
                  <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                  {this.state.complexList.map((item: any, key: any) => {
                    return (
                        <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                          {item.attributes.name}
                        </MenuItem>
                    );
                  })}
                </Select>
                <Button startIcon={<img style={{marginLeft:"10px"}} src={SearchIconImage} />} className="searchNomination" onClick={this.handleSearch}>
                  {t("Search")}
                </Button>
              </Grid>
              }
            </Box>
            {
                this.state.upcomingNominationListing &&
                <Grid className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className={"bold-text report-ExpenseReport CE-Nomination"}>{t("List of Upcoming Nominations")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                            placeholder={t("Search By Complex Name")}
                            className="search searchByNomination"
                            value={this.state.filterSearch}
                            onChange={(e:any) => this.setState({filterSearch:e.target.value},()=>this.getNominationList())}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead className="CE-Nomination">
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationCountry" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Country")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationCity" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("City")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationComplex" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Complex Name")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationChairman" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Chairman")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationProcess" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Process Starts in")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text CE-NominationRequiredDate" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Required Date")}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.nominationList.length > 0 ? (
                            this.state.nominationList.map((item: any, key: any) => {
                              return (
                                  <TableRow key={key}>
                                    <TableCell className="ellipse" align={languageCondition(language,"right","left")}>{item.attributes.country || "-"}</TableCell>
                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.city || "-"}</TableCell>
                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.complex_name}</TableCell>
                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.chairman}</TableCell>
                                    <TableCell align={languageCondition(language,"right","left")}>{moment(item.attributes.start_date).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell align={languageCondition(language,"right","left")}>{moment(item.attributes.start_date).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell className="invoice-receipts-menu expenseReportMenu"  align={languageCondition(language,"right","left")}>
                                      <Menu
                                          menuButton={
                                            <IconButton className="emailClick" onClick={() => this.setState({nominationId:item.id})}>
                                              <MoreVertIcon />
                                            </IconButton>
                                          }
                                      >
                                        <MenuItem onClick={this.sentEmailReminder}>
                                          {t("Send email reminder")}
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                              );
                            })
                        ) : (
                            <TableRow>
                              <TableCell align={languageCondition(language,"right","left")} colSpan={7}>{t("No Upcoming Nominations Available")}</TableCell>
                            </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <PaginationModule
                          pagination={this.state.pagination}
                          page={this.state.page}
                          handlePagination={this.handleNominationPagination}
                      />
                    </Box>
                  </Grid>
                </Grid>
            }
            {
                this.state.noUpcomingNomination &&
                <Paper elevation={2} style={{marginRight:"10px",width:"100%",height:"80vh",display:'flex',alignItems:'center',justifyContent:'center',flexDirection:"column",boxShadow:"4px 0px 14px #d9dce2",marginBottom:"100px"}}>
                  <Box style={{backgroundColor:"#FC8434",width:"50px",height:"50px",borderRadius:"50px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img src={newMember} />
                  </Box>
                  <Box style={{textAlign:"center",marginTop:"15px"}}>
                    <Typography>
                      {t("There are no Chairman and Vice Chairman Nominations recorded into system.")}
                    </Typography>
                    <Typography>
                      {t("Please tap on the below button to start nomination process.")}
                    </Typography>
                  </Box>
                  <AddNominationButton className="bold-text CENomination" style={{marginTop:"20px",fontSize:"14px",padding:"8px 15px",width:"260px"}} onClick={()=>this.setState({openModal:true})}>{t("Start Nomination Process")}</AddNominationButton>
                </Paper>
            }
            {
                this.state.totalNominationListing &&
                <Grid container spacing={2}>
                  {this.state.totalNominationList.map((item: any, key: any) => {
                    return (
                        <Grid key={key} item xs={12} sm={12} md={6} className="CENomination">
                          <Paper elevation={3} className="CENomination" style={{
                            backgroundColor: "white",
                            padding: "20px 30px",
                            borderRadius: "12px",
                            cursor: "pointer"
                          }} onClick={() => this.props.history.push(`/CompanyEmployee/CENominationDetails?id=${item.id}`)}>
                            <Grid container spacing={1} className="CENomination">
                              <Grid item xs={9} style={{display: "flex", alignItems: "center", height: "60px"}}
                                    className="CENomination">
                                <Typography className="bold-text textwrap CENominationTitle" style={{
                                  fontWeight: "bold",
                                  display: 'flex',
                                  fontSize: "18px"
                                }}>{item.attributes.title}</Typography>
                              </Grid>
                              <Grid item xs={3} className="CENomination"
                                    style={{display: 'flex', alignItems: "center", justifyContent: "flex-end"}}>
                                {
                                    item.attributes.status === "closed" &&
                                    <Typography variant="subtitle2"
                                                className={"statusOngoingRed bold-text CENomination"} style={{
                                      fontSize: "12px",
                                      padding: "6px,10px"
                                    }}>{t("Closed")}</Typography>
                                }
                                {
                                    item.attributes.status === "active" &&
                                    <Typography variant="subtitle2"
                                                className={"statusOngoingGreen bold-text CENomination"} style={{
                                      fontSize: "12px",
                                      padding: "6px,10px"
                                    }}>{t("Active")}</Typography>
                                }
                                {
                                    item.attributes.status === "upcoming" &&
                                    <Typography variant="subtitle2"
                                                className={"statusOngoingBlue bold-text CENomination"} style={{
                                      fontSize: "12px",
                                      padding: "6px,10px"
                                    }}>{t("Upcoming")}</Typography>
                                }
                              </Grid>
                              <Grid item xs={6}>
                                <Box>
                                  <Typography variant="subtitle1" color="textSecondary"
                                              className="CENominationComplexName" style={{
                                    fontSize: "14px",
                                    color: "rgba(24,29,37,0.5)"
                                  }}>{t("Complex Name")}:</Typography>
                                  <Typography variant="subtitle1" color="textPrimary"
                                              className="CENominationComplexData"
                                              style={{fontSize: "16px"}}>{item.attributes.complex_name}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box>
                                  <Typography variant="subtitle1" color="textSecondary" className="CENominationDuration"
                                              style={{
                                                fontSize: "14px",
                                                color: "rgba(24,29,37,0.5)"
                                              }}>{t("Duration")}:</Typography>
                                  <Typography variant="subtitle1" color="textPrimary" className="CENominationData"
                                              style={{fontSize: "16px"}}>{moment(item.attributes.start_date).format("DD-MMM-YYYY")} to {moment(item.attributes.end_date).format("DD-MMM-YYYY")}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box>
                                  <Typography variant="subtitle1" color="textSecondary"
                                              className="CENominationTotalNomination" style={{
                                    fontSize: "14px",
                                    color: "rgba(24,29,37,0.5)"
                                  }}>{t("Total Nomination")}: </Typography>
                                  <Typography variant="subtitle1" color="textPrimary"
                                              className="CENominationTotalNominationData"
                                              style={{fontSize: "16px"}}>{this.checkResponse(item.attributes.total_nomination)} {t("Members")}</Typography>
                                </Box>
                              </Grid>
                              {
                                  item.attributes.stage === "Nomination Started" &&
                                  <Grid item xs={12} className={"nominationBlueBG"}
                                        style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <Typography variant="body1"
                                                style={{width: "100%", fontSize: "13px", padding: "5px 10px"}}
                                                className="nominationBlueText Stage1">{t(item.attributes.stage)}</Typography>
                                  </Grid>
                              }
                              {
                                  item.attributes.stage === "Upcoming Nomination" &&
                                  <Grid item xs={12} className={"nominationBlueBG"}
                                        style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <Typography variant="body1"
                                                style={{width: "100%", fontSize: "13px", padding: "5px 10px"}}
                                                className="nominationBlueText Stage2">{t(item.attributes.stage)}</Typography>
                                  </Grid>
                              }
                              {
                                  item.attributes.stage === "Nomination Closed" &&
                                  <Grid item xs={12} className={"nominationGrayBG"}
                                        style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <Typography variant="body1"
                                                style={{width: "100%", fontSize: "13px", padding: "5px 10px"}}
                                                className="nominationGrayText Stage3">{t(item.attributes.stage)}</Typography>
                                  </Grid>
                              }
                              {
                                  item.attributes.stage === "Voting Started" &&
                                  <Grid item xs={12} className={"nominationOrangeBG"}
                                        style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <Typography variant="body1"
                                                style={{width: "100%", fontSize: "13px", padding: "5px 10px"}}
                                                className="nominationOrangeText Stage4">{t(item.attributes.stage)}</Typography>
                                  </Grid>
                              }
                              {
                                  item.attributes.stage === "Voting Closed" &&
                                  <Grid item xs={12} className={"nominationGrayBG"}
                                        style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <Typography variant="body1"
                                                style={{width: "100%", fontSize: "14px", padding: "5px 10px"}}
                                                className="nominationGrayText Stage5">{t(item.attributes.stage)}</Typography>
                                  </Grid>
                              }
                            </Grid>
                          </Paper>
                        </Grid>
                    )
                  })}
                </Grid>
            }
          </Container>
          <Modal
              data-test-id="modalopen"
              style={dashBoardActions.modal} open={this.state.openModal}
              className="createNominationModal" onClose={() => this.handleCloseModal()}
              closeAfterTransition BackdropComponent={Backdrop}
              BackdropProps={{timeout: 500}}
          >
            <Fade in={this.state.openModal} key="CE-Nomination">
              {/*@ts-ignore*/}
              <CECreateNominationWeb handleClose={() => this.handleCloseModal()}/>
            </Fade>
          </Modal>
          <AlertError
              show={this.state.showError}
              handleClose={() => this.setState({ showError: false, error: null })}
              message={this.state.error}
          />
          <AlertSuccess
              show={this.state.showSuccess}
              handleClose={() => this.setState({ showSuccess: false, successMessage: "" })}
              message={this.state.successMessage}
          />
        </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const AddNominationButton = withStyles((theme) => ({
  root: {
    color: "white", backgroundColor: "#2b6fed",
    fontWeight:"bold", borderRadius:"10px",
    width:"175px", height:"45px",
    padding:"5px 15px", fontSize:"14px",
    '&:hover': {backgroundColor: "#2b6fed"},
    '&:disabled': {backgroundColor:"#2b6fed36", color:"#fdfefe"}
  },
}))(Button);

// @ts-ignore
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(CENomination)));
// Customizable Area End