// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  docName: string;
  complexId: string;
  documentId: string;
  details: string;

  title: string;
  file: any;
}

interface SS {
  id: any;
}

export default class ComplexDocumentViewController extends BlockComponent<Props, S, SS> {
  GetDocumentDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      docName: "",
      complexId: "",
      documentId: "",
      details: "",

      title: "",
      file: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.GetDocumentDetailsCallId) {
        this.getDocumentDetailsResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    const complex_id = this.props.navigation.getParam("id");
    const document_name = this.props.navigation.getParam("name");
    const document_id = this.props.navigation.getParam("dId");
    const details = this.props.navigation.getParam("details");
    this.setState({ docName: document_name, complexId: complex_id, documentId: document_id, details: details }, () => {
      this.getDocumentDetails();
    });
  }

  getDocumentDetails = async () => {
    const { documentId, complexId } = this.state;
    this.GetDocumentDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_my_document/building_documents/${documentId}`,
    });
  };

  getDocumentDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const document = responseJson.data;
      this.setState({ title: document.attributes.title, file: document.attributes.images[0].url });
    }
  };
}

// Customizable Area End
