// Customizable Area Start
import React from "react";
import RealEstateCreateCompanyController, { Props } from "./RealEstateCreateCompanyController.web";
import RealEstateCompanyForm from "./RealEstateCompanyForm.web";
import { withRouter } from "react-router-dom";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class RealEstateCreateCompany extends RealEstateCreateCompanyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;
    const match: any = this.props.match;
    const id = match?.params?.id;

    return (
      <>
        <CEHeaderComponentWeb>
          <RealEstateCompanyForm navigation={navigation} id={"company-register-form"} companyId={id} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(RealEstateCreateCompany);
// Customizable Area End
