import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";

interface FormValue {
  type:string;
  country:string;
  city:string;
  individualBuilding:string;
  complex:string;
  building:string;
  unit:string;
  fullName:string;
  emailID:string;
  countryCode:string;
  phoneNumber:string;
}

// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  formValue: FormValue;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isResendDialogOpen: boolean;
  isDeleteDialogOpen:boolean;
  waitingAcceptenceList:any;
  selectedForResend:any;
  filter:any;
  countryList: any[],
  cityList: any[],
  complexList:any[];
  buildingsList:any[];
  unitList:any[];
  userList:any[];
  companyList:any[];
  complexBuildingFilterList:any[];
  formValue: FormValue;
  deleteItemId:string;
  hideField:boolean;

  complexId: string | number;
  complexType: string;

  showError: boolean;
  error: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEAwaitAcceptController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetWaitingAcceptList:any;
  GetMemberInvitationList:any;
  showAllUserAPICallId:any;
  GetCountryList:any;
  GetCityList:any;
  GetComplexListId:any;
  GetAllBuildingsDataCallId:any;
  GetAllUnitDataCallId:any;
  GetCompanyListCallId:any;
  DeleteWaitingAcceptCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isResendDialogOpen: false,
      isDeleteDialogOpen:false,
      hideField:false,
      waitingAcceptenceList:[],
      companyList:[],
      selectedForResend:"",
      filter: {
        selectedType:"",
        selectedCountry:"",
        selectedCity:"",
        selectedComplex:"",
        selectedBuilding:"",
        selectedCompany:"",
        selectedIndividualBuilding:"",
        selectedUnit:"",
      },
      countryList: [],
      cityList: [],
      complexList: [],
      buildingsList: [],
      unitList: [],
      userList: [],
      formValue: {
        type:"",
        country:"",
        city:"",
        individualBuilding:"",
        complex:"",
        building:"",
        unit:"",
        fullName:"",
        emailID:"",
        countryCode:"+1",
        phoneNumber:""
      },
      complexBuildingFilterList: [
        {
          label: "Complex",
          value: 'complex'
        },
        {
          label: "Individual Building",
          value: 'indiviualBuilding'
        },
      ],
      deleteItemId:"",

      complexId: "",
      complexType: "",

      showError: false,
      error: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetWaitingAcceptList:
          this.handlegetWaitingAcceptenceList(responseJson);
          break;
        case this.GetCompanyListCallId:
          this.handleGetCompanyList(responseJson);
          break;
        case this.GetCountryList:
          this.handleCountryResponse(responseJson)
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
          break;
        case this.GetComplexListId:
          this.handleComplexList(responseJson)
          break;
        case this.GetCityList:
          this.handleCityResponse(responseJson)
          break;
        case this.GetAllBuildingsDataCallId:
          this.handleBuildingList(responseJson)
          break;
        case this.GetAllUnitDataCallId:
          this.handleUnitList(responseJson)
          break;
        case this.GetMemberInvitationList:
          this.handleMemberResendInvitation(responseJson);
          break;
        case this.DeleteWaitingAcceptCallId:
          this.handleMemberDeleteInvitation(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const complex_type = this.props.navigation.getParam("complex");
    const complex_id = this.props.navigation.getParam("id");
    this.setState({ complexId: complex_id, complexType: complex_type }, () => {
      this.getWaitingAcceptenceList();
      this.getAllUser();
      this.getCountryListTable();

      if(this.state.complexType === "Complex") {
        this.getBuildingsDataList(complex_id);
      } else {
        this.getUnitDataList(complex_id, "");
      }
    });
  }
  handleHideField=async(type:any)=>{
    const selectedUser=this.state.userList.filter(element => element.value===type)
    this.setState({ hideField: selectedUser[0].label !== "Owner Resident" && selectedUser[0].label !== "Owner" && selectedUser[0].label !== "Tenant" && selectedUser[0].label !== "Property Manager"  });
  }
  handleMemberResendInvitation=(responseJson:any)=>{
    if (responseJson&& responseJson.message	) {
      this.setState({ showError: true, error: responseJson.message });
    }
  }
  handleFormSubmit=async(value:any)=>{
    let formData = new FormData();
    
    formData.append("member_invitation[full_name]", value.fullName);
    formData.append("member_invitation[email_address]", value.emailID);
    formData.append("member_invitation[phone_number]", value.countryCode + "-" + value.phoneNumber);
    formData.append("member_invitation[role_id]", value.type);
    formData.append("member_invitation[building_management_id]", value.building);
    formData.append("member_invitation[apartment_management_id]", value.unit);
    formData.append("member_invitation[society_management_id]",value.complex);
    
    this.GetMemberInvitationList = await apiCall({
      method: configJSON.sellerDetailsAPIMethodPOST,
      endPoint: configJSON.createMemberInvitationEndPoint+`?request_id=${this.state.selectedForResend?.id}`,
      body:formData
    });
  }
  getComplexList = async (city:any ) => {
    this.GetComplexListId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint+`?city=${city}`
    });
  };
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getCompanyList=async()=>{
    this.GetCompanyListCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getCompanyListEndPoint
    });
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getUserTypeEndPoint
    });
  };
  handleGetCompanyList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ companyList: responseJson.data.map((item: any) => 
        ({ label: item?.attributes?.company_name, value: item?.id }))}
      )
    }
  }
  handleMemberDeleteInvitation=(responseJson:any)=>{
    this.setState({isDeleteDialogOpen:false, loading:false})
    if(responseJson && responseJson.message){
      this.getWaitingAcceptenceList()
    }
  }
  handleAccept =async () => {
    this.setState({ loading: true })
    this.DeleteWaitingAcceptCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.sellerDetailsAPIMethodDELETE,
      endPoint: configJSON.deletedApiEndPoint+`/${this.state.deleteItemId}`,
    });
  }
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({  userList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.id }))})
    }
  }
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.id }))}
      )
    }
  }
  handleUnitList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ unitList: responseJson.data.map((item: any) => ({
        label: item.attributes.apartment_name,
        value: item.id
      }))});
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({
         buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
         countryList: responseJson?.data?.countries.map((item: any) => ({ label: item, value: item }))
      });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
         cityList: responseJson?.data?.cities.map((item: any) => ({ label: item, value: item })) });
    }
  };
  getWaitingAcceptenceList = () => {
    this.setState({ loading: true }, async () => {
      this.GetWaitingAcceptList = await apiCall({
        contentType: configJSON.sellerDetailsApiContentType,
        method: configJSON.getSellersAPIMethod,
        endPoint: configJSON.getwaitingAcceptenceEndPoint + `?role_id${this.getData(this.state.filter.selectedType)}&company${this.getData(this.state.filter.selectedCompany)}&country${this.getData(this.state.filter.selectedCountry)}&city${this.getData(this.state.filter.selectedCity)}&complex${this.getData(this.state.filter.selectedComplex || this.state.complexId)}&building_id${this.getData(this.state.filter.selectedBuilding)}&apartment_management_id${this.getData(this.state.filter.selectedUnit)}`,
      });
    })
  }
  getData = (value: any) => (value !== undefined && value !== "" && value !== "all" && value !== "undefined") ? `=${value}` : "";

  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataCallId = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getCityList = async (country: any) => {
    this.GetCityList = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`
    })
  }
 
  getUnitDataList = async (id:any, bId: any) => {
    this.GetAllUnitDataCallId= await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${id}&building_management_id=${bId}`,
    });
  };
  
  handleChangeValue=(event:any,keyState:any)=>{
    const value = event.target.value;
    if(keyState==="selectedCity"){
      this.getComplexList(event.target.value)
    } else if (keyState==="selectedCountry"){
      this.getCityList(event.target.value)
    } else if (keyState === "selectedBuilding") {
      this.getUnitDataList(this.state.complexId, value)
    } else if (keyState==="selectedComplex"){
      const id=event.target.value;
      this.getBuildingsDataList(id);
    } 
    this.setState({ filter: { ...this.state.filter, [keyState]:  event.target.value} })
  }

  handlegetWaitingAcceptenceList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        waitingAcceptenceList: responseJson.data || []
      })
    }
  }
  meetingValidation: any = Yup.object().shape({
    type: Yup.string().required("Required").matches(/\S/, "Required"),
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    fullName: Yup.string().required("Required").max(100, "Maximum length of Name should be 100 character").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    unit: Yup.mixed().test("unit", "Required", (value: any) => {
      if (!this.state.hideField && !value) {
          return false;
      }
      return true;
    }),
    building: Yup.mixed().test("unit", "Required", (value: any) => {
      if (!this.state.hideField && !value) {
          return false;
      }
      return true;
    }),
    phoneNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    individualBuilding: Yup.string().required("Required").matches(/\S/, "Required"),
    emailID: Yup.string().email("Invalid email format").required("Required"),
  });
 
  handleClose=()=>{
    this.setState({isResendDialogOpen:false})
  }
  handleCountryCodeValue=(value:any,keyState:any)=>{
    this.setState({ filter: { ...this.state.filter, [keyState]: value} })
  }
  handleDateFormat = (dateString: string) => {
      const p = dateString.split(/\D/g);
      return [p[2], p[1], p[0]].join("-")
  };
  handleDelete=(item?:any)=>{
    this.setState({ isDeleteDialogOpen: !this.state.isDeleteDialogOpen,  deleteItemId:item?.id,});
  }
  handleDialog = (item?:any) => {
    this.setState({
      selectedForResend :item ,
      isResendDialogOpen: !this.state.isResendDialogOpen,
      formValue: {
      type:item?.attributes?.role.id,
      country:item?.attributes?.country,
      city:item?.attributes?.city,
      individualBuilding:"complex",
      complex:item?.attributes?.complex?.id,
      building:item?.attributes?.building_management?.id || "",
      unit:item?.attributes?.apartment_management?.id	 || "",
      fullName:item?.attributes?.full_name,
      emailID:item?.attributes?.email_address,
      countryCode:item?.attributes?.phone_number?.split("-")[0],
      phoneNumber:item?.attributes?.phone_number?.split("-")[1]
    }},()=>{
      this.handleHideField(item?.attributes?.role.id.toString());
      this.getCityList(item?.attributes?.country);
      this.getComplexList(item?.attributes?.city);
      this.getBuildingsDataList(item?.attributes?.complex?.id);
      this.getUnitDataList(item?.attributes?.complex?.id, item?.attributes?.building_management?.id)
    })
  };
  // Customizable Area End
}
