import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    TableContainer,
    TableHead,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    Divider,
    styled,
    BoxProps,
    Input
} from "@material-ui/core";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
type TFunction = (value: string) => string;
import { logo } from "./assets";

// Customizable Area End

import GAMemberReportController, {
    Props,
} from "./GAMemberReportController.web";

// Customizable Area Start
export class GAMemberReport extends GAMemberReportController {
    constructor(props: Props) {
        super(props);
    }

// Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as React.ComponentType<BoxProps>
        const { t }: { t: TFunction } = this.props;
        // Customizable Area End
            return (
                // Customizable Area Start
                <CEHeaderComponentWeb>
                    <GAMemberReportCss>
                    <BoxView className="containerBox">
                            <BoxView className="navigation">
                                <Typography variant="body1" className="">
                                    <BoxView component="span">
                                        {t("Manage Subscriptions")}
                                    </BoxView>{" "}
                                    /{" "}
                                    <BoxView component="span">
                                        {t("Beth Murphy")}
                                    </BoxView>{" "}
                                    /{" "}<BoxView component="span" className="tabColor">
                                        {t("GA Member Usage Statistical Report")}
                                    </BoxView>
                                </Typography>
                            </BoxView>
                            <br />

                            {!this.state.subTable && !this.state.downloadTable && <BoxView >
                                <BoxView className="header-title">
                                    <Typography variant='h4' className="bold-text">{t("GA Member Usage Statistical Report")}</Typography>
                                    <Typography className="bold-text downloadButton" onClick={() => this.handleDownload()} data-test-id="downloadButtonId1">{t("DOWNLOAD")}</Typography>
                                </BoxView>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item>
                                        <Select
                                            displayEmpty
                                            className="selectmenu"
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            disableUnderline
                                            onChange={(event) => this.handleComplexIndividualChange(event)}
                                            id="selectMenu1"
                                            value={this.state.individualbuilding}
                                            data-test-id="complexIndividualId">
                                            <MenuItem value="" disabled id="selectStatusBudgetReport">
                                                {t("Select Complex")}
                                            </MenuItem>
                                            <MenuItem value="All">{t("All")}</MenuItem>
                                            {this.state.selectComplex?.length > 0 &&
                                                this.state.selectComplex?.map((item: { id: string; name: string }) => {                                                    
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <BoxView>
                                            <Select
                                                disableUnderline
                                                IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                                displayEmpty
                                                data-test-id="selectCountryId"
                                                className="selectmenu"
                                                value={this.state.selectedCountry}
                                                placeholder="Select Country"
                                                id="selectMenu2"
                                                onChange={(event) => this.handleSelectCountryChange(event)}
                                            >
                                                <MenuItem
                                                    value=""
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select Country")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="All"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                                {this.state.selectCountry?.length > 0 &&
                                                    this.state.selectCountry?.map((item: string) => {
                                                        return (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </BoxView>
                                    </Grid>
                                    <Grid item>
                                        <Select displayEmpty
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            data-test-id="selectCityId"
                                            value={this.state.selectedCity}
                                            disableUnderline
                                            className="selectmenu"
                                            id="selectMenu3"
                                            onChange={(event) => this.handleSelectCityChange(event)}
                                        >
                                            <MenuItem
                                                value=""
                                                disabled
                                                id="selectStatusBudgetReport"
                                            >
                                                {t("Select City")}
                                            </MenuItem>
                                            <MenuItem
                                                id="selectStatusBudgetReport"
                                                value="All"
                                            >
                                                {t("All")}
                                            </MenuItem>
                                            {this.state.selectCity?.length > 0 &&
                                                this.state.selectCity?.map((item: string) => {
                                                    return ( <MenuItem key={item} value={item}> {item} </MenuItem> );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className="mybtn"
                                            variant="contained"
                                            onClick={() => this.handleSerach()}
                                            data-test-id="saveButton"
                                        >
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                            {t("Search")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid className="table-box-card">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <BoxView className="sort-by">
                                            <h4 className="bold-text">{t("Usage Statistical Report")}</h4>
                                            <SearchInput
                                                data-test-id="filterId2"
                                                placeholder={t("Search")}
                                                t={t}
                                            />
                                        </BoxView>
                                        <Divider />
                                        <TableContainer component={Paper} className="table-container">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell className="bold-text">{t("Country")}</TableCell>
                                                        <TableCell className="bold-text">{t("City")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Complex/Building Name")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Occupancy Rate")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Registered User")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Rented Units")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Active Residents")}</TableCell>
                                                        <TableCell className= "bold-text">{t("Top 3 active Property Managers")}</TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                {this.state.gaMemberReport?.length ? <TableBody>
                                                    {this.state.gaMemberReport.map((item:{
                                                        id:number
                                                        attributes: {
                                                            city: string
                                                            country: string
                                                            complex_name: string
                                                            occupancy_rate:{
                                                                Total_units_percentage: string
                                                                Total_units_count:string
                                                            }
                                                            register_users: {
                                                                Total_registered_percentage: string
                                                                Total_registered_user: string
                                                            }
                                                            rent_occupancy_rate: {
                                                                Total_units_percentage: string
                                                                Total_units_count:string
                                                            }
                                                            top_3_property_manager:[]
                                                            resident_user_percentage: string
                                                        }
                                                    }, index:number) => (
                                                        <TableRow className="table-row" data-test-id="openSubscriotionReportId" onClick={() => this.handleSubscriotionReport(item.id)}>
                                                        <TableCell>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.country}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.city}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.complex_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.occupancy_rate.Total_units_count} 
                                                            ({item.attributes.occupancy_rate.Total_units_percentage}%)
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.register_users.Total_registered_user} 
                                                            ({item.attributes.register_users.Total_registered_percentage}%)
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.rent_occupancy_rate.Total_units_count}
                                                         ({item.attributes.rent_occupancy_rate.Total_units_percentage}%)
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.resident_user_percentage}%
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.attributes.top_3_property_manager.map((item: string) => item).join(', ') || '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}
                                                </TableBody> : <Typography className="tableError">Data Not Found</Typography>}
                                            </Table>
                                        </TableContainer>

                                        <BoxView className="table-bottom">
                                            <p>
                                                {t("Showing")} <span className="current-page">{this.state.gaMemberReport?.length}</span> {t("of")}{" "}
                                                <span className="total-page">{this.state.totalCount}</span>{" "}
                                                {t("results")}
                                            </p>
                                            <BoxView>
                                                <Pagination
                                                    count={this.state.totalPages}
                                                    id="PaginationModule"
                                                    shape="rounded"
                                                    variant="outlined"
                                                    style={{ marginRight: "20px" }}
                                                    data-test-id="paginationID"
                                                />
                                            </BoxView>
                                        </BoxView>
                                    </Grid>
                                </Grid>
                            </BoxView>}

                            {this.state.subTable && !this.state.downloadPage && <BoxView >
                                <BoxView className="header-title">
                                    <Typography variant='h4' className="bold-text">{t("GA Member Subscription Report")}</Typography>
                                    <Typography className="bold-text downloadButton" onClick={() => this.handleDownload()} data-test-id="downloadButtonId1">{t("DOWNLOAD")}</Typography>
                                </BoxView>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item>
                                    <Input
                                            disableUnderline
                                            value={this.state.date}
                                            onChange={(event)=> this.handleToDate(event)}
                                            type="text"
                                            data-test-id="dateId"
                                            placeholder={t("Select Date")}
                                            className="selectmenu"
                                            onFocus={(e:React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                                            inputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ExpandMoreIcon />
                                                    </InputAdornment>
                                                ),
                                            }
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            data-test-id="saveButton"
                                            onClick={() => this.handleDateSerach()}
                                            className="mybtn"
                                        >
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                            {t("Search")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid className="table-box-card">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <BoxView className="sort-by">
                                            <h4 className="bold-text">{t("Subscription Report")}</h4>
                                            <SearchInput
                                                t={t}
                                                placeholder={t("Search")}
                                                data-test-id="filterId2"
                                            />
                                        </BoxView>
                                        <Divider />
                                        <TableContainer className="table-container" component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell
                                                            className="bold-text" align="center">
                                                            {t("Subscription Date")}
                                                        </TableCell>
                                                        <TableCell className="bold-text"
                                                            align="center">{t("Plan")}
                                                        </TableCell>
                                                        <TableCell className="bold-text"
                                                            align="center">{t("Duration")}
                                                        </TableCell>
                                                        <TableCell className="bold-text" 
                                                            align="center">{t("Total Complexes")}
                                                        </TableCell>
                                                        <TableCell className="bold-text" 
                                                            align="center">{t("Total Indivual Building")}
                                                        </TableCell>
                                                        <TableCell className="bold-text" 
                                                            align="center">{t("Ends On")}
                                                        </TableCell>
                                                        <TableCell className="bold-text" 
                                                            align="center">{t("Status")}
                                                        </TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.gaMemberSubReport.map((item: {
                                                        attributes: {
                                                            plan: string
                                                            subscription_date: string
                                                            total_complex: string
                                                            duration: string
                                                            expiry_date: string
                                                            status: string
                                                            individual_building: string
                                                        }
                                                    }, index: number) => (
                                                        <>
                                                            <TableRow key={index} className="table-row">
                                                                <TableCell align="center">
                                                                    {index + 1}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.subscription_date}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.plan}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.duration}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.total_complex}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.individual_building}</TableCell>
                                                                <TableCell align="center">
                                                                    {item.attributes.expiry_date}</TableCell>
                                                                <TableCell align="center">
                                                                    <Typography className={`bold-text ${this.getStatusClass(item.attributes.status)}`}>
                                                                        {item.attributes.status}
                                                                    </Typography>
                                                                </TableCell>

                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <BoxView className="table-bottom">
                                            <p>
                                                {t("Showing")} <span className="current-page">{this.state.gaMemberSubReport.length}</span> {t("of")}{" "}
                                                <span className="total-page">{this.state.gaMemberSubReport.length}</span>{" "}
                                                {t("results")}
                                            </p>
                                            <BoxView>
                                                <Pagination
                                                    id="PaginationModule"
                                                    shape="rounded"
                                                    count={1}
                                                    data-test-id="paginationID"
                                                    variant="outlined"
                                                    style={{ marginRight: "20px" }}
                                                />
                                            </BoxView>
                                        </BoxView>
                                    </Grid>
                                </Grid>
                            </BoxView>}

                            {this.state.downloadPage &&
                                <BoxView>
                                    <BoxView className="header-title">
                                        <Typography variant='h4' className="bold-text">{t("GA Member Subscription Report")}</Typography>
                                        <Typography className="bold-text downloadButton" data-test-id="downloadTable" onClick={this.handleSubTableDownloadReport}>{t("DOWNLOAD REPORT")}</Typography>
                                    </BoxView>
                                    <BoxView>
                                        <BoxView className="main">
                                            <BoxView className="heading">
                                                <BoxView>
                                                    <img className="imageBox" src={logo.default} width={126} height={49} />
                                                    <Typography className="bold-text">{t("TENANT INTERNATIONAL")}</Typography>
                                                </BoxView>
                                                <Typography className="bold-text">Report Generated on 18 June 2023 9:00 AM By Peter Parker</Typography>
                                            </BoxView>
                                            <div className="detail">
                                                <Typography className="bold-text" variant="h5">Subscription Report: Property Manager</Typography>
                                                <Typography>John Harper</Typography>
                                            </div>
                                            <Grid container xs={12} className="show-country">
                                                <Grid xs={2} item>
                                                    <BoxView>
                                                        <Typography className="bold-text">{t("Country")}</Typography>
                                                        <Typography >{this.state.selectedCountry}</Typography>
                                                    </BoxView>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <BoxView>
                                                        <Typography className="bold-text">{t("City")}</Typography>
                                                        <Typography>{this.state.selectedCity}</Typography>
                                                    </BoxView>
                                                </Grid>
                                            </Grid>
                                            <BoxView className="table-Data">
                                                <TableContainer component={Paper} className="table-container">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>#</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Subscription Date")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Plan")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Duration")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Total Complexes")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Total Indivual Building")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Ends On")}</TableCell>
                                                                <TableCell align="center" className="bold-text">{t("Status")}</TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {this.state.gaMemberSubReport.map((item: {
                                                        attributes: {
                                                            subscription_date: string
                                                            plan: string
                                                            duration: string
                                                            total_complex: string
                                                            individual_building: string
                                                            expiry_date: string
                                                            status: string
                                                        }
                                                    }, index: number) => (
                                                        <>
                                                            <TableRow key={index} className="table-row">
                                                                <TableCell align="center">{index + 1}</TableCell>
                                                                <TableCell align="center">{item.attributes.subscription_date}</TableCell>
                                                                <TableCell align="center">{item.attributes.plan}</TableCell>
                                                                <TableCell align="center">{item.attributes.duration}</TableCell>
                                                                <TableCell align="center">{item.attributes.total_complex}</TableCell>
                                                                <TableCell align="center">{item.attributes.individual_building}</TableCell>
                                                                <TableCell align="center">{item.attributes.expiry_date}</TableCell>
                                                                <TableCell align="center">
                                                                    <Typography className={`bold-text ${this.getStatusClass(item.attributes.status)}`}>
                                                                        {item.attributes.status}
                                                                    </Typography>
                                                                </TableCell>

                                                            </TableRow>
                                                        </>
                                                    ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <BoxView className="total-pageBottom">
                                                    <Typography>TENANT INTERNATIONAL</Typography>
                                                    <Typography>Page 1 of 1</Typography>
                                                </BoxView>
                                            </BoxView>
                                        </BoxView>
                                    </BoxView>
                                </BoxView>}

                            {this.state.downloadTable && <BoxView>
                                <BoxView className="header-title">
                                    <Typography variant='h4' className="bold-text">{t("GA Member Usage Statistical Report")}</Typography>
                                    <Typography className="bold-text downloadButton"  onClick={this.handleDownloadReport}>{t("DOWNLOAD REPORT")}</Typography>
                                </BoxView>
                                <BoxView>
                                    <BoxView className="main">
                                        <BoxView className="heading">
                                            <BoxView>
                                                <img src={logo.default} className="imageBox" height={49} width={126} />
                                                <Typography className="bold-text">{t("TENANT INTERNATIONAL")}</Typography>
                                            </BoxView>
                                            <Typography className="bold-text">Report Generated on 18 June 2023 9:00 AM By Peter Parker</Typography>
                                        </BoxView>
                                        <div className="detail">
                                            <Typography variant="h5" className="bold-text">Usage Statistical Report: Property Manager</Typography>
                                            <Typography>John Harper</Typography>
                                        </div>
                                        <Grid container className="show-country" xs={12}>
                                            
                                            <Grid item xs={2}>
                                                <BoxView>
                                                    <Typography className="bold-text">{t("Country")}</Typography>
                                                    <Typography >{this.state.selectedCountry}</Typography>
                                                </BoxView>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <BoxView>
                                                    <Typography className="bold-text">{t("City")}</Typography>
                                                    <Typography>{this.state.selectedCity}</Typography>
                                                </BoxView>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <BoxView>
                                                    <Typography className="bold-text">{t("Complexes or Buildings")}</Typography>
                                                    <Typography>{this.state.individualbuilding}</Typography>
                                                </BoxView>
                                            </Grid>
                                        </Grid>
                                        <BoxView className="table-Data">
                                            <TableContainer component={Paper} className="table-container">
                                            <Table>
                                                <TableHead>
                                                        <TableRow>
                                                            <TableCell>#</TableCell>
                                                            <TableCell className="bold-text">
                                                                {t("Country")}
                                                            </TableCell>
                                                            <TableCell
                                                            
                                                                className="bold-text">
                                                                {t("City")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text">
                                                                {t("Complex/Building Name")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"

                                                            >{t("Occupancy Rate")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"

                                                            >{t("Registered User")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"

                                                            >{t("Rented Units")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"

                                                            >{t("Active Residents")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"

                                                            >{t("Top 3 active Property Managers")}
                                                            </TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.gaMemberReport.map((item:{
                                                        id:number
                                                        attributes: {
                                                            country: string
                                                            city: string
                                                            complex_name: string
                                                            occupancy_rate:{
                                                                Total_units_count:string
                                                                Total_units_percentage: string
                                                            }
                                                            register_users: {
                                                                Total_registered_user: string
                                                                Total_registered_percentage: string
                                                            }
                                                            rent_occupancy_rate: {
                                                                Total_units_count:string
                                                                Total_units_percentage: string
                                                            }
                                                            resident_user_percentage: string
                                                            top_3_property_manager:[]
                                                        }
                                                    }, index:number) => (
                                                        <TableRow className="table-row" data-test-id="openSubscriotionReportId" onClick={() => this.handleSubscriotionReport(item.id)}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item.attributes.country}</TableCell>
                                                        <TableCell>{item.attributes.city}</TableCell>
                                                        <TableCell>{item.attributes.complex_name}</TableCell>
                                                        <TableCell>{item.attributes.occupancy_rate.Total_units_count} ({item.attributes.occupancy_rate.Total_units_percentage}%)</TableCell>
                                                        <TableCell>{item.attributes.register_users.Total_registered_user} ({item.attributes.register_users.Total_registered_percentage}%)</TableCell>
                                                        <TableCell>{item.attributes.rent_occupancy_rate.Total_units_count} ({item.attributes.rent_occupancy_rate.Total_units_percentage}%)</TableCell>
                                                        <TableCell>{item.attributes.resident_user_percentage}%</TableCell>
                                                        <TableCell>{item.attributes.top_3_property_manager.map((item: string) => item).join(', ') || '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <BoxView className="total-pageBottom">
                                                <Typography>TENANT INTERNATIONAL</Typography>
                                                <Typography>Page 1 of 1</Typography>
                                            </BoxView>
                                        </BoxView>
                                    </BoxView>
                                </BoxView>
                            </BoxView>}
                        </BoxView>
                    </GAMemberReportCss>
                </CEHeaderComponentWeb>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
export const GAMemberReportCss = styled(Box)({
    '& .containerBox': {
        padding: "0px 40px"
    },
    '& .selectmenu': {
        border: '1px solid #F0F0F0',
        padding: '7px',
        background: '#fff',
        borderRadius: '15px',
        borderBottom: 'none',
        width: '100%',
        gap: '10px',
        marginTop: '10px',
    },
    '& .mybtn': {
        backgroundColor: '#2D6EED',
        color: 'white',
        padding: '8px 16px',
        marginTop: '11px',
        textTransform: 'capitalize',
    },
    '& .Box': {
        borderRadius: '10px',
        backgroundColor: '#fff',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        paddingLeft: '30px',
        alignItems: 'flex-start',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        marginTop: '25px',
    },
    "& .header-title": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom:"15px"
    },
    '& .table-container': {
        boxShadow: 'none',
    },
    '& .tabColor': {
        color: "rgb(43, 111, 237)"
    },
    "& .downloadButton": {
        padding: "10px 20px",
        background: "#FC8434",
        color: "#fff",
        borderRadius: "8px",
        cursor: "pointer"
    },
    '& .sort-by': {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
    },
    '& .table-row': {
        cursor:"pointer"
    },
    '& .main': {
        padding: '15px',
        backgroundColor: '#fff',
        marginTop:"20px"
    },
    '& .heading': {
        justifyContent: 'space-between',
        display: 'flex',
    },
    '& .detail': {
        backgroundColor: 'rgba(221, 221, 221, 0.38)',
        marginTop: '35px',
        textAlign: 'center',
        color: '#191E26',
        borderRadius: '8px',
        padding: '10px 50px',
    },
    '& .total-pageBottom': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .table-Data': {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '83%',
        display: 'flex',
        marginTop:"15px"
    },
    '& .show-country': {
        marginTop:"20px"
    },
    "& .status-active": {
        background: "rgb(30,198,91,0.2)",
        color: "rgb(30 198 91)",
        padding: "5px 10px",
        borderRadius: "20px"
    },
    "& .status-suspend": {
        background: "rgba(242,23,23,0.2)",
        color: "#F21717",
        padding: "5px 10px",
        borderRadius: "20px"
    },
    "& .status-deactive": {
        background: "#D8D8D885",
        color: "#45474B",
        padding: "5px 10px",
        borderRadius: "20px"
    },
    '& .imageBox': {
        marginLeft:"20px"
    },
    '& .tableError' : {
        padding : "10px"
    }
})
export default withTranslation()(
    //@ts-ignore
    (withRouter(GAMemberReport))
);
// Customizable Area End