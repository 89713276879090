import React from "react";
// Customizable Area Start
import { 
  Box, 
  Breadcrumbs, 
  styled, 
  Typography, 
  Button, 
  Select, 
  FormControl, 
  MenuItem, 
  Grid, 
  Card, 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  Paper, 
  Chip, 
  TextField, 
  Divider, 
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import LeaseReportController, {
  Props,
  AccountManagerItem, 
  BuildingListItem, 
  SalesManagerItem, 
  SelectedComplexListItem, 
  TFunction, 
  ContractListItem,
  I18Interface
} from "./LeaseReportController.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Lease } from "./assets";
import Loader from "../../../components/src/Loader.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class LeaseReport extends LeaseReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderFilterSection = () => {
      const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
      const language = i18n.language;
      return (
      <Box dir={languageCondition(language, "rtl", "ltr")}>
        <Box className='leaseReportTitleSection' dir={languageCondition(language, "rtl", "ltr")}>
          <Typography variant='h4' className='leaseReportHeading' dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Report")}</Typography>
          <Button className='downloadReportBtn' onClick={this.getDownloadLeaseReport} data-testId="downloadLeaseReportTestId" dir={languageCondition(language, "rtl", "ltr")}>{t("Download Report")}</Button>
        </Box>
        <Box className='filterDropDownContainer' dir={languageCondition(language, "rtl", "ltr")}>
          <FormControl variant='outlined' >
            <Select
            displayEmpty
            IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.complex}
              onChange={this.handleComplexChange}
              data-testId={"complexSelectTestId"}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Complex")}
                </MenuItem>
                {this.state.complexList.map((item: string, index: number) => {
                  return <MenuItem key={index} value={item}>{item}</MenuItem>
                })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              data-testId={"countrySelectTestId"}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Country")}
              </MenuItem>
              {this.state.countryList.map((item: string, index: number) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.city}
              onChange={this.handleCityChange}
              data-testId={"citySelectTestId"}
              disabled={this.state.country?false:true}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select City")}
              </MenuItem>
              {this.state.cityList.map((item: string, index: number) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.selectedComplex}
              onChange={this.handleCompleSelectChange}
              data-testId={"selectComplexTestId"}
              disabled={this.state.complex?false:true}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Complex")}
              </MenuItem>
              {this.state.selectedComplexList.map((item: SelectedComplexListItem, index: number) => {
                return <MenuItem key={index} value={item.attributes.name}>{item.attributes.name}</MenuItem>
              })}

            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.building}
              onChange={this.handleBuildingChange}
              data-testId={"buildingSelectTestId"}
              disabled={this.state.selectedComplex?false:true}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Building")}
              </MenuItem>
              {this.state.buildingList.map((item: BuildingListItem, index: number) => {
                return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelect'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.year}
              onChange={this.handleYearChange}
              data-testId={"yearSelectTestId"}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Year")}
              </MenuItem>
              {this.state.yearList.map((item: number, index: number) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelectXl'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                    height:"400px",
                    overflow:"scroll"
                  },
                },
              }}
              value={this.state.salesManager}
              onChange={this.handleSalesManagerChange}
              data-testId={"salesManagerSelectTestId"}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Sales Manager")}
              </MenuItem>
              {this.state.salesManagerList.map((item: SalesManagerItem, index: number) => {
                return <MenuItem key={index} value={item.first_name}>{item.first_name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl variant='outlined' >
            <Select
              displayEmpty
              IconComponent={ExpandMoreIcon}
              className='filterSelectXl'
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "55px",
                  },
                },
              }}
              value={this.state.accountManager}
              onChange={this.handleAccountManagerChange}
              data-testId={"accountManagerSelectTestId"}
            >
              <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>
                {t("Select Account Manager")}
              </MenuItem>
              {this.state.accountManagerList.map((item: AccountManagerItem, index: number) => {
                return <MenuItem key={index} value={item.first_name}>{item.first_name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            className='searchBtn'
            onClick={this.searchLeaseDataDetails}
            data-testId={"searchBtnTestId"}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            {t("Search")}
          </Button>
        </Box>
      </Box>
      )
    };

    renderCards = () => {  
      const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
      const language = i18n.language;
      return (
      <Box className='renderCardsContainer' dir={languageCondition(language, "rtl", "ltr")}>
        <Grid spacing={3} container>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className='cardContainer' dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer'>
                <img src={Lease} className="leaseReportCardIcon"/>
              </Box>
              <Typography variant='h5' className='cardText' dir={languageCondition(language, "rtl", "ltr")}>{t("Average price per SQM/SQF for")} {this.state.country}</Typography>
                {this.state.country ?
                  <Chip label={this.handleDecimalValues(this.state.searchData.average_prices.country)} className='priceChip' /> :
                  <Typography className="errorText" dir={languageCondition(language, "rtl", "ltr")}>{t("Plese select country to view data")}</Typography>
                }
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className='cardContainer' dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer'>
                <img src={Lease} className="leaseReportCardIcon"/>
              </Box>
              <Typography variant='h5' className='cardText' dir={languageCondition(language, "rtl", "ltr")}>{t("Average price per SQM/SQF for")} {this.state.city }</Typography>
                {this.state.city ?
                  <Chip label={this.state.searchData.lease_rate_and_occupancy_rates === null || this.state.searchData.lease_rate_and_occupancy_rates ===  undefined ? "-" : this.handleDecimalValues(this.state.searchData.average_prices.city)} className='priceChip' />
                  :
                  <Typography className="errorText" dir={languageCondition(language, "rtl", "ltr")}>{t("Plese select city to view data")}</Typography>
                }
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className='cardContainer' dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer'>
                <img className="leaseReportCardIcon" src={Lease}/>
              </Box>
              <Typography className='cardText' variant='h5' dir={languageCondition(language, "rtl", "ltr")}>{t("Average price per SQM/SQF for")} {this.state.selectedComplex }</Typography>
                {this.state.selectedComplex ?
                  <Chip label={this.handleDecimalValues(this.state.searchData.average_prices.complex)} className='priceChip' /> :
                  <Typography className="errorText" dir={languageCondition(language, "rtl", "ltr")}>{t("Plese select complex to view data")}</Typography>
                }
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className='cardContainer' raised={false} dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer' component={'div'}>
                <img className="leaseReportCardIcon" src={Lease}/>
              </Box>
              <Typography className='cardText' variant='h5' dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Rate & Occupacy Rate for")} { this.state.country}</Typography>
                {this.state.country ?
                  <Typography className='cardText2' dir={languageCondition(language, "rtl", "ltr")}>
                    {t("Lease")}{" "}
                    <span className='orangeText'>{this.state.searchData.lease_rate_and_occupancy_rates.country === null ? "-" : this.handleDecimalValues(this.state.searchData.lease_rate_and_occupancy_rates.country.lease)}%{" "}</span> 
                    {t("Occupancy")} {" "}
                    <span className='orangeText'>{this.state.searchData.lease_rate_and_occupancy_rates.country.occupancy}% {" "}</span>
                  </Typography> :
                  <Typography dir={languageCondition(language, "rtl", "ltr")} className="errorText">{t("Plese select country to view data")}</Typography>
                }
            </Card>
          </Grid>
          <Grid lg={4} md={4} sm={6} xs={12} item>
            <Card className='cardContainer' dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer'>
                <img className="leaseReportCardIcon" src={Lease}/>
              </Box>
              <Typography dir={languageCondition(language, "rtl", "ltr")} variant='h5' className='cardText'>{t("Lease Rate & Occupacy Rate for")} { this.state.selectedComplex}</Typography>
                {this.state.selectedComplex ?
                  <Typography dir={languageCondition(language, "rtl", "ltr")} className='cardText2'>
                    {t("Lease")}{" "}
                    <span className='orangeText'>{this.state.searchData.lease_rate_and_occupancy_rates.complex === null ? "-" : this.state.searchData.lease_rate_and_occupancy_rates.complex.lease}%{" "}</span>
                    {t("Occupancy")}{" "}
                    <span className='orangeText'>{this.state.searchData.lease_rate_and_occupancy_rates.complex.occupancy}%{" "}</span>
                  </Typography> :
                  <Typography dir={languageCondition(language, "rtl", "ltr")} className="errorText">{t("Plese select complex to view data")}</Typography>
                }
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className='cardContainer' dir={languageCondition(language, "rtl", "ltr")}>
              <Box className='cardImageContainer'>
                <img className="leaseReportCardIcon" src={Lease}/>
              </Box>
              <Typography dir={languageCondition(language, "rtl", "ltr")} variant='h5' className='cardText'>{t("Average Price per SQM/SQF for")} {this.state.building }</Typography>
              {this.state.building?
              <Chip
                label={
                  this.handleDecimalValues(this.state.searchData.average_prices.building)
                } className='priceChip' />:
                <Typography className="errorText" dir={languageCondition(language, "rtl", "ltr")}>{t("Plese select building to view data")}</Typography>
            }
            </Card>
          </Grid>
        </Grid>
      </Box>
      )
    }

    renderTable = () => {
      const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
      const language = i18n.language;
      return( 
      <Box className='renderTableSection' component={Paper} dir={languageCondition(language, "rtl", "ltr")}>
        <Box className='tableTopSection' dir={languageCondition(language, "rtl", "ltr")}>
          <Typography className='tableSectionHeading' variant='h5' dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Reports")}</Typography>
            <TextField
              className='searchField'
              variant='outlined'
              placeholder={t("Search Complex")}
              InputProps={{
                startAdornment: <SearchIcon className="searchIcon" />
              }}
              onChange={this.searchLeaseReportInputChange}
              value={this.state.serachText}
              data-testId={"leaseReportSearchInputTestId"}
            />
        </Box>
        <Divider className='divider' />
        <TableContainer dir={languageCondition(language, "rtl", "ltr")}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("#")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Year")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Complex")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Building")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Unit")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Generated By")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Role")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Generated On")}</TableCell>
                <TableCell align="left" className='tableHeadingText' dir={languageCondition(language, "rtl", "ltr")}>{t("Expiring In (Days)")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {this.state.contractList.map((item: ContractListItem, contractListItemIndex: number) => (
                  <TableRow key={contractListItemIndex}>
                    <TableCell align="left" className='tableHeadingText'>{contractListItemIndex + 1}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.year === null ? "-" : item.attributes.year}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.complex === null ? "" : item.attributes.complex.name}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.building === null?"-":item.attributes.building.name}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.unit===null?"-":item.attributes.unit.apartment_name}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.generated_by===null?"-":item.attributes.generated_by.full_name}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{this.handleRole(item.attributes.lease_issuer)}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.generated_on === null ? "-" : item.attributes.generated_on}</TableCell>
                    <TableCell align="left" className='tableHeadingText'>{item.attributes.expiring_in = null ? "-" : item.attributes.expiring_in}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className='paginationContainer'>
          <PaginationModule pagination={this.state.paginationData} handlePagination={this.handleContractListPagination} page={this.state.paginationData.current_page} data-testId={"leaseReportTableTestId"}/>
          </Box>
      </Box>
      )
    };
    // Customizable Area End

    render() {
      // Customizable Area Start
      const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
      const language = i18n.language;
      return (
        <MainScreen>
          <LeaseReportMainContainer>
            <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
              <span className="leaseReportBreadCrumb" data-testId={"leaseReportHeadingTestId"} onClick={()=>this.handleDocumentAndReportRoute("CompEmpDocument")} dir={languageCondition(language, "rtl", "ltr")}>
                {t("Documents & Reports")}
              </span>
              <span className="leaseReportBreadCrumb" data-testId={"leaseReportHeasdingTestId2"} onClick={()=>this.handleDocumentAndReportRoute("ReportsStatistics")} dir={languageCondition(language, "rtl", "ltr")}>
                {t("Reports")}
              </span>
              <Typography className="leaseReportBreadCrumbActive" dir={languageCondition(language, "rtl", "ltr")}>{t("Lease Reports")}</Typography>
            </Breadcrumbs>
            {this.renderFilterSection()}
            {this.renderCards()}
            {this.renderTable()}
            <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"alertErrorTestId"} />
            <Loader loading={this.state.loader} />
          </LeaseReportMainContainer>
        </MainScreen>
      );
      // Customizable Area End
    }
}

// Customizable Area Start
export default  withTranslation()(LeaseReport);
export { LeaseReport };

const LeaseReportMainContainer = styled(Box)({
  backgroundColor: "#f4f7ff",
  padding: "40px",
  "& .leaseReportBreadCrumb":{
    color:"#3f444c",
    cursor:"pointer"
  },
  "& .leaseReportBreadCrumbActive":{
    color:"#3e7def",
    cursor:"pointer"
  },
  "& .leaseReportTitleSection": {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px"
  },
  "& .downloadReportBtn": {
    color: "white",
    backgroundColor: "#fc8435",
    fontWeight: "800"
  },
  "& .leaseReportHeading": {
    fontWeight: "900"
  },
  "& .filterDropDownContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px"
  },
  "& .filterSelect": {
    width: "250px",
    borderRadius: "8px",
    border: "1px solid #F0F0F0"
  },
  "& .filterSelectXl": {
    width: "370px",
    borderRadius: "8px",
    border: "1px solid #f4f3f4"
  },
  "& .searchBtn": {
    height: "54px",
    borderRadius: "8px",
    backgroundColor: "#2a6fed"
  },
  "& .renderCardsContainer": {
    marginTop: "50px"
  },
  "& .cardContainer": {
    height: "205px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "20px",

  },
  "& .renderTableSection": {
    marginTop: "50px",
    backgroundColor: "#fff",
    borderRadius: "10px",
  },
  "& .cardImageContainer": {
    border: "1px solid gray",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%"
  },
  "& .leaseReportCardIcon": {
    border:"1px solid #eee",
    borderRadius:"50%"
  },
  "& .priceChip": {
    backgroundColor: "#fff8f3",
    color: "#fc883b",
    width: "100px"
  },
  "& .cardText": {
    fontWeight: "900",
    textAlign: "left"
  },
  "& .cardText2": {
    fontWeight: "600"
  },
  "& .tableSectionHeading": {
    fontWeight: "900"
  },
  "& .tableTopSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",

  },
  "& .tableHeadingText": {
    fontWeight: "800"
  },
  "& .searchField": {
    background: "#FFF",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    width: "300px",
    "& .MuiOutlinedInput-inputAdornedStart": {
      marginLeft: "40px"
    },
  },
  "& .searchIcon": {
    color: "#F0F0F0"
  },
  "& .divider": {
    margin: "10px 0px",
    height: "2px"
  },
  "& .paginationContainer": {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .orangeText": {
    color: "#fc8e45"
  },
  "& .tablePagination": {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgb(252, 132, 52) !important",
      color: "#fff",
      fontWeight: 700,
      margin: "0px 5px",
      borderColor: "rgb(252, 132, 52) !important"
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "rgb(252, 132, 52) !important",
      color: "#fff",
      fontWeight: 700,
      margin: "0px 5px",
      borderColor: "rgb(252, 132, 52) !important"
    },
    "& .MuiPaginationItem-rounded": {
      color: "#f4f3f3",
      margin: "0px 5px",
    },
    "& .MuiPaginationItem-outlined": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      color: "rgba(0, 0, 0, 0.23)"
    },
    "& .MuiPaginationItem-outlined:hover": {
      color: "#2b6fed",
      border: "1px solid #2b6fed"
    },
    "& .MuiPaginationItem-page.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  "& .errorText":{
    color:"red"
  },

})
// Customizable Area End