import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
  t: (value: string) => string;
  i18n: {
    language: string;
  };
    // Customizable Area End
}

interface S {
    // Customizable Area Start
  companiesData: any[];
  complexData: any[];
  cooComplexData: any[];
  cooAccountData: any[];
  tabValue: number;
  selectDetails: string;
  userRole: string | null;
  country: string;
  city: string;
  building: string;
  complex: string;
  searchText: string;
  cooComplexPage: number;
  countryList: any[];
  cityList: any[];
  companyList: any[];
  showError: boolean;
  showErrorMessage: string;
  salesManagerProfileDetails: any;
  salesManagerProfileDetailsdata:any;
  complexList: any[];
  salesManagerList: any[];
  individualBuildingList: any[];
  paginationData: any | null;
  subscriptionEndsIn: string;
  salesManagerId: string;
  companyId: string;
  complexId: string;
  accountManagerId: string;
  loader: boolean;
  openBuildingModal: boolean;
  latitude: number | null;
  longitude: number | null;
  buildingList: any[];
  buildingId: string;
  sortBy: string;
  popoverAncher: HTMLElement | null;
  selectedItem: any | null;
  registeredOwner: any;
  unit: string;
  ownerStatus: string;
  payingClient: string;
  cooPage: number;
  salesPage: number;
  accountPage: number;
  page: number;
  propertyManagerList: any[];
  propertyManager: string;
  propertyManagerId: string;
  openReassignModal: boolean;
  modalBuildingId: string;
  accountManagerAssign: string;
  salesManagerAssign: string;
  noteText: string;
  profileDataid:number;
  profileRole:string;
link: {
  snapchat: string;
  twitter: string;
  instagram: string;
  facebook: string;
};
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SalesManagerProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  countryListApiCallId: string = "";
  cityListApiCallId: string = "";
  companiesListApiCallId: string = "";
  salesManagerProfileDetailsApiCallId: string = "";
  salesManagerProfiledataDetailsApiCallId:string="";
  individualBuildingApiCallId: string = "";
  complexListApiCallId: string = "";
  salesManagerApiCallId: string = "";
  buildingListApiCallId: string = "";
  registerOwnerApiCalId: string = "";
  propertyManagerApiCallId: string = "";
  complexTabDataApiCallId: string = "";
  accountManagerTabDataApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
      country: "",
      city: "",
      building: "",
      complex: "",
      tabValue: 0,
      selectDetails: "",
      searchText: "",
      cooComplexPage: 0,
      companiesData: [],
      userRole: "",
      complexData: [],
      cooComplexData: [],
      cooAccountData: [],
      countryList: [],
      cityList: [],
      companyList: [],
      showError: false,
      showErrorMessage: "",
      salesManagerProfileDetails:null,
      salesManagerProfileDetailsdata:null,
      profileDataid:0,
      profileRole:"",
      link: {
          instagram: "",
          twitter: "",
          facebook: "",
          snapchat: "",
        },
      complexList: [],
      salesManagerList: [],
      individualBuildingList: [],
      paginationData: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
        total_count: 0,
      },
      subscriptionEndsIn: "",
      salesManagerId: "",
      companyId: "",
      complexId: "",
      accountManagerId: "",
      loader: false,
      openBuildingModal: false,
      latitude: 0,
      longitude: 0,
      buildingList: [],
      buildingId: "",
      sortBy: "",
      unit: "",
      ownerStatus: "",
      payingClient: "",
      cooPage: 1,
      salesPage: 1,
      accountPage: 1,
      popoverAncher: null,
      selectedItem: null,
      registeredOwner: [],
      page: 0,
      propertyManagerList: [],
      propertyManager: "",
      propertyManagerId: "",
      openReassignModal: false,
      accountManagerAssign: "",
      modalBuildingId: "",
      noteText: "",
      salesManagerAssign: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJsonData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJsonData && !responseJsonData.errors) {
        this.salesManagerprofileApiResponseSucessCell(apiRequestCallId, responseJsonData);
      } else if (responseJsonData && responseJsonData.errors) {
        this.salesManagerprofileApiResponseFailureCall(apiRequestCallId, responseJsonData);
      }
    }
  
     
        // Customizable Area End
    }
    // Customizable Area Start
  async componentDidMount() {
    const profileId = await getStorageData("viewProfileid")
    const profileRole= await getStorageData("profileRole")

    this.setState({profileDataid:profileId,
        profileRole:profileRole
    },()=>{
        this.getSalesManagerProfileDetails();
    this.getSalesManagerProfileDetailsmapData()
            })
    this.getUserType();
    this.getCountryList();
    this.getCompanyList();
    this.getSalesManagerListing();
    this.getComplexTabData("1");
  }

  salesManagerProfileApiCall = async (apiPayloadType: any) => {
    let token = localStorage.getItem("loginSuccessToken");
    let { method, endPoint, body, type = "", contentType } = apiPayloadType;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    let contractDetailsrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    contractDetailsrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contractDetailsrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    contractDetailsrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    contractDetailsrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
    return contractDetailsrequestMessage.messageId;
  };

  salesManagerprofileApiResponseSucessCell = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.countryListApiCallId) {
      this.countryListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.cityListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.companiesListApiCallId) {
      this.companyListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerProfileDetailsApiCallId) {
      this.salesManagerProfileDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerProfiledataDetailsApiCallId) {
      this.salesManagerProfiledataDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.individualBuildingApiCallId) {
      this.individualBuildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.complexListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerApiCallId) {
      this.salesManagerListSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.buildingListSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.registerOwnerApiCalId) {
      this.registeredOwnerSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.propertyManagerApiCallId) {
      this.propertyManagerSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexTabDataApiCallId) {
      this.complexTabDataSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.accountManagerTabDataApiCallId) {
      this.accountManagerTabDataSucessCallBack(responseJson);
    }
  };

  salesManagerprofileApiResponseFailureCall = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.countryListApiCallId) {
      this.countryListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.cityListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.companiesListApiCallId) {
      this.companyListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerProfileDetailsApiCallId) {
      this.salesManagerProfileDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.individualBuildingApiCallId) {
      this.individualBuildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.complexListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerApiCallId) {
      this.salesManagerListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.buildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.registerOwnerApiCalId) {
      this.registeredOwnerFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.propertyManagerApiCallId) {
      this.propertyManagerFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexTabDataApiCallId) {
      this.complexTabDataFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.accountManagerTabDataApiCallId) {
      this.accountManagerTabDataFailureCallBack(responseJson);
    }
  };

  getCountryList = async () => {
    this.countryListApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.countryEndPoint,
    });
  };

  countryListSucessCallBack = (response: any) => {
    this.setState({ countryList: response.data.countries });
  };

  countryListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getCityList = async () => {
    this.cityListApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.cityEndPoint}${this.state.country}`,
    });
  };

  cityListSucessCallBack = (response: any) => {
    this.setState({ cityList: response.data.cities });
  };

  cityListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getCompanyList = async () => {
    this.companiesListApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.companyListEndpoint,
    });
  };

  companyListSucessCallBack = (response: any) => {
    this.setState({ companyList: response.data });
  };

  companyListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getSalesManagerProfileDetails = async () => {
    this.salesManagerProfileDetailsApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint:configJSON.viewProfiledataAPIendpoint+`${this.state.profileDataid}`,
    });
  };

  getSalesManagerProfileDetailsmapData = async () => {
    this.salesManagerProfiledataDetailsApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint:configJSON.viewProfiledataAPIendpoint+`${this.state.profileDataid}`,
    });
  };

  salesManagerProfileDetailsSucessCallBack = (response: any) => {
    this.setState({ salesManagerProfileDetails: response.data?.attributes,
      link: {
          snapchat: response.data.attributes.website[3].snapchat_link,
          facebook: response.data.attributes.website[2].fb_link,
          instagram: response.data.attributes.website[1].instagram_link,
          twitter: response.data.attributes.website[0].twitter_link,
        },
   });
  };

  salesManagerProfiledataDetailsSucessCallBack=(response:any)=>{
    this.setState({
      salesManagerProfileDetailsdata: response.data
    })
  }

  salesManagerProfileDetailsFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getIndividualBuildingListing = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.individualBuilidingListEndpoint}page=${page}`;
    let { country, city, companyId, complexId, salesManagerId, subscriptionEndsIn, searchText } = this.state;
    if (country) {
      endPoint += `&country=${country}`;
    }
    if (city) {
      endPoint += `&city=${city}`;
    }
    if (salesManagerId) {
      endPoint += `&sales_manager=${salesManagerId}`;
    }
    if (companyId) {
      endPoint += `&company=${companyId}`;
    }
    if (complexId) {
      endPoint += `&complex=${complexId}`;
    }
    if (searchText) {
      endPoint += `&search_term=${searchText}`;
    }
    if (subscriptionEndsIn) {
      endPoint += `&subscription_ends_in=${subscriptionEndsIn}`;
    }
    this.individualBuildingApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  individualBuildingListSucessCallBack = (response: any) => {
    this.setState({ individualBuildingList: response.data, paginationData: response.meta.pagination, loader: false });
  };

  individualBuildingListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getComplexListing = async () => {
    this.complexListApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.complexListEndpoint}city=${this.state.city}&is_building=true`,
    });
  };

  complexListSucessCallBack = (response: any) => {
    this.setState({ complexList: response.data });
  };

  complexListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getSalesManagerListing = async () => {
    this.salesManagerApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.salesManagerEndPoint,
    });
  };

  salesManagerListSuccessCallBack = (response: any) => {
    this.setState({ salesManagerList: response.sales_manager_listing });
  };

  salesManagerListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getBuildingListing = async () => {
    this.buildingListApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.buidlingListEndPoint}${this.state.complexId}`,
    });
  };

  buildingListSuccessCallBack = (response: any) => {
    this.setState({ buildingList: response.data.buildings });
  };

  buildingListFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getRegisteredOwner = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.registeredOwnerDataEndPoint}page=${page}`;
    let { ownerStatus, payingClient, sortBy, searchText } = this.state;
    if (ownerStatus) {
      endPoint = endPoint + `&owner=${ownerStatus}`;
    }
    if (payingClient) {
      endPoint = endPoint + `&paying_clients=${payingClient}`;
    }
    if (sortBy) {
      endPoint = endPoint + `&sort_by=${sortBy}`;
    }
    if (searchText) {
      endPoint = endPoint + `&q=${searchText}`;
    }
    this.registerOwnerApiCalId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  registeredOwnerSucessCallBack = (response: any) => {
    if (response.meta.pagination !== null) {
      this.setState({ registeredOwner: response.data, paginationData: response.meta.pagination, loader: false });
    } else {
      this.setState({
        paginationData: {
          current_page: 0,
          next_page: 0,
          prev_page: 0,
          total_pages: 0,
          total_count: 0,
        },
      });
    }
  };

  registeredOwnerFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getPropertyManagerList = async (page: string) => {
    let endPoint = `${configJSON.propertyManagerListEndpoint}?page=${page}`;
    let { country, city, propertyManagerId, searchText, sortBy } = this.state;
    if (country) {
      endPoint = endPoint + `&country=${country}`;
    }
    if (city) {
      endPoint = endPoint + `&city=${city}`;
    }
    if (propertyManagerId) {
      endPoint = endPoint + `&p_id=${propertyManagerId}`;
    }
    if (searchText) {
      endPoint = endPoint + `&search_term=${searchText}`;
    }
    if (sortBy) {
      endPoint = endPoint + `&sort_by=${sortBy}`;
    }

    this.propertyManagerApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  propertyManagerSucessCallBack = (response: any) => {
    if (response.meta.pagination !== null) {
      this.setState({ propertyManagerList: response.data, paginationData: response.meta.pagination });
    } else {
      this.setState({
        propertyManagerList: [],
        paginationData: {
          current_page: 0,
          next_page: 0,
          prev_page: 0,
          total_pages: 0,
          total_count: 0,
        },
      });
    }
  };

  propertyManagerFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getComplexTabData = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.complexTabEndpoint}?page=${page}`;
    let { country, city, searchText, sortBy, companyId } = this.state;
    if (country) {
      endPoint = endPoint + `&country=${country}`;
    }
    if (city) {
      endPoint = endPoint + `&city=${city}`;
    }
    if (companyId) {
      endPoint = endPoint + `&company=${companyId}`;
    }
    if (searchText) {
      endPoint = endPoint + `&search_term=${searchText}`;
    }
    this.complexTabDataApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  complexTabDataSucessCallBack = (response: any) => {
    if (response.meta.pagination !== null) {
      this.setState({ cooComplexData: response.data, paginationData: response.meta.pagination, loader: false });
    } else {
      this.setState({
        propertyManagerList: [],
        paginationData: {
          current_page: 0,
          next_page: 0,
          prev_page: 0,
          total_pages: 0,
          total_count: 0,
        },
        loader: false,
      });
    }
  };

  complexTabDataFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
  };

  getAccountManagerTabData = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.accountManagerTabEndpoint}?page=${page}`;
    let { country, city, searchText, sortBy, companyId } = this.state;
    if (country) {
      endPoint = endPoint + `&country=${country}`;
    }
    if (city) {
      endPoint = endPoint + `&city=${city}`;
    }
    if (companyId) {
      endPoint = endPoint + `&company=${companyId}`;
    }
    if (searchText) {
      endPoint = endPoint + `&search_term=${searchText}`;
    }
    if (sortBy) {
      endPoint = endPoint + `&sort_by=${sortBy}`;
    }

    this.accountManagerTabDataApiCallId = await this.salesManagerProfileApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  accountManagerTabDataSucessCallBack = (response: any) => {
    if (response.meta.pagination !== null) {
      this.setState({ cooAccountData: response.data, paginationData: response.meta.pagination, loader: false });
    } else {
      this.setState({
        propertyManagerList: [],
        paginationData: {
          current_page: 0,
          next_page: 0,
          prev_page: 0,
          total_pages: 0,
          total_count: 0,
        },
        loader: false,
      });
    }
  };

  accountManagerTabDataFailureCallBack = (response: any) => {
    this.setState({ showError: true, showErrorMessage: response.errors[0], loader: false });
  };

  getUserType = async () => {
    const storedRole = await localStorage.getItem("role");
    this.setState({ userRole: storedRole });
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState(
      {
        tabValue: newValue,
        paginationData: {
          current_page: 0,
          next_page: 0,
          prev_page: 0,
          total_pages: 0,
          total_count: 0,
        },
        country: "",
        city: "",
        cityList: [],
        companyId: "",
        propertyManager: "",
        propertyManagerId: "",
        ownerStatus: "",
        payingClient: "",
        complex: "",
        subscriptionEndsIn: "",
      },
      () => {
        if (this.state.tabValue === 0 && this.state.userRole === "coo") {
          this.getComplexTabData("1");
        }
        if (this.state.tabValue === 1 && this.state.userRole === "coo") {
          this.getIndividualBuildingListing("1");
        }
        if (this.state.tabValue === 2 && this.state.userRole === "coo") {
          this.getRegisteredOwner("1");
        }
        if (this.state.tabValue === 3 && this.state.userRole === "coo") {
          this.getPropertyManagerList("1");
        }
        if (this.state.tabValue === 4 && this.state.userRole === "coo") {
          this.getAccountManagerTabData("1");
        }
      }
    );
  };

  handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ country: event.target.value as string }, () => {
      this.getCityList();
    });
  };

  handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ city: event.target.value as string }, () => {
      this.getComplexListing();
    });
  };

  handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let building = this.state.buildingList.find((building) => {
      return building.name === event.target.value;
    });
    if (building) {
      this.setState({ building: event.target.value as string, buildingId: building.id });
    }
  };

  handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let complex = this.state.complexList.find((complex) => {
      return complex.attributes.name === event.target.value;
    });
    if (complex) {
      this.setState({ complex: event.target.value as string, complexId: complex.id }, () => {
        this.getBuildingListing();
      });
    }
  };

  handleCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ companyId: event.target.value as string });
  };

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  };

  handleSearchCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      this.getIndividualBuildingListing("1");
    });
  };

  handleSalesManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ salesManagerId: event.target.value as string });
  };

  handleSubscriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ subscriptionEndsIn: event.target.value as string });
  };

  handleCooComplexPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getComplexTabData(String(value));
  };

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getIndividualBuildingListing(String(value));
  };

  handleOwnerStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ ownerStatus: event.target.value as string });
  };

  handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ sortBy: event.target.value as string }, () => {
      this.getRegisteredOwner("1");
    });
  };

  handlePayingClientsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ payingClient: event.target.value as string });
  };

  handleSearchInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ searchText: event.target.value as string }, () => {
      this.getRegisteredOwner("1");
    });
  };

  handleCooPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getRegisteredOwner(String(value));
  };

  handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
    this.setState({ popoverAncher: event.currentTarget, selectedItem: item });
  };

  handlePropertyManagerIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let propertyManager = this.state.propertyManagerList.find((propertyManager) => {
      return propertyManager.id === event.target.value;
    });
    if (propertyManager) {
      this.setState({ propertyManagerId: event.target.value as string, propertyManager: event.target.value as string });
    }
  };

  handleCooAccountPagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getAccountManagerTabData(String(value));
  };

  handleErrorAlert = () => {
    this.setState({ showError: false });
  };

  handleCloseViewBuilding = () => {
    this.setState({ openBuildingModal: false });
  };

  handleNullValue = (value: string | null | number) => {
    return value === null ? "-" : value;
  };

  handleSalesManager = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ salesManagerAssign: event.target.value as string });
  };

  handleAccountManager = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ accountManagerAssign: event.target.value as string });
  };

  handleNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ noteText: event.target.value });
  };

  handleCloseReassignModal = () => {
    this.setState({ openReassignModal: false });
  };

  handleOpenReassignModal = () => {
    this.setState({ openReassignModal: true });
  };
  handleMapvalues = (cooComplex: any) => {
    this.setState({
      latitude: cooComplex && cooComplex.attributes && cooComplex.attributes.lat,
      longitude: cooComplex && cooComplex.attributes && cooComplex.attributes.long,
    },()=>{
      this.handleOpenmapModal()
    });
  };
  handleOpenmapModal = () => {
    this.setState({
      openBuildingModal: !this.state.openBuildingModal,
    });
  };
  handleNavigatedashboard=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'DashboardCompanyEmployee'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);  
}
handleNavigateSalesManager=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SalesManagerDashboard'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);  
}
handleNavigateAccountManager=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AccountManagerDashboard'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);  
}
handleSocialicons=(link:any)=>{
    if (link) {
        this.handleLink(link);
      } else {
        this.handleErrorback(); 
      }
}
  handleLink = (link: any) => {
    const aTag = document.createElement("a");
    aTag.href = link;
    aTag.target = "_blank";
    document.body.appendChild(aTag);
    aTag.click();
    if (aTag.parentNode) {
      aTag.parentNode.removeChild(aTag);
    }
  };
  handleErrorback=()=>{
    toast.error("Link is not Provided.")
  }
  // Customizable Area End
}
