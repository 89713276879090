// Customizable Area Start
import React from "react";
import { Box, Input, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "../assets";
import { LanguageAttributeHandler } from "../UtilFunction.web";
import { languageCondition } from "../ConditionManager.web";

const SearchInput = ({ t, language, label, width, ...props }: any) => {
  return (
    <Box className="input_icon__form_box input_box__search_filter" style={{ width:width }}>
      <Input
        className="input_box"
        fullWidth
        placeholder={t(label)}
        {...props}
        startAdornment={
          <InputAdornment position="start" className={LanguageAttributeHandler(language, "", "arabic_search_icon")}>
            <img src={SearchIcon} />
          </InputAdornment>
        }
        align={languageCondition(language, "right", "left")}
      />
    </Box>
  );
};

export default SearchInput;
// Customizable Area End
