import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");
export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation:any
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    individualbuilding:string
    country:string
    selectCountry:string[]
    city:string
    selectCity:string[]
    complex:string
    selectComplex:string[]
    building:string
    selectBuilding:string[]
    sales:string
    selectSales:string[]
    accountManager:string
    selectAccountManager:string[]
    sort:string
    anchorEl: HTMLButtonElement | null;
    token: string
    ownerId:string
    ownerReportDetails:any
    isListVisible: boolean;
    downloadReport : boolean
    owenrReportData:any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class OwnersReportsDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCountryApiId: string = "";
    getCityApiId: string = "";
    ownerReportDetailsApiId: string=""
    getComplexApiId: string =""
    getBuildingApiId: string = ""
    ownerReportApiId: string =""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.receive = this.receive.bind(this);

        this.state = {
            individualbuilding:'',
            country:'',
            selectCountry:[],
            city:'',
            selectCity:[],
            complex:'',
            selectComplex:[],
            building:'',
            selectBuilding:[],
            sales:'',
            selectSales:[],
            accountManager:'',
            selectAccountManager:[],
            sort:'',
            anchorEl: null,
            token:'',
            ownerId:'',
            ownerReportDetails:[],
            isListVisible: true,
            downloadReport:false,
            owenrReportData:{}

        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.ownerReportDetailsApiId) {
                this.setState({ ownerReportDetails: responseJson.data })
            }  else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.getBuildingApiId) {
                this.setState({ selectBuilding: responseJson.data.building_list })
            } else if (apiRequestCallId === this.ownerReportApiId) {
                let ownerId = responseJson.data.find((item: any) => item.id == this.state.ownerId)
                this.setState({ owenrReportData: ownerId })
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        const user_id = this.props.navigation.getParam("id");
        this.setState({ ownerId: user_id ,token:token},()=>{
            this.handleCountryApi();
            this.handleCityApi()
            this.ownerReportDetailsApi()
            this.handleComplexApi()
            this.handleBuildingApi()
            this.ownerReportApi()
        });     
      }

    handleCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        const values = {
            country: this.state.country,
            city: value ? value :""
          }
          this.handleComplexApi(values as {
            country:string,
            city:string
          }) 
        this.setState({ city: value as string })
    };

    handleComplexChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleBuildingApi(value as string)
        this.setState({ complex: value as string })
    };

    handleBuildingChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ building: value as string })
    };

    handleIndividualChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleCountryApi = (value?: string) => {
        const params = value ? `?company_id=${value}` : ""
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint + `${params}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleCityApi = (value?: string) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const params = value ? `?country=${value}` : ""

        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint + `${params}`
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleComplexApi = (value?: {
        country: string,
        city: string
    }) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const params = value ? `?country=${value.country}&city=${value.city}` : ""

        this.getComplexApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.complexApiEndpoin + `${params}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleCityApi(value as string)
        const values = {
            country: value ? value : "",
            city: this.state.city
        }
        this.handleComplexApi(values as {
            country: string,
            city: string
        })
        this.setState({ country: value as string })
    };

    handleBuildingApi = (value?:string) => {
        const params = value ? `?complex_id=${value}` : ""
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessag = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuildingApiId = requestMessag.messageId;

        requestMessag.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.buildingApiEndpoin + `${params}`
        );
        requestMessag.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessag.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessag.id, requestMessag);
        return true;
    };

    handleFilter = (value:ChangeEvent<{ value: unknown | string}>) => {
        this.ownerReportDetailsApi(value as unknown as string);
    }

    handleFilterSearch = () => {
        this.ownerReportDetailsApi("")
    }
    
    ownerReportDetailsApi = (value?:string) => {
        const { city,  complex, building, country,ownerId } = this.state
        let userFilters: any = {
            city: city,
            complex: complex,
            building: building,
            country: country,
            owner_id: ownerId,
            listing:true,
            search:value
        }

        let initialEndPoint = `${configJSON.ownerReportDetailsApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter] !== 'All') {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ownerReportDetailsApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleVisibleList = () => {
        this.setState({ isListVisible: true });
    };

    handleVisibleMap = () => {
        this.setState({ isListVisible: false });
    };

    handleDownload = () => {
        this.setState({downloadReport:true})
    }

    ownerReportApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ownerReportApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ownerReportsApiEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleDownloadReport = () => {
        this.downloadPdf(
            `/bx_block_report/owner_reports/download_owner_report.pdf?listing=false&owner_id=${this.state.ownerId}&report_type=owner_report_details`, `owner_report.pdf`
        );
    }

    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        // @ts-ignore
        link.parentNode.removeChild(link);
    };

    

  
    // Customizable Area End
}
