// Customizable Area Start
import React from "react";
import RealEstateCompanyDetailsController, { Props } from "./RealEstateCompanyDetailsController.web";
import { withRouter } from "react-router-dom";
import RealEstateCompanyDetailsCard from "./RealEstateCompanyDetailsCard.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class RealEstateCompanyDetails extends RealEstateCompanyDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;
    const match: any = this.props.match;
    const id = match?.params?.id;

    return (
      <>
        <CEHeaderComponentWeb>
          <RealEstateCompanyDetailsCard navigation={navigation} id={"company-details-card"} companyId={id} />
        </CEHeaderComponentWeb>
      </>
    );
  }
}

export default withRouter(RealEstateCompanyDetails);
// Customizable Area End
