export const MultiUserImage = require("../assets/multi-user.png");
export const login = require("../assets/login.svg");
export const Owner = require("../assets/owners.svg");
export const Lease = require("../assets/lease.svg");
export const Invitations = require("../assets/invitations.svg");
export const Property = require("../assets/property.svg");
export const Subscriptions = require("../assets/subscription.svg");
export const logo = require("../assets/BuildingLogo.png");

export const ChatIcon = require("../assets/chat.png");
export const CallIcon = require("../assets/call.png");
export const EmailIcon = require("../assets/email.png");
export const FacebookIcon = require("../assets/facebook.png");
export const InstagramIcon = require("../assets/instagram.png");
export const TwitterIcon = require("../assets/twitter.png");
export const SnapchatIcon = require("../assets/snapchat.png");
