export const ChatIcon = require("../assets/chat.png");
export const CallIcon = require("../assets/call.png");
export const EmailIcon = require("../assets/email.png");
export const FacebookIcon = require("../assets/facebook.png");
export const InstagramIcon = require("../assets/instagram.png");
export const TwitterIcon = require("../assets/twitter.png");
export const SnapchatIcon = require("../assets/snapchat.png");
export const ProfileNameIcon = require("../assets/profile-name.png");
export const ProfilePhoneIcon = require("../assets/profile-phone.png");
export const ProfileEmailIcon = require("../assets/profile-email.png");
export const ProfileCalendarIcon = require("../assets/profile-calendar.png");
export const TwitterProfileIcon = require("../assets/profile-twitter.png");
export const FacebookProfileIcon = require("../assets/profile-facebook.png");
export const InstagramProfileIcon = require("../assets/profile-instagram.png");
export const SnapchatProfileIcon = require("../assets/profile-snapchat.png");
export const HeartProfileIcon = require("../assets/profile-heart.png");
export const SettingIcon = require("../assets/setting.png");
