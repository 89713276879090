import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");
interface UserFilters {
    city: string;
    complex: string;
    [key: string]: string | number | boolean | null;
  }
export interface Props {
    t:(value:string)=> string
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    city: string;
    country: string;
    complex: string;
    selectDate: string;
    subTable: boolean;
    downloadPage: boolean;
    downloadTable: boolean;
    subscriptionId: number | null;
    token: string;
    selectComplex: { id: string; name: string }[];
    selectCountry: string[];
    selectCity: string[];
    propertyManager: [];
    totalPages: number
    totalCount: null | number
    propertyManagerSubTable: [];
    subscriberId: null | number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

export default class PropertyManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getComplexApiId: string = ""
    getCountryApiId: string = ""
    getCityApiId: string = ""
    propertyManagerApiId: string = ""
    propertyManagerSubApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            city: "",
            country: "",
            complex: "",
            selectDate: "",
            subTable: false,
            downloadPage: false,
            downloadTable: false,
            subscriptionId: null,
            token: "",
            selectComplex: [],
            selectCountry: [],
            selectCity: [],
            propertyManager: [],
            totalPages: 1,
            totalCount:0,
            propertyManagerSubTable:[],
            subscriberId: null
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies})
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.propertyManagerApiId) {
                this.setState({ 
                    propertyManager: responseJson.data,
                    totalPages:responseJson?.meta?.pagination?.total_pages,
                    totalCount:responseJson?.meta?.pagination?.total_count 
                })
            } else if (apiRequestCallId === this.propertyManagerSubApiId) {
                this.setState({propertyManagerSubTable: responseJson.data })
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let subscription = await getStorageData("idees");
        this.setState({ subscriptionId: subscription })
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.propertyManagerAReportApi(1)
        this.ComplexApi()
        this.handleCountryApi()
        this.handleCityApi()
    }

    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ city: value as string })
    };

    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleCityApi(value as string)
        this.setState({ country: value as string })
    };

    handleComplexIndividualChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ complex: value as string })
    };

    handleSelectDateChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectDate: value as string })
    };

    handleSubscriotionReport = (index:number) => {
        this.setState({subTable:true, subscriberId: index})
        this.propertyManagerSubReportApi(index)
    }

    handleDownload = () => {        
        if(this.state.subTable){
            this.setState({downloadPage:true})
        } else if (!this.state.subTable) {
            this.setState({downloadTable:true})
        }
    }

    getStatusClass = (status: string) => {
        switch (status) {
            case 'active':
                return 'status-active';
            case 'suspended':
                return 'status-suspend';
            case 'deactive':
                return 'status-deactive';
            default:
                return '';
        }
    };

    ComplexApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.complexApiEndpoin
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.getComplexApiId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );

        this.getCountryApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCityApi = (value?:string) => {
        const params = `?country=${value}` 

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint + `${params}`
        );

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    propertyManagerAReportApi = (page:number) => {
        const { country,  city, complex, subscriptionId} = this.state
        let userFilters: UserFilters = {
            country: country,
            city: city,
            complex: complex,
            subscriber_id: subscriptionId,
            page: page,
            per_page: "10"
        }

        let initialEndPoint = `${configJSON.propertyManagerApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter] && userFilters[filter] !== 'All') {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.propertyManagerApiId = requestMessages.messageId;
        
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleSerach = () => {
        this.propertyManagerAReportApi(1)
    }

    handlePageChange = (_event:React.ChangeEvent<unknown>,page:number) => {
        this.propertyManagerAReportApi(page)
    }

    handleDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/property_manager_report/property_manager_statical_download.pdf?listing=false&subscriber_id=${this.state.subscriptionId}`, `PropertyManager.pdf`
          );
    }

    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    };

    propertyManagerSubReportApi = (index:number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.propertyManagerSubApiId = requestMessages.messageId;
        
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.propertyManagerSubApiEndPoint}?subscriber_id=${index}`
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleSubTableDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/subscription_reports/subscription_property_report_download?subscriber_id=${this.state.subscriberId}&listing=false`, `PropertyManager.pdf`
          );
    }
    // Customizable Area End
}
