// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import RentHistoryController, { Props } from "./RentHistoryController.web";
import { MyUnitStyle } from "./MyUnitStyle.web";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  withStyles,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { CalenderIcon, DeleteRentIcon, ReceivedIcon, RentAmountHistoryIcon, TenantIcon } from "./assets";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import moment from "moment";
import { Form, Formik } from "formik";
import Loader from "../../../components/src/Loader.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class RentHistory extends RentHistoryController {
  constructor(props: Props) {
    super(props);
  }

  handleDeleteOption = (t: any) => {
    if (this.state.isDeleteOpen) {
      return (
        <>
          <p style={{ color: "#FC8434" }} className="bold-text" onClick={() => this.selectAllHistory()}>
            {t("Select All")}
          </p>
          <img src={DeleteRentIcon} alt="delete" onClick={() => this.deleteRentHistories()} />
        </>
      );
    } else {
      return <img src={DeleteRentIcon} alt="delete" onClick={() => this.setState({ isDeleteOpen: true })} />;
    }
  };

  handleEmptyList = (t: any) => {
    if (this.state.rentHistory.length === 0) {
      return <Box className="rent-history">{t("No history available")}</Box>;
    }
  };

  handleCheckbox = (e: any, history: any) => {
    if (!e.target.checked) {
      const newIdList = this.state.selectedRentHistory.filter((id: any) => id !== history.id);
      this.setState({
        selectedRentHistory: newIdList,
      });
    } else {
      this.setState({
        selectedRentHistory: [...this.state.selectedRentHistory, history.id],
      });
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.tenantDetails}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <IconButton
                      className="rent-history-back-btn"
                      onClick={() => this.props.navigation.navigate("MyUnitDetails", { id: this.state.unitId })}
                    >
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">{t("Rent History")}</span>
                  </div>
                  <div className="right-icon">{this.handleDeleteOption(t)}</div>
                </Box>
                <Box className="">
                  <Container className="rent-history-grid">
                    <Box className="rent-history-box">
                      {this.handleEmptyList(t)}
                      {this.state.rentHistory.map((history: any) => {
                        return (
                          <Box className="rent-history" key={history.id}>
                            <Box className="header">
                              <Box className="left-side">
                                <h4 className="bold-text">
                                  {moment(history.attributes.start_date, "YYYY-MM-DD").format("MMMM YYYY") +
                                    " to " +
                                    moment(history.attributes.end_date, "YYYY-MM-DD").format("MMMM YYYY")}
                                </h4>
                                <p className="date">{history.attributes.tenant_name || "-"}</p>
                              </Box>
                              {this.state.isDeleteOpen && (
                                <Checkbox
                                  onChange={(e: any) => {
                                    this.handleCheckbox(e, history);
                                  }}
                                  checked={this.state.selectedRentHistory.includes(history.id)}
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                              )}
                            </Box>
                            <Divider />
                            <Box className="history-info-box">
                              <Box className="info">
                                <p>{t("Rent Amount (Monthly)")}</p>
                                <span>
                                  {(history.attributes.currency &&
                                    this.validationText(history.attributes.currency.currency)) +
                                    " " +
                                    Number(this.validationText(history.attributes.rent_amount)).toLocaleString()}
                                </span>
                              </Box>
                              <Box className="info">
                                <p>{t("Received Amount")}</p>
                                <span>
                                  {(history.attributes.currency &&
                                    this.validationText(history.attributes.currency.currency)) +
                                    " " +
                                    Number(this.validationText(history.attributes.received_amount)).toLocaleString()}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    {!this.state.isDeleteOpen && (
                      <div className="upload-button">
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Button onClick={() => this.handleRentHistoryModal()}>
                              {t("Add Another Rent History")}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Container>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Drawer
          anchor="bottom"
          className="condition-modal penalty-dialog rent-history-dialog"
          open={this.state.isRentHistoryModalOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.handleRentHistoryModal()}
        >
          <Formik
            enableReinitialize
            initialValues={this.state.rentHistoryForm}
            validationSchema={this.validationRentHistoryFormSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true }, () => {
                this.handleRentHistoryModal();
                this.addRentHistory(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes" className="rent-history-form">
                  <Box className={languageCondition(language, "arabic-grid", "")}>
                    <h4 className="bold-text rent-modal-heading">{t("Rent History")}</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box rent-modal-start-date">
                            <Input
                              inputProps={{
                                max: moment().format("YYYY-MM-DD"),
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.startDate}
                              name="startDate"
                              className={
                                !values.startDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              type="date"
                              placeholder={t("Start Date")}
                            />
                            <img src={CalenderIcon} alt="" />
                          </Box>
                          {ErrorHandler(t, touched.startDate, errors.startDate)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box rent-modal-end-date">
                            <Input
                              onChange={handleChange}
                              name="endDate"
                              onBlur={handleBlur}
                              value={values.endDate}
                              className={
                                !values.endDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              type="date"
                              placeholder={t("End Date")}
                              inputProps={{
                                max: moment().format("YYYY-MM-DD"),
                                min: values.startDate,
                              }}
                            />
                            <img src={CalenderIcon} alt="" />
                          </Box>
                          {ErrorHandler(t, touched.endDate, errors.endDate)}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency rent-modal-amount">
                        <Input
                          placeholder={t("Rent Amount (Monthly)")}
                          value={values.rentAmount}
                          name="rentAmount"
                          className="select-input input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={RentAmountHistoryIcon} alt="" />
                            </InputAdornment>
                          }
                          type="text"
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.rentAmount, errors.rentAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency rent-modal-receive">
                        <Input
                          onChange={handleChange}
                          value={values.receivedAmount}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={ReceivedIcon} alt="" />
                            </InputAdornment>
                          }
                          onBlur={handleBlur}
                          placeholder={t("Received Amount")}
                          className="select-input input"
                          name="receivedAmount"
                          type="text"
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.receivedAmount, errors.receivedAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        className="select-input input"
                        placeholder={t("Tenant Name")}
                        value={values.tenantName}
                        onChange={handleChange}
                        name="tenantName"
                        onBlur={handleBlur}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={TenantIcon} alt="" />
                          </InputAdornment>
                        }
                        type="text"
                      />
                       {ErrorHandler(t, touched.tenantName, errors.tenantName)}
                    </FormControl>
                  </Box>
                  <Box className="button-group rent-modal-add-btn">
                    <Button className="add-button" type="submit">
                      {t("Add")}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withStyles(MyUnitStyle)(RentHistory));
// Customizable Area End
