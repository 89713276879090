import toast from "react-hot-toast";

let lastError = "";

export const ApiCatchErrorResponse = (errorResponse:any) => {
  if (errorResponse) {
    const errorMessage = JSON.stringify(errorResponse).replace(new RegExp('"', "g"), "");
    if (lastError !== errorMessage) { 
      lastError = errorMessage;
      toast.error(errorMessage);
    }
  }
};

export const ApiErrorResponse = (responseJson:any) => {
  if (!responseJson || !responseJson.errors) {
    return;
  }

  let errors = responseJson.errors;

  if (errors.length !== 0 && errors[0].token) {
    if (lastError !== errors[0].token) { 
      lastError = errors[0].token;
      toast.error(errors[0].token);
      // localStorage.clear();
      window.location.href = "/";
    }
    return;
  }

  let allErrors = "";
  errors.map((object:any) => {
    const newLocal = JSON.stringify(object);
    JSON.parse(newLocal, (key, value) => {
      if (value.length > 0) {
        if (allErrors.length <= 0) {
          allErrors = value;
        } else {
          allErrors = `${allErrors}\n${value}`;
        }
      }
    });
  });

  if (allErrors && lastError !== allErrors) { 
    lastError = allErrors;
    toast.error(allErrors);
  }
};
