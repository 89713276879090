// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  InputAdornment,
  Input,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Drawer,
  Link,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  EarthIcon,
  CubeIcon,
  CalenderIcon,
  RentAmountIcon,
  BuildingIcon,
  RegionIcon,
  CityIcon,
  ComplexIcon,
  FloorIcon,
  SizeIcon,
  ConfigIcon,
  PriceIcon,
  ValuationIcon,
  DeleteRentIcon,
  RentAmountHistoryIcon,
  ReceivedIcon,
  TenantIcon,
  UploadIcon,
} from "./assets";
import RegisterUnitController, { Props } from "./RegisterUnitController.web";
import moment from "moment";
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";
import { MyUnitStyle } from "./MyUnitStyle.web";
import { del_image } from "../../LeadManagement/src/assets";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class EditMyUnit extends RegisterUnitController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const unit_id = this.props.navigation.getParam("id");
    const society_id = localStorage.getItem("society_id");
    this.setState({ unitId: unit_id }, () => {
      this.getComplexDetails(society_id);
      this.getRentHistory(this.state.unitId);
      this.getEditUnitDetails();
    });
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.registerUnit}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className={languageCondition(language, "arabic-grid", "")}>
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <IconButton
                      className="edit-unit-back-btn"
                      onClick={() => this.props.navigation.navigate("MyUnitDetails", { id: this.state.unitId })}
                    >
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">{t("Edit Unit")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content form" style={{ marginBottom: "120px" }}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={this.state.unitRegisterForm}
                      validationSchema={this.validationEditUnitFormSchema}
                      onSubmit={(values: any, { resetForm }) => {
                        this.setState({ loading: true }, () => {
                          this.editRegisterUnit(values);
                          if (this.state.newRentIds.length > 0) {
                            this.publishRentHistory();
                          }
                          if (this.state.deleteRentIds.length > 0) {
                            this.deleteRentHistoriesOnSave();
                          }
                          resetForm();
                        });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                          <Form onSubmit={handleSubmit} translate="yes">
                            <Box className="select-input-box">
                              <h4 className="bold-text" style={{ marginTop: "18px", fontSize: "18px" }}>
                                {t("Location Details")}
                              </h4>
                              <FormControl fullWidth>
                                <Input
                                  value={values.country}
                                  name="country"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Country")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={EarthIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.region}
                                  name="region"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Region")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={RegionIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.city}
                                  name="city"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("City")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={CityIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <Box className="map-span">
                                <Link
                                  href={`https://maps.google.com/?q=${this.state.lat},${this.state.long}`}
                                  target="_blank"
                                >
                                  <span className="bold-text">{t("See building on map")}</span>
                                </Link>
                              </Box>
                              <h4 className="bold-text" style={{ marginTop: "18px", fontSize: "18px" }}>
                                {t("Unit Details")}
                              </h4>
                              <FormControl fullWidth>
                                <Input
                                  value={values.complex}
                                  name="complex"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Complex Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={ComplexIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.buildingId}
                                  name="buildingId"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Building Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={BuildingIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.floorId}
                                  name="floorId"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Floor Number")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={FloorIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.unitId}
                                  name="unitId"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Unit Number")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={CubeIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="unit-box-currency">
                                  <Input
                                    value={values.size}
                                    name="size"
                                    className="select-input input disable-input-edit-unit"
                                    placeholder={t("Size")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={SizeIcon} alt="" />
                                      </InputAdornment>
                                    }
                                    readOnly
                                  />
                                  <Box className="unit-box-value">{t(this.state.measurement)}</Box>
                                </Box>
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.config}
                                  name="config"
                                  className="select-input input disable-input-edit-unit"
                                  placeholder={t("Configuration")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={ConfigIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  readOnly
                                />
                              </FormControl>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={7}>
                                  <FormControl fullWidth>
                                    <Box className="unit-box-currency">
                                      <Input
                                        onChange={handleChange}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <img src={PriceIcon} alt="" />
                                          </InputAdornment>
                                        }
                                        className="select-input input edit-unit-price"
                                        value={values.price}
                                        name="price"
                                        placeholder={t("Purchase Price")}
                                        onBlur={handleBlur}
                                      />
                                      <Box className="unit-box-value">{t(this.state.currency)}</Box>
                                    </Box>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                  <FormControl fullWidth>
                                    <Input
                                      value={values.date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="date"
                                      className={
                                        !values.date && is_mobile
                                          ? "select-input input placeHolderForDate"
                                          : "select-input input"
                                      }
                                      placeholder={t("Purchase Date")}
                                      style={{ marginTop: "0" }}
                                      type="date"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <img src={CalenderIcon} alt="" />
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <h4 className="bold-text" style={{ marginTop: "18px" }}>
                                {t("Unit Status")}
                              </h4>
                              <FormControl fullWidth>
                                <Box>
                                  <RadioGroup
                                    name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="type-radio-select"
                                  >
                                    <FormControlLabel
                                      value="Rented"
                                      control={<Radio />}
                                      label={t("Rented")}
                                      className="rented"
                                    />
                                    <FormControlLabel
                                      value="Non-Rented"
                                      control={<Radio />}
                                      label={t("Vacant")}
                                      className="non-rented"
                                    />
                                  </RadioGroup>
                                </Box>
                                {ErrorHandler(t, touched.type, errors.type)}
                              </FormControl>
                              <h4 className="bold-text" style={{ marginTop: "18px", fontSize: "18px" }}>
                                {t("Rent History")}
                              </h4>
                              {this.state.rentHistoryList.map((rentHistory: any) => {
                                return (
                                  <Box className="rent-history-box" key={rentHistory.id}>
                                    <Box className="heading">
                                      <h4 className="bold-text">
                                        {moment(rentHistory.attributes.start_date, "YYYY-MM-DD").format("MMM YYYY")} to{" "}
                                        {moment(rentHistory.attributes.end_date, "YYYY-MM-DD").format("MMM YYYY")}
                                      </h4>
                                      <img
                                        onClick={() => {
                                          this.setState({ loading: true }, () => {
                                            this.deleteRentHistories(rentHistory.id);
                                          });
                                        }}
                                        src={DeleteRentIcon}
                                        alt="delete"
                                      />
                                    </Box>
                                    <p className="tenant-name">{rentHistory.attributes.tenant_name}</p>
                                    <Divider />
                                    <Box className="info-box">
                                      <Box className="info">
                                        <p>{t("Rent Amount (Monthly)")}</p>
                                        <span>
                                          {this.state.currency +
                                            " " +
                                            Number(rentHistory.attributes.rent_amount).toLocaleString()}
                                        </span>
                                      </Box>
                                      <Box className="info">
                                        <p>{t("Received Amount")}</p>
                                        <span>
                                          {this.state.currency +
                                            " " +
                                            Number(rentHistory.attributes.received_amount).toLocaleString()}
                                        </span>
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              })}
                              {values.type === "Rented" && (
                                <>
                                  <Button
                                    className="add-rent-history-btn"
                                    onClick={() => this.handleCloseRentHistoryModal()}
                                  >
                                    {this.state.rentHistoryList.length == 0
                                      ? `${t("+ Add Rent History")}`
                                      : `${t("+ Add Another Rent History")}`}
                                  </Button>
                                  <FormControl fullWidth>
                                    <Box className="unit-box-currency edit-unit-income-box">
                                      <Input
                                        className="select-input input edit-unit-renting"
                                        onBlur={handleBlur}
                                        value={values.income}
                                        name="income"
                                        placeholder={t("Monthly Renting Income")}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <img src={RentAmountIcon} alt="" />
                                          </InputAdornment>
                                        }
                                        onChange={handleChange}
                                      />
                                      <Box className="unit-box-value">{t(this.state.currency)}</Box>
                                    </Box>
                                    {ErrorHandler(t, touched.income, errors.income)}
                                  </FormControl>
                                </>
                              )}
                              <FormControl fullWidth>
                                <Box className="unit-box-currency edit-unit-valuation-box">
                                  <Input
                                    placeholder={t("Current Valuation")}
                                    name="valuation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="select-input input edit-unit-valuation"
                                    value={values.valuation}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={ValuationIcon} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                  <Box className="unit-box-value edit-unit-currency">{t(this.state.currency)}</Box>
                                </Box>
                                {ErrorHandler(t, touched.valuation, errors.valuation)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="upload-box" onClick={() => this.uploadUnitImages.click()}>
                                  <img src={UploadIcon} alt="" />
                                  <p>{t("Upload Images")}</p>
                                </Box>
                                <input
                                  onChange={(e: any) => {
                                    for (let i = 0; i < e.target.files.length; i++) {
                                      const file = e.target.files[i];
                                      let reader = new FileReader();
                                      reader.onloadend = () => {
                                        values.images = [...values.images, reader.result];
                                        setFieldValue("images", values.images);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  name="images"
                                  style={{ display: "none" }}
                                  ref={(ref: any) => (this.uploadUnitImages = ref)}
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  multiple
                                  className="unit-edit-image-input"
                                />
                                <Grid container spacing={4} style={{ marginTop: "0" }}>
                                  {values.images.map((image: any, index: number) => {
                                    return (
                                      <Grid item md={3} sm={4} xs={6} key={index}>
                                        <Box className="building-image">
                                          <img
                                            src={del_image}
                                            className="delete-image"
                                            onClick={() => {
                                              const remainImage = values.images.filter(
                                                (img: any, idx: number) => idx !== index
                                              );
                                              setFieldValue("images", remainImage);
                                            }}
                                          />
                                          <img src={image} alt="" />
                                        </Box>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                {errors.images && touched.images && (
                                  <p className="error" style={{ marginTop: "20px" }}>
                                    {t(errors.images)}
                                  </p>
                                )}
                              </FormControl>
                              <Box className="next-button edit-submit-button">
                                <Button
                                  className="cancel"
                                  onClick={() =>
                                    this.props.navigation.navigate("MyUnitDetails", { id: this.state.unitId })
                                  }
                                >
                                  {t("Cancel")}
                                </Button>
                                <Button type="submit">{t("Save")}</Button>
                              </Box>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Drawer
          anchor="bottom"
          className="condition-modal penalty-dialog rent-history-dialog"
          open={this.state.isRentHistoryModalOpen}
          onClose={() => this.handleCloseRentHistoryModal()}
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <Formik
            enableReinitialize
            initialValues={this.state.rentHistoryForm}
            validationSchema={this.validationRentHistoryFormSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true }, () => {
                this.handleCloseRentHistoryModal();
                this.addRentHistory(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <Box className={languageCondition(language, "arabic-grid", "")}>
                    <h4 className="bold-text edit-unit-heading">{t("Rent History")}</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box">
                            <Input
                              onChange={handleChange}
                              value={values.startDate}
                              inputProps={{
                                max: moment().format("YYYY-MM-DD"),
                              }}
                              name="startDate"
                              className={
                                !values.startDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              placeholder={t("Start Date")}
                              onBlur={handleBlur}
                              type="date"
                            />
                            <img src={CalenderIcon} alt="calender" />
                          </Box>
                          {ErrorHandler(t, touched.startDate, errors.startDate)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box edit-unit-end-box">
                            <Input
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="endDate"
                              value={values.endDate}
                              className={
                                !values.endDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              inputProps={{
                                min: values.startDate,
                                max: moment().format("YYYY-MM-DD"),
                              }}
                              placeholder={t("End Date")}
                              type="date"
                            />
                            <img src={CalenderIcon} alt="" />
                          </Box>
                          {ErrorHandler(t, touched.endDate, errors.endDate)}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency edit-unit-amount-box">
                        <Input
                          value={values.rentAmount}
                          className="select-input input edit-unit-amount"
                          placeholder={t("Rent Amount")}
                          type="text"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={RentAmountHistoryIcon} alt="" />
                            </InputAdornment>
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="rentAmount"
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.rentAmount, errors.rentAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency edit-unit-receive-box">
                        <Input
                          value={values.receivedAmount}
                          className="select-input input"
                          placeholder={t("Received Amount")}
                          onBlur={handleBlur}
                          name="receivedAmount"
                          type="text"
                          onChange={handleChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={ReceivedIcon} alt="" />
                            </InputAdornment>
                          }
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.receivedAmount, errors.receivedAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        placeholder={t("Tenant Name")}
                        name="tenantName"
                        className="select-input input"
                        type="text"
                        value={values.tenantName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={TenantIcon} alt="" />
                          </InputAdornment>
                        }
                      />
                      {ErrorHandler(t, touched.tenantName, errors.tenantName)}
                    </FormControl>
                  </Box>
                  <Box className="button-group edit-unit-button-box">
                    <Button className="add-button edit-unit-button" type="submit">
                      {t("Add")}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withStyles(MyUnitStyle)(EditMyUnit));
// Customizable Area End
