// Customizable Area Start
import React from "react";
import { Container, Typography, withStyles, Divider, Box, Card } from "@material-ui/core";
import InvitationReportController, {Props} from "./InvitationReportController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebarWeb from "../../dashboard/src/ChairmanSidebar.web";
import {withTranslation} from "react-i18next";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import {withRouter} from "react-router";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class InvitationReport extends InvitationReportController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { t, classes }: any = this.props;

        return (
            <>
                <GeneralHeader>
                    <Container className={classes.reportDetails}>
                        <Box className="navigation">
                            <Typography variant="body1">
                                {t("Documents & Reports")} /
                                <Box
                                    component="span"
                                    className="reports-invitationReport breadcrumbInvitation" onClick={() => this.props.history.push("/Reports")}
                                    style={{ cursor: "pointer" }}
                                >
                                    {" "}
                                    {t("Reports")}
                                </Box>{" "}
                                /{" "}
                                <Box className="reports-invitationReport" component="span" style={{color: "blue"}}>
                                    {t("Invitations Reports")}
                                </Box>
                            </Typography>
                            <Box className="sub-heading-box">
                                <Typography
                                    variant="h5"
                                    className="sub-heading bold-text reports-invitationReport"
                                    style={{fontSize: "28px"}}
                                >
                                    {t("Invitations Reports")}
                                </Typography>
                                <select name="" id="" className="invitationReportSelectYear" value={this.state.selectedYear} onChange={this.manageChangeYear}>
                                    <option value={new Date().getFullYear() - 4}>{new Date().getFullYear() - 4}</option>
                                    <option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</option>
                                    <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                                    <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                </select>
                            </Box>
                        </Box>

                        <Box className="budget-box audit-box">
                            <Card>
                                <Box className="heading">
                                    <h4 className="bold-text reports-invitationReport">{t("Invitations Reports")}</h4>
                                </Box>
                                <Divider/>
                                <Box className="budget-content-box">
                                    <Box className="head audit-line">
                                        <p className={"bold-text"}>{t("Title")}</p>
                                        <span className={"bold-text"}>{t("Count")}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Total Sent Invitations")}</p>
                                        <span>{this.state.invitationData?.total_sent_invitation || 0} </span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Accepted Invitations by users")}</p>
                                        <span>{this.state.invitationData?.member_invitation_accepted || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Rejected Invitation by users")}</p>
                                        <span>{this.state.invitationData?.rejected_invitation_by_users || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Total Received Invitations")}</p>
                                        <span>{this.state.invitationData?.total_receive_invitation || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Pending join requests")}</p>
                                        <span>{this.state.invitationData?.pending_join_requests || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Sent invitations awaiting acceptance")}</p>
                                        <span>{this.state.invitationData?.sent_invitation_awaiting_acceptence || 0}</span>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Container>
                </GeneralHeader>
            </>
        );
    }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(InvitationReport)));

// Customizable Area End
