import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");
interface UserFilters {
    city: string;
    complex: string;
    [key: string]: string | number | boolean | null;
  }
export interface Props {
    t:(value:string)=> string
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    selectedCity: string;
    selectedCountry: string;
    individualbuilding: string;
    selectDate: string;
    subTable: boolean;
    downloadPage: boolean;
    downloadTable: boolean;
    gaMemberReport:[];
    token: string;
    selectComplex: { id: string; name: string }[];
    selectCountry: string[];
    selectCity: string[];
    totalPages: number | undefined
    totalCount: number;
    gaMemberSubReport : [];
    subscriberId: number | null;
    date: string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

export default class GAMemberReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGAMemberReportApiId: string = ""
    getComplexApiId: string = ""
    getCountryApiId: string = ""
    getCityApiId: string = ""
    gaMemberSubTabelApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            selectedCity: "",
            selectedCountry: "",
            individualbuilding: "",
            selectDate: "",
            subTable: false,
            downloadPage: false,
            downloadTable: false,
            gaMemberReport:[],
            token: '',
            selectComplex: [],
            selectCountry: [],
            selectCity: [],
            totalCount: 1,
            totalPages: 0,
            gaMemberSubReport:[],
            subscriberId: null,
            date:''
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getGAMemberReportApiId) {
                this.setState({ 
                    gaMemberReport: responseJson.data,
                    totalPages:responseJson?.meta?.pagination?.total_pages,
                    totalCount:responseJson?.meta?.pagination?.total_count 
                })
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies})
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if(apiRequestCallId === this.gaMemberSubTabelApiId) {
                this.setState({gaMemberSubReport: responseJson.data})
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.gaMemberReportApi()
        this.ComplexApi()
        this.handleCountryApi()
        this.handleCityApi()
    }

    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCity: value as string })
    };

    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleCityApi(value as string)
        this.setState({ selectedCountry: value as string })
    };

    handleComplexIndividualChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleSelectDateChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectDate: value as string })
    };

    handleSubscriotionReport = (index:number) => {
        this.setState({subTable:true,subscriberId:index})
        this.gaMemberSubTabelApi(index)
    }

    handleDownload = () => {        
        if(this.state.subTable){
            this.setState({downloadPage:true})
        } else if (!this.state.subTable) {
            this.setState({downloadTable:true})
        }
    }

     formatDate = (date:string) => {
        const latestDate = new Date(date);
        const day = String(latestDate.getDate()).padStart(2, '0');
        const month = String(latestDate.getMonth() + 1).padStart(2, '0');
        const year = String(latestDate.getFullYear()).slice(-2);
        
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    handleToDate = (event:ChangeEvent<{ value: unknown | string}>) => {        
        this.setState({
            ...this.state,
            date: event.target.value as string,
        });
      }

    getStatusClass = (status:string) => {
        switch (status) {
          case 'active':
            return 'status-active';
          case 'Suspended':
            return 'status-suspend';
          case 'Deactive':
            return 'status-deactive';
          default:
            return '';
        }
      };

    handleSerach = () => {
        this.gaMemberReportApi()
    }

    handleDateSerach = () => {
        this.gaMemberSubTabelApi(this.state.subscriberId)
    }

      gaMemberReportApi = () => {
        const { selectedCountry,  selectedCity, individualbuilding} = this.state
        let userFilters: UserFilters = {
            country: selectedCountry,
            city: selectedCity,
            complex: individualbuilding,
        }

        let initialEndPoint = `${configJSON.gaMemberReportApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter] && userFilters[filter] !== 'All') {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGAMemberReportApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    ComplexApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.complexApiEndpoin
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.getComplexApiId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );

        this.getCountryApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCityApi = (value?:string) => {
        const params = `?country=${value}` 

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint + `${params}`
        );

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    gaMemberSubTabelApi= (index:number | null) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.gaMemberSubTabelApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.gaMemberSubTabelApiIdEndpoint}?subscriber_id=${index}&${this.formatDate(this.state.date)}`
        );

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleSubTableDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/subscription_reports/subscription_ga_report_download?subscriber_id=${this.state.subscriberId}&listing=false`, `GAMemberSubscriptionReport.pdf`
          );
    }

    handleDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/property_manager_report/ga_usage_statical_report_download?listing_mamber=false`, `GAMemberUsageStatisticalReport.pdf`
          );
    }

    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    };
    // Customizable Area End
}
