// Customizable Area Start
import React from "react";
import BuildingMainBlockController, {
    Props,
} from "./BuildingMainBlockController.web";
import '../../../dashboard/src/Company Employee/global.css';
import CompEmpBuildingBlock from "./CompEmpBuildingBlock.web"
import { withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class BuildingMainBlock extends BuildingMainBlockController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { navigation } = this.props
        const match: any = this.props.match;
        const id = match?.params?.id;

        return (
            <>
                <CEHeaderComponentWeb>
                    <CompEmpBuildingBlock navigation={navigation} id={"CompEmpBuildingBlock"} location={this.props.location} history={this.props.history} companyId={id} />
                </CEHeaderComponentWeb>
            </>
        );
    }
}

export default withTranslation()(withRouter(BuildingMainBlock))
// Customizable Area End
