// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

import * as Yup from "yup";
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { checkPhoneValidation } from "../../../components/src/UtilFunction.web";

export type StyleObject = {
  [key: string]: {
    [key: string]: string | number;
  };
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  loading: boolean;
  isProfileOpen: boolean;
  isPublishOpen: boolean;

  userId: string;
  role: string;
  profileDetailObj: ProfileDetail | null;

  link: {
    snapchat: string;
    twitter: string;
    instagram: string;
    facebook: string;
  };

  profileForm: ProfileForm;
  publishDetails: IPublishDetails;

  error: string;
  showError: boolean;
}

interface IPublishDetails {
  [key: string]: boolean;
}

interface ProfileDetail {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: { name: string; publilc_access: boolean };
    bio: { bio: string | null };
    full_phone_number: {
      full_phone_number: null | string;
      country_code: null | string;
      phone_number: string | null;
      publilc_access: boolean;
    };
    email: { email: string | null; publilc_access: boolean };
    gender: { gender: number | null; publilc_access: boolean };
    date_of_birth: { date_of_birth: string | null; publilc_access: boolean };
    hobbies: { hobbies: string[]; publilc_access: boolean };
    website: [
      { twitter_link: string; publilc_access: boolean },
      { instagram_link: string; publilc_access: boolean },
      { fb_link: string; publilc_access: boolean },
      { snapchat_link: string; publilc_access: boolean }
    ];
    profile_pic: {
      url: string;
    } | null;
  };
}

interface ProfileForm {
  logo: File | never | null;
  displayLogo: string;
  name: string | null;
  phoneNumber: string | null;
  countryCode: string | null;
  email: string | null;
  about: string | null;
  gender: string | null;
  dob: string | null;
  hobbies: string[];
  twitter: string | null;
  facebook: string | null;
  instagram: string | null;
  snapchat: string | null;
}

interface SS {
  id: any;
}

export default class CEAccountProfileController extends BlockComponent<Props, S, SS> {
  uploadLogo: any;
  GetProfileDetailCallId: string = "";
  UpdateProfileDetailCallId: string = "";
  UpdatePublishProfileCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isProfileOpen: false,
      isPublishOpen: false,

      userId: "",
      role: "",
      profileDetailObj: null,

      profileForm: {
        logo: null,
        displayLogo: "",
        name: "",
        phoneNumber: "",
        countryCode: "+971",
        email: "",
        about: "",
        gender: "",
        dob: "",
        hobbies: [],
        twitter: "",
        facebook: "",
        instagram: "",
        snapchat: "",
      },

      link: {
        instagram: "",
        twitter: "",
        facebook: "",
        snapchat: "",
      },

      publishDetails: {
        name: false,
        phone: false,
        email: false,
        gender: false,
        dob: false,
        hobby: false,
        twitter: false,
        instagram: false,
        facebook: false,
        snapchat: false,
      },

      error: "",
      showError: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetProfileDetailCallId:
          return this.handleProfileResponse(responseJson);
        case this.UpdateProfileDetailCallId:
          return this.handleEditProfileResponse();
        case this.UpdatePublishProfileCallId:
          return this.handleEditPublishProfileResponse();
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const user_id = localStorage.getItem("user_id") || "";
    const role = localStorage.getItem("role") || "";
    this.setState({ userId: user_id, role: role }, () => {
      this.getProfileDetails();
    });
  }

  handleEditProfile = () => {
    this.setState({ isProfileOpen: !this.state.isProfileOpen });
  };

  handleEditPublishProfile = () => {
    this.setState({ isPublishOpen: !this.state.isPublishOpen });
  };

  getProfileDetails = async () => {
    this.GetProfileDetailCallId = await CEApiCall({
      method: "GET",
      endPoint: `bx_block_profile/profiles/employee_profile?id=${this.state.userId}`,
      contentType: "application/json",
    });
  };

  handleProfileResponse = (responseJson: { data: ProfileDetail }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        profileDetailObj: responseJson.data,
        link: {
          snapchat: responseJson.data.attributes.website[3].snapchat_link,
          facebook: responseJson.data.attributes.website[2].fb_link,
          instagram: responseJson.data.attributes.website[1].instagram_link,
          twitter: responseJson.data.attributes.website[0].twitter_link,
        },
      });
    }
  };

  updateProfile = async (value: ProfileForm) => {
    const formData = new FormData();
    formData.append("[data][attributes][date_of_birth]", `${value.dob}`);
    formData.append("[data][attributes][full_name]", `${value.name}`);
    formData.append("[data][attributes][full_phone_number]", `${value.countryCode}-${value.phoneNumber}`);
    formData.append("[data][attributes][gender]", value.gender == "Male" ? "0" : "1");
    formData.append("[data][attributes][profile_bio]", value.about || "");
    formData.append("[data][attributes][instagram_link]", value.instagram || "");
    formData.append("[data][attributes][snapchat_link]", value.snapchat || "");
    formData.append("[data][attributes][twitter_link]", value.twitter || "");
    formData.append("[data][attributes][fb_link]", value.facebook || "");
    (value.hobbies || []).forEach((item: string) => {
      formData.append("[data][attributes][hobbies][]", item);
    });
    if (value.hobbies.length === 0) {
      formData.append("[data][attributes][hobbies][]", "");
    }
    if (value.logo) {
      formData.append("[data][attributes][image]", value.logo);
    }

    this.UpdateProfileDetailCallId = await CEApiCall({
      body: formData,
      endPoint: `bx_block_profile/profiles_update?id=${this.state.userId}`,
      method: "PUT",
    });
  };

  handleEditProfileResponse = () => {
    this.setState({ loading: false }, () => {
      this.getProfileDetails();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  handleEditProfileDetails = () => {
    const profile = this.state.profileDetailObj;
    if (profile) {
      this.setState(
        {
          profileForm: {
            logo: null,
            displayLogo: profile.attributes.profile_pic?.url || "",
            name: profile.attributes.full_name.name,
            phoneNumber: profile.attributes.full_phone_number.phone_number,
            countryCode: "+" + profile.attributes.full_phone_number.country_code,
            email: profile.attributes.email.email,
            about: profile.attributes.bio.bio,
            gender: profile.attributes.gender.gender === 1 ? "Female" : "Male",
            dob: profile.attributes.date_of_birth.date_of_birth,
            hobbies: profile.attributes.hobbies.hobbies,
            facebook: profile.attributes.website[2].fb_link,
            instagram: profile.attributes.website[1].instagram_link,
            snapchat: profile.attributes.website[3].snapchat_link,
            twitter: profile.attributes.website[0].twitter_link,
          },
        },
        () => {
          this.handleEditProfile();
        }
      );
    }
  };

  handleOpenProfilePublish = () => {
    const profile = this.state.profileDetailObj;
    if (profile) {
      this.setState({
        isPublishOpen: true,
        publishDetails: {
          name: profile.attributes.full_name.publilc_access,
          phone: profile.attributes.full_phone_number.publilc_access,
          email: profile.attributes.email.publilc_access,
          gender: profile.attributes.gender.publilc_access,
          dob: profile.attributes.date_of_birth.publilc_access,
          hobby: profile.attributes.hobbies.publilc_access,
          twitter: profile.attributes.website[0].publilc_access,
          instagram: profile.attributes.website[1].publilc_access,
          facebook: profile.attributes.website[2].publilc_access,
          snapchat: profile.attributes.website[3].publilc_access,
        },
      });
    }
  };

  updatePublishProfile = async (value: IPublishDetails) => {
    const data = {
      data: {
        attributes: {
          name_public: value.name,
          email_public: value.email,
          phone_no_public: value.phone,
          gender_public: value.gender,
          date_of_birth_public: value.dob,
          hobbies_public: value.hobby,
          twitter_public: value.twitter,
          instagram_public: value.instagram,
          snapchat_public: value.snapchat,
          apartment_no_public: true,
          family_details_public: true,
        },
      },
    };
    this.UpdatePublishProfileCallId = await CEApiCall({
      contentType: "application/json",
      endPoint: `bx_block_profile/profiles/employee_public_profile?id=${this.state.userId}`,
      method: "PUT",
      body: JSON.stringify(data),
    });
  };

  handleEditPublishProfileResponse = () => {
    this.setState({ loading: false }, () => {
      this.getProfileDetails();
    });
  };

  handleActiveClass = (value: string | null, match: string) => (value === match ? "active" : "unactive");

  validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    about: Yup.string()
      .required("About profile is required")
      .nullable(),
  });

  handleFormSubmit = (values: ProfileForm) => {
    if (checkPhoneValidation(values.phoneNumber, values.countryCode)) {
      this.setState({ loading: true, isProfileOpen: false }, () => {
        this.updateProfile(values);
      });
    } else {
      this.setState({ error: "Please enter valid mobile number", showError: true });
    }
  };
}

// Customizable Area End
