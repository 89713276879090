import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { setStorageData } from "../../../framework/src/Utilities";

import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
  id: string;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  match?: any;
  location?: any;
  history?: any;
  navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
  accountSaleValue: string;
  accountManagerDataSave: any[];
  sortBy: string;
  searchText: string;
  pageNumber: number;
  paginationData: any | null;
  showError: boolean;
  errorMessage: string;
  loader: boolean;
  accountManagerprofileId:number
  anchorEl: HTMLButtonElement | null;
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AccountManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  assignedAccountManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
      sortBy: "",
      searchText: "",
      pageNumber: 0,
      anchorEl: null,
      accountManagerprofileId:0,
      accountManagerDataSave: [],
      accountSaleValue: "",
      paginationData: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
        total_count: 0,
      },
      showError: false,
      errorMessage: "",
      loader: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if ((responseJson && !responseJson.errors) || responseJson.contracts) {
        this.assignedAccountManagerApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.assignedAccountManagerApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
        // Customizable Area End
    }
    // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAssignedAccountManagerList("1");
  }

  assignedAccountManagerApiResponseSucessCell = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.assignedAccountManagerApiCallId) {
      this.assignedAccountManagerListSucessCallBack(responseJson);
    }
  };

  assignedAccountManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.assignedAccountManagerApiCallId) {
      this.assignedAccountManagerListFailureCallBack(responseJson);
    }
  };

  getAssignedAccountManagerList = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.assignAccountManagerEndpoint}page=${page}`;
    let { sortBy, searchText } = this.state;
    if (sortBy) {
      endPoint += `&sort_by=${sortBy}`;
    }
    if (searchText) {
      endPoint += `&q=${searchText}`;
    }
    this.assignedAccountManagerApiCallId = await CEApiCall({
      method: "GET",
      endPoint: endPoint,
      contentType: "application/json",
    });
  };

  assignedAccountManagerListSucessCallBack = (response: any) => {
    this.setState({ accountManagerDataSave: response.data, paginationData: response.meta.pagination, loader: false });
  };

  assignedAccountManagerListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0], loader: false });
  };

  sortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ sortBy: event.target.value as string }, () => {
      this.getAssignedAccountManagerList("1");
    });
  };

  searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      this.getAssignedAccountManagerList("1");
    });
  };

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getAssignedAccountManagerList(String(value));
  };

    handleViewData = (accountManager:any) => {
        this.setState({
            accountManagerprofileId:accountManager.attributes.id,
            anchorEl:null
        })
    };
    handleAccountmanagerProfile=()=>{
        setStorageData("viewProfileid",this.state.accountManagerprofileId)
        setStorageData("profileRole","Account Manager")

    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "SalesManagerProfileDashboard");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        id: this.state.accountManagerprofileId
      });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  handleAlertError = () => {
    this.setState({ errorMessage: "", showError: false });
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
      this.setState({ anchorEl: null });
  };
    // Customizable Area End
}
